:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
/* Global styles */
input[type=range] {
  -webkit-appearance: none;
  margin: auto;
  position: relative;
  height: 15px;
  width: 100px;
  cursor: pointer;
  border-radius: 6px;
  background: transparent;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  input[type=range] {
    width: 100px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  input[type=range] {
    width: 150px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  input[type=range] {
    width: 200px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  input[type=range] {
    width: 200px;
  }
}
@media only screen and (min-width:2303px) {
  input[type=range] {
    width: 200px;
  }
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 15px;
  border-radius: 6px;
  background: var(--rgbaVal);
  box-shadow: inset 0 0 0 1px white;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background: #fff;
  border: 2px solid #999;
  border-radius: 50%;
  margin-top: -1px;
}

/* Custom slider styles */
input.custom-slider[type=range] {
  height: 10px !important;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  input.custom-slider[type=range] {
    width: 72%;
    height: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  input.custom-slider[type=range] {
    width: 72%;
    height: 15px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  input.custom-slider[type=range] {
    width: 72%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  input.custom-slider[type=range] {
    width: 72%;
    height: 15px !important;
  }
}
@media only screen and (min-width:2303px) {
  input.custom-slider[type=range] {
    width: 200px;
  }
}

input.custom-slider[type=range]::-webkit-slider-runnable-track {
  background: var(--rgbaVal) !important;
  height: 12px;
  box-shadow: inset 0 0 0 1px white;
}

input.custom-slider[type=range]::-webkit-slider-thumb {
  width: 15px !important;
  height: 15px !important;
  background: #fff !important;
  border: 1px solid #999 !important;
  border-radius: 50%;
  margin-top: -1px;
}

/* Firefox styles */
input.custom-slider[type=range]::-moz-range-track {
  background: var(--rgbaVal);
}

input.custom-slider[type=range]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #999;
}

/* IE and Edge styles */
input.custom-slider[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input.custom-slider[type=range]::-ms-fill-lower {
  background: var(--rgbaVal);
}

input.custom-slider[type=range]::-ms-fill-upper {
  background: var(--rgbaVal);
}

input.custom-slider[type=range]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #999;
  border-radius: 50%;
}/*# sourceMappingURL=Layout.css.map */