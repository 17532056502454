:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .resettotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .resettotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .resettotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .resettotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .resetPassTotalArea {
    display: grid;
    grid-template-rows: auto;
    grid-template-areas: "a";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .resetPassTotalArea {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "a";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .resetPassTotalArea {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "a";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .resetPassTotalArea {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "a";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .resetPassTotalArea {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "a";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .resetPassAreaA {
    width: 100%;
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .resetPassAreaA {
    width: 40%;
    grid-area: a;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .resetPassAreaA {
    width: 30%;
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .resetPassAreaA {
    width: 20%;
    grid-area: a;
  }
}
@media only screen and (min-width:2303px) {
  .resetPassAreaA {
    width: 20%;
    grid-area: a;
  }
}

.resetPassHeaderWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resetPassHeader {
  font-family: var(--font-family);
  font-size: 20px;
}

.resetPassFormWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.resetPassFieldset {
  width: 90%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
}

.resetPassLegend {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
}

.resetPassInput, .resetPassConfirm {
  width: 70%;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 5px;
  padding: 5px;
  font-family: var(--font-family);
  font-size: 14px;
  outline: none;
}

.resetPassInput:active, .resetPassConfirm:active {
  border: 1px solid;
  border-color: rgb(225, 176, 68);
}

.resetPassInput:focus, .resetPassConfirm:focus {
  border: 1px solid;
  border-color: rgb(225, 176, 68);
}

.resetPassButton {
  width: 70%;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 5px;
  padding: 5px;
  font-family: var(--font-family);
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s;
}
.resetPassButton:hover {
  background-color: rgb(229, 64, 68);
  border: 1px solid;
  color: white;
}/*# sourceMappingURL=ResetLivePassword.css.map */