
.analyticsPage {
  max-width: 1200px;
 // margin: 0 auto;
  padding: 1rem;
  height:60vh;
  max-height:60vh;
  overflow-y:scroll;


}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.8rem;
  margin-bottom: 2rem;
}

.metric {
  background-color: #f0f0f0;
  padding: 0.2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  span {
    font-size: 0.875rem;
    color: #666;
  }
}
.metric2 {
  background-color: #f0f0f0;
  padding: 0.2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor:pointer;
  h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  span {
    font-size: 0.875rem;
    color: #666;
  }
}

.recipeList {
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 60vh; /* Restrict height for better UX */
  overflow: auto; /* Enable scrolling within this section */
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.searchInput {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    padding: 0.25rem 0.5rem;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    font-weight: bold;
    background-color: #f5f5f5;
  }

  tr:last-child td {
    border-bottom: none;
  }
}

  

