@import '../style/MainStyles.scss';/* styles.css */


.xrtotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}


.xrwindowArea {
    @include gadgets-to(mobile) {
        display: grid;
       // grid-template-rows: 3% 5% 26% 17% 30%;
        grid-template-rows:auto auto auto auto auto;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "e"
            ;
        width: 100%;
        height:auto;
        object-fit: cover;



    }


    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:28% 43% 28%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;

    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;


    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 99.8%;
        height: 100%;
        object-fit: cover;
    }
}

.xrwindowArea1 {
    @extend .xrwindowArea;
    background-color: map-get($generalStyles, backgroundColor);
}

.xrwindowAreaA {
    @include gadgets-to(mobile) {
    grid-area: c;
    border: none;
    width:100%;
    height: 30vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
    @include gadgets-to(tablet) {
        grid-area: c;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        @media(min-width:1170px) and (max-width:1195px)
        {
            height:75vh;
        }
    }
    @include gadgets-to(laptop) {
        grid-area: c;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(desktop) {
        grid-area: c;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(largeDesktop) {
        grid-area: c;
        border:1px solid;
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }

}

.xrwindowAreaB {
    @include gadgets-to(mobile) {

    grid-area: d;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:50vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top: 5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
    @include gadgets-to(tablet) {
        grid-area: d;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top: 5px;
        border-radius: map-get($generalStyles, sixBorderRadius);

        @media(min-width:1170px) and (max-width:1195px)
        {
            height:75vh;
        }
    
    }
    @include gadgets-to(laptop) {
        grid-area: d;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top: 5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(desktop) {
        grid-area: d;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top: 5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(largeDesktop) {
        grid-area: d;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top: 5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }

}

.xrwindowAreaC {
    @include gadgets-to(mobile) {
    grid-area: e;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 98%;
    height: 320px;
    margin-left: 1px;
    background-color: transparent;
    margin-top:20px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    
    @media(max-width:360px){
        margin-top:30px;
    }
    @media(min-width:390px)and (max-width:420px){
        margin-top:30px;
    }
    @media(min-width:421px){
        margin-top:35px;
    }




}
@include gadgets-to(tablet) {
    grid-area: e;
//     border: none;
//     border-color: map-get($generalStyles, datalistBorderColor);
//     background-color: map-get($generalStyles, transparentChangeable);
//     width: 98%;
//     height:80vh;
//     margin-left: 1px;
//     background-color: transparent;
//   //  margin-top:20px;
//     border-radius: map-get($generalStyles, sixBorderRadius);

    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:80vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top: 5px;
    border-radius: map-get($generalStyles, sixBorderRadius);

    @media(min-width:1170px) and (max-width:1195px)
    {
        height:75vh;
    }

}
@include gadgets-to(laptop) {
    grid-area: e;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:80vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top: 5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
    grid-area: e;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:80vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top: 5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    grid-area: e;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:80vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top: 5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
}



.xrheaderArea2{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;

    }
@include gadgets-to(laptop) {
        grid-area: a;
        height:40px;
    }
@include gadgets-to(desktop) {
        grid-area: a;
         height:50px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
        height:50px;
    }

}
.xrheaderArea1{
    width:95%;
    height:30px;
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        width:95%;
  

    }
}


.xrcompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xrcompNameJAP {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 46px;
        color: white;
    }
}

.xrcompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xrcompNameJAP1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xrrangeArea{
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:20px;
         }
         // as ipad
        //  @media(min-width:1171px) and (max-width:1195px)
        //  {
         
        //     height:auto;
        //  }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:20px;
         }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}

.xrrange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xrrange2 {
    @extend .xrrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}

.xrleftTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.xrleftFieldset {
    display:flex;
    flex-direction:column;
    align-items: center;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    margin-top: 10px;
    height:auto;
 
}

.xrleftLegend {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
    margin-left:5%;
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        margin-top:5px;
      margin-left:14%;
    }
    @include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        margin-left:15%;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        margin-left:15%;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 20px;
        color: map-get($generalStyles, colorblack);
        margin-left:15%;
    }
}

.xrleftSelect {
    width: 90%;
    height: 30px;
  
    //border-color: map-get($generalStyles, selectBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;

    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        //   margin-left: 5px;
           width:70%;
           height:30px;
           color:black;
           @media(min-width:1170px) and (max-width:1195px)
           {
               margin:0;
               padding:0;
               height:30px;
               line-height: 25px;
               font-size:14px;
               border:1px solid;
               border-color: map-get($generalStyles, datalistBorderColor);
           
    }
}
    @include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        //   margin-left: 5px;
           width:70%;
           height:30px;
           border:1px solid;
           border-color: map-get($generalStyles, datalistBorderColor);
           margin-right:1%;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
     //   margin-left: 5px;
        width:70%;
        height:30px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-right:1%;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        width:70%;
        height:30px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-right:1%;
    }
}


.xrdataTop {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:90%;
    height:18vh;
    max-height:18vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:auto;
    max-height:60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;

    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:90%;
    height:auto;
    max-height:60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:90%;
    height:auto;
    max-height:60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:90%;
    height:auto;
    max-height:60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
}

.xrreceptidButton {
    @include gadgets-to(mobile) {
    width: 240px;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;

}
@include gadgets-to(tablet) {
    width:80%;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    //margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
    color:black;
}
@include gadgets-to(laptop) {
    width:80%;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
}
@include gadgets-to(desktop) {
    width:80%;
    height: 40px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
}
@include gadgets-to(largeDesktop) {
    width:80%;
    height: 50px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
}
}

.xrreceptidButton1 {
    @extend .xrreceptidButton;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}


.xrdatatop2 {
    width:100%;
}

.xrdatafieldset {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    margin-top: 10px;
    padding:0;
    @include gadgets-to(largeDesktop) {
     margin-left:1.5%;

    }
  
}

.xrdatalegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
    margin-left:3%;
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
    margin-left:3%;
    padding-bottom:6px;
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-left:2.6%;
    padding-bottom:1px;
}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
    margin-left:2.5%;
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    color: map-get($generalStyles, colorblack);
    margin-left:2.5%;
}
}

.xrheaderWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    @include gadgets-to(largeDesktop) {
        width:95%;
    }


}
.xrheaderArea {
    @include gadgets-to(mobile) {
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:0;
    margin:0;
}
@include gadgets-to(tablet) {
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:0;
    margin-bottom:3px;
    height:30px;
}
@include gadgets-to(laptop) {
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:0;
  //  margin-bottom:3px;
    height:30px;
}
@include gadgets-to(desktop) {
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:0;
    margin-bottom:3px;
    height:25px;
}
@include gadgets-to(largeDesktop) {
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:0;
    margin-bottom:3px;
    height:30px;
}
}

.xrheaderIngredid {
    @include gadgets-to(mobile) {
    width:13%;
    height: 25px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
}
@include gadgets-to(tablet) {
    width:13%;
    height: 30px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(laptop) {
    width:13%;
    height: 30px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:13%;
    height: 30px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
}
@include gadgets-to(largeDesktop) {
    width:13%;
    height: 30px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
}
}

.xrheaderBenaemn {
    @extend .xrheaderIngredid;
    width:calc(35% - 2px);
    margin-left:2px;
}

.xrheaderMangd {
    @extend .xrheaderIngredid;
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}

.xrheaderKoeff {
    @extend .xrheaderIngredid;
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}

.xrheaderIngredArbvikt {
    @extend .xrheaderIngredid;
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}

.xrheaderArborder {
    @extend .xrheaderIngredid;
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
    @include gadgets-to(largeDesktop) {
        width:calc(13% - 2px);
        margin-left:2px;
        padding:1px;
        margin-right:15px;
    }
}

.xrdataAreaTop{
    @include gadgets-to(mobile) {
    width:100%;
    height:auto;
    max-height:22vh;
    overflow-y: scroll;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
   // margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
}
@include gadgets-to(tablet) {
    width:100%;
    height:auto;
    max-height:35vh;
    overflow-y: scroll;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeSixteen);
   // margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }

        @media(min-width:1170px) and (max-width:1195px)
        {
            max-height:31vh;
        }
}
@include gadgets-to(laptop) {
    width:100%;
    height:auto;
    max-height:40vh;
    overflow-y: scroll;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
   // margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
}
@include gadgets-to(desktop) {
    width:100%;
    height:auto;
    max-height:35vh;
    overflow-y: scroll;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
   // margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
}
@include gadgets-to(largeDesktop) {
    width:95%;
    height:auto;
    max-height:35vh;
    overflow-y: scroll;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
   // margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 15px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
            border:1px solid;
            border-color:map-get($generalStyles,transparentChangeableMenu5);
        }
}
}

.xrdatarows {
    grid-area: b;
    width:95%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}

.xrsamdataingredid {
    @include gadgets-to(mobile) {
    width:13%;
    height: 25px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 3px;
}
@include gadgets-to(tablet) {
    width:13%;
    height: 30px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 3px;
    color:black;
}
@include gadgets-to(laptop) {
    width:13%;
    height: 30px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 3px;
}
@include gadgets-to(desktop) {
    width:13%;
    height:40px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    margin-top: 3px;
}
@include gadgets-to(largeDesktop) {
    width:13%;
    height: 45px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-top: 3px;
}
}

.xrsamdataingredid1 {
    @extend .xrsamdataingredid;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
   width:13%;
}

.xrsamdatabenaemn {
    @extend .xrsamdataingredid;
    @include gadgets-to(mobile) {
      width:calc(35% - 2px);
   margin-left:2px;
    font-size: 12px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   
}
@include gadgets-to(tablet) {
    width:calc(35% - 2px);
    margin-left:2px;
    font-size: 14px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
@include gadgets-to(laptop) {
    width:calc(35% - 2px);
    margin-left:2px;
    font-size: 14px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@include gadgets-to(desktop) {
    width:calc(35% - 2px);
    margin-left:2px;
    font-size: 18px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@include gadgets-to(largeDesktop) {
    width:calc(35% - 2px);
    margin-left:2px;
    font-size: 18px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}


.xrsamdatabenaemn1 {
    @extend .xrsamdatabenaemn;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
      width:calc(35% - 2px);
      margin-left:2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

}
@include gadgets-to(tablet) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(35% - 2px);
    margin-left:2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(35% - 2px);
    margin-left:2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(35% - 2px);
    margin-left:2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(35% - 2px);
    margin-left:2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}



.xrsamdatamangd {
    @extend .xrsamdataingredid;
    @include gadgets-to(mobile) {
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
   
}
@include gadgets-to(tablet) {
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}
@include gadgets-to(laptop) {
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}
@include gadgets-to(desktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}
@include gadgets-to(largeDesktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}
}

.xrsamdatamangd1 {
    @extend .xrsamdatamangd;
    @include gadgets-to(mobile) {
    width:calc(13% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    width:calc(13% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    width:calc(13% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(desktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
}


.xrsamdatakoeff {
    @extend .xrsamdataingredid;
    @include gadgets-to(mobile) {
    width:calc(13% - 2px);
   margin-left:2px;
    font-size: 12px;
    padding:1px;
}
@include gadgets-to(tablet) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 14px;
    padding:1px;
}
@include gadgets-to(laptop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 14px;
    padding:1px;
}
@include gadgets-to(desktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 18px;
    padding:1px;
}
@include gadgets-to(largeDesktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 20px;
    padding:1px;
}
}


.xrsamdatakoeff1 {
    @extend .xrsamdatakoeff;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(13% - 2px);
    margin-left:2px;
}
@include gadgets-to(tablet) {
 
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(13% - 2px);
    margin-left:2px;
}
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(13% - 2px);
    margin-left:2px;
}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(13% - 2px);
    margin-left:2px;
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(13% - 2px);
    margin-left:2px;
}
}


.xrsamdataarbvikt {
    @extend .xrsamdataingredid;
    @include gadgets-to(mobile) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 12px;
    border-color: map-get($generalStyles, datalistBorderColorBlack);
 
}
@include gadgets-to(tablet) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 14px;
    border-color: map-get($generalStyles, datalistBorderColorBlack);
}
@include gadgets-to(laptop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 14px;
    border-color: map-get($generalStyles, datalistBorderColorBlack);
}
@include gadgets-to(desktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 18px;
    border-color: map-get($generalStyles, datalistBorderColorBlack);
}
@include gadgets-to(largeDesktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 20px;
    border-color: map-get($generalStyles, datalistBorderColorBlack);
}
}


.xrsamdataarbvikt1 {
    @extend .xrsamdataarbvikt;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(13% - 2px);
    margin-left:2px;
}

.xrsamdataarborder {
    @extend .xrsamdataingredid;
    @include gadgets-to(mobile) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 12px;
}
@include gadgets-to(tablet) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 14px;
}
@include gadgets-to(laptop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 18px;
}
@include gadgets-to(largeDesktop) {
    width:calc(13% - 2px);
    margin-left:2px;
    font-size: 20px;
}
}


.xrsamdataarborder1 {
    @extend .xrsamdataarborder;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(13% - 2px);
    margin-left:2px;
}

.xrfooterWrapper{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
}

@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    //justify-content:center;
   // align-items:center;
 //  margin-left:1.8%;
   width:93.5%;
}
}


.xrfooter {
    display: flex;
    flex-direction: row;
    margin-top: 1px;
    padding:0;
    margin:0;
    width:95%;
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1px;
     ///   width:95%;
      //  margin-right:12px;
      margin-left:2.5%;
    }
}


.xrfooterIngredid {
    @include gadgets-to(mobile) {
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;

width:13%;
height: 20px;
text-align: center;
border: map-get($generalStyles, prefferedBorderStyle);
border-color: map-get($generalStyles, datalistBorderColor);
background-color: map-get($generalStyles, transparentChangeableMenu4);
border-radius: map-get($generalStyles, sixBorderRadius);
font-family: map-get($generalStyles, fontFamily);
font-size: 12px;
margin-top: 3px;
font-weight: bold;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;

    width:13%;
    height: 25px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 3px;
   // font-weight: bold;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;

    width:13%;
    height: 20px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-top: 3px;
  //  font-weight: bold;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;

    width:13%;
    height: 30px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    margin-top: 3px;
  //  font-weight: bold;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;

    width:13%;
    height: 40px;
    text-align: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    margin-top: 3px;
   // font-weight: bold;
}
}



.xrfooterBenaemn {
    @extend .xrfooterIngredid;
    width:calc(35% - 2px);
    margin-left:2px;
 
}

.xrfooterMangd {
    @extend .xrfooterIngredid;
    width:calc(13% - 2px);
    margin-left:2px;

  
}

.xrfooterKoeff {
    @extend .xrfooterIngredid;
    width:calc(13% - 2px);
    margin-left:2px;

}

.xrfooterIngredArbvikt {
    @extend .xrfooterIngredid;
    width:calc(13% - 2px);
    margin-left:2px;
 
   
}

.xrfooterArborder {
    @extend .xrfooterIngredid;
    width:calc(13% - 2px);
    margin-left:2px;
}


.xrprodsamDescriptionTop {
    width:100%;
}

.xrprodsamDescriptionFieldset {
    @include gadgets-to(mobile) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    margin-top: 5px;
}
@include gadgets-to(tablet) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    margin-top: 5px;
}
@include gadgets-to(laptop) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    margin-top: 5px;
    padding:0;
}
@include gadgets-to(desktop) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    margin-top: 5px;
    padding:0;
}
@include gadgets-to(largeDesktop) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    margin-top: 5px;
    padding:0;
}
}

.xrprodsamDescriptionLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color: map-get($generalStyles, colorblack);
//    margin-left:1%;
    margin-top:3px;
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-left:2.5%;

}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
    margin-left:3%;
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
    margin-left:3%;
}
}


.xrprodsamDescriptionArea {
    @include gadgets-to(mobile) {
    width:100%;
}
    @include gadgets-to(tablet) {
        width:100%;
    }
    @include gadgets-to(laptop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
    
}
    @include gadgets-to(desktop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
    }
    @include gadgets-to(largeDesktop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center; 
    }
}

.xrprodsamDescriptionTextArea{
    @include gadgets-to(mobile) {
    width:93%;
    height:10vh;
    max-height:10vh;
    overflow-y: scroll;
    border: none;
    border-color: map-get($generalStyles, selectBorderColor);
    outline: none;
    background-color: map-get($generalStyles, transparentChangeable);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    resize:none;
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }

}
@include gadgets-to(tablet) {
    width:93%;
    height:25vh;
    max-height:25vh;
    overflow-y: scroll;
    border: none;
    border-color: map-get($generalStyles, selectBorderColor);
    outline: none;
    background-color: map-get($generalStyles, transparentChangeable);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    resize:none;
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
        cursor:pointer;
    }
}
@include gadgets-to(laptop) {
    width:93%;
    height:30vh;
    max-height:30vh;
    overflow-y: scroll;
    border: none;
    border-color: map-get($generalStyles, selectBorderColor);
    outline: none;
    background-color: map-get($generalStyles, transparentChangeable);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    resize:none;
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
        cursor:pointer;
    }
}
@include gadgets-to(desktop) {
    width:93%;
    height:30vh;
    max-height:30vh;
    overflow-y: scroll;
    border: none;
    border-color: map-get($generalStyles, selectBorderColor);
    outline: none;
    background-color: map-get($generalStyles, transparentChangeable);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    resize:none;
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
        cursor:pointer;
    }
}
@include gadgets-to(largeDesktop) {
    width:93%;
    height:30vh;
    max-height:30vh;
    overflow-y: scroll;
    border: none;
    border-color: map-get($generalStyles, selectBorderColor);
    outline: none;
    background-color: map-get($generalStyles, transparentChangeable);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    resize:none;
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
        cursor:pointer;
    }
}
}
.xrprodsamDescriptionTextAreaChange {
    @extend .xrprodsamDescriptionTextArea;
    border: map-get($generalStyles, prefferedBorderStyle);
}

.xrprodarbsumDescriptionArea {
    width:100%;
}

.xrproductMenuTop {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    width:100%;
    border: none;
}
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        width:95%;
        border: none;
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        width:95%;
        border: none;
    }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        width:95%;
        border: none;
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        width:95%;
        border: none;
    }
}

.xrproductMenuFieldset {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    padding:0;
    margin:0;
}
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:95%;
        padding:0;
        margin:0;
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction:column;
       // justify-content: center;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        padding:0;
        margin:0;
    
}
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        padding:0;
        margin:0;
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        padding:0;
        margin:0;
    }
}

.xrproductMenuData {
    @include gadgets-to(mobile) {
    height:auto;
    max-height:38vh;
    overflow-y: scroll;
    border: none;
    width:100%;
    //small scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(tablet) {
    height:auto;
    max-height:60vh;
    overflow-y: scroll;
    border: none;
    width:95%;
    //small scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(laptop) {
    height:auto;
    max-height:60vh;
    overflow-y: scroll;
    border: none;
    width:95%;
    //small scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }

}
@include gadgets-to(desktop) {
    height:auto;
    max-height:64vh;
    overflow-y: scroll;
    border: none;
    width:95%;
    //small scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(largeDesktop) {
    height:auto;
    max-height:70vh;
    overflow-y: scroll;
    border: none;
    width:95%;
    //small scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
      background:map-get($generalStyles, backgroundColor);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
}
}

.xrproductMenuDataArea {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // align-items: center;
    width:100%;
}
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // align-items: center;
        width:100%;
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // align-items: center;
        width:100%;
    }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // align-items: center;
        width:100%;
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // align-items: center;
        width:100%;
    }
}

.xrproductMenuLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
    margin-left:2%;
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
    margin-left:3%;
    padding-top:14px;
  //  margin-bottom:3px;
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-left:3%;
    padding-top:7px;
}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-left:3%;
    padding-top:9px;
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    color: map-get($generalStyles, colorblack);
    padding-top:5px;
    margin-left:3%;
}
}
.xrmenuProduktid {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:25%;
    height: 25px;
    margin-left: 3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-top:2px;
    cursor: pointer;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:25%;
    height: 30px;
    margin-left: 3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top:2px;
    cursor: pointer;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:25%;
    height: 30px;
    margin-left: 3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top:2px;
    cursor: pointer;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:25%;
    height: 30px;
    margin-left: 3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top:2px;
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:25%;
    height: 30px;
    margin-left: 3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top:2px;
    cursor: pointer;
}
}

.xrmenuBenaemn {
    @extend .xrmenuProduktid;
    @include gadgets-to(mobile) {
    width:calc(50% - 2px);
    margin-left:2px;

}
    @include gadgets-to(tablet) {
        width:calc(50% - 2px);
        margin-left:2px;
    }
    @include gadgets-to(laptop) {
        width:calc(50% - 2px);
        margin-left:2px;
    }
    @include gadgets-to(desktop) {
        width:calc(50% - 2px);
        margin-left:2px;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(50% - 2px);
        margin-left:2px;
    }
}

.xrmenuAntal {
    @extend .xrmenuProduktid;
    @include gadgets-to(mobile) {
    width:calc(25% - 2px);
    margin-right:1px;
}
    @include gadgets-to(tablet) {
        width:calc(25% - 2px);
        margin-right:1px;
    }
    @include gadgets-to(laptop) {
        width:calc(25% - 2px);
        margin-right:1px;
    }
    @include gadgets-to(desktop) {
        width:calc(25% - 2px);
        margin-right:1px;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(25% - 2px);
        margin-right:1px;
    }
}




.xrproduktidData {
    @extend .xrmenuProduktid;
    width:25%;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}

.xrproduktidData1 {
    @extend .xrproduktidData;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:25%;
}

.xrbenaemnData {
    @extend .xrmenuProduktid;
    @include gadgets-to(mobile) {
    width:calc(50% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
        //ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

}
    @include gadgets-to(tablet) {
        width:calc(50% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        //ellipsis
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      //  padding: 0 1px; // Adjust as needed
        line-height:30px;
        padding-top:2px;
      
    }
    @include gadgets-to(laptop) {
        width:calc(50% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        //ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @include gadgets-to(desktop) {
        width:calc(50% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        //ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(50% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        //ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.xrbenaemnData1 {
    @extend .xrbenaemnData;
    width:calc(50% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
        //ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

}


.xrantalData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:calc(25% - 2px);
    height: 25px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-top: 2px;
    margin-left: 3px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    @include gadgets-to(mobile) {
    width:calc(25% - 2px);
}
@include gadgets-to(tablet) {
    width:calc(25% - 2px);
    height:30px;
}
@include gadgets-to(laptop) {
    width:calc(25% - 2px);
    height:30px;
}
@include gadgets-to(desktop) {
    width:calc(25% - 2px);
    height:30px;
}
@include gadgets-to(largeDesktop) {
    width:calc(25% - 2px);
    height:30px;
    
    



}
}

.xrantalData1 {
    @extend .xrantalData;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:calc(25% - 2px);
}

.xrarbsumNewSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xrArbsumNewSpinner {
    background-color: transparent;
}

.xrbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }