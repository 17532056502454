:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xltotalArea, .xltotalArea1 {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xltotalArea, .xltotalArea1 {
    display: grid;
    grid-template-columns: 50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xltotalArea, .xltotalArea1 {
    display: grid;
    grid-template-columns: 50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xltotalArea, .xltotalArea1 {
    display: grid;
    grid-template-columns: 50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xltotalArea, .xltotalArea1 {
    display: grid;
    grid-template-columns: 50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xltotalArea1 {
  background-color: rgb(245, 245, 237);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlheaderArea {
    grid-area: a;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xlheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xlrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xlrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xlrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlrange1, .xlrange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlrange1, .xlrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlrange1, .xlrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlrange1, .xlrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xlrange1, .xlrange2 {
    accent-color: blue;
    width: 125px;
    margin-right: 10px;
    cursor: pointer;
    margin-left: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xlrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xlCompName,
.xlCompName1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lobster";
  font-size: 24px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlCompName,
  .xlCompName1 {
    font-size: 24px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlCompName,
  .xlCompName1 {
    font-size: 28px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlCompName,
  .xlCompName1 {
    font-size: 32px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlCompName,
  .xlCompName1 {
    font-size: 32px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xlCompName,
  .xlCompName1 {
    font-size: 40px;
    margin-top: 5px;
  }
}

.xlCompName {
  color: white;
}

.xlCompName1 {
  color: var(--rgbaVal);
}

@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xldatatopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xldatatopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xldatatopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.xlareaA, .xlareaB {
  grid-area: c;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  width: 100%;
  height: 82svh;
  background-color: var(--val);
  border-radius: 6px;
  margin-top: 1dvh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlareaA, .xlareaB {
    height: 82svh;
    margin-top: 1dvh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) {
  .xlareaA, .xlareaB {
    height: 84svh;
    margin-top: 1dvh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 390px) and (max-width: 429px) {
  .xlareaA, .xlareaB {
    height: 82svh;
    margin-top: 2dvh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 360px) and (max-width: 389px) {
  .xlareaA, .xlareaB {
    height: 80svh;
    margin-top: 3dvh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 360px) and (max-width: 389px) and (max-height: 640px) {
  .xlareaA, .xlareaB {
    height: 80svh;
    margin-top: 4dvh;
  }
}

.xlareaB {
  grid-area: d;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlsearchFieldset {
    border: none;
    margin-top: 10px;
    width: 91%;
    margin-left: 5px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlsearchFieldset {
    border: none;
    margin-top: 10px;
    width: 91%;
    margin-left: 5px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlsearchFieldset {
    border: none;
    margin-top: 10px;
    width: 80%;
    margin-left: 5px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlsearchFieldset {
    border: none;
    margin-top: 10px;
    width: 60%;
    margin-left: 5px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xlsearchFieldset {
    border: none;
    margin-top: 10px;
    width: 80%;
    margin-left: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlsearchLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlsearchLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlsearchLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 0.8vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlsearchLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 0.9vw;
  }
}
@media only screen and (min-width:2303px) {
  .xlsearchLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 0.9vw;
  }
}

.xlmessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-area: a;
}

.xltextSend {
  grid-area: a;
  width: 60%;
  height: 30px;
  border: 1px solid;
  background-color: white;
  color: rgb(255, 128, 0);
  font-family: var(--font-family);
  font-size: 16px;
  resize: none;
  border-radius: 6px;
  border-color: rgb(223, 212, 212);
  color: black;
  outline: none;
}

.xlbuttonSend {
  width: 150px;
}

.xlbuttonSendEnabled, .xlbuttonSend {
  width: 35%;
  height: 32px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: green;
  color: white;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 5px;
  outline: none;
  transition: background-color 1s ease;
}

.xlSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xlSpinner {
  color: black;
}

.xlrecepyNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlbuttonRecepyName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 35px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlbuttonRecepyName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 35px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlbuttonRecepyName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 35px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlbuttonRecepyName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 35px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xlbuttonRecepyName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 45px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlbuttonRecepyNameHeader {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-bottom: 3px;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlbuttonRecepyNameHeader {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-bottom: 3px;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlbuttonRecepyNameHeader {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-bottom: 3px;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlbuttonRecepyNameHeader {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-bottom: 3px;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xlbuttonRecepyNameHeader {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-bottom: 3px;
    margin-top: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xldataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xldataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xldataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xldataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xldataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xldataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xldataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xldataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xldataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
}
@media only screen and (min-width:2303px) {
  .xldataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}

.xlheader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlheaderButton,
  .xlheaderKcals,
  .xlheaderButtonBenaemn,
  .xlheaderButtonAmount,
  .xlheaderInvisible,
  .xlheaderButtonUpdate {
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlheaderButton,
  .xlheaderKcals,
  .xlheaderButtonBenaemn,
  .xlheaderButtonAmount,
  .xlheaderInvisible,
  .xlheaderButtonUpdate {
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlheaderButton,
  .xlheaderKcals,
  .xlheaderButtonBenaemn,
  .xlheaderButtonAmount,
  .xlheaderInvisible,
  .xlheaderButtonUpdate {
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlheaderButton,
  .xlheaderKcals,
  .xlheaderButtonBenaemn,
  .xlheaderButtonAmount,
  .xlheaderInvisible,
  .xlheaderButtonUpdate {
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xlheaderButton,
  .xlheaderKcals,
  .xlheaderButtonBenaemn,
  .xlheaderButtonAmount,
  .xlheaderInvisible,
  .xlheaderButtonUpdate {
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 20px;
    border-radius: 6px;
    margin-bottom: 2px;
  }
}

.xlheaderButtonBenaemn {
  width: calc(30% - 2px);
  text-align: center;
  padding: 1px;
}

.xlheaderButtonAmount {
  width: calc(14% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xlheaderKcals {
  width: calc(13% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xlheaderButton {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xlheaderButtonUpdate {
  width: calc(20% - 2px);
  border-top-right-radius: 6px;
  margin-left: 2px;
  padding: 1px;
}

.xlheaderInvisible {
  border: none;
  width: calc(8% - 2px);
  margin-left: 2px;
}

.xlreceivedContainer, .xlreceivedContainer1 {
  width: 100%;
  height: 36svh;
  max-height: 36svh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.xlreceivedContainer::-webkit-scrollbar, .xlreceivedContainer1::-webkit-scrollbar {
  width: 1px;
}
.xlreceivedContainer::-webkit-scrollbar-thumb, .xlreceivedContainer1::-webkit-scrollbar-thumb {
  background-color: rgb(223, 212, 212);
  border-radius: 10px;
}
.xlreceivedContainer::-webkit-scrollbar-track, .xlreceivedContainer1::-webkit-scrollbar-track {
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 360px) and (max-width: 360px) and (min-height: 700px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 375px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 384px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 390px) and (max-width: 390px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) and (max-width: 393px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 414px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 48svh;
    max-height: 48vh;
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1024px) and (max-width: 1080px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 40svh;
    max-height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) and (max-width: 1360px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 46svh;
    max-height: 46vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1361px) and (max-width: 1366px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 50svh;
    max-height: 50vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 48svh;
    max-height: 48vh;
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 48svh;
    max-height: 48vh;
    overflow-y: scroll;
  }
}
@media only screen and (min-width:2303px) {
  .xlreceivedContainer, .xlreceivedContainer1 {
    height: 50svh;
    max-height: 50vh;
    overflow-y: scroll;
  }
}

.xlreceivedContainer1 {
  height: 15svh;
  max-height: 15svh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlreceivedContainer1 {
    height: 15svh;
    max-height: 15svh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 640px) {
  .xlreceivedContainer1 {
    height: 3vh;
    max-height: 3vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 375px) {
  .xlreceivedContainer1 {
    height: 3vh;
    max-height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlreceivedContainer1 {
    height: 23svh;
    max-height: 23svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xlreceivedContainer1 {
    height: 10svh;
    max-height: 10svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) and (max-width: 1080px) {
  .xlreceivedContainer1 {
    height: 5svh;
    max-height: 5svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) and (max-width: 1120px) {
  .xlreceivedContainer1 {
    height: 5svh;
    max-height: 5svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) and (max-width: 1360px) {
  .xlreceivedContainer1 {
    height: 5svh;
    max-height: 5svh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlreceivedContainer1 {
    height: 23svh;
    max-height: 23svh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlreceivedContainer1 {
    height: 23svh;
    max-height: 23svh;
  }
}
@media only screen and (min-width:2303px) {
  .xlreceivedContainer1 {
    height: 23svh;
    max-height: 23svh;
  }
}

.xldatarows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xlnfobutton, .xlinfodeleteButton, .xlinfodeleteButton2, .xlinfobuttonChecked, .xlinfobuttonChecked2, .xlinfobutton2, .xlinfobutton, .xlkcalsButton, .xlkcalsButton2, .xlinfobuttonAmount, .xlinfobuttonAmount2, .xlinfobuttonBenaemn, .xlinfobuttonBenaemn2 {
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-bottom: 2px;
  background-color: var(--rgbaVal);
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlnfobutton, .xlinfodeleteButton, .xlinfodeleteButton2, .xlinfobuttonChecked, .xlinfobuttonChecked2, .xlinfobutton2, .xlinfobutton, .xlkcalsButton, .xlkcalsButton2, .xlinfobuttonAmount, .xlinfobuttonAmount2, .xlinfobuttonBenaemn, .xlinfobuttonBenaemn2 {
    height: 30px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlnfobutton, .xlinfodeleteButton, .xlinfodeleteButton2, .xlinfobuttonChecked, .xlinfobuttonChecked2, .xlinfobutton2, .xlinfobutton, .xlkcalsButton, .xlkcalsButton2, .xlinfobuttonAmount, .xlinfobuttonAmount2, .xlinfobuttonBenaemn, .xlinfobuttonBenaemn2 {
    height: 30px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlnfobutton, .xlinfodeleteButton, .xlinfodeleteButton2, .xlinfobuttonChecked, .xlinfobuttonChecked2, .xlinfobutton2, .xlinfobutton, .xlkcalsButton, .xlkcalsButton2, .xlinfobuttonAmount, .xlinfobuttonAmount2, .xlinfobuttonBenaemn, .xlinfobuttonBenaemn2 {
    height: 30px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xlnfobutton, .xlinfodeleteButton, .xlinfodeleteButton2, .xlinfobuttonChecked, .xlinfobuttonChecked2, .xlinfobutton2, .xlinfobutton, .xlkcalsButton, .xlkcalsButton2, .xlinfobuttonAmount, .xlinfobuttonAmount2, .xlinfobuttonBenaemn, .xlinfobuttonBenaemn2 {
    height: 40px;
    font-size: 26px;
  }
}

.xlinfobuttonBenaemn, .xlinfobuttonBenaemn2 {
  width: calc(30% - 2px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  padding-top: 5px;
}

.xlinfobuttonBenaemn2 {
  width: calc(30% - 2px);
  background-color: var(--rgbaVal2);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.xlinfobuttonAmount, .xlinfobuttonAmount2 {
  width: calc(14% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xlinfobuttonAmount2 {
  width: calc(14% - 2px);
  background-color: var(--rgbaVal2);
  padding: 1px;
}

.xlinfobutton, .xlkcalsButton, .xlkcalsButton2 {
  width: calc(15% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xlinfobutton2 {
  width: calc(15% - 2px);
  background-color: var(--rgbaVal2);
  margin-left: 2px;
  padding: 1px;
}

.xlkcalsButton, .xlkcalsButton2 {
  width: calc(13% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xlkcalsButton2 {
  width: calc(13% - 2px);
  background-color: var(--rgbaVal2);
  margin-left: 2px;
  padding: 1px;
}

.xlinfobuttonChecked, .xlinfobuttonChecked2 {
  width: calc(20% - 2px);
  padding: 1px;
  margin-left: 2px;
}

.xlinfobuttonChecked2 {
  width: calc(20% - 2px);
  background-color: var(--rgbaVal2);
  margin-left: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlinfodeleteButton, .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlinfodeleteButton, .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlinfodeleteButton, .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlinfodeleteButton, .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xlinfodeleteButton, .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    background-color: var(--rgbaVal2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    background-color: var(--rgbaVal2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    background-color: var(--rgbaVal2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    background-color: var(--rgbaVal2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xlinfodeleteButton2 {
    width: calc(8% - 2px);
    background-color: var(--rgbaVal2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlbiMessageSquareAdd {
    font-size: 18px;
    color: green;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlbiMessageSquareAdd {
    font-size: 18px;
    color: green;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlbiMessageSquareAdd {
    font-size: 18px;
    color: green;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlbiMessageSquareAdd {
    font-size: 18px;
    color: green;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xlbiMessageSquareAdd {
    font-size: 28px;
    color: green;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 2px;
  }
}

.xlMatchOK,
.xlMatchOK2 {
  border: none;
  background-color: transparent;
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .mdDoneOutline {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: not-allowed;
    background-color: transparent;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mdDoneOutline {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: not-allowed;
    background-color: transparent;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mdDoneOutline {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: not-allowed;
    background-color: transparent;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mdDoneOutline {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    cursor: not-allowed;
    background-color: transparent;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .mdDoneOutline {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    cursor: not-allowed;
    background-color: transparent;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlSelect {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 95%;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlSelect {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 95%;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlSelect {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 95%;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlSelect {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 95%;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xlSelect {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 20px;
    width: 95%;
    border-radius: 6px;
    background-color: white;
  }
}

.xltotalCostContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xltotalCostButton,
  .xltotalCaloriesButton,
  .xltotalCostBenaemn,
  .xltotalinfobuttonChecked,
  .xltotaldeleteButton,
  .xltotalAmountButton {
    width: 48px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2px;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xltotalCostButton,
  .xltotalCaloriesButton,
  .xltotalCostBenaemn,
  .xltotalinfobuttonChecked,
  .xltotaldeleteButton,
  .xltotalAmountButton {
    width: 48px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2px;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xltotalCostButton,
  .xltotalCaloriesButton,
  .xltotalCostBenaemn,
  .xltotalinfobuttonChecked,
  .xltotaldeleteButton,
  .xltotalAmountButton {
    width: 48px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2px;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xltotalCostButton,
  .xltotalCaloriesButton,
  .xltotalCostBenaemn,
  .xltotalinfobuttonChecked,
  .xltotaldeleteButton,
  .xltotalAmountButton {
    width: 48px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2px;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xltotalCostButton,
  .xltotalCaloriesButton,
  .xltotalCostBenaemn,
  .xltotalinfobuttonChecked,
  .xltotaldeleteButton,
  .xltotalAmountButton {
    width: 48px;
    height: 35px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 20px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2px;
    align-items: center;
  }
}

.xltotalCostBenaemn {
  width: calc(30% - 2px);
  text-align: left;
}

.xltotalAmountButton {
  width: calc(14% - 2px);
  margin-left: 2px;
}

.xltotalCostButton {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xltotalCaloriesButton {
  width: calc(13% - 2px);
  margin-left: 2px;
}

.xltotalinfobuttonChecked {
  width: calc(20% - 2px);
  margin-left: 2px;
}

.xltotaldeleteButton {
  width: calc(8% - 2px);
  margin-left: 2px;
}

.xlSelectNewIngredientContainer {
  margin-top: 3px;
  margin-left: 2px;
}

.xlSelectDbOwnerIngredient {
  height: 25px;
  outline: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  width: 187px;
  font-family: var(--font-family);
  font-size: 16px;
  border-radius: 6px;
  background-color: white;
}

.xlTextAreaContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
}

.xladdAmountIngramPlus,
.xladdAmountIngramMinus {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal2);
  color: black;
  font-family: var(--font-family);
  font-size: 11px;
  border-radius: 50px;
  margin-left: 2px;
  margin-bottom: 2px;
  font-weight: bold;
  cursor: pointer;
}

.xladdAmountIngramPlus {
  padding-left: 7px;
}

.xladdAmountIngramMinus {
  padding-left: 3px;
}

.xlTextareaComments {
  width: 242px;
  height: 80px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: white;
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
  font-weight: bold;
  padding: 1px;
  outline: none;
  resize: none;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlReceivedRecepyDescription {
    width: 98.6%;
    height: 300px;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: var(--font-family);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;
  }
  .xlReceivedRecepyDescription::-webkit-scrollbar {
    width: 5px;
  }
  .xlReceivedRecepyDescription::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
  .xlReceivedRecepyDescription::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlReceivedRecepyDescription {
    width: 98.6%;
    height: 76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: var(--font-family);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;
  }
  .xlReceivedRecepyDescription::-webkit-scrollbar {
    width: 5px;
  }
  .xlReceivedRecepyDescription::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
  .xlReceivedRecepyDescription::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlReceivedRecepyDescription {
    width: 98.6%;
    height: 76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: var(--font-family);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlReceivedRecepyDescription {
    width: 98.6%;
    height: 76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: var(--font-family);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;
  }
}
@media only screen and (min-width:2303px) {
  .xlReceivedRecepyDescription {
    width: 98.6%;
    height: 76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: var(--font-family);
    font-size: 24px;
    background-color: white;
    overflow-y: scroll;
  }
}

.xlRecepydescHeader {
  font-family: "Times New Roman, Times, serif";
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  margin-top: 7px;
  margin-left: 8px;
}

.xldescriptionlengthWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.xldescriptionLength {
  font-family: var(--font-family);
  font-size: 14px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlsaveMenuHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 87%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlsaveMenuHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 87%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlsaveMenuHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 87%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlsaveMenuHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 78%;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xlsaveMenuHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 87%;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlsaveMenuHeaderText {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlsaveMenuHeaderText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlsaveMenuHeaderText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlsaveMenuHeaderText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xlsaveMenuHeaderText {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

.xlsavemenuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xlSaveMenuContainer {
  width: 90%;
  height: 21svh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlSaveMenuContainer {
    height: 18dvh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1024px) and (max-width: 1080px) {
  .xlSaveMenuContainer {
    height: 20dvh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1360px) {
  .xlSaveMenuContainer {
    height: 25dvh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlSaveMenuContainer {
    width: 90%;
    height: 18svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlSaveMenuContainer {
    width: 80%;
    height: 18svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width:2303px) {
  .xlSaveMenuContainer {
    width: 90%;
    height: 13svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: var(--rgbaVal2);
  }
}

.xlSaveNameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 15px;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlsavenameFieldset {
    border: none;
    width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlsavenameFieldset {
    border: none;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlsavenameFieldset {
    border: none;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlsavenameFieldset {
    border: none;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xlsavenameFieldset {
    border: none;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlsavenameLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlsavenameLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlsavenameLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlsavenameLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xlsavenameLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}

.xlsavenametitle {
  font-size: 14px;
  color: black;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 50%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlSaveRecepySelect {
    width: 100%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlSaveRecepySelect {
    width: 100%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlSaveRecepySelect {
    width: 100%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlSaveRecepySelect {
    width: 100%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xlSaveRecepySelect {
    width: 100%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlInputSaveRecepyName {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    width: 100%;
    height: 25px;
    border-radius: 6px;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlInputSaveRecepyName {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    width: 100%;
    height: 25px;
    border-radius: 6px;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlInputSaveRecepyName {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    width: 100%;
    height: 25px;
    border-radius: 6px;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlInputSaveRecepyName {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    width: 100%;
    height: 30px;
    border-radius: 6px;
    box-sizing: border-box;
  }
}
@media only screen and (min-width:2303px) {
  .xlInputSaveRecepyName {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    width: 100%;
    height: 30px;
    border-radius: 6px;
    box-sizing: border-box;
  }
}

.xlsaverecepybuttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlsaverecepybuttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlsaverecepybuttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlsaverecepybuttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xlsaverecepybuttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
}

.xlsaverecepyButton10, .xlsaverecepyButton11 {
  width: 130px;
  height: 30px;
  border: none;
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  cursor: pointer;
  background-color: var(--rgbaVal);
}

.xlsaverecepyButton11 {
  background-color: rgb(229, 64, 68);
  color: white;
  cursor: pointer;
}

.xlbuttonsaverecepyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.xlButtonSaveRecepy {
  width: 100px;
  height: 30px;
  border: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  border-radius: 6px;
  margin-right: 5%;
  margin-top: 4px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xladdingredientsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xladdingredientsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xladdingredientsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xladdingredientsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xladdingredientsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.xlAddIngredientHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  margin-top: 5px;
  white-space: nowrap;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlAddIngredientHeader {
    width: 89%;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlAddIngredientHeader {
    padding-left: 5px;
    width: 89%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlAddIngredientHeader {
    padding-left: 5px;
    width: 89%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlAddIngredientHeader {
    padding-left: 5px;
    width: 69%;
  }
}
@media only screen and (min-width:2303px) {
  .xlAddIngredientHeader {
    padding-left: 5px;
    width: 69%;
    font-size: 16px;
  }
}

.xladdingredientWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlAddIngredient {
    display: grid;
    grid-template-columns: 22% 78%;
    gap: 6px;
    grid-auto-rows: 33px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    width: 90%;
    height: 20svh;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 640px) {
  .xlAddIngredient {
    height: 28svh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 641px) and (max-height: 740px) {
  .xlAddIngredient {
    height: 24svh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 741px) and (max-height: 800px) {
  .xlAddIngredient {
    height: 28svh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 361px) and (max-width: 384px) {
  .xlAddIngredient {
    height: 23svh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 385px) and (max-width: 413px) {
  .xlAddIngredient {
    height: 25svh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) and (max-width: 480px) {
  .xlAddIngredient {
    height: 23svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlAddIngredient {
    display: grid;
    grid-template-columns: 26% 74%;
    gap: 8px;
    grid-auto-rows: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-top: 10px;
    width: 90%;
    height: 15svh;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    height: 15svh;
    width: 90%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xlAddIngredient {
    height: 23svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1025px) and (max-width: 1169px) {
  .xlAddIngredient {
    height: 20.5svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1200px) {
  .xlAddIngredient {
    height: 23svh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1201px) and (max-width: 1290px) {
  .xlAddIngredient {
    height: 22vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1291px) and (max-width: 1438px) {
  .xlAddIngredient {
    height: 17svh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlAddIngredient {
    display: grid;
    grid-template-columns: 33% 67%;
    gap: 8px;
    grid-auto-rows: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    width: 90%;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    height: 18svh;
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlAddIngredient {
    display: grid;
    grid-template-columns: 38% 62%;
    gap: 8px;
    grid-auto-rows: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    width: 90%;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    height: 16svh;
    width: 70%;
  }
}
@media only screen and (min-width:2303px) {
  .xlAddIngredient {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 8px;
    grid-auto-rows: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    height: 15svh;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    height: 12svh;
    width: 70%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlAddIngredientName,
  .xlAddPrice {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    background-color: transparent;
    align-self: end;
    justify-self: end;
    padding-bottom: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlAddIngredientName,
  .xlAddPrice {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    background-color: transparent;
    align-self: end;
    justify-self: end;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlAddIngredientName,
  .xlAddPrice {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    background-color: transparent;
    align-self: end;
    justify-self: end;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlAddIngredientName,
  .xlAddPrice {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    background-color: transparent;
    align-self: end;
    justify-self: end;
  }
}
@media only screen and (min-width:2303px) {
  .xlAddIngredientName,
  .xlAddPrice {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    background-color: transparent;
    align-self: end;
    justify-self: end;
  }
}

.xlSupplier {
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  align-self: end;
  justify-self: end;
}

.xlAddIngredientName {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.xlSupplier {
  margin-top: 5px;
}

.xlAddCategory {
  background-color: transparent;
  color: black;
  align-self: end;
  justify-self: end;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlInputAddIngredient {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlInputAddIngredient {
    display: flex;
    width: 70%;
    height: 30px;
    border: 1px solid;
    box-sizing: border-box;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlInputAddIngredient {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlInputAddIngredient {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xlInputAddIngredient {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}

.xlButtonAddIngredient,
.xlButtonAddIngredient2 {
  width: 120px;
  height: 30px;
  border: none;
  color: black;
  font-size: 12px;
  border-radius: 6px;
  margin-bottom: 1px;
  margin-top: 2px;
}

.xlButtonAddIngredient {
  background-color: rgb(229, 64, 68);
  cursor: pointer;
  color: white;
}

.xlButtonAddIngredient2 {
  background-color: var(--rgbaVal);
  color: black;
  cursor: pointer;
  opacity: 0.8;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlInputAddPris {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlInputAddPris {
    display: flex;
    width: 70%;
    height: 25px;
    border: 1px solid;
    box-sizing: border-box;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlInputAddPris {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlInputAddPris {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xlInputAddPris {
    display: flex;
    width: 195px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlSelectAddIngredient {
    width: 200px;
    height: 30px;
    border: none;
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    margin-top: 1px;
  }
  .xlSelectAddIngredient option {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlSelectAddIngredient {
    width: 70%;
    height: 30px;
    border: none;
    box-sizing: border-box;
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
  .xlSelectAddIngredient option {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlSelectAddIngredient {
    width: 200px;
    height: 30px;
    border: none;
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
  .xlSelectAddIngredient option {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlSelectAddIngredient {
    width: 200px;
    height: 30px;
    border: none;
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
  .xlSelectAddIngredient option {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xlSelectAddIngredient {
    width: 200px;
    height: 30px;
    border: none;
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    margin-top: 5px;
  }
  .xlSelectAddIngredient option {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlCloseSaveContainer {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlCloseSaveContainer {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlCloseSaveContainer {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlCloseSaveContainer {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xlCloseSaveContainer {
    cursor: pointer;
    margin-left: 45%;
    font-size: 22px;
    color: var(--rgbaVal);
  }
}

.xladdingredientsContainerJAP {
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xladdingredientsContainerJAP {
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xladdingredientsContainerJAP {
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xladdingredientsContainerJAP {
    width: 70%;
  }
}
@media only screen and (min-width:2303px) {
  .xladdingredientsContainerJAP {
    width: 60%;
  }
}

.xlAddIngredientHeaderJAP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  margin-top: 5px;
  margin-left: 3px;
  white-space: nowrap;
  width: 100%;
}

.xlCloseSaveContainerJAP {
  margin-left: 60%;
  font-size: 22px;
  color: var(--rgbaVal);
}

.xlAddIngredientJAP {
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 4px;
  grid-auto-rows: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  margin-top: 5px;
  width: 100%;
  height: 23.5svh;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  border-color: rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlAddIngredientJAP {
    height: 26vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 640px) {
  .xlAddIngredientJAP {
    height: 33vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 375px) and (max-height: 640px) {
  .xlAddIngredientJAP {
    height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 390px) and (max-width: 390px) {
  .xlAddIngredientJAP {
    height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) and (max-width: 393px) {
  .xlAddIngredientJAP {
    height: 31vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlAddIngredientJAP {
    display: grid;
    grid-template-columns: 32% 68%;
    gap: 4px;
    grid-auto-rows: 30px;
    height: 20vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xlAddIngredientJAP {
    height: 26vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) and (max-width: 1090px) {
  .xlAddIngredientJAP {
    height: 25vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) and (max-width: 1120px) {
  .xlAddIngredientJAP {
    height: 25vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1121px) and (max-width: 1200px) {
  .xlAddIngredientJAP {
    height: 25vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1201px) {
  .xlAddIngredientJAP {
    height: 25vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlAddIngredientJAP {
    display: grid;
    grid-template-columns: 35% 65%;
    gap: 4px;
    grid-auto-rows: 30px;
    height: 22vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlAddIngredientJAP {
    display: grid;
    grid-template-columns: 38% 62%;
    gap: 4px;
    grid-auto-rows: 30px;
    height: 18vh;
  }
}
@media only screen and (min-width:2303px) {
  .xlAddIngredientJAP {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 4px;
    grid-auto-rows: 30px;
    height: 14vh;
  }
}

.xlAddIngredientNameJAP,
.xlAddPriceJAP,
.xlAddCategoryJAP {
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  align-self: end;
  justify-self: end;
}

.xlAddIngredientNameJAP {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.xlInputAddIngredientJAP {
  display: flex;
  width: 195px;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: white;
  color: black;
  font-family: var(--font-family);
  font-size: 16px;
  border-radius: 3px;
  outline: none;
  resize: none;
  margin-top: 5px;
}

.xlInputAddFuriganaJAP {
  display: flex;
  width: 195px;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: white;
  color: black;
  font-family: var(--font-family);
  font-size: 16px;
  border-radius: 3px;
  outline: none;
  resize: none;
  margin-top: 5px;
}

.xlSelectAddIngredientJAP {
  width: 200px;
  height: 30px;
  border: none;
  background-color: white;
  color: black;
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 3px;
  padding: 1px;
  outline: none;
  resize: none;
  margin-top: 5px;
}
.xlSelectAddIngredientJAP option {
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
}

.xlInputAddPrisJAP {
  display: flex;
  width: 195px;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: white;
  color: black;
  font-family: var(--font-family);
  font-size: 16px;
  border-radius: 3px;
  outline: none;
  resize: none;
  margin-top: 5px;
}

.xlButtonAddIngredientJAP, .xlButtonAddIngredientJAP2 {
  width: 120px;
  height: 30px;
  border: none;
  border-color: rgb(223, 212, 212);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-bottom: 1px;
  margin-top: 2px;
  background-color: var(--rgbaVal);
  opacity: 0.8;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlButtonAddIngredientJAP, .xlButtonAddIngredientJAP2 {
    margin-top: 7px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlButtonAddIngredientJAP, .xlButtonAddIngredientJAP2 {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlButtonAddIngredientJAP, .xlButtonAddIngredientJAP2 {
    margin-top: 7px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlButtonAddIngredientJAP, .xlButtonAddIngredientJAP2 {
    margin-top: 7px;
  }
}
@media only screen and (min-width:2303px) {
  .xlButtonAddIngredientJAP, .xlButtonAddIngredientJAP2 {
    margin-top: 7px;
  }
}

.xlButtonAddIngredientJAP2 {
  background-color: rgb(229, 64, 68);
  color: white;
  cursor: not-allowed;
  opacity: 1;
}

.xlSaveMenuContainerJapan {
  width: 90%;
  height: 22svh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
}

.xlSaveNameContainerJapan {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 5px;
}

.xlInputSaveRecepyNameJapan,
.xlInputSaveFuriganaNameJapan {
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  width: 280px;
  height: 25px;
  border-radius: 3px;
}

.xlInputSaveFuriganaNameJapan {
  margin-top: 2px;
}

.xlSaveRecepySelectJapan {
  width: 285px;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  font-family: var(--font-family);
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 3px;
  border-radius: 3px;
  background-color: white;
}

.xlsaverecepybuttonContainerJap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xlsaverecepyButton10Japan,
.xlsaverecepyButton11Japan {
  width: 130px;
  height: 30px;
  border: none;
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  cursor: pointer;
  background-color: var(--rgbaVal);
}

.xlsaverecepyButton10Japan {
  background-color: var(--rgbaVal);
  opacity: 0.8;
}

.xlsaverecepyButton11Japan {
  background-color: rgb(229, 64, 68);
  opacity: 1;
  color: white;
}

.xlOutlinePlusCircle {
  color: var(--rgbaVal);
  font-size: 45px;
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xllastSearch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xllastSearch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xllastSearch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xllastSearch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xllastSearch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xllastSearchtext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xllastSearchtext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xllastSearchtext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xllastSearchtext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xllastSearchtext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    padding: 5px;
  }
}

.xllastSearchtext {
  margin-top: 2px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.xlbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.xlspinner-text {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  font-size: 8px;
  font-family: var(--font-family);
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--rgbaVal);
  padding: 3px;
}

.xlspinner-text1 {
  position: absolute;
  top: 50vh;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 50%;
  height: 20vh;
  font-size: 18px;
  font-family: var(--font-family);
  background-color: white;
  border-radius: 4px;
  border: 2px solid var(--rgbaVal);
  padding: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlspinner-text1 {
    width: 90%;
    height: 23vh;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlspinner-text1 {
    width: 50%;
    height: 20vh;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlspinner-text1 {
    width: 30%;
    height: 20vh;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlspinner-text1 {
    width: 30%;
    height: 20vh;
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .xlspinner-text1 {
    width: 30%;
    height: 20vh;
    font-size: 20px;
  }
}

.xlaialertTitle, .xlaialertTitleJAP {
  font-size: 24px;
  font-family: var(--font-family);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlaialertTitle, .xlaialertTitleJAP {
    font-size: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlaialertTitle, .xlaialertTitleJAP {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlaialertTitle, .xlaialertTitleJAP {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlaialertTitle, .xlaialertTitleJAP {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .xlaialertTitle, .xlaialertTitleJAP {
    font-size: 24px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlaialertTitleJAP {
    font-size: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlaialertTitleJAP {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlaialertTitleJAP {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlaialertTitleJAP {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .xlaialertTitleJAP {
    font-size: 24px;
  }
}

.xlaialertMessage, .xlaialertMessageJAP {
  font-size: 14px;
  font-family: var(--font-family);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlaialertMessage, .xlaialertMessageJAP {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlaialertMessage, .xlaialertMessageJAP {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlaialertMessage, .xlaialertMessageJAP {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlaialertMessage, .xlaialertMessageJAP {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xlaialertMessage, .xlaialertMessageJAP {
    font-size: 18px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xlaialertMessageJAP {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xlaialertMessageJAP {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xlaialertMessageJAP {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xlaialertMessageJAP {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xlaialertMessageJAP {
    font-size: 18px;
  }
}

.xlaialertCloseButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 30px;
  background-color: rgb(229, 64, 68);
  border-radius: 6px;
  padding: 20px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}/*# sourceMappingURL=CreateRecepyNew3.css.map */