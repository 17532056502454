@import '../style/MainStyles.scss';/* styles.css */



.xxaHeaderSpinnerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    transform: scale(0.8);
}

.xxaselectTop {
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    width:100%;
  //  border-radius:map-get($generalStyles,sixBorderRadius);
}

// .xxaselectFieldset {
//     border-radius: 6px;
//     background-color: white;
//     color: map-get($generalStyles, 'colorblack');
//     border: 1px solid;
//     border-color: map-get($generalStyles, 'datalistitemColor');
//     outline: none;
//    // margin-top: 10px;
//    // margin-left: 15px;
// }

// .xxaselectLegend {
//     font-family: map-get($generalStyles, 'fontFamily');
//     font-size: 12px;
//     color: map-get($generalStyles, 'colorblack');
// }

.xxaselectMenuSelect {
    background-color: white;
    color: map-get($generalStyles,colorblack);
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    border-radius: 6px;
    outline: none;
    width:55%;
    font-family:var(--font-family);
    height:30px;
    margin-left: 4vw;
    // align-self: center;

}


.xxauserPicture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 1px;
    margin-left: 3px;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
}


//   .xxalogo{
//     font-family: 'Lobster', cursive;
//     font-size: 30px;
//     margin-left: 15px;
//       }

.xxalogoWrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    

}
.xxalogo{
        font-family: 'Lobster', cursive;
        font-size: 30px;
        text-decoration: none;
        color:black;
         margin-left:3px;
        //margin-top: 5px;

        
}

.xxaimageWrapper{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items: center;
    width:15%;
}

.xxaheaderAccountContainer{
display:flex;
flex-direction: column;
justify-content:center;
align-items:center;
width:100%;
}

.xxahamburgerButton {
    position: absolute;
    top: 2px;
    right:2px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000; /* Ensure the button is above other content */
    color:white;
    transition: color 0.3s ease; /* Smooth transition for color change */
}
.xxahamburgerButton.open {
    color: black;
}

.xxaslidingMenu {
    position: fixed;
    top: 0;
    right: -300px; /* Adjust width as needed to ensure it is completely off-screen */
    width: 250px;
    height:100vh;
    background-color:white;
    box-shadow: -2px 0 15px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 999; /* Ensure the menu is above other content */
    border-radius:3px;
}

.xxaslidingMenu.open {
    right: 0;
    height:97vh;
    max-height:97vh;
    overflow-y:auto;
      //webkit -scrollbar
  &::-webkit-scrollbar {
    width:5px;
    //webkit -scrollbar-thumb
    &-thumb {
      background-color: map-get($generalStyles, colorblack);
      border-radius: 5px;
    }
    // handle
    &-track {
      background: map-get($generalStyles, colorblack);
    }

  }

}

.xxaamenupersonal, .xxaamenupersonal1 {
    margin: 3px 0;
    padding: 3px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: transform 0.2s ease, background-color 0.2s ease; 


    a {
        color: inherit; /* Ensure links inherit the button's color */
        text-decoration: none; /* Remove underline from links */
    }

    // &:active {
    //     background-color:white; /* Change background color when clicked */
    //     color: #000; /* Change text color when clicked */
    //     font-size:18px;
    // }
   
    &:active {
      transform: scale(1.1); // Slightly increase the size of the button
      background-color: rgb(243, 216, 187); // Lighten the background color on click
    }
    &:hover{
      background-color:rgb(77, 43, 29);
      color:white;
      border-radius:3px;
      width: 80%;
    }



}

.xxaamenupersonal1 {
    font-weight: bold;
}

.xxasearchSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xxasearchSpinner {
    color: map-get($generalStyles, colorblack);
}