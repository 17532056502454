:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
html {
  scrollbar-width: thin; /* For Firefox */
  overflow: overlay; /* For Chrome, Safari */
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xetotalArea {
    display: grid;
    grid-template-rows: 5% 3% 17% 28.5% 43.5%;
    grid-template-areas: "a" "b" "c" "d" "e";
    width: 100%;
    height: 710px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xetotalArea {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xetotalArea {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xetotalArea {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xetotalArea {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xetotalArea1 {
    display: grid;
    grid-template-rows: 5% 3% 17% 28.5% 43.5%;
    grid-template-areas: "a" "b" "c" "d" "e";
    width: 100%;
    height: 710px;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xetotalArea1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xetotalArea1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xetotalArea1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width:2303px) {
  .xetotalArea1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: rgb(245, 245, 237);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2px;
    margin-left: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2px;
    margin-left: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2px;
    margin-left: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xeheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2px;
    margin-left: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xecompName, .xecompName1 {
    font-family: "Lobster";
    font-size: 26px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xecompName, .xecompName1 {
    font-family: "Lobster";
    font-size: 26px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xecompName, .xecompName1 {
    font-family: "Lobster";
    font-size: 40px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xecompName, .xecompName1 {
    font-family: "Lobster";
    font-size: 40px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xecompName, .xecompName1 {
    font-family: "Lobster";
    font-size: 50px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xecompNameJAP {
    font-family: var(--font-family);
    font-size: 40px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xecompNameJAP {
    font-family: var(--font-family);
    font-size: 40px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xecompNameJAP {
    font-family: var(--font-family);
    font-size: 40px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xecompNameJAP {
    font-family: var(--font-family);
    font-size: 40px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xecompNameJAP {
    font-family: var(--font-family);
    font-size: 50px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xecompNameJAP1 {
    font-family: var(--font-family);
    font-size: 40px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xecompNameJAP1 {
    font-family: var(--font-family);
    font-size: 40px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xecompNameJAP1 {
    font-family: var(--font-family);
    font-size: 40px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xecompNameJAP1 {
    font-family: var(--font-family);
    font-size: 40px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xecompNameJAP1 {
    font-family: var(--font-family);
    font-size: 50px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xecompName1 {
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xecompName1 {
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xecompName1 {
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xecompName1 {
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xecompName1 {
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xerangeArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-area: b;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xerangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xerangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xerangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .xerangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xerange1 {
    accent-color: blue;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xerange1 {
    accent-color: blue;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xerange1 {
    accent-color: blue;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xerange1 {
    accent-color: blue;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xerange1 {
    accent-color: blue;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xerange2 {
    accent-color: rgb(251, 239, 132);
    margin-right: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xerange2 {
    accent-color: rgb(251, 239, 132);
    margin-right: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xerange2 {
    accent-color: rgb(251, 239, 132);
    margin-right: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xerange2 {
    accent-color: rgb(251, 239, 132);
    margin-right: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xerange2 {
    accent-color: rgb(251, 239, 132);
    margin-right: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeareaA, .xeareaC, .xeareaB {
    margin-top: 5px;
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeareaA, .xeareaC, .xeareaB {
    width: 100%;
    height: 76vh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeareaA, .xeareaC, .xeareaB {
    width: 100%;
    height: 80vh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeareaA, .xeareaC, .xeareaB {
    width: 100%;
    height: 80vh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--val);
  }
}
@media only screen and (min-width:2303px) {
  .xeareaA, .xeareaC, .xeareaB {
    width: 100%;
    height: 80vh;
    margin-top: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--val);
  }
}

.xeareaMenuButton {
  grid-area: c;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeareaB {
    grid-area: d;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeareaB {
    grid-area: d;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeareaB {
    grid-area: d;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeareaB {
    grid-area: d;
  }
}
@media only screen and (min-width:2303px) {
  .xeareaB {
    grid-area: d;
  }
}

.xeareaC {
  grid-area: e;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeareaC {
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeareaC {
    width: 95%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeareaC {
    width: 95%;
  }
}
@media only screen and (min-width:2303px) {
  .xeareaC {
    width: 95%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredSpinnerContainer {
    position: absolute;
    top: 368px;
    left: 170px;
    background-color: transparent;
    transform: scale(2.5);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xeingredSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xeingredSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xeingredSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xeingredSpinnerContainer.active {
    z-index: 9999;
  }
}

.hrsettings {
  width: 350px;
  height: 2px;
  background-color: transparent;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredmenubuttonsTop {
    grid-area: a;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredmenubuttonsTop {
    grid-area: a;
    margin-top: 20%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredmenubuttonsTop {
    grid-area: a;
    margin-top: 20%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredmenubuttonsTop {
    grid-area: a;
    margin-top: 20%;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredmenubuttonsTop {
    grid-area: a;
    margin-top: 20%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredmenubuttonsData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredmenubuttonsData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredmenubuttonsData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredmenubuttonsData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredmenubuttonsData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xecommonButtonStyle, .xemenuactivebuttonStyle, .xemenubuttonStyle {
    width: calc(33% - 3px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2px;
    height: 30px;
    border-radius: 6px;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 376px) {
  .xecommonButtonStyle, .xemenuactivebuttonStyle, .xemenubuttonStyle {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xecommonButtonStyle, .xemenuactivebuttonStyle, .xemenubuttonStyle {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2px;
    height: 30px;
    border-radius: 6px;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xecommonButtonStyle, .xemenuactivebuttonStyle, .xemenubuttonStyle {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2px;
    height: 30px;
    border-radius: 6px;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xecommonButtonStyle, .xemenuactivebuttonStyle, .xemenubuttonStyle {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2px;
    height: 45px;
    border-radius: 6px;
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
  }
}
@media only screen and (min-width:2303px) {
  .xecommonButtonStyle, .xemenuactivebuttonStyle, .xemenubuttonStyle {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2px;
    height: 50px;
    border-radius: 6px;
    color: black;
    font-family: var(--font-family);
    font-size: 20px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xemenubuttonStyle {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xemenubuttonStyle {
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xemenubuttonStyle {
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xemenubuttonStyle {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xemenubuttonStyle {
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xemenuactivebuttonStyle {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xemenuactivebuttonStyle {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xemenuactivebuttonStyle {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xemenuactivebuttonStyle {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width:2303px) {
  .xemenuactivebuttonStyle {
    background-color: var(--rgbaVal2);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeareaKategoriSelect {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeareaKategoriSelect {
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeareaKategoriSelect {
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeareaKategoriSelect {
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xeareaKategoriSelect {
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xekatfieldset {
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xekatfieldset {
    margin-top: 10px;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xekatfieldset {
    margin-top: 10px;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xekatfieldset {
    margin-top: 20px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xekatfieldset {
    margin-top: 20px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xekatlegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xekatlegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xekatlegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xekatlegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    margin-left: 10%;
  }
}
@media only screen and (min-width:2303px) {
  .xekatlegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    margin-left: 10%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xekategoriselect {
    width: 100%;
    height: 30px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xekategoriselect {
    width: 100%;
    height: 30px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xekategoriselect {
    width: 100%;
    height: 30px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xekategoriselect {
    width: 80%;
    height: 40px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .xekategoriselect {
    width: 80%;
    height: 40px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
    font-family: var(--font-family);
    font-size: 16px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axeconnRecepyFieldset {
    border: none;
    margin-top: 10px;
    margin-left: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axeconnRecepyFieldset {
    border: none;
    margin-top: 15px;
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axeconnRecepyFieldset {
    border: none;
    margin-top: 20px;
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axeconnRecepyFieldset {
    border: none;
    margin-top: 25px;
    margin-left: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .axeconnRecepyFieldset {
    border: none;
    margin-top: 25px;
    margin-left: 12px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axeconnRecepyLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axeconnRecepyLegend {
    width: 75%;
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axeconnRecepyLegend {
    width: 75%;
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axeconnRecepyLegend {
    width: 85%;
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width:2303px) {
  .axeconnRecepyLegend {
    width: 85%;
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axedeleteIngredientTop {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axedeleteIngredientTop {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axedeleteIngredientTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axedeleteIngredientTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .axedeleteIngredientTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xesimpleButton {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xesimpleButton {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xesimpleButton {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 6px;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xesimpleButton {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 10px;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xesimpleButton {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 8px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 12px;
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xesimpleButton1 {
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xesimpleButton1 {
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xesimpleButton1 {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xesimpleButton1 {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 10px;
    font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xesimpleButton1 {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 8px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 12px;
    font-family: var(--font-family);
    font-size: 18px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredientListTop {
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredientListTop {
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredientListTop {
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientListTop {
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredientListTop {
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axeingredientListTop {
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axeingredientListTop {
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axeingredientListTop {
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .axeingredientListTop {
    margin-top: 5px;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axbingredientListTtop {
    margin-top: 5px;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axeingredientListData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 120px;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axeingredientListData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 70vh;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
  .axeingredientListData::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axeingredientListData::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axeingredientListData::-webkit-scrollbar-thumb {
    background: white; /* Adjust as needed */
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axeingredientListData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 70vh;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
  .axeingredientListData::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axeingredientListData::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axeingredientListData::-webkit-scrollbar-thumb {
    background: white; /* Adjust as needed */
  }
}
@media only screen and (min-width:2303px) {
  .axeingredientListData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 70vh;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
  .axeingredientListData::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axeingredientListData::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axeingredientListData::-webkit-scrollbar-thumb {
    background: white; /* Adjust as needed */
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredientListData3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 110px;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredientListData3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 65vh;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
  .xeingredientListData3::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xeingredientListData3::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredientListData3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 0px;
    max-height: 65vh;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
  .xeingredientListData3::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredientListData3::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredientListData3::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientListData3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 0px;
    max-height: 65vh;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
  .xeingredientListData3::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredientListData3::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredientListData3::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredientListData3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 0px;
    max-height: 65vh;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
  .xeingredientListData3::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredientListData3::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredientListData3::-webkit-scrollbar-thumb {
    background: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredientListData, .axbingredientListData2, .xeingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 110px;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredientListData, .axbingredientListData2, .xeingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 110px;
    overflow-y: scroll;
    width: 98%;
    margin-left: 1vh;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredientListData, .axbingredientListData2, .xeingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 110px;
    overflow-y: scroll;
    width: 100%;
    margin-left: 1vh;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientListData, .axbingredientListData2, .xeingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 110px;
    overflow-y: scroll;
    width: 100%;
    margin-left: 1vh;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredientListData, .axbingredientListData2, .xeingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3px;
    height: auto;
    min-height: 10px;
    max-height: 110px;
    overflow-y: scroll;
    width: 100%;
    margin-left: 1vh;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredientListData2 {
    margin-top: 10px;
    min-height: 5px;
    max-height: 110px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredientListData2 {
    margin-top: 10px;
    min-height: 5px;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .xeingredientListData2::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredientListData2::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredientListData2::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredientListData2 {
    width: 98%;
    margin-top: 10px;
    min-height: 5px;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .xeingredientListData2::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredientListData2::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredientListData2::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 98%;
    margin-top: 10px;
    min-height: 5px;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .xeingredientListData2::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredientListData2::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredientListData2::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 98%;
    margin-top: 10px;
    min-height: 5px;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .xeingredientListData2::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredientListData2::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredientListData2::-webkit-scrollbar-thumb {
    background: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axbingredientListData2 {
    margin-top: 1px;
    max-height: 120px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axbingredientListData2 {
    margin-top: 1px;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .axbingredientListData2::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .axbingredientListData2::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axbingredientListData2 {
    width: 95%;
    margin-top: 1px;
    min-height: 5px;
    max-height: 55vh;
    overflow-y: scroll;
  }
  .axbingredientListData2::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axbingredientListData2::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axbingredientListData2::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axbingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    margin-top: 1px;
    margin-left: 2%;
    min-height: 5px;
    max-height: 55vh;
    overflow-y: scroll;
  }
  .axbingredientListData2::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axbingredientListData2::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axbingredientListData2::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .axbingredientListData2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    margin-top: 1px;
    margin-left: 2%;
    min-height: 5px;
    max-height: 55vh;
    overflow-y: scroll;
  }
  .axbingredientListData2::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axbingredientListData2::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axbingredientListData2::-webkit-scrollbar-thumb {
    background: white;
  }
}

.xeingredNew1 {
  height: 0;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredNew1 {
    transform: rotate(0deg);
    animation-name: xeingredientmobile;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredNew1 {
    transform: rotate(0deg);
    animation-name: xeingredienttablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredNew1 {
    transform: rotate(0deg);
    animation-name: xeingredientlaptop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredNew1 {
    transform: rotate(0deg);
    animation-name: xeingredientdesktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredNew1 {
    transform: rotate(0deg);
    animation-name: xeingredientLargedesktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes xeingredientmobile {
  0% {
    height: 0px;
  }
  100% {
    height: 115px;
  }
}
@keyframes xeingredienttablet {
  0% {
    height: 0px;
  }
  100% {
    height: 100px;
  }
}
@keyframes xeingredientlaptop {
  0% {
    height: 0px;
  }
  100% {
    height: 120px;
  }
}
@keyframes xeingredientdesktop {
  0% {
    height: 0px;
  }
  100% {
    height: 135px;
  }
}
@keyframes xeingredientLargedesktop {
  0% {
    height: 0px;
  }
  100% {
    height: 112px;
  }
}
.ingredNew {
  transform: rotate(0deg);
  animation-name: ingredientNew;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes ingredientNew {
  0% {
    height: 0px;
  }
  100% {
    height: 102px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredNew2 {
    transform: rotate(0deg);
    animation-name: xeingredientNew62mobile;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredNew2 {
    transform: rotate(0deg);
    animation-name: xeingredientNew62tablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredNew2 {
    transform: rotate(0deg);
    animation-name: xeingredientNew62laptop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredNew2 {
    transform: rotate(0deg);
    animation-name: xeingredientNew62desktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredNew2 {
    transform: rotate(0deg);
    animation-name: xeingredientNew62Largedesktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes xeingredientNew62mobile {
  0% {
    height: 0px;
  }
  100% {
    height: 158px;
  }
}
@keyframes xeingredientNew62tablet {
  0% {
    height: 0px;
  }
  100% {
    height: 138px;
  }
}
@keyframes xeingredientNew62laptop {
  0% {
    height: 0px;
  }
  100% {
    height: 138px;
  }
}
@keyframes xeingredientNew62desktop {
  0% {
    height: 0px;
  }
  100% {
    height: 138px;
  }
}
@keyframes xeingredientNew62Largedesktop {
  0% {
    height: 0px;
  }
  100% {
    height: 154px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientBox1 {
    width: 100%;
    height: 14vh;
    border-radius: 3px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientBox1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 10vh;
    border-radius: 3px;
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientBox1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 10vh;
    border-radius: 3px;
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientBox1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 11vh;
    border-radius: 3px;
    margin-top: 5px;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientBox1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 11vh;
    border-radius: 3px;
    margin-top: 5px;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientInput {
    width: 100%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientInput {
    width: 90%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientInput {
    width: 98%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientInput {
    width: 98%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientInput {
    width: 98%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxeeditIngredientFieldset1 {
    margin-top: 1px;
    width: 200px;
    height: 12vh;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxeeditIngredientFieldset1 {
    margin-top: 1px;
    width: 100%;
    height: 10vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxeeditIngredientFieldset1 {
    margin-top: 1px;
    width: 98%;
    height: 10vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxeeditIngredientFieldset1 {
    margin-top: 1px;
    width: 100%;
    height: 10vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xxeeditIngredientFieldset1 {
    margin-top: 1px;
    width: 100%;
    height: 6vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeEditIngredientButton, .editIngredientButton1, .editIngredientButton {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeEditIngredientButton, .editIngredientButton1, .editIngredientButton {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeEditIngredientButton, .editIngredientButton1, .editIngredientButton {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeEditIngredientButton, .editIngredientButton1, .editIngredientButton {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xeEditIngredientButton, .editIngredientButton1, .editIngredientButton {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xebuttonplacer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xebuttonplacer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xebuttonplacer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media only screen and (min-width:2303px) {
  .xebuttonplacer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .editIngredientButton {
    margin-left: 53%;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .editIngredientButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .editIngredientButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .editIngredientButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .editIngredientButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .editIngredientButton1 {
    margin-left: 53%;
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .editIngredientButton1 {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .editIngredientButton1 {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .editIngredientButton1 {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .editIngredientButton1 {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientHeaderStyle, .xeheaderUnit1, .xeheaderQuantity1, .xeheaderNutrient1 {
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientHeaderStyle, .xeheaderUnit1, .xeheaderQuantity1, .xeheaderNutrient1 {
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientHeaderStyle, .xeheaderUnit1, .xeheaderQuantity1, .xeheaderNutrient1 {
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientHeaderStyle, .xeheaderUnit1, .xeheaderQuantity1, .xeheaderNutrient1 {
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientHeaderStyle, .xeheaderUnit1, .xeheaderQuantity1, .xeheaderNutrient1 {
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeheaderNutrient1 {
    width: 60%;
    height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeheaderNutrient1 {
    width: 60%;
    height: 25px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeheaderNutrient1 {
    width: 60%;
    height: 25px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeheaderNutrient1 {
    width: 60%;
    height: 25px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xeheaderNutrient1 {
    width: 60%;
    height: 25px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeheaderQuantity1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeheaderQuantity1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeheaderQuantity1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeheaderQuantity1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xeheaderQuantity1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeheaderUnit1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeheaderUnit1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeheaderUnit1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeheaderUnit1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xeheaderUnit1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    cursor: pointer;
  }
}

.xedataTopStart {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xedataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    width: 90%;
    margin-top: 1px;
    min-height: 5px;
    max-height: 22vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .xedataTop::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xedataTop::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xedataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    width: 90%;
    margin-top: 1px;
    min-height: 5px;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .xedataTop::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xedataTop::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xedataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    width: 90%;
    margin-top: 1px;
    min-height: 5px;
    max-height: 65vh;
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: pointer;
  }
  .xedataTop::-webkit-scrollbar {
    width: 5px; /* Adjust as needed */
    cursor: pointer;
  }
  .xedataTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xedataTop::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xedataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    width: 90%;
    margin-top: 1px;
    min-height: 5px;
    max-height: 65vh;
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: pointer;
  }
  .xedataTop::-webkit-scrollbar {
    width: 5px; /* Adjust as needed */
    cursor: pointer;
  }
  .xedataTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xedataTop::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .xedataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    width: 90%;
    margin-top: 1px;
    min-height: 5px;
    max-height: 65vh;
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: pointer;
  }
  .xedataTop::-webkit-scrollbar {
    width: 5px; /* Adjust as needed */
    cursor: pointer;
  }
  .xedataTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xedataTop::-webkit-scrollbar-thumb {
    background: white;
  }
}

.xefoodrowsarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientValue2 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientValue2 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientValue2 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientValue2 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientValue2 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientValue3 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal2);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientValue3 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal2);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientValue3 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal2);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientValue3 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal2);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientValue3 {
    width: 60%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: var(--rgbaVal2);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.xenutrientQuantity2 {
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientQuantity2 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientQuantity2 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientQuantity2 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientQuantity2 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientQuantity2 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
  }
}

.xenutrientQuantity3 {
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientQuantity3 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientQuantity3 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientQuantity3 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: white;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientQuantity3 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientQuantity3 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    margin-top: 2px;
    text-align: left;
  }
}

.xenutrientUnit2 {
  background-color: var(--rgbaVal);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientUnit2 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientUnit2 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-top: 2px;
    text-align: left;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientUnit2 {
    width: calc(20% - 5px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientUnit2 {
    width: calc(20% - 5px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientUnit2 {
    width: calc(20% - 5px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-top: 2px;
    text-align: left;
  }
}

.xenutrientUnit3 {
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientUnit3 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientUnit3 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    color: black;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientUnit3 {
    width: calc(20% - 5px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientUnit3 {
    width: calc(20% - 5px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientUnit3 {
    width: calc(20% - 5px);
    margin-left: 2px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-top: 2px;
    text-align: left;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axxeDeleteMessageButton, .axxedeleteMessageButton6, .axxedeleteMessageButton5 {
    width: 59%;
    height: 40px;
    border: 1px solid;
    margin-left: 1%;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axxeDeleteMessageButton, .axxedeleteMessageButton6, .axxedeleteMessageButton5 {
    width: 59%;
    height: 40px;
    border: 1px solid;
    margin-left: 2%;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axxeDeleteMessageButton, .axxedeleteMessageButton6, .axxedeleteMessageButton5 {
    width: 59%;
    height: 40px;
    border: 1px solid;
    margin-left: 2%;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axxeDeleteMessageButton, .axxedeleteMessageButton6, .axxedeleteMessageButton5 {
    width: 59%;
    height: 40px;
    border: 1px solid;
    margin-left: 2%;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .axxeDeleteMessageButton, .axxedeleteMessageButton6, .axxedeleteMessageButton5 {
    width: 59%;
    height: 40px;
    border: 1px solid;
    margin-left: 2%;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axxedeleteMessageButton5 {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axxedeleteMessageButton5 {
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axxedeleteMessageButton5 {
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axxedeleteMessageButton5 {
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .axxedeleteMessageButton5 {
    background-color: var(--rgbaVal);
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axxedeleteMessageButton6 {
    background-color: white;
    border-color: none;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axxedeleteMessageButton6 {
    background-color: white;
    border-color: none;
    text-align: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axxedeleteMessageButton6 {
    background-color: white;
    border-color: none;
    text-align: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axxedeleteMessageButton6 {
    background-color: white;
    border-color: none;
    text-align: center;
  }
}
@media only screen and (min-width:2303px) {
  .axxedeleteMessageButton6 {
    background-color: white;
    border-color: none;
    text-align: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axxeDeleteButton, .axxeingredDeleteButton3, .axxeingredDeleteButton4 {
    margin-left: 10px;
    width: 39%;
    height: 40px;
    margin-right: 1%;
    border-radius: 6px;
    border: none;
    outline: none;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axxeDeleteButton, .axxeingredDeleteButton3, .axxeingredDeleteButton4 {
    margin-left: 4px;
    width: 41%;
    height: 40px;
    margin-right: 1%;
    border-radius: 6px;
    border: none;
    outline: none;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axxeDeleteButton, .axxeingredDeleteButton3, .axxeingredDeleteButton4 {
    margin-left: 4px;
    width: 41%;
    height: 40px;
    margin-right: 1%;
    border-radius: 6px;
    border: none;
    outline: none;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axxeDeleteButton, .axxeingredDeleteButton3, .axxeingredDeleteButton4 {
    margin-left: 4px;
    width: 41%;
    height: 40px;
    margin-right: 1%;
    border-radius: 6px;
    border: none;
    outline: none;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .axxeDeleteButton, .axxeingredDeleteButton3, .axxeingredDeleteButton4 {
    margin-left: 4px;
    width: 41%;
    height: 40px;
    margin-right: 1%;
    border-radius: 6px;
    border: none;
    outline: none;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axxeingredDeleteButton4 {
    background-color: rgb(229, 64, 68);
    border: none;
    opacity: 0.4;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axxeingredDeleteButton4 {
    background-color: rgb(229, 64, 68);
    border: none;
    opacity: 0.4;
    margin-right: 4%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axxeingredDeleteButton4 {
    background-color: rgb(229, 64, 68);
    border: none;
    opacity: 0.4;
    margin-right: 4%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axxeingredDeleteButton4 {
    background-color: rgb(229, 64, 68);
    border: none;
    opacity: 0.4;
  }
}
@media only screen and (min-width:2303px) {
  .axxeingredDeleteButton4 {
    background-color: rgb(229, 64, 68);
    border: none;
    opacity: 0.4;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axxeingredDeleteButton3 {
    background-color: rgb(229, 64, 68);
    opacity: 1;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axxeingredDeleteButton3 {
    background-color: rgb(229, 64, 68);
    opacity: 1;
    margin-right: 4%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axxeingredDeleteButton3 {
    background-color: rgb(229, 64, 68);
    opacity: 1;
    margin-right: 4%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axxeingredDeleteButton3 {
    background-color: rgb(229, 64, 68);
    opacity: 1;
  }
}
@media only screen and (min-width:2303px) {
  .axxeingredDeleteButton3 {
    background-color: rgb(229, 64, 68);
    opacity: 1;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axedeleteArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    width: 99%;
    height: 32px;
    margin-left: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axedeleteArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    width: 99%;
    height: 32px;
    margin-left: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axedeleteArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    width: 99%;
    height: 32px;
    margin-left: 1.5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axedeleteArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    width: 99%;
    height: 32px;
    margin-right: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .axedeleteArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    width: 99%;
    height: 32px;
    margin-right: 1%;
  }
}

.nutrientMessage {
  font-family: var(--font-family);
  font-size: 16px;
  margin-left: 10px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredientAreaData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredientAreaData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredientAreaData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientAreaData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredientAreaData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredientFieldset {
    width: 90%;
    height: 60px;
    padding-bottom: 1px;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredientFieldset {
    width: 90%;
    height: 60px;
    padding-bottom: 1px;
    border: none;
    margin-top: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredientFieldset {
    width: 90%;
    height: 60px;
    padding-bottom: 1px;
    border: none;
    margin-top: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredientFieldset {
    width: 90%;
    height: 60px;
    padding-bottom: 1px;
    border: none;
    margin-top: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .ingredientFieldset {
    width: 90%;
    height: 60px;
    padding-bottom: 1px;
    border: none;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredientLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredientLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredientLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredientLegend {
    font-family: var(--font-family);
    margin-left: 5px;
    font-size: 18px;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .ingredientLegend {
    font-family: var(--font-family);
    margin-left: 10%;
    font-size: 22px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredientLegend1 {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredientLegend1 {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredientLegend1 {
    font-family: var(--font-family);
    margin-left: 12px;
    margin-top: 5px;
    font-size: 18px;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .ingredientLegend1 {
    font-family: var(--font-family);
    margin-left: 12%;
    margin-top: 5px;
    font-size: 22px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredientInput {
    width: 95%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredientInput {
    width: 95%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredientInput {
    width: 95%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredientInput {
    width: 95%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .ingredientInput {
    width: 80%;
    height: 35px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .kategoriFieldset {
    width: 95%;
    height: 50px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .kategoriFieldset {
    width: 95%;
    height: 50px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 18px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .kategoriFieldset {
    width: 95%;
    height: 50px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .kategoriFieldset {
    width: 95%;
    height: 50px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .kategoriFieldset {
    width: 95%;
    height: 50px;
    padding-bottom: 2px;
    margin-top: 20px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .kategoriLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .kategoriLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredselect {
    width: 92%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredselect {
    width: 92%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredselect {
    width: 92%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredselect {
    width: 91%;
    height: 30px;
    margin-left: 1%;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .ingredselect {
    width: 76%;
    height: 35px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xe1saveButtonArea {
    align-self: flex-end;
    margin-right: 9%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xe1saveButtonArea {
    align-self: flex-end;
    margin-right: 9%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xe1saveButtonArea {
    align-self: flex-end;
    margin-right: 9%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xe1saveButtonArea {
    align-self: flex-end;
    margin-right: 9.5%;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xe1saveButtonArea {
    align-self: flex-end;
    margin-right: 14.5%;
    margin-top: 15px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xesaveButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xesaveButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xesaveButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xesaveButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xesaveButton {
    width: 170px;
    height: 40px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xesaveButton1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xesaveButton1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xesaveButton1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xesaveButton1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.8;
  }
}
@media only screen and (min-width:2303px) {
  .xesaveButton1 {
    width: 170px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.8;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xerecepyConnTop {
    display: flex;
    width: 95%;
    height: auto;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 1px;
    max-height: 100px;
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xerecepyConnTop {
    display: flex;
    width: 95%;
    height: auto;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 1px;
    max-height: 100px;
    overflow-y: scroll;
  }
  .xerecepyConnTop::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xerecepyConnTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xerecepyConnTop::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xerecepyConnTop {
    display: flex;
    width: 95%;
    height: auto;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 1px;
    max-height: 100px;
    overflow-y: scroll;
  }
  .xerecepyConnTop::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xerecepyConnTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xerecepyConnTop::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xerecepyConnTop {
    display: flex;
    width: 95%;
    height: auto;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 1px;
    min-height: 0px;
    max-height: 100px;
    overflow-y: auto;
  }
  .xerecepyConnTop::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xerecepyConnTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xerecepyConnTop::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .xerecepyConnTop {
    display: flex;
    width: 95%;
    height: auto;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 1px;
    max-height: 100px;
    overflow-y: scroll;
  }
  .xerecepyConnTop::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xerecepyConnTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xerecepyConnTop::-webkit-scrollbar-thumb {
    background: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xerecepyConnData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xerecepyConnData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xerecepyConnData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xerecepyConnData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xerecepyConnData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxeRecepyConnButton, .xe1recepyConnButton1, .xe1recepyConnButton {
    width: auto;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxeRecepyConnButton, .xe1recepyConnButton1, .xe1recepyConnButton {
    width: auto;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxeRecepyConnButton, .xe1recepyConnButton1, .xe1recepyConnButton {
    width: auto;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxeRecepyConnButton, .xe1recepyConnButton1, .xe1recepyConnButton {
    width: auto;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xxeRecepyConnButton, .xe1recepyConnButton1, .xe1recepyConnButton {
    width: auto;
    height: 40px;
    border: none;
    border-radius: 6px;
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xe1recepyConnButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xe1recepyConnButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xe1recepyConnButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xe1recepyConnButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xe1recepyConnButton {
    background-color: var(--rgbaVal);
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xe1recepyConnButton1 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xe1recepyConnButton1 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xe1recepyConnButton1 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xe1recepyConnButton1 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xe1recepyConnButton1 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xerecepyConnFieldset {
    width: 96%;
    height: 100px;
    margin-top: 2px;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xerecepyConnFieldset {
    width: 96%;
    min-height: 1vh;
    height: auto;
    max-height: 10vh;
    margin-top: 2%;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xerecepyConnFieldset {
    width: 96%;
    min-height: 1vh;
    height: auto;
    max-height: 10vh;
    margin-top: 3%;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xerecepyConnFieldset {
    width: 96%;
    min-height: 1vh;
    height: auto;
    max-height: 10vh;
    margin-top: 3%;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xerecepyConnFieldset {
    width: 96%;
    min-height: 1vh;
    height: auto;
    max-height: 10vh;
    margin-top: 3%;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xerecepyConnLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xerecepyConnLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xerecepyConnLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xerecepyConnLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}
@media only screen and (min-width:2303px) {
  .xerecepyConnLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axeingredientListFieldset {
    border: none;
    margin-top: 10px;
    margin-left: 5px;
    height: 41px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axeingredientListFieldset {
    border: none;
    margin-top: 10px;
    margin-left: 5px;
    height: 41px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axeingredientListFieldset {
    border: none;
    margin-top: 10px;
    margin-left: 5px;
    height: 41px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axeingredientListFieldset {
    border: none;
    margin-top: 10px;
    margin-left: 5px;
    height: 41px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width:2303px) {
  .axeingredientListFieldset {
    border: none;
    margin-top: 10px;
    margin-left: 5px;
    height: 41px;
    border: none;
    width: 87%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axeingredientListLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axeingredientListLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axeingredientListLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axeingredientListLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }
}
@media only screen and (min-width:2303px) {
  .axeingredientListLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axeingredientListSelect {
    outline: none;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 98%;
    height: 30px;
    border-radius: 6px;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axeingredientListSelect {
    outline: none;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 98%;
    height: 30px;
    border-radius: 6px;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axeingredientListSelect {
    outline: none;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 98%;
    height: 30px;
    border-radius: 6px;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axeingredientListSelect {
    outline: none;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 98%;
    height: 30px;
    border-radius: 6px;
    background-color: white;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .axeingredientListSelect {
    outline: none;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 98%;
    height: 35px;
    border-radius: 6px;
    background-color: white;
    font-family: var(--font-family);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axechangeIngredientFieldset {
    margin-top: 1px;
    margin-left: 3px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axechangeIngredientFieldset {
    margin-top: 1%;
    margin-left: 3px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axechangeIngredientFieldset {
    margin-top: 1%;
    margin-left: 3px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axechangeIngredientFieldset {
    margin-top: 1%;
    margin-left: 3px;
    border: none;
    width: 87%;
  }
}
@media only screen and (min-width:2303px) {
  .axechangeIngredientFieldset {
    margin-top: 1%;
    margin-left: 3px;
    border: none;
    width: 87%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axechangeIngredientLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axechangeIngredientLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axechangeIngredientLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axechangeIngredientLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .axechangeIngredientLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axechangeIngredientArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 5px;
    margin-left: 1px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    max-height: 60px;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axechangeIngredientArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 5px;
    margin-left: 1px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    max-height: 25vh;
    overflow-y: auto;
  }
  .axechangeIngredientArea::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axechangeIngredientArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axechangeIngredientArea::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axechangeIngredientArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 5px;
    margin-left: 1px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    max-height: 25vh;
    overflow-y: auto;
  }
  .axechangeIngredientArea::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axechangeIngredientArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axechangeIngredientArea::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axechangeIngredientArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 5px;
    margin-left: 1px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    max-height: 25vh;
    overflow-y: auto;
  }
  .axechangeIngredientArea::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axechangeIngredientArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axechangeIngredientArea::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .axechangeIngredientArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 5px;
    margin-left: 1px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    max-height: 25vh;
    overflow-y: auto;
  }
  .axechangeIngredientArea::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .axechangeIngredientArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .axechangeIngredientArea::-webkit-scrollbar-thumb {
    background: white;
  }
}

.axechangeIngredientButton, .axechangeIngredientButton11 {
  width: auto;
  height: 30px;
  border: none;
  border-radius: 6px;
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  outline: none;
}
@media only screen and (min-width:2303px) {
  .axechangeIngredientButton, .axechangeIngredientButton11 {
    height: 40px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axechangeIngredientButton11 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axechangeIngredientButton11 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axechangeIngredientButton11 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axechangeIngredientButton11 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .axechangeIngredientButton11 {
    background-color: var(--rgbaVal2);
    color: black;
  }
}

.axechangeIngredientArea1 {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axechangeIngredientButton1 {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 60%;
    margin-bottom: 4px;
    background-color: rgb(229, 64, 68);
    color: white;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axechangeIngredientButton1 {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: rgb(229, 64, 68);
    color: white;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axechangeIngredientButton1 {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: rgb(229, 64, 68);
    color: white;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axechangeIngredientButton1 {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: rgb(229, 64, 68);
    color: white;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .axechangeIngredientButton1 {
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: rgb(229, 64, 68);
    color: white;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    font-family: var(--font-family);
    font-size: 18px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .axechangeIngredientButtonDisabled {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 60%;
    margin-bottom: 4px;
    background-color: var(--rgbaVal);
    color: black;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    opacity: 0.5;
    cursor: not-allowed;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .axechangeIngredientButtonDisabled {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: var(--rgbaVal);
    color: black;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    opacity: 0.5;
    cursor: not-allowed;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .axechangeIngredientButtonDisabled {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: var(--rgbaVal);
    color: black;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    opacity: 0.5;
    cursor: not-allowed;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .axechangeIngredientButtonDisabled {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: var(--rgbaVal);
    color: black;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    opacity: 0.5;
    cursor: not-allowed;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .axechangeIngredientButtonDisabled {
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 6px;
    margin-left: 57%;
    margin-bottom: 4px;
    background-color: var(--rgbaVal);
    color: black;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    opacity: 0.5;
    cursor: not-allowed;
    font-family: var(--font-family);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientBox {
    width: 100%;
    height: 88px;
    border: 1px solid grey;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 5px;
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientBox {
    width: 100%;
    height: 88px;
    margin-top: 3px;
    margin-bottom: 5px;
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientBox {
    width: 100%;
    height: 92px;
    margin-top: 3px;
    margin-bottom: 5px;
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientBox {
    width: 100%;
    height: 11vh;
    margin-top: 3px;
    margin-bottom: 5px;
    overflow-x: hidden;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientBox {
    width: 100%;
    height: 11vh;
    margin-top: 3px;
    margin-bottom: 5px;
    overflow-x: hidden;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientFieldset2 {
    border: none;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientFieldset2 {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientFieldset2 {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientFieldset2 {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientFieldset2 {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-top: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientPriceBox {
    width: 99%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientPriceBox {
    width: 99%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientPriceBox {
    width: 99%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientPriceBox {
    width: 99%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientLegend1 {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientPricePlusIcon {
    font-family: var(--font-family);
    cursor: pointer;
    margin-top: 1px;
    font-size: 46px;
    color: var(--rgbaVal);
    opacity: 0.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientPricePlusIcon {
    font-family: var(--font-family);
    cursor: pointer;
    margin-top: 1px;
    font-size: 46px;
    color: var(--rgbaVal);
    opacity: 0.5;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientPricePlusIcon {
    font-family: var(--font-family);
    cursor: pointer;
    margin-top: 1px;
    font-size: 46px;
    color: var(--rgbaVal);
    opacity: 0.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientPricePlusIcon {
    font-family: var(--font-family);
    cursor: pointer;
    margin-top: 1px;
    font-size: 46px;
    color: var(--rgbaVal);
    opacity: 0.5;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientPricePlusIcon {
    font-family: var(--font-family);
    cursor: pointer;
    margin-top: 1px;
    font-size: 46px;
    color: var(--rgbaVal);
    opacity: 0.5;
  }
}

.xeeditIngredientPriceMinusIcon {
  font-family: var(--font-family);
  cursor: pointer;
  margin-left: 10px;
  margin-top: 1px;
  font-size: 46px;
  color: var(--rgbaVal);
  opacity: 0.5;
}

.xeeditIngredientPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditPriceButton {
    width: 60px;
    height: 25px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: var(--rgbaVal);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: black;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditPriceButton {
    width: 60px;
    height: 25px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: var(--rgbaVal);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: black;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditPriceButton {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: var(--rgbaVal);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: black;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditPriceButton {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: var(--rgbaVal);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: black;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditPriceButton {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: var(--rgbaVal);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: black;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditPriceButton1 {
    width: 60px;
    height: 25px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: rgb(229, 64, 68);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditPriceButton1 {
    width: 60px;
    height: 25px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: rgb(229, 64, 68);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditPriceButton1 {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: rgb(229, 64, 68);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditPriceButton1 {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: rgb(229, 64, 68);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditPriceButton1 {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left: 10%;
    margin-top: 20px;
    background-color: rgb(229, 64, 68);
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeprisAreaTop {
    width: 60%;
    height: 50px;
    background-color: transparent;
    border: 1px solid rgb(223, 212, 212);
    margin-left: 1px;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeprisAreaTop {
    width: 98%;
    height: 50px;
    background-color: transparent;
    border: none;
    margin-left: 1px;
    margin-top: 15px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeprisFieldset {
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeprisFieldset {
    border: none;
    width: 98%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeprisFieldset {
    border: none;
    width: 98%;
    margin-top: 3%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeprisFieldset {
    border: none;
    width: 98%;
    margin-top: 4%;
  }
}
@media only screen and (min-width:2303px) {
  .xeprisFieldset {
    border: none;
    width: 98%;
    margin-top: 4%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeprisLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeprisLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeprisLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeprisLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    margin-left: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xeprisLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    margin-left: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeprisButton {
    font-family: var(--font-family);
    font-size: 20px;
    border: none;
    background-color: transparent;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeprisButton {
    font-family: var(--font-family);
    font-size: 24px;
    border: none;
    background-color: transparent;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeprisButton {
    font-family: var(--font-family);
    font-size: 42px;
    border: none;
    background-color: transparent;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeprisButton {
    font-family: var(--font-family);
    font-size: 46px;
    border: none;
    background-color: transparent;
  }
}
@media only screen and (min-width:2303px) {
  .xeprisButton {
    font-family: var(--font-family);
    font-size: 46px;
    border: none;
    background-color: transparent;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeinkopsStatistikTop {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeinkopsStatistikTop {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeinkopsStatistikTop {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeinkopsStatistikTop {
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xeinkopsStatistikTop {
    margin-top: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeinkopsStatistikFieldset {
    border: none;
    margin-top: 1px;
    margin-left: 5px;
    height: 1px;
    width: 60%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeinkopsStatistikFieldset {
    border: none;
    margin-top: 2%;
    margin-left: 3%;
    height: 1px;
    width: 60%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeinkopsStatistikFieldset {
    border: none;
    margin-top: 2%;
    margin-left: 1%;
    height: 1px;
    width: 60%;
    font-family: var(--font-family);
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeinkopsStatistikFieldset {
    border: none;
    margin-top: 2%;
    margin-left: 1%;
    height: 1px;
    width: 60%;
    font-family: var(--font-family);
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .xeinkopsStatistikFieldset {
    border: none;
    margin-top: 2%;
    margin-left: 1%;
    height: 1px;
    width: 60%;
    font-family: var(--font-family);
    font-size: 18px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeinkopsStatistikLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeinkopsStatistikLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeinkopsStatistikLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeinkopsStatistikLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width:2303px) {
  .xeinkopsStatistikLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeinkopHeaderDatum {
    border: none;
    width: 150px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 15px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeinkopHeaderDatum {
    border: none;
    width: 39%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 15px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeinkopHeaderDatum {
    border: none;
    width: 31.4%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 15px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeinkopHeaderDatum {
    border: none;
    width: 31.4%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 15px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xeinkopHeaderDatum {
    border: none;
    width: 31.4%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 15px;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeinkopHeaderPris {
    border: none;
    width: 100px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeinkopHeaderPris {
    border: none;
    width: calc(20% - 2px);
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeinkopHeaderPris {
    border: none;
    width: 15.5%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeinkopHeaderPris {
    border: none;
    width: 15.8%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xeinkopHeaderPris {
    border: none;
    width: 15.8%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal);
    border-radius: 3px;
    margin-left: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeinkopsStatistikData {
    margin-left: 13px;
    max-height: 180px;
    overflow-y: scroll;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeinkopsStatistikData {
    margin-left: 13px;
    max-height: 180px;
    overflow-y: scroll;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeinkopsStatistikData {
    margin-left: 13px;
    min-height: 3px;
    max-height: 50vh;
    overflow-y: scroll;
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeinkopsStatistikData {
    margin-left: 13px;
    min-height: 3px;
    max-height: 50vh;
    overflow-y: scroll;
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xeinkopsStatistikData {
    margin-left: 13px;
    min-height: 3px;
    max-height: 50vh;
    overflow-y: scroll;
    width: 80%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xedataDatum {
    width: 150px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xedataDatum {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xedataDatum {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xedataDatum {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xedataDatum {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xedataDatum1 {
    width: 150px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xedataDatum1 {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xedataDatum1 {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xedataDatum1 {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xedataDatum1 {
    width: 40%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xedataPris {
    width: 100px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xedataPris {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xedataPris {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xedataPris {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xedataPris {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xedataPris1 {
    width: 100px;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xedataPris1 {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xedataPris1 {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xedataPris1 {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xedataPris1 {
    width: 20%;
    height: 20px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--rgbaVal2);
    margin-left: 2px;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditNutrientTop {
    margin-top: 1px;
  }
}

.xenutrientHeaderTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientHeaderArea {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientHeaderArea {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientHeaderArea {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientHeaderArea {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientHeaderArea {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientNameArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientNameArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 98%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientNameArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 10px;
    width: 98%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientNameArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 10px;
    width: 98%;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientNameArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 10px;
    width: 98%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientName {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    font-style: italic;
    margin-top: 1.5%;
    margin-bottom: 1%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientName {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    font-style: italic;
    margin-top: 1.5%;
    margin-bottom: 1%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientName {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    font-style: italic;
    margin-top: 1.5%;
    margin-bottom: 1%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientName {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    font-style: italic;
    margin-top: 1.5%;
    margin-bottom: 1%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientName {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    font-style: italic;
    margin-top: 3.5%;
    margin-bottom: 1%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xenutrientButtonBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 4.5%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xenutrientButtonBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 4.5%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xenutrientButtonBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 4.5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xenutrientButtonBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 4.5%;
  }
}
@media only screen and (min-width:2303px) {
  .xenutrientButtonBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 4.5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xefetchNutrientButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    margin-left: 2px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xefetchNutrientButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    margin-left: 2px;
    margin-top: 3px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xefetchNutrientButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    margin-left: 2px;
    margin-top: 3px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xefetchNutrientButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    margin-left: 2px;
    margin-top: 3px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xefetchNutrientButton {
    width: 170px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border: none;
    margin-right: 0.7%;
    margin-top: 3px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeconvertFromVolumeTop {
    margin-top: 5px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeconvertFromVolumeTop {
    margin-top: 10px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeconvertFromVolumeTop {
    margin-top: 10px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeTop {
    margin-top: 10px;
    margin-left: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeTop {
    margin-top: 10px;
    margin-left: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeconvertFromVolumeFieldset {
    border: none;
    margin-top: 1px;
    margin-left: 17px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeconvertFromVolumeFieldset {
    border: none;
    margin-top: 1px;
    margin-left: 17px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeconvertFromVolumeFieldset {
    border: none;
    margin-top: 12px;
    margin-left: 17px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeFieldset {
    border: none;
    margin-top: 18px;
    margin-left: 17px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeFieldset {
    border: none;
    margin-top: 22px;
    margin-left: 17px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeconvertFromVolumeLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeconvertFromVolumeLegend {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeconvertFromVolumeLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    margin-left: 6.5%;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    margin-left: 8%;
  }
}

.xeconvertFromVolumeSelect1 {
  width: 65%;
  height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  background-color: white;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeSelect1 {
    width: 65%;
    height: 40px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeSelect1 {
    width: 65%;
    height: 40px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}

.xeconvertFromVolumeButton1 {
  width: 100px;
  height: 30px;
  font-family: var(--font-family);
  font-size: 12px;
  border: none;
  border-radius: 6px;
  background-color: var(--rgbaVal);
  margin-left: 2px;
  cursor: pointer;
  color: white;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeButton1 {
    width: 150px;
    height: 40px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 5px;
    cursor: pointer;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeButton1 {
    width: 150px;
    height: 40px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 5px;
    cursor: pointer;
    color: white;
  }
}

.xeconvertFromVolumeButton {
  width: 100px;
  height: 30px;
  font-family: var(--font-family);
  font-size: 12px;
  border: none;
  border-radius: 6px;
  background-color: rgb(229, 64, 68);
  margin-left: 2px;
  cursor: pointer;
  color: white;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeButton {
    width: 150px;
    height: 40px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    margin-left: 5px;
    cursor: pointer;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeButton {
    width: 150px;
    height: 40px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    margin-left: 5px;
    cursor: pointer;
    color: white;
  }
}

.xeconvertFromVolumeData {
  margin-left: 27px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeconvertFromVolumeDataText {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    margin-left: 5px;
    margin-top: 10px;
    color: #c12661;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeconvertFromVolumeDataText {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    margin-left: 5px;
    margin-top: 10px;
    color: #c12661;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeconvertFromVolumeDataText {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    margin-left: 7%;
    margin-top: 10px;
    color: #c12661;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeDataText {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 48px;
    margin-left: 7%;
    margin-top: 20px;
    color: #c12661;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeDataText {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 48px;
    margin-left: 7%;
    margin-top: 20px;
    color: #c12661;
  }
}

.xegramText {
  font-family: var(--font-family);
  font-size: 14px;
  margin-left: 7px;
  margin-top: 10px;
  color: black;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xegramText {
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 7px;
    margin-top: 10px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xegramText {
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 7px;
    margin-top: 10px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeconvertFromVolumeDisclaimer {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeconvertFromVolumeDisclaimer {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeconvertFromVolumeDisclaimer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeDisclaimer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeDisclaimer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeconvertFromVolumeDisclaimerText {
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 20px;
    margin-top: 10px;
    font-style: italic;
    width: 95%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeconvertFromVolumeDisclaimerText {
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 20px;
    margin-top: 10px;
    font-style: italic;
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeconvertFromVolumeDisclaimerText {
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 20px;
    margin-top: 10px;
    font-style: italic;
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeconvertFromVolumeDisclaimerText {
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 20px;
    margin-top: 10px;
    font-style: italic;
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xeconvertFromVolumeDisclaimerText {
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 20px;
    margin-top: 10px;
    font-style: italic;
    width: 80%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredInformationTop {
    margin-top: 5px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredInformationTop {
    margin-top: 5px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredInformationTop {
    margin-top: 5px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredInformationTop {
    margin-top: 5px;
    margin-left: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredInformationTop {
    margin-top: 5px;
    margin-left: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredInformationFieldset {
    border: none;
    margin-top: 8px;
    margin-left: 7px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredInformationFieldset {
    border: none;
    margin-top: 15px;
    margin-left: 7px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredInformationFieldset {
    border: none;
    margin-top: 15px;
    margin-left: 7px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredInformationFieldset {
    border: none;
    margin-top: 20px;
    margin-left: 7px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredInformationFieldset {
    border: none;
    margin-top: 20px;
    margin-left: 7px;
    height: 41px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredInformationLegend {
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredInformationLegend {
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredInformationLegend {
    font-family: var(--font-family);
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredInformationLegend {
    font-family: var(--font-family);
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredInformationLegend {
    font-family: var(--font-family);
    font-size: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredInformationButton {
    width: 130px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    margin-left: 2px;
    cursor: pointer;
    color: white;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredInformationButton {
    width: 130px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    margin-left: 2px;
    cursor: pointer;
    color: white;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredInformationButton {
    width: 130px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    margin-left: 2px;
    cursor: pointer;
    color: white;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredInformationButton {
    width: 160px;
    height: 40px;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    margin-left: 2px;
    cursor: pointer;
    color: white;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredInformationButton {
    width: 160px;
    height: 40px;
    font-family: var(--font-family);
    font-size: 16px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 2px;
    cursor: pointer;
    color: white;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredInformationCheckbox {
    width: 15px;
    height: 15px;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredInformationCheckbox {
    width: 15px;
    height: 15px;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredInformationCheckbox {
    width: 15px;
    height: 15px;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredInformationCheckbox {
    width: 15px;
    height: 15px;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredInformationCheckbox {
    width: 15px;
    height: 15px;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredInformationTextArea {
    background-color: transparent;
    width: 95%;
    height: 200px;
    max-height: 200px;
    overflow-x: scroll;
    outline: none;
    border: 1px dotted;
    font-family: var(--font-family);
    font-size: 16px;
    padding: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredInformationTextArea {
    background-color: transparent;
    width: 95%;
    height: 50vh;
    max-height: 50vh;
    overflow-x: scroll;
    outline: none;
    border: 1px dotted;
    font-family: var(--font-family);
    font-size: 16px;
    padding: 5px;
    overflow-x: hidden;
  }
  .xeingredInformationTextArea::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xeingredInformationTextArea::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredInformationTextArea {
    background-color: transparent;
    width: 95%;
    height: 50vh;
    max-height: 50vh;
    overflow-x: scroll;
    outline: none;
    border: 1px dotted;
    font-family: var(--font-family);
    font-size: 16px;
    padding: 5px;
    overflow-x: hidden;
  }
  .xeingredInformationTextArea::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xeingredInformationTextArea::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredInformationTextArea {
    background-color: transparent;
    width: 95%;
    height: 50vh;
    min-height: 1px;
    max-height: 50vh;
    overflow-y: auto;
    outline: none;
    border: 1px dotted;
    font-family: var(--font-family);
    font-size: 20px;
    padding: 5px;
    overflow-x: hidden;
  }
  .xeingredInformationTextArea::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredInformationTextArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredInformationTextArea::-webkit-scrollbar-thumb {
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredInformationTextArea {
    background-color: transparent;
    width: 95%;
    height: 50vh;
    min-height: 1px;
    max-height: 50vh;
    overflow-y: auto;
    outline: none;
    border: 1px dotted;
    font-family: var(--font-family);
    font-size: 20px;
    padding: 5px;
    overflow-x: hidden;
  }
  .xeingredInformationTextArea::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xeingredInformationTextArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xeingredInformationTextArea::-webkit-scrollbar-thumb {
    background: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xecheckboxlabel {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    margin-left: 5px;
    margin-top: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeareaChartLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeareaChartLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeareaChartLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeareaChartLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }
}
@media only screen and (min-width:2303px) {
  .xeareaChartLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeareaChartFieldset {
    border: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeareaChartFieldset {
    border: none;
    margin-top: 3%;
    margin-left: 3%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeareaChartFieldset {
    border: none;
    margin-top: 3%;
    margin-left: 3%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeareaChartFieldset {
    border: none;
    margin-top: 3%;
    margin-left: 3%;
  }
}
@media only screen and (min-width:2303px) {
  .xeareaChartFieldset {
    border: none;
    margin-top: 3%;
    margin-left: 3%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredChartChoiceArea {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredChartChoiceArea {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredChartChoiceArea {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredChartChoiceArea {
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredChartChoiceArea {
    margin-top: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredChartChoiceFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredChartChoiceFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredChartChoiceFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredChartChoiceFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredChartChoiceFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
    margin-left: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredChartChoiceLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredChartChoiceLegend {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredChartChoiceLegend {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredChartChoiceLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredChartChoiceLegend {
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingred-katprodchoice-chart-button {
    margin: 2px;
    width: auto;
    height: 20px;
    border-radius: 6px;
    background-color: RGB(255, 128, 0);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
  }
  .ingred-katprodchoice-chart-button:hover {
    background-color: rgb(141, 71, 0);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingred-katprodchoice-chart-button {
    margin: 2px;
    width: auto;
    height: 20px;
    border-radius: 6px;
    background-color: RGB(255, 128, 0);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
  }
  .ingred-katprodchoice-chart-button:hover {
    background-color: rgb(141, 71, 0);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingred-katprodchoice-chart-button {
    margin: 2px;
    width: 100px;
    height: 30px;
    border-radius: 6px;
    background-color: RGB(255, 128, 0);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
  }
  .ingred-katprodchoice-chart-button:hover {
    background-color: rgb(141, 71, 0);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingred-katprodchoice-chart-button {
    margin: 2px;
    width: 100px;
    height: 30px;
    border-radius: 6px;
    background-color: RGB(255, 128, 0);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
  }
  .ingred-katprodchoice-chart-button:hover {
    background-color: rgb(141, 71, 0);
  }
}
@media only screen and (min-width:2303px) {
  .ingred-katprodchoice-chart-button {
    margin: 2px;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    background-color: RGB(255, 128, 0);
    color: white;
    font-family: var(--font-family);
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
  }
  .ingred-katprodchoice-chart-button:hover {
    background-color: rgb(141, 71, 0);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingred-katprodchoice-chart-button.active {
    background-color: red;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingred-katprodchoice-chart-button.active {
    background-color: red;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingred-katprodchoice-chart-button.active {
    background-color: red;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingred-katprodchoice-chart-button.active {
    background-color: red;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .ingred-katprodchoice-chart-button.active {
    background-color: red;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .kanjiinput {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .kanjiinput {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .kanjiinput {
    width: 80%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .kanjiinput {
    width: 80%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .kanjiinput {
    width: 80%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 18px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .furiganainput {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .furiganainput {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .furiganainput {
    width: 80%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .furiganainput {
    width: 80%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .furiganainput {
    width: 80%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 18px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    margin-top: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .kategoriFieldsetJAP {
    width: 100%;
    height: 48px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 40px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .kategoriFieldsetJAP {
    width: 100%;
    height: 48px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 9%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .kategoriFieldsetJAP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 48px;
    padding-bottom: 2px;
    margin-top: 10px;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .kategoriFieldsetJAP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 48px;
    padding-bottom: 2px;
    margin-top: 4%;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .kategoriFieldsetJAP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 48px;
    padding-bottom: 2px;
    margin-top: 4%;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredientFieldsetJAP {
    width: 90%;
    height: 60px;
    padding-bottom: 20px;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredientFieldsetJAP {
    width: 90%;
    height: 60px;
    padding-bottom: 20px;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredientFieldsetJAP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-bottom: 20px;
    border: none;
    margin-top: 3%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientFieldsetJAP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-bottom: 20px;
    border: none;
    margin-top: 3%;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredientFieldsetJAP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-bottom: 20px;
    border: none;
    margin-top: 3%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeingredientLegendJAP {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeingredientLegendJAP {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeingredientLegendJAP {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientLegendJAP {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeingredientLegendJAP {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    margin-left: 10%;
  }
}
@media only screen and (min-width:2303px) {
  .xeingredientLegendJAP {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    margin-left: 10%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredselectJAP {
    width: 91%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredselectJAP {
    width: 92%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredselectJAP {
    width: 80%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredselectJAP {
    width: 80%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .ingredselectJAP {
    width: 80%;
    height: 35px;
    font-family: var(--font-family);
    font-size: 16px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    color: black;
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .saveButtonJAP {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .saveButtonJAP {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .saveButtonJAP {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .saveButtonJAP {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .saveButtonJAP {
    width: 170px;
    height: 40px;
    border-radius: 6px;
    background-color: rgb(229, 64, 68);
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .saveButtonJAP1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .saveButtonJAP1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .saveButtonJAP1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .saveButtonJAP1 {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.7;
  }
}
@media only screen and (min-width:2303px) {
  .saveButtonJAP1 {
    width: 170px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.7;
  }
}

.xesaveButtonArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xe1saveButtonAreaJAP {
    align-self: flex-end;
    margin-right: 14%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xe1saveButtonAreaJAP {
    align-self: flex-end;
    margin-right: 4.5%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xe1saveButtonAreaJAP {
    align-self: flex-end;
    margin-right: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xe1saveButtonAreaJAP {
    align-self: flex-end;
    margin-right: 10%;
    margin-top: 1.5%;
  }
}
@media only screen and (min-width:2303px) {
  .xe1saveButtonAreaJAP {
    align-self: flex-end;
    margin-right: 10%;
    margin-top: 1.5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientBox1JAP {
    width: 100%;
    height: 14vh;
    border-radius: 3px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientBox1JAP {
    width: 100%;
    height: 14vh;
    border-radius: 3px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientBox1JAP {
    width: 100%;
    height: 14vh;
    border-radius: 3px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientBox1JAP {
    width: 100%;
    height: 14vh;
    border-radius: 3px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientBox1JAP {
    width: 100%;
    height: 14vh;
    border-radius: 3px;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientFieldset1JAP {
    margin-top: 1px;
    width: 200px;
    height: 12vh;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientFieldset1JAP {
    margin-top: 1px;
    width: 200px;
    height: 12vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientFieldset1JAP {
    margin-top: 1px;
    width: 200px;
    height: 12vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientFieldset1JAP {
    margin-top: 1px;
    width: 200px;
    height: 12vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientFieldset1JAP {
    margin-top: 1px;
    width: 200px;
    height: 9vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientLegend1JAP {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientLegend1JAP {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientLegend1JAP {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientLegend1JAP {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientLegend1JAP {
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientKanjiInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientKanjiInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientKanjiInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientKanjiInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientKanjiInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 16px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientFuriganaInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientFuriganaInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientFuriganaInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientFuriganaInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientFuriganaInputJAP {
    width: 100%;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    margin-top: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientButtonJAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: var(--rgbaVal);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientButtonJAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: var(--rgbaVal);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientButtonJAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: var(--rgbaVal);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientButtonJAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: var(--rgbaVal);
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientButtonJAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: var(--rgbaVal);
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xeeditIngredientButton1JAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: rgb(229, 64, 68);
    border-radius: 6px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xeeditIngredientButton1JAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: rgb(229, 64, 68);
    border-radius: 6px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xeeditIngredientButton1JAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: rgb(229, 64, 68);
    border-radius: 6px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xeeditIngredientButton1JAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: rgb(229, 64, 68);
    border-radius: 6px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xeeditIngredientButton1JAP {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: 6px;
    margin-left: 52%;
    cursor: pointer;
    background-color: rgb(229, 64, 68);
    border-radius: 6px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xechartArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    height: 36vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xechartArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    height: 60vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xechartArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    margin-top: 4vh;
    height: 70vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xechartArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    margin-top: 4vh;
    height: 70vh;
  }
}
@media only screen and (min-width:2303px) {
  .xechartArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    margin-top: 4vh;
    height: 70vh;
  }
}/*# sourceMappingURL=IngredientsNew1.css.map */