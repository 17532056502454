:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.afakturaPrintButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.afakturaPrintButton {
  width: 100px;
  height: 30px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  font-family: var(--font-aafamily);
  font-size: 12px;
  color: rgb(229, 64, 68);
  border-color: rgb(223, 212, 212);
  margin-right: 5px;
  outline: none;
}
@media print {
  .afakturaPrintButton {
    display: none;
  }
}

.afakturaTop {
  width: 100%;
}

.afakturaWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.afakturaSelect {
  width: 50%;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  height: 25px;
  font-family: var(--font-aafamily);
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 5px;
  outline: none;
}
@media print {
  .afakturaSelect {
    display: none;
  }
}

.afakturaArea {
  width: 100%;
  height: 90vh;
}

.afakturaAreaWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .afakturaAreaGrid {
    display: grid;
    grid-template-rows: 15% 16% 43% 16%;
    grid-template-areas: "a" "b" "c" "d";
    width: 95%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .afakturaAreaGrid {
    display: grid;
    grid-template-rows: 15% 16% 43% 16%;
    grid-template-areas: "a" "b" "c" "d";
    width: 95%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .afakturaAreaGrid {
    display: grid;
    grid-template-rows: 15% 16% 43% 16%;
    grid-template-areas: "a" "b" "c" "d";
    width: 75%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .afakturaAreaGrid {
    display: grid;
    grid-template-rows: 15% 16% 43% 16%;
    grid-template-areas: "a" "b" "c" "d";
    width: 45%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .afakturaAreaGrid {
    display: grid;
    grid-template-rows: 15% 16% 43% 16%;
    grid-template-areas: "a" "b" "c" "d";
    width: 95%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    border: 1px solid rgb(223, 212, 212);
  }
}

.afakturaAreaA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: a;
  width: 100%;
  height: 16vh;
  background-color: white;
}

.afakturaHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.afakturaHeaderName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Lobster,cursive";
  font-size: 70px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 3px;
  width: 50%;
  height: 7vh;
  color: gold;
}

.afakturaHeaderType, .afakturaHeaderTypeJAP {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 60px;
  margin-top: 1vh;
  font-style: italic;
  margin-bottom: 3px;
  width: 55%;
  color: grey;
}
@media print {
  .afakturaHeaderType, .afakturaHeaderTypeJAP {
    margin-left: 10px;
  }
}

.afakturaHeaderType, .afakturaHeaderTypeJAP {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 60px;
  margin-top: 1vh;
  font-style: italic;
  margin-bottom: 3px;
  width: 55%;
  color: grey;
}
@media print {
  .afakturaHeaderType, .afakturaHeaderTypeJAP {
    margin-left: 10px;
  }
}

.afakturaHeaderTypeJAP {
  font-size: 46px;
}

.afakturaHeaderBroughtToYou {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 10px;
  width: 55%;
}

.afakturaHeaderInvoiceDue {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 45px;
  padding-left: 10%;
}

.afakturaAreaB {
  grid-area: b;
  width: 100%;
  height: 14vh;
  background-color: white;
}

.afakturaHeaderLine {
  width: 90%;
  height: 2px;
  background-color: grey;
}

.afakturacompanyInfo {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
}

.afakturacustomerInfo {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.afakturainfo {
  width: 100%;
}

.afakturaInfoTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.afakturainfoWrapper {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.afakturaOwnerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: var(--font-family);
  font-size: 16px;
}

.afakturaCustomerid {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: var(--font-family);
  font-size: 16px;
}

.afakturacustomerDet {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: var(--font-family);
  font-size: 16px;
}

.afakturaAreaC {
  grid-area: c;
  width: 100%;
  height: 46vh;
  background-color: white;
}

.afakturaAreaCWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaCWrapper1 {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaCTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.afakturaAreaCHeaderService {
  width: 10%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.afakturaAreaCHeaderNamn {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.afakturaAreaCHeaderAntal {
  width: calc(8% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.afakturaAreaCHeaderMaxAntal {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  white-space: nowrap;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.afakturaAreaCHeaderafakturerat {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.afakturaAreaCHeaderTotalt {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.afakturaAreaCHeaderKredit {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.afakturaAreaList {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.afakturaAreaListRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.afakturaAreaListService {
  width: 10%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaListNamn {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  white-space: nowrap;
  margin-top: 2px;
}

.afakturaAreaListAntal {
  width: calc(8% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaListMaxAntal {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaListafakturerat {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaListTotalt {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaListKredit {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.afakturaAreaD {
  grid-area: d;
  width: 100%;
  height: 17vh;
  background-color: white;
}

.afakturaFooterTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.afakturaFooterWrapper {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.afakturaFooterHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.afakturaFooterHeadertext {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
}

.afakturaPayWrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.afakturaPayNetSum {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-family);
  font-size: 18px;
  margin-top: 5px;
  color: black;
}

.afakturaPayVAT {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-family);
  font-size: 18px;
  margin-top: 5px;
  white-space: nowrap;
  color: black;
}

.afakturaPayTotal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 26px;
  margin-top: 5px;
  color: grey;
}

.afakturaPayTotal1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 30px;
  margin-top: 5px;
}

.afakturaPayTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=PrintInvoice.css.map */