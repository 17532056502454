:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xntotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xntotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xntotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnwindowArea, .xnwindowArea1 {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas: "a" "b" "c" "d" "e";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnwindowArea, .xnwindowArea1 {
    display: grid;
    grid-template-columns: 24% 40% 35%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnwindowArea, .xnwindowArea1 {
    display: grid;
    grid-template-columns: 24% 50% 25%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnwindowArea, .xnwindowArea1 {
    display: grid;
    grid-template-columns: 24% 50% 25%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xnwindowArea, .xnwindowArea1 {
    display: grid;
    grid-template-columns: 25% 50% 24%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xnwindowAreaA {
  grid-area: c;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  width: 100%;
  height: 32.5vh;
  background-color: var(--val);
  margin-top: 5px;
  border-radius: 6px;
}
@media (min-width: 375px) and (max-width: 380px) {
  .xnwindowAreaA {
    height: 33vh;
  }
}
@media (min-width: 393px) {
  .xnwindowAreaA {
    height: 33vh;
  }
}
@media (min-width: 393px) {
  .xnwindowAreaA {
    height: 33vh;
  }
}
@media (min-width: 410px) and (max-width: 413px) {
  .xnwindowAreaA {
    height: 24vh;
  }
}
@media (min-width: 414px) {
  .xnwindowAreaA {
    height: 28vh;
  }
}
@media (min-width: 428px) {
  .xnwindowAreaA {
    height: 33vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnwindowAreaA {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xnwindowAreaA {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xnwindowAreaA {
    height: 74vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnwindowAreaA {
    height: 84vh;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnwindowAreaA {
    height: 84vh;
    margin-top: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xnwindowAreaA {
    height: 84vh;
    margin-top: 0;
  }
}

.xnwindowAreaB {
  grid-area: d;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  width: 100%;
  height: 48vh;
  background-color: var(--val);
  margin-top: 5px;
  border-radius: 6px;
}
@media (min-width: 375px) and (max-width: 380px) {
  .xnwindowAreaB {
    height: 48vh;
  }
}
@media (min-width: 384px) and (max-width: 392px) {
  .xnwindowAreaB {
    height: 50vh;
  }
}
@media (min-width: 393px) and (max-width: 409px) {
  .xnwindowAreaB {
    height: 49vh;
  }
}
@media (min-width: 410px) and (max-width: 413px) {
  .xnwindowAreaB {
    height: 52.5vh;
  }
}
@media (min-width: 414px) {
  .xnwindowAreaB {
    height: 54vh;
  }
}
@media (min-width: 428px) {
  .xnwindowAreaB {
    height: 50vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnwindowAreaB {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xnwindowAreaB {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xnwindowAreaB {
    height: 74vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnwindowAreaB {
    height: 84vh;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnwindowAreaB {
    height: 84vh;
    margin-top: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xnwindowAreaB {
    height: 84vh;
    margin-top: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnwindowAreaC {
    grid-area: e;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 30vh;
    background-color: var(--val);
    margin-top: 1.5vh;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnwindowAreaC {
    grid-area: e;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    background-color: var(--val);
    margin-top: 3px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xnwindowAreaC {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xnwindowAreaC {
    height: 74vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnwindowAreaC {
    grid-area: e;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnwindowAreaC {
    grid-area: e;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xnwindowAreaC {
    grid-area: e;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    background-color: var(--val);
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnheaderArea {
    grid-area: a;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xnheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xncompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xncompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xncompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xncompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xncompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xncompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xncompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xncompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xncompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xncompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xnrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnrange1, .xnrange2 {
    grid-area: b;
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnrange1, .xnrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 20px;
    margin-right: 2%;
    cursor: pointer;
    grid-area: b;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnrange1, .xnrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnrange1, .xnrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xnrange1, .xnrange2 {
    accent-color: blue;
    width: 125px;
    margin-right: 10px;
    cursor: pointer;
    margin-left: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xnrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xndataContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 0.1vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xndataContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 18vh;
    margin-left: 1px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xndataContainer {
    height: 24vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1025px) and (max-width: 1169px) {
  .xndataContainer {
    height: 24vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xndataContainer {
    height: 22vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xndataContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 18vh;
    margin-left: 1px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xndataContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 22vh;
    margin-left: 1px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xndataContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 22vh;
    margin-left: 1px;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnnewProductFieldset {
    border: none;
    height: 40px;
    width: 97%;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnnewProductFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    height: 5.5vh;
    width: 98%;
    margin-top: 1px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnnewProductFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    height: 5.5vh;
    width: 98%;
    margin-top: 1px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnnewProductFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    height: 5.5vh;
    width: 98%;
    margin-top: 1px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
@media only screen and (min-width:2303px) {
  .xnnewProductFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    height: 5.5vh;
    width: 98%;
    margin-top: 1px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnnewProductLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnnewProductLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnnewProductLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnnewProductLegend {
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 10px;
    padding: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xnnewProductLegend {
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 5px;
    padding: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnnewProductionCategorySelect {
    width: 45%;
    height: 30px;
    margin-left: 15px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnnewProductionCategorySelect {
    width: 48%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnnewProductionCategorySelect {
    width: 48%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnnewProductionCategorySelect {
    width: 48%;
    height: 35px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnnewProductionCategorySelect {
    width: 48%;
    height: 40px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 18px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnnewProductionProductSelect {
    width: 45%;
    height: 30px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnnewProductionProductSelect {
    width: 48%;
    height: 30px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnnewProductionProductSelect {
    width: 48%;
    height: 30px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnnewProductionProductSelect {
    width: 48%;
    height: 35px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 15px;
  }
}
@media only screen and (min-width:2303px) {
  .xnnewProductionProductSelect {
    width: 48%;
    height: 40px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnongoingProductionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnongoingProductionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnongoingProductionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnongoingProductionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xnongoingProductionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnongoingProductionLegend {
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnongoingProductionLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnongoingProductionLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnongoingProductionLegend {
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 10px;
    padding: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xnongoingProductionLegend {
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 5px;
    padding: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnongoingProductionSelect {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnongoingProductionSelect {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnongoingProductionSelect {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnongoingProductionSelect {
    width: 99%;
    height: 35px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnongoingProductionSelect {
    width: 99%;
    height: 40px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionHistoryFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionHistoryFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionHistoryFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionHistoryFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionHistoryFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    width: 98%;
    padding: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionHistoryLegend {
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionHistoryLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionHistoryLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionHistoryLegend {
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 10px;
    padding: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionHistoryLegend {
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 5px;
    padding: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionHistorySelect {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionHistorySelect {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionHistorySelect {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionHistorySelect {
    width: 99%;
    height: 35px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionHistorySelect {
    width: 99%;
    height: 40px;
    margin-top: 2px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionHeaderArea, .xnproductionHeaderArea1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 45vh;
    border: none;
    background-color: transparent;
    max-height: 45vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .xnproductionHeaderArea::-webkit-scrollbar, .xnproductionHeaderArea1::-webkit-scrollbar {
    width: 1px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-thumb, .xnproductionHeaderArea1::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-track, .xnproductionHeaderArea1::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionHeaderArea, .xnproductionHeaderArea1 {
    width: 100%;
    height: 75vh;
    border: none;
    background-color: transparent;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .xnproductionHeaderArea::-webkit-scrollbar, .xnproductionHeaderArea1::-webkit-scrollbar {
    width: 1px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-thumb, .xnproductionHeaderArea1::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-track, .xnproductionHeaderArea1::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionHeaderArea, .xnproductionHeaderArea1 {
    width: 100%;
    height: 75vh;
    border: none;
    background-color: transparent;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .xnproductionHeaderArea::-webkit-scrollbar, .xnproductionHeaderArea1::-webkit-scrollbar {
    width: 1px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-thumb, .xnproductionHeaderArea1::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-track, .xnproductionHeaderArea1::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionHeaderArea, .xnproductionHeaderArea1 {
    width: 100%;
    height: 75vh;
    border: none;
    background-color: transparent;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .xnproductionHeaderArea::-webkit-scrollbar, .xnproductionHeaderArea1::-webkit-scrollbar {
    width: 1px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-thumb, .xnproductionHeaderArea1::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-track, .xnproductionHeaderArea1::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionHeaderArea, .xnproductionHeaderArea1 {
    width: 100%;
    height: 75vh;
    border: none;
    background-color: transparent;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .xnproductionHeaderArea::-webkit-scrollbar, .xnproductionHeaderArea1::-webkit-scrollbar {
    width: 1px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-thumb, .xnproductionHeaderArea1::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xnproductionHeaderArea::-webkit-scrollbar-track, .xnproductionHeaderArea1::-webkit-scrollbar-track {
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionHeaderArea1 {
    height: 10vh;
    max-height: 10vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionHeaderArea1 {
    height: 40vh;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionHeaderArea1 {
    height: 40vh;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionHeaderArea1 {
    height: 40vh;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionHeaderArea1 {
    height: 40vh;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 99%;
    height: 30px;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 98%;
    height: 30px;
    margin-top: 2px;
    background-color: transparent;
    outline: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 30px;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 85%;
    height: 35px;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    height: 40px;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xndatarows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xndatarows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionDataAreas {
    display: flex;
    flex-direction: column;
    height: 30vh;
    max-height: 30vh;
    width: 99%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionDataAreas {
    display: flex;
    flex-direction: column;
    height: 75vh;
    max-height: 75vh;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionDataAreas {
    display: flex;
    flex-direction: column;
    height: 75vh;
    max-height: 75vh;
    width: 99%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionDataAreas {
    display: flex;
    flex-direction: column;
    height: 75vh;
    max-height: 75vh;
    width: 99%;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionDataAreas {
    display: flex;
    flex-direction: column;
    height: 75vh;
    max-height: 75vh;
    width: 99%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionPid {
    width: 12%;
    height: 25px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionPid {
    width: 12%;
    height: 25px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionPid {
    width: 12%;
    height: 25px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionPid {
    width: 12%;
    height: 35px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionPid {
    width: 12%;
    height: 40px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionBenaemn {
    width: calc(34% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionBenaemn {
    width: calc(34% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionBenaemn {
    width: calc(34% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionBenaemn {
    width: calc(34% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionBenaemn {
    width: calc(34% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionNum {
    width: calc(12% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionNum {
    width: calc(12% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionNum {
    width: calc(12% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionNum {
    width: calc(12% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionNum {
    width: calc(12% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionTotal {
    width: calc(16% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionTotal {
    width: calc(16% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionTotal {
    width: calc(16% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionTotal {
    width: calc(16% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionTotal {
    width: calc(16% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionStatus {
    width: calc(18% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionStatus {
    width: calc(18% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionStatus {
    width: calc(18% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionStatus {
    width: calc(18% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionStatus {
    width: calc(18% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionDelete {
    width: calc(8% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionDelete {
    width: calc(8% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionDelete {
    width: calc(8% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionDelete {
    width: calc(8% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionDelete {
    width: calc(8% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: var(--rgbaVal);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproductionDataAreas {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproductionDataAreas {
    width: 98%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproductionDataAreas {
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproductionDataAreas {
    width: 85%;
  }
}
@media only screen and (min-width:2303px) {
  .xnproductionDataAreas {
    width: 80%;
  }
}

.xnprodbuttontemp, .xndelete, .xndelete1, .xnstatus, .xnstatus1, .xntotal, .xntotal1, .xnantal, .xnantal1, .xnbenaemn, .xnbenaemn1, .xnproduktionid, .xnproduktionid1 {
  width: 12%;
  border-radius: 6px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal2);
  margin-bottom: 2px;
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnproduktionid, .xnproduktionid1 {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnproduktionid, .xnproduktionid1 {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnproduktionid, .xnproduktionid1 {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnproduktionid, .xnproduktionid1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnproduktionid, .xnproduktionid1 {
    height: 50px;
    font-size: 24px;
  }
}

.xnproduktionid1 {
  background-color: var(--rgbaVal);
  margin-bottom: 2px;
}

.xnbenaemn, .xnbenaemn1 {
  width: calc(34% - 2px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnbenaemn, .xnbenaemn1 {
    height: 25px;
    font-size: 12px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnbenaemn, .xnbenaemn1 {
    height: 25px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnbenaemn, .xnbenaemn1 {
    height: 30px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnbenaemn, .xnbenaemn1 {
    height: 35px;
    font-size: 16px;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xnbenaemn, .xnbenaemn1 {
    height: 50px;
    font-size: 24px;
    margin-left: 2px;
  }
}

.xnbenaemn1 {
  background-color: var(--rgbaVal);
}

.xnantal, .xnantal1 {
  width: calc(12% - 2px);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnantal, .xnantal1 {
    height: 25px;
    font-size: 12px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnantal, .xnantal1 {
    height: 25px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnantal, .xnantal1 {
    height: 30px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnantal, .xnantal1 {
    height: 35px;
    font-size: 16px;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xnantal, .xnantal1 {
    height: 50px;
    font-size: 24px;
    margin-left: 2px;
  }
}

.xnantal1 {
  background-color: var(--rgbaVal);
}

.xntotal, .xntotal1 {
  width: calc(16% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xntotal, .xntotal1 {
    height: 25px;
    font-size: 12px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xntotal, .xntotal1 {
    height: 25px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xntotal, .xntotal1 {
    height: 30px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xntotal, .xntotal1 {
    height: 35px;
    font-size: 16px;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xntotal, .xntotal1 {
    height: 50px;
    font-size: 24px;
    margin-left: 2px;
  }
}

.xntotal1 {
  background-color: var(--rgbaVal);
}

.xnstatus, .xnstatus1 {
  width: calc(18% - 2px);
  margin-left: 2px;
  padding: 1px;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnstatus, .xnstatus1 {
    height: 25px;
    font-size: 12px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnstatus, .xnstatus1 {
    height: 25px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnstatus, .xnstatus1 {
    height: 30px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnstatus, .xnstatus1 {
    height: 35px;
    font-size: 16px;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xnstatus, .xnstatus1 {
    height: 50px;
    font-size: 24px;
    margin-left: 2px;
  }
}

.xnstatus1 {
  background-color: var(--rgbaVal);
}

.xndelete, .xndelete1 {
  width: calc(8% - 2px);
  margin-left: 2px;
  padding: 1px;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xndelete, .xndelete1 {
    height: 25px;
    font-size: 12px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xndelete, .xndelete1 {
    height: 25px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xndelete, .xndelete1 {
    height: 30px;
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xndelete, .xndelete1 {
    height: 35px;
    font-size: 16px;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xndelete, .xndelete1 {
    height: 50px;
    font-size: 24px;
    margin-left: 2px;
  }
}

.xndelete1 {
  background-color: var(--rgbaVal);
}

.xndeleteArrow {
  width: calc(8% - 2px);
  color: var(--rgbaVal);
  margin-left: 2px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 2px;
}

.xneditproductTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xneditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 100%;
    height: 60px;
    margin-top: 1px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xneditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 98%;
    height: 60px;
    margin-top: 1px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xneditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 90%;
    height: 60px;
    margin-top: 1px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xneditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 100%;
    height: 60px;
    margin-top: 1px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xneditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 100%;
    height: 70px;
    margin-top: 1px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnplussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnplussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnplussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnplussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width:2303px) {
  .xnplussign {
    font-family: var(--font-family);
    font-size: 50px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnminussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnminussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnminussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnminussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}
@media only screen and (min-width:2303px) {
  .xnminussign {
    font-family: var(--font-family);
    font-size: 50px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: rgb(229, 64, 68);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xnAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 60px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnAiOutlineMinusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnAiOutlineMinusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnAiOutlineMinusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnAiOutlineMinusCircle {
    color: var(--rgbaVal);
    font-size: 45px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xnAiOutlineMinusCircle {
    color: var(--rgbaVal);
    font-size: 60px;
    cursor: pointer;
  }
}

.xnproductionbuttonTop {
  width: 88%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 27px;
}

.xnsaveproductionButton {
  width: 70px;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 5px;
  margin-top: 4px;
  cursor: pointer;
  opacity: 0.5;
}

.xnsaveproductionButtonActive {
  width: 70px;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 5px;
  margin-top: 4px;
  cursor: pointer;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
}

.xnsaveproductionButtonIcon {
  color: white;
  font-size: 26px;
  cursor: pointer;
  margin-left: 1px;
  opacity: 1;
}

.xnsaveproductionButtonIconActive {
  color: white;
  font-size: 26px;
  cursor: pointer;
  margin-left: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsaveproductionMenuTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsaveproductionMenuTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsaveproductionMenuTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsaveproductionMenuTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xnsaveproductionMenuTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.xnsaveProductionMenuFieldset {
  border: none;
  border-color: rgb(223, 212, 212);
  width: 90%;
  height: 165px;
  background-color: transparent;
  margin-top: 5px;
  margin-left: 4px;
}

.xnsaveProductionMenuLegend {
  font-size: 12px;
  font-family: var(--font-family);
  color: black;
  margin-left: 10px;
  margin-top: 10px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsaveProductionMenuArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 95%;
    height: 175px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsaveProductionMenuArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 90%;
    height: 175px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsaveProductionMenuArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 85%;
    height: 175px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsaveProductionMenuArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 80%;
    height: 175px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsaveProductionMenuArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    width: 75%;
    height: 175px;
    border-radius: 6px;
  }
}

.xnsaveProductionMenuInput2 {
  width: 91%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 10px;
  margin-left: 2px;
  outline: none;
  border-radius: 3px;
}

.xnsaveProductionMenuInputJAP {
  width: 91%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 10px;
  margin-left: 2px;
  outline: none;
  border-radius: 3px;
}

.xnsaveProductionMenuInputFurigana {
  width: 91%;
  height: 15px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 2px;
  margin-left: 2px;
  outline: none;
  border-radius: 3px;
}

.xnsaveproductMenuButtonActive {
  width: 92.5%;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 2px;
  margin-top: 10px;
  cursor: pointer;
  opacity: 1;
}

.xnsaveproductMenuButtonActive1 {
  width: 92%;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 1px;
  margin-top: 7px;
  cursor: pointer;
  opacity: 1;
}

.xnsaveproductMenuButtonNotActive {
  width: 92.5%;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 1px;
  margin-top: 10px;
  cursor: not-allowed;
  opacity: 0.5;
}

.xnsaveproductMenuButtonKlarskriv {
  width: 92%;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 11px;
  margin-top: 7px;
  cursor: pointer;
  opacity: 1;
  margin-right: 10px;
}

.xnsaveproductMenuButtonKlarskriv1 {
  width: 92%;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 11px;
  margin-top: 7px;
  cursor: pointer;
  opacity: 0.5;
  margin-right: 10px;
}

.xnstatusheaderMain {
  margin-top: 5px;
  width: 100%;
}

.xnstatusHeaderFieldset {
  border: none;
  border-color: rgb(223, 212, 212);
  width: 100%;
  height: 30vh;
  background-color: transparent;
  margin-top: 2px;
}

.xnstatusHeaderLegend {
  font-size: 12px;
  font-family: var(--font-family);
  color: black;
  margin-top: 10px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnstatusheaderHeader {
    width: 99%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnstatusheaderHeader {
    margin-top: 5px;
    width: 96%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnstatusheaderHeader {
    width: 99%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnstatusheaderHeader {
    width: 99%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xnstatusheaderHeader {
    width: 99%;
    margin-bottom: 2px;
  }
}

.xnstatusheaderData {
  height: 23vh;
  max-height: 23vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 99%;
}
.xnstatusheaderData::-webkit-scrollbar {
  width: 1px;
}
.xnstatusheaderData::-webkit-scrollbar-thumb {
  background-color: rgb(223, 212, 212);
  border-radius: 10px;
}
.xnstatusheaderData::-webkit-scrollbar-track {
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnstatusheaderData {
    height: 23vh;
    max-height: 23vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 99%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnstatusheaderData {
    height: 70vh;
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 96%;
  }
}

.xnstatusButton, .xnsamtotantal1, .xnsamtotantal, .xnsamantal1, .xnsamantal, .xnsambenaemn1, .xnsambenaemn, .xnsamproduktionid1, .xnsamproduktionid, .xnstatusheaderNum, .xnstatusheaderSum, .xnstatusheaderBenaemn, .xnstatusheaderProduktionsid {
  height: 30px;
  margin-bottom: 2px;
  font-family: var(--font-family);
  font-size: 14px;
  background-color: var(--rgbaVal);
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  color: black;
}

.xnproductionStatisticTop {
  width: 100%;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xnstatusheaderProduktionsid {
  width: 20%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnstatusheaderProduktionsid {
    height: 25px;
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnstatusheaderProduktionsid {
    height: 25px;
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnstatusheaderProduktionsid {
    height: 30px;
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnstatusheaderProduktionsid {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnstatusheaderProduktionsid {
    height: 35px;
    font-size: 18px;
  }
}

.xnstatusheaderBenaemn {
  width: calc(40% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnstatusheaderBenaemn {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnstatusheaderBenaemn {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnstatusheaderBenaemn {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnstatusheaderBenaemn {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnstatusheaderBenaemn {
    height: 35px;
    font-size: 18px;
  }
}

.xnstatusheaderSum {
  width: calc(20% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnstatusheaderSum {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnstatusheaderSum {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnstatusheaderSum {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnstatusheaderSum {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnstatusheaderSum {
    height: 35px;
    font-size: 18px;
  }
}

.xnstatusheaderNum {
  width: calc(20% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnstatusheaderNum {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnstatusheaderNum {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnstatusheaderNum {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnstatusheaderNum {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnstatusheaderNum {
    height: 35px;
    font-size: 18px;
  }
}

.xnsamproduktionid {
  width: 20%;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsamproduktionid {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsamproduktionid {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsamproduktionid {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsamproduktionid {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsamproduktionid {
    height: 40px;
    font-size: 24px;
  }
}

.xnsamproduktionid1 {
  width: 20%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsamproduktionid1 {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsamproduktionid1 {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsamproduktionid1 {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsamproduktionid1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsamproduktionid1 {
    height: 40px;
    font-size: 24px;
  }
}

.xnsambenaemn {
  width: calc(40% - 2px);
  margin-left: 2px;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsambenaemn {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsambenaemn {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsambenaemn {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsambenaemn {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsambenaemn {
    height: 40px;
    font-size: 24px;
  }
}

.xnsambenaemn1 {
  width: calc(40% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsambenaemn1 {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsambenaemn1 {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsambenaemn1 {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsambenaemn1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsambenaemn1 {
    height: 40px;
    font-size: 24px;
  }
}

.xnsamantal {
  width: calc(20% - 2px);
  margin-left: 2px;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsamantal {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsamantal {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsamantal {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsamantal {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsamantal {
    height: 40px;
    font-size: 24px;
  }
}

.xnsamantal1 {
  width: calc(20% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsamantal1 {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsamantal1 {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsamantal1 {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsamantal1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsamantal1 {
    height: 40px;
    font-size: 24px;
  }
}

.xnsamtotantal {
  width: calc(20% - 2px);
  margin-left: 2px;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsamtotantal {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsamtotantal {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsamtotantal {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsamtotantal {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsamtotantal {
    height: 40px;
    font-size: 24px;
  }
}

.xnsamtotantal1 {
  width: calc(20% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsamtotantal1 {
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsamtotantal1 {
    height: 25px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsamtotantal1 {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsamtotantal1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsamtotantal1 {
    height: 40px;
    font-size: 24px;
  }
}

.xnprodNewSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xnprodNewSpinner {
  background-color: transparent;
}

.xnbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.xnchangevalueWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.xncreateProductionAnimatedDiv2 {
  width: 100%;
  transform: rotate(0deg);
  animation-name: identifier67;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes identifier67 {
  0% {
    height: 0px;
  }
  100% {
    height: 67px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  @keyframes identifier67 {
    0% {
      height: 0px;
    }
    100% {
      height: 67px;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  @keyframes identifier67 {
    0% {
      height: 0px;
    }
    100% {
      height: 67px;
    }
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  @keyframes identifier67 {
    0% {
      height: 0px;
    }
    100% {
      height: 67px;
    }
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  @keyframes identifier67 {
    0% {
      height: 0px;
    }
    100% {
      height: 67px;
    }
  }
}
@media only screen and (min-width:2303px) {
  @keyframes identifier67 {
    0% {
      height: 0px;
    }
    100% {
      height: 77px;
    }
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnareaWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnareaWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnareaWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnareaWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xnareaWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsaveWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 95%;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsaveWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsaveWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsaveWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xnsaveWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xnsavemenuName {
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xnsavemenuName {
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xnsavemenuName {
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xnsavemenuName {
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 10px;
    margin-left: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xnsavemenuName {
    font-family: var(--font-family);
    font-size: 20px;
    margin-top: 10px;
    margin-left: 5px;
  }
}

.xnabcwrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xncreateproductionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xncreateproductionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xncreateproductionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xncreateproductionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xncreateproductionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xncreatenewItem, .xncreatenewItem1 {
    font-size: 24px;
    color: var(--rgbaVal);
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xncreatenewItem, .xncreatenewItem1 {
    font-size: 30px;
    color: var(--rgbaVal);
    margin-right: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xncreatenewItem, .xncreatenewItem1 {
    font-size: 30px;
    color: var(--rgbaVal);
    margin-right: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xncreatenewItem, .xncreatenewItem1 {
    font-size: 30px;
    color: var(--rgbaVal);
    margin-right: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xncreatenewItem, .xncreatenewItem1 {
    font-size: 40px;
    color: var(--rgbaVal);
    margin-right: 10px;
    cursor: pointer;
  }
}

.xncreatenewItem1 {
  color: black;
  margin-right: 10px;
  cursor: pointer;
}/*# sourceMappingURL=productionNew1.css.map */