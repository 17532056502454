@import '../style/MainStyles.scss';/* styles.css */

.cookie-consent {
    position: fixed;
    bottom: -25%; // Start off-screen
    left: 0;
    right: 0;
    background-color:transparent; // Background color
    //border-top: 1px solid #e0e0e0; // Lighter border
    padding: 20px; // Padding for spacing
  //  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15); // Shadow for depth
    z-index: 1000;
    text-align: center;
    transition: bottom 0.5s ease; // Transition for bottom property
    max-height: 15vh; // Maximum height of 15% of viewport height
    overflow: hidden; // Hide overflow to ensure clean appearance
  
    &.visible {
      bottom: 0; // Move to visible position
    }
  
    p {
      font-size: 16px; // Font size
      color: #333; // Text color
      margin: 0 0 10px; // Margin for spacing
    }
    @include gadgets-to(mobile){
       max-height:45vh; 
       bottom:-55%;
    }
  }
  
  .cookie-consent-content {
    max-width: 700px; // Max width for content
    margin: 0 auto; // Center content
    line-height: 1.5; // Line height for readability
    border:2px solid map-get($generalStyles,datalistBorderColor);
    background-color: white;
    border-radius:8px;
    padding:10px;
    box-shadow: 12px 12px 10px 5px rgba(0,0,0,0.5);
  
  }
  
  .cookie-consent-button {
    margin-top: 10px; // Margin above button
    padding: 12px 24px; // Padding for button
    background-color: #007bff; // Primary color
    color: #ffffff; // Button text color
    border: none; // No border
    border-radius: 5px; // Rounded corners
    cursor: pointer; // Pointer cursor
    font-size: 16px; // Font size
    transition: background-color 0.3s ease, transform 0.3s ease; // Button transition
    
    &:hover {
      background-color: #0056b3; // Hover color
      transform: translateY(-2px); // Lift effect on hover
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2); // Shadow on hover
}
  
    &:focus {
      outline: none; // Remove default focus outline
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); // Focus effect
    }
  }
  
  