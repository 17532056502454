@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
.navbar {
  display: grid;
  grid-template-areas: "a b c d e f g h i j k";
  grid-template-rows: 100%;
  width: 100%;
  height: 6.5vh;
  background-color: white;
  z-index: 90;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
}

/* Links inside the navbar */
.navbar a {
  float: left;
  font-size: 16px;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  padding-left: 160px;
}

/* The dropdown container */
.dropdown {
  padding-top: 10px;
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  font-family: Arial, Helvetica, sans-serif; /* Important for vertical align on mobile phones */
  font-size: 15px;
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: rgb(245, 245, 237);
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 90;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  border-radius: 7px;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgb(245, 245, 237);
  border-radius: 7px;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.helperTopWrapper {
  width: 240px;
  height: 49vh;
  border: none;
  outline: none;
  resize: none;
}

.helperTextarea {
  width: 240px;
  height: 30vh;
  border: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  border-radius: 7px;
}

.helperInfoButton {
  background-color: white;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.helperInfoIcon {
  color: black;
}

.helperSupportIcon {
  color: black;
}

.header-menu {
  position: relative; /* Ensure the dropdown is positioned relative to the header menu */
}

.helper-content {
  position: absolute;
  top: 110%; /* Position below the button */
  right: -75%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(85, 82, 82, 0.6);
  z-index: 1000; /* Ensure it appears above other content */
  width: 250px; /* Adjust width as needed */
  border-radius: 20px;
  border: 5px solid var(--rgbaVal);
}

.logo {
  font-family: "Lobster", cursive;
  font-size: 30px;
  margin-left: 15px;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 40px;
  width: 40px;
}

.logoName {
  grid-area: a;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 26px;
  color: purple;
  margin-top: 6px;
  margin-left: 10px;
  text-align: left;
  padding-top: 10px;
}

.startlink {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.startlinkJAP {
  font-family: "ZenOldMincho";
  font-size: 16px;
}

.hpTopWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hpInfoTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
}

.hpTextArea {
  width: 100%;
  height: 40vh;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  border-color: var(--rgbaVal);
  outline: none;
  border: none;
  resize: none;
  padding: 3px;
  font-size: 16px;
  font-family: var(--font-family);
  max-height: 40vh;
  overflow: auto;
}
.hpTextArea::-webkit-scrollbar {
  width: 10px;
}
.hpTextArea::-webkit-scrollbar-track {
  background: var(--rgbaVal2);
}
.hpTextArea::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid;
  border-color: grey;
}

.hpSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.hpSpinner {
  color: black;
}

.hpSpinner-text {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  font-size: 8px;
  font-family: var(--font-family);
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--rgbaVal);
  padding: 3px;
}/*# sourceMappingURL=HeaderMenu.css.map */