:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xmtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.xmwindowBackground {
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmwindowArea, .xmwindowArea1 {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "a" "b" "c" "d" "e";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmwindowArea, .xmwindowArea1 {
    display: grid;
    grid-template-columns: 25% 50% 23%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmwindowArea, .xmwindowArea1 {
    display: grid;
    grid-template-columns: 25% 50% 24%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmwindowArea, .xmwindowArea1 {
    display: grid;
    grid-template-columns: 25% 50% 24%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xmwindowArea, .xmwindowArea1 {
    display: grid;
    grid-template-columns: 25% 50% 24%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmwindowAreaA, .xmwindowAreaC, .xmwindowAreaBJAP, .xmwindowAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 22vh;
    border-radius: 6px;
    background-color: var(--val);
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmwindowAreaA, .xmwindowAreaC, .xmwindowAreaBJAP, .xmwindowAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 85vh;
    border-radius: 6px;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) and (max-width: 1195px) {
  .xmwindowAreaA, .xmwindowAreaC, .xmwindowAreaBJAP, .xmwindowAreaB {
    height: 77vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmwindowAreaA, .xmwindowAreaC, .xmwindowAreaBJAP, .xmwindowAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    border-radius: 6px;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmwindowAreaA, .xmwindowAreaC, .xmwindowAreaBJAP, .xmwindowAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    border-radius: 6px;
    background-color: var(--val);
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xmwindowAreaA, .xmwindowAreaC, .xmwindowAreaBJAP, .xmwindowAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    border-radius: 6px;
    background-color: var(--val);
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmwindowAreaB {
    grid-area: d;
    height: 58svh;
    margin-top: 1vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xmwindowAreaB {
    height: 60vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) {
  .xmwindowAreaB {
    height: 59vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) {
  .xmwindowAreaB {
    height: 59vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .xmwindowAreaB {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) {
  .xmwindowAreaB {
    height: 61vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xmwindowAreaB {
    height: 60vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmwindowAreaB {
    grid-area: d;
    height: 85vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xmwindowAreaB {
    height: 77vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmwindowAreaB {
    grid-area: d;
    height: 84vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmwindowAreaB {
    grid-area: d;
    height: 84vh;
  }
}
@media only screen and (min-width:2303px) {
  .xmwindowAreaB {
    grid-area: d;
    height: 84vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmwindowAreaBJAP {
    grid-area: d;
    height: 59svh;
    margin-top: 1vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xmwindowAreaBJAP {
    height: 62vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) {
  .xmwindowAreaBJAP {
    height: 61vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) {
  .xmwindowAreaBJAP {
    height: 61vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xmwindowAreaBJAP {
    height: 62vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmwindowAreaBJAP {
    grid-area: d;
    height: 85vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmwindowAreaBJAP {
    grid-area: d;
    height: 84vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmwindowAreaBJAP {
    grid-area: d;
    height: 84vh;
  }
}
@media only screen and (min-width:2303px) {
  .xmwindowAreaBJAP {
    grid-area: d;
    height: 85vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmwindowAreaC {
    grid-area: e;
    border: none;
    height: 58vh;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmwindowAreaC {
    grid-area: e;
    border: none;
    height: 85vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xmwindowAreaC {
    height: 77vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmwindowAreaC {
    grid-area: e;
    border: none;
    height: 84vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmwindowAreaC {
    grid-area: e;
    border: none;
    height: 84vh;
  }
}
@media only screen and (min-width:2303px) {
  .xmwindowAreaC {
    grid-area: e;
    border: none;
    height: 84vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmheaderArea {
    grid-area: a;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xmheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xmcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xmcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xmrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmrange1, .xmrange2 {
    grid-area: b;
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmrange1, .xmrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 20px;
    margin-right: 2%;
    cursor: pointer;
    grid-area: b;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmrange1, .xmrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmrange1, .xmrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xmrange1, .xmrange2 {
    accent-color: blue;
    width: 125px;
    margin-right: 10px;
    cursor: pointer;
    margin-left: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xmrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xmareaATop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.xmareaAFieldset {
  border: none;
  border-color: rgb(223, 212, 212);
  width: 70%;
  height: 5.7vh;
  margin-left: 1px;
  margin-top: 4px;
}

.xmareaALegend {
  font-size: 12px;
  font-family: var(--font-family);
  color: black;
  margin-left: 1px;
  margin-top: 10px;
}

.xmareaASelect {
  width: 99%;
  height: 30px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  outline: none;
  font-family: var(--font-family);
  font-size: 16px;
  color: black;
  border-radius: 6px;
  margin-left: 1px;
  background-color: white;
}
@media only screen and (min-width:2303px) {
  .xmareaASelect {
    height: 40px;
    font-size: 16px;
  }
}

.xmareaAMiddle {
  margin-top: 1px;
}

.xmdataAwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaADataArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    max-height: 12vh;
    overflow-y: scroll;
  }
  .xmareaADataArea::-webkit-scrollbar {
    width: 1px;
  }
  .xmareaADataArea::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xmareaADataArea::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaADataArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height: 80vh;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaADataArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height: 80vh;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaADataArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height: 80vh;
    overflow-y: scroll;
    margin-left: 25px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaADataArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height: 80vh;
    overflow-y: scroll;
    margin-left: 25px;
  }
}

.xmareaADataButton, .xmareaADataButton1 {
  width: auto;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  margin-left: 3px;
  margin-top: 3px;
  border-radius: 6px;
  color: black;
  font-size: 12px;
  font-family: var(--font-family);
  outline: none;
  cursor: pointer;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaADataButton, .xmareaADataButton1 {
    height: 40px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaADataButton, .xmareaADataButton1 {
    height: 40px;
    font-size: 16px;
  }
}

.xmareaADataButton1 {
  background-color: var(--rgbaVal2);
}

.xmbtopWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBTop {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBTop {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBTop {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBTop {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBTop {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 80%;
  }
}

.xmingredSelectWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xmrecipeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 32px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 32px;
    background-color: transparent;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 32px;
    background-color: transparent;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 32px;
    background-color: transparent;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 50px;
    background-color: transparent;
  }
}

.xmareaBLegend {
  font-size: 12px;
  font-family: var(--font-family);
  color: black;
  margin-top: 10px;
}

.xmareaBSelect {
  width: 100%;
  height: 25px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  outline: none;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  border-radius: 6px;
  background-color: white;
}
@media only screen and (min-width:2303px) {
  .xmareaBSelect {
    height: 35px;
    font-size: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBMiddle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBMiddle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBMiddle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBMiddle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBMiddle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.xmareaBHeaderTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
@media only screen and (min-width:2303px) {
  .xmareaBHeaderTop {
    width: 80%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBDataArea {
    width: 100%;
    height: 32vh;
    max-height: 32vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xmareaBDataArea {
    height: 35vh;
    max-height: 35vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) and (min-height: 750px) {
  .xmareaBDataArea {
    height: 40vh;
    max-height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) {
  .xmareaBDataArea {
    height: 37vh;
    max-height: 37vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xmareaBDataArea {
    height: 60vh;
    height: 60vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBDataArea::-webkit-scrollbar {
    width: 1px;
  }
  .xmareaBDataArea::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xmareaBDataArea::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBDataArea {
    width: 100%;
    height: 58vh;
    max-height: 58vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .xmareaBDataArea::-webkit-scrollbar {
    width: 1px;
  }
  .xmareaBDataArea::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xmareaBDataArea::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBDataArea {
    width: 90%;
    height: 58vh;
    max-height: 58vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .xmareaBDataArea::-webkit-scrollbar {
    width: 1px;
  }
  .xmareaBDataArea::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xmareaBDataArea::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBDataArea {
    width: 80%;
    height: 58vh;
    max-height: 58vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .xmareaBDataArea::-webkit-scrollbar {
    width: 1px;
  }
  .xmareaBDataArea::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xmareaBDataArea::-webkit-scrollbar-track {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBDataArea {
    width: 80%;
    height: 58vh;
    max-height: 58vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .xmareaBDataArea::-webkit-scrollbar {
    width: 1px;
  }
  .xmareaBDataArea::-webkit-scrollbar-thumb {
    background-color: rgb(223, 212, 212);
    border-radius: 10px;
  }
  .xmareaBDataArea::-webkit-scrollbar-track {
    background-color: white;
  }
}

.xmareaBBottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-top: 5px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin-top: 5px;
    margin-left: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin-top: 5px;
    margin-left: 10px;
  }
}

.xmdatalistRows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.xmareaBHeaderProduktid, .xmareaBHeaderDelete, .xmareaBHeaderKalorier, .xmareaBHeaderTotalt, .xmareaBHeaderAmount, .xmareaBHeaderBenaemn {
  width: 30px;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  border-radius: 6px;
  font-size: 12px;
  font-family: var(--font-family);
  color: black;
  padding: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBHeaderBenaemn {
    width: 42%;
    width: 42%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBHeaderBenaemn {
    width: 42%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBHeaderBenaemn {
    width: 42%;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBHeaderBenaemn {
    width: 42%;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBHeaderBenaemn {
    width: 42%;
    height: 35px;
    font-size: 16px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBHeaderAmount {
    width: calc(17% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBHeaderAmount {
    width: calc(17% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBHeaderAmount {
    width: calc(17% - 2px);
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBHeaderAmount {
    width: calc(17% - 2px);
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBHeaderAmount {
    width: calc(17% - 2px);
    margin-left: 2px;
    height: 35px;
    font-size: 16px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBHeaderTotalt {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBHeaderTotalt {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBHeaderTotalt {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBHeaderTotalt {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBHeaderTotalt {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 35px;
    font-size: 16px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBHeaderKalorier {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBHeaderKalorier {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBHeaderKalorier {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBHeaderKalorier {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBHeaderKalorier {
    width: calc(17% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 35px;
    font-size: 16px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaBHeaderDelete {
    width: calc(7% - 2px);
    padding: 1px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaBHeaderDelete {
    width: calc(7% - 2px);
    padding: 1px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaBHeaderDelete {
    width: calc(7% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaBHeaderDelete {
    width: calc(7% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaBHeaderDelete {
    width: calc(7% - 2px);
    padding: 1px;
    margin-left: 2px;
    height: 35px;
    font-size: 16px;
  }
}

.xmnameHeaderInput {
  width: 95%;
  height: 25px;
  border: none;
  background-color: transparent;
  font-family: var(--font-family);
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  outline: none;
  color: black;
  margin-left: 5px;
}
@media only screen and (min-width:2303px) {
  .xmnameHeaderInput {
    font-size: 30px;
  }
}

.xmdatalistProduktid, .xmdatalistKalorier, .xmdatalistDelete, .xmdatalistDelete1, .xmdatalistKalorier1, .xmdatalistPris, .xmdatalistPris1, .xmdatalistMangd, .xmdatalistMangd1, .xmdatalistBenaemn, .xmdatalistBenaemn1 {
  width: 30px;
  height: 25px;
  margin-top: 3px;
  outline: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: var(--rgbaVal2);
  font-family: var(--font-family);
  font-size: 12px;
  padding: 1px;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmdatalistProduktid, .xmdatalistKalorier, .xmdatalistDelete, .xmdatalistDelete1, .xmdatalistKalorier1, .xmdatalistPris, .xmdatalistPris1, .xmdatalistMangd, .xmdatalistMangd1, .xmdatalistBenaemn, .xmdatalistBenaemn1 {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmdatalistProduktid, .xmdatalistKalorier, .xmdatalistDelete, .xmdatalistDelete1, .xmdatalistKalorier1, .xmdatalistPris, .xmdatalistPris1, .xmdatalistMangd, .xmdatalistMangd1, .xmdatalistBenaemn, .xmdatalistBenaemn1 {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmdatalistProduktid, .xmdatalistKalorier, .xmdatalistDelete, .xmdatalistDelete1, .xmdatalistKalorier1, .xmdatalistPris, .xmdatalistPris1, .xmdatalistMangd, .xmdatalistMangd1, .xmdatalistBenaemn, .xmdatalistBenaemn1 {
    width: 30px;
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xmdatalistProduktid, .xmdatalistKalorier, .xmdatalistDelete, .xmdatalistDelete1, .xmdatalistKalorier1, .xmdatalistPris, .xmdatalistPris1, .xmdatalistMangd, .xmdatalistMangd1, .xmdatalistBenaemn, .xmdatalistBenaemn1 {
    width: 30px;
    height: 35px;
    font-size: 16px;
  }
}

.xmdatalistBenaemn, .xmdatalistBenaemn1 {
  width: 42%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xmdatalistBenaemn1 {
  background-color: var(--rgbaVal);
}

.xmdatalistMangd, .xmdatalistMangd1 {
  width: calc(17% - 2px);
  margin-left: 2px;
}

.xmdatalistMangd1 {
  background-color: var(--rgbaVal);
}

.xmdatalistPris, .xmdatalistPris1 {
  width: calc(17% - 2px);
  margin-left: 2px;
}

.xmdatalistPris1 {
  background-color: var(--rgbaVal);
}

.xmdatalistKalorier, .xmdatalistDelete, .xmdatalistDelete1, .xmdatalistKalorier1 {
  width: calc(17% - 2px);
  margin-left: 2px;
}

.xmdatalistKalorier1 {
  background-color: var(--rgbaVal);
}

.xmdatalistDelete, .xmdatalistDelete1 {
  width: calc(7% - 2px);
  padding: 1px;
  margin-left: 2px;
}

.xmdatalistDelete1 {
  background-color: var(--rgbaVal);
}

.xmdeleteImage {
  color: var(--rgbaVal);
  font-size: 13px;
  cursor: pointer;
  padding: 1px;
}

.xmeditproductTop {
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmeditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    height: 60px;
    margin-top: 2px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmeditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    height: 60px;
    margin-top: 2px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmeditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    height: 60px;
    margin-top: 2px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmeditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    height: 60px;
    margin-top: 2px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xmeditproductArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    height: 60px;
    margin-top: 2px;
    border-radius: 6px;
  }
}

.xmplussign {
  font-family: var(--font-family);
  font-size: 42px;
  margin-top: 8px;
  margin-left: 10px;
  color: var(--rgbaVal);
  cursor: pointer;
}

.xmminussign {
  font-family: var(--font-family);
  font-size: 42px;
  margin-top: 6px;
  margin-left: 5px;
  color: var(--rgbaVal);
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmproductnewSpinnerContainer {
    position: fixed;
    top: 50%;
    left: 45%;
    background-color: transparent;
    transform: scale(2);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmproductnewSpinnerContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: scale(2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmproductnewSpinnerContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: scale(2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmproductnewSpinnerContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: scale(2);
  }
}
@media only screen and (min-width:2303px) {
  .xmproductnewSpinnerContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: scale(2);
  }
}

.xmproducNewSpinner {
  color: black;
}

.xmsaveproductButton {
  width: 70px;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: white;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 32px;
  margin-top: 4px;
  cursor: pointer;
  opacity: 0.5;
}

.xmsaveproductButtonActive {
  width: 70px;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: white;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 32px;
  margin-top: 4px;
  cursor: pointer;
  border: 6px;
  border-color: rgb(223, 212, 212);
}

.xmbuttonTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.xmsaveOldproductButton {
  width: 70px;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 72px;
  margin-top: 4px;
  cursor: pointer;
  opacity: 0.5;
}

.xmsaveOldproductButtonActive {
  width: 70px;
  height: 30px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-left: 72px;
  margin-top: 4px;
  cursor: pointer;
  opacity: 1;
}

.xmsaveButtonOldIcon {
  color: white;
  font-size: 22px;
  cursor: pointer;
  margin-left: 5px;
  opacity: 1;
}

.xmsaveButtonIcon {
  color: white;
  font-size: 22px;
  cursor: pointer;
  margin-left: 5px;
  opacity: 1;
}

.xmsaveButtonIconActive {
  color: white;
  font-size: 26px;
  cursor: pointer;
  margin-left: 5px;
}

.xmsaveRecepyMenuTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmsaveRecepyMenuArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    background-color: var(--rgbaVal);
    width: 90%;
    height: 20svh;
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 740px) {
  .xmsaveRecepyMenuArea {
    height: 25vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmsaveRecepyMenuArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--rgbaVal);
    width: 100%;
    height: 20svh;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmsaveRecepyMenuArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--rgbaVal);
    height: 15vh;
    width: 100%;
    height: 20svh;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmsaveRecepyMenuArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--rgbaVal);
    height: 15vh;
    width: 100%;
    height: 20svh;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xmsaveRecepyMenuArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--rgbaVal);
    height: 15vh;
    width: 100%;
    height: 20svh;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmsaveRecepyMenuFieldset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    height: auto;
    background-color: transparent;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmsaveRecepyMenuFieldset {
    border: none;
    width: 90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmsaveRecepyMenuFieldset {
    border: none;
    width: 90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmsaveRecepyMenuFieldset {
    border: none;
    width: 90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xmsaveRecepyMenuFieldset {
    border: none;
    width: 90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmsaveLegendWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmsaveLegendWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmsaveLegendWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmsaveLegendWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xmsaveLegendWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 3px;
  }
}

.xmsaveRecepyMenuLegend {
  font-size: 14px;
  font-family: var(--font-family);
  color: black;
  margin-top: 10px;
}

.xmsaveRecepyMenuInput2 {
  width: 90%;
  height: 30px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 10px;
  outline: none;
  border-radius: 6px;
  box-sizing: border-box;
}

.xmselectArea {
  margin-top: 5px;
  width: 100%;
}

.xmsaveproductselect {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 90%;
  height: 30px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 5px;
  outline: none;
  border-radius: 6px;
  background-color: white;
  box-sizing: border-box;
}

.xmsaveRecepyMenuButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 90%;
}

.xmsaveRecepyMenuButton {
  width: 100px;
  height: 30px;
  background-color: var(--rgbaVal2);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-top: 5px;
  cursor: not-allowed;
  opacity: 0.8;
  border: none;
}

.xmsaveRecepyMenuButtonActive {
  width: 100px;
  height: 30px;
  background-color: rgb(229, 64, 68);
  border: none;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 155px;
  margin-top: 5px;
  cursor: pointer;
  opacity: 1;
  color: white;
}

.xmtrashContainer {
  font-size: 22px;
  color: var(--rgbaVal);
  cursor: pointer;
  border: none;
  border-color: var(--rgbaVal);
  padding: 10px 8px 8px 8px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--rgbaVal);
  color: white;
}
@media only screen and (min-width:2303px) {
  .xmtrashContainer {
    font-size: 22px;
    color: var(--rgbaVal);
    cursor: pointer;
    border: none;
    border-color: var(--rgbaVal);
    padding: 14px 12px 8px 12px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--rgbaVal);
    color: white;
  }
}

.xmTrash {
  color: white;
  font-size: 24px;
  cursor: pointer;
  opacity: 1;
}
@media only screen and (min-width:2303px) {
  .xmTrash {
    font-size: 36px;
  }
}

.xmTrashHovered {
  color: red;
  font-size: 30px;
  cursor: pointer;
  opacity: 1;
}
@media only screen and (min-width:2303px) {
  .xmTrashHovered {
    font-size: 36px;
  }
}

.xmareacFieldsetWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 88%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaCTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaCTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaCTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaCTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaCTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmareaCFieldset {
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 45vh;
    background: transparent;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmareaCFieldset {
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    width: 98%;
    height: 67vh;
    background: transparent;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmareaCFieldset {
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 60vh;
    background: transparent;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmareaCFieldset {
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 60vh;
    background: transparent;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xmareaCFieldset {
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 60vh;
    background: transparent;
    margin-top: 5px;
  }
}

.xmareaCFieldsetJAP {
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: transparent;
  margin-top: 5px;
}

.xmareaCLegend {
  font-size: 16px;
  font-family: var(--font-family);
  color: black;
  margin-top: 10px;
}

.xmnutritionMix {
  color: var(--rgbaVal);
  font-size: 26px;
}

.xmproduktkalkylinfoArea {
  font-family: var(--font-family);
  font-size: 16px;
  color: black;
  margin-top: 5px;
  margin-left: 1px;
}
@media only screen and (min-width:2303px) {
  .xmproduktkalkylinfoArea {
    font-size: 30px;
  }
}

.xmnutrientInfoTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 10px;
  margin-top: 5px;
  margin-left: 1px;
}

.xmnutrientInfoAreaHeader {
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 20px;
  margin-bottom: 5px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoAreaHeader {
    font-size: 30px;
  }
}

.xmnutrientInfoKalorierHeader {
  font-family: var(--font-family);
  font-size: 16px;
  margin-bottom: 1px;
  color: brown;
  width: 50%;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoKalorierHeader {
    font-size: 30px;
  }
}

.xmnutrientInfoKalorier {
  font-family: var(--font-family);
  font-size: 16px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoKalorier {
    font-size: 30px;
  }
}

.xmnutrientInfoProteinHeader {
  font-family: var(--font-family);
  font-size: 16px;
  color: #778D45;
  margin-bottom: 1px;
  margin-top: 1px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoProteinHeader {
    font-size: 30px;
  }
}

.xmnutrientInfoProtein {
  font-family: var(--font-family);
  font-size: 16px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoProtein {
    font-size: 30px;
  }
}

.xmnutrientInfoFatHeader {
  font-family: var(--font-family);
  font-size: 16px;
  color: rgb(229, 64, 68);
  margin-bottom: 1px;
  margin-top: 1px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoFatHeader {
    font-size: 30px;
  }
}

.xmnutrientInfoFat {
  font-family: var(--font-family);
  font-size: 16px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoFat {
    font-size: 30px;
  }
}

.xmnutrientInfoCarbohydrateHeader {
  font-family: var(--font-family);
  font-size: 16px;
  color: orange;
  margin-bottom: 1px;
  margin-top: 1px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoCarbohydrateHeader {
    font-size: 30px;
  }
}

.xmnutrientInfoCarbohydrate {
  font-family: var(--font-family);
  font-size: 16px;
}
@media only screen and (min-width:2303px) {
  .xmnutrientInfoCarbohydrate {
    font-size: 30px;
  }
}

.xmsaveRecepyMenuTopJAP {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmsaveRecepyMenuFieldsetJAP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    height: auto;
    background-color: transparent;
    margin-top: 2px;
  }
}

.xmsaveRecepyMenuLegendJAP {
  font-size: 14px;
  font-family: var(--font-family);
  color: black;
  margin-top: 10px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmsaveRecepyMenuAreaJAP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    background-color: var(--rgbaVal);
    width: 90%;
    height: 25svh;
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 740px) {
  .xmsaveRecepyMenuAreaJAP {
    height: 27vh;
  }
}

.xmsaveRecepyMenuInput2JAP {
  width: 90%;
  height: 30px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 10px;
  outline: none;
  border-radius: 6px;
  box-sizing: border-box;
}

.xmsaveRecepyMenuFuriganaInputJAP {
  width: 90%;
  height: 30px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 10px;
  outline: none;
  border-radius: 6px;
  box-sizing: border-box;
}

.xmselectAreaJAP {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
}

.xmsaveproductselectJAP {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 90%;
  height: 30px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 5px;
  outline: none;
  border-radius: 6px;
  background-color: white;
  box-sizing: border-box;
}

.xmsaveRecepyMenuButtonJAP {
  width: 100px;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-top: 3px;
  cursor: not-allowed;
  opacity: 0.5;
}

.xmsaveRecepyMenuButtonActiveJAP {
  width: 100px;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: rgb(229, 64, 68);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 155px;
  margin-top: 3px;
  cursor: pointer;
  opacity: 1;
}

.xmimageUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.xmimageUploadWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.xminputimageUpload {
  display: none;
}

.xmfileLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 5px;
  height: 25px;
  width: 120px;
  background-color: var(--rgbaVal);
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
}

.xmbuttonUploadImageWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 50%;
}

.xmbuttonUploadImage, .xmbuttonUploadImage1 {
  margin-left: 5px;
  width: 120px;
  height: 30px;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  opacity: 0.7;
}

.xmbuttonUploadImage1 {
  background-color: rgb(229, 64, 68);
  color: white;
  cursor: pointer;
}

.xmfileName {
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 3px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.xmimageUploadMessage {
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 7px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmimageUploadMessage {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmimageUploadMessage {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmimageUploadMessage {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmimageUploadMessage {
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xmimageUploadMessage {
    font-size: 14px;
  }
}

.xmingredcommentWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.xmanimateddivCreateProduct {
  width: 100%;
  transform: rotate(0deg);
  animation-name: identifierCreateProduct;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes identifierCreateProduct {
  0% {
    height: 0px;
  }
  100% {
    height: 65px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmimageinput {
    width: 70%;
    height: 15svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmimageinput {
    width: 70%;
    height: 15svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmimageinput {
    width: 70%;
    height: 15svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmimageinput {
    width: 70%;
    height: 15svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width:2303px) {
  .xmimageinput {
    width: 70%;
    height: 15svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 24px;
    resize: none;
  }
}

.xmcreateProductJAPMobile {
  margin-left: 7px;
  width: 95%;
  outline: none;
  border: none;
  border-radius: 3px;
}

.xmcreatenewitem {
  font-size: 30px;
  color: var(--rgbaVal);
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .xmcreatenewitem {
    font-size: 40px;
  }
}

.xmcreatenewitem1 {
  font-size: 30px;
  color: var(--rgbaVal);
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .xmcreatenewitem1 {
    font-size: 40px;
  }
}

.xmcreateNewItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.xmtab-button2, .xmtab-button1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 231, 231);
  padding: 3px 4px;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 12px;
  border-top-right-radius: 6px;
  border: 1px solid transparent;
  width: 50%;
  margin: 2px;
  border-top-right-radius: 12px;
}

.xmtab-button1 {
  background-color: white;
}

.xmproduktPris {
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:2303px) {
  .xmproduktPris {
    font-size: 30px;
  }
}

@media only screen and (min-width:2303px) {
  .xmImageContainerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmImageContainerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmImageContainerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
}

.xmnochosenProduktid {
  font-family: var(--font-family);
  font-size: 10px;
  margin-left: 3px;
  color: red;
}

.xmbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.xmbuttonIcon {
  position: absolute;
  top: 0;
  right: 0;
  color: gold;
  font-size: 18px;
}

.xmbuttonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  overflow: hidden;
}

.xmDescriptionTextarea {
  width: 95%;
  height: 30vh;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px;
  font-size: 16px;
  font-family: var(--font-family);
  resize: none;
  outline: none;
  box-sizing: border-box;
  margin-top: 5px;
  margin-left: 1px;
}
.xmDescriptionTextarea::-webkit-scrollbar {
  width: 10px;
}
.xmDescriptionTextarea::-webkit-scrollbar-track {
  background: var(--rgbaVal2);
}
.xmDescriptionTextarea::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--rgbaVal);
}

.xmDescriptionTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.xmButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 95%;
  margin-top: 5px;
}

.xmSaveProductButton, .xmSaveProductButton1 {
  width: 120px;
  height: 30px;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-top: 5px;
  cursor: pointer;
  opacity: 0.7;
  border: none;
}

.xmSaveProductButton1 {
  background-color: rgb(229, 64, 68);
  color: white;
  opacity: 1;
}

.xmTextLength {
  font-family: var(--font-family);
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-left: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xmIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 10px;
    margin-left: 3px;
    color: black;
  }
  .xmIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xmIconText {
    white-space: nowrap;
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xmIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xmIconText {
    white-space: nowrap;
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xmIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xmIconText {
    white-space: nowrap;
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xmIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width:2303px) {
  .xmIconText {
    white-space: nowrap;
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 16px;
    margin-left: 3px;
    color: black;
  }
  .xmIconText:hover {
    font-weight: bold;
  }
}/*# sourceMappingURL=CreateProductNew2.css.map */