@import '../style/MainStyles.scss';/* styles.css */


.xototWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}




.xoprodStatArea {
    @include gadgets-to(mobile) {

        display: grid;
        grid-template-rows:6% 4% 46% 40%;
       // grid-template-rows:auto auto auto auto;
    
         grid-template-areas:
             "a"
             "b"
             "c"
             "d"
             ;
         width: 100%;
         height:auto;
         object-fit: cover;

         @media(min-width:410px) and (max-width:413px){
            grid-template-rows:6% 4% 43% 40%;
    }
}

    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:24% 40% 35%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d d";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:24% 40% 35%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d d";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:24% 40% 35%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d d";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:24% 40% 35%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d d";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    

}
.xocompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color:white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 36px;
        color: white;
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xocompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 36px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}


.xoprodStatArea1 {
    @extend .xoprodStatArea;
    background-color: map-get($generalStyles, backgroundColor);
}

.xoprodStatAreaA {
    @include gadgets-to(mobile) {

    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:2px;
    border-radius: map-get($generalStyles, threeBorderRadius);
    height:40vh;
}
@include gadgets-to(tablet) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
   // margin-top:2px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    height:80vh;
    @media(min-width:1170px) and (max-width:1190px)
    {
        height:75vh;
    }
}
@include gadgets-to(laptop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
    height:80vh;
}
@include gadgets-to(desktop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
    height:80vh;

}
@include gadgets-to(largeDesktop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
    height:80vh;
}
}

.xoprodStatAreaB {
    @include gadgets-to(mobile) {
    grid-area: d;
    height:44vh;
    //margin-top:6px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
    @media(min-width:375px) and (max-width:380px){
        height: 40vh;
    
    }
    @media(min-width:384px) and (max-width:392px){
        height:40vh;
    
    }


    @media(min-width:393px) and (max-width:409px){
        height: 40vh;
    
    }

    @media(min-width:410px) and (max-width:413px){
        height:41vh;
        margin-top:20px;
    
    }

    @media(min-width:414px){
        height:40vh;
    
    }
    @media(min-width:428px){
        height:40vh;
    
    }

}
@include gadgets-to(tablet) {
    grid-area: d;
    height:80vh;
   // margin-top:6px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
    
    @media(min-width:1170px) and (max-width:1190px)
    {
        height:75vh;
    }
}
@include gadgets-to(laptop) {
    grid-area: d;
    height:80vh;
   // margin-top:6px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
    grid-area: d;
    height:80vh;
   // margin-top:6px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    grid-area: d;
    height:80vh;
   // margin-top:6px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
}
}

.xoheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;
        margin-top:5px;

    }
@include gadgets-to(laptop) {
        grid-area: a;
        height:30px;
    }
@include gadgets-to(desktop) {
        grid-area: a;
         height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
        height:50px;
    }

}

.xorangeArea{
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
        //  @media(min-width:1081px) and (max-width:1170px)
        //  {
        //     height:20px;
        //  }
        //  // as ipad
        // //  @media(min-width:1171px) and (max-width:1195px)
        // //  {
         
        // //     height:auto;
        // //  }

        //  @media(min-width:1196px) and (max-width:1400px)
        //  {
        //     height:20px;
        //  }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}
.xorange1{
    @include gadgets-to(mobile) {
        grid-area:b;
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:20px;
        margin-right:2%;
        cursor:pointer;
        grid-area:b;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-right:10px;
        cursor:pointer;
        margin-left:10px;
    }
}

.xorange2 {
    @extend .xorange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
        margin-right:1%;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}

.xoprodstatTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.xoprodstatFieldset {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
   // margin-left: 62px;
    margin-top:10px;
    border: none;
    padding:0;

}

.xoprodstatLegend{
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:5%;

}

.xoprodstatFieldsetFrom {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
   // margin-left: 62px;
    margin-top:5px;
    border: none;
    margin-top:5px;
    padding:0;

}
.xoprodstatLegendFrom{
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:5%;
}

.xoprodstatFieldsetTo {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
   // margin-left: 62px;
    margin-top:5px;
    border: none;
    margin-top:5px;
    padding:0;

}
.xoprodstatLegendTo{
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:5%;
}

.xoprodstatSelect {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:black;
}
.xoprodstatStartDate{
    @include gadgets-to(mobile) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:black;
}
@include gadgets-to(tablet) {
    -webkit-appearance: none;
    width: 85%;
    height: 25px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:black;
}
@include gadgets-to(laptop) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(desktop) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(largeDesktop) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
}
.xoprodstatEndDate{
    @include gadgets-to(mobile) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:black;
}
@include gadgets-to(tablet) {
    -webkit-appearance: none;
    width: 85%;
    height: 25px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:black;
}
@include gadgets-to(laptop) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:black;
}
@include gadgets-to(desktop) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(largeDesktop) {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
}



.xoprodstatOption {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
}

.xoprodstatDataArea {
    display: flex;
    flex-direction: row;
    margin-left: 29px;
}


.xoprodstatFromArea {
    width:100%;
}

.xoprodstatoToArea {
width:100%;
}
.xoprodstatMidWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
   // align-items:center;
    width:100%;
    margin-top:5px;
@include gadgets-to(laptop) {
//     display:flex;
//     flex-direction:row;
//     justify-content:center;
//   //  align-items:center;
    width:100%;
    margin-top:5px;
   // height:50vh;
}
}

.xoprodstatMid {
    @include gadgets-to(mobile) {
    grid-area:c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height:14vh;
    overflow-y: scroll;
    width:90%;
            
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
        @media(min-width:375px) and (max-width:380px){
            height: 40vh;
            @media(max-height:600px){
                height:8vh;
            }
        
        }
        @media(min-width:384px) and (max-width:392px){
            max-height:15vh;
        
        }
    
    
        @media(min-width:393px) and (max-width:409px){
            height: 40vh;
        
        }
    
        @media(min-width:410px) and (max-width:413px){
            height:40vh;
            max-height:18vh;
            @media(max-height:667px){
                max-height:10vh;
            }
        
        }
    
        @media(min-width:414px){
            height:40vh;
        
        }
        @media(min-width:428px){
            height:40vh;
        
        }
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height:55vh;
        overflow-y: scroll;
        width:90%;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
        @media(max-width:1024px){
            max-height:50vh;
        }

        @media(min-width:1170px) and (max-width:1190px)
        {
            max-height:48vh;
        }
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       max-height:50vh;
      height:auto;
        overflow-y: scroll;
        width:90%;

    }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       max-height:50vh;
      height:auto;
        overflow-y: scroll;
        width:90%;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       max-height:50vh;
      height:auto;
        overflow-y: scroll;
        width:90%;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
}


.xoprodstatMidJAP {
    @include gadgets-to(mobile) {
    grid-area:c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height:10vh;
    overflow-y: scroll;
    width:90%;
            
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
        @media(min-width:375px) and (max-width:380px){
            height: 40vh;
            @media(max-height:600px){
                height:8vh;
            }
        
        }
        @media(min-width:384px) and (max-width:392px){
            max-height:12vh;
        
        }
    
    
        @media(min-width:393px) and (max-width:409px){
            height: 40vh;
        
        }
    
        @media(min-width:410px) and (max-width:413px){
            height:40vh;
            max-height:15vh;
            @media(max-height:667px){
                max-height:10vh;
            }
        
        }
    
        @media(min-width:414px){
            height:40vh;
        
        }
        @media(min-width:428px){
            height:40vh;
        
        }
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height:55vh;
        overflow-y: scroll;
        width:90%;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
        @media(max-width:1024px){
            max-height:50vh;
        }

        @media(min-width:1170px) and (max-width:1190px)
        {
            max-height:48vh;
        }
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       max-height:50vh;
      height:auto;
        overflow-y: scroll;
        width:90%;

    }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       max-height:50vh;
      height:auto;
        overflow-y: scroll;
        width:90%;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       max-height:50vh;
      height:auto;
        overflow-y: scroll;
        width:90%;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
}










.xoprodstatButton{
    @include gadgets-to(mobile) {

    width: auto;
   // height:auto;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
 }@include gadgets-to(tablet) {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width: auto;
    height:30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
 }

    @include gadgets-to(laptop) {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width: auto;
  //  height:30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu5);

}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width: auto;
    height:30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width: auto;
    height:40px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
}
}

.xoprodstatButton1{
    width: auto;
   // height:auto;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    width: auto;
    height:30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
}
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    width: auto;
    height:30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width: auto;
    height:30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width: auto;
    height:40px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size:20px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding:5px;
    cursor: pointer;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
}
}



.xoprodstatButtontext {
  //  width: 200px;
   // height: 30px;
  //  background-color: map-get($generalStyles, pinkLightColor);
    color: black;
    border: none;
    border-radius: 3px;
 //   margin-left: 3px;
  //  margin-bottom: 3px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSize);
}

.xoprodstatRightFieldset {
    border: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:12px;
    margin-top:8px;
    background-color: map-get($generalStyles, transparent);
    margin-left: 10px;
}

.xoprodstatRightLegend, .xoprodstatHeader {
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSize);
}

.xoprodstatHeader {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 18px;
}
.xoprodstatChartWrapper{
height:60vh;
@include gadgets-to(mobile) {
    height:27vh;
    @media (max-width:370px){
        height: 22vh;
    
    }
}
@include gadgets-to(tablet) {
    height:60vh;
}
@include gadgets-to(laptop) {
    height:65vh;
}
@include gadgets-to(desktop) {
    height:70vh;
}
@include gadgets-to(largeDesktop) {
    height:68vh;
}
}

.xoChartChoiceArea{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:1vh;



}

.xochartFieldset {
    border: none;
    width: 100%;
    padding:0;
    margin:0;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}

.xochartbackgroundColor {
    background-color: map-get($generalStyles, transparentChangeable);
}

.xochartLegend {
    font-family: map-get($generalStyles,fontFamily);
    font-size:12px;
    color: black;
}

.xouseChartChoicechartbutton, .xouseChartChoicechartbuttonactive {
    margin: 2px;
    width: auto;
    height: 30px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: white;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSize);
    border: none;
    cursor: pointer;
    transition: 0.3s;
}

.xouseChartChoicechartbutton {
    background-color: RGB(255, 128, 0);
}

.xouseChartChoicechartbuttonactive {
    background-color: red;
}


.xoprodStatSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xoprodStatSpinner {
  // styles for prodStatSpinner
}
// .xocompName,
// .xocompName1 {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     top:45px;
//     left: 0;
//     right: 0;
//     font-family: map-get($generalStyles, fontFamilyLobster);
//     font-size: 26px;
//     @media(min-width:375px) and (max-width:380px){
//         top:45px;
    
//     }
//     @media(min-width:384px) and (max-width:392px){
//         top:60px;
    
//     }


//     @media(min-width:393px) and (max-width:409px){
//         top:55px;
    
//     }

//     @media(min-width:410px) and (max-width:413px){
//         top:65px;
    
//     }

//     @media(min-width:414px) and (max-width:427px){
//         top:55px;
//         @media(max-height:700px){
//             top:45px;
//         }

    
//     }
//     @media(min-width:428px){
//         top:45px;
    
//     }
//     @include gadgets-to(tablet) {
//         top:55px;
//         font-size:30px;
//         margin-top:5px;
      
//     }   
//     @include gadgets-to(laptop) {
//         top:75px;
//         font-size: 32px;
     
     
//     }
//     @include gadgets-to(desktop) {
//         top:80px;
//         font-size:44px;
//     }
//     @include gadgets-to(largeDesktop) {
//         top:120px;
//         font-size: 40px;
//     }

// }

// .xocompName {
//     color: map-get($generalStyles, colorwhite);
    
// }

// .xocompName1 {
//     color: map-get($generalStyles, transparentChangeableMenu5);
// }

// .xocompNameJAP{
//     color: map-get($generalStyles, colorwhite);
//         display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     top:47px;
//     left: 0;
//     right: 0;
//     font-family: map-get($generalStyles, fontFamilyLobster);
//     font-size: 26px;
//     @media(min-width:375px) and (max-width:380px){
//         top:45px;
    
    
//     }
//     @media(min-width:384px) and (max-width:392px){
//         top:60px;
    
//     }


//     @media(min-width:393px) and (max-width:409px){
//         top:55px;
    
//     }

//     @media(min-width:410px) and (max-width:413px){
//         top:66px;
    
//     }

//     @media(min-width:414px) and (max-width:427px){
//         top:55px;
//         @media(max-height:700px){
//             top:45px;
//         }

    
//     }
//     @media(min-width:428px){
//         top:45px;
    
//     }
//     @include gadgets-to(tablet) {
//         top:55px;
//     }   
//     @include gadgets-to(laptop) {
//         top:75px;
//     }
//     @include gadgets-to(desktop) {
//         top:80px;
//         font-size: 32px;
//     }
//     @include gadgets-to(largeDesktop) {
//         top:120px;
//         font-size: 40px;
//     }

// }
// .xocompNameJAP1{
//     color: map-get($generalStyles, transparentChangeableMenu5);
// }


.xobackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }
.xochartArea{
    background-color:map-get($generalStyles,transparentChangeableMenu);
    
}
.xobarChart{
    background-color:map-get($generalStyles,transparentChangeableMenu);
    margin: 10px 30px 0 -15px;
    height:30%;
    width:70%;
}










// ChartArea:{
//     height:'555px',
//    // borderRadius:'10px',
//     marginTop:'65px',
//     marginLeft:'10px',
//     marginRight:'10px',
//     marginBottom:'10px',
//     backgroundColor:generalStyles.transparentChangeable,
//     fillColor:generalStyles.transparentChangeableMenu5,
//     yaxisColor:'blue',
//     xaxisColor:'blue',
//     strokeColor:'blue',
//     fillColor:'rgb(255, 128, 0)',
//     gridColor:generalStyles.pinkRed,
//     gridBgColor:'grey',
// },