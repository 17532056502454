@import '../style/MainStyles.scss';/* styles.css */



.xktotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}



.xktotalArea {
    @include gadgets-to(mobile) {
        display: grid;
       // grid-template-rows: 3% 5% 26% 17% 30%;
        grid-template-rows:auto auto auto auto auto;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "e"
            ;
        width: 100%;
        height:auto;
        object-fit: cover;



    }


    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        .area-f {
            grid-area: d;
            // style for the tabs
            .tabs {
              display: flex;
              justify-content: space-between;
            }
            .tab-content {
              display: none;
            }
          }
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;


    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 99.8%;
        height: 100%;
        object-fit: cover;
    }
}

.xktotalArea1 {
    @extend .xktotalArea;
    background-color: map-get($generalStyles, backgroundColor);
}

.xktotalArea2 {
    @extend .xktotalArea;
    @include gadgets-to(tablet) {
    //  grid-template-columns:0% 50% 50%;
     // background-color:white;
     grid-template-columns:0% 50% 47%;
     grid-template-areas:
       "a a a"
       "b b b"
       "c d e";
   }
 
   .c {
     display: none;
   }

  @include gadgets-to(laptop) {
    grid-template-columns:0% 50% 50%;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e"
  }

  .c {
    display: none;
  }
  @include gadgets-to(desktop) {
    grid-template-columns:0% 50% 50%;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
  
}
.c {
  display: none;
}
@include gadgets-to(largeDesktop) {
    grid-template-columns:0% 50% 50%;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
  
}
.c {
  display: none;
}
}



.xkheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:30px;

    }
@include gadgets-to(laptop) {
        grid-area: a;
    }
@include gadgets-to(desktop) {
        grid-area: a;
         height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
    }

}

.xkcompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xkcompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xkrangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:20px;
         }
         // as ipad
        //  @media(min-width:1171px) and (max-width:1195px)
        //  {
         
        //     height:auto;
        //  }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:20px;
         }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}


.xkareaA {
    @include gadgets-to(mobile) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 99%;
        height: 190px;
        background-color: map-get($generalStyles, transparentChangeable);
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(tablet) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
      //  height:60vh;
         height:map-get($generalStyles,applicationHeightTablet);
        background-color: map-get($generalStyles, transparentChangeable);
        border-radius: map-get($generalStyles, sixBorderRadius);
       
        @media(max-width:1080px){
            height:83vh;
         }
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:84vh;
         }
         // as ipad
         @media(min-width:1171px) and (max-width:1195px)
         {
            height:76.5vh;
         }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:84vh;
         }
  
    }
    @include gadgets-to(laptop) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        height: 100%;
        background-color: map-get($generalStyles, transparentChangeable);
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(desktop) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        height: 100%;
        background-color: map-get($generalStyles, transparentChangeable);
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(largeDesktop) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        height: 100%;
        background-color: map-get($generalStyles, transparentChangeable);
        border-radius: map-get($generalStyles, sixBorderRadius);
    }

}

.xkareaB {
    @extend .xkareaA;
    @include gadgets-to(mobile) {

    grid-area: d;
    height:53vh;
    margin-top:3px;
    border-radius: map-get($generalStyles, sixBorderRadius);

          //360
          @media(max-width:360px) and (max-height:690px) {
            margin-top:1vh; 
            height:47.5vh;
        }
        //360x740
        @media(max-width:360px) and (min-height:730px) {
            height:57.5vh;
        }
        // 375
        @media(min-width:370px) and (max-width:380px) {
            height:52vh;
        }
        //384
        @media(min-width:381px) and (max-width:385px) {
            height:60.5vh;
        }
        //393
        @media(min-width:386px) and (max-width:394px) {
            height:53vh;
        }

        // //393x852
        // @media(min-width:386px) and (max-width:394px) 
        // and (max-height:860px) {
        //     height:56vh;
  
        // }

        
        @media(min-width:395px) and (max-width:411px) {
            height:45vh;
        }
        // 412px , a phone
        @media(min-width:413px) and (max-width:414px) {
            @media(max-height:750px){
                height:56vh;
            }
     
         
        }

        // 412px , a phone
        @media(min-width:412px) and (max-width:413px)
        {
            @media(min-height:650px) and (max-height:751px){
                height:56vh;
            }
            @media(min-height:752px) and (max-height:990px){
                height:54vh;
            }

            

            height:55vh;
        }

        //
        @media(min-width:415px) and (max-width:429px) {
            height:61vh;
        }

        @media(min-width:415px) and (max-width:429px)
        and (max-height:926px)
        {
            height:58vh;
        }
        //430x932
        @media(min-width:430px) and (max-width:480px) {
            height:58.5vh;
        }
        
}
@include gadgets-to(tablet) {
    grid-area: d;
    height:map-get($generalStyles,applicationHeightTablet);
  //  margin-top: 3px;
    border-radius: map-get($generalStyles, sixBorderRadius);

    @media(max-width:1080px){
        height:83vh;
     }
     @media(min-width:1081px) and (max-width:1170px)
     {
        height:84vh;
     }
     // as ipad
     @media(min-width:1171px) and (max-width:1195px)
     {
        height:76.5vh;
     }

     @media(min-width:1196px) and (max-width:1400px)
     {
        height:84vh;
     }
}
@include gadgets-to(laptop) {
    grid-area: d;
    height: 100%;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
    grid-area: d;
    height: 100%;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    grid-area: d;
    height: 100%;
    border-radius: map-get($generalStyles, sixBorderRadius);
}

}




.xkareaC {
    @extend .xkareaA;
    @include gadgets-to(mobile) {
        grid-area: e;
        height:map-get($generalStyles,applicationHeightTablet);
        background-color: white;
        margin-top:1.5vh;
        height:40vh;
    }
    @include gadgets-to(tablet) {
        grid-area: e;

        height:map-get($generalStyles,applicationHeightTablet);
        background-color:map-get($generalStyles,transparentChangeable);
        
        @media(max-width:1080px){
            height:83vh;
         }
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:84vh;
         }
         // as ipad
         @media(min-width:1171px) and (max-width:1195px)
         {
            height:76.5vh;
         }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:84vh;
         }
    

}
@include gadgets-to(laptop) {
    grid-area: e;
    height: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
   // margin-top:1.5vh;
}
@include gadgets-to(desktop) {
    grid-area: e;
    height: 100%;
    background-color:map-get($generalStyles,transparentChangeable);
  //  margin-top:1.5vh;
}
@include gadgets-to(largeDesktop) {
    grid-area: e;
    height: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
 //   margin-top:1.5vh;
}

}


.xkareaC1 {
    @extend .xkareaC;
    @include gadgets-to(mobile) {
        grid-area: e;
        height:20vh;
        margin-left: 11px;
        background: white;
        margin-top: 50px;
        z-index: 10;
    }
    @include gadgets-to(tablet) {
        grid-area: e;
       // height:80vh;
     //   margin-left: 11px;
        background: white;
      //  margin-top: 50px;
        z-index: 10;

        @media(max-width:1080px){
            height:83vh;
         }
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:84vh;
         }
         // as ipad
         @media(min-width:1171px) and (max-width:1195px)
         {
            height:76.5vh;
         }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:84vh;
         }
    }
    @include gadgets-to(laptop) {
        grid-area: e;
        height: 100%;
      //  background: white;
      //  margin-top: 50px;
        z-index: 10;
    }
    @include gadgets-to(desktop) {
        grid-area: e;
        height: 100%;
        //background: white;
       // margin-top: 50px;
        z-index: 10;
    }
    @include gadgets-to(largeDesktop) {
        grid-area: e;
        height: 100%;
       // background: white;
      //  margin-top: 50px;
        z-index: 10;
    }

}



.xkareaATop {
    @include gadgets-to(mobile) {

    display: flex;
    flex-direction: column;
  //  justify-content: center;
    align-items: center;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;


}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

}

.xkareaBTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.xkAreaABottom {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // width: 60%;
}
.xkareaABottom1{

}
.xkcheckboxWrapper{
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:flex-end;
   
    width:100%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:flex-end;
    width:100%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:flex-end;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:flex-end;
    width:100%;
}
}


.xkcheckpseudo{
    @include gadgets-to(tablet) {
    margin-top:1vh;
    margin-right:10%;
    }
    @include gadgets-to(laptop) {
        margin-top:1vh;
        margin-right:10%;
    }
    @include gadgets-to(desktop) {
        margin-top:1vh;
        margin-right:10%;
    }
    @include gadgets-to(largeDesktop) {
        margin-top:1vh;
        margin-right:10%;
    }

}
.xkcheckpseudoLabel{
    @include gadgets-to(tablet) {
     font-family: map-get($generalStyles,fontFamily);
     font-size:12px;
    }
    @include gadgets-to(laptop) {
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
    }


}



.xkrange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xkrange2 {
    @extend .xkrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}
.xkSelect{
    @include gadgets-to(mobile) {

    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;

}
@include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;

}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
}

.xkSelect1{
    @include gadgets-to(mobile) {

    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
}


.xkSelectIngredFieldset {
@include gadgets-to(mobile) {
    width: 100%;
    height: 30px;
    border: none;
    border-color: white;
    margin-top: 5px;
    display:flex;
    flex-direction:row;
    justify-content:center;

}
@include gadgets-to(tablet) {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(laptop) {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(desktop) {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(largeDesktop) {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
}


.xkSelectIngredLegend {
    @include gadgets-to(mobile) {

    color: map-get($generalStyles, colorblack);
    font-size: map-get($generalStyles, fontsizeSmall);
    font-weight: map-get($generalStyles, fontWeight);
    font-family: map-get($generalStyles, fontFamily);
    margin-left:11%;
}
@include gadgets-to(tablet) {
    color: map-get($generalStyles, colorblack);
    font-size: map-get($generalStyles, fontsizeSmall);
    font-weight: map-get($generalStyles, fontWeight);
    font-family: map-get($generalStyles, fontFamily);
    margin-left:11%;
}
@include gadgets-to(laptop) {
    color: map-get($generalStyles, colorblack);
    font-size: map-get($generalStyles, fontsizeSmall);
    font-weight: map-get($generalStyles, fontWeight);
    font-family: map-get($generalStyles, fontFamily);
    margin-left:11%;

}
@include gadgets-to(desktop) {
    color: map-get($generalStyles, colorblack);
    font-size: map-get($generalStyles, fontsizeSmall);
    font-weight: map-get($generalStyles, fontWeight);
    font-family: map-get($generalStyles, fontFamily);
    margin-left:11%;
}
@include gadgets-to(largeDesktop) {
    color: map-get($generalStyles, colorblack);
    font-size: map-get($generalStyles, fontsizeSmall);
    font-weight: map-get($generalStyles, fontWeight);
    font-family: map-get($generalStyles, fontFamily);
    margin-left:11%;
}
}

.xkrecepySelectTop{
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:100%;
}
.xkSelectRecepyFieldset {
    // position: absolute;
    // top: 165px;
    // left: -15px;
    width:100%;
    height: 30px;
    border: none;
    border-color: white;
    margin-top:1.5vh;
    display:flex;
    flex-direction:row;
    justify-content:center;
}

.xkselectRecepyTop{
display:flex;
flex-direction:row;
justify-content:center;
}
.xkSelectRecepyLegend {
    color: map-get($generalStyles, colorblack);
    font-size: map-get($generalStyles, fontsizeSmall);
    font-weight: map-get($generalStyles, fontWeight);
    font-family: map-get($generalStyles, fontFamily);
    margin-left:11%;
 }



.xkSelectRecepySelect {
    @include gadgets-to(mobile) {
  
    width:80%;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeSixteen);
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
}
@include gadgets-to(tablet) {
    width:80%;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeSixteen);
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
}
@include gadgets-to(laptop) {
    width:80%;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;

}
@include gadgets-to(desktop) {
    width:80%;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
}
@include gadgets-to(largeDesktop) {
    width:80%;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
}
}


.xkingredButtonContainer {
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        margin-left: 10px;
        width: 92%;
        max-height: 68px;
        overflow-y: scroll;
        overflow-x:hidden;
   
        
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        margin-left: 10px;
        width: 92%;
        max-height:55vh;
        overflow-y:scroll;
        overflow-x:hidden;
        padding-top:1vh;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }

        @media(min-width:1170px)and (max-width:1190px){
          //  height:50vh;
            max-height:53vh;
        }
    }
@include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        margin-left: 10px;
        width: 92%;
        height:auto;
        min-height:1px;
        max-height: 57vh;
        overflow-y:auto;
        overflow-x:hidden;
        padding-top:1vh;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
@include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        margin-left: 10px;
        width: 92%;
        max-height: 55vh;
        overflow-y: scroll;
        overflow-x:hidden;
        padding-top:1vh;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        margin-left: 10px;
        width: 92%;
        max-height: 55vh;
        overflow-y: scroll;
        overflow-x:hidden;
        padding-top:1vh;
        //here we can change the color of the scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of the thumb
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }

}

.xkshowIngredButton {
    position:relative;
    @include gadgets-to(mobile) {
        width:auto;
        height: 40px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 20px;
    //    border-radius: map-get($generalStyles, sixBorderRadius);
    border-radius:20px;
    margin-top: 1px;
        cursor: pointer;
        margin-left: 3px;
        margin-bottom: 3px;
        white-space: nowrap;

        
        &::after {
            content: attr(data-price);
          //  content: "15.9"; // This is the text that will be displayed
            position: absolute; // This allows us to position the pseudo-element relative to the button
            top: -8px; // Adjust these values to position the ring where you want it
            right: -8px;
            width:20px; // The size of the ring
            height:20px;
            border:1px solid white; // The color of the ring
           border-radius:50%; // This makes the ring circular
            color: white; // The color of the text
            font-size: 10px; // The size of the text
            display: flex; // These styles center the text in the ring
            justify-content: center;
            align-items: center;
            z-index:1;
            line-height: 20px;
            background-color: map-get($generalStyles,transparentChangeableMenu4); // The color of the ring
            color:black;
            padding:1px;
        }

        &::after {
            // background-color: map-get($generalStyles,transparentChangeableMenu4); // The color of the ring
              background-color: white;
         }
 
         &[data-lowest="1"]::after {
             background-color: green; // Change the color to green for lowest price
         }
     
         &[data-highest="1"]::after {
             background-color:  red; // Change the color to red for highest price
         }
         &[data-mid="1"]::after {
             background-color:pink; // Color for midrange price
           }
 
           &[data-mid1="1"]::after {
             background-color: yellow; // Color for midrange price
           }
         
 
           &.hide-pseudo::after {
             display: none;
         }
    }
    @include gadgets-to(tablet) {
        width:auto;
        height:40px;
        padding:6px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        border-radius:20px;
        font-size:20px;
        margin-left:2px;
        margin-bottom:3px;
        cursor:pointer;


        &::after {
            content: attr(data-price);
            //content: "15.9"; // This is the text that will be displayed
            position: absolute; // This allows us to position the pseudo-element relative to the button
            top: -8px; // Adjust these values to position the ring where you want it
            right: -8px;
            min-width:20px;
            min-height:20px;
            width:auto; // The size of the ring
            height:auto;
            border:1px solid white; // The color of the ring
           border-radius:50%; // This makes the ring circular
            font-size: 10px; // The size of the text
            display: flex; // These styles center the text in the ring
            justify-content: center;
            align-items: center;
            z-index:1;
            line-height: 20px;
            color:black;
            padding:1px;
            cursor:pointer;
            

      
        }
        &::after {
           // background-color: map-get($generalStyles,transparentChangeableMenu4); // The color of the ring
             background-color: white;
        }

        &[data-lowest="1"]::after {
            background-color: green; // Change the color to green for lowest price
        }
    
        &[data-highest="1"]::after {
            background-color:  red; // Change the color to red for highest price
        }
        &[data-mid="1"]::after {
            background-color:pink; // Color for midrange price
          }

          &[data-mid1="1"]::after {
            background-color: yellow; // Color for midrange price
          }
        

          &.hide-pseudo::after {
            display: none;
        }


}
@include gadgets-to(laptop) {
    width:auto;
    height:40px;
    padding:6px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    border-radius:20px;
    font-size:20px;
    margin-left:2px;
    margin-bottom:3px;
    cursor:pointer;
    &::after {
        content: attr(data-price);
        //content: "15.9"; // This is the text that will be displayed
        position: absolute; // This allows us to position the pseudo-element relative to the button
        top: -8px; // Adjust these values to position the ring where you want it
        right: -8px;
        min-width:20px;
        min-height:20px;
        width:auto; // The size of the ring
        height:auto;
        border:1px solid white; // The color of the ring
       border-radius:50%; // This makes the ring circular
        font-size: 10px; // The size of the text
        display: flex; // These styles center the text in the ring
        justify-content: center;
        align-items: center;
        z-index:1;
        line-height: 20px;
        color:black;
        padding:1px;
        cursor:pointer;
        

  
    }

}
&::after {
   // background-color: map-get($generalStyles,transparentChangeableMenu4); // The color of the ring
     background-color: white;
}

&[data-lowest="1"]::after {
    background-color: green; // Change the color to green for lowest price
}

&[data-highest="1"]::after {
    background-color:  red; // Change the color to red for highest price
}
&[data-mid="1"]::after {
    background-color:pink; // Color for midrange price
  }

  &[data-mid1="1"]::after {
    background-color: yellow; // Color for midrange price
  }


  &.hide-pseudo::after {
    display: none;
}


@include gadgets-to(desktop) {
    width:auto;
    height:40px;
    padding:6px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    border-radius:20px;
    font-size:20px;
    margin-left:2px;
    margin-bottom:3px;
    cursor:pointer;
    &::after {
        content: attr(data-price);
        //content: "15.9"; // This is the text that will be displayed
        position: absolute; // This allows us to position the pseudo-element relative to the button
        top: -8px; // Adjust these values to position the ring where you want it
        right: -8px;
        min-width:20px;
        min-height:20px;
        width:auto; // The size of the ring
        height:auto;
        border:1px solid white; // The color of the ring
       border-radius:50%; // This makes the ring circular
        font-size: 10px; // The size of the text
        display: flex; // These styles center the text in the ring
        justify-content: center;
        align-items: center;
        z-index:1;
        line-height: 20px;
        color:black;
        padding:1px;
        cursor:pointer;
        

  
    }
}
&::after {
   // background-color: map-get($generalStyles,transparentChangeableMenu4); // The color of the ring
     background-color: white;
}

&[data-lowest="1"]::after {
    background-color: green; // Change the color to green for lowest price
}

&[data-highest="1"]::after {
    background-color:  red; // Change the color to red for highest price
}
&[data-mid="1"]::after {
    background-color:pink; // Color for midrange price
  }

  &[data-mid1="1"]::after {
    background-color: yellow; // Color for midrange price
  }


  &.hide-pseudo::after {
    display: none;
}



@include gadgets-to(largeDesktop) {
    width:auto;
    height:60px;
    padding:6px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    border-radius:20px;
    font-size:24px;
    margin-left:2px;
    margin-bottom:3px;
    cursor:pointer;
    &::after {
        content: attr(data-price);
        //content: "15.9"; // This is the text that will be displayed
        position: absolute; // This allows us to position the pseudo-element relative to the button
        top: -8px; // Adjust these values to position the ring where you want it
        right: -8px;
        min-width:20px;
        min-height:20px;
        width:auto; // The size of the ring
        height:auto;
        border:1px solid white; // The color of the ring
       border-radius:50%; // This makes the ring circular
        font-size: 10px; // The size of the text
        display: flex; // These styles center the text in the ring
        justify-content: center;
        align-items: center;
        z-index:1;
        line-height: 20px;
        color:black;
        padding:1px;
        cursor:pointer;
        

  
    }
}

&::after {
   // background-color: map-get($generalStyles,transparentChangeableMenu4); // The color of the ring
     background-color: white;
}

&[data-lowest="1"]::after {
    background-color: green; // Change the color to green for lowest price
}

&[data-highest="1"]::after {
    background-color:  red; // Change the color to red for highest price
}
&[data-mid="1"]::after {
    background-color:pink; // Color for midrange price
  }

  &[data-mid1="1"]::after {
    background-color: yellow; // Color for midrange price
  }


  &.hide-pseudo::after {
    display: none;
}

}


.xkshowIngredButton1 {
    @extend .xkshowIngredButton;
    @include gadgets-to(mobile) {

        background-color: map-get($generalStyles, transparentChangeableMenu4);
    }
    @include gadgets-to(tablet) {
     background-color: map-get($generalStyles, transparentChangeableMenu4);
    }
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu4);



}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}

}
.xkrecepyHeaderTop{
    // display:flex;
    // flex-direction:row;
    // justify-content:center;
     width:100%;
}

.xkrecepyHeaderWrapper{
 @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    width:100%;
    margin-left:2%;

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    width:100%;
    margin-left:2%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    width:100%;
    margin-left:10%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    width:100%;
    margin-left:10%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    width:100%;
    margin-left:2%;
}

}

.xkrecepyHeader {
    @include gadgets-to(mobile) {

    width:98%;
    border: none;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:20px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
   // margin-left:-11%;
}

@include gadgets-to(tablet) {
    width:98%;
    border: none;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:24px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
   // margin-left:-11%;
}
@include gadgets-to(laptop) {
    width:98%;
    border: none;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:24px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
   // margin-left:-11%;


}
@include gadgets-to(desktop) {
    width:98%;
    border: none;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:28px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
   // margin-left:-11%;
}
@include gadgets-to(largeDesktop) {
    width:98%;
    border: none;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:30px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
    margin-left:4%;
}

}
.xkrecepyHeaderMANJAP{
    @extend .xkrecepyHeader;
    font-size: 16px;
    @include gadgets-to(mobile) {
        font-size: 16px;
    }
    @include gadgets-to(tablet) {
        font-size: 18px;
    }
    @include gadgets-to(laptop) {
        font-size: 20px;
    }
    @include gadgets-to(desktop) {
        font-size: 24px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 24px;
    }

}

.xkrecepyHeaderJAP{
    @extend .xkrecepyHeader;
    font-size: 12px;
    @include gadgets-to(mobile) {
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        font-size: 16px;
    }
    @include gadgets-to(desktop) {
        font-size: 18px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 18px;
    }
}

// .xkrecepyManHeaderJAPMobile{
//     @extend .xkrecepyHeader;
//     background-color: white;
//     font-size:16px;
//     border-radius: 3px;
// }

.xkheaderButtonIngredid {
    @include gadgets-to(mobile) {
        width: 30px;
        height: 20px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 10px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        font-weight: bold;
    }
    @include gadgets-to(tablet) {
        width: 30px;
        height: 20px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 10px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        font-weight: bold;
    }
@include gadgets-to(laptop) {
        width: 30px;
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        font-weight: bold;
    }
@include gadgets-to(desktop) {
       display:flex;
         flex-direction:row;
            justify-content:center;
            align-items:flex-end;
        width: 30px;
        height: 30px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        font-weight: bold;
        padding:3px;
    }
@include gadgets-to(largeDesktop) {
        width: 30px;
        height: 40px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 18px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        font-weight: bold;
    }

}

.xkheaderButtonBenaemn {
    @extend .xkheaderButtonIngredid;
    width:40%;
    text-align: left;
    cursor: pointer;
}

.xkheaderButtonAmount {
    @extend .xkheaderButtonIngredid;
    width:15%;
}

.xkheaderButton {
    @extend .xkheaderButtonIngredid;
    width:15%;
}

.xkheaderKcals {
    @extend .xkheaderButtonIngredid;
    width:10%;
    cursor: pointer;
}

.xkheaderOrder {
    @extend .xkheaderButtonIngredid;
    width:10%;
    cursor: pointer;

}
.xkheaderDelete{
    @extend .xkheaderButtonIngredid;
    @include gadgets-to(mobile) {
    width:7%;
    cursor:pointer;
}
@include gadgets-to(tablet) {
    width:5.5%;
    background-color:transparent;
    border:none;
}
@include gadgets-to(laptop) {
    width:5.5%;
    background-color:transparent;
    border:none;

}
@include gadgets-to(desktop) {
    width:5.5%;
    background-color:transparent;
    border:none;

    
}
@include gadgets-to(largeDesktop) {
    width:5.5%;
    cursor:pointer;
}

}

.xkHeaderButtonUpdate {
    @extend .xkheaderButtonIngredid;
    width: 190px;
    height: 30px;
    background-color: map-get($generalStyles, datalistitemColor);
    border-radius: 1px;
    border-top-right-radius: map-get($generalStyles, borderRadius);
}

.xkHeaderTop{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:column;
    //justify-content:center;
 //   align-items: center;

    width:100%;

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items: center;
    width:100%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items: center;
    width:100%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items: center;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items: center;
    width:100%;
}
}


.xkheader {
    @include gadgets-to(mobile) {
    grid-area: b;
  //  margin-left: 5px;
  width:100%;
  display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(tablet) {
    grid-area: b;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;

}
@include gadgets-to(laptop) {
    grid-area: b;
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;

}
@include gadgets-to(desktop) {
    grid-area: b;
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    margin-left:2px;

}
@include gadgets-to(largeDesktop) {
    grid-area: b;
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;

}
}


.xkreceivedContainer {
    @include gadgets-to(mobile) {

    height:32vh;
    max-height:32vh;
    overflow-y: scroll;
    width: 100%;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
   // margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }

                  //360
                  @media(min-width:359px) and (max-width:370px) {
                    height:20vh;
                    max-height:20vh;
                }
            //    //360x740
            //    @media(max-width:360px) and (max-height:741px) {
            //     height:38vh;
            //     max-height:38vh;
            // }

        // 375
        @media(min-width:370px) and (max-width:380px) {
            height:27vh;
            max-height:27vh;
        }
        //384
        @media(min-width:381px) and (max-width:385px) {
        height:34vh;
        max-height:34vh;
       }

        //393
        @media(min-width:386px) and (max-width:394px) {
            height:28.5vh;
            max-height:28.5vh;
        }

        @media(min-width:411px) and (max-width:414px) {
            @media(max-height:750px){
                height:35vh;
                max-height:35vh;
            }
        }


     // 414px , a phone
     @media(min-width:413px) and (max-width:414px) {
        @media(max-height:950px){
            height:32vh;
            max-height:32vh;
        }
        @media(min-height:750px) and (max-height:900px){
            height:35vh;
            max-height:35vh;
        }
    }



       // 412px , a phone
       @media(min-width:412px) and (max-width:413px)
       {
           @media(min-height:650px) and (max-height:751px){
            height:30vh;
            max-height:30vh;
           }
           @media(min-height:752px) and (max-height:900px){
            height:31vh;
            max-height:31vh;
           }
           @media(min-height:901px) and (max-height:990px){
            height:31vh;
            max-height:31vh;
           }
           
       }





            //428x926
            @media(min-width:415px) and (max-width:429px)
        and (min-height:820px)
        {
            height:32vh;
            max-height:32vh;
        }


        @media(min-width:415px) and (max-width:429px) {
            height:34vh;
            max-height:34vh;
        }
            //430x932
        @media(min-width:430px) and (max-width:480px) {
            height:35vh;
            max-height:35vh;
        }

            
    
        
}
@include gadgets-to(tablet) {
    height: 53vh;
    max-height: 53vh;
    overflow-y: scroll;
    width: 100%;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
    margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }



         @media(max-width:1080px){
            height: 62vh;
            max-height: 62vh;
         }
         @media(min-width:1081px) and (max-width:1170px)
         {
            height: 64vh;
            max-height: 64vh;
         }
         // as ipad
         @media(min-width:1171px) and (max-width:1195px)
         {
            height: 51vh;
            max-height:51vh;
         }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height: 64vh;
            max-height: 64vh;
         }

}
@include gadgets-to(laptop) {
    height: 60vh;
    max-height: 60vh;
    overflow-y:auto;
    width:95%;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
   // margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }

}
@include gadgets-to(desktop) {
    height: 60vh;
    max-height: 60vh;
    overflow-y:auto;
    width:95%;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
    margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }


}
@include gadgets-to(largeDesktop) {
    height:60vh;
    max-height: 60vh;
    overflow-y:auto;
    width:95%;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
    margin-left: 5px;
    display:flex;
    flex-direction:column;
    align-items: center;

        // For Chrome, Safari, and Edge
        &::-webkit-scrollbar {
            width: 1px; /* width of the entire scrollbar */
        }
    
        &::-webkit-scrollbar-track {
          background: map-get($generalStyles,transparentChangeableMenu5); /* color of the tracking area */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }

}

}

.xkdatarows{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
  //  justify-content:center;
    flex-wrap:wrap;
    width:100%;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    flex-wrap:wrap;
    width:100%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    flex-wrap:wrap;
    width:100%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    flex-wrap:wrap;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    flex-wrap:wrap;
    width:100%;
}
}

.xkinfobuttonIngredid {
@include gadgets-to(mobile) {
    width: 30px;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
}
@include gadgets-to(tablet) {
    width: 30px;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:14px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
}
@include gadgets-to(laptop) {
    width: 30px;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:14px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;


}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items: flex-end;
    width: 30px;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    padding:1px;
    padding-left:5px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: flex-end;
    width: 30px;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    padding:1px;
    padding-left:5px;
}

}



.xkinfobuttonIngredid2 {
    @extend .xkinfobuttonIngredid;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}

}

.xkinfobuttonBenaemn {
    @extend .xkinfobuttonIngredid;
    @include gadgets-to(mobile) {
    width:40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    // ellipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@include gadgets-to(tablet) {
    width:40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    // ellipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@include gadgets-to(laptop) {
    width:40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    // ellipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    


}
@include gadgets-to(desktop) {
    width:40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    // ellipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding-left:2%;
   // font-size: 22px;
    width:40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    // ellipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

}

.xkinfobuttonBenaemn2 {
    @extend .xkinfobuttonBenaemn;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
}

.xkinfobuttonAmount {
    @extend .xkinfobuttonIngredid;
    @include gadgets-to(mobile) {
    width:15%;
}
@include gadgets-to(tablet) {
    height:30px;
    width:15%;
}
@include gadgets-to(laptop) {
    height:30px;
    width:15%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    height:35px;
    width:15%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    height:35px;
    width:15%;
}
}

.xkinfobuttonAmount2 {
    @extend .xkinfobuttonAmount;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
}

.xkinfobutton {
    @extend .xkinfobuttonIngredid;
    @include gadgets-to(mobile) {
    width:15%;
}
@include gadgets-to(tablet) {
    height:30px;
    width:15%;
}
@include gadgets-to(laptop) {
    height:30px;
    width:15%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    height:35px;
    width:15%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    height:35px;
    width:15%;
}
}


.xkinfobutton2 {
    @extend .xkinfobutton;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
}

.xkkcalsButton {
    @extend .xkinfobuttonIngredid;
    @include gadgets-to(mobile) {
    width:10%;
}
@include gadgets-to(tablet) {
    height:30px;
    width:10%;
}
@include gadgets-to(laptop) {
    height:30px;
    width:10%;


}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    height:35px;
    width:10%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    height:35px;
    width:10%;
}
}

.xkkcalsButton2 {
    @extend .xkkcalsButton;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
}
@include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
}
}

.xkarborderButton {
    @extend .xkinfobuttonIngredid;
    @include gadgets-to(mobile) {
    width:10%;
    cursor: pointer;
}
@include gadgets-to(tablet) {
    width:10%;
    cursor: pointer;
}
@include gadgets-to(laptop) {
    width:10%;
    cursor: pointer;

}
@include gadgets-to(desktop) {
    width:10%;
    cursor: pointer;
    height:35px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
}
@include gadgets-to(largeDesktop) {
    width:10%;
    cursor: pointer;
    wheight:35px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
}
}

.xkarborderButton2 {
    @extend .xkarborderButton;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
    }
    @include gadgets-to(laptop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:30px;
    }
    @include gadgets-to(desktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
    }
    @include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:35px;
    }
}


.xkdelete{
@extend .xkinfobuttonIngredid;
@include gadgets-to(mobile) {
width:6%;
padding:1px;
background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(tablet) {
width:6%;
padding:1px;
background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(laptop) {
width:6%;
padding:1px;
background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;


width:6%;
padding:1px;
background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;

width:6%;
padding:1px;
background-color: map-get($generalStyles, transparentChangeableMenu4);
}
}
.xkdelete1{
    @extend .xkinfobuttonIngredid;
    @include gadgets-to(mobile) {
    width:6%;
    padding:1px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    width:6%;
    padding:1px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    width:6%;
    padding:1px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
 
    width:6%;
    padding:1px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
   
    width:6%;
    padding:1px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
}


.xkdeletebackline {
    @include gadgets-to(mobile) {

    font-size: 14px;
   // color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
   // margin-left: 2px;
   color:black;
}
@include gadgets-to(tablet) {
    font-size: 14px;
   // color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
   // margin-left: 2px;
   color:black;
}
@include gadgets-to(laptop) {
    font-size: 14px;
   // color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
   // margin-left: 2px;
   color:black;

}
@include gadgets-to(desktop) {
    font-size: 18px;
   // color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
   // margin-left: 2px;
   color:black;
}
@include gadgets-to(largeDesktop) {
    font-size: 18px;
   // color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
   // margin-left: 2px;
   color:black;
}
}


.ingredcommentTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100%;

}
.ingredcommentWrapper{
    display:flex;
    flex-direction:row;
    width:100%;
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        width:100%;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:row;
        width:97%;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:row;
        width:97%;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        width:97%;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        width:97%;
    }
   
}


.xktotalCostContainer {
    @include gadgets-to(mobile) {

    display: flex;
    flex-direction: row;
    width:100%;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;


}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
}
}

.xktotalcostBenaemn {
    @include gadgets-to(mobile) {
    width:40%;
    height: 20px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, borderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(tablet) {
    width:40%;
    height: 20px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, borderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(laptop) {
    width:40%;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: map-get($generalStyles, borderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
  
    width:40%;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: map-get($generalStyles, borderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    width:40%;
    height: 40px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    border-radius: map-get($generalStyles, borderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
}

.xktotalamountButton {
    @extend .xktotalcostBenaemn;
    @include gadgets-to(mobile) {
    width:15%;
    padding:1px;
    text-align: center;
    font-size: 10px;
}
@include gadgets-to(tablet) {
    width:15%;
    padding:1px;
    text-align: center;
    font-size: 10px;
}
@include gadgets-to(laptop) {
    width:15%;
    padding:1px;
    text-align: center;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    text-align:center;
    width:15%;
    padding:1px;
    font-size: 14px;

}
@include gadgets-to(largeDesktop) {
    width:15%;
    padding:1px;
    font-size: 20px;
    text-align:center;
   
}
}


.xktotalcostButton {
    @extend .xktotalcostBenaemn;
    @include gadgets-to(mobile) {
    width:15%;
    text-align: center;
    font-size: 10px;
}
@include gadgets-to(tablet) {
    width:15%;
    text-align: center;
    font-size: 10px;
}
@include gadgets-to(laptop) {
    width:15%;
    text-align: center;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:15%;
    text-align: center;
    font-size: 14px;
}
@include gadgets-to(largeDesktop) {
    width:15%;
    text-align: center;
    font-size: 20px;
}
}

.xktotalcaloriesButton {
    @extend .xktotalcostBenaemn;
    @include gadgets-to(mobile) {
    width:10%;
    text-align: center;
    font-size: 10px;
}
@include gadgets-to(tablet) {
    width:10%;
    text-align: center;
    font-size: 10px;
}
@include gadgets-to(laptop) {
    width:10%;
    text-align: center;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:10%;
    text-align: center;
    font-size: 14px;
}
@include gadgets-to(largeDesktop) {
    width:10%;
    text-align: center;
    font-size: 20px;
}
}


.xkavgcaloriesButton {
    @extend .xktotalcostBenaemn;
    @include gadgets-to(mobile) {
    width:10%;
    font-size:10px;
    cursor: pointer;
    text-align:center;
}
@include gadgets-to(tablet) {
    width:10%;
    font-size:10px;
    cursor: pointer;
    text-align:center;
}
@include gadgets-to(laptop) {
    width:10%;
    font-size:14px;
    cursor: pointer;
    text-align:center;
}
@include gadgets-to(desktop) {
    width:10%;
    font-size:14px;
    cursor: pointer;
    text-align:center;
}
@include gadgets-to(largeDesktop) {
    width:10%;
    font-size:20px;
    cursor: pointer;
    text-align:center;
}
}

.xkavgcaloriesdelete{
    @extend .xktotalcostBenaemn;
    @include gadgets-to(mobile) {
    width:6%;
    padding:1px;
    text-align: center;

}
@include gadgets-to(tablet) {
    width:6%;
    padding:1px;
    text-align: center;
}
@include gadgets-to(laptop) {
    width:6%;
    padding:1px;
    text-align: center;
}
@include gadgets-to(desktop) {
    width:6%;
    padding:1px;
    text-align: center;
}
@include gadgets-to(largeDesktop) {
    width:6%;
    padding:1px;
    text-align: center;
}
}

.xkselectnewingredientContainer {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    margin-top:1vh;
    margin-bottom:1vh;
    width:100%;
}

.xkselectdbOwnerIngredient {
    @include gadgets-to(mobile) {
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 187px;
    height: 25px;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left: 1px;
    background-color: white;
    color:black;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(tablet) {
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 187px;
    height: 25px;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left: 1px;
    background-color: white;
    color:black;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(laptop) {
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:40%;
    height:30px;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-left: 1px;
    background-color: white;
    color:black;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(desktop) {
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:40%;
    height: 35px;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 1px;
    background-color: white;
    color:black;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:40%;
    height: 35px;
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left: 1px;
    background-color: white;
    color:black;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
}


.xksaveRecepyButton {
    @include gadgets-to(mobile) {
    width: 100px;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:map-get($generalStyles, sixBorderRadius);
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
}
@include gadgets-to(tablet) {
    width: 100px;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:map-get($generalStyles, sixBorderRadius);
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
}
@include gadgets-to(laptop) {
    width: 130px;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius:map-get($generalStyles, sixBorderRadius);
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
}
@include gadgets-to(desktop) {
    width: 150px;
    height: 35px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    border-radius:map-get($generalStyles, sixBorderRadius);
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
}
@include gadgets-to(largeDesktop) {
    width: 150px;
    height: 35px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    border-radius:map-get($generalStyles, sixBorderRadius);
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
}
}


.xksaveRecepyButtonActive {
    @extend .xksaveRecepyButton;
    background-color: map-get($generalStyles, ActiveButtonColor);
    color: white;
    opacity:1;
}



.xknutrientSummaryContainer {
@include gadgets-to(mobile) {
    width:98%;
    height:11vh;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin:1%;
    @media(max-width:360px){
        height:12vh;
    }
}
@include gadgets-to(tablet) {
    width:98%;
    height:10vh;
    display:flex;
    flex-direction:row;
   // flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    border:none;
   // border-color: map-get($generalStyles,transparentChangeableMenu5);
   // border-radius: map-get($generalStyles, sixBorderRadius);
    margin:1%;
}
@include gadgets-to(laptop) {
    width:98%;
    height:10vh;
    display:flex;
    flex-direction:row;
   // flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    border:none;
    // border-color: map-get($generalStyles,transparentChangeableMenu5);
    // border-radius: map-get($generalStyles, sixBorderRadius);
    margin:1%;
}
@include gadgets-to(desktop) {
    width:98%;
    height:10vh;
    display:flex;
    flex-direction:row;
   // flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    border:none;
    //border-color: map-get($generalStyles,transparentChangeableMenu5);
   // border-radius: map-get($generalStyles, sixBorderRadius);
    margin:1%;
}
@include gadgets-to(largeDesktop) {
    width:98%;
    height:10vh;
    display:flex;
    flex-direction:row;
   // flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    border:none;
    //border-color: map-get($generalStyles,transparentChangeableMenu5);
    //border-radius: map-get($generalStyles, sixBorderRadius);
    margin:1%;
}
}


.xkviewNutrientData {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
   justify-content:center;
    align-items: center;
    margin-top: 5px;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
   // flex-wrap:wrap;
    justify-content:center;
    align-items: center;
    margin-top: 5px;
    width:100%;

}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
  //  flex-wrap:wrap;
    justify-content:center;
    align-items: center;
    margin-top: 5px;
    width:95%;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
   // flex-wrap:wrap;
    justify-content:center;
    align-items: center;
    margin-top: 5px;
    width:95%;

}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
  //  flex-wrap:wrap;
    justify-content:center;
    align-items: center;
    margin-top: 5px;
    width:95%;

}
}

.xknutrientSummaryBFats,
.xknutrientSummaryButtonProtein,
.xknutrientSummaryButtonKcals,
.xknutrientSummaryButtonCarb,
.xknutrientSummaryButtonFats {
    @include gadgets-to(mobile) {
    width:25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:12px;
    font-style:italic;

    border-radius: map-get($generalStyles, borderRadius);
    // margin-left: 2px;
    margin-top: 2px;
    text-align:left;
}
@include gadgets-to(tablet) {
    width:25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:12px;
    font-style:italic;

    border-radius: map-get($generalStyles, borderRadius);
    // margin-left: 2px;
    margin-top: 2px;
    text-align:left;
}
@include gadgets-to(laptop) {
    width:25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
    font-style:italic;

    border-radius: map-get($generalStyles, borderRadius);
    // margin-left: 2px;
    margin-top: 2px;
    text-align:left;
}
@include gadgets-to(desktop) {
    width:25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    font-style:italic;

    border-radius: map-get($generalStyles, borderRadius);
    // margin-left: 2px;
    margin-top: 2px;
    text-align:left;
}
@include gadgets-to(largeDesktop) {
    width:25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    font-style:italic;

    border-radius: map-get($generalStyles, borderRadius);
    // margin-left: 2px;
    margin-top: 2px;
    text-align:left;
}
}

.xkNutrientSummaryButtonCarb {
    width: 80px;
}

.xkmeatIcon,
.xkcheeseIcon,
.xkwheatIcon,
.xkfireIcon {
    @include gadgets-to(mobile) {
    width:25%;
    height:25px;
    font-size: 22px;
    color: map-get($generalStyles, transparentChangeableMenu5);
   // margin-left: 10px;
}
@include gadgets-to(tablet) {
    width:25%;
    height:25px;
    font-size: 22px;
    color: map-get($generalStyles, transparentChangeableMenu5);
   // margin-left: 10px;
}
@include gadgets-to(laptop) {
    width:25%;
    height:30px;
    font-size: 30px;
    color: map-get($generalStyles, transparentChangeableMenu5);
   // margin-left: 10px;
}
@include gadgets-to(desktop) {
    width:25%;
    height:30px;
    font-size: 30px;
    color: map-get($generalStyles, transparentChangeableMenu5);
   // margin-left: 10px;
}
@include gadgets-to(largeDesktop) {
    width:25%;
    height:25px;
    font-size: 30px;
    color: map-get($generalStyles, transparentChangeableMenu5);
   // margin-left: 10px;
}
}


.xkFireIcon {
    @include gadgets-to(mobile) {
    margin-left: 12px;
}
@include gadgets-to(tablet) {
    margin-left: 12px;
}
@include gadgets-to(laptop) {
    margin-left: 12px;
}
@include gadgets-to(desktop) {
    margin-left: 12px;
}
@include gadgets-to(largeDesktop) {
    margin-left: 12px;
}
}



.xksearchSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xksearchSpinner {
    color: map-get($generalStyles, colorblack);
}

.xkrecepyDescriptionHeader {
    @include gadgets-to(mobile) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: map-get($generalStyles, fontSizeTwenty);
    margin-top: 3px;
    margin-left: 4px;
 
}
@include gadgets-to(tablet) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: map-get($generalStyles, fontSizeTwenty);
    margin-top: 3px;
    margin-left: 4px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}
@include gadgets-to(laptop) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: map-get($generalStyles, fontSizeTwenty);
    margin-top: 3px;
    margin-left: 4px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;



}
@include gadgets-to(desktop) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: map-get($generalStyles, fontSizeTwenty);
    margin-top: 3px;
    margin-left: 4px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}
@include gadgets-to(largeDesktop) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: map-get($generalStyles, fontSizeTwenty);
    margin-top: 3px;
    margin-left: 4px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}
}

.xkrecepyDescriptionHeader1 {
    @include gadgets-to(mobile) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size:24px;
    margin-left:5px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:97%;
    }
    @include gadgets-to(tablet) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size:24px;
    margin-left:5px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:97%;
    }
    @include gadgets-to(laptop) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size:16px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:99%;
    margin-left:5px;
    }
    @include gadgets-to(desktop) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size:16px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:99%;
    margin-left:5px;
    }
    @include gadgets-to(largeDesktop) {
    grid-area: c;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size:16px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:98%;
    margin-left:5px;
    }
}
    

.xkresizeBookIcon{
    @include gadgets-to(mobile) {
    // font-size: 16px;
    // margin-top:4px;
    // margin-left:5px;
    // cursor:pointer;
}
@include gadgets-to(tablet) {
    font-size: 22px;
    margin-top:4px;
    margin-right:5px;
    cursor:pointer;
}
@include gadgets-to(laptop) {
    font-size: 22px;
    margin-top:4px;
    margin-right:5px;
    cursor:pointer;

}
@include gadgets-to(desktop) {
    font-size: 22px;
    margin-top:4px;
    margin-right:5px;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    font-size: 22px;
    margin-top:4px;
    margin-right:5px;
    cursor:pointer;
}
}
.xkresizeBookIcon1{
    @include gadgets-to(mobile) {
    // font-size: 16px;
    // margin-top:4px;
    // margin-left:5px;
    // cursor:pointer;
}
@include gadgets-to(tablet) {
    font-size: 26px;
    margin-top:4px;
  //  margin-right:10px;
    cursor:pointer;
}
@include gadgets-to(laptop) {
    font-size: 26px;
    margin-top:4px;
  //  margin-right:10px;
    cursor:pointer;

}
@include gadgets-to(desktop) {
    font-size: 26px;
    margin-top:4px;
  //  margin-right:10px;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    font-size: 26px;
    margin-top:4px;
  //  margin-right:10px;
    cursor:pointer;
}
}

.xkkviewdescription {
    @include gadgets-to(mobile) {
    grid-area: c;
    width: 99%;
    height: 290px;
    margin-top: 2px;
    padding:3px;
    border: none;
    border-color: white;
    outline: none;
    background: white;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeSixteen);
    color: map-get($generalStyles, colorblack);
    max-height: 290px;
    overflow-y: scroll;
    // here we wlil change the color of the scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of the thumb
    &::-webkit-scrollbar-thumb {
        background-color:white;
        border:1px solid;
        border-color: map-get($generalStyles, transparentChangeableMenu5);
        
    }

}
@include gadgets-to(tablet) {
    grid-area: c;
    width: 99%;
    height:70vh;
    margin-top: 2px;
    border:none;
   // border-color: white;
    outline: none;
    background:transparent;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeSixteen);
    color: map-get($generalStyles, colorblack);
    max-height:70vh;
    overflow-y:auto;
    // here we wlil change the color of the scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of the thumb
    &::-webkit-scrollbar-thumb {
        background-color:white;
        border:1px solid;
        border-color: map-get($generalStyles, transparentChangeableMenu5);
        
    }   

    @media(max-width:1080px){
        height:75vh;
        max-height:75vh;
    
     }
     @media(min-width:1081px) and (max-width:1170px)
     {
        height:75vh;
        max-height:75vh;
   
     }
     // as ipad
     @media(min-width:1171px) and (max-width:1195px)
     {
        height:68vh;
        max-height:68vh;
        width:98%;
     
     }

     @media(min-width:1196px) and (max-width:1400px)
     {
        height:77vh;
        max-height:77vh;
 
     }

}
@include gadgets-to(laptop) {
    grid-area: c;
    width: 99%;
    height:72vh;
    margin-top: 2px;
    border:none;
    resize: none;
   // border-color: white;
    outline: none;
    background:transparent;
    font-family: map-get($generalStyles, fontFamily);
    font-size:18px;
    color: map-get($generalStyles, colorblack);
    min-height:1px;
    max-height:72vh;
    overflow-y:auto;
    padding:5px;
    // here we wlil change the color of the scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
 
      }
    //color of the thumb
    &::-webkit-scrollbar-thumb {
        background-color:white;
        border:1px solid;
        border-color: map-get($generalStyles, transparentChangeableMenu5);
        
    }   


}
@include gadgets-to(desktop) {
    grid-area: c;
    width: 97%;
    height:77vh;
    margin-top:2px;
    border:none;
   // border-color: white;
    outline: none;
    resize: none;
    background:transparent;
    font-family: map-get($generalStyles, fontFamily);
    font-size:20px;
    color: map-get($generalStyles, colorblack);
    max-height:77vh;
    overflow-y:auto;
    padding:5px;
    // here we wlil change the color of the scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of the thumb
    &::-webkit-scrollbar-thumb {
        background-color:white;
        border:1px solid;
        border-color: map-get($generalStyles, transparentChangeableMenu5);
        
    }   


}
@include gadgets-to(largeDesktop) {
    grid-area: c;
    width: 99%;
    height:75vh;
    margin-top: 2px;
    border:none;
    resize: none;
   // border-color: white;
    outline: none;
    background:transparent;
    font-family: map-get($generalStyles, fontFamily);
    font-size:22px;
    color: map-get($generalStyles, colorblack);
    max-height:75vh;
    overflow-y:auto;
    padding:5px;
    // here we wlil change the color of the scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of the thumb
    &::-webkit-scrollbar-thumb {
        background-color:white;
        border:1px solid;
        border-color: map-get($generalStyles, transparentChangeableMenu5);
        
    }   

  

}
}

.xkkviewdescription1 {
    @include gadgets-to(mobile) {
    grid-area: c;
    width: 99%;
    height: 580px;
    overflow-y: scroll;
    margin-top: 8px;
    margin-left: 35px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background: white;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwentyFour);
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(tablet) {
    grid-area: c;
    width: 99%;
    height:73vh;
    overflow-y:auto;
    margin-top: 8px;
    padding:3px;
   // margin-left:5px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background: white;
    font-family: map-get($generalStyles, fontFamily);
    font-size:24px;
    color: map-get($generalStyles, colorblack);
    // as ipad
         @media(min-width:1171px) and (max-width:1195px)
         {
            height:68vh;
         }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:75vh;
         }
}
@include gadgets-to(laptop) {
    grid-area: c;
    width: 98%;
    height:73vh;
    overflow-y: scroll;
    margin-top: 8px;
    padding:3px;
   // margin-left:5px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background:transparent;
    font-family: map-get($generalStyles, fontFamily);
    font-size:24px;
    color: map-get($generalStyles, colorblack);
    padding:5px;
  


}
@include gadgets-to(desktop) {
    grid-area: c;
    width: 98%;
    height:73vh;
    overflow-y: scroll;
    margin-top: 8px;
    padding:3px;
   // margin-left:5px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background-color:transparent;
    font-family: map-get($generalStyles, fontFamily);
    font-size:24px;
    color: map-get($generalStyles, colorblack);
    padding:5px;


}
@include gadgets-to(largeDesktop) {
    grid-area: c;
    width: 99%;
    height:73vh;
    overflow-y: scroll;
    margin-top: 8px;
    padding:3px;
   // margin-left:5px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background: white;
    font-family: map-get($generalStyles, fontFamily);
    font-size:24px;
    color: map-get($generalStyles, colorblack);
    padding:5px;


}
}


.xknumTecken1,
.xknumTecken2 {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: map-get($generalStyles,font-family);
    font-size:12px;
    align-items: center;
    
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: map-get($generalStyles,font-family);
    font-size:12px;
    align-items: center;
    width:97%;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: map-get($generalStyles,font-family);
    font-size:12px;
    align-items: center;
    width:97%;
    
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: map-get($generalStyles,font-family);
    font-size:12px;
    align-items: center;
    width:97%;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: map-get($generalStyles,font-family);
    font-size:12px;
    align-items: center;
    width:97%;
}
}

.xknumTecken{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    font-family: map-get($generalStyles, fontFamily);
    font-size:12px;
    align-items:center;
    width:100%;

}

.xktextareaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
}

.xktextareaComments {
    width:60%;
    height:50px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeTwelve);
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
}



.xkAddamountIngramMinus,
.xkAddamountIngramPlus {
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeEleven);
    border-radius: map-get($generalStyles, borderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    cursor: pointer;
}

.xkAddamountIngramPlus {
    padding-left: 7px;
}

.xkbuttonFoodInfoWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;

}

.xkbuttonFoodInfo {
    width:61%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,font-family);
    font-size:12px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    margin-top: 2px;
    cursor: pointer;
    align-self: flex-start;
    
}

.xkfoodArea {
    width:100%;
}

.nutrientButton{
    text-align: left;
    margin-top: 1px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: map-get($generalStyles, fontFamily);
    font-size:12px;
    font-weight: bold;
   // margin-left: 3px;
    border-radius: map-get($generalStyles, threeBorderRadius);
    color:black;
}
.xkheaderButtonNutrient{
    @extend .nutrientButton;
 width:50%;

}
.xkheaderButtonQuantity{
    @extend .nutrientButton;
    width:calc(25% - 2px);
    margin-left:2px;

}
.xkheaderButtonUnit {
    @extend .nutrientButton;
    width:calc(25% - 2px);
    margin-left:2px;
}

.xkHeaderButtonQuantity,
.xkHeaderButtonUnit {
    width: 70px;
}
.xkfoodAreaHeader{
    width:100%;
}
.xkfoodareaWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}

.xkfoodareaContainer {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width:98%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: map-get($generalStyles, datalistbackgroundColor);

    &::-webkit-scrollbar {
        width: 1px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of the thumb
    &::-webkit-scrollbar-thumb {
        background-color: white;
    }
}

.xknutrientvalueButton{
    text-align: left;
    margin-top: 1px;
    border:none;
  //  border-color: white;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
  //  margin-left: 3px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    //elippsis
    //margin-bottom:1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:black;
    height:20px;
}
.xknutrientValue{
    @extend .xknutrientvalueButton;
    width:50%;
}
.xknutrientValue2{
    @extend .xknutrientvalueButton;
    width:50%;

    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
.xknutrientQuantity{
    @extend .xknutrientvalueButton;
    width:calc(25% - 2px);
    margin-left:2px;
}
.xknutrientQuantity2{
    @extend .xknutrientvalueButton;
    width:calc(25% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
.xknutrientUnit {
    @extend .xknutrientvalueButton;
    width:calc(25% - 2px);
    margin-left:2px;
}
.xknutrientUnit2 {
    @extend .xknutrientvalueButton;
    width:calc(25% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}



.xkCompName,
.xkCompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: map-get($generalStyles, fontSizeTwentyEight);
}

.xkCompName {
    color: map-get($generalStyles, colorwhite);
}

.xkCompName1 {
    color: map-get($generalStyles, transparentChangeableMenu5);
}

.xkAiOutlinePlusCircle {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size:50px;
    cursor: pointer;
}

.xkanimateddiv20{ 
    transform:rotate(0deg);
    animation-name:xjingredientNew20;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    
      }

      @keyframes xjingredientNew20 {
        0%{ height:0px }
        100%{height:105px}
        }

        .animateddiv21{
            animation-name: xjidentifier21;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
          }

          @keyframes xjidentifier21 {
            0%{ height:0px }
            100%{height:305px}
            }
.xktabs{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    @include gadgets-to(mobile) {
        height:3vh;
    }
    @include gadgets-to(tablet) {
        height:4vh;
    }
     @include gadgets-to(laptop) {
      height:4vh;
    }
    @include gadgets-to(desktop) {
        height:4vh;
    }
    @include gadgets-to(largeDesktop) {
    height:5vh;
    }
}


.xktab-button {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        background-color:white;
        padding:3px 4px;
        cursor: pointer;
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
         border-top-left-radius: 6px;
        // border-top-right-radius:6px;
       border:1px solid transparent;
      //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
       // border-bottom-color:transparent;
        width:50%;
        margin:2px;
    }

    @include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
     border-top-left-radius: 6px;
    // border-top-right-radius:6px;
   border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
}


.xktab-button1 {
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:rgb(235, 231, 231);
    padding:3px 4px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
   // border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:rgb(235, 231, 231);
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
   // border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:rgb(235, 231, 231);
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:rgb(235, 231, 231);
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:rgb(235, 231, 231);
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
}


.xktab-button:hover {
    background-color:white;
    
}

.xktab-button.active {
    background-color:map-get($generalStyles, transparentChangeableMenu4);
}

.xkImageIcon{
    @include gadgets-to(mobile) {
  font-size: 15px;
  color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(tablet) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(laptop) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(desktop) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
}
.xkRecipeIcon{
    @include gadgets-to(mobile) {
    font-size: 15px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(desktop) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
}

.xkIconText{
    @include gadgets-to(mobile) {

font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 10px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 16px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
}

.xkgenerateImageButton{
    @include gadgets-to(mobile) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
 
}
@include gadgets-to(tablet) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
@include gadgets-to(laptop) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
@include gadgets-to(desktop) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
@include gadgets-to(largeDesktop) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
}
.xkgenerateImageButton1{
    @extend .xkgenerateImageButton;
    background-color: map-get($generalStyles,pinkRed);
    color:white;
    cursor:pointer;
}

.xkimageinput{
    @include gadgets-to(mobile) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(tablet) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(laptop) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(desktop) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(largeDesktop) {
    width:70%;
    height:4.7svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:24px;
    resize:none;
}
}
.xkgenerateImageTop{
    display:flex;
    flex-direction:row;
    justify-content: center;
}

.xkgenerateImageWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:100%;
    margin-top:8px;
}

.s3imagedata{
@include gadgets-to(mobile) {
    margin-top:1vh;
    width:90%;
    height:auto;
    border-radius: 6px;

}
@include gadgets-to(tablet) {
    margin-top:2vh;
    width:70%;
    height:auto;
    border-radius: 6px;
}
@include gadgets-to(laptop) {
    margin-top:2vh;
    width:70%;
    height:auto;
    border-radius: 6px;
    cursor: pointer;
  
}
@include gadgets-to(desktop) {
    margin-top:2vh;
    width:70%;
    height:auto;
    border-radius: 6px;
    cursor: pointer;
    
}
@include gadgets-to(largeDesktop) {
    width:70%;
    height:70%;
    border-radius: 6px;
    margin-top:2vh;
    cursor: pointer;
    
}
}
.s3imagedata1{
@extend .s3imagedata;
border:5px solid map-get($generalStyles, pinkRed);
cursor:pointer;


}

.s3imagedataNotFound{
    @extend .s3imagedata;
}




.xkImageContainer{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:100%;
    margin-top:1vh;
    height:44vh;
    max-height:44vh;
    overflow-y:auto;

    // here we will fix the width of the scrollbar and
    // the color of the scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }


    
// 375
@media(min-width:370px) and (max-width:380px) {
    height:36vh;
    max-height:36vh;
}
    //393
    @media(min-width:386px) and (max-width:394px) {
    height:38vh;
    max-height:38vh;
}

@media(min-width:411px) and (max-width:414px) {
    @media(max-height:750px){
        height:40vh;
        max-height:40vh;
    }
}


//384
@media(min-width:381px) and (max-width:385px) {
height:43vh;
max-height:43vh;
}



    //428x926
    @media(min-width:415px) and (max-width:429px)
    and (min-height:820px)
    {
        height:32vh;
        max-height:32vh;
    }


    @media(min-width:415px) and (max-width:429px) {
        height:43vh;
        max-height:43vh;
    }
        //430x932
    @media(min-width:430px) and (max-width:480px) {
        height:45vh;
        max-height:45vh;
    }

    

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:100%;
    margin-top:1vh;
    height:65vh;
    max-height:65vh;
    overflow-y:auto;
    cursor:pointer;

    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }

    


         // as ipad
         @media(min-width:1171px) and (max-width:1195px)
         {
            height: 59vh;
            max-height:59vh;
         }
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:100%;
    margin-top:1vh;
    height:65vh;
    max-height:65vh;
    overflow-y:auto;
    cursor:pointer;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:100%;
    margin-top:1vh;
    height:65vh;
    max-height:65vh;
    overflow-y:auto;
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:80%;
    margin-top:1vh;
    height:65vh;
    max-height:65vh;
    overflow-y:auto;
    cursor:pointer;
}
}

.xkimageInformationTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
   margin-top:5px;
}
.xkimageInformationText{

    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    justify-content:center;
   align-items:center;
    width:95%;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;

    @include gadgets-to(mobile) {
        font-size: 11px;
    }
    @include gadgets-to(tablet) {
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        font-size: 14px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 14px;
    }
}

.xkimageIconUpload{
    font-size: 22px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
@include gadgets-to(mobile) {
font-size:18px;
}
@include gadgets-to(tablet) {
    font-size: 22px;
}
@include gadgets-to(laptop) {
    font-size: 22px;
}
@include gadgets-to(desktop) {
    font-size: 22px;
}
@include gadgets-to(largeDesktop) {
    font-size: 22px;
}
}
.xkimageUploadContainer{
    display:flex;
    flex-direction:column;
   // justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    margin-top:5px;

}
.xkimageUploadWrapper{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    margin-top:5px;
}
.xkinputimageUpload{
    display:none;
}

.xkfileLabel{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex-shrink: 0;
    margin-left:5px;
    height:25px;
    width:120px;
    //padding:3px;
    border-radius: map-get($generalStyles, sixborderRadius);
    background-color:map-get($generalStyles, transparentChangeableMenu5);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
}

.xkbuttonUploadImageWrapper{
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    width:50%;
}
.xkbuttonUploadImage{
    margin-left:5px;
    width:120px;
    height: 30px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    cursor:pointer;
    margin-top: 5px;
    opacity:0.7;
}
.xkbuttonUploadImage1{
    @extend .xkbuttonUploadImage;
    background-color: map-get($generalStyles, pinkRed);
    color: white;
    cursor:pointer;
}


.xkfileName{
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    margin-left:3px;
    //elippsis
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    width:100%;
    display:flex;
    flex-wrap:wrap;
}

.xkimageUploadMessage{

    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    margin-top:5px;
    margin-left:7px;
    @include gadgets-to(mobile) {
        font-size: 11px;
    }
    @include gadgets-to(tablet) {
        font-size: 12px;
    }
    @include gadgets-to(laptop) {
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        font-size: 14px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 14px;
    }
}


.xkbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }






