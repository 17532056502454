:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaTotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 95vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsatotalArea, .bsatotalArea1 {
    display: grid;
    grid-template-rows: 8% 8% 9% 81%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: 71vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) {
  .bsatotalArea, .bsatotalArea1 {
    grid-template-rows: 8% 8% 10% 74%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 375px) and (max-height: 750px) {
  .bsatotalArea, .bsatotalArea1 {
    grid-template-rows: 8% 8% 10% 74%;
  }
}

.bsatotalArea1 {
  background-color: rgb(245, 245, 237);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsarangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 4vh;
  }
}

.bsabackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsarange1, .bsarange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsacompName, .bsacompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsacompName1, .bsacompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}

.bsacompNameJAP {
  font-family: var(--font-family);
}

.bsacompNameJAP1 {
  font-family: var(--font-family);
}

.bsaTopWrapper2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bsaareaA {
  grid-area: c;
  width: 100%;
  background-color: var(--val);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaareaB {
    grid-area: d;
    background-color: var(--val);
    height: 100vh;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .bsaareaB::-webkit-scrollbar {
    width: 1px;
  }
  .bsaareaB::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .bsaareaB::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
  .bsaareaB::-webkit-scrollbar-thumb:hover {
    background: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsacategoriSelectWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsacategorySelect {
    width: 75%;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-bottom: 3px;
    margin-top: 5px;
    outline: none;
    background-color: white;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaimageArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsascrollImages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 2;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaimageContainer, .bsaimageContainer1 {
    display: grid;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaimageOverlay {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
    z-index: 1;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .textOverlay, .textOverlayJap {
    position: relative;
    z-index: 2;
    font-family: Lobster;
    font-size: 50px;
    text-align: center;
    color: white;
    cursor: pointer;
  }
}

.textOverlayJap {
  font-family: var(--font-family);
}

.textOverlay.active, .active.textOverlayJap {
  color: var(--rgbaVal);
}

.bsaimageOverlay, .textOverlay, .textOverlayJap, .buttonContainer {
  grid-area: image;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  margin-top: 1vh;
  opacity: 0;
}

.buttonContainer.visible {
  animation: fadeIn 1.5s ease-in-out forwards;
}

.bsagetinfoButton {
  background-color: var(--rgbaVal);
  color: white;
  border: none;
  border-radius: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 3vh;
  border-radius: 50%;
  outline: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.infocontainer .infocontainer {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.infocontainer .infocontainer.open {
  animation: openInfo 1s forwards;
  visibility: visible;
  display: flex;
}
.infocontainer .infocontainer.closed {
  animation: closeInfo 1s forwards;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontainer.open {
    animation: openInfo 1s forwards;
    visibility: visible;
    width: 100%;
  }
}

.infocontainer.closed {
  animation: closeInfo 1s forwards;
  width: 100%;
}

@keyframes openInfo {
  0% {
    height: 0;
  }
  100% {
    height: 230px;
  }
}
@keyframes closeInfo {
  0% {
    height: 230px;
  }
  100% {
    height: 0;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsarecipeBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25vh;
    max-height: 25vh;
    overflow-y: scroll;
  }
  .bsarecipeBox::-webkit-scrollbar {
    width: 5px;
  }
  .bsarecipeBox::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .bsarecipeBox::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsarecipeBoxHeader, .bsareceipeBoxHeaderJAP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Lobster;
    font-size: 20px;
    color: var(--rgbaVal);
    margin-top: 10px;
  }
}

.bsareceipeBoxHeaderJAP {
  font-family: var(--font-family);
}

.bsareceipeBoxContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bsaRecipeList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bsaRecipeList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bsaRecipeListHeader {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 16px;
  color: var(--rgbaVal);
  margin-top: 2px;
}

.bsaReceipeListItem {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 16px;
  color: var(--rgbaVal);
  margin-top: 2px;
}

.bsaitemBenaemn {
  width: 40%;
  height: 20px;
  background-color: var(--rgbaVal);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  padding-left: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bsaitemBenaemn1 {
  width: 40%;
  height: 20px;
  background-color: var(--rgbaVal2);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bsaitemMangd {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

.bsaitemMangd1 {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal2);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

.bsaitemAdsum {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

.bsaitemAdsum1 {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal2);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

.bsaitemCalories {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

.bsaitemCalories1 {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal2);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

.bsaitemArborder {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

.bsaitemArborder1 {
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal2);
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 12px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaDescriptionBox {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.bsaDescriptionBoxHeader, .bsaDescriptionBoxHeaderJAP {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Lobster;
  font-size: 20px;
  font-weight: bold;
  color: var(--rgbaVal);
  margin-top: 2px;
}

.bsaDescriptionBoxHeaderJAP {
  font-family: var(--font-family);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsaDescriptionBoxText {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 2px;
    height: 150px;
    border: none;
    resize: none;
    outline: none;
    background-color: transparent;
  }
  .bsaDescriptionBoxText::-webkit-scrollbar {
    width: 5px;
  }
  .bsaDescriptionBoxText::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .bsaDescriptionBoxText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

.spinner {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to perfectly center regardless of its size */
  /* Add more styling for your spinner here */
  z-index: 5;
}

.bsasearchSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.bsasearchSpinner {
  color: black;
}/*# sourceMappingURL=SearchRecepyScroll.css.map */