
@import '../style/variabels.scss';
.useChartChoice-button{
    margin:2px;
    width:auto;
    height: 30px;
    border-radius: 6px;
    background-color:RGB(255, 128, 0);
    color: white;
    font-family: $primary-font-family;
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color:rgb(141, 71, 0);
    }
}
$chartArea-height:100%;
$bottom-Area-height: calc(100% - #{$chartArea-height});
.useChartChoice-bottom{
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    align-content: flex-start;
    justify-content:center;
    width:73%;
    height:$chartArea-height;
    margin-top: 12px;
    margin-bottom: 2px;
    margin-right:10px;
   // border:2px solid $chocolateBrownBorderLight;
    border-radius:6px;
}
.useChartChoice-ingenkategorivald{
    color:white;
    font-family: $primary-font-family;
    font-size: 14px;
}
.useChartChoice-chartarea{
    display:flex;
    flex-direction:row-reverse;
    justify-content:center;
    align-items: flex-end;
    width:99%;
    height:$bottom-Area-height;
   // border:2px solid $chocolateBrownBorderLight;
    margin-bottom: 5px;
    margin-top:min(25px);
    margin-right:60px;
}
.useChartChoice-chart-top{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    width:100%;
    height:$bottom-Area-height;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
}
.useChartChoice-chart-button{
  
    margin:2px;
    width: auto;
    height: 30px;
    border-radius: 6px;
    background-color:RGB(255, 128, 0);
    color: white;
    font-family: $primary-font-family;
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color:rgb(141, 71, 0);
    }
}
.useChartChoice-chart-button.active{
 
    background-color:red;
    color: white;
}




.useChartChoice-chart-fieldset{
    font-family: $primary-font-family;
    font-size:14px;
    color:rgb(236, 143, 49);
    border:none;
    border-radius: 6px;
    margin-bottom:10px;
}
.useChartChoice-chart-legend{
    font-family: $primary-font-family;
    font-size:14px;
    color:black;
    margin-left:10px;
    margin-right:10px;
}
// .useChartChoice-statistik{
//     display:flex;
//     flex-direction:row;

//     width:500px;
//     margin-top: 5px;
//     margin-bottom: 5px;
//     margin-left: 80px;
//     margin-right:5px;
//     //border:2px solid $chocolateBrownBorderLight;
// }
.useChartChoice-statistik-fieldset{

    font-family: $primary-font-family;
    font-size:11px;
    height:25px;
    color:white;
    border-color: $chocolateBorder;
    border-radius: 6px;
    margin-bottom:2px;
    margin-top:3px;
}
.useChartChoice-statistik-legend{
    color:$chocolateBorder;
}