@import '../style/MainStyles.scss';/* styles.css */

.xztotalArea {
@include gadgets-to(mobile) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "b";
    width: 100%;
    height:90vh;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@include gadgets-to(tablet) {
    display: grid;
    grid-template-columns: 50% 20% 30%; // Adjust as needed
   // grid-template-rows: auto auto auto;
    grid-template-areas:
      "a b c";
    column-gap: 5px;
    width:100%;
    height:95vh;
    margin-top:5px;
    object-fit: cover;

}
@include gadgets-to(laptop) {
    display: grid;
    grid-template-columns: 40% 30% 30%; // Adjust as needed
   // grid-template-rows: auto auto auto;
    grid-template-areas:
      "a b c";
    column-gap: 5px;
    width:100%;
    height:95vh;
    margin-top:5px;
    object-fit: cover;

}
@include gadgets-to(desktop) {
    display: grid;
    grid-template-columns: 40% 30% 30%; // Adjust as needed
    grid-template-areas:
      "a b c";
    column-gap: 5px;
    width:100%;
    height:95vh;
    margin-top:5px;
    object-fit: cover;
}
@include gadgets-to(largeDesktop) {
    display: grid;
    grid-template-columns: 40% 30% 30%; // Adjust as needed
    grid-template-areas:
      "a b c";
    column-gap: 5px;
    width:100%;
    height:85vh;
    margin-top:5px;
    object-fit: cover;
}
}

.xztotalArea1 {
    @include gadgets-to(mobile) {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "b";
        width: 100%;
        height:85vh;
        object-fit: cover;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
    }
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns: 50% 20% 30%; // Adjust as needed
       // grid-template-rows: auto auto auto;
        grid-template-areas:
          "a b c";
        column-gap: 5px;
        width:100%;
        height:95vh;
        margin-top:5px;
        object-fit: cover;
    
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns: 40% 30% 30%; // Adjust as needed
       // grid-template-rows: auto auto auto;
        grid-template-areas:
          "a b c";
        column-gap: 5px;
        width:100%;
        height:95vh;
        margin-top:5px;
        object-fit: cover;
    
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns: 40% 30% 30%; // Adjust as needed
        grid-template-areas:
          "a b c";
        column-gap: 5px;
        width:100%;
        height:95vh;
        margin-top:5px;
        object-fit: cover;
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns: 40% 30% 30%; // Adjust as needed
        grid-template-areas:
          "a b c";
        column-gap: 5px;
        width:100%;
        height:95vh;
        margin-top:5px;
        object-fit: cover;
    }
    }


.xzareaB {
    grid-area: a;
    border: map-get($generalStyles, prefferedBorderStyleNone);
    border-color: map-get($generalStyles, prefferedBorderColor);
    width: 100%;
    height: 60vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:15vh;
    border-radius: 6px;
    background-color:transparent;

    @include gadgets-to(mobile){
     height:63vh;
        // 360 phones
        @media(max-width:360px)and (min-height:600px)
        and (max-height:650px)
        
        {
            height:59vh;
        }
        @media(max-width:360px)and (min-height:650px)
        and (max-height:700px){
            height:55vh;
        }
        
           // 375 phones
           @media(min-width:375px)and (max-width:375px)
           and  (min-height:500px) and (max-height:630px)
           
           {
               height:68vh;
           }
           @media(min-width:375px)and (max-width:375px)
           and  (min-height:631px) and (max-height:800px)
           
           {
               height:58vh;
           }
              //384 phones
        @media(min-width:384px)and (max-width:384px)
        and  (min-height:640px) and (max-height:800px)
        
        {
            height:54vh;
        }
        // 390
        @media(min-width:390px)and (max-width:390px)
        and  (min-height:640px) and (max-height:800px)
        
        {
            height:54vh;
        }
           // 393 phones
           @media(min-width:393px)and (max-width:393px)
           and  (min-height:640px) and (max-height:800px)
           
           {
               height:54vh;
           }

          // 412x800 <824
          @media(min-width:411px)and (max-width:413px)
          and  (min-height:640px) and (max-height:700px)
          
          {
              height:51vh;
          }
          //412 >824 <900
          @media(min-width:411px)and (max-width:413px)
          and  (min-height:701px) and (max-height:740px)
          
          {
              height:51vh;
          }


        // 412 x915
        @media(min-width:411px)and (max-width:413px)
        and  (min-height:741px) 
        
        {
            height:47vh;
        }
       


        // 414
        @media(min-width:414px)and (max-width:414px)
        and  (min-height:640px) and (max-height:800px)
        
        {
            height:52vh;
        }
        // 428
        @media(min-width:428px)and (max-width:428px)
        and  (min-height:640px) and (max-height:800px)
        
        {
            height:51vh;
        }
        // 430
        @media(min-width:430px)and (max-width:430px)
        and  (min-height:640px) and (max-height:800px)
        
        {
            height:50vh;
        }
          // Big phones
          @media(min-width:431px)
          and  (min-height:640px) and (max-height:800px)
          
          {
                 height:50vh;
          }
          @media(min-width:431px)
          and  (min-height:800px) and (max-height:900px)
          
          {
                 height:46vh;
          }
    






    }
    @include gadgets-to(tablet){
       width:100%;
       height:90vh;
       @media(orientation:portrait){
           width:42%;
           height:37vh;
       }
       // big tablets
       @media(min-width:1366px){
            height:40vh;
       }
    }
    @include gadgets-to(laptop){
        width:100%;
        height:75vh;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
    @include gadgets-to(desktop){
        width:100%;
        height:75vh;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;


    }
    @include gadgets-to(largeDesktop){
        width:100%;
        height:75vh;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
 
    }
}

.xzloginTextEnglish {
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-size: 30px;
    white-space: nowrap;
    //font-weight: bold;
    text-align: center;
    color: rgb(225, 176, 68);
    margin-top:5px;
    @include gadgets-to(largeDesktop){
        font-size: 40px;
    }
}
.xzloginTextSvenska {
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-size: 30px;
    white-space: nowrap;
   // font-weight: bold;
    text-align: center;
    color: rgb(225, 176, 68);

    @include gadgets-to(largeDesktop){
        font-size: 40px;
    }
}

.xzloginArea {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-content: center;
    width:100%;
    @include gadgets-to(tablet){
        width:100%;
        margin-top:1vh;
        display: flex;
        flex-direction: column;
        align-items:center;
        align-content: center;
    }
    @include gadgets-to(laptop){
        width:100%;
        height:70vh;
        display:flex;
        flex-direction:column;
       // justify-content:center;
        align-items:center;
    }
    @include gadgets-to(desktop){
        width:100%;
        display:flex;
        flex-direction:column;
        //justify-content:flex-end;
        align-items:center;
    }
    @include gadgets-to(largeDesktop){
        width:100%;
        display:flex;
        flex-direction:column;
        //justify-content:flex-end;
        align-items:center;
    }
}

.xzloginBox {
    width:100%;
    height:55vh;
   // padding: 20px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: 6px;
   // margin-top: 20%;
    background-color: map-get($generalStyles, colorwhite);
    @include gadgets-to(mobile){
        height:65vh;
        // 360 phones
        @media(max-width:360px)and (min-height:600px)
        and (max-height:650px)
        
        {
            height:59vh;
        }
        @media(max-width:360px)and (min-height:650px)
        and (max-height:700px){
            height:55vh;
        }
        // 375 phones
        @media(min-width:375px)and (max-width:375px)
        and  (min-height:500px) and (max-height:630px)
        
        {
            height:68vh;
        }
        @media(min-width:375px)and (max-width:375px)
        and  (min-height:631px) and (max-height:800px)
        
        {
            height:58vh;
        }
        //384 phones
        @media(min-width:384px)and (max-width:384px)
        and  (min-height:640px) and (max-height:800px)
        
        {
            height:54vh;
        }
        // 390
        @media(min-width:390px)and (max-width:390px)
        and  (min-height:640px) and (max-height:800px)
        
        {
            height:54vh;
        }
           // 393
           @media(min-width:393px)and (max-width:393px)
           and  (min-height:640px) and (max-height:800px)
           
           {
               height:54vh;
           }
              // 412x800 <824
              @media(min-width:411px)and (max-width:413px)
              and  (min-height:640px) and (max-height:700px)
              
              {
                  height:52vh;
              }
              //412 >824 <900
              @media(min-width:411px)and (max-width:413px)
              and  (min-height:701px) and (max-height:740px)
              
              {
                  height:52vh;
              }


            // 412 x915
            @media(min-width:411px)and (max-width:413px)
            and  (min-height:741px) 
            
            {
                height:48vh;
            }
         



            // 414
            @media(min-width:414px)and (max-width:414px)
            and  (min-height:640px) and (max-height:800px)
            
            {
                height:52vh;
            }
            // 428
            @media(min-width:428px)and (max-width:428px)
            and  (min-height:640px) and (max-height:800px)
            
            {
                height:51vh;
            }
                // 430
                @media(min-width:430px)and (max-width:430px)
                and  (min-height:640px) and (max-height:800px)
                
                {
                    height:50vh;
                }
             // Big phones
             @media(min-width:431px)
             and  (min-height:640px) and (max-height:800px)
             
             {
                    height:50vh;
             }
             @media(min-width:431px)
             and  (min-height:800px) and (max-height:900px)
             
             {
                    height:46vh;
             }
     


        
       
    }

    @include gadgets-to(tablet){
    width:350px;
    height:62vh;
    @media(orientation:portrait){
        height:37vh;
    }

           // big tablets
           @media(min-width:1366px){
            height:43vh;
       }

}
@include gadgets-to(laptop){
    width:400px;
    height:42vh;

}
@include gadgets-to(desktop){
    width:400px;
    height:43vh;
}
@include gadgets-to(largeDesktop){
    width:450px;
    height:40vh;
}
}

.xzloginText {
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: rgb(225, 176, 68);
}
.xzboxTop{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.xzloginTextJAP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
   // font-weight: bold;
    color: rgb(225, 176, 68);
    @include gadgets-to(largeDesktop){
        font-size: 40px;
    }
}

.boxgoogleWrapper{
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//   //  align-items: center;
//     margin-bottom:1px;
//     width:100%;
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
width:90%;
height:40px;
background-color:white;
//border:1px solid map-get($generalStyles,datalistBorderColor);
outline:none;
border-radius:5px;
color:black;
cursor:pointer;
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
&:hover {
  padding:2px;
}
@include gadgets-to(mobile) {
  width:90%;
}

}
.xzboxGoogle {
   // margin-left: 2px;
   // width:75%;
   @include gadgets-to(tablet){
 
}
}

.xzboxSignup {
text-align: center;
    margin-top: 5px;
}


.xzboxTop {}

.xzboxBottom {
    margin-bottom: 10px;
}

.xzloginFieldset {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: 4px;
    margin-bottom: 10px;
    width:82%;
    height:40px;
    @include gadgets-to(tablet){
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        width:83%;
        height:5.4vh;
        margin-bottom:10px;
    }
    @include gadgets-to(laptop){
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        width:83%;
        height:4vh;
    }
    @include gadgets-to(desktop){
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        width:83%;
        height:4vh;
    }
    @include gadgets-to(largeDesktop){
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        width:83%;
        height:4vh;
    }
}

.xzloginLegend {
    font-family: var(--font-family);
    font-size: 12px;
}

.xzloginEmail, .xzloginPass {
    width: 90%;
    height: 30px;
    outline: none;
    border:none;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: white;
}



.xzloginPassJAP {
    width: 90%;
    height: 30px;
    outline: none;
    border: none;
    font-family: var(--font-family);
    font-size: 14px;
}

.xzloginEmail:-webkit-autofill,
.xzloginPass:-webkit-autofill,
.xzloginPassJAP:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black;
}

.xzloginButton, .xzloginButtonNo {
    font-family: var(--font-family);
    font-size: 16px;
    width: 90%;
    height: 35px;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, buttonLoginColor);
    color: map-get($generalStyles, colorwhite);
    margin-left: 1px;
}

.xzloginButtonNo {
    cursor: not-allowed;
}
.xzloginButtonForce{
    font-family: var(--font-family);
    font-size: 12px;
    width: 90%;
    height: 25px;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, buttonLoginColor);
    color: map-get($generalStyles, colorwhite);
    margin-left: 1px;
    margin-top:0.5vh;
}
.xzsingUpButton {
    width: 92%;
    height: 45px;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
    border: none;
    background-color: map-get($generalStyles, colorwhite);
}

.xzunderlinedText {
    text-decoration: underline;
    color: map-get($generalStyles, buttonLoginColor);
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    @include gadgets-to(tablet){
        font-size: 14px;
    }
}
.xzlogintext{
    font-family: var(--font-family);
    font-size: 14px;
    color:black;

}

.xzloginSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: scale(1.5);
}

.xzcountryFlagBox {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.xzloginflaggb, .xzloginflagse, .xzloginflagJapan {
    width: 30px;
    height: 20px;
    margin-left: 3px;
    cursor: pointer;
    @include gadgets-to(largeDesktop){
        width: 40px;
        height: 30px;
    }
}

.xzloginflagJapan {
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
  
}
.xzloginflagJapan1{
    width: 30px;
    height: 20px;
    margin-left: 3px;
    cursor: pointer;
    @include gadgets-to(largeDesktop){
        width: 40px;
        height: 30px;
    }

}

.xzlangaugesAvailabletext {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 11px;
    color: map-get($generalStyles, buttonLoginColor);
    margin-top: 10px;
}

.xzloginButtonWrapper{
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}

.xzlineloginboxWrapper{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
}

.xzlineloginButton {
width:240px;
height:40px;
background-color:#06C755;
border:none;
border-radius: 6px;
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items: center;
cursor:pointer;
// hover

@include gadgets-to(tablet){
    width:240px;
    height: 40px;
    background-color:#06C755;
    border:none;
    border-radius: 6px;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items: center;
    cursor:pointer;
  
}
}

  .xzlinebuttonImage {
    margin-right: 8px; /* Adjust as needed */
    width:37px; /* Adjust as needed */
    height:37px; /* Adjust as needed */

  }
  .xzlinebuttonText{
font-family: 'Helvetica Neue', Arial, sans-serif;
font-weight: bold;
font-size:16px;
color:white;
  }

.xzlineloginbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-top: 3px;
    width:100%;
  //  margin-left: 1px;
}

// .xzlinelogin {
//     width: 70%;
//     height: 30%;
//     margin-top: 4px;
//     cursor: pointer;
//     @include gadgets-to(mobile){
//         width: 50%;
//         height: 30%;
//         margin-top: 4px;

//               //384 phones
//               @media(min-width:384px)and (max-width:384px)
//               and  (min-height:640px) and (max-height:800px)
              
//               {
//                 width: 50%;
//                 height: 30%;
//                 margin-top: 4px;
//               }
        
        
//     }
// @include gadgets-to(tablet){
//     width: 50%;
//     height: 30%;
//     margin-top: 4px;

// }
// @include gadgets-to(laptop){
//     width: 50%;
//     height: 30%;
//     margin-top: 4px;

// }
// @include gadgets-to(desktop){
//     width: 50%;
//     height: 30%;
//     margin-top: 4px;

// }
// @include gadgets-to(largeDesktop){
//     width: 40%;
//     height: 30%;
//     margin-top: 4px;

// }
// }
.xzbackgroundImageLogin {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1);
    overflow:hidden;
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
   
  }

.xzforgotPasswordTop {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-family: var(--font-family);
    font-size: 12px;
    color: map-get($generalStyles, buttonLoginColor);
    cursor: pointer;
}
.xzforogotPasswordText {
    font-family: var(--font-family);
    font-size: 12px;
    color: map-get($generalStyles, buttonLoginColor);
    cursor: pointer;
}
.xzinputEmailArea {
    @include gadgets-to(mobile){

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:97%;
    margin-top:2vh;

    @media(max-width:360px)and (min-height:500px)
    {
        margin-top:3px;
    
    }
    @media(min-width:384px)and (max-width:384px)
    and (min-height:700px)
    {
        margin-top:5vh;
    } 

}
@include gadgets-to(tablet){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:97%;
    margin-top:1vh;
}
@include gadgets-to(laptop){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:97%;
    margin-top:2vh;

}
@include gadgets-to(desktop){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:98%;
    margin-top:2vh;

}
@include gadgets-to(largeDesktop){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:98%;
    margin-top:2vh;

}
}

.xzresetInputEmail {
    width:65%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: 6px;
    background-color: map-get($generalStyles, colorwhite);
    //margin-top: 10px;
    outline:none;
}
.xzsendEmailWrapper{
    @include gadgets-to(mobile){

    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom:5px;
    border-radius: 6px;
    height:10vh;
    padding:5px;

}
@include gadgets-to(tablet){
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom:5px;
    border-radius: 6px;
    height:7vh;
    padding:5px;
}
@include gadgets-to(laptop){
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom:5px;
    border-radius: 6px;
    height:10vh;
    padding:5px;
}
@include gadgets-to(desktop){
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom:5px;
    border-radius: 6px;
    height:6vh;
    padding:5px;
}
@include gadgets-to(largeDesktop){
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom:5px;
    border-radius: 6px;
    height:6vh;
    padding:5px;
}
}

.xzSendButton {
    width:28%;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, buttonLoginColor);
    color: map-get($generalStyles, colorwhite);
    margin-left: 3px;
}
// on hover
.xzSendButton:hover {
    background-color: map-get($generalStyles,pinkRed);
    color: map-get($generalStyles, colorwhite);
}
//on active
.xzSendButton:active {
    background-color: map-get($generalStyles, buttonLoginColor);
    color: map-get($generalStyles, colorwhite);
}
.xzresetloginFieldset{
    border: 1px dotted;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: 4px;
    width:97%;
  //  margin-top:2vh;
   //// height:3vh;
   display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    
}
.xzresetloginLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: map-get($generalStyles, buttonLoginColor);
}

.xzareaC {
    grid-area:c;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    margin-top:5vh;
}

.xzAreaCWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:50vh;
    @include gadgets-to(tablet){
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width:100%;
        height:47vh;
    }
    @include gadgets-to(laptop){
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width:100%;
        height:50vh;
    }
    @include gadgets-to(desktop){
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width:100%;
        height:50vh;
    }
    @include gadgets-to(largeDesktop){
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width:100%;
        height:50vh;
    }
}
.xzLatestNewsArea{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:70%;
    height:50vh;
    max-height:50vh;
    overflow-y:scroll;
    overflow-x:hidden;
    border:3px dotted grey;
    padding:7px;
    border-radius:12px;
 //   box-shadow: 10px 12px 15px 5px rgba(170, 161, 161, 0.8);
 //   background-color: rgba(216, 194, 194, 0.3);

    &::-webkit-scrollbar {
        display: none;
      }
  

}
.xzlatestNewsHeader{
    font-family: var(--font-family);
    font-size: 26px;
    color: map-get($generalStyles, buttonLoginColor);
    margin-top:5px;
    @include gadgets-to(tablet){
        font-size: 26px;
    }
    @include gadgets-to(laptop){
        font-size: 30px;
    }
    @include gadgets-to(desktop){
        font-size:34px;
    }
    @include gadgets-to(largeDesktop){
        font-size: 36px;
    }
}
.xzlatestNewsBox{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
.xzlatestNewsTitle{
    @include gadgets-to(tablet){

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 16px;
    color: map-get($generalStyles, buttonLoginColor);
    margin-top:5px;
    margin-left:1vw;
}
@include gadgets-to(laptop){
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 20px;
    color: map-get($generalStyles, buttonLoginColor);
    margin-top:5px;
    margin-left:1vw;

}
@include gadgets-to(desktop){
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 24px;
    color: map-get($generalStyles, buttonLoginColor);
    margin-top:5px;
    margin-left:1vw;

}
@include gadgets-to(largeDesktop){
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 24px;
    color: map-get($generalStyles, buttonLoginColor);
    margin-top:5px;
    margin-left:1vw;

}
}
.xzlatestNewsText{
    @include gadgets-to(tablet){
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    color:white;
    margin-top:5px;
    width:100%;
    margin-left:1vw;
}
@include gadgets-to(laptop){
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 20px;
    color:white;
    margin-top:5px;
    width:100%;
    margin-left:1vw;

}
@include gadgets-to(desktop){
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 22px;
    color:white;
    margin-top:5px;
    width:100%;
    margin-left:1vw;

}
@include gadgets-to(largeDesktop){
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 22px;
    color:white;
    margin-top:5px;
    width:100%;
    margin-left:1vw;

}
}

.xzlanguageArea{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:0.5vh;
    margin-bottom:10px;
}

// footer area

.xzfooterArea{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:30vh;
    background-color:map-get($generalStyles,footerColor);
    margin-top:5vh;
    border-radius:12px;
    background-color:transparent;
    color:white;
    font-size: 22px;
    font-family: map-get($generalStyles,fontFamilyLobster);
}
.xzreturnArea{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:1vh;
    font-size:24px;
    font-family: map-get($generalStyles,fontFamilyLobster);
    color:white;

}
.xzfooterAreaWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

}
.footer-links {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    display: flex;
    gap: 15px;
    font-size:20px;
    font-family: map-get($generalStyles,fontFamilyLobster);
  
    li {
      a {
        color:white;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: #1e90ff;
        }
      }
    }
  }

  .xzmidWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:70vh;
}
  .xzSignUpWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:50vh;
    background-color:transparent;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
.xzSignUpTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:98%;
    height:40vh;
    background-color:transparent;
    border-radius: map-get($generalStyles, sixBorderRadius);
   // border:2px solid white;
}
.xzSignUpText{
    font-family: map-get($generalStyles,fontFamily);             
    font-size: 15px;
    color:black;
    margin-bottom: 5px;
}
.xzSignUpWithBakesoftText{
    font-family: map-get($generalStyles,fontFamilyLobster);             
    font-size: 15px;
    color:black;
    margin-bottom: 5px;
    margin-top:5px;
}

.xzSignUpWithGoogleDetailsTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:98%;
    height:40vh;
    background-color:transparent;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border:3px dotted white;
    background-color: white;
}
.xzSignUpWithGoogleInput{
    width: 90%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: 6px;
    background-color: map-get($generalStyles, colorwhite);
    margin-top:7px;
    outline:none;
}
.xzSignUpWithGoogleDetailsButton{
    width: 91%;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, buttonLoginColor);
    color: map-get($generalStyles, colorwhite);
   // margin-left: 1px;
    margin-top:5px;
}
.xzSignUpWithGoogleImage{
    width:60px;
    height:60px;
    margin-left: 3px;
    // make image round
    border-radius: 50%;
    margin-top:0.2vh;
  
}
.xzSignUpWithBakesoftTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:81%;

}
.xzSignUpWithBakesoftButton{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width: 91%;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, buttonLoginColor);
    color: map-get($generalStyles, colorwhite);
   // margin-left: 1px;
    margin-top:5px;
    font-size: 16px;
    font-family:map-get($generalStyles,fontFamilyLobster);
}

.klarnaTopHr{
    width: 70%;
    margin-top: 5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-bottom:5px;
    margin-top:5px;
}
.klarnamessage{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
 width:100%;
    height:40px;
    margin-top:5px;
    border-radius:3px;
   // zoom:0.7;
 //   transform: scale(0.9);
  //  transform-origin: top left;
}
.xzMobileWelcomeMessageTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:90%;

}


.xzMobileWelcomeMessage{
    font-family: map-get($generalStyles,fontFamilyLobster);             
    font-size: 22px;
    color:white;
    margin-bottom: 5px;
    margin-top:5px;
}
.xzPaymentWrapper{
    @include gadgets-to(tablet){

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:300px;
    height:220px;
    background-color:transparent;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(laptop){
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:400px;
    height:240px;
    background-color:transparent;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(desktop){
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:400px;
    height:240px;
    background-color:transparent;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(largeDesktop){
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:300px;
    height:240px;
    background-color:transparent;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
}

.xzPaymentChannelKlarna{
    height:80px;
    width:250px;
   // zoom:0.9;

}
.xzPaymentChannelPayPal{
height:1500px;
width:200px;
}

.xzfbWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:2px;
}

.xzcontinueGoogleButton{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    height:40px;
    background-color:white;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius:5px;
    color:black;
    cursor:pointer;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    &:hover {
      padding:2px;
    }
    @include gadgets-to(mobile) {
      width:90%;
    }
}

.xzcontinueFacebookButton{
    background-color: #4267B2;  // Facebook blue color
    color: white;
    width:90%;
    margin-top:5px;
    height:40px;
    border-radius:5px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    border:none;
    outline:none;
    cursor:pointer;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    &:hover {
      border:2px solid #4267B2;
    }
    @include gadgets-to(mobile) {
      width:90%;
    }
  
}

.xzcontinueLineButton{
    background-color:#06C755;// Facebook blue color
    color: white;
    font-size:16px;
    width:90%;
    margin-top:5px;
    height:40px;
    border-radius:5px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    border:none;
    outline:none;
    cursor:pointer;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;   
    &:hover {
      border:2px solid #06C755;
    }
    @include gadgets-to(mobile) {
      width:90%;
    }
  
}

.xzsignUpPointerWrapper{
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
    width:100%;
    height:30vh;
   // border:1px solid red;
   margin-top:10vh;
   @include gadgets-to(tablet){
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-start;
   
}
}
// .xzSignUpPointerButton{
//     display:flex;
//     flex-direction:row;
//     justify-content:center;
//     align-items:center;
//     width:70%;
//     height:50px;
//     background-color:Black;
//     border-radius:12px;
//     color:white;
//     font-size: 18px;
//     font-family: map-get($generalStyles,fontFamily);
//     cursor:pointer;
//    // margin-top:5px;
//     box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.5);
//     transition: all 0.3s ease;
//     background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    
//     &:hover{
//         color:white;
//         border:2px solid white;
//         // linear gradient background color
//      //   background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    
      
//     }
//     @include gadgets-to(desktop){
//         width:60%;
//         height:60px;
//     }
//     @include gadgets-to(largeDesktop){
//         width:60%;
//         height:60px;
//     }
// }
.xzSignUpPointerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 50px;
    border-radius:6px;
    color: white;
    font-size: 18px;
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.5);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
    background-size: 200% 200%; // Allows space for gradient animation
    animation: gradientShift 3s linear infinite alternate; // Animate forward and backward
  
    transition: all 0.3s ease;
  
    &:hover {
      border: 2px solid white;
    }
  
    @include gadgets-to(desktop) {
      width: 50%;
      height: 60px;
    }
  
    @include gadgets-to(largeDesktop) {
      width: 60%;
      height: 60px;
    }
  }
  
  // Keyframes for gradient animation
  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  
  

.xzfauserplusIcon{
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
    @include gadgets-to(largeDesktop){
        width: 40px;
        height: 30px;
    }
}