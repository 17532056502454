@import '../style/MainStyles.scss';/* styles.css */




.xltotalArea {
    @include gadgets-to(mobile) {

    display: grid;
    // grid-template-rows: 3% 5% 26% 17% 30%;
     grid-template-rows:auto auto auto auto;

     grid-template-areas:
         "a"
         "b"
         "c"
         "d"
         ;
     width: 100%;
     height:auto;
     object-fit: cover;
}

@include gadgets-to(tablet) {
    display: grid;
    grid-template-columns:50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;

   
}
@include gadgets-to(laptop) {
    display: grid;
    grid-template-columns:50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@include gadgets-to(desktop) {
    display: grid;
    grid-template-columns:50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@include gadgets-to(largeDesktop) {
    display: grid;
    grid-template-columns:50% 49%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
}

.xltotalArea1{
    @extend .xltotalArea;
     background-color:map-get($generalStyles, backgroundColor);

}
.xlheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;
        height:30px;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;

    }
@include gadgets-to(laptop) {
        grid-area: a;
    }
@include gadgets-to(desktop) {
        grid-area: a;
         height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
    }

}


.xlrangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:20px;
         }
         // as ipad
        //  @media(min-width:1171px) and (max-width:1195px)
        //  {
         
        //     height:auto;
        //  }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:20px;
         }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}


.xlrange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-right:10px;
        cursor:pointer;
        margin-left:10px;
    }
}

.xlrange2 {
    @extend .xlrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}


.xlCompName,
.xlCompName1 {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size:24px;
    @include gadgets-to(mobile) {
        font-size:24px;
        margin-top: 5px;
    }
    @include gadgets-to(tablet) {
        font-size: 28px;
        margin-top: 5px;
    }
    @include gadgets-to(laptop) {
        font-size: 32px;
        margin-top: 5px;
    }
    @include gadgets-to(desktop) {
        font-size: 32px;
        margin-top: 5px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 40px;
        margin-top: 5px;
    }
}

.xlCompName {
    color: map-get($generalStyles, colorwhite);

}

.xlCompName1 {
    color: map-get($generalStyles, transparentChangeableMenu5);
}



.xldatatopWrapper{
    @include gadgets-to(mobile) {
        // display:flex;
        // flex-direction:column;
        // justify-content:center;
        // align-items:center;
        // width:100%;
    }
    @include gadgets-to(tablet) {
        // display:flex;
        // flex-direction:column;
        // justify-content:center;
        // align-items:center;
        // width:100%;
    
}
@include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items: center;
      

    }
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    }
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    }

}

.xlareaA {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:82svh;
    background-color: map-get($generalStyles, transparentChangeable);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-top:1dvh;
    @include gadgets-to(mobile) {
        height:82svh;
        margin-top: 1dvh;
        @media(min-width:430px){
            height: 84svh;
            margin-top: 1dvh;
        }
        @media(min-width:390px) and (max-width:429px){
            height: 82svh;
            margin-top: 2dvh;
        }
        @media (min-width: 360px) and (max-width: 389px) {
            height:80svh;
            margin-top: 3dvh;
            @media(max-height: 640px){
                height: 80svh;
                margin-top: 4dvh;
            }
        }
    }
}

.xlareaB {
    @extend .xlareaA;
     grid-area: d;
    // border: none;
    // width: 99%;
    // height: 180px;
    // margin-left: 1px;
    // background-color: white;
    // margin-top:3svh;
}



.xlsearchFieldset {
    @include gadgets-to(mobile) {

    border: none;
    margin-top: 10px;
    width: 91%;
    margin-left: 5px;
    margin-top: 5px;
}
    @include gadgets-to(tablet) {
        border: none;
        margin-top: 10px;
        width: 91%;
        margin-left: 5px;
        margin-top: 5px;
    }
@include gadgets-to(laptop) {
        border: none;
        margin-top: 10px;
        width: 80%;
        margin-left: 5px;
        margin-top: 5px;
    }
    @include gadgets-to(desktop) {
        border: none;
        margin-top: 10px;
        width: 60%;
        margin-left: 5px;
        margin-top: 5px;
    }
    @include gadgets-to(largeDesktop) {
        border: none;
        margin-top: 10px;
        width: 80%;
        margin-left: 5px;
        margin-top: 5px;
    }

}

.xlsearchLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: black;
}
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        color: black;
    }
@include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        color: black;
        margin-left:0.8vw;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        color: black;
        margin-left:0.9vw;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        color: black;
        margin-left:0.9vw;
    }
}

.xlmessageContainer {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    grid-area: a;
}

.xltextSend {
    grid-area: a;
    width:60%;
    height: 30px;
    border: 1px solid;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: rgb(255, 128, 0);
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, datalisttextsize);
    resize: none;
    border-radius:map-get($generalStyles,sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    color: map-get($generalStyles, colorblack);
    outline: none;
}

.xlbuttonSend {
    @extend .xlbuttonSendEnabled;
    width: 150px;
    // Rest of the properties...
}




.xlbuttonSendEnabled {
    width:35%;
    height: 32px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, buttonklickedColor);
    color: white;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 5px;
    outline: none;
    transition: background-color 1s ease;
}

.xlSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;

}

.xlSpinner {
    color: map-get($generalStyles, colorblack);
}





.xlrecepyNameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.xlbuttonRecepyName {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:70%;
    height: 35px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:black;

}
    @include gadgets-to(tablet) {
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:70%;
    height: 35px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:black;
    }
@include gadgets-to(laptop) {
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:70%;
    height: 35px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:black;
    }
@include gadgets-to(desktop) {
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:70%;
    height: 35px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:black;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:70%;
    height: 45px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: 3px;
    cursor: pointer;
    font-family: Lobster, cursive;
    font-size: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:black;
    }
}




.xlbuttonRecepyNameHeader {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
    margin-bottom:3px;
    margin-top:3px;
}
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
    margin-bottom:3px;
    margin-top:3px;
    }
@include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
    margin-bottom:3px;
    margin-top:3px;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
        
    color: map-get($generalStyles, colorblack);
    margin-bottom:3px;
    margin-top:3px;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
    margin-bottom:3px;
    margin-top:3px;
    }
}


.xldataWrapper{
    @include gadgets-to(mobile) {
        width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    }
    @include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
}

.xldataContainer {
    @include gadgets-to(mobile) {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
   
    }
    @include gadgets-to(tablet) {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:95%;
    }
@include gadgets-to(laptop) {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:90%;
    }
@include gadgets-to(desktop) {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:85%;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:95%;
    }
}

.xlheader {
    display:flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
    margin-top: 10px;
   // margin-left: 1px;
}

.xlheaderButton,
.xlheaderKcals,
.xlheaderButtonBenaemn,
.xlheaderButtonAmount,
.xlheaderInvisible,
.xlheaderButtonUpdate {
    @include gadgets-to(mobile) {
    height: 25px;
   // border:none;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-bottom: 2px;
    //font-weight: bold;
   
}
    @include gadgets-to(tablet) {
        height: 25px;
        // border:none;
         border: map-get($generalStyles, prefferedBorderStyle);
         border-color: map-get($generalStyles, datalistBorderColor);
         background-color: map-get($generalStyles, transparentChangeableMenu5);
         color: map-get($generalStyles, colorblack);
         font-family: map-get($generalStyles, fontFamily);
         font-size: 12px;
         border-radius: map-get($generalStyles, sixBorderRadius);
         margin-bottom: 2px;
         //font-weight: bold;
    }
@include gadgets-to(laptop) {
    height: 25px;
    // border:none;
     border: map-get($generalStyles, prefferedBorderStyle);
     border-color: map-get($generalStyles, datalistBorderColor);
     background-color: map-get($generalStyles, transparentChangeableMenu5);
     color: map-get($generalStyles, colorblack);
     font-family: map-get($generalStyles, fontFamily);
     font-size: 12px;
     border-radius: map-get($generalStyles, sixBorderRadius);
     margin-bottom: 2px;
     //font-weight: bold;
    }
@include gadgets-to(desktop) {
    height: 25px;
    // border:none;
     border: map-get($generalStyles, prefferedBorderStyle);
     border-color: map-get($generalStyles, datalistBorderColor);
     background-color: map-get($generalStyles, transparentChangeableMenu5);
     color: map-get($generalStyles, colorblack);
     font-family: map-get($generalStyles, fontFamily);
     font-size: 12px;
     border-radius: map-get($generalStyles, sixBorderRadius);
     margin-bottom: 2px;
     //font-weight: bold;
    }
@include gadgets-to(largeDesktop) {
    height:30px;
    // border:none;
     border: map-get($generalStyles, prefferedBorderStyle);
     border-color: map-get($generalStyles, datalistBorderColor);
     background-color: map-get($generalStyles, transparentChangeableMenu5);
     color: map-get($generalStyles, colorblack);
     font-family: map-get($generalStyles, fontFamily);
     font-size: 20px;
     border-radius: map-get($generalStyles, sixBorderRadius);
     margin-bottom: 2px;
     //font-weight: bold;
    }
}

.xlheaderButtonBenaemn {
    width:calc(30% - 2px);
    text-align:center;
    padding:1px;
 
}

.xlheaderButtonAmount {
    width:calc(14% - 2px);
    margin-left: 2px;
    padding:1px;
}
.xlheaderKcals {
    width:calc(13% - 2px);
    margin-left: 2px;
    padding:1px;
}
.xlheaderButton{
    width:calc(15% - 2px);
    margin-left: 2px;
}

.xlheaderButtonUpdate {
    width:calc(20% - 2px);
    border-top-right-radius: 6px;
    margin-left: 2px;
    padding:1px;
}

.xlheaderInvisible{
    border:none;
    width:calc(8% - 2px);
    margin-left:2px;

}


.xlreceivedContainer {
//     max-height: 295px;
//     overflow-y: scroll;
//     width:100%;
//  //   margin-left: 1px;
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 12px;
width:100%;
height:36svh;
max-height:36svh;
overflow-y:scroll;
overflow-x:hidden;
// here we will reduce the size of the scrollbar by pseudo element
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }

    @include gadgets-to(mobile){

        @media(min-width:360px)and (max-width:360px){
            @media(min-height:700px){
            height:45vh;
            max-height:45vh;
            }
            
        }

        @media(min-width:375px)and (max-width:375px){
            height:45vh;
            max-height:45vh;
            
        }

        @media(min-width:384px)and (max-width:384px){
        height:45vh;
        max-height:45vh
        }

        @media(min-width:390px)and (max-width:390px){
            height:45vh;
            max-height:45vh
            }
            @media(min-width:393px)and (max-width:393px){
                height:45vh;
                max-height:45vh
                }
    @media(min-width:412px)and (max-width:414px){
        height:45vh;
        max-height:45vh
        }
        @media(min-width:428px){
            height:45vh;
            max-height:45vh
            }
}


    @include gadgets-to(tablet) {
        height: 48svh;
        max-height:48vh;
        overflow-y:scroll;
      //  overflow-x:hidden;
        @media(min-width:1024px) and (max-width:1080px){
            height: 40svh;
            max-height:40vh;
        }
        @media(min-width:1180px) and (max-width:1360px){
            height:46svh;
            max-height:46vh;
        }
        @media(min-width:1361px) and (max-width:1366px){
            height: 50svh;
            max-height:50vh;
        }
   
    }
    @include gadgets-to(laptop) {
        height: 48svh;
        max-height:48vh;
        overflow-y:scroll;



    }
    @include gadgets-to(desktop) {
        height: 48svh;
        max-height:48vh;
        overflow-y:scroll;

    }
    @include gadgets-to(largeDesktop) {
        height: 50svh;
        max-height:50vh;
        overflow-y:scroll;



    }

}

.xlreceivedContainer1 {
    @extend .xlreceivedContainer;
    height: 15svh;
    max-height:15svh;
    @include gadgets-to(mobile) {
        height: 15svh;
        max-height:15svh;
        @media(max-width: 360px) and (max-height: 640px){
            height: 3vh;
            max-height:3vh;
        }
        @media(max-width: 375px){
            height: 3vh;
            max-height:3vh;
        }
    }
    @include gadgets-to(tablet) {
        height:23svh;
        max-height:23svh;
        @media(max-width:1024px){
            height: 10svh;
            max-height:10svh;
        }
        @media(min-width:1070px) and (max-width:1080px){
            height:5svh;
            max-height:5svh;
        }
          @media(min-width:1100px) and (max-width:1120px){
            height:5svh;
            max-height:5svh;
    }

        @media(min-width:1180px) and (max-width:1360px){
            height:5svh;
            max-height:5svh;
        }

     
    }
    @include gadgets-to(laptop) {
        height:23svh;
        max-height:23svh;

    
    }
    @include gadgets-to(desktop) {
        height:23svh;
        max-height:23svh;

    }
    @include gadgets-to(largeDesktop) {
        height:23svh;
        max-height:23svh;

    }
}

.xldatarows{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    width:100%;

}

.xlnfobutton{
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color:map-get($generalStyles, datalistBorderColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
  //  margin-left: 2px;
    margin-bottom: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    cursor:pointer;
    @include gadgets-to(tablet) {
        height: 30px;
        font-size: 16px;
    }
    @include gadgets-to(laptop) {
        height: 30px;
        font-size: 16px;
    }
    @include gadgets-to(desktop) {
        height: 30px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height: 40px;
        font-size:26px;
    }
}


.xlinfobuttonBenaemn{
@extend .xlnfobutton;
    width:calc(30% - 2px);
    //ellipsis
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    padding-top:5px;
}



.xlinfobuttonBenaemn2{
    @extend .xlinfobuttonBenaemn;
    width:calc(30% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align:left;
}

.xlinfobuttonAmount {
@extend .xlnfobutton;
width:calc(14% - 2px);
margin-left:2px;
padding:1px;

}
.xlinfobuttonAmount2 {
    @extend .xlinfobuttonAmount;
    width:calc(14% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding:1px;
}
.xlinfobutton{
    @extend .xlnfobutton;
    width:calc(15% - 2px);
    margin-left:2px;
    padding:1px;
}
.xlinfobutton2{
    @extend .xlnfobutton;
    width:calc(15% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left:2px;
    padding:1px;
}
.xlkcalsButton{
    @extend .xlinfobutton;
    width:calc(13% - 2px);
    margin-left:2px;
    padding:1px;
}
.xlkcalsButton2{
    @extend .xlkcalsButton;
    width:calc(13% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left:2px;
    padding:1px;
}
.xlinfobuttonChecked{
    @extend .xlnfobutton;
    width:calc(20% - 2px);
    padding:1px;
    margin-left:2px;
}
.xlinfobuttonChecked2{
    @extend .xlinfobuttonChecked;
    width:calc(20% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left:2px;
}
.xlinfodeleteButton{
    @extend .xlnfobutton;
    @include gadgets-to(mobile) {
    width:calc(8% - 2px);
    margin-left:2px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    padding:3px;
}
    @include gadgets-to(tablet) {
        width:calc(8% - 2px);
        margin-left:2px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
@include gadgets-to(laptop) {
        width:calc(8% - 2px);
        margin-left:2px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
    @include gadgets-to(desktop) {
        width:calc(8% - 2px);
        margin-left:2px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(8% - 2px);
        margin-left:2px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
}
.xlinfodeleteButton2{
    @extend .xlinfodeleteButton;
    @include gadgets-to(mobile) {
    width:calc(8% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
    padding:3px;

}

    @include gadgets-to(tablet) {
        width:calc(8% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
@include gadgets-to(laptop) {
        width:calc(8% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
    @include gadgets-to(desktop) {
        width:calc(8% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(8% - 2px);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        padding:3px;
    }
}
.xlbiMessageSquareAdd {
    @include gadgets-to(mobile) {
    font-size: 18px;
    color:green;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 2px;
}
    @include gadgets-to(tablet) {
        font-size: 18px;
        color:green;
        margin-top: 5px;
        cursor: pointer;
        margin-left: 2px;
    }
@include gadgets-to(laptop) {
        font-size: 18px;
        color:green;
        margin-top: 5px;
        cursor: pointer;
        margin-left: 2px;
    }
    @include gadgets-to(desktop) {
        font-size: 18px;
        color:green;
        margin-top: 5px;
        cursor: pointer;
        margin-left: 2px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 28px;
        color:green;
        margin-top: 5px;
        cursor: pointer;
        margin-left: 2px;
    }
}

// .xlrideleteBackLine {
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 15px;
//     color: map-get($generalStyles, transparentChangeableMenu5);
//     cursor: pointer;
// }



// .xl-aiInfobutton,
// .xl-aiInfobutton2 {
//     width: 50px;
// }

// .xl-aikcalsButton,
// .xl-aikcalsButton2 {
//     width: 45px;
// }

// .xl-aiInfobuttonBenaemn,
// .xl-aiInfobuttonBenaemn2 {
//     width: 118px;
//     text-align: left;
//     white-space: nowrap;
//     cursor: pointer;
// }

// .xl-aiInfobuttonIngredid,
// .xl-aiInfobuttonIngredid2 {
//     width: 30px;
// }

// .xl-aiInfobuttonAmount,
// .xl-aiInfobuttonAmount2 {
//     width: 46px;
// }



.xlMatchOK,
.xlMatchOK2 {
    border: none;
    background-color: transparent;
    color: map-get($generalStyles, colorblack);
}

.mdDoneOutline {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    cursor: not-allowed;
    background-color: transparent;
    border: none;
}
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        cursor: not-allowed;
        background-color: transparent;
        border: none;
    }
@include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        cursor: not-allowed;
        background-color: transparent;
        border: none;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 16px;
        color: map-get($generalStyles, colorblack);
        cursor: not-allowed;
        background-color: transparent;
        border: none;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 20px;
        color: map-get($generalStyles, colorblack);
        cursor: not-allowed;
        background-color: transparent;
        border: none;
    }
}



// .xl-aiInfobuttonChecked,
// .xl-aiInfobuttonChecked2 {
//     width: 40px;
//     height: 20px;
//     border: map-get($generalStyles, prefferedBorderStyle);
//     border-color: map-get($generalStyles, datalistBorderColor);
//     color: map-get($generalStyles, colorblack);
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 12px;
//     border-radius: map-get($generalStyles, sixBorderRadius);
//     margin-left: 2px;
//     margin-bottom: 2px;
//     font-weight: bold;
// }

// .xl-aiInfobuttonChecked {
//     background-color: map-get($generalStyles, transparentChangeableMenu4);
// }

// .xl-aiInfobuttonChecked2 {
//     background-color: map-get($generalStyles, transparentChangeableMenu5);
// }

.xlSelect {
    @include gadgets-to(mobile) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    width:95%;
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
    }
    @include gadgets-to(tablet) {
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        width:95%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: white;
    }
@include gadgets-to(laptop) {
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        width:95%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: white;
    }
    @include gadgets-to(desktop) {
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        width:95%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: white;
    }
    @include gadgets-to(largeDesktop) {
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        font-family: map-get($generalStyles, fontFamily);
        font-size: 20px;
        width:95%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: white;
    }
}



.xltotalCostContainer {
    display: flex;
    flex-direction: row;
    width:100%;
  //  margin-top:0.4svh;
}

.xltotalCostButton,
.xltotalCaloriesButton,
.xltotalCostBenaemn,
.xltotalinfobuttonChecked,
.xltotaldeleteButton,
.xltotalAmountButton {
    @include gadgets-to(mobile) {
    width: 48px;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:4px;
    //margin-left: 2px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    padding-bottom:2px;
    align-items: center;

}
    @include gadgets-to(tablet) {
    width: 48px;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    border-radius:4px;
    //margin-left: 2px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    padding-bottom:2px;
    align-items: center;
    }

    @include gadgets-to(laptop) {
    width: 48px;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    border-radius:4px;
    //margin-left: 2px;
    margin-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
    font-style: italic;
        
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    padding-bottom:2px;
    align-items: center;
    }
    @include gadgets-to(desktop) {
        width: 48px;
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 16px;
        border-radius:4px;
        //margin-left: 2px;
        margin-bottom: 2px;
        white-space: nowrap;
        font-weight: bold;
        font-style: italic;
            
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        padding-bottom:2px;
        align-items: center;
}
    @include gadgets-to(largeDesktop) {
        width: 48px;
        height: 35px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 20px;
        border-radius:4px;
        //margin-left: 2px;
        margin-bottom: 2px;
        white-space: nowrap;
        font-weight: bold;
        font-style: italic;
            
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        padding-bottom:2px;
        align-items: center;
    }
}

.xltotalCostBenaemn {
    width:calc(30% - 2px);
    text-align: left;

}
.xltotalAmountButton {
    width:calc(14% - 2px);
    margin-left:2px;
 
}
.xltotalCostButton{
    width:calc(15% - 2px);
    margin-left:2px;

}
.xltotalCaloriesButton {    
    width:calc(13% - 2px);
    margin-left:2px;
}

.xltotalinfobuttonChecked{
    width:calc(20% - 2px);
    margin-left:2px;
}
.xltotaldeleteButton{
    width:calc(8% - 2px);
    margin-left:2px;
}



.xlSelectNewIngredientContainer {
    margin-top: 3px;
    margin-left: 2px;
}

.xlSelectDbOwnerIngredient {
    height: 25px;
    outline: none;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 187px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
}

.xlTextAreaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
}

.xladdAmountIngramPlus,
.xladdAmountIngramMinus {
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 11px;
    border-radius: 50px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    cursor: pointer;
}

.xladdAmountIngramPlus {
    padding-left: 7px;
}

.xladdAmountIngramMinus {
    padding-left: 3px;
}

.xlTextareaComments {
    width: 242px;
    height: 80px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
}

.xlReceivedRecepyDescription {
    @include gadgets-to(mobile) {

    width: 98.6%;
    height: 300px;
    margin-top: 5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistitemColor);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        
    }
}
@include gadgets-to(tablet) {
    width: 98.6%;
    height:76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistitemColor);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;


    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        
    }

}
@include gadgets-to(laptop) {
    width: 98.6%;
    height:76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistitemColor);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;
}
@include gadgets-to(desktop) {
    width: 98.6%;
    height:76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistitemColor);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    background-color: white;
    overflow-y: scroll;
}
@include gadgets-to(largeDesktop) {
    width: 98.6%;
    height:76dvh;
    margin-top: 5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistitemColor);
    resize: none;
    outline: none;
    margin-left: 1px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 24px;
    background-color: white;
    overflow-y: scroll;
}
}



.xlRecepydescHeader {
    font-family: map-get($generalStyles, fontFamilyTimes);
    font-size: 18px;
    font-style: italic;
    font-weight: bold;
    margin-top: 7px;
    margin-left: 8px;
}

.xldescriptionlengthWrapper{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    width:100%;

}

.xldescriptionLength {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    

}
.xlsaveMenuHeader{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:87%;
    margin-top:5px;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:87%;
    margin-top:5px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:87%;
    margin-top:5px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:78%;
    margin-top:5px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:87%;
    margin-top:5px;
}
}

.xlsaveMenuHeaderText{
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);

}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);

}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);

}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color: map-get($generalStyles, colorblack);

}
}

.xlsavemenuWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;


}

.xlSaveMenuContainer {
    width:90%;
    height:21svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
   // margin-left: 25px;
  //  margin-top: 8px;
    border-radius: map-get($generalStyles, sixBorderRadius);
@include gadgets-to(tablet) {
height:18dvh;
@media(min-width:1024px) and (max-width:1080px){
    height:20dvh;
}
//as 1180px
@media(min-width:1170px) and (max-width:1360px){
    height:25dvh;


   
}
}
@include gadgets-to(laptop) {
    width:90%;
    height:18svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
   // margin-left: 25px;
  //  margin-top: 8px;

}
@include gadgets-to(desktop) {
    width:80%;
    height:18svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
   // margin-left: 25px;
  //  margin-top: 8px;

}
@include gadgets-to(largeDesktop) {
    width:90%;
    height:13svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
   // margin-left: 25px;
  //  margin-top: 8px;

}
}


.xlSaveNameContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-top: 15px;
    width:100%;
}
.xlsavenameFieldset{
    @include gadgets-to(mobile) {
    border:none;
    width:80%;
    margin-top:5px;
    margin-bottom:5px;

}
@include gadgets-to(tablet) {
    border:none;
    width:50%;
    margin-top:5px;
    margin-bottom:5px;
}
@include gadgets-to(laptop) {
    border:none;
    width:50%;
    margin-top:5px;
    margin-bottom:5px;

}
@include gadgets-to(desktop) {
    border:none;
    width:50%;
    margin-top:5px;
    margin-bottom:5px;

}
@include gadgets-to(largeDesktop) {
    border:none;
    width:50%;
    margin-top:5px;
    margin-bottom:5px;

}
}

.xlsavenameLegend{
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
   // font-style: italic;
    color: map-get($generalStyles, colorblack);

}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);


}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);

}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);

}
}
.xlsavenametitle{
    font-family: map-get($generalStyles,font-family);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding-left:50%;
  
}
.xlSaveRecepySelect {
    @include gadgets-to(mobile) {
    width:100%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    background-color: white;
    box-sizing: border-box;
    color: map-get($generalStyles, colorblack);
    
}
@include gadgets-to(tablet) {
    width:100%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    background-color: white;
    box-sizing: border-box;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(laptop) {
    width:100%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    background-color: white;
    box-sizing: border-box;
    color: map-get($generalStyles, colorblack);

}
@include gadgets-to(desktop) {
    width:100%;
    height: 30px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    background-color: white;
    box-sizing: border-box;
    color:map-get($generalStyles, colorblack);

}
@include gadgets-to(largeDesktop) {
    width:100%;
    height: 30px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    background-color: white;
    box-sizing: border-box;
    color: map-get($generalStyles, colorblack);

}
}


.xlInputSaveRecepyName {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    width:100%;
    height: 25px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing: border-box;
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    width:100%;
    height: 25px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing: border-box;
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    width:100%;
    height: 25px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing: border-box;


}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color: map-get($generalStyles, colorblack);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    width:100%;
    height: 30px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing: border-box;

}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color: map-get($generalStyles, colorblack);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    width:100%;
    height: 30px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing: border-box;

}
}
.xlsaverecepybuttonContainer{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:2px;
  //  margin-right:22%;
  @include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
   // margin-right:55.5%;
    margin-top:2px;
}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
   // margin-right:55.5%;
    margin-top:2px;
}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
   // margin-right:55.5%;
    margin-top:2px;
}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
   // margin-right:55.5%;
    margin-top:2px;
}
}


.xlsaverecepyButton10{
    width:130px;
    height:30px;
    border: none;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    
}
.xlsaverecepyButton11{
    @extend .xlsaverecepyButton10;
    background-color: map-get($generalStyles, pinkRed);
    color:white;
    cursor: pointer;
  }

// .xlButtonSaveRecepy {
//     width: 123px;
//     height: 30px;
//     border: none;
//     color: map-get($generalStyles, colorblack);
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 12px;
//     border-radius: 6px;
//     cursor: pointer;
//     margin-left: auto;
// }

.xlbuttonsaverecepyContainer{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    width:100%;


}

.xlButtonSaveRecepy {
    width: 100px;
    height:30px;
    border:none;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius:6px;
    margin-right:5%;
    margin-top: 4px;
}
.xladdingredientsContainer{
    @include gadgets-to(mobile) {
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
@include gadgets-to(tablet) {
width:100%;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
@include gadgets-to(laptop) {
width:100%;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;

}
@include gadgets-to(desktop) {
    width:100%;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
@include gadgets-to(largeDesktop) {
    width:100%;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
}

.xlAddIngredientHeader {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    white-space: nowrap;
    width:100%;
    @include gadgets-to(mobile) {
        width:89%;
        margin-left:5px;
    }
    @include gadgets-to(tablet) {
        padding-left:5px;
       // padding-right:5px;
        width:89%;
    }
    @include gadgets-to(laptop) {
        padding-left:5px;
       // padding-right:5px;
        width:89%;
    }
    @include gadgets-to(desktop) {
        padding-left:5px;
       // padding-right:5px;
        width:69%;
    }
    @include gadgets-to(largeDesktop) {
        padding-left:5px;
       // padding-right:5px;
        width:69%;
        font-size:16px;
    }
}
.xladdingredientWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
   // margin-top:5px;

}
.xlAddIngredient {
    @include gadgets-to(mobile) {
    display: grid;
    grid-template-columns:22% 78%;
    gap:6px;
    grid-auto-rows:33px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top:5px;
    width:90%;
    height:20svh;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
   
        @media(max-width:360px){
            
            @media(max-height:640px){
                height: 28svh;
            }
            @media(min-height:641px) and (max-height:740px){
                height: 24svh;
            }
            @media(min-height:741px) and (max-height:800px){
                height: 28svh;
            }
        }
        @media(min-width:361px) and (max-width:384px){
            height: 23svh;
        }
        @media(min-width:385px) and (max-width:413px){
            height: 25svh;
        }
        @media(min-width:414px) and (max-width:480px){
            height: 23svh;
        }

}

    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:26% 74%;
        gap:8px;
        grid-auto-rows:30px;
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
       // margin-top:0px;
       padding-top:10px;
        width:90%;
        height:15svh;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
       
    height:15svh;
    width:90%;
    @media(max-width:1024px){
        height:23svh;
    }
    @media(min-width:1025px) and (max-width:1169px){
        height:20.5svh;
    }
    @media(min-width:1170px)and (max-width:1200px){
        height:23svh;
    }
    @media(min-width:1201px) and (max-width:1290px){
        height:22vh;
    }
    @media(min-width:1291px) and (max-width:1438px){
        height:17svh;
    }
}
@include gadgets-to(laptop) {
    display: grid;
    grid-template-columns:33% 67%;
    gap:8px;
    grid-auto-rows:30px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top:5px;
    width:90%;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
   
    height:18svh;
    width:80%;

}
@include gadgets-to(desktop) {
    display: grid;
    grid-template-columns:38% 62%;
    gap:8px;
    grid-auto-rows:30px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top:5px;
    width:90%;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
   
    height:16svh;
    width:70%;

}
@include gadgets-to(largeDesktop) {
    display: grid;
    grid-template-columns:40% 60%;
    gap:8px;
    grid-auto-rows:30px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top:5px;
    height:15svh;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
   
    height:12svh;
    width:70%;

}
}




.xlAddIngredientName,
.xlAddPrice{
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    background-color: map-get($generalStyles, colorTransparent);
    align-self: end;
    justify-self: end;
    padding-bottom:2px;
}
    
        @include gadgets-to(tablet) {
            font-family: map-get($generalStyles, fontFamily);
            font-size: 14px;
            color: map-get($generalStyles, colorblack);
            background-color: map-get($generalStyles, colorTransparent);
            align-self: end;
            justify-self: end;
        }
        @include gadgets-to(laptop) {
            font-family: map-get($generalStyles, fontFamily);
            font-size: 14px;
            color: map-get($generalStyles, colorblack);
            background-color: map-get($generalStyles, colorTransparent);
            align-self: end;
            justify-self: end;
        }
        @include gadgets-to(desktop) {
            font-family: map-get($generalStyles, fontFamily);
            font-size: 14px;
            color: map-get($generalStyles, colorblack);
            background-color: map-get($generalStyles, colorTransparent);
            align-self: end;
            justify-self: end;
        }
        @include gadgets-to(largeDesktop) {
            font-family: map-get($generalStyles, fontFamily);
            font-size: 14px;
            color: map-get($generalStyles, colorblack);
            background-color: map-get($generalStyles, colorTransparent);
            align-self: end;
            justify-self: end;
        }
    
}

.xlSupplier {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    align-self: end;
    justify-self: end;
}

.xlAddIngredientName{
display:flex;
flex-direction:row;
justify-content:flex-end;
align-items:flex-end;
width:100%;
height:100%;

}
.xlSupplier {
    margin-top: 5px;
}

.xlAddCategory{
    background-color: map-get($generalStyles, colorTransparent);
    color: map-get($generalStyles, colorblack);
    align-self: end;
    justify-self: end;
}

.xlSelectSupplier{

}
.xlInputAddIngredient {
    @include gadgets-to(mobile) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color:white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
@include gadgets-to(tablet) {
    display:flex;
    width:70%;
    height:30px;
    border: 1px solid;
    box-sizing: border-box;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color:white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;
}
@include gadgets-to(laptop) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color:white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
@include gadgets-to(desktop) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color:white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
@include gadgets-to(largeDesktop) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color:white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
}


// .xlInputAddIngredient {
//     margin-right: 10px;
//     background-color: map-get($generalStyles, datalistbackgroundColor);

// }

.xlButtonAddIngredient,
.xlButtonAddIngredient2 {
    width:120px;
    height:30px;
    border:none;
   // border-color: map-get($generalStyles, datalistBorderColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,font-family);
    font-size: 12px;
    border-radius:6px;
   // margin-left: 55px;
    margin-bottom: 1px;
    margin-top: 2px;
}

.xlButtonAddIngredient {
    background-color: map-get($generalStyles, pinkRed);
    cursor: pointer;
    color:white;
}

.xlButtonAddIngredient2 {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color:black;
    cursor:pointer;
    opacity: 0.8;
}

.xlInputAddPris{
    @include gadgets-to(mobile) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
  //  margin-top:5px;

}
@include gadgets-to(tablet) {
    display:flex;
    width:70%;
    height:25px;
    border: 1px solid;
    box-sizing: border-box;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;
}
@include gadgets-to(laptop) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
@include gadgets-to(desktop) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
@include gadgets-to(largeDesktop) {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
}
.xlSelectAddIngredient {
    @include gadgets-to(mobile) {
    width:200px;
    height:30px;
    border: none;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    // justify-self: end;
    // align-self: end;
    margin-top:1px;
    option{
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
}
@include gadgets-to(tablet) {
    width:70%;
    height:30px;
    border: none;
    box-sizing: border-box;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    // justify-self: end;
    // align-self: end;
    margin-top:5px;
    option{
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
}
@include gadgets-to(laptop) {
    width:200px;
    height:30px;
    border: none;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    // justify-self: end;
    // align-self: end;
    margin-top:5px;
    option{
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }

}
@include gadgets-to(desktop) {
    width:200px;
    height:30px;
    border: none;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    // justify-self: end;
    // align-self: end;
    margin-top:5px;
    option{
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }

}
@include gadgets-to(largeDesktop) {
    width:200px;
    height:30px;
    border: none;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    // justify-self: end;
    // align-self: end;
    margin-top:5px;
    option{
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }

}
}



.xlCloseSaveContainer {
    @include gadgets-to(mobile) {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(desktop) {
    cursor: pointer;
    margin-left: 45%;
    font-size: 18px;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    cursor: pointer;
    margin-left: 45%;
    font-size: 22px;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
}



.xladdingredientsContainerJAP{
    width:100%;
    @include gadgets-to(tablet) {
     width:90%;
}
@include gadgets-to(laptop) {
    width:90%;

}
@include gadgets-to(desktop) {
    width:70%;

}
@include gadgets-to(largeDesktop) {
    width:60%;

}
}
.xlAddIngredientHeaderJAP {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: flex-end;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left:3px;
    white-space: nowrap;
    width:100%;
}

.xlCloseSaveContainerJAP {
    margin-left: 60%;
    font-size: 22px;
    color: map-get($generalStyles, transparentChangeableMenu5);
}

.xlAddIngredientJAP {
    display: grid;
    grid-template-columns:25% 75%;
    gap:4px;
    grid-auto-rows:30px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top:5px;

    width:100%;
    height:23.5svh;
    
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    
    @include gadgets-to(mobile){
        height:26vh;
        @media(max-width:360px) and (max-height:640px){
            height:33vh;
        }
        @media(max-width:375px) and (max-height:640px){
            height:30vh;
        }
        @media(min-width:390px) and (max-width:390px){
            height:30vh;
        }
    
        @media(min-width:393px) and (max-width:393px){
        height:31vh;
        }
}

    @include gadgets-to(tablet){
    display: grid;
    grid-template-columns:32% 68%;
    gap:4px;
    grid-auto-rows:30px;
    height:20vh;
    @media(max-width:1024px){
        height:26vh;
    }
      @media(min-width:1070px) and (max-width:1090px){
        height:25vh;
    }
          @media(min-width:1100px) and (max-width:1120px){
        height:25vh;
    }
             @media(min-width:1121px) and (max-width:1200px){
        height:25vh;
    }
    @media(min-width:1201px){
        height:25vh;
    }

    

}
@include gadgets-to(laptop){
    display: grid;
    grid-template-columns:35% 65%;
    gap:4px;
    grid-auto-rows:30px;
    height:22vh;
}
@include gadgets-to(desktop){
    display: grid;
    grid-template-columns:38% 62%;
    gap:4px;
    grid-auto-rows:30px;
    height:18vh;
}
@include gadgets-to(largeDesktop){
    display: grid;
    grid-template-columns:40% 60%;
    gap:4px;
    grid-auto-rows:30px;
    height:14vh;
}
}


.xlAddIngredientNameJAP,
.xlAddPriceJAP,
.xlAddCategoryJAP {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    align-self: end;
    justify-self: end;
}

.xlAddIngredientNameJAP {
display:flex;
flex-direction:row;
justify-content:flex-end;
align-items:flex-end;
width:100%;
height:100%;
}

// .xlInputAddIngredientJAP,
// .xlInputAddFuriganaJAP,
// .xlSelectAddIngredientJAP,
// .xlInputAddPrisJAP {
//     height: 25px;
//     border: none;
//     background-color: map-get($generalStyles, datalistbackgroundColor);
//     color: map-get($generalStyles, colorblack);
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 14px;
//     border-radius: map-get($generalStyles, threeBorderRadius);
//     outline: none;
//     resize: none;
//     align-self: end;
// }

.xlInputAddIngredientJAP{
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;

}
.xlInputAddFuriganaJAP {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;
}


.xlSelectAddIngredientJAP{
    width:200px;
    height:30px;
    border: none;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    border-radius: 3px;
    padding: 1px;
    outline: none;
    resize: none;
    // justify-self: end;
    // align-self: end;
    margin-top:5px;
    option{
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
}

.xlInputAddPrisJAP {
    display:flex;
    width:195px;
    height:25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: white;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size:16px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    outline: none;
    resize: none;
    margin-top:5px;
}




.xlButtonAddIngredientJAP {
    width:120px;
    height:30px;
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
   // margin-left: 55px;
    margin-bottom: 1px;
    margin-top: 2px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    opacity:0.8;
    @include gadgets-to(mobile) {
        margin-top:7px;
    }
    @include gadgets-to(tablet) {
  margin-top:10px;
    }
    @include gadgets-to(laptop) {
        margin-top:7px;
    }
    @include gadgets-to(desktop) {
        margin-top:7px;
    }
    @include gadgets-to(largeDesktop) {
        margin-top:7px;
    }
}

.xlButtonAddIngredientJAP2 {
    @extend .xlButtonAddIngredientJAP;
    background-color: map-get($generalStyles,pinkRed);
    color: white;
    cursor: not-allowed;
    opacity:1;
}





.xlSaveMenuContainerJapan {
    width: 90%;
    height:22svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
   // margin-left: 20px;
   // margin-top: 10px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}

.xlSaveNameContainerJapan {
    display: flex;
    flex-direction: column;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-top: 5px;
}

.xlInputSaveRecepyNameJapan,
.xlInputSaveFuriganaNameJapan {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    width: 280px;
    height: 25px;
    border-radius: map-get($generalStyles, threeBorderRadius);
}

.xlInputSaveFuriganaNameJapan {
    margin-top: 2px;
}

.xlSaveRecepySelectJapan {
    width: 285px;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 3px;
    border-radius: 3px;
    background-color: white;
}

.xlsaverecepybuttonContainerJap{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;

}

.xlsaverecepyButton10Japan,
.xlsaverecepyButton11Japan {
    width:130px;
    height:30px;
    border: none;
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    

}

.xlsaverecepyButton10Japan {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    opacity: 0.8;
}

.xlsaverecepyButton11Japan {
    background-color: map-get($generalStyles, pinkRed);
    opacity: 1;
    color:white;
}

.xlOutlinePlusCircle {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}

.xllastSearch{
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 24px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
}

.xllastSearchtext {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    padding: 5px;
}
}


.xllastSearchtext {
    margin-top: 2px;
    border-bottom-left-radius: map-get($generalStyles, sixBorderRadius);
    border-bottom-right-radius: map-get($generalStyles, sixBorderRadius);
}

.xlbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }

  .xlspinner-text{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width:100%;
    font-size:8px;
    font-family: var(--font-family);
    background-color: white;
    border-radius: 4px;
    border:1px solid map-get($generalStyles,transparentChangeableMenu5);
    padding:3px;
}
.xlspinner-text1{
    position: absolute;
    top:50vh;
    left: 50%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    transform: translate(-50%, -50%);
    text-align: center;
    width:50%;
    height:20vh;
    font-size:18px;
    font-family: var(--font-family);
    background-color: white;
    border-radius: 4px;
    border:2px solid map-get($generalStyles,transparentChangeableMenu5);
    padding:3px;
    @include gadgets-to(mobile) {
        width:90%;
        height:23vh;
        font-size:12px;

    }
    @include gadgets-to(tablet) {
        width:50%;
        height:20vh;
        font-size:14px;

    }
    @include gadgets-to(laptop) {
        width:30%;
        height:20vh;
        font-size:16px;

    }
    @include gadgets-to(desktop) {
        width:30%;
        height:20vh;
        font-size:18px;

    }
    @include gadgets-to(largeDesktop) {
        width:30%;
        height:20vh;
        font-size:20px;

    }
}

// .xlalertOverlay{
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
//     z-index: 4;
// }

// .xlalertWrapper{
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:center;
//     width:100%;
//     @include gadgets-to(mobile) {
//         width:100%;
//     }
// }


.xlaialertTitle{
    font-size: 24px;
    font-family:var(--font-family);
    @include gadgets-to(mobile) {
        font-size:18px;
    }
    @include gadgets-to(tablet) {
        font-size:18px;
    }
    @include gadgets-to(laptop) {
        font-size:20px;
    }
    @include gadgets-to(desktop) {
        font-size:20px;
    }
    @include gadgets-to(largeDesktop) {
        font-size:24px;
    }
}
.xlaialertTitleJAP{
    @extend .xlaialertTitle;
    @include gadgets-to(mobile) {
        font-size:20px;
    
}
    @include gadgets-to(tablet) {
        font-size:20px;
    }
    @include gadgets-to(laptop) {
        font-size:20px;
    }
    @include gadgets-to(desktop) {
        font-size:20px;
    }
    @include gadgets-to(largeDesktop) {
        font-size:24px;
    }
}

.xlaialertMessage{
    font-size: 14px;
    font-family:var(--font-family);
    @include gadgets-to(mobile) {
       
        font-size:14px;
    }
    @include gadgets-to(tablet) {
        font-size:16px;
    }
    @include gadgets-to(laptop) {
        font-size:14px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size:18px;
    }
}
.xlaialertMessageJAP{
    @extend .xlaialertMessage;
    @include gadgets-to(mobile) {
        font-size:12px;
    
}
    @include gadgets-to(tablet) {
        font-size:16px;
    }
    @include gadgets-to(laptop) {
        font-size:14px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size:18px;
    }
}
.xlaialertCloseButton{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:50%;
    height:30px;
    background-color:map-get($generalStyles,pinkRed);
    border-radius:map-get($generalStyles,sixBorderRadius);
    padding:20px;
    color:white;
    border:none;
    cursor:pointer;
    margin-top:10px;


}