:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxtotWrapper {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxtotWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxtotWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxtotWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xxtotWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaTopArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxaTopArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxaTopArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxaTopArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-top: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xxaTopArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-top: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxatotalArea, .xxatotalArea1 {
    display: grid;
    grid-template-rows: 4% 4% 20% 71%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: transparent;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 360px) and (max-width: 375px) {
  .xxatotalArea, .xxatotalArea1 {
    grid-template-rows: 3% 3% 22% 72%;
    grid-template-rows: auto auto auto auto;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .xxatotalArea, .xxatotalArea1 {
    grid-template-rows: 3% 3% 19% 72%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) and (max-width: 420px) {
  .xxatotalArea, .xxatotalArea1 {
    grid-template-rows: 3% 3% 20% 72%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xxatotalArea, .xxatotalArea1 {
    grid-template-rows: 4% 4% 15% 72%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) {
  .xxatotalArea, .xxatotalArea1 {
    grid-template-rows: 4% 4% 17% 72%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxatotalArea, .xxatotalArea1 {
    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a" "b b" "c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 90%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxatotalArea, .xxatotalArea1 {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a" "b b" "c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 70%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxatotalArea, .xxatotalArea1 {
    display: grid;
    grid-template-columns: 25% 65%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a" "b b" "c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 70%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xxatotalArea, .xxatotalArea1 {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a" "b b" "c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 60%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xxatotalArea1 {
  background-color: white;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .areaA, .areaB {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-area: c;
    width: 100%;
    height: 23.3vh;
    margin-top: 1vh;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .areaA, .areaB {
    display: flex;
    flex-direction: row;
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    height: 90vh;
    background-color: var(--val);
    border-radius: 6px;
    padding: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .areaA, .areaB {
    height: 90vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .areaA, .areaB {
    display: flex;
    flex-direction: row;
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    border-radius: 6px;
    padding: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .areaA, .areaB {
    display: flex;
    flex-direction: row;
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    border-radius: 6px;
    padding: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .areaA, .areaB {
    display: flex;
    flex-direction: row;
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    border-radius: 6px;
    padding: 2px;
  }
}

.areaB {
  display: flex;
  flex-direction: row;
  grid-area: d;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .areaB {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1px;
    height: 90vh;
    min-height: 1vh;
    max-height: 90vh;
    overflow-y: auto;
  }
  .areaB::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .areaB {
    height: 72vh;
    max-height: 72vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .areaB::-webkit-scrollbar {
    width: 5px;
  }
  .areaB::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .areaB::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .areaB {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .areaB {
    height: 90vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .areaB {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .areaB {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .areaB {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .menuTopWrapper {
    width: auto;
    height: 20vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .menuTopWrapper {
    width: 100%;
    height: auto;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .menuTopWrapper {
    width: 100%;
    height: 75vh;
    max-height: 75vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .menuTopWrapper {
    width: 100%;
    height: auto;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .menuTopWrapper {
    width: 100%;
    height: auto;
    margin-top: 5px;
  }
}

.areaStyleBox {
  border: 1px solid;
  border-color: rgb(223, 212, 212);
}
@media (max-width: 575.98px) {
  .areaStyleBox {
    border: none;
  }
}
@media (min-width: 834px) {
  .areaStyleBox {
    border: 2px solid;
    margin-left: 1%;
    width: 48%;
    height: 68%;
    margin-top: 1%;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
  }
}
@media (min-width: 1200px) {
  .areaStyleBox {
    margin-left: 1%;
    width: 50%;
    height: 65%;
    margin-top: 1%;
    border-radius: 6px;
    border: 2px solid;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 99%;
    margin-top: 2px;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxaheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 35px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xxaheaderArea {
    height: 40px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxaheaderArea {
    grid-area: a;
    width: 99%;
    margin-top: 2px;
    height: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxaheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 60px;
  }
}
@media only screen and (min-width:2303px) {
  .xxaheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 60px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxacompName, .xxacompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxacompName, .xxacompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 36px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxacompName, .xxacompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxacompName, .xxacompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xxacompName, .xxacompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

.xxacompName1 {
  color: var(--rgbaVal);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxacompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxacompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 32px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxacompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxacompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xxacompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxarangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxarangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xxarangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xxarangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxarangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxarangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xxarangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.xxarangeAreaTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  width: 100%;
}

.xxarangeAreaText {
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  margin-right: 2%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxarange1, .xxarange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxarange1, .xxarange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxarange1, .xxarange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxarange1, .xxarange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xxarange1, .xxarange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.settingsSpinnerContainer {
  position: absolute;
  top: 122px;
  left: 970px;
  background-color: transparent;
  transform: scale(0.8);
}

.settingsSpinner {
  background-color: transparent;
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .menuTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vh;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .menuTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .menuTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .menuTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .menuTop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 99%;
    padding-top: 2px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .menuTop {
    height: 42%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 420px) {
  .menuTop {
    height: 42%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .menuTop {
    height: 50%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .menuTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    width: 95%;
    padding-top: 1.7vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .menuTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    width: 95%;
    padding-top: 2.7vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .menuTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    width: 95%;
    padding-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .menuTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    width: 95%;
    padding-top: 2vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .menupersonal, .menupersonal1 {
    flex: 1;
    flex-grow: 1;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    transition: 0.3s;
    width: auto;
    height: 35px;
    white-space: nowrap;
    border: none;
    margin-left: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 360px) and (max-width: 376px) {
  .menupersonal, .menupersonal1 {
    height: 33px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .menupersonal, .menupersonal1 {
    width: 80%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 16px;
    transition: 0.3s;
    height: 40px;
    white-space: nowrap;
    margin-top: 3px;
    border: none;
    margin-left: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .menupersonal, .menupersonal1 {
    width: 100%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    transition: 0.3s;
    height: 40px;
    white-space: nowrap;
    margin-top: 3px;
    border: none;
    margin-left: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .menupersonal, .menupersonal1 {
    width: 100%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 200;
    transition: 0.3s;
    height: 50px;
    white-space: nowrap;
    margin-top: 3px;
    border: none;
    margin-left: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}
@media only screen and (min-width:2303px) {
  .menupersonal, .menupersonal1 {
    width: 100%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    transition: 0.3s;
    height: 50px;
    white-space: nowrap;
    margin-top: 3px;
    border: none;
    margin-left: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .menupersonal1 {
    background-color: var(--rgbaVal);
    color: white;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .menupersonal1 {
    background-color: var(--rgbaVal);
    color: white;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .menupersonal1 {
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .menupersonal1 {
    background-color: var(--rgbaVal);
    color: white;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .menupersonal1 {
    background-color: var(--rgbaVal);
    color: white;
    border: none;
  }
}

.adressinfoTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  width: 100%;
}

.xxaInfoTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 90vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaInfoTop {
    width: 98%;
    height: 80vh;
  }
}

.xxaInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaInfoWrapper {
    margin-top: 1px;
  }
}

.xxaadressinfoHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaadressinfoHeaderWrapper {
    margin-top: 1px;
  }
}

.xxaadressinfoHeaderText {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 300;
  color: black;
  margin-bottom: 5px;
}

.xxainfoSurround {
  width: 100%;
  border-radius: 12px;
  box-shadow: 12px 12px 12px 6px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 12px;
  border: 1px solid rgb(223, 212, 212);
  height: 75vh;
  max-height: 75vh;
  overflow-y: auto;
}
.xxainfoSurround::-webkit-scrollbar {
  width: 10px;
}
.xxainfoSurround::-webkit-scrollbar-track {
  background: var(--rgbaVal2);
}
.xxainfoSurround::-webkit-scrollbar-thumb {
  background: var(--rgbaVal);
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--rgbaVal);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxainfoSurround {
    width: 100%;
    height: 75vh;
    max-height: 75vh;
    overflow-y: auto;
    box-shadow: 12px 12px 12px 6px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
  }
  .xxainfoSurround::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxainfoSurround {
    width: 100%;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    box-shadow: 12px 12px 12px 6px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
  }
  .xxainfoSurround::-webkit-scrollbar {
    width: 10px;
  }
  .xxainfoSurround::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .xxainfoSurround::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxainfoSurround {
    width: 100%;
    height: 72vh;
    max-height: 72vh;
    overflow-y: auto;
    box-shadow: 12px 12px 12px 6px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
  }
  .xxainfoSurround::-webkit-scrollbar {
    width: 10px;
  }
  .xxainfoSurround::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .xxainfoSurround::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xxainfoSurround {
    width: 100%;
    height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    box-shadow: 12px 12px 12px 6px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
  }
  .xxainfoSurround::-webkit-scrollbar {
    width: 10px;
  }
  .xxainfoSurround::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .xxainfoSurround::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

.xxaInfoType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 200;
  color: grey;
  margin-right: 5px;
  width: 80%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaInfoType {
    font-size: 10px;
    width: 80%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxaInfoType {
    font-size: 12px;
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxaInfoType {
    font-size: 14px;
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxaInfoType {
    font-size: 16px;
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xxaInfoType {
    font-size: 18px;
    width: 80%;
  }
}

.xxaInfoRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaInfoRow {
    width: 90%;
  }
}

.xxaInfoRowWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xxaInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xxaInfoInput, .xxaInfoInput1 {
  width: 80%;
  height: 20px;
  font-size: 16px;
  font-family: var(--font-family);
  border-radius: 6px;
  padding: 2px;
  color: black;
  outline: none;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(223, 212, 212);
  padding-top: 3px;
  margin-top: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxaInfoInput, .xxaInfoInput1 {
    width: 80%;
    height: 23px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxaInfoInput, .xxaInfoInput1 {
    padding-top: 2px;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxaInfoInput, .xxaInfoInput1 {
    height: 25px;
  }
}
@media only screen and (min-width:2303px) {
  .xxaInfoInput, .xxaInfoInput1 {
    height: 25px;
  }
}
@media (min-width: 1200px) {
  .xxaInfoInput, .xxaInfoInput1 {
    padding-top: 5px;
    margin-top: 2px;
    height: 25px;
  }
}

.xxaInfoInput1 {
  background-color: #d4cfcf;
}

.saveAddressInfoButton {
  font-size: 14px;
  font-family: var(--font-family);
  border-radius: 6px;
  padding: 3px;
  color: black;
  background-color: var(--rgbaVal);
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 1%;
  width: 82%;
  height: 30px;
  margin-top: 1%;
  margin-bottom: 1%;
}
.saveAddressInfoButton:hover {
  background-color: rgb(229, 64, 68);
  color: white;
  border: 2px solid white;
  box-shadow: 6px 6px 12px 6px rgba(0, 0, 0, 0.2);
  outline: auto;
}
@media (max-width: 575.98px) {
  .saveAddressInfoButton {
    font-size: 12px;
    width: 100%;
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .saveAddressInfoButton {
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .saveAddressInfoButton {
    height: 35px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .saveAddressInfoButton {
    height: 35px;
    width: 82%;
    margin-top: 5px;
  }
}

.abonnemangTop {
  display: flex;
  flex-direction: row;
}
@media (max-width: 575.98px) {
  .abonnemangTop {
    display: flex;
    flex-direction: column;
    width: 99%;
    height: 45vh;
  }
}
@media (min-width: 834px) {
  .abonnemangTop {
    display: flex;
    flex-direction: row;
    width: 99%;
    height: 65vh;
  }
}
@media (min-width: 1200px) {
  .abonnemangTop {
    width: 100%;
    height: 65vh;
  }
}

@media (max-width: 575.98px) {
  .abonnemangBas {
    width: 100%;
    height: 33%;
  }
}
@media (min-width: 834px) {
  .abonnemangBas {
    width: 33%;
    height: 33%;
  }
}
@media (min-width: 1200px) {
  .abonnemangBas {
    width: 31%;
  }
}
@media (max-width: 575.98px) {
  .abonnemangBas .abonnemangTotal {
    width: 100%;
    height: 33%;
  }
}
@media (min-width: 834px) {
  .abonnemangBas .abonnemangTotal {
    width: 33%;
    height: 30%;
  }
}
@media (min-width: 1200px) {
  .abonnemangBas {
    width: 30%;
  }
}

.abonnemangAI {
  width: 30%;
}
@media (max-width: 575.98px) {
  .abonnemangAI {
    width: 100%;
    height: 33%;
  }
}
@media (min-width: 834px) {
  .abonnemangAI {
    width: 30%;
    height: 33%;
  }
}
@media (min-width: 1200px) {
  .abonnemangAI {
    width: 30%;
    height: 33%;
  }
}

.abonnemangFieldset {
  width: 90%;
  height: 80vh;
  font-family: "Times New Roman, Times, serif";
  font-size: 20px;
  border: none;
}
@media (max-width: 575.98px) {
  .abonnemangFieldset {
    width: 100%;
    height: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media (min-width: 834px) {
  .abonnemangFieldset {
    width: 100%;
    height: 54vh;
    margin-top: 2vh;
  }
}
@media (min-width: 1200px) {
  .abonnemangFieldset {
    width: 100%;
    height: 62vh;
    margin-top: 4vh;
    margin-left: 1vh;
  }
}

.abonnemangHeader {
  font-size: 20px;
  font-family: var(--font-family);
  margin-top: 1vh;
  margin-left: 1vh;
}
@media (max-width: 575.98px) {
  .abonnemangHeader {
    font-size: 14px;
    font-family: var(--font-family);
    margin-top: 1vh;
    margin-left: 1vh;
  }
}
@media (min-width: 834px) {
  .abonnemangHeader {
    font-size: 18px;
    font-family: var(--font-family);
    margin-top: 1vh;
    margin-left: 5vh;
  }
}
@media (min-width: 1200px) {
  .abonnemangHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    font-family: var(--font-family);
    margin-top: 1vh;
  }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .listArea {
    font-family: var(--font-family);
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
}

.listRow {
  font-size: 12px;
}
@media (max-width: 575.98px) {
  .listRow {
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media (min-width: 834px) {
  .listRow {
    font-family: var(--font-family);
    font-size: 12px;
    margin-top: 2px;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .listRow {
    font-family: var(--font-family);
    font-size: 15px;
    margin-top: 4px;
  }
}

.abonnemangSaveButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
  margin-left: 1vh;
  margin-right: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .abonnemangSaveButtonContainer {
    margin-bottom: 1vh;
  }
}

.abonnemangSaveButton {
  font-family: var(--font-family);
  border-radius: 6px;
  padding: 3px;
  color: black;
  background-color: var(--rgbaVal);
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  height: 30px;
  width: 100px;
}

@media (min-width: 834px) {
  .abonnemangCheck {
    margin-left: 5vh;
  }
}
@media (min-width: 1200px) {
  .abonnemangCheck {
    transform: scale(1.5);
  }
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkLabel {
  font-size: 14px;
  font-family: var(--font-family);
  margin-left: 1vh;
}
@media (max-width: 575.98px) {
  .checkLabel {
    font-size: 14px;
    font-family: var(--font-family);
    margin-left: 1vh;
  }
}
@media (min-width: 834px) {
  .checkLabel {
    font-size: 10px;
    font-family: var(--font-family);
    margin-left: 1vh;
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .checkLabel {
    font-size: 14px;
    font-family: var(--font-family);
    margin-left: 1vh;
  }
}

@media (min-width: 834px) {
  .abonnemangPrisContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-left: 4vh;
  }
}
@media (min-width: 1200px) {
  .abonnemangPrisContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-left: 4vh;
  }
}

.abonnemangPris {
  font-size: 14px;
  font-family: var(--font-family);
  margin-left: 1vh;
}
@media (max-width: 575.98px) {
  .abonnemangPris {
    font-size: 14px;
    font-family: var(--font-family);
    margin-left: 1vh;
  }
}
@media (min-width: 834px) {
  .abonnemangPris {
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 1vh;
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .abonnemangPris {
    font-size: 14px;
    font-family: var(--font-family);
  }
}

.mobilAbonnemangTop {
  width: 100%;
}

.mobileTabTop {
  grid-area: b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4vh;
  width: 100%;
  font-family: var(--font-family);
  font-size: 12px;
  background: white;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .mobileTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    border: none;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: -10px;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .mobileTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mobileTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-right: -10px;
  }
  .mobileTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mobileTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .mobileTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mobileTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .mobileTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left: -10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .mobileTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .mobileTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}

.firstButton {
  border-top-left-radius: 6px;
}

.lastButton {
  border-top-right-radius: 6px;
}

.mobileTabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  border: 1px solid rgb(223, 212, 212);
  font-family: var(--font-family);
  font-size: 15px;
  border: none;
  margin-top: 4px;
  overflow-y: scroll;
}
.mobileTabContent::-webkit-scrollbar {
  width: 5px;
}
.mobileTabContent::-webkit-scrollbar-track {
  background: var(--rgbaVal2);
}
.mobileTabContent::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--rgbaVal);
}

.mobilBasTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.mobileBasTop2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobileBasHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobilBasHeader {
  font-family: var(--font-family);
  font-size: 20px;
  margin-left: 10px;
}

.mobileBasPrisWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobileBasPris {
  font-family: var(--font-family);
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
}

.daAnvandningTop {
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .daAnvandningTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .daAnvandningTop {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .daAnvandningTop {
    width: 100%;
  }
}

.daAnvandningHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2vh;
}
@media (min-width: 834px) {
  .daAnvandningHeader {
    margin-left: 2vh;
  }
}

.daAnvandningText {
  font-family: var(--font-family);
  font-size: 14px;
  margin-left: 10px;
}

.ddrowTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ddRowTop {
    display: flex;
    flex-direction: column;
    width: 90%;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: transparent;
    height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    margin-top: 1px;
  }
  .ddRowTop::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ddRowTop {
    display: flex;
    flex-direction: column;
    width: 90%;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: transparent;
    height: 55vh;
    margin-top: 1px;
    max-height: 55vh;
    overflow-y: auto;
  }
  .ddRowTop::-webkit-scrollbar {
    width: 5px;
  }
  .ddRowTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .ddRowTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ddRowTop {
    display: flex;
    flex-direction: column;
    width: 60%;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: transparent;
    height: 55vh;
    margin-top: 1px;
    max-height: 55vh;
    overflow-y: auto;
  }
  .ddRowTop::-webkit-scrollbar {
    width: 5px;
  }
  .ddRowTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .ddRowTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ddRowTop {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: transparent;
    height: 55vh;
    margin-top: 1px;
    max-height: 55vh;
    overflow-y: auto;
  }
  .ddRowTop::-webkit-scrollbar {
    width: 5px;
  }
  .ddRowTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .ddRowTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .ddRowTop {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: transparent;
    height: 55vh;
    margin-top: 1px;
    max-height: 55vh;
    overflow-y: auto;
  }
  .ddRowTop::-webkit-scrollbar {
    width: 5px;
  }
  .ddRowTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .ddRowTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

.ddRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.daRowTrackid {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 25px;
  margin-top: 3px;
  border-radius: 3px;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .daRowTrackid {
    height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .daRowTrackid {
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .daRowTrackid {
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .daRowTrackid {
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .daRowTrackid {
    height: 30px;
  }
}

.daRowBenaemn {
  text-align: left;
  padding-left: 10px;
  display: flex;
  align-items: center;
  width: calc(60% - 2px);
  height: 30px;
  margin-left: 2px;
  margin-top: 3px;
  border-radius: 3px;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .daRowBenaemn {
    height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .daRowBenaemn {
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .daRowBenaemn {
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .daRowBenaemn {
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .daRowBenaemn {
    height: 30px;
  }
}

.daRowAntal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(20% - 2px);
  height: 30px;
  margin-left: 2px;
  margin-top: 3px;
  margin-right: 3px;
  border-radius: 3px;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .daRowAntal {
    height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .daRowAntal {
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .daRowAntal {
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .daRowAntal {
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .daRowAntal {
    height: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .minDataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .minDataTop {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 33%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 768px) and (max-width: 1199px) {
  .minDataTop {
    width: 80%;
    height: 33%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1200px) and (max-width: 1300px) {
  .minDataTop {
    width: 50%;
    height: 33%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1301px) {
  .minDataTop {
    width: 70%;
    height: 33%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .minDataTop {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 33%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .minDataTop {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 33%;
  }
}
@media only screen and (min-width:2303px) {
  .minDataTop {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 33%;
  }
}

.minDataHeaderTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .minDataHeaderTextWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .minDataHeaderTextWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .minDataHeaderTextWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .minDataHeaderTextWrapper {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .minDataHeaderTextWrapper {
    width: 100%;
  }
}

.minDataHeaderText {
  font-family: var(--font-family);
  font-size: 18px;
  margin-left: 10px;
  margin-top: 10px;
}

.minaDataInformation {
  width: 100%;
  font-family: var(--font-family);
  font-size: 12px;
  background-color: transparent;
  margin-top: 1px;
  margin-bottom: 5px;
  margin-left: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .minaDataInformation {
    width: 90%;
  }
}

.minaDataHeaderTopWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minaDataHeaderTop {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 3px;
}

.minaDataHeaderTop.header {
  margin-bottom: 1px;
}

.minaDataHeaderServicesId1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 30px;
  background-color: var(--rgbaVal2);
  margin-left: 3px;
  border-radius: 6px;
}

.minaDataHeaderServicesBenaemn1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  height: 30px;
  margin-left: 3px;
  padding-left: 10px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
}

.minaDataHeaderServicesAntal1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
}

.minaDataHeaderServicesId {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 30px;
  background-color: var(--rgbaVal2);
  margin-left: 3px;
  border-radius: 6px;
}

.minaDataHeaderServicesBenaemn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  height: 30px;
  margin-left: 3px;
  padding-left: 10px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
}

.minaDataHeaderServicesAntal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
}

.minaDataSendInfoText {
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 10px;
  margin-top: 10px;
}

.minaDataSendInfoButton {
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  padding: 3px;
  color: black;
  background-color: var(--rgbaVal);
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 1%;
  margin-right: 1%;
  width: 130px;
  height: 30px;
  margin-top: 1%;
  margin-bottom: 1%;
}
.minaDataSendInfoButton:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}
.minaDataSendInfoButton:active {
  background-color: rgb(229, 64, 68);
}

.dabackgroundImageRecepyNew10 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.xxatjansterTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxatjansterTop {
    width: 100%;
    height: 75vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxatjansterTop {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxatjansterTop {
    width: 100%;
  }
}

.spinnerContainer {
  position: fixed; /* Make the spinner position relative to the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Take up the full viewport width */
  height: 100vh; /* Take up the full viewport height */
  display: flex; /* Use flexbox to center the spinner */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Add an overlay */
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .psTop {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psTop {
    width: 50%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psTop {
    width: 40%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psTop {
    width: 30%;
  }
}
@media only screen and (min-width:2303px) {
  .psTop {
    width: 30%;
  }
}

.psHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.psPassHeader {
  font-family: var(--font-family);
  font-size: 20px;
  margin-top: 5vh;
}

.pspassLabel {
  font-family: var(--font-family);
  font-size: 14px;
  padding-top: 5px;
}

.pspassLabel1 {
  opacity: 0;
  padding-top: 5px;
}

.psInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
}

.psInput {
  width: 80%;
  height: 25px;
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid rgb(223, 212, 212);
  padding: 5px;
  margin-top: 5px;
}

.psInput1 {
  opacity: 0;
}

.psInputNew, .psInputConfirm {
  width: 80%;
  height: 25px;
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid rgb(223, 212, 212);
  padding: 5px;
  margin-top: 5px;
  outline: none;
}

.psInputNew:focus, .psInputConfirm:focus {
  outline: none;
  border: 1px solid;
  border-color: var(--rgbaVal);
}

.pscheckbox {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-family);
  font-size: 10px;
}

.psSubmitbuttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.psSubmitbutton, .psSubmitbutton1 {
  width: 100px;
  height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  color: black;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.psSubmitbutton:hover, .psSubmitbutton1:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}

.psSubmitbutton:active, .psSubmitbutton1:active {
  background-color: rgb(229, 64, 68);
  outline: none;
  border: red;
}

.psSubmitbutton1 {
  opacity: 0.8;
}

.psInput:focus {
  outline: none;
  border: 1px solid;
  border-color: var(--rgbaVal);
}

.psEmailFieldset {
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  font-size: 14px;
  border: none;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.psEmailLegend {
  font-family: var(--font-family);
  font-size: 10px;
  text-align: center;
}

.psEmailResetCheckbox {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-family);
  font-size: 10px;
}

.psEmailSendSubmitButton:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}

.psEmailSendSubmitButton:active {
  background-color: rgb(229, 64, 68);
  outline: none;
}

.psInvoiceTop {
  width: 100%;
  height: 80vh;
}

.psinvoiceHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.psinvoiceHeader {
  font-family: var(--font-family);
  font-size: 20px;
  margin-top: 2vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .psinvoiceTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psinvoiceTop {
    width: 95%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psinvoiceTop {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psinvoiceTop {
    height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width:2303px) {
  .psinvoiceTop {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .psInvoiceTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 99%;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--val);
    margin-top: 4px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psInvoiceTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    background: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psInvoiceTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 98%;
    font-family: var(--font-family);
    font-size: 12px;
    background: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psInvoiceTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    background: white;
  }
}
@media only screen and (min-width:2303px) {
  .psInvoiceTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 60%;
    font-family: var(--font-family);
    font-size: 12px;
    background: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .psinvoiceTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    border: none;
    cursor: pointer;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .psinvoiceTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psinvoiceTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .psinvoiceTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-left: -10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psinvoiceTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .psinvoiceTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left: -10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psinvoiceTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    margin-left: -10px;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .psinvoiceTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left: -10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .psinvoiceTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .psinvoiceTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left: -10px;
    cursor: pointer;
  }
}

.psinvoicefirstButton {
  border-top-left-radius: 6px;
}

.psinvoicelastButton {
  border-top-right-radius: 6px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .psinvoiceTabContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh;
    max-height: 85vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 15px;
    margin-top: 1px;
    overflow-y: scroll;
  }
  .psinvoiceTabContent::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psinvoiceTabContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 68vh;
    max-height: 68vh;
    font-family: var(--font-family);
    font-size: 15px;
    margin-top: 1px;
    border-radius: 6px;
    overflow-y: auto;
  }
  .psinvoiceTabContent::-webkit-scrollbar {
    width: 1px;
  }
  .psinvoiceTabContent::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .psinvoiceTabContent::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psinvoiceTabContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 76vh;
    max-height: 76vh;
    font-family: var(--font-family);
    font-size: 15px;
    border-radius: 6px;
    margin-top: 1px;
    overflow-y: auto;
  }
  .psinvoiceTabContent::-webkit-scrollbar {
    width: 1px;
  }
  .psinvoiceTabContent::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .psinvoiceTabContent::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psinvoiceTabContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 76vh;
    max-height: 66vh;
    font-family: var(--font-family);
    font-size: 15px;
    border-radius: 6px;
    margin-top: 1px;
    overflow-y: auto;
  }
  .psinvoiceTabContent::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .psinvoiceTabContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 46vh;
    max-height: 46vh;
    font-family: var(--font-family);
    font-size: 15px;
    border-radius: 6px;
    margin-top: 1px;
    overflow-y: auto;
  }
  .psinvoiceTabContent::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .psinvoiceOverviewTop {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psinvoiceOverviewTop {
    width: 60%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psinvoiceOverviewTop {
    width: 60%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psinvoiceOverviewTop {
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .psinvoiceOverviewTop {
    width: 60%;
  }
}

.psinvoiceOverviewHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-color: rgb(223, 212, 212);
}

.psinvoiceOverviewHeader {
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 2vh;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .psinvoiceOverviewHeader {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psinvoiceOverviewHeader {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psinvoiceOverviewHeader {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psinvoiceOverviewHeader {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .psinvoiceOverviewHeader {
    font-size: 18px;
  }
}

.psinvoiceOverviewSum {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 5px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .psinvoiceOverviewSum {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psinvoiceOverviewSum {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psinvoiceOverviewSum {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psinvoiceOverviewSum {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .psinvoiceOverviewSum {
    font-size: 18px;
  }
}

.psexplanationTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
}

.psexplanationText {
  width: 90%;
  font-size: 12px;
  font-family: var(--font-family);
  color: grey;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .psexplanationText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .psexplanationText {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .psexplanationText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .psexplanationText {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .psexplanationText {
    font-size: 16px;
  }
}

.pspaymentChoicesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pspaymentNextPaymentDue {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 2vh;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .pspaymentNextPaymentDue {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .pspaymentNextPaymentDue {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .pspaymentNextPaymentDue {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .pspaymentNextPaymentDue {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .pspaymentNextPaymentDue {
    font-size: 18px;
  }
}

.pspaymentNextPaymentDueDate {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .pspaymentNextPaymentDueDate {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .pspaymentNextPaymentDueDate {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .pspaymentNextPaymentDueDate {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .pspaymentNextPaymentDueDate {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .pspaymentNextPaymentDueDate {
    font-size: 18px;
  }
}

.psline {
  height: 1px;
  background-color: lightgrey;
  width: 70%;
  margin-top: 2vh;
}

.psperiodPrePayment {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 2vh;
}

.pspreriodPrePaymentDate {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.pstotalyUsed {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 2vh;
  white-space: nowrap;
}

.pstotalyUsedSum {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.invoiceHistoryTop {
  width: 100%;
}

.invoiceHistoryHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.invoiceHeaderTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoicelistWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoicelistWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoicelistWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoicelistWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .invoicelistWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-bottom: 2px;
  }
}

.invoiceHistoryHeader {
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-style: italic;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceHistoryHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0.25;
    width: 70%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    transition: 0.3s;
    height: 25px;
    white-space: nowrap;
    border: none;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceHistoryHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    width: 70%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    transition: 0.3s;
    height: 25px;
    white-space: nowrap;
    border: none;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceHistoryHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    width: 70%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    transition: 0.3s;
    height: 25px;
    white-space: nowrap;
    border: none;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceHistoryHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    width: 70%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    transition: 0.3s;
    height: 25px;
    white-space: nowrap;
    border: none;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .invoiceHistoryHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    width: 70%;
    border-radius: 6px;
    background-color: var(--rgbaVal2);
    color: black;
    outline: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    transition: 0.3s;
    height: 25px;
    white-space: nowrap;
    border: none;
    margin-left: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceHistoryListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60vh;
    max-height: 60vh;
    overflow-y: scroll;
    padding-bottom: 10px;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) {
  .invoiceHistoryListWrapper {
    height: 50vh;
    max-height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 392px) and (max-width: 394px) {
  .invoiceHistoryListWrapper {
    height: 70vh;
    max-height: 70vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceHistoryListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 52vh;
    max-height: 52vh;
    margin-top: 1px;
    overflow-y: scroll;
    padding-bottom: 10px;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceHistoryListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar {
    width: 1px;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceHistoryListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar {
    width: 1px;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .invoiceHistoryListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar {
    width: 1px;
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceHistoryListWrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceHistoryList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceHistoryList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceHistoryList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceHistoryList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width:2303px) {
  .invoiceHistoryList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}

.invoiceHistoryListData {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0.2;
  width: 70%;
  border-radius: 6px;
  background-color: var(--rgbaVal2);
  color: black;
  outline: none;
  cursor: default;
  font-family: var(--font-family);
  font-size: 12px;
  transition: 0.3s;
  height: 25px;
  white-space: nowrap;
  margin-top: 2px;
  border: none;
  margin-left: 2px;
  margin-bottom: 2px;
}

.invoiceHistoryListDataPDF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0.2;
  width: 70%;
  border-radius: 6px;
  background-color: var(--rgbaVal2);
  color: black;
  outline: none;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 12px;
  transition: 0.3s;
  height: 25px;
  white-space: nowrap;
  margin-top: 2px;
  border: none;
  margin-left: 2px;
  margin-bottom: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceSettingsTop {
    width: 100%;
    background-color: var(--val);
    height: 64vh;
    max-height: 64vh;
    overflow-y: auto;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .invoiceSettingsTop {
    height: 72vh;
    max-height: 72vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceSettingsTop::-webkit-scrollbar {
    width: 5px;
  }
  .invoiceSettingsTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceSettingsTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceSettingsTop {
    width: 100%;
    background-color: var(--val);
    height: 64vh;
    max-height: 64vh;
    overflow-y: auto;
  }
  .invoiceSettingsTop::-webkit-scrollbar {
    width: 5px;
  }
  .invoiceSettingsTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceSettingsTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceSettingsTop {
    width: 100%;
    background-color: transparent;
    height: 64vh;
    max-height: 64vh;
    overflow-y: auto;
  }
  .invoiceSettingsTop::-webkit-scrollbar {
    width: 5px;
  }
  .invoiceSettingsTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceSettingsTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceSettingsTop {
    width: 80%;
    background-color: var(--val);
    height: 64vh;
    max-height: 64vh;
    overflow-y: auto;
  }
  .invoiceSettingsTop::-webkit-scrollbar {
    width: 5px;
  }
  .invoiceSettingsTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceSettingsTop::-webkit-scrollbar-thumb {
    background-color: var(--val);
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .invoiceSettingsTop {
    width: 70%;
    background-color: var(--val);
    height: 64vh;
    max-height: 64vh;
    overflow-y: auto;
  }
  .invoiceSettingsTop::-webkit-scrollbar {
    width: 5px;
  }
  .invoiceSettingsTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .invoiceSettingsTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

.invoiceSettingsHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.invoiceSettingsHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 16px;
  margin-top: 2vh;
  width: 100%;
  margin-bottom: 1vh;
}

.invoiceSettingsHeader1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 1vh;
}

.invoiceSettinsHeaderText {
  font-family: var(--font-family);
  font-size: 14px;
}

.invoiceSettingsListInfo {
  font-family: var(--font-family);
  font-size: 12px;
  width: 90%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceSettingsListInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 1vh;
  }
}

.invoiceSettingsCustomerAddress {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.invoiceSettingsCustomerAddressWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invoiceSettingspLine {
  height: 1px;
  background-color: lightgrey;
  width: 70%;
  margin-top: 2vh;
}

.invoiceSettingsTaxIdWrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.invoiceSettingsTaxIdHeader {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.invoiceSettingsTaxIdText {
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 1vh;
}

.invoiceSettingsTaxidSaveWrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 2px;
}

.invoiceSettingsTaxIdInput {
  width: 66%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid rgb(223, 212, 212);
  padding: 5px;
  margin-top: 2vh;
  outline: none;
}

.invoiceSettingsTaxidSave {
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  padding: 3px;
  color: black;
  background-color: var(--rgbaVal);
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 1%;
  margin-right: 1%;
  width: 90%;
  height: 40px;
  line-height: 1;
  margin-top: 1%;
  margin-bottom: 1%;
}
.invoiceSettingsTaxidSave:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}
.invoiceSettingsTaxidSave:active {
  background-color: var(--rgbaVal);
}
.invoiceSettingsTaxidSave:focus {
  outline: none;
}

.invoiceSettingsBillingAlertWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.invoiceSettingsBillingAlertHeader {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
  font-style: italic;
}

.invoiceSettingspLine1 {
  height: 1px;
  background-color: lightgrey;
  width: 70%;
  margin-top: 1vh;
}

.invoiceSettingsSliderWrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.invoiceSettingMaxValueAlertInput {
  width: 36%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid rgb(223, 212, 212);
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  outline: none;
  margin-right: 1vw;
}

.invoiceSaveMaxValueButtonWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invoiceSaveMaxValueButton, .invoiceSaveMaxValueButton1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  border-radius: 6px;
  color: black;
  background-color: var(--rgbaVal);
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  width: 80%;
  height: 30px;
  margin-top: 1%;
  margin-bottom: 1%;
  text-align: center;
}
.invoiceSaveMaxValueButton:hover, .invoiceSaveMaxValueButton1:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}
.invoiceSaveMaxValueButton:active, .invoiceSaveMaxValueButton1:active {
  background-color: var(--rgbaVal);
}
.invoiceSaveMaxValueButton:focus, .invoiceSaveMaxValueButton1:focus {
  outline: none;
}

.invoiceSaveMaxValueButton1 {
  opacity: 0.8;
}

.invoiceEndFooter {
  width: 100%;
  height: 3vh;
}

.kontoTop {
  width: 100%;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .kontoTop {
    box-shadow: 4px 4px 10px 10px rgba(112, 107, 107, 0.2);
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    justify-content: unset;
    border-radius: 8px;
    background-color: white;
    width: 96%;
    border: 1px solid rgb(223, 212, 212);
    cursor: pointer;
  }
  .kontoTop::-webkit-scrollbar {
    width: 5px;
  }
  .kontoTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .kontoTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

.kontoHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.kontoHeader {
  font-family: var(--font-family);
  font-size: 20px;
  margin-top: 1vh;
}

.kontoInfoTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kontoInfoWrapper {
  width: 97%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.kontoInfoHeader {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 2px;
  font-style: italic;
  margin-bottom: 1vh;
}

.kontoInfoHeaderWrapper {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.kontoInfoService {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontoInfoFaktura {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontoInfoDebit {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontoInfoKredit {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontoInfoDatum {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontoInfoKommentar {
  width: 25%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontoInforHeader1 {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
  font-style: italic;
  margin-left: 2px;
  border: 1px solid rgb(223, 212, 212);
}

.kontoInfoListWrapper {
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kontoListWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 1vh;
}

.kontoInfoList {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 3px;
  border-radius: 6px;
}

.kontolistService {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
  cursor: pointer;
}

.kontolistfaktura {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontolistdebit {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontolistkredit {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontolistdate {
  width: 15%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontolistkommentar {
  width: 25%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.footerHeader {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5vh;
}

.footerHeaderList {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 1vh;
  border-radius: 6px;
  border-color: rgb(223, 212, 212);
  outline: none;
  background-color: white;
}

.footerHeaderDebit {
  width: 40%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.footerHeaderKredit {
  width: 40%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.footerHeaderSaldo {
  width: 20%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
}

.kontoInfoServiceName {
  font-family: var(--font-family);
  font-size: 16px;
  margin-top: 1vh;
}

.invoiceRowsTopWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 16px;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.invoiceRowsTop {
  width: 100%;
  font-family: var(--font-family);
  font-size: 10px;
  font-style: italic;
  text-align: center;
}

.invoiceSelectWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.invoiceSelectList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 1vh;
  border-radius: 6px;
  border-color: rgb(223, 212, 212);
  outline: none;
  background-color: white;
}

.invoiceRowsHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  font-family: var(--font-family);
  font-size: 10px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceRowfakturaid {
    width: 20%;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceRowfakturaid {
    width: 20%;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceRowfakturaid {
    width: 15%;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceRowfakturaid {
    width: 15%;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .invoiceRowfakturaid {
    width: 15%;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceRowServiceid {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceRowServiceid {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceRowServiceid {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceRowServiceid {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .invoiceRowServiceid {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceRowSumma {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceRowSumma {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceRowSumma {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceRowSumma {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .invoiceRowSumma {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .invoiceRowDate {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .invoiceRowDate {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .invoiceRowDate {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .invoiceRowDate {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .invoiceRowDate {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
  }
}

.invoiceRowType {
  width: calc(5% - 2px);
  margin-left: 2px;
  height: 20px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  padding: 1px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
}

.invoiceRowsListWrapper {
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
}

.invoiceRowsList {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 3px;
  border-radius: 6px;
}

.invoiceServiceNameWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 1vh;
}
.invoiceServiceNameWrapper .invoiceServiceName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 1vh;
  font-style: italic;
  border: 1px dotted rgb(223, 212, 212);
  padding: 10px;
}

.invoiceRowsTotalSum {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  font-style: italic;
  margin-top: 1vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterTop {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tjansterTop::-webkit-scrollbar {
    display: none;
  }
  .tjansterTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .tjansterTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .tjansterTop {
    height: 68vh;
    max-height: 68vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterTop {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }
  .tjansterTop::-webkit-scrollbar {
    width: 10px;
  }
  .tjansterTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .tjansterTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterTop {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }
  .tjansterTop::-webkit-scrollbar {
    width: 10px;
  }
  .tjansterTop::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .tjansterTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterTop {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }
  .tjansterTop::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterTop {
    width: 90%;
    height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .tjansterTop::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterTop .tjansterHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterTop .tjansterHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterTop .tjansterHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterTop .tjansterHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterTop .tjansterHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterTop .tjansterWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterTop .tjansterWrapper {
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterTop .tjansterWrapper {
    width: 95%;
    height: 55vh;
    max-height: 55vh;
    overflow-y: scroll;
  }
  .tjansterTop .tjansterWrapper::-webkit-scrollbar {
    width: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterTop .tjansterWrapper {
    width: 95%;
    height: 75vh;
    max-height: 75vh;
    overflow-y: scroll;
  }
  .tjansterTop .tjansterWrapper::-webkit-scrollbar {
    width: 5px;
  }
  .tjansterTop .tjansterWrapper::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .tjansterTop .tjansterWrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .tjansterTop .tjansterWrapper {
    width: 95%;
    height: 75vh;
    max-height: 55vh;
    overflow-y: scroll;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterTop .tjansterHeaderText {
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 1vh;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterTop .tjansterHeaderText {
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 1vh;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterTop .tjansterHeaderText {
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 1vh;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterTop .tjansterHeaderText {
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 1vh;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterTop .tjansterHeaderText {
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 1vh;
    margin-bottom: 3px;
  }
}
.tjansterTop .tjansterRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;
}
.tjansterTop .tjansterLine {
  width: 80%;
  height: 1px;
  background-color: lightgrey;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterTop .tjansterId {
    width: 6%;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterTop .tjansterId {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterTop .tjansterId {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterTop .tjansterId {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterTop .tjansterId {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterTop .tjansterId1 {
    width: 5%;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterTop .tjansterId1 {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterTop .tjansterId1 {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterTop .tjansterId1 {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterTop .tjansterId1 {
    width: 3%;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterTop .tjansterBenaemn {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    padding-left: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterTop .tjansterBenaemn {
    width: calc(42% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterTop .tjansterBenaemn {
    width: calc(42% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterTop .tjansterBenaemn {
    width: calc(42% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterTop .tjansterBenaemn {
    width: calc(42% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterBenaemn1 {
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: none;
    width: calc(40% - 2px);
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterBenaemn1 {
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: none;
    width: calc(42% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterBenaemn1 {
    width: calc(42% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterBenaemn1 {
    width: calc(42% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: none;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterBenaemn1 {
    width: calc(42% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterPris {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterPris {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterPris {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterPris {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterPris {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterPris1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterPris1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterPris1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterPris1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterPris1 {
    width: calc(20% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tjansterAdd {
  width: calc(15% - 2px);
  margin-left: 2px;
  height: 20px;
  padding: 1px;
  font-family: var(--font-family);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterAdd1 {
    border: 1px solid var(--val);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 20px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterAdd1 {
    border: 1px solid var(--val);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterAdd1 {
    border: 1px solid var(--val);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterAdd1 {
    border: 1px solid var(--val);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterAdd1 {
    border: 1px solid var(--val);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
  }
}

.tjansterStat {
  width: calc(15% - 2px);
  margin-left: 2px;
  height: 20px;
  padding: 1px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterStat1 {
    border: 1px solid var(--val);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 20px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterStat1 {
    border: 1px solid var(--val);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterStat1 {
    border: 1px solid var(--val);
    justify-content: center;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterStat1 {
    border: 1px solid var(--val);
    justify-content: center;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterStat1 {
    border: 1px solid var(--val);
    justify-content: center;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    height: 25px;
    cursor: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tjansterInfoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tjansterInfoTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tjansterInfoText {
  width: 100%;
  border: 1px solid red;
}

.tjansteranimatedRow {
  width: 100%;
  margin-top: 2px;
  transition: 0.3s;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansteranimatedBenaemn {
    padding-left: 3px;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansteranimatedBenaemn {
    padding-left: 3px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.tjansteranimated {
  transform: rotate(0deg);
  animation-name: tjanster50;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes tjanster50 {
  0% {
    height: 0px;
  }
  100% {
    height: auto;
  }
}
.fakturaTop {
  width: 100%;
}

.fakturaWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fakturaSelect {
  width: 50%;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  height: 25px;
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 5px;
  outline: none;
  background-color: white;
}

.fakturaArea {
  width: 100%;
  height: 66vh;
  max-height: 66vh;
  overflow-y: scroll;
}
.fakturaArea::-webkit-scrollbar {
  width: 10px;
}
.fakturaArea::-webkit-scrollbar-track {
  background: var(--rgbaVal2);
}
.fakturaArea::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--rgbaVal);
}

.fakturaAreaWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fakturaAreaGrid {
  display: grid;
  grid-template-rows: 15% 16% 43% 16%;
  grid-template-areas: "a" "b" "c" "d";
  width: 95%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  border: 1px solid rgb(223, 212, 212);
}

.fakturaAreaA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: a;
  width: 100%;
  height: 16vh;
  background-color: white;
}

.fakturaHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fakturaHeaderName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Lobster,cursive";
  font-size: 70px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 3px;
  width: 55%;
  height: 7vh;
  color: gold;
  padding-left: 2%;
}

.fakturaHeaderType, .fakturaHeaderTypeJAP {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 50px;
  margin-top: 1vh;
  font-style: italic;
  margin-bottom: 3px;
  width: 45%;
  color: grey;
}

.fakturaHeaderTypeJAP {
  font-size: 36px;
}

.fakturaHeaderBroughtToYou {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 10px;
  width: 55%;
}

.fakturaHeaderInvoiceDue {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 45px;
  padding-left: 10%;
}

.fakturaAreaB {
  grid-area: b;
  width: 100%;
  height: 17vh;
  background-color: white;
}

.fakturaHeaderLine {
  width: 90%;
  height: 2px;
  background-color: grey;
}

.fakturacompanyInfo {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
}

.fakturacustomerInfo {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fakturainfo {
  width: 100%;
}

.fakturaInfoTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fakturainfoWrapper {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fakturaOwnerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: var(--font-family);
  font-size: 16px;
}

.fakturaCustomerid {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: var(--font-family);
  font-size: 16px;
}

.fakturacustomerDet {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: var(--font-family);
  font-size: 16px;
}

.fakturaAreaC {
  grid-area: c;
  width: 100%;
  height: 50vh;
  background-color: white;
}

.fakturaAreaCWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.fakturaAreaCWrapper1 {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.fakturaAreaCTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fakturaAreaCHeaderService {
  width: 10%;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.fakturaAreaCHeaderNamn {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.fakturaAreaCHeaderAntal {
  width: calc(8% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fakturaAreaCHeaderMaxAntal {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  white-space: nowrap;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fakturaAreaCHeaderFakturerat {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fakturaAreaCHeaderTotalt {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fakturaAreaCHeaderKredit {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fakturaAreaList {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fakturaAreaListRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fakturaAreaListService {
  width: 10%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
  margin-top: 3px;
}

.fakturaAreaListNamn {
  width: calc(40% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid rgb(223, 212, 212);
  margin-top: 2px;
}

.fakturaAreaListAntal {
  width: calc(8% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
  margin-top: 3px;
}

.fakturaAreaListMaxAntal {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
  margin-top: 3px;
}

.fakturaAreaListFakturerat {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
  margin-top: 3px;
}

.fakturaAreaListTotalt {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
  margin-top: 3px;
}

.fakturaAreaListKredit {
  width: calc(12% - 2px);
  margin-left: 2px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(223, 212, 212);
  margin-top: 3px;
}

.fakturaAreaD {
  grid-area: d;
  width: 100%;
  height: 21vh;
  background-color: white;
}

.fakturaFooterTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fakturaFooterWrapper {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.fakturaFooterHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.fakturaFooterHeadertext {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
}

.fakturaFooterHeadertextMid {
  width: 32%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 7%;
}

.fakturaFooterHeadertextMid1 {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
}

.fakturaFooterHeadertextIban {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 7%;
}

.fakturaPayWrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.fakturaPayNetSum {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-family);
  font-size: 18px;
  margin-top: 5px;
  color: black;
}

.fakturaPayVAT {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-family);
  font-size: 18px;
  margin-top: 5px;
  white-space: nowrap;
  color: black;
}

.fakturaPayTotal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 26px;
  margin-top: 5px;
  color: grey;
}

.fakturaPayTotal1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 30px;
  margin-top: 5px;
}

.fakturaPayTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.abfakturaPrintButton {
  width: 150px;
  height: 25px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 3px;
  margin-left: 3px;
  color: black;
}

.tjansterDebTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tjansterDebWrapper {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebHeaderText {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 1vh;
    margin-bottom: 3px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebHeaderText {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 1vh;
    margin-bottom: 3px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebHeaderText {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 1vh;
    margin-bottom: 3px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebHeaderText {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 1vh;
    margin-bottom: 3px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebHeaderText {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 1vh;
    margin-bottom: 3px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebListWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebListWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebListWrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebListWrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebListWrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.tjansterDebRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebRow {
    margin-top: 1px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebRow {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebRow {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebRow {
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebRow {
    margin-top: 1px;
  }
}

.tjansterDebService {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebService {
    width: calc(8% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebService {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebService {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebService {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebService {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebService1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebService1 {
    width: calc(8% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebService1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebService1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebService1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebService1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebBenaemn {
  margin-bottom: 2px;
  margin-left: 10px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebBenaemn {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebBenaemn {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: left;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebBenaemn {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: left;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebBenaemn {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: lef;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebBenaemn {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: left;
  }
}

.tjansterDebBenaemn1 {
  margin-bottom: 3px;
  justify-content: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebBenaemn1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebBenaemn1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: cente1;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebBenaemn1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebBenaemn1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: center;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebBenaemn1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2;
    text-align: center;
  }
}

.tjansterDebQty {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebQty {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebQty {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebQty {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebQty {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebQty {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebQty1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebQty1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebQty1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebQty1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebQty1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebQty1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebMax {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebMax {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebMax {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebMax {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebMax {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebMax {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebMax1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebMax1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebMax1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebMax1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebMax1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebMax1 {
    width: calc(12% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebActive {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebActive {
    width: calc(22% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 2;
    align-self: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebActive {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebActive {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebActive {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebActive {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebActive1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebActive1 {
    width: calc(22% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebActive1 {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebActive1 {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebActive1 {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebActive1 {
    width: calc(15% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebUsable {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebUsable {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebUsable {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebUsable {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebUsable {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebUsable {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.tjansterDebUsable1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterDebUsable1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterDebUsable1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterDebUsable1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterDebUsable1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterDebUsable1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 3px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.supportHeaderTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.supportHeaderWrapper {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.supportTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 20px;
}

.supportMessageWrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.supportMessageSelect {
  width: 50%;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  height: 25px;
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 5px;
  outline: none;
  background-color: white;
}

.supportMessageHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 1vh;
  font-style: italic;
  margin-bottom: 3px;
  color: grey;
}

.supportMessageInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.supportMessageTextArea {
  width: 80%;
  height: 140px;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 5px;
  outline: none;
  resize: none;
}

.supportMessageButtonWrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.supportMessageButton {
  width: 50%;
  height: 25px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 3px;
  color: black;
}
.supportMessageButton:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}
.supportMessageButton:active {
  background-color: var(--getColorVal);
}

.supportMessageButton1 {
  width: 90%;
  height: 25px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 3px;
  color: black;
  opacity: 0.7;
  cursor: not-allowed;
}
.supportMessageButton1:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}
.supportMessageButton1:active {
  background-color: var(--rgbaVal);
}

.supportHistoryTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.supportHistoryHeaderWrapper {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.supportHistoryHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 20px;
}

.supportHistoryListWrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.supportHistoryList {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.supportHistoryServiceId {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .supportHistoryServiceId {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportHistoryServiceId {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .supportHistoryServiceId {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .supportHistoryServiceId {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .supportHistoryServiceId {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.supportHistoryServiceId1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .supportHistoryServiceId1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportHistoryServiceId1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .supportHistoryServiceId1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .supportHistoryServiceId1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .supportHistoryServiceId1 {
    width: calc(10% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.supportHistoryCreateDate {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .supportHistoryCreateDate {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .supportHistoryCreateDate:hover {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportHistoryCreateDate {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .supportHistoryCreateDate:hover {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .supportHistoryCreateDate {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .supportHistoryCreateDate:hover {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .supportHistoryCreateDate {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .supportHistoryCreateDate:hover {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .supportHistoryCreateDate {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .supportHistoryCreateDate:hover {
    background-color: rgb(229, 64, 68);
    color: white;
  }
}

.supportHistoryCreateDate1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .supportHistoryCreateDate1 {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportHistoryCreateDate1 {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .supportHistoryCreateDate1 {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .supportHistoryCreateDate1 {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .supportHistoryCreateDate1 {
    width: calc(40% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.supportHistoryStatus {
  margin-bottom: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .supportHistoryStatus {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportHistoryStatus {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .supportHistoryStatus {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .supportHistoryStatus {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .supportHistoryStatus {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.supportHistoryStatus1 {
  margin-bottom: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .supportHistoryStatus1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 20px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportHistoryStatus1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .supportHistoryStatus1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .supportHistoryStatus1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}
@media only screen and (min-width:2303px) {
  .supportHistoryStatus1 {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: 25px;
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    padding: 1px;
    font-family: var(--font-family);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.supportShowMessageTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.supportShowMessageWrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.supportShowMessageHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 2vh;
}

.supportShowMessageTextArea {
  width: 80%;
  height: 140px;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 5px;
  outline: none;
  resize: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportShowMessageTextArea {
    width: 90%;
    height: 25vh;
  }
}

.ppBakesoftTextWrapperInside {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 5px;
  border: 2px dotted grey;
  width: 99%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ppBakesoftButtonWrapperInside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ppBakesoftButtonWrapperInside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar {
    width: 5px;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ppBakesoftButtonWrapperInside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar {
    width: 5px;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ppBakesoftButtonWrapperInside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar {
    width: 5px;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
}
@media only screen and (min-width:2303px) {
  .ppBakesoftButtonWrapperInside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar {
    width: 5px;
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
  }
  .ppBakesoftButtonWrapperInside::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
}

.payPalButtonWrapperInside {
  width: 100%;
  margin-top: 5px;
}

.myAccountContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.myaccountHamburgerButton {
  position: absolute;
  top: 5px;
  right: 25px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Ensure the button is above other content */
}

.myaccountHamburgerButton1 {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Ensure the button is above other content */
}

.myaccountSlidingMenu {
  position: fixed;
  top: 0;
  right: -300px; /* Adjust width as needed to ensure it is completely off-screen */
  width: 250px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999; /* Ensure the menu is above other content */
  border-radius: 3px;
}

.myaccountSlidingMenu.open {
  right: 0;
}

.amenupersonal, .amenupersonal1 {
  margin: 10px 0;
  padding: 3px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.amenupersonal a, .amenupersonal1 a {
  color: inherit; /* Ensure links inherit the button's color */
  text-decoration: none; /* Remove underline from links */
}
.amenupersonal:active, .amenupersonal1:active {
  background-color: #ddd; /* Change background color when clicked */
  color: #000; /* Change text color when clicked */
}

.amenupersonal1 {
  font-weight: bold;
}

.myAccountButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.supportShowMessageButton {
  width: 80%;
  height: 25px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 3px;
  color: black;
}
.supportShowMessageButton:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}
.supportShowMessageButton:active {
  background-color: var(--getColorVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .supportShowMessageButton {
    width: 90%;
    height: 30px;
  }
}

.supportShowMessageButton1 {
  width: 50%;
  height: 25px;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 3px;
  color: black;
  opacity: 0.7;
  cursor: not-allowed;
}
.supportShowMessageButton1:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}
.supportShowMessageButton1:active {
  background-color: var(--rgbaVal);
}

.generalPaymentTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.generalPaymentHeaderWrapper {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.generalPaymentHeaderText {
  font-family: var(--font-family);
  font-size: 18px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .generalPaymentHeaderText {
    font-family: var(--font-family);
    font-size: 14px;
  }
}

.generalPaymentBoxTopWrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 1vh;
}

.generalPaymentBoxTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.generalPaymentSelect {
  width: 50%;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  height: 25px;
  font-family: var(--font-family);
  font-size: 14px;
  margin-bottom: 3px;
  margin-top: 5px;
  outline: none;
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .generalPaymentSelect {
    width: 95%;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    height: 25px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-bottom: 3px;
    margin-top: 5px;
    outline: none;
    background-color: white;
  }
}

.generalPaymentInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.generalPaymentInfoBoxWrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border: 2px dotted grey;
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .generalPaymentInfoBoxWrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border: 2px dotted grey;
    background-color: white;
  }
}

.generalPaymentInfoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.generalPaymentInfoText {
  font-family: var(--font-family);
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 3px;
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .generalPaymentInfoText {
    font-family: var(--font-family);
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 3px;
    color: black;
  }
}

.generalPaymentFormTopWrapper {
  width: 70%;
  background-color: white;
  height: auto;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.generalPaymentFormTopWrapper::-webkit-scrollbar {
  width: 5px;
}
.generalPaymentFormTopWrapper::-webkit-scrollbar-thumb {
  background: var(--rgbaVal);
}
.generalPaymentFormTopWrapper::-webkit-scrollbar-track {
  background: var(--rgbaVal2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .generalPaymentFormTopWrapper {
    width: 95%;
    background-color: white;
    height: 40vh;
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .generalPaymentFormTopWrapper::-webkit-scrollbar {
    width: 5px;
  }
  .generalPaymentFormTopWrapper::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
  }
  .generalPaymentFormTopWrapper::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
}

.completePageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infocompleteWrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 20px;
  font-family: var(--font-family);
}

.completePageIcon {
  width: 30px;
  height: 30px;
  color: green;
  margin-bottom: 1vh;
}

.failPageIcon {
  width: 30px;
  height: 30px;
  color: red;
  margin-bottom: 1vh;
}

.cbcompletePageWrapper {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cbinfocompleteWrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 16px;
  font-family: var(--font-family);
}

.invoiceHistoryListIcon {
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;
}

.invoiceHistoryListIconPending {
  width: 20px;
  height: 20px;
  color: red;
}

.receipt-embed-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.xjLiveUserImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.xjLive-tabs-container {
  width: 100%;
  max-width: 100%;
  height: 85vh;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.xjLive-tabs-header {
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 95%;
}

.xjLive-tab {
  padding: 4px 10%;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  color: black;
  transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
  border: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: unset;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLive-tab {
    padding: 4px 10px;
  }
}

.xjLive-tab:hover {
  color: black;
}

.xjLive-tab.xjLive-active {
  color: black;
  font-weight: bold;
  border-bottom: 2px solid black;
  font-family: var(--font-family);
  font-size: 16px;
}

.xjLive-tabs-content {
  padding: 20px;
  border: 1px solid white;
  border-top: 1px solid black;
  background-color: #f9f9f9;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLive-tabs-content {
    padding: 2px;
  }
}/*# sourceMappingURL=MyAccount.css.map */