@import '../style/MainStyles.scss';



.afakturaPrintButtonWrapper{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
    margin-top:5px;
    margin-bottom:5px;
}
.afakturaPrintButton{
    width:100px;
    height:30px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-aafamily);
    font-size: 12px;
    color: map-get($generalStyles,pinkRed);
    border-color: map-get($generalStyles,datalistBorderColor);
    margin-right:5px;
    outline:none;
    @media print {
        display: none;
      }
    }


.afakturaTop{
    width:100%;
  
     }       
    .afakturaWrapper{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    
    }
    .afakturaSelect{
        width:50%;
        border-color:map-get($generalStyles,datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        height:25px;
        font-family: var(--font-aafamily);
        font-size: 12px;
        margin-bottom: 3px;
        margin-top:5px;
        outline:none;
        @media print {
            display: none;
          }
    }
    
    .afakturaArea{
    width:100%;
    height:90vh;
    // max-height:106vh;
    // overflow-y:scroll;
    // // webkit scrollbar
    // &::-webkit-scrollbar {
    //     width: 10px;
    
    // }
    // &::-webkit-scrollbar-track {
    //     background: map-get($generalStyles,transparentChangeableMenu4);
    // }
    // &::-webkit-scrollbar-thumb {
    //     background: white;
    //     border-radius: 10px;
    //     border:1px solid;
    //     border-color: map-get($generalStyles,transparentChangeableMenu5);
    // }
    }
    
    .afakturaAreaWrapper{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    
    }
    .afakturaAreaGrid{
        @include gadgets-to(mobile){
            display: grid;
            grid-template-rows:15% 16% 43% 16%;
            grid-template-areas:
                "a"
                "b"
                "c"
                "d"
                ;
            width: 95%;
            height:auto;
            object-fit: cover;
            // grid-template-rows:auto auto auto auto;
            border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(tablet){
        display: grid;
        grid-template-rows:15% 16% 43% 16%;
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            ;
        width: 95%;
        height:auto;
        object-fit: cover;
        // grid-template-rows:auto auto auto auto;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        


        }
        @include gadgets-to(laptop){
            display: grid;
            grid-template-rows:15% 16% 43% 16%;
            grid-template-areas:
                "a"
                "b"
                "c"
                "d"
                ;
            width: 75%;
            height:auto;
            object-fit: cover;
            // grid-template-rows:auto auto auto auto;
            border:1px solid map-get($generalStyles,datalistBorderColor);
            @media print{
                width:95%;
            }
        }
        @include gadgets-to(desktop){
            display: grid;
            grid-template-rows:15% 16% 43% 16%;
            grid-template-areas:
                "a"
                "b"
                "c"
                "d"
                ;
            width: 45%;
            height:auto;
            object-fit: cover;
            // grid-template-rows:auto auto auto auto;
            border:1px solid map-get($generalStyles,datalistBorderColor);
            @media print{
              //  zoom:110%;
            }
        }
        @include gadgets-to(largeDesktop){
            display: grid;
            grid-template-rows:15% 16% 43% 16%;
            grid-template-areas:
                "a"
                "b"
                "c"
                "d"
                ;
            width: 95%;
            height:auto;
            object-fit: cover;
            // grid-template-rows:auto auto auto auto;
            border:1px solid map-get($generalStyles,datalistBorderColor);
    
        }
    }

    
    .afakturaAreaA{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    
        grid-area:a;
        width:100%;
        height:16vh;
        background-color:white;
        //border:1px solid grey;
    
    }
    .afakturaHeaderWrapper{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        width:100%;
    }
    
    .afakturaHeaderName{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
    
        font-family:'Lobster,cursive';
        font-size: 70px;
        font-style: italic;
        font-weight: bold;
        margin-bottom:3px;
        width:50%;
        height:7vh;
        color:gold;
       // padding-left:2%;
    
    }
    .afakturaHeaderType{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        font-family: var(--font-family);
        font-size: 60px;
        margin-top:1vh;
        font-style: italic;
        margin-bottom:3px;
        width:55%;
        color:grey;
        @media print{
            margin-left:10px;
        }
       
    }
    .afakturaHeaderType{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        font-family: var(--font-family);
        font-size: 60px;
        margin-top:1vh;
        font-style: italic;
        margin-bottom:3px;
        width:55%;
        color:grey;
        @media print{
            margin-left:10px;
        }
       
    }
    .afakturaHeaderTypeJAP{
  @extend .afakturaHeaderType;
    font-size: 46px;
       
    }
    .afakturaHeaderBroughtToYou{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        font-family: var(--font-family);
        font-size: 10px;
        width:55%;
    
    }
    .afakturaHeaderInvoiceDue{
        width:45%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        font-family: var(--font-family);
        font-size: 45px;
        padding-left:10%;  
    }
    
    .afakturaAreaB{
        grid-area:b;
        width:100%;
        height:14vh;
        background-color:white;
      //  border:1px solid grey;
    }
    .afakturaHeaderLine{
        width:90%;
        height:2px;
        background-color:grey;
      //  margin-top:1vh;
    }
    .afakturacompanyInfo{
        width:60%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        margin-top:1vh;
      
    
    }
    .afakturacustomerInfo{
        width:40%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
    
    .afakturainfo{
        width:100%;
    
    }
    .afakturaInfoTop{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
      
    }
    .afakturainfoWrapper{
        width:80%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
    
    
    
    }
    .afakturaOwnerInfo{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
       // align-items:center;
       font-family: var(--font-family);
        font-size: 16px;
    
     
    
    }
    .afakturaCustomerid{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        //align-items:center;
        font-family:var(--font-family);
        font-size: 16px;
    }
    .afakturacustomerDet{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
       // align-items:center;
        font-family:var(--font-family);
        font-size: 16px;
    }
    
    .afakturaAreaC{
        grid-area:c;
        width:100%;
        height:46vh;
        background-color:white;
       // border:1px solid grey;
    }
    .afakturaAreaCWrapper{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        margin-top:3px;
    }
    .afakturaAreaCWrapper1{
        width:95%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        margin-top:3px;
    }
    .afakturaAreaCTop{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    
    }
    .afakturaAreaCHeaderService{
        width:10%;  
        height:20px;
        //margin-left:12px;
      //  border:1px solid grey;
        font-family:var(--font-family);
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content:center;
        align-items:center;
        white-space: nowrap;
    }
    .afakturaAreaCHeaderNamn{
        width:calc(40% - 2px);
        margin-left:2px;
        height:20px;
      //  border:1px solid grey;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content:center;
        align-items:center;
        white-space: nowrap;
    
    }
    .afakturaAreaCHeaderAntal{
        width:calc(8% - 2px);
        margin-left:2px;
        height:20px;
       // border:1px solid grey;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    .afakturaAreaCHeaderMaxAntal{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
        //border:1px solid grey;
        white-space: nowrap;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    .afakturaAreaCHeaderafakturerat{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
       // border:1px solid grey;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    .afakturaAreaCHeaderTotalt{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
      //  border:1px solid grey;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    .afakturaAreaCHeaderKredit{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
       // border:1px solid grey;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    
    
    .afakturaAreaList{
        width:95%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
    .afakturaAreaListRow{
    
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
     
    }
    
    // list
    .afakturaAreaListService{
        width:10%;
        height:20px;
        display:flex;
        justify-content:center;
        align-items:center;
      //  border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
      // border:1px solid grey;
       margin-top:3px;
    }
    .afakturaAreaListNamn{
        width:calc(40% - 2px);
        margin-left:2px;
        height:20px;
        display:flex;
        justify-content:flex-start;
        text-align:left;
        white-space: nowrap;
     //   border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
       // border:1px solid grey;
        margin-top:2px;
    }
    .afakturaAreaListAntal{
        width:calc(8% - 2px);
        margin-left:2px;
        height:20px;
        display:flex;
        justify-content:center;
        align-items:center;
       // border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
       // border:1px solid grey;
        margin-top:3px;
    }
    .afakturaAreaListMaxAntal{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
        display:flex;
        justify-content:center;
        align-items:center;
      //  border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
      //  border:1px solid grey;
        margin-top:3px;
    }
    .afakturaAreaListafakturerat{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
        display:flex;
        justify-content:center;
        align-items:center;
    
     //   border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
       // border:1px solid grey;
        margin-top:3px;
    }
    .afakturaAreaListTotalt{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
        display:flex;
        justify-content:center;
        align-items:center;
      //  border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
        //border:1px solid grey;
        margin-top:3px;
      
    }
    .afakturaAreaListKredit{
        width:calc(12% - 2px);
        margin-left:2px;
        height:20px;
        display:flex;
        justify-content:center;
        align-items:center;
     //   border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
       // border:1px solid grey;
        margin-top:3px;
    }
    
    .afakturaAreaD{
        grid-area:d;
        width:100%;
        height:17vh;
        background-color: white;
       // border:1px solid grey;
    }
    .afakturaFooterTop{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        white-space: nowrap;
    }
    .afakturaFooterWrapper{
        width:95%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        margin-top:5px;
    }
    .afakturaFooterHeader{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:space-around;
        align-items:center;
    }
    .afakturaFooterHeadertext{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        font-family: var(--font-family);    
        font-size: 12px;
    }
    .afakturaPayWrapper{
        width:90%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        margin-top:5px;
    
    
    }
    .afakturaPayNetSum{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
        font-family: var(--font-family); 
        // font-family:lobster;
        font-size:18px;
        margin-top:5px;
        color:black;
    }
    .afakturaPayVAT{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
        font-family: var(--font-family);    
        font-size: 18px;
        margin-top:5px;
        white-space: nowrap;
        color:black;
    }
    .afakturaPayTotal{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;
        font-family: var(--font-family);    
        font-size: 26px;
        margin-top:5px;
        color:grey;
    }
    .afakturaPayTotal1{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;
        font-family: var(--font-family);    
        font-size: 30px;
        margin-top:5px;
    }
    .afakturaPayTop{
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    
    }
    
    
    
    