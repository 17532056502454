@import '../style/MainStyles.scss';



.bsaTotWrapper{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:center;
    // align-items:center;
     height:95vh;
}

}

.bsatotalArea{
    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows: 8% 8% 9% 81%;
      //  grid-template-rows:7% 83%;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d";
        width: 100%;
        height:71vh;
        object-fit: cover;


        @media(max-width:360px) {
            grid-template-rows: 8% 8% 10% 74%;

        }
        @media(min-width:375px) and (max-width:375px) 
        and (max-height:750px)
        {
            grid-template-rows: 8% 8% 10% 74%;

        }

   
  

}

}


.bsatotalArea1 {
    @extend .bsatotalArea;
        background-color: map-get($generalStyles, backgroundColor)
}

.bsaheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }


}

.bsarangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:4vh;
    }

}


.bsabackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }

  .bsarange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }

}

.bsarange2 {
    @extend .bsarange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }

}

.bsacompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color:white;
    }
 
}

.bsacompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }

}

.bsacompNameJAP{
    @extend .bsacompName;
    font-family:var(--font-family);
}
.bsacompNameJAP1{
    @extend .bsacompName1;
    font-family:var(--font-family);
}
.bsaTopWrapper2{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.bsaareaA{
    grid-area:c;
    width:100%;
    //height:6vh;
    background-color:map-get($generalStyles,transparentChangeable);
}
.bsaareaB{
    @include gadgets-to(mobile) {
    grid-area:d;
    background-color: map-get($generalStyles, transparentChangeable);
    height:100vh;
    max-height:90vh;
    overflow-y:scroll;
    overflow-x:hidden;
    
    // small webkit scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
    &::-webkit-scrollbar-thumb:hover {
        background: map-get($generalStyles,transparentChangeableMenu5);
    }

}

}



.bsacategoriSelectWrapper{
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
   
    }


}
.bsacategorySelect{
    @include gadgets-to(mobile) {

    width:75%;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:30px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-bottom: 3px; 
    margin-top:5px;
    outline:none;
    background-color:white;
    color:black;

}

}

.bsaimageArea{
    @include gadgets-to(mobile) {
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
       // align-items: center;
        height:auto;
   

    }

}
.bsascrollImages{
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:100%;

        height:100%;
        border-radius:6px;
    //     overflow-x: auto;
    //     overflow-y: hidden;
    //     white-space: nowrap;
    //    border:2px red;
      z-index:2;
    }

}
.bsaimageContainer{
    @include gadgets-to(mobile) {
    display: grid;
    width:100%;
    height:auto;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom:3px;
}

}

.bsaimageContainer1{
    @extend .bsaimageContainer;
}
.bsaimageOverlay{
    @include gadgets-to(mobile) {
width: 100%;
height: 100%;
//background-color: map-get($generalStyles, transparentChangeableMenu5);
border-radius: 10px;
position: relative;
z-index: 1;
}

}



.textOverlay {

    @include gadgets-to(mobile) {

    position: relative;
    z-index: 2;
    font-family:Lobster;
    font-size: 50px;
    text-align:center;
    color:white;   
    cursor:pointer;
}

}

.textOverlayJap{
    @extend .textOverlay;
    font-family:var(--font-family);
}
.textOverlay.active {
    color:map-get($generalStyles, transparentChangeableMenu5);
}

.bsaimageOverlay, .textOverlay, .buttonContainer {
    grid-area: image;
    

}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:3;
    margin-top:1vh;
    opacity: 0;
  // transition: opacity 4s ease-in-out;
}
.buttonContainer.visible {
    animation: fadeIn 1.5s ease-in-out forwards;
}

.bsagetinfoButton {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: white;
    border: none;
    border-radius: 5px;
    font-family:var(--font-family);
    font-size: 12px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom:3vh;
    border-radius: 50%;
    outline:none;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.infocontainer{
    .infocontainer {
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
    
    .infocontainer.open {
        animation: openInfo 1s forwards;
        visibility: visible;
        display:flex;
 
    }
    
    .infocontainer.closed {
        animation: closeInfo 1s forwards;
    }
}
.infocontainer.open {
    @include gadgets-to(mobile) {

    animation: openInfo 1s forwards;
    visibility: visible;
    width:100%;

}

}

.infocontainer.closed {
    animation: closeInfo 1s forwards;
    width:100%;
}


@keyframes openInfo {
    0% { height: 0; }
    100% { height: 230px; }
}
@keyframes closeInfo {
    0% { height:230px; }
    100% { height: 0; }
}

@mixin recipeBoxStyles($width) {
    width:$width;
    display: flex;
    flex-direction: column;
   // justify-content: center;
    align-items: center;
    height:25vh;
    max-height: 25vh;
    overflow-y: scroll;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
        border: 1px solid;
        border-color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.bsarecipeBox {
    @include gadgets-to(mobile) {
        @include recipeBoxStyles(100%)
        // Override any mobile-specific styles here
    }

}


.bsarecipeBoxHeader{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family:Lobster;
    font-size: 20px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    margin-top:10px;

}

}

.bsareceipeBoxHeaderJAP{
    @extend .bsarecipeBoxHeader;
    font-family:var(--font-family);
}


.bsareceipeBoxContent{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.bsaRecipeList{
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
}
.bsaRecipeList{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
.bsaRecipeListHeader{
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family:var(--font-family);
    font-size: 16px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    margin-top:2px;
}
.bsaReceipeListItem{
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family:var(--font-family);
    font-size: 16px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    margin-top:2px;
}
.bsaitemBenaemn{
    width:40%;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    padding-left:5px;
    font-family: var(--font-family);
    font-size: 12px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bsaitemBenaemn1{
    width:40%;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    padding-left:5px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bsaitemMangd{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaitemMangd1{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaitemAdsum{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaitemAdsum1{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaitemCalories{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaitemCalories1{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaitemArborder{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaitemArborder1{
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color:black;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius: map-get($generalStyles,threeBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
}
.bsaDescriptionBox{
    @include gadgets-to(mobile) {

    position:relative;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
}

}

.bsaDescriptionBoxHeader{
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family:Lobster;
    font-size: 20px;
    font-weight: bold;
    color:map-get($generalStyles,transparentChangeableMenu5);
    margin-top:2px;
}
.bsaDescriptionBoxHeaderJAP{
    @extend .bsaDescriptionBoxHeader;
    font-family:var(--font-family);
}
.bsaDescriptionBoxText{
    @include gadgets-to(mobile) {
    width:80%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family:var(--font-family);
    font-size: 16px;
    color:black;
    margin-top:2px;
    height:150px;
    border:none;
    resize:none;
    outline:none;
    background-color:transparent;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}

}

.spinner {
  position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust to perfectly center regardless of its size */
    /* Add more styling for your spinner here */
    z-index:5;
  }


.bsasearchSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.bsasearchSpinner {
    color: map-get($generalStyles, colorblack);
}

