//border color
$primary-border-color:black;
$secondary-border-color: rgb(248, 245, 245);
$third-border-color: rgb(219, 207, 207);
//border size
$primary-border-size-type:solid 1px;
//header color
//$primary-header-color:rgb(92, 89, 89);
$primary-header-color:purple;
//font
$primary-font-family:Arial, Helvetica, sans-serif;
$primary-font-color:white;
$secondary-font-color:black;
$primary-font-size:30px;
$secondary-font-size:18px;
$third-font-size:14px;
$forth-font-size:12px;

//background
$primary-background-color:rgb(236, 234, 229);
$secondary-background-color:rgb(84, 48, 0);
$primarygradient-background:linear-gradient(to bottom, $primary-background-color 40%,white 100%);
$secondarygradient-background:linear-gradient(to bottom, rgb(110, 105, 105) 1%, rgb(84, 48, 0) 100%);
//list-background
$primary-list-background-color:rgb(236, 234, 229);

// @media  min width
$min-width_ipad:1000px;
$min-width_laptop:1500px;

//buttons
$primary-button-color:rgb(242, 237, 237);
$primary-button-border-color:rgb(224, 223, 223);

@mixin trial{
    background-color: red;
}

@mixin Chocolateselect{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    width: 98%;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left:3px;
   // color:white;
    background-color:white;
    border-radius: 0;
}

//$chocolateBorder:rgb(255, 128, 0);
//$chocolateBorder:darkblue;
$chocolateBorder:rgb(255, 128, 0);
$chocolateBorder1:rgb(255, 128, 0);

$chocolateBorderSize:2px;
$chocolateBrownBorderLight:rgb(102, 62, 9);
$chocolateTooltipBorder-color:rgb(255, 128, 0);
$chocolateBackground-color:rgb(84, 48, 0);
//$chocolateBackground-color:yellow;
//$chocolateBackground-color:linear-gradient(to top, $primary-background-color 20%,white 100%);
//$chocolateBackground-color:linear-gradient(to bottom, $primary-background-color 40%,white 100%);



$chocolateTooltipBackground-color:rgb(109, 62, 1);
$chocolatelegend-text-color:rgb(255, 128, 0);
$chocolateheader-button-background-color:rgb(151, 76, 0);
$chocolateList-text-color:black;
$chocolateListheight:24px;
$chocolateListheigh-small:20px;
$chocolateButtonHover-color:rgb(255, 128, 0);
$chocolateButtonClicked-color:rgb(75, 231, 75);
$chocolateListColor:rgb(241, 185, 129);
$chocolateWhite:white;

$datalistBorderColor:rgb(223, 212, 212);
$pinkDarkColor:rgb(224, 166, 193);
$pinkLightColor:rgb(249, 230, 244);
$pinkColor:rgb(241, 135, 157);
$pinkRed:rgb(229, 64, 68);
$pinkerPink:#c12661;

//application width and height
$bakesoft-android-reader-width:1260px;
$bakesoft-android-reader-height:571px;
$bakesoft-android-reader-inner-height:558px;


$preffered-ipad-height:84.3vh;
$preffered-ipad-width:151.5vh;
// $preffered-ipad-height:90vh;
// $preffered-ipad-width:100%;


$preffered-ipad-component-outline-border-bottom-left-radius:20px;
$preffered-ipad-component-outline-border-bottom-right-radius:20px;
$preffered-ipad-component-outline-fieldset-height:83.8vh;
$preffered-ipad-component-outline-legend-font-size:22px;
$preffered-ipad-input-field-height:30px;
$preffered-ipad-input-field-border-radius:2px;




