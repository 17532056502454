@import '../style/MainStyles.scss';/* styles.css */


.license-agreement {
    min-height:60vh;
    background-color: #f3f4f6;
    display: flex;
    flex-direction: column;
    
    &__header {
      background-color: #ffffff;
      padding: 1.5rem;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  
    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      color: #111827;
      margin-bottom: 1rem;
    }
  
    &__language-select {
      width: 100%;
      max-width: 300px;
      padding: 0.5rem;
      font-size: 1rem;
      border: 1px solid #d1d5db;
      border-radius: 0.375rem;
      background-color: #ffffff;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 0.5rem center;
      background-size: 1.5em 1.5em;
  
      &:focus {
        outline: none;
        border-color: #3b82f6;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
      }
    }
  
    &__content {
      flex-grow: 1;
      padding: 2rem;
      display: flex;
      justify-content: center;
      @include gadgets-to(mobile) {
        padding: 1rem;
      }
    }
  
    &__agreement {
      background-color: #ffffff;
      padding: 2rem;
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      width: 100%;
      max-width: 800px;
      height: 50vh;
      overflow-y: auto;
    }
  
    &__footer {
      background-color: #ffffff;
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  
    &__back-button {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      font-weight: 500;
      color: #ffffff;
      background-color: #3b82f6;
      border-radius: 0.375rem;
      transition: all 0.2s;
      text-decoration: none;
  
      &:hover {
        background-color: #2563eb;
      }
  
      &::before {
        content: '';
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 0.5rem;
        border-top: 2px solid currentColor;
        border-left: 2px solid currentColor;
        transform: rotate(-45deg);
      }
    }
  
    &__footer-text {
      font-size: 0.875rem;
      color: #6b7280;
    }
  }
  
  
  