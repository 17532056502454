:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xytotWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xytotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xytotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xytotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xywindowArea {
    display: grid;
    grid-template-rows: 5% 19% 50% 10%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xywindowArea {
    display: grid;
    grid-template-columns: 35% 37% 28%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xywindowArea {
    display: grid;
    grid-template-columns: 35% 37% 28%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xywindowArea {
    display: grid;
    grid-template-columns: 35% 37% 28%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xywindowArea {
    display: grid;
    grid-template-columns: 35% 37% 28%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xyareaA {
    grid-area: b;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 26vh;
    background-color: white;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xyareaA {
    grid-area: b;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xyareaA {
    grid-area: b;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xyareaA {
    grid-area: b;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xyareaA {
    grid-area: b;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}

.xyareaB {
  grid-area: c;
  border-color: rgb(223, 212, 212);
  width: 99.5%;
  height: 80vh;
  background-color: white;
  margin-top: 5px;
  border-radius: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 650px) {
  .xyareaB {
    height: 100vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) and (max-height: 670px) {
  .xyareaB {
    height: 105vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) and (min-height: 671px) and (max-height: 920px) {
  .xyareaB {
    height: 74vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 390px) and (min-height: 671px) and (max-height: 920px) {
  .xyareaB {
    height: 68vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 427px) and (max-height: 650px) {
  .xyareaB {
    height: 95vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 427px) and (min-height: 651px) and (max-height: 730px) {
  .xyareaB {
    height: 72vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 427px) and (min-height: 731px) and (max-height: 800px) {
  .xyareaB {
    height: 55vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 427px) and (min-height: 801px) and (max-height: 930px) {
  .xyareaB {
    height: 56vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) and (min-height: 731px) and (max-height: 800px) {
  .xyareaB {
    height: 53vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) and (min-height: 801px) {
  .xyareaB {
    height: 60vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xyareaB {
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xyareaB {
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xyareaB {
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xyareaB {
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xyareaC {
    grid-area: d;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 30vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xyareaC {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xyareaC {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xyareaC {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xyareaC {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99.5%;
    height: 80vh;
    background-color: white;
    margin-top: 5px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xyheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 99%;
    margin-top: 2px;
    height: 35px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xyheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 40px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xyheaderArea {
    height: 45px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xyheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xyheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 60px;
  }
}
@media only screen and (min-width:2303px) {
  .xyheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 60px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xycompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xycompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xycompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xycompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xycompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xycompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xycompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 32px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xycompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xycompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xycompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

.xypageHeaderMenyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xypageHeaderMenu {
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
  margin-top: 10px;
  cursor: pointer;
  width: 73%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xypageHeaderMenu {
    width: 87%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xypageHeaderMenu {
    width: 89%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xypageHeaderMenu {
    width: 87%;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xypageHeaderMenu {
    width: 87%;
    font-size: 18px;
  }
}

.xyareaTopWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xyareaTopWrapper {
    max-height: 80vh;
  }
}

.xyareaTop {
  max-height: 20vh;
  overflow-y: scroll;
  width: 75%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xyareaTop {
    width: 90%;
    height: auto;
    max-height: 80vh;
    overflow: scroll;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1120px) {
  .xyareaTop {
    height: 72vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1194px) and (max-width: 1200px) {
  .xyareaTop {
    height: 70vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1360px) {
  .xyareaTop {
    height: 68vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xyareaTop {
    width: 90%;
    height: auto;
    max-height: 80vh;
    overflow: scroll;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xyareaTop {
    width: 90%;
    height: auto;
    max-height: 75vh;
    overflow: scroll;
  }
}
@media only screen and (min-width:2303px) {
  .xyareaTop {
    width: 90%;
    height: auto;
    max-height: 80vh;
    overflow: scroll;
  }
}

.xyareaTopData {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.xypageId, .xypageId1 {
  width: 30%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  font-size: 14px;
  font-family: var(--font-family);
  text-align: center;
  color: black;
  background-color: var(--rgbaVal);
  margin-top: 5px;
  padding: 5px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xypageId, .xypageId1 {
    width: 40%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xypageId, .xypageId1 {
    width: 40%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xypageId, .xypageId1 {
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .xypageId, .xypageId1 {
    height: 45px;
    font-size: 20px;
  }
}

.xypageId1 {
  background-color: var(--rgbaVal2);
}

.xypageBenaemn, .xypageBenaemn2, .xypageBenaemn1 {
  width: calc(70% - 2px);
  height: 25px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-family: var(--font-family);
  text-align: center;
  color: black;
  background-color: var(--rgbaVal);
  margin-left: 2px;
  margin-top: 5px;
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xypageBenaemn, .xypageBenaemn2, .xypageBenaemn1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xypageBenaemn, .xypageBenaemn2, .xypageBenaemn1 {
    height: 45px;
    font-size: 20px;
  }
}

.xypageBenaemn1 {
  background-color: var(--rgbaVal2);
}

.xyareabTop {
  width: 100%;
}

.xypageVal {
  font-family: var(--font-family);
  font-size: 24px;
  color: var(--rgbaVal);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xypageVal {
    font-size: 36px;
  }
}
@media only screen and (min-width:2303px) {
  .xypageVal {
    font-size: 30px;
  }
}

.xybackgroundShadeTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.xybackgroundShadeMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.xybackgroundShadeFieldset, .xybackgroundShadeSelect, .xybackgroundShadeFieldset1 {
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  width: 70%;
  height: 40px;
  margin-top: 10px;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xybackgroundShadeFieldset, .xybackgroundShadeSelect, .xybackgroundShadeFieldset1 {
    height: 50px;
  }
}

.xypickerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.xybackgroundShadeFieldset1 {
  width: 85%;
  height: 260px;
  border: none;
  margin-top: 5px;
}

.xybackgroundShadeLegend, .xybackgroundShadeOption {
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xybackgroundShadeLegend, .xybackgroundShadeOption {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .xybackgroundShadeLegend, .xybackgroundShadeOption {
    font-size: 22px;
  }
}

.xybackgroundShadeSelect {
  width: 90%;
  height: 20px;
  margin-left: 10px;
  margin-top: 1px;
  font-size: 14px;
  font-family: var(--font-family);
  color: black;
  background-color: white;
}

.xybackgroundShadeOption {
  margin-left: 10px;
}

.xycolorSwatch {
  width: 90%;
  height: 30px;
  border: 1px solid;
  background: none;
  border: none;
  padding: 0px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
}
.xycolorSwatch ::-webkit-scrollbar {
  width: 10px;
}
.xycolorSwatch ::-webkit-scrollbar-track {
  background: var(--rgbaVal);
}
.xycolorSwatch ::-webkit-scrollbar-thumb {
  background: white;
  border: 1px solid;
  border-color: var(--rgbaVal);
}

.xysaveButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xysaveButtonWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xysaveButtonWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xysaveButtonWrapper {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xysaveButtonWrapper {
    width: 100%;
    margin-top: 10px;
  }
}

.xysaveButton, .xysaveButton1 {
  width: 90%;
  height: 30px;
  font-size: 14px;
  color: black;
  background-color: var(--rgbaVal);
  margin-top: 2px;
  text-align: center;
  opacity: 0.7;
  border: none;
  border-radius: 6px;
  color: black;
}

.xysaveButton1 {
  color: white;
  background-color: rgb(229, 64, 68);
  opacity: 1;
  color: white;
}

.xyimageGridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  width: 95%;
  margin-top: 5px;
  margin-left: 10px;
  max-height: 70vh;
  overflow-y: scroll;
}

.xyimageGridContainer::-webkit-scrollbar {
  width: 10px;
}

.xyimageGridContainer::-webkit-scrollbar-track {
  background: var(--rgbaVal);
}

.xyimageGridContainer::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--rgbaVal);
}

.xyimageGridImage, .xyimageGridImage2, .xyimageGridImage1 {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 3px;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xyimageGridImage, .xyimageGridImage2, .xyimageGridImage1 {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xyimageGridImage, .xyimageGridImage2, .xyimageGridImage1 {
    width: calc(100% - 3px);
    height: calc(100% - 3px);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xyimageGridImage, .xyimageGridImage2, .xyimageGridImage1 {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xyimageGridImage, .xyimageGridImage2, .xyimageGridImage1 {
    width: 150px;
    height: 150px;
  }
}
@media only screen and (min-width:2303px) {
  .xyimageGridImage, .xyimageGridImage2, .xyimageGridImage1 {
    width: 180px;
    height: 180px;
  }
}

.xyimageGridImage1 {
  border: 4px solid;
  border-color: black;
}

.xyimageGridImage2 {
  border: 3px solid;
  border-color: red;
}

.xyimageGridTop {
  font-family: var(--font-family);
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}

.xysettingsSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(1.5);
  z-index: 10;
}

.xySettingsSpinner {
  background-color: transparent;
}/*# sourceMappingURL=MySettings.css.map */