:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xjtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtotalArea, .xjtotalArea2, .xjtotalArea1 {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas: "a" "b" "c" "d" "e";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .xjtotalArea .area-f, .xjtotalArea2 .area-f, .xjtotalArea1 .area-f {
    grid-area: d;
  }
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    display: flex;
    justify-content: space-between;
    height: 3vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width:359px) and (max-width: 599px) {
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    height: 4vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width:359px) and (max-width: 599px) and (max-width: 360px) {
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    height: 4vh;
    max-width: 300px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width:359px) and (max-width: 599px) and (min-width: 361px) and (max-width: 370px) {
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    height: 4vh;
    max-width: 320px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width:359px) and (max-width: 599px) and (min-width: 371px) and (max-width: 386px) {
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    height: 4vh;
    max-width: 320px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    height: 3.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtotalArea .area-f .tab-content, .xjtotalArea2 .area-f .tab-content, .xjtotalArea1 .area-f .tab-content {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtotalArea, .xjtotalArea2, .xjtotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .xjtotalArea .area-f, .xjtotalArea2 .area-f, .xjtotalArea1 .area-f {
    grid-area: d;
  }
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    display: flex;
    justify-content: space-between;
    height: 4vh;
  }
  .xjtotalArea .area-f .tab-content, .xjtotalArea2 .area-f .tab-content, .xjtotalArea1 .area-f .tab-content {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtotalArea, .xjtotalArea2, .xjtotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .xjtotalArea .area-f, .xjtotalArea2 .area-f, .xjtotalArea1 .area-f {
    grid-area: d;
  }
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    display: flex;
    justify-content: space-between;
    height: 3vh;
  }
  .xjtotalArea .area-f .tab-content, .xjtotalArea2 .area-f .tab-content, .xjtotalArea1 .area-f .tab-content {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtotalArea, .xjtotalArea2, .xjtotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .xjtotalArea .area-f, .xjtotalArea2 .area-f, .xjtotalArea1 .area-f {
    grid-area: d;
  }
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    display: flex;
    justify-content: space-between;
    height: 3vh;
  }
  .xjtotalArea .area-f .tab-content, .xjtotalArea2 .area-f .tab-content, .xjtotalArea1 .area-f .tab-content {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .xjtotalArea, .xjtotalArea2, .xjtotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 99.8%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .xjtotalArea .area-f, .xjtotalArea2 .area-f, .xjtotalArea1 .area-f {
    grid-area: d;
  }
  .xjtotalArea .area-f .tabs, .xjtotalArea2 .area-f .tabs, .xjtotalArea1 .area-f .tabs {
    display: flex;
    justify-content: space-between;
    height: 3vh;
  }
  .xjtotalArea .area-f .tab-content, .xjtotalArea2 .area-f .tab-content, .xjtotalArea1 .area-f .tab-content {
    display: none;
  }
}

.xjtotalArea1 {
  background-color: rgb(245, 245, 237);
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtotalArea2 {
    grid-template-columns: 30% 0.1% 69%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtotalArea2 {
    grid-template-columns: 30% 0.1% 69%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtotalArea2 {
    grid-template-columns: 30% 0.1% 69%;
  }
}
@media only screen and (min-width:2303px) {
  .xjtotalArea2 {
    grid-template-columns: 30% 0.1% 69%;
  }
}

.xjbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjcompName, .xjcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjcompName, .xjcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 34px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjcompName, .xjcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjcompName, .xjcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xjcompName, .xjcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjcompName1, .xjcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjcompName1, .xjcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjcompName1, .xjcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjcompName1, .xjcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjcompName1, .xjcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

.xjcompNameJAP {
  font-family: var(--font-family);
}

.xjcompNameJAP1 {
  font-family: var(--font-family);
  color: var(--rgbaVal);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjheaderArea {
    grid-area: a;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjheaderArea {
    grid-area: a;
    height: 40px;
  }
}
@media only screen and (min-width:2303px) {
  .xjheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xjrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrange1, .xjrange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrange1, .xjrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrange1, .xjrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrange1, .xjrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjrange1, .xjrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjareaA {
    grid-area: c;
    height: 34vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjareaA {
    grid-area: c;
    height: 72vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xjareaA {
    height: 83vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1082px) {
  .xjareaA {
    height: 72vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjareaA {
    grid-area: c;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjareaA {
    grid-area: c;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width:2303px) {
  .xjareaA {
    grid-area: c;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}

.xjareaATop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsearchFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0.5vh;
    width: 90%;
    border: none;
    height: 37px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsearchFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    width: 90%;
    border: none;
    height: 37px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsearchFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    width: 90%;
    border: none;
    height: 37px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsearchFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    width: 90%;
    border: none;
    height: 37px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .xjsearchFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    width: 90%;
    border: none;
    height: 37px;
    font-family: var(--font-family);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsearchLegend {
    display: flex;
    flex-direction: row;
    margin-left: 3.8vw;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsearchLegend {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 4%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsearchLegend {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsearchLegend {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 5%;
  }
}
@media only screen and (min-width:2303px) {
  .xjsearchLegend {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsearchInput {
    width: 70%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsearchInput {
    width: 70%;
    height: 20px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsearchInput {
    width: 70%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsearchInput {
    width: 70%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjsearchInput {
    width: 70%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsearchButton {
    width: calc(20% - 5px);
    height: 28px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 5px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsearchButton {
    width: calc(20% - 5px);
    height: 25px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 5px;
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsearchButton {
    width: calc(20% - 5px);
    height: 30px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 5px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsearchButton {
    width: calc(20% - 5px);
    height: 30px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 5px;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xjsearchButton {
    width: calc(20% - 5px);
    height: 30px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 5px;
    padding: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjSelectRecepyFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    height: 38px;
    border: none;
    margin-top: 1px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjSelectRecepyFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 93%;
    height: 38px;
    border: none;
    margin-top: 1px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjSelectRecepyFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 93%;
    height: 38px;
    border: none;
    margin-top: 5px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjSelectRecepyFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 93%;
    height: 38px;
    border: none;
    margin-top: 5px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xjSelectRecepyFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 93%;
    height: 38px;
    border: none;
    margin-top: 5px;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjSelectRecepyLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 3.8vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjSelectRecepyLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjSelectRecepyLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjSelectRecepyLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 5%;
  }
}
@media only screen and (min-width:2303px) {
  .xjSelectRecepyLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 5%;
  }
}

.SelectAreaTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjSelectRecepySelect {
    width: 92%;
    height: 30px;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjSelectRecepySelect {
    width: 90%;
    height: 25px;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjSelectRecepySelect {
    width: 89%;
    height: 30px;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjSelectRecepySelect {
    width: 89%;
    height: 30px;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xjSelectRecepySelect {
    width: 89%;
    height: 30px;
    border: solid 1px;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjareaB {
    grid-area: d;
    height: 45vh;
    margin-top: 5px;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 690px) {
  .xjareaB {
    margin-top: 1vh;
    height: 47.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 370px) and (max-width: 380px) {
  .xjareaB {
    height: 47vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 381px) and (max-width: 385px) {
  .xjareaB {
    height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .xjareaB {
    height: 49vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 395px) and (max-width: 411px) {
  .xjareaB {
    height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) {
  .xjareaB {
    height: 45.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (max-height: 732px) {
  .xjareaB {
    height: 49vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) and (max-width: 440px) {
  .xjareaB {
    height: 50vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjareaB {
    grid-area: d;
    height: 72vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xjareaB {
    height: 83vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjareaB {
    grid-area: d;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjareaB {
    grid-area: d;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width:2303px) {
  .xjareaB {
    grid-area: d;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjareaB1 {
    grid-area: d;
    height: 65vh;
    margin-top: 5px;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 690px) {
  .xjareaB1 {
    margin-top: 1vh;
    height: 87.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 370px) and (max-width: 380px) {
  .xjareaB1 {
    height: 87vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 381px) and (max-width: 385px) {
  .xjareaB1 {
    height: 87vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .xjareaB1 {
    height: 87vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 395px) and (max-width: 411px) {
  .xjareaB1 {
    height: 87vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) {
  .xjareaB1 {
    height: 65.5vh;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (max-height: 732px) {
  .xjareaB1 {
    height: 49vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) and (max-width: 440px) {
  .xjareaB1 {
    height: 87vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjareaC, .areaC1 {
    grid-area: e;
    width: 99%;
    height: 30vh;
    margin-top: 1vh;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjareaC, .areaC1 {
    grid-area: e;
    width: 99%;
    height: 72vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xjareaC, .areaC1 {
    height: 83vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjareaC, .areaC1 {
    grid-area: e;
    width: 99%;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjareaC, .areaC1 {
    grid-area: e;
    width: 99%;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width:2303px) {
  .xjareaC, .areaC1 {
    grid-area: e;
    width: 99%;
    height: 82vh;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
  }
}

.areaC1 {
  height: 820px;
  margin-left: 20px;
  background: white;
  margin-top: 50px;
  z-index: 10;
}

.areaBMiddle {
  grid-area: b;
  height: 30px;
}

.SelectRecepyFieldset {
  width: 300px;
  height: 38px;
  border: none;
  margin-top: 1px;
  border-color: rgb(223, 212, 212);
}

.SelectRecepyLegend {
  color: black;
  font-size: 12px;
  font-family: var(--font-family);
}

.SelectRecepySelect {
  width: 300px;
  height: 30px;
  border: solid 1px;
  border-color: rgb(223, 212, 212);
  outline: none;
  font-family: var(--font-family);
  font-size: 16px;
  color: black;
  border-radius: 6px;
  background-color: white;
}

.xjrecepiesByCategorySelectButton, .xjrecepiesByCategorySelectButton2 {
  width: auto;
  height: 30px;
  border: none;
  border-radius: 6px;
  background-color: var(--rgbaVal);
  margin-left: 3px;
  margin-bottom: 2px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  cursor: pointer;
  font-family: var(--font-family);
  color: black;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrecepiesByCategorySelectButton, .xjrecepiesByCategorySelectButton2 {
    width: auto;
    height: 35px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 2px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    cursor: pointer;
    font-family: var(--font-family);
    color: black;
    font-size: 15px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecepiesByCategorySelectButton, .xjrecepiesByCategorySelectButton2 {
    width: auto;
    height: 35px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 2px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    cursor: pointer;
    font-family: var(--font-family);
    color: black;
    font-size: 15px;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecepiesByCategorySelectButton, .xjrecepiesByCategorySelectButton2 {
    width: auto;
    height: 45px;
    border: none;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 2px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    cursor: pointer;
    font-family: var(--font-family);
    color: black;
    font-size: 18px;
  }
}

.xjbuttonIcon1 {
  position: absolute;
  top: 0;
  right: 0;
  color: gold;
  font-size: 18px;
}

.xjbuttonIcon {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 18px;
}

.xjbuttonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  overflow: hidden;
}

.xjrecepiesByCategorySelectButton2 {
  background-color: var(--rgbaVal2);
}

.xjbuttonIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.xjbuttonContent {
  position: relative;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjshowRecepiesTop {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height: auto;
    max-height: 15vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjshowRecepiesTop::-webkit-scrollbar {
    width: 5px;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-height: 690px) and (max-width: 360px) {
  .xjshowRecepiesTop {
    max-height: 13vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjshowRecepiesTop {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height: auto;
    min-height: 1px;
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjshowRecepiesTop::-webkit-scrollbar {
    width: 5px;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjshowRecepiesTop {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height: auto;
    min-height: 1px;
    max-height: 62vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjshowRecepiesTop::-webkit-scrollbar {
    width: 5px;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjshowRecepiesTop {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height: auto;
    min-height: 1px;
    max-height: 62vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjshowRecepiesTop::-webkit-scrollbar {
    width: 5px;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width:2303px) {
  .xjshowRecepiesTop {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height: auto;
    min-height: 1px;
    max-height: 62vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjshowRecepiesTop::-webkit-scrollbar {
    width: 5px;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjshowRecepiesTop::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecepyHeaderContainer {
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xjrecepyHeaderContainer {
    height: 4vh;
    padding: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) and (max-width: 1300px) {
  .xjrecepyHeaderContainer {
    height: 5vh;
    padding: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1301px) and (max-width: 1438px) {
  .xjrecepyHeaderContainer {
    height: 3.3vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecepyHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecepyHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecepyHeaderContainerJAP {
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xjrecepyHeaderContainerJAP {
    height: 4.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1025px) and (max-width: 1080px) {
  .xjrecepyHeaderContainerJAP {
    height: 4.6vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) and (max-width: 1300px) {
  .xjrecepyHeaderContainerJAP {
    height: 4.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1301px) and (max-width: 1438px) {
  .xjrecepyHeaderContainerJAP {
    height: 3.3vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrecepyHeader, .xjrecepyHeaderJ1 {
    width: 80vw;
    font-family: var(--font-family);
    font-size: 18px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 1vw;
    margin-top: 4px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecepyHeader, .xjrecepyHeaderJ1 {
    width: 95%;
    font-family: var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 5px;
    margin-top: 4px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xjrecepyHeader, .xjrecepyHeaderJ1 {
    margin-left: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) and (max-width: 1300px) {
  .xjrecepyHeader, .xjrecepyHeaderJ1 {
    margin-left: -2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrecepyHeader, .xjrecepyHeaderJ1 {
    width: 95%;
    font-family: var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 1.5%;
    margin-top: 4px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecepyHeader, .xjrecepyHeaderJ1 {
    width: 88%;
    font-family: var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 6%;
    margin-top: 4px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecepyHeader, .xjrecepyHeaderJ1 {
    width: 88%;
    font-family: var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 6%;
    margin-top: 4px;
    color: black;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecepyHeaderJ1 {
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrecepyHeaderJ1 {
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecepyHeaderJ1 {
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecepyHeaderJ1 {
    height: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrecepyHeaderJAP {
    width: 95%;
    font-family: arial;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    z-index: 1;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 2px;
    margin-bottom: 3px;
    color: black;
    background-color: white;
    border-radius: 6px;
  }
  .xjrecepyHeaderJAP::-moz-placeholder {
    color: black;
    font-size: 12px;
  }
  .xjrecepyHeaderJAP::placeholder {
    color: black;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecepyHeaderJAP {
    position: relative;
    width: 95%;
    font-family: arial;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 2px;
    margin-bottom: 3px;
    color: black;
    background-color: white;
    border-radius: 6px;
    z-index: 10;
  }
  .xjrecepyHeaderJAP::-moz-placeholder {
    color: black;
    font-size: 12px;
  }
  .xjrecepyHeaderJAP::placeholder {
    color: black;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrecepyHeaderJAP {
    position: relative;
    width: 95%;
    font-family: arial;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 1.5%;
    margin-bottom: 3px;
    color: black;
    background-color: white;
    border-radius: 6px;
    z-index: 10;
  }
  .xjrecepyHeaderJAP::-moz-placeholder {
    color: black;
    font-size: 12px;
  }
  .xjrecepyHeaderJAP::placeholder {
    color: black;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecepyHeaderJAP {
    position: relative;
    width: 95%;
    font-family: arial;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 1.5%;
    margin-bottom: 3px;
    color: black;
    background-color: white;
    border-radius: 6px;
    z-index: 10;
  }
  .xjrecepyHeaderJAP::-moz-placeholder {
    color: black;
    font-size: 12px;
  }
  .xjrecepyHeaderJAP::placeholder {
    color: black;
    font-size: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecepyHeaderJAP {
    position: relative;
    width: 95%;
    font-family: arial;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left: 1.5%;
    margin-bottom: 3px;
    color: black;
    background-color: white;
    border-radius: 6px;
    z-index: 10;
  }
  .xjrecepyHeaderJAP::-moz-placeholder {
    color: black;
    font-size: 12px;
  }
  .xjrecepyHeaderJAP::placeholder {
    color: black;
    font-size: 12px;
  }
}

.xjheader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xjheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjheaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjheaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjheaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 1.5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjheaderContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 1.5%;
  }
}
@media only screen and (min-width:2303px) {
  .xjheaderContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 1.5%;
  }
}

.xjheaderButtonBenaemn {
  width: 37%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(236, 228, 228);
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 10px;
  border-radius: 6px;
  margin-bottom: 2px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
}

.xjheaderButtonAmount {
  width: calc(15% - 2px);
  height: 25px;
  border: 1px solid;
  border-color: rgb(236, 228, 228);
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 10px;
  border-radius: 6px;
  margin-left: 2px;
  margin-bottom: 2px;
  font-weight: bold;
}

.xjheaderButton {
  width: calc(17% - 1px);
  height: 25px;
  border: 1px solid;
  border-color: rgb(236, 228, 228);
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 10px;
  border-radius: 6px;
  margin-left: 1px;
  margin-bottom: 2px;
  font-weight: bold;
}

.xjheaderKcals {
  width: calc(14% - 1px);
  height: 25px;
  border: 1px solid;
  border-color: rgb(236, 228, 228);
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 10px;
  border-radius: 6px;
  margin-left: 1px;
  margin-bottom: 2px;
  font-weight: bold;
  cursor: pointer;
}

.xjheaderOrder {
  width: calc(12% - 1px);
  height: 25px;
  border: 1px solid;
  border-color: rgb(236, 228, 228);
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 10px;
  border-radius: 6px;
  margin-left: 1px;
  margin-bottom: 2px;
  font-weight: bold;
  cursor: pointer;
  cursor: pointer;
}

.xjheaderDelete, .xjheaderDelete1 {
  width: calc(7% - 2px);
  border-radius: 6px;
  border: 1px solid;
  border-color: rgb(236, 228, 228);
  background-color: var(--rgbaVal);
  height: 25px;
  margin-right: 1px;
  margin-left: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjheaderDelete1 {
    background-color: var(--rgbaVal);
    width: calc(8.1% - 1px);
  }
}

@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjreceivedTop {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xjreceivedTop {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjreceivedContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    min-height: 1px;
    max-height: 34vh;
    height: 34vh;
    overflow-y: scroll;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 690px) {
  .xjreceivedContainer {
    height: 32vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 790px) {
  .xjreceivedContainer {
    height: 31vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xjreceivedContainer {
    height: 30vh;
    max-height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 381px) and (max-width: 385px) {
  .xjreceivedContainer {
    height: 38vh;
    max-height: 36vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (min-height: 750px) {
  .xjreceivedContainer {
    height: 30vh;
    max-height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (max-height: 700px) {
  .xjreceivedContainer {
    height: 32vh;
    max-height: 32vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) and (max-width: 430px) {
  .xjreceivedContainer {
    height: 37vh;
    max-height: 37vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjreceivedContainer::-webkit-scrollbar {
    width: 1px;
  }
  .xjreceivedContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb {
    background: #888;
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjreceivedContainer {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    max-height: 58vh;
    height: 58vh;
    overflow-y: auto;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 1%;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .xjreceivedContainer {
    height: 48vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjreceivedContainer::-webkit-scrollbar {
    width: 1px;
  }
  .xjreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjreceivedContainer {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    max-height: 60vh;
    height: 60vh;
    overflow-y: auto;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 1.5%;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjreceivedContainer::-webkit-scrollbar {
    width: 10px;
    cursor: pointer;
  }
  .xjreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb:hover {
    background: white;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjreceivedContainer {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    max-height: 57vh;
    height: 57vh;
    overflow-y: auto;
    width: 90%;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 1.5%;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjreceivedContainer::-webkit-scrollbar {
    width: 1px;
    cursor: pointer;
  }
  .xjreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb:hover {
    background: white;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjreceivedContainer {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    max-height: 65vh;
    height: 65vh;
    overflow-y: auto;
    width: 90%;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 1.5%;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .xjreceivedContainer::-webkit-scrollbar {
    width: 1px;
    cursor: pointer;
  }
  .xjreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjreceivedContainer::-webkit-scrollbar-thumb:hover {
    background: white;
    cursor: pointer;
  }
}

.xjdatarows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjinfobuttonBenaemn, .xjinfobuttonBenaemn2 {
    max-width: 37%;
    min-width: 1px;
    width: 42%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjinfobuttonBenaemn, .xjinfobuttonBenaemn2 {
    max-width: 37%;
    min-width: 1px;
    width: 42%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjinfobuttonBenaemn, .xjinfobuttonBenaemn2 {
    max-width: 37%;
    min-width: 1px;
    width: 42%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjinfobuttonBenaemn, .xjinfobuttonBenaemn2 {
    max-width: 37%;
    min-width: 1px;
    width: 42%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjinfobuttonBenaemn, .xjinfobuttonBenaemn2 {
    max-width: 37%;
    min-width: 1px;
    width: 42%;
    height: 40px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjinfobuttonAmount, .xjinfobuttonAmount2 {
    width: calc(15% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjinfobuttonAmount, .xjinfobuttonAmount2 {
    width: calc(15% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjinfobuttonAmount, .xjinfobuttonAmount2 {
    width: calc(15% - 2px);
    height: 30px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjinfobuttonAmount, .xjinfobuttonAmount2 {
    width: calc(15% - 2px);
    height: 35px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xjinfobuttonAmount, .xjinfobuttonAmount2 {
    width: calc(15% - 2px);
    height: 40px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjinfobuttonAmount2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjinfobuttonAmount2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjinfobuttonAmount2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjinfobuttonAmount2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjinfobuttonAmount2 {
    background-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjinfoButton, .xjinfoButton2 {
    width: calc(17% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjinfoButton, .xjinfoButton2 {
    width: calc(17% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjinfoButton, .xjinfoButton2 {
    width: calc(17% - 2px);
    height: 30px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjinfoButton, .xjinfoButton2 {
    width: calc(17% - 2px);
    height: 35px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xjinfoButton, .xjinfoButton2 {
    width: calc(17% - 2px);
    height: 40px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjinfoButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjinfoButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjinfoButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjinfoButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjinfoButton2 {
    background-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjkcalsButton, .xjkcalsButton2 {
    width: calc(14% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjkcalsButton, .xjkcalsButton2 {
    width: calc(14% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjkcalsButton, .xjkcalsButton2 {
    width: calc(14% - 2px);
    height: 30px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjkcalsButton, .xjkcalsButton2 {
    width: calc(14% - 2px);
    height: 35px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xjkcalsButton, .xjkcalsButton2 {
    width: calc(14% - 2px);
    height: 40px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjkcalsButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjkcalsButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjkcalsButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjkcalsButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjkcalsButton2 {
    background-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjarborderButton, .xjarborderButton2 {
    width: calc(12% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjarborderButton, .xjarborderButton2 {
    width: calc(12% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjarborderButton, .xjarborderButton2 {
    width: calc(12% - 2px);
    height: 30px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjarborderButton, .xjarborderButton2 {
    width: calc(12% - 2px);
    height: 35px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xjarborderButton, .xjarborderButton2 {
    width: calc(12% - 2px);
    height: 40px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjarborderButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjarborderButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjarborderButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjarborderButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjarborderButton2 {
    background-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjdeletebackline {
    font-size: 12px;
    color: var(--rgbaVal);
    cursor: pointer;
    border: 0;
    margin: 0;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjdeletebackline {
    font-size: 16px;
    color: var(--rgbaVal);
    cursor: pointer;
    border: 0;
    margin: 0;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjdeletebackline {
    font-size: 16px;
    color: var(--rgbaVal);
    cursor: pointer;
    border: 0;
    margin: 0;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjdeletebackline {
    font-size: 16px;
    color: var(--rgbaVal);
    cursor: pointer;
    border: 0;
    margin: 0;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjdeletebackline {
    font-size: 24px;
    cursor: pointer;
    border: 0;
    margin: 0;
    color: var(--rgbaVal);
  }
}

.avgcaloriesButton {
  height: 20px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 2px;
  margin-bottom: 2px;
  text-align: middle;
  white-space: nowrap;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtotalcostBenaemn {
    width: 36.4%;
    text-align: left;
    height: 20px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtotalcostBenaemn {
    width: 36.4%;
    text-align: left;
    height: 20px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtotalcostBenaemn {
    width: 36.6%;
    text-align: left;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtotalcostBenaemn {
    width: 36.6%;
    text-align: left;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjtotalcostBenaemn {
    width: 36.6%;
    text-align: left;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}

.xjtotalamountButton {
  width: calc(15% - 3px);
  height: 20px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 3px;
  margin-bottom: 2px;
  text-align: middle;
  white-space: nowrap;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtotalamountButton {
    width: calc(15% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtotalamountButton {
    width: calc(15% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjtotalamountButton {
    width: calc(15% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}

.xjtotalcostButton {
  width: calc(17% - 3px);
  height: 20px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 3px;
  margin-bottom: 2px;
  text-align: middle;
  white-space: nowrap;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtotalcostButton {
    width: calc(17% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtotalcostButton {
    width: calc(17% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjtotalcostButton {
    width: calc(17% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}

.xjtotalcaloriesButton {
  width: calc(14.2% - 3px);
  height: 20px;
  border: none;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  margin-left: 3px;
  margin-bottom: 2px;
  text-align: middle;
  white-space: nowrap;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtotalcaloriesButton {
    width: calc(14.2% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtotalcaloriesButton {
    width: calc(14.2% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjtotalcaloriesButton {
    width: calc(14.2% - 3px);
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjavgcaloriesButton {
    width: calc(12% - 2px);
    height: 22px;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal);
    cursor: pointer;
    text-align: left;
    margin-left: 2px;
    font-size: 9px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjavgcaloriesButton {
    width: calc(12% - 2px);
    height: 22px;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal);
    cursor: pointer;
    font-size: 11px;
    padding: 1px;
    color: black;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjavgcaloriesButton {
    width: calc(12% - 2px);
    height: 26px;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: black;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjavgcaloriesButton {
    width: calc(12% - 2px);
    height: 26px;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: black;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xjavgcaloriesButton {
    width: calc(12% - 2px);
    height: 26px;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgb(236, 228, 228);
    background-color: var(--rgbaVal);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: black;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjselectnewingredientContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3px;
    width: 99%;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjselectnewingredientContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3px;
    width: 99%;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjselectnewingredientContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3px;
    width: 99%;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjselectnewingredientContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3px;
    width: 99%;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xjselectnewingredientContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3px;
    width: 99%;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjselectnewingredientFieldset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 24%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 40px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjselectnewingredientFieldset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    width: 20%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 40px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjselectnewingredientFieldset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    width: 20%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjselectnewingredientFieldset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    width: 20%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 40px;
  }
}
@media only screen and (min-width:2303px) {
  .xjselectnewingredientFieldset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    width: 20%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 40px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjselectnewingredientLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjselectnewingredientLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjselectnewingredientLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjselectnewingredientLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xjselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnumPortionsContainer {
    width: 17%;
    border: none;
    height: 50px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnumPortionsContainer {
    width: 22%;
    border: none;
    height: 50px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnumPortionsContainer {
    width: 22%;
    border: none;
    height: 50px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnumPortionsContainer {
    width: 22%;
    border: none;
    height: 50px;
  }
}
@media only screen and (min-width:2303px) {
  .xjnumPortionsContainer {
    width: 22%;
    border: none;
    height: 50px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjselectPortions {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 50px;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjselectPortions {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjselectPortions {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjselectPortions {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xjselectPortions {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: 6px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnumPortionsFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 50px;
    outline: none;
    height: 40px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnumPortionsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 60%;
    outline: none;
    height: 40px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnumPortionsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 60%;
    outline: none;
    height: 40px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnumPortionsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 60%;
    outline: none;
    height: 40px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xjnumPortionsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 60%;
    outline: none;
    height: 40px;
    color: black;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnumPortionsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnumPortionsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnumPortionsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjnumPortionsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsizeportionsIngramsFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 20%;
    outline: none;
    height: 40px;
    color: black;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsizeportionsIngramsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 25%;
    outline: none;
    height: 40px;
    color: black;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsizeportionsIngramsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 25%;
    outline: none;
    height: 40px;
    color: black;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsizeportionsIngramsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 25%;
    outline: none;
    height: 40px;
    color: black;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xjsizeportionsIngramsFieldset {
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 25%;
    outline: none;
    height: 40px;
    color: black;
    margin-left: 2px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsizeportionsIngramsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsizeportionsIngramsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsizeportionsIngramsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xjsizeportionsIngramsLegend {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsizeportionsIngramsInput {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 12px;
    width: 88%;
    outline: none;
    height: 22px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsizeportionsIngramsInput {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 20px;
    color: black;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
  .xjsizeportionsIngramsInput::-moz-placeholder {
    color: black;
    font-size: 11px;
  }
  .xjsizeportionsIngramsInput::placeholder {
    color: black;
    font-size: 11px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsizeportionsIngramsInput {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 20px;
    color: black;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
  .xjsizeportionsIngramsInput::-moz-placeholder {
    color: black;
    font-size: 11px;
  }
  .xjsizeportionsIngramsInput::placeholder {
    color: black;
    font-size: 11px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsizeportionsIngramsInput {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 20px;
    color: black;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
  .xjsizeportionsIngramsInput::-moz-placeholder {
    color: black;
    font-size: 11px;
  }
  .xjsizeportionsIngramsInput::placeholder {
    color: black;
    font-size: 11px;
  }
}
@media only screen and (min-width:2303px) {
  .xjsizeportionsIngramsInput {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 20px;
    color: black;
    border-radius: 6px;
    background-color: white;
    color: black;
  }
  .xjsizeportionsIngramsInput::-moz-placeholder {
    color: black;
    font-size: 11px;
  }
  .xjsizeportionsIngramsInput::placeholder {
    color: black;
    font-size: 11px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsaveRecepyButton, .xjsaveRecepyButtonActive {
    width: 70px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsaveRecepyButton, .xjsaveRecepyButtonActive {
    width: 100%;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsaveRecepyButton, .xjsaveRecepyButtonActive {
    width: 100%;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
    padding: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsaveRecepyButton, .xjsaveRecepyButtonActive {
    width: 100%;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
    padding: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xjsaveRecepyButton, .xjsaveRecepyButtonActive {
    width: 100%;
    height: 40px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
    padding: 3px;
  }
}

.xjsaveRecepyButtonActive {
  background-color: rgb(229, 64, 68);
  color: white;
  opacity: 1;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjsaverecepybuttoncontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 45px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjsaverecepybuttoncontainer {
    width: 100%;
    height: 45px;
    border: none;
    margin-top: 3px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjsaverecepybuttoncontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 45px;
    border: none;
    margin-top: 3px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjsaverecepybuttoncontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 45px;
    border: none;
    margin-top: 3px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width:2303px) {
  .xjsaverecepybuttoncontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 45px;
    border: none;
    margin-top: 3px;
    margin-left: 1vw;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjreceptSizeFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 90%;
    height: 30px;
    color: black;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjreceptSizeFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 90%;
    height: 30px;
    color: black;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjreceptSizeFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 90%;
    height: 30px;
    color: black;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjreceptSizeFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 90%;
    height: 30px;
    color: black;
    margin-left: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xjreceptSizeFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    width: 90%;
    height: 30px;
    color: black;
    margin-left: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjreceptSizeLegend {
    margin-left: 13vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjreceptSizeLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 6.5vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjreceptSizeLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 6.5vw;
  }
}
@media only screen and (min-width:2303px) {
  .xjreceptSizeLegend {
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 6.5vw;
  }
}

.xjrangeGrid {
  display: flex;
  justify-content: space-between;
  width: 70%;
  border: none;
}

.xjrangeinput {
  width: 70%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnutrientSummaryContainer {
    grid-area: d;
    width: 99%;
    height: 10vh;
    border: none;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnutrientSummaryContainer {
    grid-area: d;
    width: 99%;
    height: 10vh;
    border: none;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnutrientSummaryContainer {
    grid-area: d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    height: 10vh;
    border: none;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnutrientSummaryContainer {
    grid-area: d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    height: 10vh;
    border: none;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xjnutrientSummaryContainer {
    grid-area: d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 99%;
    height: 10vh;
    border: none;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    width: 99%;
    overflow: hidden;
    height: 50px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    margin-top: 1vh;
    width: 99%;
    overflow: hidden;
    height: 50px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    margin-top: 1vh;
    width: 99%;
    overflow: hidden;
    height: 50px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    margin-top: 1vh;
    width: 99%;
    overflow: hidden;
    height: 50px;
  }
}
@media only screen and (min-width:2303px) {
  .xjviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    margin-top: 1vh;
    width: 99%;
    overflow: hidden;
    height: 50px;
  }
}

.xjnutrientsummaryButton, .xjnutrientSummaryBFats, .xjnutrientSummaryButtonCarb, .xjnutrientSummaryButtonProtein, .xjnutrientSummaryButtonKcals {
  width: 15%;
  height: 30px;
  border: none;
  background-color: transparent;
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 3px;
  margin-top: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnutrientSummaryButtonKcals {
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnutrientSummaryButtonKcals {
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnutrientSummaryButtonKcals {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnutrientSummaryButtonKcals {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjnutrientSummaryButtonKcals {
    white-space: nowrap;
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnutrientSummaryButtonProtein {
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnutrientSummaryButtonProtein {
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnutrientSummaryButtonProtein {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnutrientSummaryButtonProtein {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjnutrientSummaryButtonProtein {
    white-space: nowrap;
    font-size: 14px;
  }
}

.xjnutrientSummaryButtonCarb {
  width: 15%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnutrientSummaryButtonCarb {
    white-space: nowrap;
    width: 18%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnutrientSummaryButtonCarb {
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnutrientSummaryButtonCarb {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnutrientSummaryButtonCarb {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjnutrientSummaryButtonCarb {
    white-space: nowrap;
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnutrientSummaryBFats {
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnutrientSummaryBFats {
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnutrientSummaryBFats {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnutrientSummaryBFats {
    white-space: nowrap;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjnutrientSummaryBFats {
    white-space: nowrap;
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjfireIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjfireIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjfireIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjfireIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .xjfireIcon {
    font-size: 36px;
    color: black;
    margin-left: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjcheeseIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjcheeseIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjcheeseIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjcheeseIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .xjcheeseIcon {
    font-size: 40px;
    color: black;
    margin-left: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjmeatIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjmeatIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjmeatIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjmeatIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .xjmeatIcon {
    font-size: 40px;
    color: black;
    margin-left: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjwheatIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjwheatIcon {
    font-size: 22px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjwheatIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjwheatIcon {
    font-size: 30px;
    color: black;
    margin-left: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .xjwheatIcon {
    font-size: 36px;
    color: black;
    margin-left: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtextareaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    border: 1px solid;
    height: 70px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtextareaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    border: 1px solid;
    height: 14.5vh;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtextareaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    border: 1px solid;
    height: 14.5vh;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtextareaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    border: 1px solid;
    height: 14.5vh;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xjtextareaContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    border: 1px solid;
    height: 14.5vh;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtextareaComments {
    width: 48%;
    height: 45px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtextareaComments {
    width: 52%;
    height: 12vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtextareaComments {
    width: 52%;
    height: 12vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtextareaComments {
    width: 52%;
    height: 12vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
  }
}
@media only screen and (min-width:2303px) {
  .xjtextareaComments {
    width: 60.5%;
    height: 12vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 38px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 38px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 46px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 46px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjAiOutlinePlusCircle {
    color: var(--rgbaVal);
    font-size: 46px;
    cursor: pointer;
  }
}

.xjbuttonFoodInfo {
  width: 25%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 10px;
  border-radius: 3px;
  margin-left: 2%;
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: pointer;
}

.xjnumTecken {
  font-family: var(--font-family);
  font-size: 12px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjfoodAreaHeader {
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjfoodAreaHeader {
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjfoodAreaHeader {
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjfoodAreaHeader {
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xjfoodAreaHeader {
    margin-top: 5px;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjfoodArea {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjfoodArea {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjfoodArea {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjfoodArea {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xjfoodArea {
    width: 100%;
  }
}

.xjfoodarearows {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.xjheaderButtonNutrient {
  width: 70%;
  text-align: left;
  margin-top: 1px;
  border: none;
  border-radius: 3px;
  background-color: var(--rgbaVal);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  color: black;
}

.xjheaderButtonQuantity {
  width: calc(15% - 2px);
  text-align: left;
  margin-top: 1px;
  border: none;
  border-radius: 3px;
  background-color: var(--rgbaVal);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  margin-left: 2px;
  color: black;
}

.xjheaderButtonUnit {
  width: 14%;
  text-align: left;
  margin-top: 1px;
  border: none;
  border-radius: 3px;
  background-color: var(--rgbaVal);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  margin-left: 2px;
  color: black;
}

.xjfoodareaContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 99%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: transparent;
  overflow-x: hidden;
  /* Handle */
  /* Handle on hover */
}
.xjfoodareaContainer::-webkit-scrollbar {
  width: 3px;
}
.xjfoodareaContainer::-webkit-scrollbar-track {
  background-color: var(--rgbaVal);
}
.xjfoodareaContainer::-webkit-scrollbar-thumb {
  background: white;
}
.xjfoodareaContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjfoodareaContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: transparent;
    overflow-x: hidden;
    /* Handle */
    /* Handle on hover */
  }
  .xjfoodareaContainer::-webkit-scrollbar {
    width: 8px;
  }
  .xjfoodareaContainer::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjfoodareaContainer::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjfoodareaContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjfoodareaContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: transparent;
    overflow-x: hidden;
    /* Handle */
    /* Handle on hover */
  }
  .xjfoodareaContainer::-webkit-scrollbar {
    width: 8px;
  }
  .xjfoodareaContainer::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjfoodareaContainer::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjfoodareaContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjfoodareaContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: transparent;
    overflow-x: hidden;
    /* Handle */
    /* Handle on hover */
  }
  .xjfoodareaContainer::-webkit-scrollbar {
    width: 8px;
  }
  .xjfoodareaContainer::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjfoodareaContainer::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjfoodareaContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
}

.xjnutrientValue, .xjnutrientValue2 {
  width: 70%;
  height: 20px;
  text-align: left;
  margin-top: 2px;
  border: none;
  padding-top: 3px;
  border-radius: 3px;
  background-color: var(--rgbaVal2);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: italic;
  color: black;
}

.xjnutrientValue2 {
  background-color: var(--rgbaVal);
}

.xjnutrientQuantity, .xjnutrientQuantity2 {
  width: calc(15% - 2px);
  height: 20px;
  text-align: left;
  margin-top: 2px;
  border: none;
  padding-top: 3px;
  border-radius: 3px;
  background-color: var(--rgbaVal2);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: italic;
  margin-left: 2px;
  color: black;
}

.xjnutrientQuantity2 {
  background-color: var(--rgbaVal);
}

.xjnutrientUnit, .xjnutrientUnit2 {
  width: calc(15% - 2px);
  height: 20px;
  text-align: left;
  margin-top: 2px;
  border: none;
  padding-top: 3px;
  border-radius: 3px;
  background-color: var(--rgbaVal2);
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 2px;
  font-style: italic;
  color: black;
}

.xjnutrientUnit2 {
  background-color: var(--rgbaVal);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewRecepyCommentFromAiContainer {
    grid-area: c;
    width: 98%;
    height: 5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewRecepyCommentFromAiContainer {
    grid-area: c;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewRecepyCommentFromAiButton {
    width: 90%;
    height: 23px;
    border: 1px solid;
    border-color: rgb(248, 244, 244);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 3px;
    margin-left: 2px;
    background-color: var(--val);
    box-shadow: 1vh 0.5vh 0.5vh 0.5vh rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewRecepyCommentFromAiButton {
    width: 70%;
    height: 50px;
    border: 1px solid;
    border-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    border-radius: 3px;
    margin-left: 2px;
    background-color: var(--val);
    box-shadow: 1vh 0.2vh 0.5vh 0.5vh rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjviewRecepyCommentFromAiButton {
    width: 80%;
    height: 60px;
    border: 2px solid var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 20px;
    border-radius: 6px;
    margin-left: 2px;
    background-color: var(--val);
    box-shadow: 0.5vh 0.3vh 0.3vh 0.2vh rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewRecepyCommentFromAiButton {
    width: 80%;
    height: 50px;
    border: 2px solid var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 20px;
    border-radius: 6px;
    margin-left: 2px;
    background-color: var(--val);
    box-shadow: 0.5vh 0.3vh 0.3vh 0.2vh rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (min-width:2303px) {
  .xjviewRecepyCommentFromAiButton {
    width: 80%;
    height: 50px;
    border: 2px solid var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 24px;
    border-radius: 6px;
    margin-left: 2px;
    background-color: var(--val);
    box-shadow: 0.5vh 0.3vh 0.3vh 0.2vh rgba(0, 0, 0, 0.2);
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewRecepyCommentFromAiHeader, .xjviewRecepyCommentFromAiHeader1 {
    font-family: var(--font-family);
    font-size: 10px;
    color: black;
    margin-left: 3vw;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjviewRecepyCommentFromAiHeader, .xjviewRecepyCommentFromAiHeader1 {
    font-family: var(--font-family);
    font-size: 10px;
    color: black;
    margin-left: 5.7vw;
    margin-top: 2px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewRecepyCommentFromAiHeader, .xjviewRecepyCommentFromAiHeader1 {
    font-family: var(--font-family);
    font-size: 10px;
    color: black;
    margin-left: 3vw;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewRecepyCommentFromAiHeader, .xjviewRecepyCommentFromAiHeader1 {
    font-family: var(--font-family);
    font-size: 10px;
    color: black;
    margin-left: 5.7vw;
    margin-top: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xjviewRecepyCommentFromAiHeader, .xjviewRecepyCommentFromAiHeader1 {
    font-family: var(--font-family);
    font-size: 10px;
    color: black;
    margin-left: 5.7vw;
    margin-top: 2px;
  }
}

.xjviewRecepyCommentFromAiHeader1 {
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewRecepyCommentFromAiFieldset {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewRecepyCommentFromAiFieldset {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjviewRecepyCommentFromAiFieldset {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewRecepyCommentFromAiFieldset {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10%;
  }
}
@media only screen and (min-width:2303px) {
  .xjviewRecepyCommentFromAiFieldset {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewRecepyCommentFromAiLegend {
    font-family: var(--font-family);
    font-size: "14px";
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjviewRecepyCommentFromAiLegend {
    font-family: var(--font-family);
    font-size: "14px";
    color: black;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewRecepyCommentFromAiLegend {
    font-family: var(--font-family);
    font-size: "14px";
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewRecepyCommentFromAiLegend {
    font-family: var(--font-family);
    font-size: "14px";
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xjviewRecepyCommentFromAiLegend {
    font-family: var(--font-family);
    font-size: "14px";
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewdescription {
    grid-area: e;
    width: 99%;
    height: 41.3vh;
    overflow-y: scroll;
    margin-top: 3px;
    border: none;
    resize: none;
    outline: none;
    background: white;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    padding: 3px;
    /* Handle */
    /* Handle on hover */
  }
  .xjviewdescription::-webkit-scrollbar {
    width: 8px;
  }
  .xjviewdescription::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjviewdescription::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjviewdescription::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewdescription {
    grid-area: e;
    width: 95%;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    margin-top: 3px;
    border: none;
    resize: none;
    outline: none;
    background: transparent;
    padding: 10px;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    /* Handle */
    /* Handle on hover */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xjviewdescription {
    height: 75vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081) {
  .xjviewdescription {
    height: 78vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewdescription::-webkit-scrollbar {
    width: 8px;
  }
  .xjviewdescription::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjviewdescription::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjviewdescription::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjviewdescription {
    grid-area: e;
    width: 95%;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 3px;
    border: none;
    resize: none;
    outline: none;
    background: transparent;
    padding: 10px;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    /* Handle */
    /* Handle on hover */
  }
  .xjviewdescription::-webkit-scrollbar {
    width: 8px;
  }
  .xjviewdescription::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjviewdescription::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjviewdescription::-webkit-scrollbar-thumb:hover {
    background: rgb(229, 64, 68);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewdescription {
    grid-area: e;
    width: 95%;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 3px;
    border: none;
    resize: none;
    outline: none;
    background: transparent;
    padding: 15px;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    /* Handle */
    /* Handle on hover */
  }
  .xjviewdescription::-webkit-scrollbar {
    width: 8px;
  }
  .xjviewdescription::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjviewdescription::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjviewdescription::-webkit-scrollbar-thumb:hover {
    background: rgb(229, 64, 68);
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjviewdescription {
    grid-area: e;
    width: 90%;
    height: 60vh;
    overflow-y: auto;
    margin-top: 3px;
    border: none;
    resize: none;
    outline: none;
    background: transparent;
    padding: 20px;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    /* Handle */
    /* Handle on hover */
  }
  .xjviewdescription::-webkit-scrollbar {
    width: 8px;
  }
  .xjviewdescription::-webkit-scrollbar-track {
    background-color: var(--rgbaVal);
  }
  .xjviewdescription::-webkit-scrollbar-thumb {
    background: white;
  }
  .xjviewdescription::-webkit-scrollbar-thumb:hover {
    background: rgb(229, 64, 68);
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjviewdescription1 {
    font-size: 20px;
    color: black;
    background: white;
    margin-top: 0px;
    height: 64vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjviewdescription1 {
    font-size: 20px;
    color: black;
    background: white;
    margin-top: 0px;
    height: 63vh;
    margin-left: 3px;
    padding: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xjviewdescription1 {
    height: 77vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjviewdescription1 {
    font-size: 22px;
    color: black;
    background: white;
    margin-top: 0px;
    height: 63vh;
    margin-left: 3px;
    padding: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjviewdescription1 {
    font-size: 22px;
    color: black;
    background: white;
    margin-top: 0px;
    height: 60vh;
    margin-left: 3px;
    padding: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xjviewdescription1 {
    font-size: 22px;
    color: black;
    background: white;
    margin-top: 0px;
    height: 60vh;
    margin-left: 3px;
    padding: 3px;
  }
}

@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .numTeckenTop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .numTeckenTop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .numTeckenTop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnumTecken1 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 1vw;
    margin-top: 2.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 690px) {
  .xjnumTecken1 {
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnumTecken1 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnumTecken1 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 11px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnumTecken1 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 11px;
    margin-left: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xjnumTecken1 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 11px;
    margin-left: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjnumTecken2 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 1vw;
    margin-top: 2.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 690px) {
  .xjnumTecken2 {
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjnumTecken2 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 13px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjnumTecken2 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 11px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjnumTecken2 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 11px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width:2303px) {
  .xjnumTecken2 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 11px;
    margin-left: 1vw;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrecepyDescriptionHeader, .xjrecepyDescriptionHeader1 {
    grid-area: e;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecepyDescriptionHeader, .xjrecepyDescriptionHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-area: e;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrecepyDescriptionHeader, .xjrecepyDescriptionHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-area: e;
    font-family: var(--font-family);
    font-size: 22px;
    font-weight: bold;
    margin-top: 4px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecepyDescriptionHeader, .xjrecepyDescriptionHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-area: e;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
    margin-left: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecepyDescriptionHeader, .xjrecepyDescriptionHeader1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-area: e;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
    margin-left: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrecepyDescriptionHeader1 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecepyDescriptionHeader1 {
    font-size: 22px;
    background: white;
    border-top-left-radius: 6px;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrecepyDescriptionHeader1 {
    font-size: 22px;
    background: white;
    border-top-left-radius: 6px;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecepyDescriptionHeader1 {
    font-size: 22px;
    background: white;
    border-top-left-radius: 6px;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecepyDescriptionHeader1 {
    font-size: 22px;
    background: white;
    border-top-left-radius: 6px;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjresizeDescriptionText {
    position: "absolute";
    top: "800px";
    left: "380px";
    font-size: "20px";
    cursor: "pointer";
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjresizeDescriptionText {
    cursor: pointer;
    font-size: 18px;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjresizeDescriptionText {
    cursor: pointer;
    font-size: 22px;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjresizeDescriptionText {
    cursor: pointer;
    font-size: 22px;
    margin-left: 2%;
  }
}
@media only screen and (min-width:2303px) {
  .xjresizeDescriptionText {
    cursor: pointer;
    font-size: 22px;
    margin-left: 2%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjresizeDescriptionText1 {
    position: "absolute";
    top: "800px";
    left: "120px";
    font-size: "20px";
    cursor: "pointer";
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjresizeDescriptionText1 {
    font-size: 26px;
    margin-right: 3%;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjresizeDescriptionText1 {
    font-size: 28px;
    margin-right: 3%;
    width: 90%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjresizeDescriptionText1 {
    font-size: 28px;
    margin-right: 3%;
    width: 90%;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjresizeDescriptionText1 {
    font-size: 28px;
    margin-right: 3%;
    width: 90%;
    cursor: pointer;
  }
}

.xjsearchSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xjsearchSpinner {
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjdeleteRecepy {
    font-size: 22px;
    color: var(--rgbaVal);
    cursor: pointer;
    margin-right: 4%;
    border: none;
    border-color: var(--rgbaVal);
    padding: 7px;
    border-radius: 50%;
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjdeleteRecepy {
    font-size: 26px;
    color: var(--rgbaVal);
    cursor: pointer;
    margin-right: 4%;
    border: none;
    border-color: var(--rgbaVal);
    padding: 9px;
    border-radius: 50%;
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjdeleteRecepy {
    font-size: 26px;
    color: var(--rgbaVal);
    cursor: pointer;
    margin-right: 4%;
    border: none;
    border-color: var(--rgbaVal);
    padding: 9px;
    border-radius: 50%;
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjdeleteRecepy {
    font-size: 26px;
    color: var(--rgbaVal);
    cursor: pointer;
    margin-right: 4%;
    border: none;
    border-color: var(--rgbaVal);
    padding: 9px;
    border-radius: 50%;
    background-color: var(--rgbaVal);
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xjdeleteRecepy {
    font-size: 30px;
    color: var(--rgbaVal);
    cursor: pointer;
    margin-right: 4%;
    border: none;
    border-color: var(--rgbaVal);
    padding: 11px;
    border-radius: 50%;
    background-color: var(--rgbaVal);
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtrashcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 30px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtrashcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    cursor: pointer;
    height: 50px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtrashcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    cursor: pointer;
    height: 50px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtrashcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    cursor: pointer;
    height: 50px;
  }
}
@media only screen and (min-width:2303px) {
  .xjtrashcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    cursor: pointer;
    height: 50px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjanimateddiv50 {
    transform: rotate(0deg);
    animation-name: xjingredientNew50;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjanimateddiv50 {
    transform: rotate(0deg);
    animation-name: xjingredientNew50tablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjanimateddiv50 {
    transform: rotate(0deg);
    animation-name: xjingredientNew50laptop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjanimateddiv50 {
    transform: rotate(0deg);
    animation-name: xjingredientNew50desktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width:2303px) {
  .xjanimateddiv50 {
    transform: rotate(0deg);
    animation-name: xjingredientNew50largedesktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes xjingredientNew50 {
  0% {
    height: 0px;
  }
  100% {
    height: 75px;
  }
}
@keyframes xjingredientNew50tablet {
  0% {
    height: 0px;
  }
  100% {
    height: 140px;
  }
}
@keyframes xjingredientNew50laptop {
  0% {
    height: 0px;
  }
  100% {
    height: 150px;
  }
}
@keyframes xjingredientNew50desktop {
  0% {
    height: 0px;
  }
  100% {
    height: 158px;
  }
}
@keyframes xjingredientNew50largedesktop {
  0% {
    height: 0px;
  }
  100% {
    height: 210px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjanimateddiv60 {
    transform: rotate(0deg);
    animation-name: xjingredientNew60;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjanimateddiv60 {
    transform: rotate(0deg);
    animation-name: xjingredientNew60tablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes xjingredientNew60 {
  0% {
    height: 0px;
  }
  100% {
    height: 105px;
  }
}
@keyframes xjingredientNew60tablet {
  0% {
    height: 0px;
  }
  100% {
    height: 120px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjanimateddiv105 {
    transform: rotate(0deg);
    animation-name: ingredientNew5;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjanimateddiv105 {
    transform: rotate(0deg);
    animation-name: ingredientNew5tablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes ingredientNew5 {
  0% {
    height: 0px;
  }
  100% {
    height: 50px;
  }
}
@keyframes ingredientNew5tablet {
  0% {
    height: 0px;
  }
  100% {
    height: 125px;
  }
}
.xjtabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtabs {
    height: 3vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtabs {
    height: 4vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtabs {
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xjtabs {
    height: 5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 3px 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xjtab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjtab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 3px 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjtab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjtab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjtab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xjtab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}

.xjtab-button2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 231, 231);
  padding: 3px 4px;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 12px;
  border-top-right-radius: 6px;
  border: 1px solid transparent;
  width: 50%;
  margin: 2px;
  border-top-right-radius: 12px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjImageIcon {
    font-size: 15px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjRecipeIcon {
    font-size: 15px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xjRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 10px;
    margin-left: 3px;
    color: black;
  }
  .xjIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 10px;
    margin-left: 3px;
    color: black;
  }
  .xjIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xjIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xjIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width:2303px) {
  .xjIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 16px;
    margin-left: 3px;
    color: black;
  }
  .xjIconText:hover {
    font-weight: bold;
  }
}

.xjimageIconUpload {
  font-size: 22px;
  cursor: pointer;
  color: var(--rgbaVal);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjimageIconUpload {
    font-size: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjimageIconUpload {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjimageIconUpload {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjimageIconUpload {
    font-size: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .xjimageIconUpload {
    font-size: 22px;
  }
}

.xjrecipeDesc {
  font-size: 22px;
  cursor: pointer;
  color: var(--rgbaVal);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjrecipeDesc {
    font-size: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjrecipeDesc {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjrecipeDesc {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjrecipeDesc {
    font-size: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .xjrecipeDesc {
    font-size: 22px;
  }
}

.xjImagesContainerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjimagesListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: auto;
    max-height: 40vh;
    overflow-y: auto;
  }
  .xjImageContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xjImageContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 370px) and (max-width: 380px) {
  .xjImageContainer {
    height: 38vh;
    max-height: 38vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .xjImageContainer {
    height: 38vh;
    max-height: 38vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 414px) and (max-height: 750px) {
  .xjImageContainer {
    height: 40vh;
    max-height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 381px) and (max-width: 385px) {
  .xjImageContainer {
    height: 43vh;
    max-height: 43vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) and (min-height: 820px) {
  .xjImageContainer {
    height: 32vh;
    max-height: 32vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) {
  .xjImageContainer {
    height: 43vh;
    max-height: 43vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 480px) {
  .xjImageContainer {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xjImageContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xjImageContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xjImageContainer {
    height: 59vh;
    max-height: 59vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-top: 1vh;
    height: 78h;
    max-height: 78vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xjImageContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xjImageContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainer::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 1vh;
    height: 78h;
    max-height: 78vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xjImageContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xjImageContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainer::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width:2303px) {
  .xjImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 1vh;
    height: 78h;
    max-height: 78vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xjImageContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xjImageContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainer::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjImageContainer1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
    margin-top: 1vh;
    height: 55svh;
    max-height: 55svh;
    overflow-y: auto;
    cursor: pointer;
    align-items: start;
  }
  .xjImageContainer1::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xjImageContainer1::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainer1::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjImageContainer1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xjImageContainer1::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  .xjImageContainer1::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainer1::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjImageContainer1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xjImageContainer1::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  .xjImageContainer1::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainer1::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width:2303px) {
  .xjImageContainer1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xjImageContainer1::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  .xjImageContainer1::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainer1::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjImageContainerOneItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 1vh;
    max-height: 50vh;
    overflow-x: hidden;
  }
  .xjImageContainerOneItem::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjImageContainerOneItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 40vh;
    margin-top: 1vh;
    overflow-x: hidden;
  }
  .xjImageContainerOneItem::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjImageContainerOneItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 40vh;
    overflow-x: hidden;
    margin-top: 1vh;
  }
  .xjImageContainerOneItem::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
@media only screen and (min-width:2303px) {
  .xjImageContainerOneItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 40vh;
    margin-top: 1vh;
    overflow-x: hidden;
  }
  .xjImageContainerOneItem::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xjImageContainerOneItem::-webkit-scrollbar-thumb {
    background-color: var(--rgbaVal); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}

.xjImageContainerTwoItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 400px;
  height: 400px;
  gap: 4px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjs3imagedata, .xjs3imagedata1 {
    margin-top: 1vh;
    width: 90%;
    height: auto;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjs3imagedata, .xjs3imagedata1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    width: 100%;
    height: auto;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjs3imagedata, .xjs3imagedata1 {
    margin-top: 1vh;
    width: 100%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjs3imagedata, .xjs3imagedata1 {
    margin-top: 1vh;
    width: 100%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjs3imagedata, .xjs3imagedata1 {
    width: 100%;
    height: auto;
    border-radius: 6px;
    margin-top: 1vh;
    cursor: pointer;
  }
}

.xjs3imagedata1 {
  border: 2px solid rgb(229, 64, 68);
  cursor: pointer;
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjs3imagedataFor2, .xjs3imagedata1For2 {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    width: 410px;
    height: auto;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjs3imagedataFor2, .xjs3imagedata1For2 {
    margin-top: 2vh;
    width: 100%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjs3imagedataFor2, .xjs3imagedata1For2 {
    margin-top: 2vh;
    width: 100%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjs3imagedataFor2, .xjs3imagedata1For2 {
    width: 100%;
    height: auto;
    border-radius: 6px;
    margin-top: 2vh;
    cursor: pointer;
  }
}

.xjs3imagedata1For2 {
  border: 5px solid rgb(229, 64, 68);
  cursor: pointer;
}

.xjdataNotFoundWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjs3imagedataNotFound {
    margin-top: 1vh;
    width: 90%;
    height: auto;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjs3imagedataNotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    width: 80%;
    height: auto;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjs3imagedataNotFound {
    margin-top: 2vh;
    width: 80%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjs3imagedataNotFound {
    margin-top: 2vh;
    width: 80%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xjs3imagedataNotFound {
    width: 80%;
    height: auto;
    border-radius: 6px;
    margin-top: 2vh;
    cursor: pointer;
  }
}

.xjgenerateImageTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.xjgenerateImageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
}

.xjimageUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.xjimageUploadWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.xjinputimageUpload {
  display: none;
}

.xjfileLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 5px;
  height: 25px;
  width: 120px;
  background-color: var(--rgbaVal);
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
}

.xjbuttonUploadImageWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 50%;
}

.xjbuttonUploadImage, .xjbuttonUploadImage1 {
  margin-left: 5px;
  width: 120px;
  height: 30px;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  opacity: 0.7;
}

.xjbuttonUploadImage1 {
  background-color: rgb(229, 64, 68);
  color: white;
  cursor: pointer;
}

.xjfileName {
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 3px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.xjimageUploadMessage {
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 7px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjimageUploadMessage {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjimageUploadMessage {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjimageUploadMessage {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjimageUploadMessage {
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjimageUploadMessage {
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width:2303px) {
  .xjimageinput {
    width: 70%;
    height: 4.7svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 24px;
    resize: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjgenerateImageButton, .xjgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjgenerateImageButton, .xjgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjgenerateImageButton, .xjgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjgenerateImageButton, .xjgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xjgenerateImageButton, .xjgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}

.xjgenerateImageButton1 {
  background-color: rgb(229, 64, 68);
  color: white;
  cursor: pointer;
}

.xjimageInformationTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.xjimageInformationText {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjimageInformationText {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjimageInformationText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjimageInformationText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjimageInformationText {
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjimageInformationText {
    font-size: 14px;
  }
}

.bsLivePubslishWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  background-color: transparent;
}

.xjLiveWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 65vh;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 8px;
  border: 1px solid rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveWrapper {
    width: 99%;
    height: 84vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .xjLiveWrapper {
    height: 83vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 371px) {
  .xjLiveWrapper {
    height: 60vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveWrapper {
    width: 100%;
    height: 68.6vh;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) and (max-width: 1430px) {
  .xjLiveWrapper {
    height: 58.6vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1431px) and (max-width: 1600px) {
  .xjLiveWrapper {
    height: 60.3vh;
  }
}

.xjLiveHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 5vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 32px;
  font-weight: bold;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveHeader {
    font-size: 14px;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveHeader {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveHeader {
    font-size: 16px;
  }
}

.xjLiveCreatorStatusWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .xjLiveCreatorStatusWrapper {
    height: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveCreatorStatusWrapper {
    margin-left: 3vw;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveCreatorStatusWrapper {
    margin-left: 3vw;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveCreatorStatusWrapper {
    margin-left: 4vw;
    width: 90%;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveCreatorStatusWrapper {
    margin-left: 5vw;
    width: 90%;
  }
}

.xjLiveCreatorStatusHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
  height: 3vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveCreatorStatusHeader {
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveCreatorStatusHeader {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveCreatorStatusHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveCreatorStatusHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveCreatorStatusHeader {
    font-size: 16px;
  }
}

.xjLiveCreatorStatusText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveCreatorStatusText {
    font-size: 10px;
  }
}

.xjLiveCreatorStatusText1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
  width: 35%;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveCreatorStatusText1 {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveCreatorStatusText1 {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveCreatorStatusText1 {
    font-size: 16px;
  }
}

.xjLiveCreatorStatusText2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
  width: 35%;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveCreatorStatusText2 {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveCreatorStatusText2 {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveCreatorStatusText2 {
    font-size: 16px;
  }
}

.xjLiveCreatorStatusText3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
  width: 25%;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveCreatorStatusText3 {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveCreatorStatusText3 {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveCreatorStatusText3 {
    font-size: 16px;
  }
}

.xjLiveRecepyHeaderToPublish {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: 2vh;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 200;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveRecepyHeaderToPublish {
    font-size: 12px;
    margin-top: unset;
    margin-bottom: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveRecepyHeaderToPublish {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveRecepyHeaderToPublish {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveRecepyHeaderToPublish {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveRecepyHeaderToPublish {
    font-size: 16px;
  }
}

.xjLiveDoneIcon {
  font-size: 22px;
  cursor: pointer;
  color: green;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveDoneIcon {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveDoneIcon {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveDoneIcon {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveDoneIcon {
    font-size: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveDoneIcon {
    font-size: 22px;
  }
}

.xjLiveRecipeToPublishWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 30vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 8px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveRecipeToPublishWrapper {
    width: 100%;
    height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .xjLiveRecipeToPublishWrapper {
    height: 30vh;
    border-radius: unset;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 371px) {
  .xjLiveRecipeToPublishWrapper {
    height: 26vh;
    border-radius: unset;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveRecipeToPublishWrapper {
    width: 95%;
    height: 30vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveRecipeToPublishWrapper {
    width: 80%;
    height: 25vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveRecipeToPublishWrapper {
    width: 80%;
    height: 25vh;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveRecipeToPublishWrapper {
    width: 70%;
    height: 35vh;
  }
}

.xjLiveInputPriceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6vh;
  margin-top: 2vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveInputPriceWrapper {
    height: 3.5vh;
    margin-top: unset;
    align-items: unset;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveInputPriceWrapper {
    height: 4vh;
    width: 70%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveInputPriceWrapper {
    height: 4vh;
    width: 70%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveInputPriceWrapper {
    height: 4vh;
    width: 70%;
  }
}

.xjLiveInputPrice {
  width: 50%;
  height: 20px;
  border: 1px solid rgb(223, 212, 212) !important;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 200;
  resize: none;
  margin-bottom: 2vh;
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveInputPrice {
    font-size: 12px;
    margin-bottom: 1vh;
    height: 2.5vh;
    width: 50%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveInputPrice {
    font-size: 16px;
    margin-bottom: 1vh;
    width: 190px;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveInputPrice {
    font-size: 16px;
    margin-bottom: 1vh;
    width: 51.5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveInputPrice {
    font-size: 16px;
    margin-bottom: 1vh;
    width: 42%;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveInputPrice {
    font-size: 16px;
    margin-bottom: 1vh;
    height: 30px;
  }
}

.xjLiveInputPriceText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveInputPriceText {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 25%;
    height: 2.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveInputPriceText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 40%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveInputPriceText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 40%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveInputPriceText {
    font-size: 16px;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveInputPriceText {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 30%;
  }
}

.xjLiveInputDifficultyText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveInputDifficultyText {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveInputDifficultyText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveInputDifficultyText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 40%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveInputDifficultyText {
    font-size: 16px;
    width: 21%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveInputDifficultyText {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
  }
}

.xjLiveSelectDifficultyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveSelectDifficultyWrapper {
    height: 6vh;
    margin-top: unset;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveSelectDifficultyWrapper {
    height: 3vh;
    margin-top: unset;
    width: 70%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveSelectDifficultyWrapper {
    height: 3vh;
    margin-top: unset;
    width: 70%;
  }
}

.xjLiveInputTimeText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveInputTimeText {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveInputTimeText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 28%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveInputTimeText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveInputTimeText {
    font-size: 16px;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveInputTimeText {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
  }
}

.xjLiveSelectDifficulty {
  width: 52%;
  height: 20px;
  border: 1px solid rgb(223, 212, 212) !important;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 200;
  resize: none;
  margin-bottom: 1vh;
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveSelectDifficulty {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveSelectDifficulty {
    font-size: 16px;
    width: 203px;
    height: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveSelectDifficulty {
    font-size: 14px;
    height: 25px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveSelectDifficulty {
    font-size: 16px;
    width: 30%;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveSelectDifficulty {
    font-size: 16px;
    height: 30px;
    width: 50%;
  }
}

.xjLiveInputTimeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  margin-top: 2vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveInputTimeWrapper {
    height: 3vh;
    margin-top: unset;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveInputTimeWrapper {
    height: 4vh;
    margin-top: unset;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveInputTimeWrapper {
    height: 4vh;
    margin-top: unset;
    width: 70%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveInputTimeWrapper {
    height: 4vh;
    margin-top: unset;
    width: 70%;
  }
}

.xjLivePublishButton {
  width: 72%;
  height: 35px;
  background-color: black;
  color: white;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}
.xjLivePublishButton:hover {
  background-color: black;
  opacity: 0.8;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLivePublishButton {
    font-size: 12px;
    width: 78%;
    height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLivePublishButton {
    width: 64%;
    font-size: 14px;
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLivePublishButton {
    width: 73%;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLivePublishButton {
    width: 72%;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLivePublishButton {
    width: 81%;
    font-size: 14px;
  }
}

.xjLiveUnpublishWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveUnpublishWrapper {
    width: 77%;
    height: 5vh;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveUnpublishWrapper {
    width: 64%;
    height: 5vh;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveUnpublishWrapper {
    width: 72%;
    height: 4vh;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveUnpublishWrapper {
    width: 72%;
    height: 5vh;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveUnpublishWrapper {
    width: 80%;
    height: 5vh;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
  }
}

.xjLiveUsersHasAccesstext {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 65%;
  height: 2vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 200;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveUsersHasAccesstext {
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveUsersHasAccesstext {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveUsersHasAccesstext {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveUsersHasAccesstext {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveUsersHasAccesstext {
    font-size: 16px;
  }
}

.xjLiveUnpublishButton, .xjLiveUnpublishButton1 {
  width: 40%;
  height: 25px;
  background-color: red;
  color: white;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}
.xjLiveUnpublishButton:hover, .xjLiveUnpublishButton1:hover {
  background-color: red;
  opacity: 0.8;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveUnpublishButton, .xjLiveUnpublishButton1 {
    width: 200px;
    font-size: 14px;
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveUnpublishButton, .xjLiveUnpublishButton1 {
    width: 44%;
    font-size: 14px;
  }
}

.xjLiveUnpublishButton1 {
  background-color: rgb(228, 130, 130);
  cursor: pointer;
}

.xjLiveCloseIcon {
  font-size: 22px;
  cursor: pointer;
  color: red;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveCloseIcon {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveCloseIcon {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveCloseIcon {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveCloseIcon {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveCloseIcon {
    font-size: 26px;
  }
}

.xjLiveRecepyHeaderToPublishText {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 200;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveRecepyHeaderToPublishText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveRecepyHeaderToPublishText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveRecepyHeaderToPublishText {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveRecepyHeaderToPublishText {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveRecepyHeaderToPublishText {
    font-size: 20px;
  }
}

.xjLiveRecepyHeaderToPublishText1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: 2vh;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 200;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveRecepyHeaderToPublishText1 {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjLiveRecepyHeaderToPublishText1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjLiveRecepyHeaderToPublishText1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjLiveRecepyHeaderToPublishText1 {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xjLiveRecepyHeaderToPublishText1 {
    font-size: 16px;
  }
}

.xjInfoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjInfoText {
    font-size: 10px;
    height: 0.1vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .xjInfoText {
    font-size: 10px;
    height: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xjInfoText {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xjInfoText {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xjInfoText {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .xjInfoText {
    font-size: 20px;
  }
}

.xjLiveTranslateWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xjLiveTranslateHeaderText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  padding: 0.25rem;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveTranslateHeaderText {
    padding: 0.5rem;
  }
}

.xjLiveTranslateSelect {
  width: 50%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 14px;
  resize: none;
  color: black;
  background-color: white;
  margin-top: 1px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveTranslateSelect {
    width: 80%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1026px) {
  .xjLiveTranslateSelect {
    height: 20px;
    width: 60%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1026px) {
  .xjLiveTranslateSelect {
    height: 20px;
    width: 58%;
  }
}

.xjLiveRecipeToPublishWrapper2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4px;
  align-items: center;
  width: 60%;
  height: 4.5vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveRecipeToPublishWrapper2 {
    width: 80%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1026px) {
  .xjLiveRecipeToPublishWrapper2 {
    height: 3vh;
    width: 58%;
  }
}

.xjLiveRecepyToPublishButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 20px;
  margin-left: 2px;
  background-color: var(--rgbaVal2);
  color: white;
  outline: none;
  font-family: var(--font-family);
  font-size: 20px;
  border-radius: 6px;
  border: 1px solid rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLiveRecepyToPublishButton {
    width: 35px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .xjLiveRecepyToPublishButton {
    width: 39px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .xjLiveRecepyToPublishButton {
    width: 44px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1025px) {
  .xjLiveRecepyToPublishButton {
    height: 18px;
    width: 42px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1026px) {
  .xjLiveRecepyToPublishButton {
    height: 15px;
    width: 42px;
    font-size: 16px;
  }
}

.xjLivePublishLanguageButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 58.4%;
  height: 22px;
  color: white;
  background-color: black;
  border-radius: 4px;
  margin-top: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 11px;
  cursor: pointer;
}
.xjLivePublishLanguageButton:hover {
  background-color: black;
  opacity: 0.8;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xjLivePublishLanguageButton {
    width: 80%;
    height: 25px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1025px) {
  .xjLivePublishLanguageButton {
    height: 20px;
    width: 60.5%;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1026px) {
  .xjLivePublishLanguageButton {
    height: 20px;
    width: 58.5%;
    font-size: 12px;
  }
}

.xjLiveTranslateGlobeIcon {
  width: 15px;
  height: 15px;
  color: lightgrey;
  margin-right: 5px;
}/*# sourceMappingURL=SearchRecepyNew3.css.map */