:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xztotalArea {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "b";
    width: 100%;
    height: 90vh;
    -o-object-fit: cover;
       object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xztotalArea {
    display: grid;
    grid-template-columns: 50% 20% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 95vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xztotalArea {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 95vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xztotalArea {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 95vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xztotalArea {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 85vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xztotalArea1 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "b";
    width: 100%;
    height: 85vh;
    -o-object-fit: cover;
       object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xztotalArea1 {
    display: grid;
    grid-template-columns: 50% 20% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 95vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xztotalArea1 {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 95vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xztotalArea1 {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 95vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xztotalArea1 {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-areas: "a b c";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: 95vh;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xzareaB {
  grid-area: a;
  border: none;
  border-color: rgb(223, 212, 212);
  width: 100%;
  height: 60vh;
  background-color: var(--val);
  margin-top: 15vh;
  border-radius: 6px;
  background-color: transparent;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xzareaB {
    height: 63vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 600px) and (max-height: 650px) {
  .xzareaB {
    height: 59vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 650px) and (max-height: 700px) {
  .xzareaB {
    height: 55vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 375px) and (min-height: 500px) and (max-height: 630px) {
  .xzareaB {
    height: 68vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 375px) and (min-height: 631px) and (max-height: 800px) {
  .xzareaB {
    height: 58vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 384px) and (min-height: 640px) and (max-height: 800px) {
  .xzareaB {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 390px) and (max-width: 390px) and (min-height: 640px) and (max-height: 800px) {
  .xzareaB {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) and (max-width: 393px) and (min-height: 640px) and (max-height: 800px) {
  .xzareaB {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (min-height: 640px) and (max-height: 700px) {
  .xzareaB {
    height: 51vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (min-height: 701px) and (max-height: 740px) {
  .xzareaB {
    height: 51vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (min-height: 741px) {
  .xzareaB {
    height: 47vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) and (max-width: 414px) and (min-height: 640px) and (max-height: 800px) {
  .xzareaB {
    height: 52vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) and (max-width: 428px) and (min-height: 640px) and (max-height: 800px) {
  .xzareaB {
    height: 51vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 430px) and (min-height: 640px) and (max-height: 800px) {
  .xzareaB {
    height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 431px) and (min-height: 640px) and (max-height: 800px) {
  .xzareaB {
    height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 431px) and (min-height: 800px) and (max-height: 900px) {
  .xzareaB {
    height: 46vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzareaB {
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xzareaB {
    width: 42%;
    height: 37vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1366px) {
  .xzareaB {
    height: 40vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzareaB {
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzareaB {
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xzareaB {
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.xzloginTextEnglish {
  font-family: "Lobster";
  font-size: 30px;
  white-space: nowrap;
  text-align: center;
  color: rgb(225, 176, 68);
  margin-top: 5px;
}
@media only screen and (min-width:2303px) {
  .xzloginTextEnglish {
    font-size: 40px;
  }
}

.xzloginTextSvenska {
  font-family: "Lobster";
  font-size: 30px;
  white-space: nowrap;
  text-align: center;
  color: rgb(225, 176, 68);
}
@media only screen and (min-width:2303px) {
  .xzloginTextSvenska {
    font-size: 40px;
  }
}

.xzloginArea {
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzloginArea {
    width: 100%;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzloginArea {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzloginArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xzloginArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.xzloginBox {
  width: 100%;
  height: 55vh;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xzloginBox {
    height: 65vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 600px) and (max-height: 650px) {
  .xzloginBox {
    height: 59vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 650px) and (max-height: 700px) {
  .xzloginBox {
    height: 55vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 375px) and (min-height: 500px) and (max-height: 630px) {
  .xzloginBox {
    height: 68vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 375px) and (min-height: 631px) and (max-height: 800px) {
  .xzloginBox {
    height: 58vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 384px) and (min-height: 640px) and (max-height: 800px) {
  .xzloginBox {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 390px) and (max-width: 390px) and (min-height: 640px) and (max-height: 800px) {
  .xzloginBox {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) and (max-width: 393px) and (min-height: 640px) and (max-height: 800px) {
  .xzloginBox {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (min-height: 640px) and (max-height: 700px) {
  .xzloginBox {
    height: 52vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (min-height: 701px) and (max-height: 740px) {
  .xzloginBox {
    height: 52vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 413px) and (min-height: 741px) {
  .xzloginBox {
    height: 48vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) and (max-width: 414px) and (min-height: 640px) and (max-height: 800px) {
  .xzloginBox {
    height: 52vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) and (max-width: 428px) and (min-height: 640px) and (max-height: 800px) {
  .xzloginBox {
    height: 51vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 430px) and (min-height: 640px) and (max-height: 800px) {
  .xzloginBox {
    height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 431px) and (min-height: 640px) and (max-height: 800px) {
  .xzloginBox {
    height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 431px) and (min-height: 800px) and (max-height: 900px) {
  .xzloginBox {
    height: 46vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzloginBox {
    width: 350px;
    height: 62vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xzloginBox {
    height: 37vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1366px) {
  .xzloginBox {
    height: 43vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzloginBox {
    width: 400px;
    height: 42vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzloginBox {
    width: 400px;
    height: 43vh;
  }
}
@media only screen and (min-width:2303px) {
  .xzloginBox {
    width: 450px;
    height: 40vh;
  }
}

.xzloginText {
  font-family: var(--font-family);
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: rgb(225, 176, 68);
}

.xzboxTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xzloginTextJAP {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 24px;
  color: rgb(225, 176, 68);
}
@media only screen and (min-width:2303px) {
  .xzloginTextJAP {
    font-size: 40px;
  }
}

.boxgoogleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 40px;
  background-color: white;
  outline: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.boxgoogleWrapper:hover {
  padding: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .boxgoogleWrapper {
    width: 90%;
  }
}

.xzboxSignup {
  text-align: center;
  margin-top: 5px;
}

.xzboxBottom {
  margin-bottom: 10px;
}

.xzloginFieldset {
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 4px;
  margin-bottom: 10px;
  width: 82%;
  height: 40px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzloginFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 83%;
    height: 5.4vh;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzloginFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 83%;
    height: 4vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzloginFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 83%;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xzloginFieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 83%;
    height: 4vh;
  }
}

.xzloginLegend {
  font-family: var(--font-family);
  font-size: 12px;
}

.xzloginEmail, .xzloginPass {
  width: 90%;
  height: 30px;
  outline: none;
  border: none;
  font-family: var(--font-family);
  font-size: 14px;
  background-color: white;
}

.xzloginPassJAP {
  width: 90%;
  height: 30px;
  outline: none;
  border: none;
  font-family: var(--font-family);
  font-size: 14px;
}

.xzloginEmail:-webkit-autofill,
.xzloginPass:-webkit-autofill,
.xzloginPassJAP:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black;
}

.xzloginButton, .xzloginButtonNo {
  font-family: var(--font-family);
  font-size: 16px;
  width: 90%;
  height: 35px;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: rgb(225, 176, 68);
  color: white;
  margin-left: 1px;
}

.xzloginButtonNo {
  cursor: not-allowed;
}

.xzloginButtonForce {
  font-family: var(--font-family);
  font-size: 12px;
  width: 90%;
  height: 25px;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: rgb(225, 176, 68);
  color: white;
  margin-left: 1px;
  margin-top: 0.5vh;
}

.xzsingUpButton {
  width: 92%;
  height: 45px;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  border: none;
  background-color: white;
}

.xzunderlinedText {
  text-decoration: underline;
  color: rgb(225, 176, 68);
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzunderlinedText {
    font-size: 14px;
  }
}

.xzlogintext {
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
}

.xzloginSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: scale(1.5);
}

.xzcountryFlagBox {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.xzloginflaggb, .xzloginflagse, .xzloginflagJapan {
  width: 30px;
  height: 20px;
  margin-left: 3px;
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .xzloginflaggb, .xzloginflagse, .xzloginflagJapan {
    width: 40px;
    height: 30px;
  }
}

.xzloginflagJapan {
  border: 1px solid;
  border-color: rgb(223, 212, 212);
}

.xzloginflagJapan1 {
  width: 30px;
  height: 20px;
  margin-left: 3px;
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .xzloginflagJapan1 {
    width: 40px;
    height: 30px;
  }
}

.xzlangaugesAvailabletext {
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 11px;
  color: rgb(225, 176, 68);
  margin-top: 10px;
}

.xzloginButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xzlineloginboxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xzlineloginButton {
  width: 240px;
  height: 40px;
  background-color: #06C755;
  border: none;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzlineloginButton {
    width: 240px;
    height: 40px;
    background-color: #06C755;
    border: none;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
}

.xzlinebuttonImage {
  margin-right: 8px; /* Adjust as needed */
  width: 37px; /* Adjust as needed */
  height: 37px; /* Adjust as needed */
}

.xzlinebuttonText {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: white;
}

.xzlineloginbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 6px;
  margin-top: 3px;
  width: 100%;
}

.xzbackgroundImageLogin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
  overflow: hidden;
}

.xzforgotPasswordTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  color: rgb(225, 176, 68);
  cursor: pointer;
}

.xzforogotPasswordText {
  font-family: var(--font-family);
  font-size: 12px;
  color: rgb(225, 176, 68);
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xzinputEmailArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 500px) {
  .xzinputEmailArea {
    margin-top: 3px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 384px) and (min-height: 700px) {
  .xzinputEmailArea {
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzinputEmailArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzinputEmailArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzinputEmailArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xzinputEmailArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin-top: 2vh;
  }
}

.xzresetInputEmail {
  width: 65%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: white;
  outline: none;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xzsendEmailWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom: 5px;
    border-radius: 6px;
    height: 10vh;
    padding: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzsendEmailWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom: 5px;
    border-radius: 6px;
    height: 7vh;
    padding: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzsendEmailWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom: 5px;
    border-radius: 6px;
    height: 10vh;
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzsendEmailWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom: 5px;
    border-radius: 6px;
    height: 6vh;
    padding: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xzsendEmailWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    padding-bottom: 5px;
    border-radius: 6px;
    height: 6vh;
    padding: 5px;
  }
}

.xzSendButton {
  width: 28%;
  height: 30px;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: rgb(225, 176, 68);
  color: white;
  margin-left: 3px;
}

.xzSendButton:hover {
  background-color: rgb(229, 64, 68);
  color: white;
}

.xzSendButton:active {
  background-color: rgb(225, 176, 68);
  color: white;
}

.xzresetloginFieldset {
  border: 1px dotted;
  border-color: rgb(223, 212, 212);
  border-radius: 4px;
  width: 97%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.xzresetloginLegend {
  font-family: var(--font-family);
  font-size: 12px;
  color: rgb(225, 176, 68);
}

.xzareaC {
  grid-area: c;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5vh;
}

.xzAreaCWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzAreaCWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 47vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzAreaCWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzAreaCWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
  }
}
@media only screen and (min-width:2303px) {
  .xzAreaCWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
  }
}

.xzLatestNewsArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 3px dotted grey;
  padding: 7px;
  border-radius: 12px;
}
.xzLatestNewsArea::-webkit-scrollbar {
  display: none;
}

.xzlatestNewsHeader {
  font-family: var(--font-family);
  font-size: 26px;
  color: rgb(225, 176, 68);
  margin-top: 5px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzlatestNewsHeader {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzlatestNewsHeader {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzlatestNewsHeader {
    font-size: 34px;
  }
}
@media only screen and (min-width:2303px) {
  .xzlatestNewsHeader {
    font-size: 36px;
  }
}

.xzlatestNewsBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzlatestNewsTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: rgb(225, 176, 68);
    margin-top: 5px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzlatestNewsTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: rgb(225, 176, 68);
    margin-top: 5px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzlatestNewsTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: rgb(225, 176, 68);
    margin-top: 5px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width:2303px) {
  .xzlatestNewsTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: rgb(225, 176, 68);
    margin-top: 5px;
    margin-left: 1vw;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzlatestNewsText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: white;
    margin-top: 5px;
    width: 100%;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzlatestNewsText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: white;
    margin-top: 5px;
    width: 100%;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzlatestNewsText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 22px;
    color: white;
    margin-top: 5px;
    width: 100%;
    margin-left: 1vw;
  }
}
@media only screen and (min-width:2303px) {
  .xzlatestNewsText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 22px;
    color: white;
    margin-top: 5px;
    width: 100%;
    margin-left: 1vw;
  }
}

.xzlanguageArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.5vh;
  margin-bottom: 10px;
}

.xzfooterArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
  margin-top: 5vh;
  border-radius: 12px;
  background-color: transparent;
  color: white;
  font-size: 22px;
  font-family: "Lobster";
}

.xzreturnArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
  font-size: 24px;
  font-family: "Lobster";
  color: white;
}

.xzfooterAreaWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  gap: 15px;
  font-size: 20px;
  font-family: "Lobster";
}
.footer-links li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}
.footer-links li a:hover {
  color: #1e90ff;
}

.xzmidWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.xzSignUpWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  background-color: transparent;
  border-radius: 6px;
}

.xzSignUpTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 40vh;
  background-color: transparent;
  border-radius: 6px;
}

.xzSignUpText {
  font-family: var(--font-family);
  font-size: 15px;
  color: black;
  margin-bottom: 5px;
}

.xzSignUpWithBakesoftText {
  font-family: "Lobster";
  font-size: 15px;
  color: black;
  margin-bottom: 5px;
  margin-top: 5px;
}

.xzSignUpWithGoogleDetailsTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 40vh;
  background-color: transparent;
  border-radius: 6px;
  border: 3px dotted white;
  background-color: white;
}

.xzSignUpWithGoogleInput {
  width: 90%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: white;
  margin-top: 7px;
  outline: none;
}

.xzSignUpWithGoogleDetailsButton {
  width: 91%;
  height: 30px;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: rgb(225, 176, 68);
  color: white;
  margin-top: 5px;
}

.xzSignUpWithGoogleImage {
  width: 60px;
  height: 60px;
  margin-left: 3px;
  border-radius: 50%;
  margin-top: 0.2vh;
}

.xzSignUpWithBakesoftTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 81%;
}

.xzSignUpWithBakesoftButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 91%;
  height: 30px;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: rgb(225, 176, 68);
  color: white;
  margin-top: 5px;
  font-size: 16px;
  font-family: "Lobster";
}

.klarnaTopHr {
  width: 70%;
  margin-top: 5px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  margin-bottom: 5px;
  margin-top: 5px;
}

.klarnamessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  border-radius: 3px;
}

.xzMobileWelcomeMessageTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.xzMobileWelcomeMessage {
  font-family: "Lobster";
  font-size: 22px;
  color: white;
  margin-bottom: 5px;
  margin-top: 5px;
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 220px;
    background-color: transparent;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xzPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 240px;
    background-color: transparent;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 240px;
    background-color: transparent;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xzPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 240px;
    background-color: transparent;
    border-radius: 6px;
  }
}

.xzPaymentChannelKlarna {
  height: 80px;
  width: 250px;
}

.xzPaymentChannelPayPal {
  height: 1500px;
  width: 200px;
}

.xzfbWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2px;
}

.xzcontinueGoogleButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 40px;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  outline: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.xzcontinueGoogleButton:hover {
  padding: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xzcontinueGoogleButton {
    width: 90%;
  }
}

.xzcontinueFacebookButton {
  background-color: #4267B2;
  color: white;
  width: 90%;
  margin-top: 5px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.xzcontinueFacebookButton:hover {
  border: 2px solid #4267B2;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xzcontinueFacebookButton {
    width: 90%;
  }
}

.xzcontinueLineButton {
  background-color: #06C755;
  color: white;
  font-size: 16px;
  width: 90%;
  margin-top: 5px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.xzcontinueLineButton:hover {
  border: 2px solid #06C755;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xzcontinueLineButton {
    width: 90%;
  }
}

.xzsignUpPointerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 30vh;
  margin-top: 10vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xzsignUpPointerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.xzSignUpPointerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 50px;
  border-radius: 6px;
  color: white;
  font-size: 18px;
  font-family: var(--font-family);
  cursor: pointer;
  box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(9, 9, 121) 35%, rgb(0, 212, 255) 100%);
  background-size: 200% 200%;
  animation: gradientShift 3s linear infinite alternate;
  transition: all 0.3s ease;
}
.xzSignUpPointerButton:hover {
  border: 2px solid white;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xzSignUpPointerButton {
    width: 50%;
    height: 60px;
  }
}
@media only screen and (min-width:2303px) {
  .xzSignUpPointerButton {
    width: 60%;
    height: 60px;
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.xzfauserplusIcon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .xzfauserplusIcon {
    width: 40px;
    height: 30px;
  }
}/*# sourceMappingURL=LoginPage1.css.map */