@import '../style/MainStyles.scss';/* styles.css */


.xytotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
    //  width:100%;
}
@include gadgets-to(tablet) {

    display:flex;
    flex-direction:column;
   // justify-content:center;
     align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}

.xywindowArea {
    @include gadgets-to(mobile) {
        display: grid;
       grid-template-rows: 5% 19% 50% 10%;
       // grid-template-rows:auto auto auto auto auto;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d";
        width: 100%;
        height:auto;
        object-fit: cover;

   
    }
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:35% 37% 28%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b c d";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;
        

    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:35% 37% 28%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b c d";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;
        
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:35% 37% 28%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b c d";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;


    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:35% 37% 28%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b c d";
        column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;
    }
}

.xyareaA {
    @include gadgets-to(mobile) {
    grid-area: b;
   // border: 1px solid;
    border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height:26vh;
    background-color:white;
   // margin-top:4vh;
    border-radius:map-get($generalStyles,sixBorderRadius); 
}
@include gadgets-to(tablet) {
    grid-area: b;
    border: 1px solid;
   // border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height: 80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(laptop) {
    grid-area: b;
    border: 1px solid;
    //border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height: 80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(desktop) {
    grid-area: b;
    border: 1px solid;
   // border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height: 80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    grid-area: b;
    border: 1px solid;
   // border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height: 80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
}


.xyareaB {
    grid-area: c;
   //border: 1px solid;
   //border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height:80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
     
    @include gadgets-to(mobile) {

        @media(max-width:360px){

            @media(max-height:650px){
                height:100vh;
            }
            }
        
        }

        @include gadgets-to(mobile) {

            @media(min-width:375px) and (max-width:380px){

                @media(max-height:670px){
                    height:105vh;
                }
                @media(min-height:671px) and (max-height:920px){
                    height:74vh;
                }
                
    
       
            }
            @media(min-width:384px) and (max-width:390px){

                @media(min-height:671px) and (max-height:920px){
                    height:68vh;
                }
            
            }



        @media(min-width:412px) and (max-width:427px){

            @media(max-height:650px){
                height:95vh;
            }
            @media(min-height:651px) and (max-height:730px){
                height:72vh;
            }
            @media(min-height:731px) and (max-height:800px){
                height:55vh;
            }
            @media(min-height:801px) and (max-height:930px){
                height:56vh;
            }
        
        }

        @media(min-width:428px){
            @media(min-height:731px) and (max-height:800px){
                height:53vh;
            }
            @media(min-height:801px){
                height:60vh;
            }
        
        }


    }@include gadgets-to(tablet) {
        height: 80vh;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
          //border:none;
    }
    @include gadgets-to(laptop) {
        height: 80vh;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
          //border:none;
    }
    @include gadgets-to(desktop) {
        height: 80vh;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
          //border:none;
    }
    @include gadgets-to(largeDesktop) {
        height: 80vh;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
          //border:none;
    }

}

.xyareaC {
    @include gadgets-to(mobile) {
    grid-area: d;
   // border: 1px solid;
    border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height:30vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(tablet) {
    grid-area: d;
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height: 80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(laptop) {
    grid-area: d;
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height: 80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(desktop) {
    grid-area: d;
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height:80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    grid-area: d;
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    width: 99.5%;
    height:80vh;
    background-color:white;
    margin-top:5px;
    border-radius: map-get($generalStyles,sixBorderRadius);
}
}


.xyheaderArea{
    @include gadgets-to(mobile) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        width:99%;
        margin-top:2px;
        height:35px;
    }
    @include gadgets-to(tablet) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:40px;
        @media(orientation:portrait){
            height:45px;
        }
    }
    @include gadgets-to(laptop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:40px;
    }
    @include gadgets-to(desktop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:60px;
    }
    @include gadgets-to(largeDesktop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:60px;
    }

}


.xycompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color:map-get($generalStyles,transparentChangeableMenu5);
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles,transparentChangeableMenu5);
    }
}

.xycompNameJAP {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 24px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 38px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 44px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 46px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
}

.xypageHeaderMenyWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;


}

.xypageHeaderMenu {
  font-family: var(--font-family);
    font-size: 12px;
    color: map-get($generalStyles,colorblack);
  //  margin-left:16%;
    margin-top: 10px;
    cursor: pointer;
    width:73%;
    @include gadgets-to(tablet) {
        width: 87%;
    }
    @include gadgets-to(laptop) {
        width: 89%;
    }
    @include gadgets-to(desktop) {
        width: 87%;
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        width: 87%;
        font-size: 18px;
    }

    
}

.xyareaTopWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    @include gadgets-to(tablet) {
        max-height:80vh;
    }
}
.xyareaTop {
    max-height:20vh;
    overflow-y:scroll;
    width: 75%;

    @include gadgets-to(tablet) {
        width: 90%;
        height:auto;
        max-height:80vh;
        overflow:scroll;
        @media(max-width:1120px){
            height:72vh;
        }
        @media(min-width:1194px) and (max-width:1200px){
            height:70vh;
        }
        @media(min-width:1360px){
            height:68vh;
        }
    }
    @include gadgets-to(laptop) {
        width: 90%;
        height:auto;
        max-height:80vh;
        overflow:scroll;
    }
    @include gadgets-to(desktop) {
        width: 90%;
        height:auto;
        max-height:75vh;
        overflow:scroll;
    }
    @include gadgets-to(largeDesktop) {
        width: 90%;
        height:auto;
        max-height:80vh;
        overflow:scroll;
}
}




.xyareaTopData{
    width:100%;
    display:flex;
    flex-direction:row;

    

}
    

.xypageId {
    width:30%;
    height:25px;
   // border:none;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-size: 14px;
  font-family: var(--font-family);
    text-align: center;
    color: map-get($generalStyles,colorblack);
    background-color:map-get($generalStyles, transparentChangeableMenu5);
    margin-top:5px;
    padding: 5px;
    @include gadgets-to(tablet) {
        width: 40%;
    }
    @include gadgets-to(laptop) {
        width: 40%;
    }
    @include gadgets-to(desktop) {
        height:35px;
    }
    @include gadgets-to(largeDesktop) {
        height:45px;
        font-size: 20px;
    }
}

.xypageId1 {
    @extend .xypageId;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
}

.xypageBenaemn {
    width:calc(70% - 2px);
    height: 25px;
   border:none;
   // border:1px solid;
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-size: 14px;
  font-family: var(--font-family);
    text-align: center;
    color: map-get($generalStyles,colorblack);
    background-color: var(--rgbaVal);
    margin-left:2px;
    margin-top: 5px;
    padding: 5px;
    cursor: pointer;
    // elipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    @include gadgets-to(tablet) {
     //
    }
    @include gadgets-to(laptop) {
      //
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:45px;
        font-size: 20px;
    }


}

.xypageBenaemn1 {
    @extend .xypageBenaemn;
    background-color:var(--rgbaVal2);
}

.xypageBenaemn2 {
    @extend .xypageBenaemn;
 //   border: map-get($generalStyles,prefferedBorderSettingStyle2);
}



.xyareabTop {
  width:100%;
}


.xypageVal {
    font-family: map-get($generalStyles, 'fontFamily');
    font-size: 24px;
    color: map-get($generalStyles, 'transparentChangeableMenu5');
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    @include gadgets-to(desktop) {
        font-size: 36px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xybackgroundShadeTop {
display:flex;
flex-direction:column;
align-items:center;
}

.xybackgroundShadeMiddle {
    display:flex;
    flex-direction:column;
    align-items:center;
    }

.xybackgroundShadeFieldset {
    border: map-get($generalStyles, 'prefferedBorderStyle');
    border-color: map-get($generalStyles, 'datalistBorderColor');
    border-radius: map-get($generalStyles, 'sixBorderRadius');
    width: 70%;
    height: 40px;
  //  margin-left: 40px;
  margin-top: 10px;
  @include gadgets-to(desktop) {
height:50px;

}
}

.xypickerWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;

}

.xybackgroundShadeFieldset1 {
    @extend .xybackgroundShadeFieldset;
    width:85%;
    height: 260px;
    //margin-left:2%;
    border: none;
   // padding-left: 5px;
    margin-top:5px;
}

.xybackgroundShadeLegend {
  font-family: var(--font-family);
    font-size: 14px;
    color: map-get($generalStyles,colorblack);
    @include gadgets-to(desktop) {
        font-size: 18px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 22px;
    }
    
}

.xybackgroundShadeSelect {
    @extend .xybackgroundShadeFieldset;
    width: 90%;
    height: 20px;
    margin-left: 10px;
    margin-top: 1px;
    font-size: 14px;
  font-family: var(--font-family);
    color: map-get($generalStyles,colorblack);
    background-color: map-get($generalStyles,colorwhite);
}

.xybackgroundShadeOption {
    @extend .xybackgroundShadeLegend;
    margin-left: 10px;
}

.xycolorSwatch {
   
        width: 90%;
        height: 30px;
        border: map-get($generalStyles, prefferedBorderStyle);
        background: none;
        border: none;
        padding: 0px;
        appearance: none;
        cursor: pointer;
        //webkit scrollbar
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
        }
        ::-webkit-scrollbar-thumb {
            background:white;
            border:1px solid;
            border-color:map-get($generalStyles,transparentChangeableMenu5);

        }

 
}

.xysaveButtonWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    @include gadgets-to(tablet) {
        width: 100%;
    }
    @include gadgets-to(laptop) {
        width: 100%;
    }
    @include gadgets-to(desktop) {
        width: 100%;
    }
    @include gadgets-to(largeDesktop) {
        width: 100%;
        margin-top:10px;
    }


}

.xysaveButton {
    // @extend .xybackgroundShadeFieldset;
    width:90%;
    height: 30px;
    font-size: 14px;
    color: map-get($generalStyles,colorblack);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
   // margin-left: 23px;
    margin-top: 2px;
    text-align: center;
    opacity: 0.7;
    border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);    
    color:black;
}

.xysaveButton1 {
    @extend .xysaveButton;
    color: map-get($generalStyles, colorwhite);
    background-color: map-get($generalStyles,pinkRed);
    opacity: 1;
    color:white;
}

    .xyimageGridContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4px;
        width: 95%;
        margin-top: 5px;
        margin-left: 10px;
        max-height: 70vh;
        overflow-y: scroll;
    }
    
    .xyimageGridContainer::-webkit-scrollbar {
        width: 10px;
    }
    
    .xyimageGridContainer::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
    }
    
    .xyimageGridContainer::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
    }
    

.xyimageGridImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 3px;
    cursor: pointer;
    @include gadgets-to(mobile) {
        width:calc(100% - 4px);
        height:calc(100% - 4px);
    }
    @include gadgets-to(tablet) {
        width:calc(100% - 3px);
        height:calc(100% - 3px);
    }

    //     @media(max-width:1024px){
    //         width:75px;
    //         height:75px;
    //     }
    //     @media(min-width:1070px) and (max-width:1115px){
    //         width:80px;
    //         height:80px;
    //     }

    // }
    // @media(min-width:1116px) and (max-width:1200px){
    //     width:90px;
    //     height:90px;
    // }
    //     @media(min-width:1360px){
    //         width: 105px;
    //         height: 105px;
    //     }

    @include gadgets-to(laptop) {
        width: 120px;
        height: 120px;
    }
    @include gadgets-to(desktop) {
        width: 150px;
        height: 150px;
    }
    @include gadgets-to(largeDesktop) {
        width: 180px;
        height: 180px;
    }
}

.xyimageGridImage1 {
    @extend .xyimageGridImage;
    border: 4px solid;
    border-color: map-get($generalStyles,colorblack);
}

.xyimageGridImage2 {
    @extend .xyimageGridImage;
    border: 3px solid;
    border-color: red;
}

.xyimageGridTop {
  font-family: var(--font-family);
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
}

.xysettingsSpinnerContainer {
    position: absolute;
    top: 50%;
    left:50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(1.5);
    z-index: 10;
}

.xySettingsSpinner {
    background-color: transparent;
}

