@import '../style/MainStyles.scss';/* styles.css */

html {
    scrollbar-width: thin; /* For Firefox */
    overflow: overlay; /* For Chrome, Safari */
  }

.totalIngredListArea1{
   

    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }
        // Use the mixin for tablet devices
        @include gadgets-to(tablet) {
    
            display: grid;
            grid-template-rows:auto auto auto;
            grid-template-areas: 
              "a"
              "b"
              "c";
            width: 100%;
            justify-items: center;
            gap:10px;
            grid-auto-rows: minmax(5px,auto);
        
        }

    @include gadgets-to(laptop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }

    @include gadgets-to(desktop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }

    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }







  }



.backgroundImageListIngred{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1);
    opacity: 0.8;
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
   
}
.backgroundImageListIngred1{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    z-index: -1;
    // transform:scale(1);
    // opacity: 0.8;
background-color: white;
}

.xxheaderArea{
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
}
.xxrangeArea{
  @include gadgets-to(mobile) {
    grid-area:b;
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    width: 99%;
    // width:99%;
    // display:flex;
    // flex-direction: row;
    // justify-content:center;
    
}
@include gadgets-to(tablet) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(laptop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(desktop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
}





.xxareaIngredListB{
    grid-area: c;
    border:map-get($generalStyles,prefferedBorderStyle );
    border-color:map-get($generalStyles,prefferedBorderColor);
    background-color:map-get($generalStyles,transparentChangeable);
    width:99%;
    border:1px solid white;
    
    //mobile
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-items: center;
        width:99%;
        height:78vh;
        margin-left:2px;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:80%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        border:map-get($generalStyles,prefferedBorderStyle );
        border-color:map-get($generalStyles,prefferedBorderColor);
    }

    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:70%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border:map-get($generalStyles,prefferedBorderStyle);
        border-color:map-get($generalStyles,prefferedBorderColor);
    }

    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:60%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border-color:map-get($generalStyles,prefferedBorderColor);
    }

    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:60%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border-color:map-get($generalStyles,prefferedBorderColor);
    }
    

   

}

.compNameIngredList {

  font-family: map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:white;
    //pc
    @include gadgets-to(tablet) {
        margin-top:2px;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
        color:white;
    }
    @include gadgets-to(laptop) {
        margin-top:2vh;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:35px;
        color:white;
    }

    @include gadgets-to(desktop) {
        margin-top:3vh;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:35px;
        color:white;
    }

    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:45px;
        color:white;
    }


}

.compNameIngredList1 {
 grid-area: a;

  font-family: map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:map-get($generalStyles,transparentChangeableMenu5);
   

   @include gadgets-to(tablet) {
    margin-top:2px;
    font-family: map-get($generalStyles,fontFamilyLobster);
    font-Size:30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    margin-top:2vh;
    font-family: map-get($generalStyles,fontFamilyLobster);
    font-Size:30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}


@include gadgets-to(desktop) {
    margin-top:3vh;
    font-family: map-get($generalStyles,fontFamilyLobster);
    font-Size:35px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}

@include gadgets-to(largeDesktop) {
    margin-top:3vh;
    font-family: map-get($generalStyles,fontFamilyLobster);
    font-Size:45px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}


}

.compNameIngredJAP {
    grid-area:a;

   font-family:var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:white;

    @include gadgets-to(tablet) {
        margin-top:2vh;
       font-family:var(--font-family);
        font-Size:30px;
        color:white;
      }
    @include gadgets-to(laptop) {
        margin-top:2vh;
       font-family:var(--font-family);
        font-Size:30px;
        color:white;

      }



    @include gadgets-to(desktop) {
        margin-top:3vh;
       font-family:var(--font-family);
        font-Size:35px;
        color:white;
    }
    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
       font-family:var(--font-family);
        font-Size:45px;
        color:white;
      }
    


}

.compNameIngredJAP1 {
    grid-area:a;

   font-family:var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:map-get($generalStyles,transparentChangeableMenu5);

    @include gadgets-to(tablet) {
        margin-top:2vh;
       font-family:var(--font-family);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }

    @include gadgets-to(laptop) {
        margin-top:2vh;
       font-family:var(--font-family);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }


    @include gadgets-to(desktop) {
        margin-top:3vh;
       font-family:var(--font-family);
        font-Size:35px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
       font-family:var(--font-family);
        font-Size:45px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    
}

.xxarange1 {
  @include gadgets-to(mobile) {
      margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
  }
  @include gadgets-to(tablet) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      margin-right:2%;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(desktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(largeDesktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
}

.xxarange2 {
 // @extend .xkrange1;
  @include gadgets-to(mobile) {
      accent-color: rgb(251, 239, 132);
      width:125px;

  }
  @include gadgets-to(tablet) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(desktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(largeDesktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
}



// .xxarange1 {
//     grid-area:b;
//     width:30%; 
//     cursor: pointer;
//     @include gadgets-to(tablet) {
//      width:20%;
//     }
//     @include gadgets-to(laptop) {
//         width:12%;
//        }

//        @include gadgets-to(desktop) {
//         width:12%;
//        }

//        @include gadgets-to(largeDesktop) {
//         width:10%;
//        }
// }

// .xxarange2 {
//     grid-area:b;
//     width:30%;
//     cursor: pointer;
  


//     @include gadgets-to(tablet) {
//         width:20%;
//        }
//     @include gadgets-to(laptop) {
//     width:12%;
//     }
    
//     @include gadgets-to(desktop) {
//     width:12%;
//     }

//     @include gadgets-to(largeDesktop) {
//     width:10%;
//     }
// }

.ingredselectTop10 {
    grid-area:c;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
  }
  
  .ingredselectFieldset10 {
    grid-area:c;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:90%;
    margin-top:3vh;
    border:none;
    @include gadgets-to(tablet) {
        width:80%;
       }
@include gadgets-to(laptop) {
        width:70%;
       }
 
}
  
  .ingredselectLegend10 {
    margin-left:5px;
    font-family:var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);

    @include gadgets-to(tablet) {
        margin-left:10%;
       }
          

       @include gadgets-to(desktop) {
        margin-left:2.5%;
       }
       @include gadgets-to(largeDesktop) {
        margin-left:2.5%;
       }
}
  
  .ingredselectLegendJAP10 {
    font-family:var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);

    @include gadgets-to(tablet) {
        margin-left:10%;
       }
  
  }
  
  .ingredselectIngred10 {
    width:95%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
 font-family:var(--font-family);
    background-color: white;

    @include gadgets-to(tablet) {
        width:80%;
       }
@include gadgets-to(laptop) {
        width:100%;
        box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
       }
}
  
  .ingredselectIngredJAP10 {
    width: 100%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
 font-family: var(--font-family);
     background-color: white;

     
    @include gadgets-to(tablet) {
        width:80%;
       }

       @include gadgets-to(largeDesktop) {
        width:75%;
       }
}
  
  .datalistTop10{
    @include gadgets-to(mobile){
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:98%;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width:80%;
        margin-top:1vh;
    
    }

@include gadgets-to(laptop) {
  display: flex;
  flex-direction: row;
  justify-content: center;
        width:70%;
        margin-top:1vh;
       }
             
@include gadgets-to(desktop) {
  display: flex;
  flex-direction: row;
  justify-content: center;
width:70%;
}
      
@include gadgets-to(largeDesktop) {
  display: flex;
  flex-direction: row;
  justify-content: center;
width:70%;
}
  }

  
.listingredid10 {
    @include gadgets-to(mobile){
    width:15%;
    height: map-get($generalStyles, prefferedHeaderHeight);
 font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }


    @include gadgets-to(tablet){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }
    @include gadgets-to(laptop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }

    @include gadgets-to(desktop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }

    @include gadgets-to(largeDesktop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }



  }
  
  .listingredidJAP10 {
    @extend .listingredid10;
    padding-top: 1px;
    color:black;
  }
  
  .listingredbenaemn10 {
    @include gadgets-to(mobile){
    width:calc(55% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
 font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }

    @include gadgets-to(tablet){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(laptop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(desktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(largeDesktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    // @include gadgets-to(tablet){
    //   width:55%;
    // }

  }
  
  .listingredbenaemnJAP10 {
    @extend .listingredbenaemn10;
    padding-top: 1px;
  }
  
  .listingredactive10 {

    @include gadgets-to(mobile){
    width:calc(15% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
 font-family: var(--font-family);
    font-size: 12px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }

    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
     font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
     font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
     font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
     font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

  }
  
  .listingredactiveJAP10 {
    @extend .listingredactive10;
    padding-top: 1px;
  }

  .listingredSave10 {
    @include gadgets-to(mobile){
    width:calc(15% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
 font-family: var(--font-family);
    font-size:12px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    margin-bottom: 3px;
    color:black;
    padding:1px;
    }
 

    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }   

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;



    }   

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }  
    
    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
     font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }   

  }
  
  .listingredSaveJAP10 {
    @extend .listingredSave10;
    padding-top: 1px;
  }

  .datalistData10,.datalistDataJAP10 {

     
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: column;
        // gap:2px;
        // margin: 0 2px;
        max-height:53vh;
        overflow-y:scroll;
         width:98%;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }
       }


    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        // gap:2px;
        // margin: 0 2px;
        max-height:53vh;
        overflow-y:scroll;
        width:80%;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }
       }

       @include gadgets-to(laptop) {
        display: flex;
        flex-direction: column;
        height:auto;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        box-shadow: 0 6px 10px 10px rgba(0, 0, 0, 0.2);
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);

          }
          //thumb
          &::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 10px;
            border:1px solid;
            border-color: map-get($generalStyles,transparentChangeableMenu5);

       }
      }

       @include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        &::-webkit-scrollbar {
          width: 10px; /* Adjust as needed */
        }
        &::-webkit-scrollbar-track {
          background:map-get($generalStyles,transparentChangeableMenu5);

        }
        //thumb
        &::-webkit-scrollbar-thumb {
          background: white;
          border-radius: 10px;
          border:1px solid;
          border-color: map-get($generalStyles,transparentChangeableMenu5);

     }


       }

       @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        &::-webkit-scrollbar {
          width: 10px; /* Adjust as needed */
        }
        &::-webkit-scrollbar-track {
          background:map-get($generalStyles,transparentChangeableMenu5);

        }
        //thumb
        &::-webkit-scrollbar-thumb {
          background: white;
          border-radius: 10px;
          border:1px solid;
          border-color: map-get($generalStyles,transparentChangeableMenu5);
     }


       }

  
  }
  
//   .datalistDataItem10 {
//     height: 34px;
//   }
  
  .datalistDataItemId10 {
    @include gadgets-to(mobile){
    width:15%;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 3px;
    text-align: center;
 font-family: var(--font-family);
    font-size:12px;
    color:black;

  }
  @include gadgets-to(tablet){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
 font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(laptop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
 font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(desktop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
 font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(largeDesktop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
 font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }
}

  
  .datalistDataItemId110 {
    @extend .datalistDataItemId10;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  }
  
  .datalistbenaemn10 {
    @include gadgets-to(mobile){
    width:calc(55% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 3px;
    text-align: center;
 font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    color:black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }


    @include gadgets-to(tablet){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    font-family:var(--font-family);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor:pointer;
        //hover
        &:hover {
        border-color: map-get($generalStyles,prefferedBorderColor);
        border:2px solid;
        color:white;
        cursor:pointer;
        }

    }

    @include gadgets-to(laptop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    font-family:var(--font-family);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        // active
        &:hover {
        border-color: map-get($generalStyles,prefferedBorderColor);
        border:2px solid;
        color:white;
        cursor:pointer;
        }
    }

    @include gadgets-to(desktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    font-family:var(--font-family);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        cursor: pointer;
    }

    
    @include gadgets-to(largeDesktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    font-family:var(--font-family);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        cursor: pointer;
    }
  
  }
  
  .datalistbenaemn110 {
    @extend .datalistbenaemn10;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  }




  .datalistDataItemActive10,.datalistDataItemActive11 {

    @include gadgets-to(mobile){
 width:calc(15% - 3px);
 margin-left:3px;
 height:map-get($generalStyles,prefferedTabletListHeight);
 border-radius: map-get($generalStyles,sixBorderRadius);
 border: map-get($generalStyles,prefferedBorderStyle);
 border-color: map-get($generalStyles,prefferedBorderColor);
 background-color: map-get($generalStyles,transparentChangeableMenu5);
 margin-bottom:3px;
 margin-top:3px;

 .dataitemChecked{
    width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

      .dataitemUnchecked{
        width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }
    }

    @include gadgets-to(tablet){
 width:calc(15% - 3px);
 margin-left:3px;
 height:map-get($generalStyles,prefferedTabletListHeight);
 border-radius: map-get($generalStyles,sixBorderRadius);
 border: map-get($generalStyles,prefferedBorderStyle);
 border-color: map-get($generalStyles,prefferedBorderColor);
 background-color: map-get($generalStyles,transparentChangeableMenu5);
 margin-bottom:3px;
 margin-top:3px;


 .dataitemChecked{
    width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

      .dataitemUnchecked{
        width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;

        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
     //   margin-bottom:3px;
     //   margin-top:3px;
        cursor:pointer;
       
       
        .dataitemChecked{
           width:20px;
           height:20px;
           padding:0;
           margin:0;
           color:map-get($generalStyles,transparentChangeableMenu4);
           border-radius: map-get($generalStyles,sixBorderRadius);
           // box shadow grey
            box-shadow: 0 5 9px 0 rgba(0,0,0,0.2);
          }
       
             .dataitemUnchecked{
               width:20px;
           height:20px;
           padding:0;
           margin:0;
           color:map-get($generalStyles,transparentChangeableMenu5);
             }
       
           }

              @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
      //  margin-bottom:3px;
      //  margin-top:3px;
        cursor:pointer;
       
       
        .dataitemChecked{
           width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
             .dataitemUnchecked{
               width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
           }

           @include gadgets-to(largeDesktop){
            width:calc(15% - 3px);
            margin-left:3px;
            height:map-get($generalStyles,prefferedTabletListHeight);
            border-radius: map-get($generalStyles,sixBorderRadius);
            border: map-get($generalStyles,prefferedBorderStyle);
            border-color: map-get($generalStyles,prefferedBorderColor);
            background-color: map-get($generalStyles,transparentChangeableMenu5);
            margin-bottom:3px;
            margin-top:3px;
            cursor:pointer;
           
           
            .dataitemChecked{
               width:12px;
               height:12px;
               padding:0;
               margin:0;
               color:blue;
                 }
           
                 .dataitemUnchecked{
                   width:12px;
               height:12px;
               padding:0;
               margin:0;
               color:blue;
                 }
           
               }

}


.datalistDataItemActive11{
    @extend .datalistDataItemActive10;
    background-color: map-get($generalStyles,transparentChangeableMenu4);

  }

.xdatabutton {
    @include gadgets-to(mobile){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
    }


    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }




}
  
  .xdatabutton1{
    @include gadgets-to(mobile){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
    }


    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
       // margin-bottom:3px;
     //   margin-top:3px;
        color:black;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
     //   margin-bottom:3px;
      //  margin-top:3px;
        color:black;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
      //  margin-bottom:3px;
      //  margin-top:3px;
        color:black;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
      //  margin-bottom:3px;
      //  margin-top:3px;
        color:black;
     font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
    }
    
}

    .xdatabuttonJAP1{
    @extend .xdatabutton;
    font-size:12px;
    }

    .xdatabuttonJAP2{
        @extend .xdatabutton1;
        font-size:12px;
        }
  

  
  .listSpinnerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        background-color: transparent;
  }

  .datalistDataRow{
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width:100%;
    padding:0;
    margin:0;
  }
