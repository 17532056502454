:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
html {
  scrollbar-width: thin; /* For Firefox */
  overflow: overlay; /* For Chrome, Safari */
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .totalIngredListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .totalIngredListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .totalIngredListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .totalIngredListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width:2303px) {
  .totalIngredListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}

.backgroundImageListIngred {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
  opacity: 0.8;
}

.backgroundImageListIngred1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: white;
}

.xxheaderArea {
  grid-area: a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 99%;
  margin-top: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 99%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xxrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}

.xxareaIngredListB {
  grid-area: c;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--val);
  width: 99%;
  border: 1px solid white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxareaIngredListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
    height: 78vh;
    margin-left: 2px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxareaIngredListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 80%;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxareaIngredListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 70%;
    border-radius: 6px;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxareaIngredListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 60%;
    border-radius: 6px;
    margin-top: 10px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xxareaIngredListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 60%;
    border-radius: 6px;
    margin-top: 10px;
    border-color: rgb(223, 212, 212);
  }
}

.compNameIngredList {
  font-family: "Lobster";
  font-Size: 26px;
  color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .compNameIngredList {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .compNameIngredList {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .compNameIngredList {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .compNameIngredList {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: white;
  }
}

.compNameIngredList1 {
  grid-area: a;
  font-family: "Lobster";
  font-Size: 26px;
  color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .compNameIngredList1 {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .compNameIngredList1 {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .compNameIngredList1 {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .compNameIngredList1 {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

.compNameIngredJAP {
  grid-area: a;
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .compNameIngredJAP {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .compNameIngredJAP {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .compNameIngredJAP {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .compNameIngredJAP {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 45px;
    color: white;
  }
}

.compNameIngredJAP1 {
  grid-area: a;
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .compNameIngredJAP1 {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .compNameIngredJAP1 {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .compNameIngredJAP1 {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 35px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .compNameIngredJAP1 {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxarange1 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxarange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxarange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxarange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xxarange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xxarange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.ingredselectTop10 {
  grid-area: c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 99%;
}

.ingredselectFieldset10 {
  grid-area: c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-top: 3vh;
  border: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredselectFieldset10 {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredselectFieldset10 {
    width: 70%;
  }
}

.ingredselectLegend10 {
  margin-left: 5px;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredselectLegend10 {
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredselectLegend10 {
    margin-left: 2.5%;
  }
}
@media only screen and (min-width:2303px) {
  .ingredselectLegend10 {
    margin-left: 2.5%;
  }
}

.ingredselectLegendJAP10 {
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredselectLegendJAP10 {
    margin-left: 10%;
  }
}

.ingredselectIngred10 {
  width: 95%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredselectIngred10 {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredselectIngred10 {
    width: 100%;
    box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
  }
}

.ingredselectIngredJAP10 {
  width: 100%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredselectIngredJAP10 {
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .ingredselectIngredJAP10 {
    width: 75%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .datalistTop10 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .datalistTop10 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .datalistTop10 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .datalistTop10 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
  }
}
@media only screen and (min-width:2303px) {
  .datalistTop10 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .listingredid10, .listingredidJAP10 {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .listingredid10, .listingredidJAP10 {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .listingredid10, .listingredidJAP10 {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .listingredid10, .listingredidJAP10 {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .listingredid10, .listingredidJAP10 {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.listingredidJAP10 {
  padding-top: 1px;
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .listingredbenaemn10, .listingredbenaemnJAP10 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .listingredbenaemn10, .listingredbenaemnJAP10 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .listingredbenaemn10, .listingredbenaemnJAP10 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .listingredbenaemn10, .listingredbenaemnJAP10 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .listingredbenaemn10, .listingredbenaemnJAP10 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.listingredbenaemnJAP10 {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .listingredactive10, .listingredactiveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .listingredactive10, .listingredactiveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .listingredactive10, .listingredactiveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .listingredactive10, .listingredactiveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .listingredactive10, .listingredactiveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.listingredactiveJAP10 {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .listingredSave10, .listingredSaveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    margin-bottom: 3px;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .listingredSave10, .listingredSaveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .listingredSave10, .listingredSaveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .listingredSave10, .listingredSaveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .listingredSave10, .listingredSaveJAP10 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}

.listingredSaveJAP10 {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .datalistData10, .datalistDataJAP10 {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 98%;
  }
  .datalistData10::-webkit-scrollbar, .datalistDataJAP10::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .datalistData10::-webkit-scrollbar-track, .datalistDataJAP10::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .datalistData10, .datalistDataJAP10 {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 80%;
  }
  .datalistData10::-webkit-scrollbar, .datalistDataJAP10::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .datalistData10::-webkit-scrollbar-track, .datalistDataJAP10::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .datalistData10, .datalistDataJAP10 {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
    box-shadow: 0 6px 10px 10px rgba(0, 0, 0, 0.2);
  }
  .datalistData10::-webkit-scrollbar, .datalistDataJAP10::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .datalistData10::-webkit-scrollbar-track, .datalistDataJAP10::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .datalistData10::-webkit-scrollbar-thumb, .datalistDataJAP10::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .datalistData10, .datalistDataJAP10 {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .datalistData10::-webkit-scrollbar, .datalistDataJAP10::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .datalistData10::-webkit-scrollbar-track, .datalistDataJAP10::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .datalistData10::-webkit-scrollbar-thumb, .datalistDataJAP10::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .datalistData10, .datalistDataJAP10 {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .datalistData10::-webkit-scrollbar, .datalistDataJAP10::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .datalistData10::-webkit-scrollbar-track, .datalistDataJAP10::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .datalistData10::-webkit-scrollbar-thumb, .datalistDataJAP10::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .datalistDataItemId10, .datalistDataItemId110 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .datalistDataItemId10, .datalistDataItemId110 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .datalistDataItemId10, .datalistDataItemId110 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .datalistDataItemId10, .datalistDataItemId110 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .datalistDataItemId10, .datalistDataItemId110 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}

.datalistDataItemId110 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .datalistbenaemn10, .datalistbenaemn110 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .datalistbenaemn10, .datalistbenaemn110 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .datalistbenaemn10:hover, .datalistbenaemn110:hover {
    border-color: rgb(223, 212, 212);
    border: 2px solid;
    color: white;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .datalistbenaemn10, .datalistbenaemn110 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
  .datalistbenaemn10:hover, .datalistbenaemn110:hover {
    border-color: rgb(223, 212, 212);
    border: 2px solid;
    color: white;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .datalistbenaemn10, .datalistbenaemn110 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .datalistbenaemn10, .datalistbenaemn110 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
}

.datalistbenaemn110 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .datalistDataItemActive10, .datalistDataItemActive11 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .datalistDataItemActive10 .dataitemChecked, .datalistDataItemActive11 .dataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .datalistDataItemActive10 .dataitemUnchecked, .datalistDataItemActive11 .dataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .datalistDataItemActive10, .datalistDataItemActive11 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .datalistDataItemActive10 .dataitemChecked, .datalistDataItemActive11 .dataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .datalistDataItemActive10 .dataitemUnchecked, .datalistDataItemActive11 .dataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .datalistDataItemActive10, .datalistDataItemActive11 {
    width: calc(15% - 3px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    cursor: pointer;
  }
  .datalistDataItemActive10 .dataitemChecked, .datalistDataItemActive11 .dataitemChecked {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    color: var(--rgbaVal2);
    border-radius: 6px;
    box-shadow: 0 5 9px 0 rgba(0, 0, 0, 0.2);
  }
  .datalistDataItemActive10 .dataitemUnchecked, .datalistDataItemActive11 .dataitemUnchecked {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .datalistDataItemActive10, .datalistDataItemActive11 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    cursor: pointer;
  }
  .datalistDataItemActive10 .dataitemChecked, .datalistDataItemActive11 .dataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .datalistDataItemActive10 .dataitemUnchecked, .datalistDataItemActive11 .dataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width:2303px) {
  .datalistDataItemActive10, .datalistDataItemActive11 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
  }
  .datalistDataItemActive10 .dataitemChecked, .datalistDataItemActive11 .dataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .datalistDataItemActive10 .dataitemUnchecked, .datalistDataItemActive11 .dataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}

.datalistDataItemActive11 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdatabutton, .xdatabuttonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdatabutton, .xdatabuttonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdatabutton, .xdatabuttonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdatabutton, .xdatabuttonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xdatabutton, .xdatabuttonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdatabutton1, .xdatabuttonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdatabutton1, .xdatabuttonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdatabutton1, .xdatabuttonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdatabutton1, .xdatabuttonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xdatabutton1, .xdatabuttonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}

.xdatabuttonJAP1 {
  font-size: 12px;
}

.xdatabuttonJAP2 {
  font-size: 12px;
}

.listSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  background-color: transparent;
}

.datalistDataRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
}/*# sourceMappingURL=Linstingred1.css.map */