@import '../style/MainStyles.scss';/* styles.css */





.xvtotWrapper{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}

.xvtotalArea {
    @include gadgets-to(mobile) {
        display: grid;
       grid-template-rows: 6% 4% 73% 18%;
       // grid-template-rows:auto auto auto auto;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            ;
        width: 100%;
        height:auto;
        object-fit: cover;



    }

        @include gadgets-to(tablet) {
            display: grid;
            grid-template-columns: 75% 25%; // Adjust as needed
            grid-template-rows: auto auto;
            grid-template-areas:
              "a a"
              "b b"
              "c d";
            column-gap: 7px;
            width: 95%;
            height: 100%;
            object-fit: cover;
          }
       
    
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:30% 45% 20%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c c d";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:30% 45% 20%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c c d";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;


    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:30% 45% 20%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c c d";
          column-gap:7px;
        width:85%;
        height: 100%;
        object-fit: cover;
    }
}

.xvtotalArea1 {
    @extend .xvtotalArea;
   // background-color: map-get($generalStyles, backgroundColor);
      background-color:white;
}

.xvareaA {
    
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:99.4%;
    height:63vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius: map-get($generalStyles, sixBorderRadius);

    @include gadgets-to(tablet) {
        height:80vh;
        @media(min-width:1170px) and (max-width:1190px)
        {
            height:77vh;
        }
        @media(min-width:1270px) and (max-height:632px){
            height:72vh;
        }
    }
    @include gadgets-to(laptop) {
        height:80vh;
    }
    @include gadgets-to(desktop) {
        height:80vh;
    }
    @include gadgets-to(largeDesktop) {
        height:80vh;
    }
}

.xvareaB {

    grid-area: d;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:15vh;
   // margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include gadgets-to(mobile) {
        @media(min-width:412px) and (max-width:415px)
        {
            margin-top:18px;
            height:12vh;
        }
        @media(min-width:412px) and (max-height:732px)
        {
            margin-top:10px;
            height:14vh;
        }
    }

    @include gadgets-to(tablet) {
        height:80vh;

        @media(min-width:1170px) and (max-width:1190px)
        {
            height:77vh;
        }

        @media(min-width:1270px) and (max-height:632px){
            height:72vh;
        }
    }
    
    @include gadgets-to(laptop) {
        height:80vh;
    }
    @include gadgets-to(desktop) {
        height:80vh;
    }
    @include gadgets-to(largeDesktop) {
        height:80vh;
    }
}


.xvheaderArea{
    @include gadgets-to(mobile) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:30px;
    }
    @include gadgets-to(tablet) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:30px;
        @media(orientation:portrait){
            height:45px;
        }
    }
    @include gadgets-to(laptop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:40px;
    }
    @include gadgets-to(desktop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:40px;
    }
    @include gadgets-to(largeDesktop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:7vh;
    }

}



.xvcompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xvcompNameJAP {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 46px;
        color: white;
    }
}

.xvcompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xvcompNameJAP1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}



.xvrangeArea{
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:20px;
         }
         // as ipad
        //  @media(min-width:1171px) and (max-width:1195px)
        //  {
         
        //     height:auto;
        //  }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:20px;
         }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}

.xvrange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xvrange2 {
    @extend .xvrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}


.xvselectArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top:5px;
    @include gadgets-to(largeDesktop) {
        height:5vh;
    }
}

.xvselectfieldset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 40%;
    font-family: var(--font-family);
    font-size: 12px;
    border: none;
    height: 40px;


}
.xvselectlegend{
    font-family: var(--font-family);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    @include gadgets-to(tablet) {
        font-size: 14px;
        margin-left:7%;
        @media(min-width:1025px) and (max-width:1120px)
        {
            font-size: 14px;
            margin-left:9%;
        }
        @media(min-width:1190px) and (max-width:1198px)
        {
            font-size: 14px;
            margin-left:12%;
        }
        @media(min-width:1199px)
        {
            font-size: 14px;
            margin-left:18%;
        }

        @media(orientation:portrait){
            font-size: 14px;
            margin-left:2%;
        }
    }
    @include gadgets-to(laptop) {
        font-size: 14px;
        margin-left:6%;
   
    }
    @include gadgets-to(desktop) {
        font-size: 14px;
        margin-left:6%;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 18px;
        margin-left:1%;
    }

}

.xvselect {
    width: 250px;
    height: 30px;
    color: black;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
    font-family: var(--font-family);
    @include gadgets-to(laptop) {
        width:25vw;
        height: 30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        width:25vw;
        height: 30px;
        font-size: 14px;
    }
    @include gadgets-to(largeDesktop) {
        width:25vw;
        height: 35px;
        font-size: 16px;
    }

}

.xvheaderWrapper{
    @include gadgets-to(tablet) {

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}
}

.xvheader {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  //  margin-left: 5px;
  width:100%;
  height:25px;
  @include gadgets-to(tablet) {
    width:95%;
    height:25px;
}
@include gadgets-to(laptop) {
    width:95%;
    height:25px;
}
@include gadgets-to(desktop) {
    width:80%;
    height:25px;
}
@include gadgets-to(largeDesktop) {
    width:80%;
    height:35px;
}
}


.xvproduktid1 {
    @include gadgets-to(mobile) {

    width:calc(12% - 2px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-bottom: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: var(--font-family);
    font-size: 12px;
}
@include gadgets-to(tablet) {
    width:calc(12% - 2px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-bottom: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: var(--font-family);
    font-size: 12px;
    color:black;
}
@include gadgets-to(laptop) {
    width:calc(12% - 2px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-bottom: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: var(--font-family);
    font-size: 12px;
}
@include gadgets-to(desktop) {
    width:calc(12% - 2px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-bottom: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: var(--font-family);
    font-size: 14px;
}
@include gadgets-to(largeDesktop) {
    width:calc(12% - 2px);
    height:35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-bottom: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    font-family: var(--font-family);
    font-size: 16px;
}
}


.xvbenaemn1 {
    @extend .xvproduktid1;
    width:calc(37% - 2px);
    margin-left:2px;
}

.xvpris1 {
    @extend .xvproduktid1;
    width:calc(12% - 2px);
    margin-left:2px;
}

.xvmargin1 {
    @extend .xvproduktid1;
    width:calc(12% - 2px);
    margin-left:2px;
}

.xvinkmoms1 {
    @extend .xvproduktid1;
    width:calc(12% - 2px);
    margin-left:2px;
    padding:1px;
}

.xvspara1 {
    @extend .xvproduktid1;
    width:calc(15% - 2px);
    margin-left:2px;
    padding:1px;
    @include gadgets-to(laptop) {
        width:calc(15% - 2px);
        margin-left:2px;
        padding:1px;
        margin-right:22px;
    }
    @include gadgets-to(desktop) {
        width:calc(15% - 2px);
        margin-left:2px;
        padding:1px;
        margin-right:20px;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(15% - 2px);
        margin-left:2px;
        padding:1px;
        margin-right:21px;
    }

}

.xvpricelistArea {
    width:100%;
    padding:0;
    margin:0;

        //scroll webkit
        ::-webkit-scrollbar {
            width: 1px;
        }
        //track
        ::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        //handle
        ::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 10px;
            border:1px solid;
            border-color: map-get($generalStyles,transparentChangeableMenu5);
        
        }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:auto;
    max-height:65vh;
    overflow-y: scroll;
    //scroll webkit
    ::-webkit-scrollbar {
        width: 10px;
    }
    //track
    ::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5);
    }
    //handle
    ::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    
    }

}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:auto;
    max-height:65vh;
    overflow-y: scroll;
    //scroll webkit
    ::-webkit-scrollbar {
        width: 10px;
    }
    //track
    ::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5);
    }
    //handle
    ::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    
    }

}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    height:auto;
    max-height:65vh;
    overflow-y: scroll;
    //scroll webkit
    ::-webkit-scrollbar {
        width: 10px;
    }
    //track
    ::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5);
    }
    //handle
    ::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    
    }

}
}




.xvpricelistData {
    display: flex;
    flex-direction: column;
    margin-top: 1px;
  //  margin-left: 5px;
    max-height:47vh;
    overflow-y: scroll;
    width: 100%;
    overflow-x: hidden;
    @include gadgets-to(tablet) {
        width:95%;
        max-height:65vh;

        @media(min-width:1270px) and (max-height:632px){
            max-height:56vh;
        }
    }
    @include gadgets-to(laptop) {
        width:95%;
        max-height:70vh;


    }
    @include gadgets-to(desktop) {
        width:80%;
        max-height:65vh;
    }
    @include gadgets-to(largeDesktop) {
        width:80%;
        max-height:65vh;
    }


}

.xvoptionstyle {
    font-family: var(--font-family);
    font-size: map-get($generalStyles, selectlistFontSize);
}

.xvAiOutlinePlusCircle,
.xvAiOutlineMinusCircle {
    color: map-get($generalStyles, colorblack);
    font-size: 26px;
    cursor: pointer;
    opacity: 0.5;
    @include gadgets-to(mobile) {
       font-size:25px;
    }
    @include gadgets-to(tablet) {
        font-size:40px;
    }
    @include gadgets-to(laptop) {
        font-size:40px;
    }
    @include gadgets-to(desktop) {
        font-size:40px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 50px;
    }
}

.xvplussign,
.xvminussign {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 8px;
   // margin-left: 7px;
    color: map-get($generalStyles, colorblack);
    @include gadgets-to(mobile) {
        @media(max-width:360px){
            padding-left:1px;
            
        }
        @media(min-width:361px) and (max-width:430px){
            padding-left:2px;
        }
    }
    @include gadgets-to(tablet) {
        @media(min-width:768px) and (max-width:1024px){
            padding-left:12px;
        }
        @media(min-width:1025px) and (max-width:1120px){
            padding-left:12px;
        }
        @media(min-width:1121px) and (max-width:1190px){
            padding-left:4px;
        }
        @media(min-width:1191px) and (max-width:1198px){
            padding-left:12px;
        }
        @media(min-width:1199px){
            padding-left:14px;
        }
    }
    @include gadgets-to(laptop) {
        padding-left:12px;
    }
    @include gadgets-to(desktop) {
        padding-left:12px;
    }
    @include gadgets-to(largeDesktop) {
        padding-left:12px;
        
    }
    
}

.xvplustext {
    font-family: var(--font-family);
    font-size: 20px;
    margin-top: 6px;
    margin-left: 5px;
    color: map-get($generalStyles, colorblack);
@include gadgets-to(laptop) {
    font-size:30px;
}
@include gadgets-to(desktop) {
    font-size:30px;
}

    @include gadgets-to(largeDesktop) {
       font-size: 40px;
}
}


.xveditproductTop{
    width:100%;
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items: center;
}
.xveditproductArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width:95%;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    height:7vh;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    box-shadow: inset 0 0 0 1px white; // Adjust as needed
    
}   

.xvaverageArea{
    width:100%;
}

.xvaverageText {
    font-family: var(--font-family);
    font-size: 14px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    margin-top: 10px;
    text-align: center;
    @include gadgets-to(largeDesktop) {
        margin-top:2vh;
        font-size: 16px;
    }
}

.xvaveragePercentage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 42px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 1px;
    margin-top: 8px;
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 40px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 1px;
    margin-top: 2px;
    }   
    @include gadgets-to(largeDesktop) {
        font-size: 46px;
    }
}

.xvpricelistSpinnerContainer {
    position: absolute;
    top: 50%;
    left:50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(1.5);
    z-index: 10;
    @include gadgets-to(desktop) {
      left:45%;
    }
    @include gadgets-to(largeDesktop) {
       left:45%;
    }
  
}
.xvspinner-text{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width:100%;
    font-size: 8px;
    font-family: var(--font-family);
    background-color: white;
    border-radius: 4px;
    border:1px solid map-get($generalStyles,transparentChangeableMenu5);
    padding:3px;
}


.xvpidbutton {
    width:calc(12% - 2px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-bottom: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    font-family: var(--font-family);
    font-size: 12px;
    color:black;
    @include gadgets-to(laptop) {
      height:30px;
      font-size: 14px;
    }
    @include gadgets-to(desktop) {
      height:35px;
      font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
      height:40px;
      font-size: 20px;
    }

}

.xvpidbutton1 {
    @extend .xvpidbutton;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xvbenaemnbutton {
    @extend .xvpidbutton;
    width:calc(37% - 2px);
    color: map-get($generalStyles, colorblack);
    cursor: pointer;
  //  margin-top: 2px;
    text-align: left;
    margin-left:2px;
    //elllipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 
}

.xvbenaemnbutton1 {
    @extend .xvbenaemnbutton;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xvprisbutton {
    @extend .xvpidbutton;
    width:calc(12% - 2px);
    margin-left:2px;
    padding:1px;
}

.xvprisbutton1 {
    @extend .xvprisbutton;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xvmarginbutton {
    @extend .xvpidbutton;
    width:calc(12% - 2px);
    margin-left:2px;
    padding:1px;
}

.xvmarginbutton1 {
    @extend .xvmarginbutton;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xvinkmomsbutton {
    @extend .xvpidbutton;
    width:calc(12% - 2px);
    margin-left:2px;
    padding:1px;
}

.xvinkmomsbutton1 {
    @extend .xvinkmomsbutton;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xvsparabutton {
    @extend .xvpidbutton;
    color: map-get($generalStyles, colorblack);
    cursor: pointer;
    width:calc(15% - 2px);
    margin-left:2px;
    opacity: 0.8;
    padding:1px;
}

.xvsparabutton1 {
    @extend .xvsparabutton;
    border-radius: map-get($generalStyles, threeBorderRadius);
    cursor: pointer;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xvsparabutton2 {
    @extend .xvsparabutton;
    background-color: red;
    border: none;
    opacity: 1;
}
 
.xvbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }

  .xvanimateddivPricelistNew{
    width:100%;
    transform:rotate(0deg);
    animation-name: identifierCreateProduct;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    
    @include gadgets-to(tablet) {
    
        @media(min-width:1360px) and (max-width:1367px) {
            animation-name: identifierCreateProduct1;
        }
    }
    @include gadgets-to(laptop) {
            animation-name: identifierCreateProduct2;
    }
    @include gadgets-to(desktop) {
        animation-name: identifierCreateProduct3;
    }
    @include gadgets-to(largeDesktop) {
        animation-name: identifierCreateProduct4;
    }
    
}

      @keyframes identifierCreateProduct {
        0%{ height:0px }
        100%{height:63px}
}

@keyframes identifierCreateProduct1 {
    0%{ height:0px }
    100%{height:73px}
}

@keyframes identifierCreateProduct2 {
    0%{ height:0px }
    100%{height:80px}
}

@keyframes identifierCreateProduct3 {
    0%{ height:0px }
    100%{height:80px}
}

@keyframes identifierCreateProduct4 {
    0%{ height:0px }
    100%{height:110px}
}


