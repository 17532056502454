@import '../style/MainStyles.scss';/* styles.css */

.xmtotWrapper{
        @include gadgets-to(mobile) {
    
        // display:flex;
        // flex-direction:row;
        // justify-content:center;
        //  align-items:center;
    }
    @include gadgets-to(tablet) {
        // display:flex;
        // flex-direction:row;
        // justify-content:center;
        //  align-items:center;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
         align-items:center;
         width:100%;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
         align-items:center;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
         align-items:center;
    }
    
}


.xmwindowBackground {
    //  backgroundColor: map-get($generalStyles, backgroundColor),
width:100%;
}

.xmwindowArea {
    @include gadgets-to(mobile) {

        display: grid;
        // grid-template-rows: 3% 5% 26% 17% 30%;
         grid-template-rows:auto auto auto auto;
    
         grid-template-areas:
             "a"
             "b"
             "c"
             "d"
             "e"
             ;
         width: 100%;
         height:auto;
         object-fit: cover;
    }
    
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:25% 50% 23%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    
       
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:25% 50% 24%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:25% 50% 24%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:25% 50% 24%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.xmwindowArea1 {
    @extend .xmwindowArea;
    // background-color: map-get($generalStyles, backgroundColor);
}

.xmwindowAreaA {
    @include gadgets-to(mobile) {

    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:22vh;
    border-radius:map-get($generalStyles,sixBorderRadius);
    // margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
}
@include gadgets-to(tablet) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:85vh;
    border-radius:map-get($generalStyles,sixBorderRadius);
    // margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
   // margin-top:5px;
   @media(min-width:1180px) and (max-width:1195px){
    height: 77vh;
   }
}
@include gadgets-to(laptop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:84vh;
    border-radius:map-get($generalStyles,sixBorderRadius);
    // margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    //margin-top:5px;
}
@include gadgets-to(desktop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:84vh;
    border-radius:map-get($generalStyles,sixBorderRadius);
    // margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;

}
@include gadgets-to(largeDesktop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:84vh;
    border-radius:map-get($generalStyles,sixBorderRadius);
    // margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
}
}
.xmwindowAreaB {
    @extend .xmwindowAreaA;
    @include gadgets-to(mobile) {

    grid-area:d;
    height:58svh;
    // margin-left: 10px;
    margin-top:1vh;

    @media(min-width:375px) and (max-width:380px){
        height: 60vh;
    
    }
    @media(min-width:393px){
        height: 59vh;
    
    }

    @media(min-width:393px){
        height: 59vh;
    
    }

    @media(min-width:410px) and (max-width:413px){
        height: 54vh;
    
    }

    @media(min-width:414px){
        height: 61vh;
    
    }
    @media(min-width:428px){
        height: 60vh;
    
    }
}
@include gadgets-to(tablet) {
    grid-area:d;
    height:85vh;
    // margin-left: 10px;
   // margin-top:1vh;
   @media(min-width:1170px) and (max-width:1195px){
    height: 77vh;
   }
}
@include gadgets-to(laptop) {
    grid-area:d;
    height:84vh
    // margin-left: 10px;
   // margin-top:1vh;
}
@include gadgets-to(desktop) {
    grid-area:d;
    height:84vh;
    // margin-left: 10px;
  //  margin-top:1vh;
}
@include gadgets-to(largeDesktop) {
    grid-area:d;
    height:84vh;
    // margin-left: 10px;
   // margin-top:1vh;
}
}
.xmwindowAreaBJAP {
    @extend .xmwindowAreaA;
    @include gadgets-to(mobile) {

    grid-area:d;
    height:59svh;
    // margin-left: 10px;
    margin-top:1vh;

    @media(min-width:375px) and (max-width:380px){
        height: 62vh;
    
    }
    @media(min-width:393px){
        height: 61vh;
    
    }

    @media(min-width:393px){
        height: 61vh;
    
    }
    @media(min-width:428px){
        height: 62vh;
    
    }
}
@include gadgets-to(tablet) {
    grid-area:d;
    height:85vh;
    // margin-left: 10px;
   // margin-top:1vh;
}
@include gadgets-to(laptop) {
    grid-area:d;
    height:84vh;
    // margin-left: 10px;
 //   margin-top:1vh;
}
@include gadgets-to(desktop) {
    grid-area:d;
    height:84vh;
    // margin-left: 10px;
   // margin-top:1vh;
}
@include gadgets-to(largeDesktop) {
    grid-area:d;
    height:85vh;
    // margin-left: 10px;
 //   margin-top:1vh;
}
}



.xmwindowAreaC {
    @extend .xmwindowAreaA;
    @include gadgets-to(mobile) {

    grid-area:e;
    border: none;
    height:58vh;
    // margin-left: 2px;
    margin-top:2vh;
    // z-index: 1;
}
@include gadgets-to(tablet) {
    grid-area:e;
    border: none;
    height:85vh;
    // margin-left: 2px;
 //   margin-top: 37px;
    // z-index: 1;
    @media(min-width:1170px) and (max-width:1195px){
        height: 77vh;
       }
}
@include gadgets-to(laptop) {
    grid-area:e;
    border: none;
    height:84vh;
    // margin-left: 2px;
  //  margin-top: 37px;
    // z-index: 1;
}
@include gadgets-to(desktop) {
    grid-area:e;
    border: none;
    height:84vh;
    // margin-left: 2px;
 //   margin-top: 37px;
    // z-index: 1;

}
@include gadgets-to(largeDesktop) {
    grid-area:e;
    border: none;
    height:84vh;
    // margin-left: 2px;
 //   margin-top: 37px;
    // z-index: 1;
}
}



.xmheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;

    }
@include gadgets-to(laptop) {
        grid-area: a;
    }
@include gadgets-to(desktop) {
        grid-area: a;
         height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
    }

}
.xmcompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}
.xmcompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xmrangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
        //  @media(min-width:1081px) and (max-width:1170px)
        //  {
        //     height:20px;
        //  }
        //  // as ipad
        // //  @media(min-width:1171px) and (max-width:1195px)
        // //  {
         
        // //     height:auto;
        // //  }

        //  @media(min-width:1196px) and (max-width:1400px)
        //  {
        //     height:20px;
        //  }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}


.xmrange1 {
    @include gadgets-to(mobile) {
        grid-area:b;
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:20px;
        margin-right:2%;
        cursor:pointer;
        grid-area:b;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-right:10px;
        cursor:pointer;
        margin-left:10px;
    }
}

.xmrange2 {
    @extend .xmrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
        margin-right:1%;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}

.xmareaATop {
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
}

.xmareaAFieldset {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 70%;
    height:5.7vh;
    margin-left: 1px;
    background-color: map-get($generalStyles, transparent);
    margin-top:4px;
}

.xmareaALegend {
    font-size: 12px;
    font-family: map-get($generalStyles, fontFamily);
    color: map-get($generalStyles, colorblack);
    margin-left: 1px;
    margin-top: 10px;
}

.xmareaASelect {
    width: 99%;
    height: 30px;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: map-get($generalStyles, fontSizeSixteen);
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 1px;
    background-color: white;
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:16px;
    }
}

.xmareaAMiddle {
    margin-top: 1px;
}

.xmdataAwrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}

.xmareaADataArea {
    @include gadgets-to(mobile) {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width:90%;
    max-height:12vh;
    overflow-y: scroll;
  //  margin-left: 25px;

    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }

}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height:80vh;
    overflow-y:auto;
   // margin-left: 25px;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height:80vh;
    overflow-y:auto;
  //  margin-left: 25px;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height:80vh;
    overflow-y: scroll;
    margin-left: 25px;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93%;
    max-height:80vh;
    overflow-y: scroll;
    margin-left: 25px;
}
}

.xmareaADataButton {
    width: auto;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 3px;
    margin-top: 3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    font-size: 12px;
    font-family: map-get($generalStyles, fontFamily);
    outline: none;
    cursor: pointer;
    @include gadgets-to(desktop) {
        height:40px;
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:16px;
    }
}

.xmareaADataButton1 {
    @extend .xmareaADataButton;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}

.xmbtopWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;

}
.xmareaBTop {
@include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 100%;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 100%;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width:90%;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width: 80%;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    width:80%;
}
}

.xmingredSelectWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;

}
.xmrecipeWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;

}

.xmareaBFieldset {

    @include gadgets-to(mobile) {

    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height: 32px;
    background-color: transparent;
 //   margin-top: 10px;
}
@include gadgets-to(tablet) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height: 32px;
    background-color: transparent;
    margin-top: 10px;
}
@include gadgets-to(laptop) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height: 32px;
    background-color: transparent;
    margin-top: 10px;
}
@include gadgets-to(desktop) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height: 32px;
    background-color: transparent;
 //   margin-top: 10px;
}
@include gadgets-to(largeDesktop) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:50px;
    background-color: transparent;
 //   margin-top: 10px;
}
}

.xmareaBLegend {
    font-size: 12px;
    font-family: map-get($generalStyles, fontFamily);
    color: map-get($generalStyles, colorblack);
 //   margin-left: 10px;
    margin-top: 10px;
}

.xmareaBSelect {
    width:100%;
    height: 25px;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color: map-get($generalStyles, colorblack);
 //   margin-left: 1px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
    @include gadgets-to(largeDesktop) {
        height:35px;
        font-size:20px;
    }
}


.xmareaBMiddle{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items: center;
    width:100%;

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items: center;
    width:100%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items: center;
    width:90%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items: center;
    width:80%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items: center;
    width:100%;
}
}

.xmareaBHeaderTop{

    display: flex;
    flex-direction:column;
    justify-content:flex-start;
   // align-items: center;
    width:100%;
    @include gadgets-to(largeDesktop) {
 width:80%;
      //  margin-left: 5px;
    }
}

.xmareaBHeader {
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top: 1px;
  //  margin-left: 5px;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top: 1px;
  //  margin-left: 5px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:90%;
    margin-top: 1px;
  //  margin-left: 5px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:80%;
    margin-top: 1px;
  //  margin-left: 5px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:80%;
    margin-top: 1px;
  //  margin-left: 5px;
}
}


.xmareaBDataArea {
    @include gadgets-to(mobile) {
    // display: flex;
    // flex-direction: column;
    width: 100%;
    height:32vh;
    max-height:32vh;
    overflow-y: scroll;
    overflow-x: hidden;

    @media(min-width:375px) and (max-width:380px){
        height:35vh;
        max-height:35vh;

        @media(min-height:750px){
            height:40vh;
            max-height:40vh;
        }
    
    }
    @media(min-width:393px){
        height:37vh;
        max-height:37vh;
    
    }
    @media(min-width:428px){
        height:60vh;
        height: 60vh;
    
    }
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }
}
@include gadgets-to(tablet) {
    // display: flex;
    // flex-direction: column;
    width: 100%;
    height:58vh;
    max-height:58vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }


}
@include gadgets-to(laptop) {
    // display: flex;
    // flex-direction: column;
    width:90%;
    height:58vh;
    max-height:58vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }

}

@include gadgets-to(desktop) {
    // display: flex;
    // flex-direction: column;
    width:80%;
    height:58vh;
    max-height:58vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }


}
@include gadgets-to(largeDesktop) {
    // display: flex;
    // flex-direction: column;
    width:80%;
    height:58vh;
    max-height:58vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }


}
}
.xmareaBBottom{
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
width:100%;
margin-top:5px;
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    margin-top:5px;
    margin-left:10px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:90%;
    margin-top:5px;
    margin-left:10px;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:80%;
    margin-top:5px;
    margin-left:10px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:80%;
    margin-top:5px;
    margin-left:10px;
}
}

.xmdatalistRows{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    width:100%;
}

.xmareaBHeaderProduktid {
    width: 30px;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles,datalistBorderColor);
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-size: 12px;
    font-family: map-get($generalStyles, fontFamily);
    color: map-get($generalStyles, colorblack);
  //  margin-bottom: 2px;
  //  margin-right: 2px;
    padding: 1px;


}


.xmareaBHeaderBenaemn {
    @extend .xmareaBHeaderProduktid;
    @include gadgets-to(mobile) {
        width: 42%;
  
    width:42%;
}
@include gadgets-to(tablet) {
    width:42%;
}
@include gadgets-to(laptop) {
    width:42%;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:42%;
    height:30px;
    font-size: 14px;

}
@include gadgets-to(largeDesktop) {
    width:42%;
    height:35px;
    font-size: 16px;
}
}


.xmareaBHeaderAmount {
    @extend .xmareaBHeaderProduktid;
    @include gadgets-to(mobile) {
  
        width:calc(17% - 2px);
    margin-left:2px;
}
@include gadgets-to(tablet) {
    width:calc(17% - 2px);
    margin-left:2px;
}
@include gadgets-to(laptop) {
    width:calc(17% - 2px);
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:calc(17% - 2px);
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(largeDesktop) {
    width:calc(17% - 2px);
    margin-left:2px;
    height:35px;
    font-size: 16px;
}
}

.xmareaBHeaderTotalt {
    @extend .xmareaBHeaderProduktid;
    @include gadgets-to(mobile) {
    
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;

}
@include gadgets-to(tablet) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
}
@include gadgets-to(laptop) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(largeDesktop) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
    height:35px;
    font-size: 16px;
}
}

.xmareaBHeaderKalorier {
    @extend .xmareaBHeaderProduktid;
    @include gadgets-to(mobile) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
}
@include gadgets-to(tablet) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
}
@include gadgets-to(laptop) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(largeDesktop) {
    width:calc(17% - 2px);
    padding: 1px;
    margin-left:2px;
    height:35px;
    font-size: 16px;
}
}

.xmareaBHeaderDelete{
    @extend .xmareaBHeaderProduktid;
    @include gadgets-to(mobile) {
    width:calc(7% - 2px);
    padding: 1px;
    margin-left:2px;
}
@include gadgets-to(tablet) {
    width:calc(7% - 2px);
    padding: 1px;
    margin-left:2px;
}
@include gadgets-to(laptop) {
    width:calc(7% - 2px);
    padding: 1px;
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:calc(7% - 2px);
    padding: 1px;
    margin-left:2px;
    height:30px;
    font-size: 14px;
}
@include gadgets-to(largeDesktop) {
    width:calc(7% - 2px);
    padding: 1px;
    margin-left:2px;
    height:35px;
    font-size: 16px;
}
}


.xmnameHeaderInput {
    width:95%;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyleNone);
    background-color: transparent;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    color: map-get($generalStyles, colorblack);
    margin-left: 5px;
    @include gadgets-to(largeDesktop){
        font-size:30px;
    }
}

.xmdatalistProduktid {

    width: 30px;
    height: 25px;
    margin-top: 3px;
    outline: none;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    padding: 1px;
    color:black;
    @include gadgets-to(tablet) {
        
    width: 30px;
    height: 30px;
    font-size: 14px;
    }
    @include gadgets-to(laptop) {
          width: 30px;
          height: 30px;
          font-size: 14px;

    }
    @include gadgets-to(desktop) {
        width: 30px;
        height: 35px;
        font-size: 16px;
    

}
    @include gadgets-to(largeDesktop) {
          width: 30px;
          height: 35px;
          font-size: 16px;
    }
}

// .xmdatalistProduktid1 {
//     @extend .xmdatalistProduktid;
//     background-color: map-get($generalStyles, transparentChangeableMenu5);
// }

.xmdatalistBenaemn {
    @extend .xmdatalistProduktid;
    width:42%;
  //  margin-left: 3px;
    cursor: pointer;
    //ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.xmdatalistBenaemn1 {
    @extend .xmdatalistBenaemn;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xmdatalistMangd {
    @extend .xmdatalistProduktid;
    width:calc(17% - 2px);
    margin-left:2px;
}

.xmdatalistMangd1 {
    @extend .xmdatalistMangd;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xmdatalistPris {
    @extend .xmdatalistProduktid;
    width:calc(17% - 2px);
    margin-left:2px;
}

.xmdatalistPris1 {
    @extend .xmdatalistPris;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xmdatalistKalorier {
    @extend .xmdatalistProduktid;
    width:calc(17% - 2px);
    margin-left:2px;
}

.xmdatalistKalorier1 {
    @extend .xmdatalistKalorier;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
.xmdatalistDelete{
    @extend .xmdatalistKalorier;
    width:calc(7% - 2px);
    padding: 1px;
    margin-left:2px;
}
.xmdatalistDelete1 {
    @extend .xmdatalistDelete;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}


.xmdeleteImage {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 13px;
    // margin-left: 3px;
    // margin-top: 3px;
    cursor: pointer;
    padding:1px;
}

.xmeditproductTop {
width:100%;
}

.xmeditproductArea {
    @include gadgets-to(mobile) {
   display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width:100%;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    height: 60px;
   // margin-left: 5px;
    margin-top: 2px;
    border-radius:map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width:100%;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    height: 60px;
   // margin-left: 5px;
    margin-top: 2px;
    border-radius:map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width:100%;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    height: 60px;
   // margin-left: 5px;
    margin-top: 2px;
    border-radius:map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width:100%;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    height: 60px;
   // margin-left: 5px;
    margin-top: 2px;
    border-radius:map-get($generalStyles,sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width:100%;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    height: 60px;
   // margin-left: 5px;
    margin-top: 2px;
    border-radius:map-get($generalStyles,sixBorderRadius);
}
}

.xmeditproductPlusAmount {
    // All styles are commented out
}

.xmeditproductMinusAmount {
    // All styles are commented out
}

.xmplussign {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 42px;
    margin-top: 8px;
    margin-left: 10px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    cursor:pointer;
}

.xmminussign {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 42px;
    margin-top: 6px;
    margin-left: 5px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    cursor:pointer;
}

.xmproductnewSpinnerContainer {
@include gadgets-to(mobile) {
    position: fixed;
    top: 50%;
    left: 45%;
    background-color: transparent;
    transform: scale(2.0);
}
@include gadgets-to(tablet) {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: scale(2.0);
}
@include gadgets-to(laptop) {
    position: fixed;
    top: 50%;
    left:50%;
    background-color: transparent;
    transform: scale(2.0);
}
@include gadgets-to(desktop) {
    position: fixed;
    top: 50%;
    left:50%;
    background-color: transparent;
    transform: scale(2.0);
}
@include gadgets-to(largeDesktop) {
    position: fixed;
    top: 50%;
    left:50%;
    background-color: transparent;
    transform: scale(2.0);
}
}


.xmproducNewSpinner {
    color: map-get($generalStyles, colorblack);
}

.xmsaveproductButton {
    width: 70px;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorwhite);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 32px;
    margin-top: 4px;
    cursor: pointer;
    opacity: 0.5;
}

.xmsaveproductButtonActive {
    width: 70px;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: white;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 32px;
    margin-top: 4px;
    cursor: pointer;
    border: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
}

.xmbuttonTop {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  //  margin-left: 13px;
}

.xmsaveOldproductButton {
    width: 70px;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 72px;
    margin-top: 4px;
    cursor: pointer;
    opacity: 0.5;
}

.xmsaveOldproductButtonActive {
    width: 70px;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 72px;
    margin-top: 4px;
    cursor: pointer;
    opacity: 1;
}

.xmsaveButtonOldIcon {
    color: white;
    font-size: 22px;
    cursor: pointer;
    margin-left: 5px;
    opacity: 1;
}

.xmsaveButtonIcon {
    color: white;
    font-size: 22px;
    cursor: pointer;
    margin-left: 5px;
    opacity: 1;
}

.xmsaveButtonIconActive {
    color: white;
    font-size: 26px;
    cursor: pointer;
    margin-left: 5px;
}




.xmsaveRecepyMenuTop {
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
}

.xmsaveRecepyMenuArea {
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:98%;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:90%;
    height:20svh;
    border-radius: map-get($generalStyles, sixBorderRadius);

    @media(max-width:360px) and (max-height:740px)
    {
        height:25vh;
    }

 
    


}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:100%;
    height:20svh;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:15vh;
    width:100%;
    height:20svh;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:15vh;
    width:100%;
    height:20svh;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    height:15vh;
    width:100%;
    height:20svh;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
}


.xmsaveRecepyMenuFieldset {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    border: none;
    width:100%;
    height:auto;
   // margin-left: 45px;
    background-color: transparent;
    margin-top: 2px;

}
@include gadgets-to(tablet) {
    border: none;
    width:90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
}
@include gadgets-to(laptop) {
    border: none;
    width:90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
}
@include gadgets-to(desktop) {
    border: none;
    width:90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
}
@include gadgets-to(largeDesktop) {
    border: none;
    width:90%;
    height: 95px;
    background-color: transparent;
    margin-top: 2px;
}
}
.xmsaveLegendWrapper{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:90%;
    margin-bottom:3px;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:90%;
    margin-bottom:3px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:90%;
    margin-bottom:3px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:90%;
    margin-bottom:3px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:90%;
    margin-bottom:3px;
}
}

.xmsaveRecepyMenuLegend {
    font-size: 14px;
    font-family: map-get($generalStyles,fontFamily);
    color: map-get($generalStyles,colorblack);
  //  margin-left:6%;
    margin-top: 10px;
}

.xmsaveRecepyMenuInput2 {
    width: 90%;
    height: 30px;
    border: solid 1px;
    border-color: map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 10px;
  //  margin-left: 10px;
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing:border-box;
}


.xmselectArea{
    margin-top:5px;
    width:100%;
}
.xmsaveproductselect {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
    height: 30px;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 5px;
  //  margin-left: 10px;
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
    box-sizing: border-box;
}

.xmsaveRecepyMenuButtonWrapper{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
   // margin-right:10%;
    //align-items:center;
    width:90%;
}
.xmsaveRecepyMenuButton {
    width: 100px;
    height: 30px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
 //   margin-left: 155px;
    margin-top: 5px;
    cursor: not-allowed;
    opacity: 0.8;
    border:none;
}

.xmsaveRecepyMenuButtonActive {
    width: 100px;
    height: 30px;
     background-color: map-get($generalStyles, pinkRed);
    border:none;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    margin-left: 155px;
    margin-top: 5px;
    cursor: pointer;
    opacity: 1;
    color:white;
}
.xmtrashContainer{
    font-size:22px;
    color:map-get($generalStyles,transparentChangeableMenu5);
      cursor: pointer;
    //  margin-right:4%;
      border:none;
      border-color:map-get($generalStyles,transparentChangeableMenu5);
      padding:10px 8px 8px 8px;
      height:25px;
      border-radius: 50%;
      background-color:map-get($generalStyles,transparentChangeableMenu5);
      color:white;
   //   margin-bottom:5px;

   @include gadgets-to(largeDesktop) {
    font-size:22px;
    color:map-get($generalStyles,transparentChangeableMenu5);
      cursor: pointer;
    //  margin-right:4%;
      border:none;
      border-color:map-get($generalStyles,transparentChangeableMenu5);
      padding:14px 12px 8px 12px;
      height:45px;
      border-radius: 50%;
      background-color:map-get($generalStyles,transparentChangeableMenu5);
      color:white;
  
}
}

.xmTrash {
    // position: absolute;
    // top: 740px;
    // left: 15px;
    color:white;
    font-size:24px;
    cursor: pointer;
    opacity: 1;
    @include gadgets-to(largeDesktop) {
        font-size:36px;
    
    }
}

.xmTrashHovered {
    // position: absolute;
    // top: 905px;
    // left: 600px;
    color: red;
    font-size:30px;      
    cursor: pointer;
    opacity: 1;
    @include gadgets-to(largeDesktop) {
        font-size:36px;
    
    }
}
.xmareacFieldsetWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
   width:88%;

}
.xmareaCTop {
    @include gadgets-to(mobile) {

    display: flex;
    flex-direction: row;
    justify-content: center;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
   
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
}


.xmareaCFieldset {
    @include gadgets-to(mobile) {

    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:45vh;
    background: transparent;
    margin-top: 5px;
}
@include gadgets-to(tablet) {
    border: solid 1px;
    border-color: map-get($generalStyles,datalistBorderColor);
    width:98%;
    height:67vh;
    background: transparent;
    margin-top: 5px;
}
@include gadgets-to(laptop) {
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:60vh;
    background: transparent;
    margin-top: 5px;
}
@include gadgets-to(desktop) {
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:60vh;
    background: transparent;
    margin-top: 5px;
}
@include gadgets-to(largeDesktop) {
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:60vh;
    background: transparent;
    margin-top: 5px;
}
}
.xmareaCFieldsetJAP {
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:fit-content;
    background: transparent;
    margin-top: 5px;
}

.xmareaCLegend {
    font-size: 16px;
    font-family: map-get($generalStyles,fontFamily);
    color: map-get($generalStyles,colorblack);
   // margin-left: 10px;
    margin-top: 10px;
}

.xmnutritionMix {
    color: map-get($generalStyles,transparentChangeableMenu5);
    font-size: 26px;
}



.xmproduktKalkylinfoTop {
    // No styles defined
}

.xmproduktkalkylinfoArea {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorblack);
    margin-top: 5px;
    margin-left: 1px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;

    }
}


.xmnutrientInfoTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-right: 10px;
    margin-top: 5px;
    margin-left: 1px;
}

.xmnutrientInfoAreaHeader {
    border-color: map-get($generalStyles, datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-bottom: 5px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoKalorierHeader {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-bottom: 1px;
    color: map-get($generalStyles, colorBrown);
    width: 50%;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoKalorier {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoProteinHeader {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, greenNatureColor);
    margin-bottom: 1px;
    margin-top: 1px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoProtein {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoFatHeader {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, pinkRed);
    margin-bottom: 1px;
    margin-top: 1px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoFat {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoCarbohydrateHeader {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color: map-get($generalStyles, colorOrange);
    margin-bottom: 1px;
    margin-top: 1px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmnutrientInfoCarbohydrate {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
    }
}

.xmsaveRecepyMenuTopJAP {
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
}

.xmsaveRecepyMenuFieldsetJAP {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    border: none;
    width:100%;
    height:auto;
   // margin-left: 45px;
    background-color: transparent;
    margin-top: 2px;
}
}

.xmsaveRecepyMenuLegendJAP {
    font-size: 14px;
    font-family: map-get($generalStyles, fontFamily);
    color: map-get($generalStyles, colorblack);
  //  margin-left:6%;
    margin-top: 10px;
}

.xmsaveRecepyMenuAreaJAP {
    @include gadgets-to(mobile) {

        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width:98%;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        width:90%;
        height:25svh;
        border-radius: map-get($generalStyles, sixBorderRadius);
    
        @media(max-width:360px) and (max-height:740px)
        {
            height:27vh;
        }
    
     
        
    
    
    }
}

.xmsaveRecepyMenuInput2JAP {
    width: 90%;
    height: 30px;
    border: solid 1px;
    border-color: map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 10px;
  //  margin-left: 10px;
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing:border-box;
}

.xmsaveRecepyMenuFuriganaInputJAP {
    // width: 85%;
    // height: 20px;
    // border: 1px solid;
    // border-color: map-get($generalStyles, datalistBorderColor);
    // font-family: map-get($generalStyles, fontFamily);
    // font-size: 14px;
    // margin-top: 10px;
    // margin-left: 35px;
    // outline: none;
    width: 90%;
    height: 30px;
    border: solid 1px;
    border-color: map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 10px;
  //  margin-left: 10px;
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    box-sizing:border-box;
}

.xmselectAreaJAP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-right: 10px;
}

.xmsaveproductselectJAP {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
    height: 30px;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 5px;
  //  margin-left: 10px;
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
    box-sizing: border-box;
}

.xmsaveRecepyMenuButtonJAP {
    width: 100px;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
 //   margin-left: 155px;
    margin-top: 3px;
    cursor: not-allowed;
    opacity: 0.5;
}

.xmsaveRecepyMenuButtonActiveJAP {
    width: 100px;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, pinkRed);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    margin-left: 155px;
    margin-top: 3px;
    cursor: pointer;
    opacity: 1;
}

.xmimageUploadContainer{
    display:flex;
    flex-direction:column;
   // justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    margin-top:5px;

}
.xmimageUploadWrapper{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    margin-top:5px;
}
.xminputimageUpload{
    display:none;
}

.xmfileLabel{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex-shrink: 0;
    margin-left:5px;
    height:25px;
    width:120px;
    //padding:3px;
    border-radius: map-get($generalStyles, sixborderRadius);
    background-color:map-get($generalStyles, transparentChangeableMenu5);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
}

.xmbuttonUploadImageWrapper{
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    width:50%;
}
.xmbuttonUploadImage{
    margin-left:5px;
    width:120px;
    height: 30px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    cursor:pointer;
    margin-top: 5px;
    opacity:0.7;
}
.xmbuttonUploadImage1{
    @extend .xmbuttonUploadImage;
    background-color: map-get($generalStyles, pinkRed);
    color: white;
    cursor:pointer;
}


.xmfileName{
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    margin-left:3px;
    //elippsis
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    width:100%;
    display:flex;
    flex-wrap:wrap;
}

.xmimageUploadMessage{

    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    margin-top:5px;
    margin-left:7px;
    @include gadgets-to(mobile) {
        font-size: 11px;
    }
    @include gadgets-to(tablet) {
        font-size: 12px;
    }
    @include gadgets-to(laptop) {
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        font-size: 14px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 14px;
    }
}

.xmingredcommentWrapper{
    display:flex;
    flex-direction:row;
    width:100%;


}

.xmanimateddivCreateProduct{
  width:100%;
  transform:rotate(0deg);
  animation-name: identifierCreateProduct;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  
    }

    @keyframes identifierCreateProduct {
      0%{ height:0px }
      100%{height:65px}
      }


.xmimageinput{
    @include gadgets-to(mobile) {
    width:70%;
    height:15svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(tablet) {
    width:70%;
    height:15svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(laptop) {
    width:70%;
    height:15svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(desktop) {
    width:70%;
    height:15svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(largeDesktop) {
    width:70%;
    height:15svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:24px;
    resize:none;
}
}

.xmcreateProductJAPMobile{
    margin-left: 7px;
    width:95%;
    outline:none;
    border:none;
    border-radius: 3px;
  }

  .xmcreatenewitem{
    font-size: 30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    cursor:pointer;

    @include gadgets-to(largeDesktop){
        font-size:40px;
    }
    

  }
  .xmcreatenewitem1{
    font-size: 30px;
    color:map-get($generalStyles,transparentChangeableMenu5); 
    cursor:pointer;
    @include gadgets-to(largeDesktop){
        font-size:40px;
    }
}

  .xmcreateNewItemWrapper{
display:flex;   
flex-direction:column;
justify-content:center;
//align-items:f;
  }

  .xmtab-button2{

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:rgb(235, 231, 231);
    padding:3px 4px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
   // border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    border-top-right-radius:12px;
 
 
  }
.xmtab-button1{
    @extend .xmtab-button2;
    background-color:white;
}
  .xmproduktPris{
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    @include gadgets-to(largeDesktop){
        font-size:30px;
    }
  }
.xmImageContainerWrapper{
    @include gadgets-to(largeDesktop) {

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:5px;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:5px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:5px;
}
}
.xmnochosenProduktid{
font-family: map-get($generalStyles, fontFamily);
font-size: 10px;
margin-left:3px;    
color:red;
}

.xmbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }
  .xmbuttonIcon {
    position: absolute;
    top: 0;
    right: 0;
    color:gold;
    font-size:18px;
    // border: 1px solid white;
    // border-radius: 50%; /* Makes the border circular */
    // padding: 2px; /* Adjust as needed */
 
}
.xmbuttonContent{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 10px; // Adjust as needed
    overflow: hidden; // Add this line
}
.xmDescriptionTextarea {
    width: 95%;
    height:30vh;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px;
    font-size: 16px;
    font-family:var(--font-family);
    resize: none;
    outline: none;
    box-sizing: border-box;
    margin-top: 5px;
    margin-left: 1px;
 
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }



}
.xmDescriptionTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:5px;
}
.xmButtonWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    width:95%;
    margin-top:5px;
}
.xmSaveProductButton{
    width: 120px;
    height: 30px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 6px;
    margin-top: 5px;
    cursor: pointer;
    opacity: 0.7;
    border:none;
}
.xmSaveProductButton1{

@extend .xmSaveProductButton;
background-color: map-get($generalStyles,pinkRed);
color:white;
opacity: 1;
}
.xmTextLength{
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: red;
    margin-top: 5px;
    margin-left: 1px;
}
.xmIconText{
  
    @include gadgets-to(mobile) {

font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 10px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(tablet) {
    white-space: nowrap;
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(laptop) {
    white-space: nowrap;
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(desktop) {
    white-space: nowrap;
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(largeDesktop) {
    white-space: nowrap;
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 16px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
}