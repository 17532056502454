@import '../style/MainStyles.scss';/* styles.css */



.xptotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     width:100%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     width:100%;
}
}



.xpProdTotalArea {
    @include gadgets-to(mobile) {
        display: grid;
       grid-template-rows: 4.5% 5% 46% 44%;
      //  grid-template-rows:auto auto auto auto;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d" ;
        width: 100%;
        height:auto;
        object-fit: cover;



    }


    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:50% 49%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d d";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        .area-f {
            grid-area: d;
            // style for the tabs
            .tabs {
              display: flex;
              justify-content: space-between;
            }
            .tab-content {
              display: none;
            }
          }
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:52% 48%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d d";
          column-gap:7px;
        width:90%;
        height: 100%;
        object-fit: cover;
     
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:52% 48%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d d";
          column-gap:15px;
        width:90%;
        height: 100%;
        object-fit: cover;


    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:52% 48%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 99.8%;
        height: 100%;
        object-fit: cover;
    }

}

.xpProdTotalArea1 {
    @extend .xpProdTotalArea;
}

.xpProdTotalWindowA {
    @include gadgets-to(mobile) {

    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:40vh;
    background-color: map-get($generalStyles, transparentChangeable);
  //  margin-top:5px;
  border-radius: map-get($generalStyles, sixBorderRadius);
}
    @include gadgets-to(tablet) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:84vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        @media(min-width:1170px) and (max-width:1190px)
        {
            height:78vh;
        }
    }
    @include gadgets-to(laptop) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(desktop) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
    @include gadgets-to(largeDesktop) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:100%;
        height:90vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5px;
        border-radius: map-get($generalStyles, sixBorderRadius);
    }
}

.xpProdTotalWindowB {
    @extend .xpProdTotalWindowA;
    @include gadgets-to(mobile) {
    grid-area: d;
    display: flex;
    flex-direction: column;
    height:39vh;
    margin-top:5px;
}
    @include gadgets-to(tablet) {
        grid-area: d;
        display: flex;
        flex-direction: column;
        height:84vh;
        margin-top:5px;
        width:100%;
        @media(min-width:1170px) and (max-width:1190px)
        {
            height:78vh;
        }
    }
    @include gadgets-to(laptop) {
        grid-area: d;
        display: flex;
        flex-direction: column;
        height:82vh;
        margin-top:5px;
    }
    @include gadgets-to(desktop) {
        grid-area: d;
        display: flex;
        flex-direction: column;
        height:82vh;
        margin-top:5px;
    }
    @include gadgets-to(largeDesktop) {
        grid-area: d;
        display: flex;
        flex-direction: column;
        height:90vh;
        margin-top:5px;
    }
}
.xpheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;

    }
@include gadgets-to(laptop) {
        grid-area: a;
    }
@include gadgets-to(desktop) {
        grid-area: a;
         height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
    }


}
.xprangeArea{
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:4vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:20px;
         }
         // as ipad
        //  @media(min-width:1171px) and (max-width:1195px)
        //  {
         
        //     height:auto;
        //  }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:20px;
         }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    width:100%;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}

.xprange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
   
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:10px;
        cursor:pointer;
    }
}

.xprange2 {
    @extend .xprange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}

.xpCompName {
    grid-area:a;
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xpCompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }


}



.xpProdTotalFieldset {
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    align-items: center;
  //  justify-content: center;

    width:100%;
    margin-top: 5px;
    height: 300px;
    border: none;
    margin:0;
    padding:0;
}
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction: column;
        align-items: center;
//        justify-content: center;
        width:100%;
        margin-top: 5px;
        height:80vh;
        border: none;
        margin:0;
        padding:0;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction: column;
        align-items: center;
      //  justify-content: center;
        width:100%;
        margin-top:1vh;
        height:80vh;
        border: none;
        margin:0;
        padding:0;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction: column;
        align-items: center;
      //  justify-content: center;
        width:100%;
        margin-top:1.2vh;
        height:80vh;
        border: none;
     //   margin:0;
        padding:0;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        width:100%;
        margin-top:1.2vh;
        height:80vh;
        border: none;
       // margin:0;
        padding:0;
    }
}

.xpProdTotalLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    margin-left: 2px;
}
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles,fontFamily);
        font-size: 16px;
        margin-left:5%;
        padding-top:5px;
   
    }
    @include gadgets-to(laptop) {
        font-family: map-get($generalStyles,fontFamily);
        font-size: 16px;
        margin-left:5%;
        padding-top:5px;
  
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles,fontFamily);
        font-size: 18px;
        margin-left:7.5%;
        padding-top:5px;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles,fontFamily);
        font-size: 30px;
        margin-left:10%;
        padding-top:10px;
    }
}

.xpProdTotalHeaderArea {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    width:100%;
    border: none;
   // margin-left: 4px;
}
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        width:90%;
        border: none;
       // margin-left: 4px;
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        width:90%;
        border: none;
      //  margin-left: 4px;
    }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        width:85%;
        border: none;
       // margin-left: 4px;
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        width:80%;
        border: none;
    //    margin-left: 4px;
    }
}


.xpProdTotalProduktionsId {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   // border: map-get($generalStyles, prefferedBorderStyle);
  //  border-color: map-get($generalStyles, datalistBorderColor);
    width:calc(15% - 2px);
    height: 25px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
   // margin-left: 1px;
    margin-top: 3px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    cursor: pointer;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:calc(15% - 2px);
    height: 25px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 1px;
    margin-top: 3px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    cursor: pointer;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:calc(15% - 2px);
    height: 25px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
   // margin-left: 1px;
    margin-top: 3px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    cursor: pointer;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:calc(15% - 2px);
    height: 35px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
   // margin-left: 1px;
    margin-top: 3px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    width:calc(15% - 2px);
    height:40px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
   // margin-left: 1px;
    margin-top: 3px;
    font-family: map-get($generalStyles, fontFamily);
    font-size:20px;
    cursor: pointer;
}
}
.xpProdTotalProduktid {
    @extend .xpProdTotalProduktionsId;
    width:calc(15% - 2px);
    font-size: 14px;
    margin-left:2px;
}

.xpProdTotalBenaemn {
    @extend .xpProdTotalProduktionsId;
    width:calc(40% - 2px);
   // width: 148px;
    margin-left:2px;
  
}

.xpProdTotalAntal {
    @extend .xpProdTotalProduktionsId;
    width:calc(15% - 2px);
   // width: 40px;
    margin-left:2px;
}

.xpProdTotalPris {
    @extend .xpProdTotalProduktionsId;
    width:calc(15% - 2px);
  //  width: 40px;
    margin-left:2px;
}

.xpProdTotalTot {
    @extend .xpProdTotalProduktionsId;
    @include gadgets-to(mobile) {
    width:15%; // no calc here we we balance out the scrollbar
  //  width: 50px;
    margin-left:2px;
}
@include gadgets-to(tablet) {
    width:15%; // no calc here we we balance out the scrollbar
  //  width: 50px;
    margin-left:2px;
}
@include gadgets-to(laptop) {
    width:15%; // no calc here we we balance out the scrollbar
  //  width: 50px;
    margin-left:2px;
    margin-right:7px;

}
@include gadgets-to(desktop) {
    width:15%; // no calc here we we balance out the scrollbar
  //  width: 50px;
    margin-left:1px;
    margin-right:8px;
}
@include gadgets-to(largeDesktop) {
    width:15%; // no calc here we we balance out the scrollbar
  //  width: 50px;
    margin-left:1px;
    margin-right:14px;
}
}




.xptotallist1Data {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: column;
    max-height:32vh;
    overflow-y: scroll;
    width:100%;
   // margin-left: 4px;
   // small scrollbar
   //webkit
    &::-webkit-scrollbar {
     width: 1px;
}
&::-webkit-scrollbar-track {
     background: transparent;
}
&::-webkit-scrollbar-thumb {
     background: map-get($generalStyles, datalistBorderColor);
}
}
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        height:80vh;
        max-height:80vh;
        overflow-y: scroll;
        width:90%;
        outline:none;
       // margin-left: 4px;
       // small scrollbar
       //webkit
        &::-webkit-scrollbar {
         width: 1px;
    }
    &::-webkit-scrollbar-track {
         background: transparent;
    }
    &::-webkit-scrollbar-thumb {
         background: map-get($generalStyles, datalistBorderColor);
    }

    @media(min-width:1170px) and (max-width:1190px)
    {
        height:70vh;
        max-height:70vh;
    }
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: column;
        height:auto;
        max-height:80vh;
        overflow-y: scroll;
        width:90%;
        outline:none;
        &::-webkit-scrollbar {
            width: 10px;
       }
       &::-webkit-scrollbar-track {
            background:map-get($generalStyles, transparentChangeableMenu5);
       }
       &::-webkit-scrollbar-thumb {
            background:white;
            border-radius:10px;
            border:1px solid map-get($generalStyles,transparentChangeableMenu5);       
        }

}
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        height:auto;
        max-height:80vh;
        overflow-y: scroll;
        width:85%;
        &::-webkit-scrollbar {
            width: 10px;
       }
       &::-webkit-scrollbar-track {
            background:map-get($generalStyles, transparentChangeableMenu5);
       }
       &::-webkit-scrollbar-thumb {
            background:white;
            border-radius:10px;
            border:1px solid map-get($generalStyles,transparentChangeableMenu5);       
        }
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        height:auto;
        max-height:65vh;
        overflow-y:scroll;
        width:80%;
        &::-webkit-scrollbar {
            width: 10px;
       }
       &::-webkit-scrollbar-track {
            background:map-get($generalStyles, transparentChangeableMenu5);
       }
       &::-webkit-scrollbar-thumb {
            background:white;
            border-radius:10px;
            border:1px solid map-get($generalStyles,transparentChangeableMenu5);       
        }

    }
}

.xptotalproduktionid {
    width:15%;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-top: 1px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size: 20px;
    }
}

.xptotalproduktionid1 {
    @extend .xptotalproduktionid;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xptotalproduktid {
    @extend .xptotalproduktionid;
    width:calc(15% - 2px);
    margin-left: 2px;
}

.xptotalproduktid1 {
    @extend .xptotalproduktionid1;
    width:calc(15% - 2px);
    margin-left: 2px;

}

.xptotalbenaemn {
    @extend .xptotalproduktionid;
    width:calc(40% - 2px);
    margin-left: 2px;
      //elippsis
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
}

.xptotalbenaemn1 {
    @extend .xptotalproduktionid1;
    width:calc(40% - 2px);
    margin-left: 2px;
      //elippsis
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
}

.xptotalantal {
    @extend .xptotalproduktionid;
    width:calc(15% - 2px);
    margin-left: 1px;
}

.xptotalantal1 {
    @extend .xptotalproduktionid1;
    width:calc(15% - 2px);
    margin-left:2px;
}

.xptotalpris {
    @extend .xptotalproduktionid;
    width:calc(15% - 2px);
    margin-left: 2px;
}

.xptotalpris1 {
    @extend .xptotalproduktionid1;
    width:calc(15% - 2px);
    margin-left: 2px;
}

.xptotallisttotal {
    @extend .xptotalproduktionid;
    width:calc(15% - 2px);
    margin-left: 2px;
}

.xptotallisttotal1 {
    @extend .xptotalproduktionid1;
    width:calc(15% - 2px);
    margin-left: 2px;
}



.xpProdTotalSummaryBottom {
    width:100%;
}

.xpProdTotalSummaryBottomAntal {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
   // margin-left: 15px;
    margin-top: 8px;
}
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
        margin-left:5%;
        margin-top: 8px;
    }
    @include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
        margin-left: 15px;
        margin-top: 8px;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
        margin-left:5%;
        margin-top: 8px;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
        margin-left: 15px;
        margin-top: 8px;
    }
}

.xpProdTotalSummaryText {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    white-space: nowrap;
    margin-left:3px;
}
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 16px;
        white-space: nowrap;
        margin-left:3px;
    }
    @include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 16px;
        white-space: nowrap;
        margin-left:3%;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 18px;
        white-space: nowrap;
        margin-left:3%;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 30px;
        white-space: nowrap;
        margin-left:4%;
    }
}

.xpProdTotalSummaryFieldset {
    @include gadgets-to(mobile) {
    width:100%;
 //   margin-left: 1px;
    margin-top: 8px;
    height: 300px;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    padding:0;
    margin:0;
}
    @include gadgets-to(tablet) {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
       //justify-content: center;

      //  margin-left: 1px;
        margin-top: 8px;
        height: 80vh;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        padding:0;
        margin:0;
    }
    @include gadgets-to(laptop) {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
   //    justify-content: center;

        margin-left: 1px;
        margin-top: 8px;
        height:80vh;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        padding:0;
        margin:0;
    }
    @include gadgets-to(desktop) {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
  //     justify-content: center;
        margin-left: 1px;
        margin-top: 8px;
        height: 300px;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        padding:0;
        margin:0;
    }
    @include gadgets-to(largeDesktop) {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
       justify-content: center;
        margin-left: 1px;
        margin-top: 8px;
        height: 300px;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        padding:0;
        margin:0;
    }
}

.xpProdTotalSummaryTopLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
}
    @include gadgets-to(tablet) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        margin-left:5%;
    }
    @include gadgets-to(laptop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        margin-left:5%;
    }
    @include gadgets-to(desktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
        margin-left:8%;
    }
    @include gadgets-to(largeDesktop) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 20px;
        margin-left:5%;
    }
}

.xpstatusheaderMain {
    @include gadgets-to(mobile) {
    margin:0;
    padding:0;
    width:100%;

}
    @include gadgets-to(tablet) {
        margin:0;
        padding:0;
        width:90%;
    }
    @include gadgets-to(laptop) {
        margin:0;
        padding:0;
        width:90%;
    }
    @include gadgets-to(desktop) {
        margin:0;
        padding:0;
        width:85%;
    }
    @include gadgets-to(largeDesktop) {
        margin:0;
        padding:0;
        width:90%;
    }
}

.xpstatusheaderHeader {
   // margin-left: 2px;
   display:flex;
    flex-direction: row;
    width: 100%;
    margin:0;
    padding:0;
}

.xpstatusheaderData {
    @include gadgets-to(mobile) {
  //  margin-left: 2px;
    width:100%;
    height:28vh;
    max-height:28vh;
    overflow-y: scroll;
    margin:0;
    padding:0;

    &::-webkit-scrollbar {
        width: 1px;
   }
   &::-webkit-scrollbar-track {
        background: transparent;
   }
   &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles, datalistBorderColor);
   }
}
    @include gadgets-to(tablet) {
        width:100%;
        height:80vh;
        max-height:80vh;
        overflow-y: scroll;
        margin:0;
        padding:0;
        &::-webkit-scrollbar {
            width: 1px;
       }
       &::-webkit-scrollbar-track {
            background: transparent;
       }
       &::-webkit-scrollbar-thumb {
            background: map-get($generalStyles, datalistBorderColor);
       }
       @media(min-width:1170px) and (max-width:1190px)
       {
           height:68vh;
           max-height:68vh;
       }
    }
    @include gadgets-to(laptop) {
        width:100%;
        height:auto;
        max-height:72vh;
        overflow-y: scroll;
        margin:0;
        padding:0;
        &::-webkit-scrollbar {
            width:10px;
       }
       &::-webkit-scrollbar-track {
            background:map-get($generalStyles, transparentChangeableMenu5);
       }
       &::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 6px;
            border:1px solid;
            
    }
    }
    @include gadgets-to(desktop) {
        width:100%;
        height:auto;
        max-height:76vh;
        overflow-y:scroll;
        margin:0;
        padding:0;
        &::-webkit-scrollbar {
            width:10px;
       }
       &::-webkit-scrollbar-track {
            background:map-get($generalStyles, transparentChangeableMenu5);
       }
       &::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 6px;
            border:1px solid;
            
    }
    }
    @include gadgets-to(largeDesktop) {
        width:100%;
        height:auto;
        max-height:77vh;
        overflow-y: scroll;
        margin:0;
        padding:0;
        &::-webkit-scrollbar {
            width:10px;
       }
       &::-webkit-scrollbar-track {
            background:map-get($generalStyles, transparentChangeableMenu5);
       }
       &::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 6px;
            border:1px solid;
            
    }
    }
}

.xpProductionStatisticTop {
}

.xpstatusheaderProduktionsid {
    width:calc(15%);
    height: 25px;
   // margin-left: 1px;
    margin-bottom: 2px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-color: map-get($generalStyles, datalistBorderColor);
    color:black;
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size: 20px;
    }
}

.xpstatusheaderProduktid {
    @extend .xpstatusheaderProduktionsid;
    width:calc(15% - 2px);
    margin-left:2px;
}

.xpstatusheaderBenaemn {
    @extend .xpstatusheaderProduktionsid;
    width:calc(40% - 2px);
    margin-left:2px;
}

.xpstatusheaderSum {
    @extend .xpstatusheaderProduktionsid;
    width:calc(15% - 2px);
    margin-left:2px;
    padding:1px;
}

.xpstatusheaderNum {
    @extend .xpstatusheaderProduktionsid;
    width:calc(15% - 2px);
    margin-left: 2px;
    @include gadgets-to(laptop) {
        margin-right:10px;
    }
    @include gadgets-to(desktop) {
        margin-right:10px;
    }
    @include gadgets-to(largeDesktop) {
        margin-right:10px;
    }
 
}

.xpsamproduktionid {
    @extend .xpstatusheaderProduktionsid;
    width:15%;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-top: 1px;
   // margin-left: 2px;
}

.xpsamproduktionid1 {
    @extend .xpsamproduktionid;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    width:15%;
}

.xpsamkategoriid {
    @extend .xpsamproduktionid;
    width:calc(15% - 2px);
    margin-left:2px;
}

.xpsamkategoriid1 {
    @extend .xpsamproduktionid1;
    width:calc(15% - 2px);
    margin-left:2px;
}

.xpsambenaemn {
    @extend .xpsamproduktionid;
    width:calc(40% - 2px);
    margin-left:2px;
}

.xpsambenaemn1 {
    @extend .xpsamproduktionid1;
    width:calc(40% - 2px);
    margin-left:2px;
}

.xpsamsum {
    @extend .xpsamproduktionid;
    width:calc(15% - 2px);
    padding:1px;
    margin-left:2px;
}

.xpsamsum1 {
    @extend .xpsamproduktionid1;
    width:calc(15% - 2px);  
    padding:1px;
    margin-left:2px;
}

.xpsamtotal {
    @extend .xpsamproduktionid;
    width:calc(15% - 2px);
    margin-left:2px;
}

.xpsamtotal1 {
    @extend .xpsamproduktionid1;
    width:calc(15% - 2px);
    margin-left:2px;
}


.xpbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }

  .xpProdTotalSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xpProdtotalSpinner {
    color: map-get($generalStyles, colorblack);
}
