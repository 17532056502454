@import '../src/components/style/MainStyles.scss';/* styles.css */


// /* Global styles */
// input[type="range"] {
//     margin: auto;
//     -webkit-appearance: none;
//     appearance: none;
//     position: relative;
//     overflow: hidden;
//     height: 15px;
//     width: 100px;
//     cursor: pointer;
//     border-radius: 6px; /* iOS */
    
//     @include gadgets-to(mobile){
//         width: 100px;
//     }
//     @include gadgets-to(tablet){
//         width: 150px;
//     }
//     @include gadgets-to(laptop){
//         width: 200px;
//     }
//     @include gadgets-to(desktop){
//         width: 200px;
//     }
//     @include gadgets-to(largeDesktop){
//         width: 200px;
//     }
// }

// ::-webkit-slider-runnable-track {
//     -webkit-appearance: none;
//     background: #ddd;
// }

// ::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     width: 20px;
//     height: 40px;
//     background: #fff;
//     box-shadow: -200px 0 0 200px map-get($generalStyles,transparentChangeableMenu5);
//     border: 2px solid #999;
// }

// /* Custom slider styles - increase specificity */
// input.custom-slider[type="range"] {
//   //  width:1 !important; /* Override width */
//     height: 10px !important;  /* Override height */

//     @include gadgets-to(mobile){
//                 width:72%;
//                 height:10px;
//             }
//             @include gadgets-to(tablet){
//                 width:72%;
//                 height:15px;
//             }
//             @include gadgets-to(laptop){
//                 width:72%;
//             }
//             @include gadgets-to(desktop){
//                 width: 72%;
//                 height: 15px !important;
//             }
//             @include gadgets-to(largeDesktop){
//                 width: 200px;
//             }
// }

// input.custom-slider[type="range"]::-webkit-slider-runnable-track {
//     background: #ddd !important; /* Custom track background */
// }

// input.custom-slider[type="range"]::-webkit-slider-thumb {
//     -webkit-appearance: none !important;
//     width: 20px !important;
//     height: 30px !important;
//     background: #fff !important;
//     box-shadow: -200px 0 0 200px map-get($generalStyles,transparentChangeableMenu5) !important;
//     border: 2px solid #999 !important;
// }

// input.custom-slider[type="range"]::-moz-range-track {
//     background: #f06c00 !important;
// }

// input.custom-slider[type="range"]::-moz-range-thumb {
//     width: 20px;
//     height: 30px;
//     background: #fff;
//     box-shadow: -200px 0 0 200px map-get($generalStyles,transparentChangeableMenu5);
//     border: 2px solid #999;
// }

// input.custom-slider[type="range"]::-ms-fill-lower { 
//     background: #f06c00 !important;
// }

// input.custom-slider[type="range"]::-ms-thumb { 
//     width: 20px;
//     height: 40px;
//     background: #fff;
//     box-shadow: -200px 0 0 200px map-get($generalStyles,transparentChangeableMenu5);
//     border: 2px solid #999;
// }


// /* Global styles */
// input[type="range"] {
//     -webkit-appearance: none;
//     margin: auto;
//     position: relative;
//     height: 15px;
//     width: 100px;
//     cursor: pointer;
//     border-radius: 6px;
//     background: transparent;
    
//     @include gadgets-to(mobile) { width: 100px; }
//     @include gadgets-to(tablet) { width: 150px; }
//     @include gadgets-to(laptop) { width: 200px; }
//     @include gadgets-to(desktop) { width: 200px; }
//     @include gadgets-to(largeDesktop) { width: 200px; }
// }

// input[type="range"]::-webkit-slider-runnable-track {
//     -webkit-appearance: none;
//     height: 15px;
//     border-radius: 6px;
//     background: #ddd;
// }

// input[type="range"]::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     width: 20px;
//     height: 15px;
//     background: #fff;
//     border: 2px solid #999;
//     border-radius: 50%;
//     margin-top: -1px;
// }

// /* Custom slider styles */
// input.custom-slider[type="range"] {
//     height: 10px !important;

//     @include gadgets-to(mobile) { width: 72%; height: 10px; }
//     @include gadgets-to(tablet) { width: 72%; height: 15px; }
//     @include gadgets-to(laptop) { width: 72%; }
//     @include gadgets-to(desktop) { width: 72%; height: 15px !important; }
//     @include gadgets-to(largeDesktop) { width: 200px; }
// }

// input.custom-slider[type="range"]::-webkit-slider-runnable-track {
//     background: #ddd !important;
// }

// input.custom-slider[type="range"]::-webkit-slider-thumb {
//     width: 20px !important;
//     height: 30px !important;
//     background: #fff !important;
//     border: 2px solid #999 !important;
//     margin-top: -10px;
// }

// /* Colored track for webkit browsers */
// input.custom-slider[type="range"]::-webkit-slider-runnable-track {
//     background: linear-gradient(to right, map-get($generalStyles,transparentChangeableMenu5) 0%, map-get($generalStyles,transparentChangeableMenu5) 50%, #ddd 50%, #ddd 100%);
// }

// /* Firefox styles */
// input.custom-slider[type="range"]::-moz-range-track {
//     background: #ddd;
// }

// input.custom-slider[type="range"]::-moz-range-thumb {
//     width: 20px;
//     height: 30px;
//     background: #fff;
//     border: 2px solid #999;
//     border-radius: 50%;
// }

// /* IE and Edge styles */
// input.custom-slider[type="range"]::-ms-track {
//     background: transparent;
//     border-color: transparent;
//     color: transparent;
// }

// input.custom-slider[type="range"]::-ms-fill-lower {
//     background: map-get($generalStyles,transparentChangeableMenu5);
// }

// input.custom-slider[type="range"]::-ms-fill-upper {
//     background: #ddd;
// }

// input.custom-slider[type="range"]::-ms-thumb {
//     width: 20px;
//     height: 30px;
//     background: #fff;
//     border: 2px solid #999;
//     border-radius: 50%;
// }


/* Global styles */
input[type="range"] {
    -webkit-appearance: none;
    margin: auto;
    position: relative;
    height: 15px;
    width: 100px;
    cursor: pointer;
    border-radius: 6px;
    background: transparent;
    
    @include gadgets-to(mobile) { width: 100px; }
    @include gadgets-to(tablet) { width: 150px; }
    @include gadgets-to(laptop) { width: 200px; }
    @include gadgets-to(desktop) { width: 200px; }
    @include gadgets-to(largeDesktop) { width: 200px; }
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 15px;
    border-radius: 6px;
    background: map-get($generalStyles,transparentChangeableMenu5);
    box-shadow: inset 0 0 0 1px white;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: #fff;
    border: 2px solid #999;
    border-radius: 50%;
    margin-top: -1px;
}

/* Custom slider styles */
input.custom-slider[type="range"] {
    height: 10px !important;

    @include gadgets-to(mobile) { width: 72%; height: 10px; }
    @include gadgets-to(tablet) { width: 72%; height: 15px; }
    @include gadgets-to(laptop) { width: 72%; }
    @include gadgets-to(desktop) { width: 72%; height: 15px !important; }
    @include gadgets-to(largeDesktop) { width: 200px; }
}

// multiplying slider, safari and chrome
input.custom-slider[type="range"]::-webkit-slider-runnable-track {
    background: map-get($generalStyles,transparentChangeableMenu5) !important;
    height:12px;
    box-shadow: inset 0 0 0 1px white;

}

input.custom-slider[type="range"]::-webkit-slider-thumb {
    width: 15px !important;
    height: 15px !important;
    background: #fff !important;
    border: 1px solid #999 !important;
    border-radius: 50%;
    margin-top:-1px;
}

/* Firefox styles */
input.custom-slider[type="range"]::-moz-range-track {
    background: map-get($generalStyles,transparentChangeableMenu5);
}

input.custom-slider[type="range"]::-moz-range-thumb {
    width: 20px;
    height:20px;
    background: #fff;
    border: 2px solid #999;
   // border-radius: 50%;
}

/* IE and Edge styles */
input.custom-slider[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input.custom-slider[type="range"]::-ms-fill-lower {
    background: map-get($generalStyles,transparentChangeableMenu5);
}

input.custom-slider[type="range"]::-ms-fill-upper {
    background: map-get($generalStyles,transparentChangeableMenu5);
}

input.custom-slider[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #999;
    border-radius: 50%;
}