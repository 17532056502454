
.card {
    width: 100%;
    max-width: 32rem;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    &-header {
      padding: 1.5rem;
      border-bottom: 1px solid #e2e8f0;
    }
  
    &-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1a202c;
    }
  
    &-description {
      font-size: 0.875rem;
      color: #4a5568;
      margin-top: 0.5rem;
    }
  
    &-content {
      padding: 1.5rem;
    }
  
    &-footer {
      padding: 1.5rem;
      border-top: 1px solid #e2e8f0;
      display: flex;
      justify-content: space-between;
    }
  }
  
  .progress-bar {
    width: 100%;
    height: 0.5rem;
    background-color: #e2e8f0;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
  
    .progress {
      height: 100%;
      background-color: #4299e1;
      border-radius: 0.25rem;
      transition: width 0.3s ease;
    }
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  
    label {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      color: #4a5568;
      margin-bottom: 0.5rem;
    }
  }
  
  .image-upload {
    display: flex;
    align-items: center;
    gap: 1rem;
  
    .hidden-input {
      display: none;
    }
  
    .preview-image {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  
  .textarea {
    width: 100%;
    min-height: 8rem;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    resize: vertical;
  }
  
  .char-count {
    font-size: 0.75rem;
    color: #718096;
    text-align: right;
    margin-top: 0.25rem;
  }
  
  .input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
  }
  
  .link {
    font-size: 0.875rem;
    color: #4299e1;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
  
    &:hover {
      text-decoration: underline;
    }
  
    .icon {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.25rem;
    }
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .checkbox {
    width: 1rem;
    height: 1rem;
  }
  
  .checkbox-label {
    font-size: 0.875rem;
    color: #4a5568;
  }
  
  .btn {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &-outline {
      background-color: transparent;
      border: 1px solid #4299e1;
      color: #4299e1;
  
      &:hover {
        background-color: #ebf8ff;
      }
    }
  
    &-primary {
      background-color: #4299e1;
      color: #ffffff;
      border: none;
  
      &:hover {
        background-color: #3182ce;
      }
  
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  
    .icon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
  
  