
@import '../style/MainStyles.scss';/* styles.css */


.xitotalArea{
    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows: 3% 5% 70%;
        grid-template-areas:
            "a"
            "b"
            "c";
        width: 100%;
        height:71vh;
        object-fit: cover;
        
  

}
@include gadgets-to(tablet) {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas:
        "a"
        "b"
        "c";
    width: 100%;
    height:71vh;
    object-fit: cover;
    
}
@include gadgets-to(laptop) {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas:
        "a"
        "b"
        "c";
    width: 100%;
    height:71vh;
    object-fit: cover;
}
@include gadgets-to(desktop) {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas:
        "a"
        "b"
        "c";
    width: 100%;
    height:71vh;
    object-fit: cover;
}
@include gadgets-to(largeDesktop) {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas:
        "a"
        "b"
        "c";
    width: 100%;
    height:71vh;
    object-fit: cover;
}
}


.xitotalArea1 {
    @extend .xitotalArea;

    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, backgroundColor);
    }
}
.topArea{
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
    }

}
.xiareaA {
    @include gadgets-to(mobile) {
        grid-area:c;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        width: 98.5%;
        height: 70vh;
        margin-left: 1px;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:5vh;
    }
    @include gadgets-to(tablet) {
        grid-area:c;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        width: 80%;
        height: 70vh;
        margin-left: 1px;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:10px;
    }
@include gadgets-to(laptop) {
        grid-area:c;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        width: 65%;
        height: 70vh;
        margin-left: 1px;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:10px;
    }
@include gadgets-to(desktop) {
        grid-area:c;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        width: 60%;
        height: 70vh;
        margin-left: 1px;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:10px;
    }
@include gadgets-to(largeDesktop) {
        grid-area:c;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        width: 60%;
        height: 70vh;
        margin-left: 1px;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:10px;
    }


}

.xibackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
   
  }

  .xiheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;
        height:30px;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;
    }
@include gadgets-to(laptop) {
        grid-area: a;
        height:26px;
    }
@include gadgets-to(desktop) {
        grid-area: a;
        height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
        height:30px;
    }

  }

  .xirangeArea{
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5%;
        height:20px;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:3vh;
    }
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
  }

.xirange1{
    @include gadgets-to(mobile) {
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-top:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xirange2 {
 @extend .xirange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}

.xisearchTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1%;

}
.xisearchWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2%;
    margin-bottom:9px;

}
.xisearchHeader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    margin-left: 5px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
}

.xisearchFieldset {
    border: none;
    font-family: map-get($generalStyles, fontFamily);
}

.xisearchLegend {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color: map-get($generalStyles, colorblack);
}

.xisearchInput {
    width: 200px;
    height: 75px;
    font-size: 14px;
    font-family: map-get($generalStyles, fontFamily);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.xisearchButton {
    @include gadgets-to(mobile) {
        width: 100px;
        height: 80px;
        font-size: 18px;
        font-family: map-get($generalStyles, fontFamily);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        cursor: pointer;
    }
    width: 100px;
    height: 80px;
    font-size: 18px;
    font-family: map-get($generalStyles, fontFamily);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 3px;
    cursor: pointer;

}

.xirecepyIdeasArea {
    width:100%;
    height:25px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border: none;
   // margin-left: 3px;
}
.headerrow{
    width:90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


}
.xirecepyHeaderId{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-grow:1;
    width:10%;
    height:25px;
    font-family:map-get($generalStyles,fontFamily);  //i passe the font to here
    font-size:14px;
    color:map-get($generalStyles,colorblack);   
    border-right:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    border-radius:map-get($generalStyles,sixBorderRadius);
    text-align:center;
   // margin-bottom:1px;
   // margin-left:12px;

}
.xirecepyHeaderName{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-grow:1;
    width:calc(80% - 3px);
    height:25px;
    font-family:map-get($generalStyles,fontFamily);  //i passe the font to here
    font-size:14px;
    color:map-get($generalStyles,colorblack);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles, sixBorderRadius);
    text-align: center;
    margin-left:3px;
   // margin-bottom:1px;

}
.xirecepyDataId{
  display:flex;
  align-items: center;
    justify-content: center;
    flex-grow:1;
        width:10%;
        height:25px;
        font-family:map-get($generalStyles,fontFamily);  //i passe the font to here
        font-size:14px;
        color:map-get($generalStyles,colorblack);
        border-right:1px solid;
        border-color:map-get($generalStyles,datalistBorderColor);
        background-color:map-get($generalStyles,transparentChangeableMenu4);
        border-radius:map-get($generalStyles,sixBorderRadius);
        text-align:center;
        margin-top: 3px;

}
.xirecepyDataId1{
    @extend .xirecepyDataId;
    background-color:map-get($generalStyles,transparentChangeableMenu5);
}
.xirecepyDataName{
        display:flex;
        align-items: center;
        justify-content:flex-start;
        flex-grow:1;
        width:calc(80% - 3px);
        height:25px;
        font-family:map-get($generalStyles,fontFamily);  //i passe the font to here
        font-size:14px;
        color:map-get($generalStyles,colorblack);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        border-radius: map-get($generalStyles, sixBorderRadius);
        text-align: center;
        margin-top: 3px;
        margin-left:3px;
        padding-left:10%;

        .ellipsis-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 360px; // adjust this value based on your needs
        }

        
        @media(max-width:360px){
            min-width:1px;
            max-width:360px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            cursor:pointer;
            }

}
.xirecepyDataName1 {
@extend .xirecepyDataName;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}



.xirecepyIdeasData {
    display: flex;
    flex-direction:column;
     align-items: center;
  //  justify-content: center;
    margin-top: 1px;
    border: none;
   // margin-left: 1%;
    width:100%;
    max-height:40vh;
    overflow-x: scroll;

}
.xidatarows{
    width:90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.xidatarowstop{
 height:50vh;
 max-height:50vh;
 overflow-y:scroll;

}

.xiSpinnerContainer {
    @include gadgets-to(mobile) {
        position: absolute;
        top: 290px;
        left: 170px;
        background-color: transparent;
        transform: scale(1.5);
        z-index: 100;
    }
@include gadgets-to(tablet) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 100; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(laptop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(desktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 100; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(largeDesktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2.5);
    z-index: 100; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
}

.xiSpinner {
    @include gadgets-to(mobile) {
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(laptop) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(desktop) {
        color: map-get($generalStyles, colorblack);
    }
}

.xiconfirmationArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.xiconfirmationButton {
    width:50%;
    height: 30px;
    font-size: 14px;
    font-family: map-get($generalStyles, fontFamily);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    outline: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
  
}

.xicompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color:white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 36px;
        color: white;
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xicompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 36px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}
.xiChecfHat{
    width:20px;
    height:20px;
    margin-right:15px;
}

.xipopup-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width:85%;
    max-width:600px;
    height:35vh;
    padding: 10px;
    background:white;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index:10;
    border-radius: 10px;

  
    p {
      font-size: 14px;
      color: #333;
    }
  
    .close-btn {
      margin-top: 10px;
      padding: 5px 10px;
      background: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 3px;
    }
  
    .close-btn:hover {
      background: #0056b3;
    }
  }
  .xipopup-box-content{
    width:100%;
    height:30vh;
    border-radius:10px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:16px;
   

  }



  