
@import './style/MainStyles.scss';/* styles.css */
  
.wwtotalTop{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:90vh;

}

.wwtotalArea {
  @include gadgets-to(mobile) {
      display: grid;
      grid-template-rows:99%;
      grid-template-areas:
          "b";
      width: 100%;
      height:90vh;
      object-fit: cover;


      
  }
  @include gadgets-to(tablet) {
      // display: grid;
      // grid-template-columns:99%;
      // grid-template-areas:
      //   "b";
      //   column-gap:7px;
      // width:98%;
      // height:90vh;
      // object-fit: cover;
      display: grid;
      grid-template-columns:20% 80%;
      grid-template-rows: auto auto;
      grid-template-areas:
        "a b"
        "a b";

        column-gap:10px;
      width:99%;
      height:80vh;
      object-fit: cover;

  }
  @include gadgets-to(laptop) {
      // display: grid;
      // grid-template-columns:99%;
      // grid-template-areas:
      //   "b";
      //   column-gap:7px;
      // width:98%;
      // height:90vh;
      // object-fit: cover;
      display: grid;
      grid-template-columns:20% 78%;
      grid-template-rows: auto auto;
      grid-template-areas:
        "a b"
        "a b";

        column-gap:7px;
      width:98%;
      height: 100%;
      object-fit: cover;

  }
  @include gadgets-to(desktop) {
      // display: grid;
      // grid-template-columns:99%;
      // grid-template-areas:
      //   "b";
      //   column-gap:7px;
      // width:98%;
      // height:90vh;
      // object-fit: cover;
      display: grid;
      grid-template-columns:20% 80%;
      grid-template-rows: auto auto;
      grid-template-areas:
        "a b"
        "a b";

        column-gap:7px;
      width:99%;
      height: 100%;
      object-fit: cover;

  }
  @include gadgets-to(largeDesktop) {
      display: grid;
      grid-template-columns:99%;
      grid-template-areas:
        "b";
        column-gap:7px;
      width:98%;
      height:90vh;
      object-fit: cover;

  }
}


// 

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    object-fit: cover;
    z-index: -1;
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
  
  }

.wwbackgroundwrapper {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:90vh;
  background-color: map-get($generalStyles,transparentChangeable);
  border-radius: map-get($generalStyles,sixBorderRadius);
  margin-top:2vh;
  @include gadgets-to(tablet) {
    height:90vh;
    width: 100%;
    margin-top:unset;
  }

}

.wwrangeArea {
  @include gadgets-to(mobile) {
      grid-area: b;
      display:flex;
      flex-direction: row;
      justify-content:center;
      align-items:flex-end;
      height:3vh;
  }
  @include gadgets-to(tablet) {
      grid-area: a;
      display:flex;
      flex-direction: row;
      justify-content:flex-end;
      height:10vh;
      width:100%;


       @media(min-width:1081px) and (max-width:1170px)
       {
          height:5vh;
       }
       // as ipad
      //  @media(min-width:1171px) and (max-width:1195px)
      //  {
       
      //     height:auto;
      //  }

       @media(min-width:1196px) and (max-width:1400px)
       {
          height:5vh;
       }


}
@include gadgets-to(laptop) {
      grid-area: b;
      display:flex;
      flex-direction: row;
      justify-content:flex-end;
   //   margin-top:3%;
  height:4vh;
  width:100%;
  }
@include gadgets-to(desktop) {
      grid-area: b;
      display:flex;
      flex-direction: row;
      justify-content:flex-end;
     // padding-top:5vh;
   //   margin-top:3%;
  height:5vh;
  width:100%;
  }
@include gadgets-to(largeDesktop) {
      grid-area: b;
      display:flex;
      flex-direction: row;
      justify-content:flex-end;
   height:5vh;
   width:100%;
}
}
.wwrange1 {
  @include gadgets-to(mobile) {
      margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
  }
  @include gadgets-to(tablet) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      margin-right:2%;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(desktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(largeDesktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
}

.wwkrange2 {
  @extend .wwrange1;
  @include gadgets-to(mobile) {
      accent-color: rgb(251, 239, 132);
      width:125px;

  }
  @include gadgets-to(tablet) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(desktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(largeDesktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
}
.wwleftAreaWrapper{
  grid-area: a;
  display:flex;
  flex-direction:column;
  //justify-content: center;
  align-items: center;
  width:80%;
  height:75vh;
  margin-left:1vw;
 // border:1px solid blue;
  margin-top:2vh;
  @include gadgets-to(tablet) {
    margin-top:unset;
    height:71vh;
  }
}
.wwrightAreaWrapper{
  grid-area: b;
  display:flex;
  flex-direction:column;
  //justify-content: center;
  align-items: center;
  width:95%;
  height:75vh;
  // border:2px solid red;
  margin-top:2vh;

  @include gadgets-to(tablet) {
    margin-top:unset;
    height:70vh;
  }
}

.wwbuttonArea{
  display:flex;
  flex-direction:column;
  //justify-content: center;
  align-items: center;
  //border:2px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 6px;
  width:100%;
  height:15vh;
  //margin-top:3vh;
  padding-top:2vh;
  box-shadow: 4px 4px 10px 5px rgba(0,0,0,0.5);
  background-color: white;
  
}
.wwbutton{
  grid-area:a;
  width:90%;
  height:40px;
  background-color:map-get($generalStyles,transparentChangeableMenu5);
  color:white;
  font-size: 12px;
  font-weight:200;
  font-family: var(--font-family);
  border-radius: 6px;
  cursor:pointer;
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  margin-top:5px;
  // hover
  transition: 0.4s;
  &:hover{
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    border:2px solid map-get($generalStyles,transparentChangeableMenu5);
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
  }

}

.wwsearchSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 58%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.wwsearchSpinner {
  color: map-get($generalStyles, colorblack);
}

.wwlivexvspinner-text{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 58%;
  transform: translate(-50%, -50%);
  text-align: center;
  width:20%;
  height:5vh;
  font-size: 16px;
  font-family: var(--font-family);
  background-color:white;
  border-radius:12px;
  border:3px solid map-get($generalStyles,colorGold);
  padding:3px;
  font-size:12px;
  font-family: #{map-get($generalStyles, fontFamilyLobster)};
   box-shadow:12px 12px 8px rgba(0, 0, 0, 0.5);
   z-index:2006;
@include gadgets-to(mobile) {
  width: 80%;
  height: 4vh;
  font-size: 12px;

}
@include gadgets-to(tablet) {
  width: 40%;
  height: 6vh;
  font-size: 14px;
  }
}

.wwLiveloading-dots {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 3px;
  width: 40%;

  span {
    width: 16px;  // Increase width and height for star shape
    height: 16px;
    margin: 0 4px;
    background-color: #f8b400;  // Yellow color for the stars
    display: inline-block;
    animation: loading2 1s infinite ease-in-out;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);  // Star shape using clip-path
    transform: rotate(90deg);  // Rotate stars to appear upright

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}
@keyframes loading2 {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  
}


 .areaBLogo {
      grid-area:b;
    font-size: 140px;
    font-family:var(--font-family);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:80%;
    // coilor almost ransparent
    color:rgba(255, 255, 255, 0.8);
    //color:white;
    @include gadgets-to(mobile) {
      font-size:70px;
      width:98%;
      }
      @include gadgets-to(tablet) {
        font-size: 140px;
        width:90%;
        }
        @include gadgets-to(laptop) {
          font-size: 140px;
          width:90%;
          }
          @include gadgets-to(desktop) {
            font-size: 140px;
            }
            @include gadgets-to(largeDesktop) {
              font-size: 140px;
              }
    }


.areaBTop{
  grid-area:b;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:87vh;
 // border:2px solid green;
  margin-top:2vh;
  background-color: map-get($generalStyles,transparentChangeable);
  border-radius: map-get($generalStyles,sixBorderRadius);
}
  

.wwLogoWrapper{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height:10vh;
  background-color: map-get($generalStyles,transparentChangeable);
  border-radius: map-get($generalStyles,sixBorderRadius);
  margin-top:2vh;
  font-family:map-get($generalStyles,fontFamilyLobster);
}

.wwServicesAreaTopWrapper{
  display:flex;
  flex-direction:column;
  //justify-content: center;
  align-items: center;
  width:100%;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  height:50vh;
  border-radius:12px;
  background-color: white;
  box-shadow: 12px 12px 10px 5px rgba(0,0,0,0.5);
}
.wwServicesAreaHeader{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items: center;
  width:95%;
  height:3vh;
  color:black;
  font-family:var(--font-family);
  font-size:24px;
  font-weight:200;
  padding:2vh;
  padding-left:3vw;

}
.wwServiceHeadertext{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  font-size: 16px;
  font-weight:100;
  font-family: var(--font-family);
  color:black;
  margin-bottom:1vh;
  @include gadgets-to(mobile){
    font-size: 12px;
    margin-top:5px;
  }
  @include gadgets-to(tablet){
    font-size: 16px;
    margin-top:5px;
  }
  @include gadgets-to(laptop){
    font-size: 16px;
    margin-top:5px;
  }
  @include gadgets-to(desktop){
    font-size: 16px;
    margin-top:5px;
  }
  @include gadgets-to(largeDesktop){
    font-size: 16px;
    margin-top:5px;
  }

}

.wwServicesAreaWrapper{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:90%;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  height:auto;
  box-shadow: 12px 12px 10px 5px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: white;
}
.wwServicesRow{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:100%;
  height:4vh;
  color:black;
  font-family:var(--font-family);
  font-size:24px;
  font-weight:200;
  z-index: 99;
  @include gadgets-to(mobile){
    width:95%;
    height:6vh;
  }
  @include gadgets-to(tablet){
    width:95%;
    height:6vh;
  }
  @include gadgets-to(laptop){
    width:95%;
    height:6vh;
  }
  @include gadgets-to(desktop){
    width:95%;
    height:6vh;
  }
  @include gadgets-to(largeDesktop){
    width:95%;
    height:6vh;
  }

}
.wwServicesRow1{
  @extend .wwServicesRow;
  background-color:lightgrey;
  border-radius: 10px;
  color:red;
}
.wwServicesInvoice{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:flex-start;
  width:100%;
  color:black;
  width:15%;
  font-size: 16px;
  font-weight:200;
  font-family: var(--font-family);
  padding-left:1vw;
    // no text wrap
    white-space: nowrap;
    @include gadgets-to(mobile){
      font-size: 12px;
    }

}
.wwServicesService{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  color:black;
  width:15%;
  font-size: 16px;
  font-weight:200;
  font-family: var(--font-family);


}
.wwServicesDueDate{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:10%;
  font-size: 16px;
  font-weight:200;
  font-family: var(--font-family);

}
.wwServicesAmount{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:20%;
  font-size: 16px;
  font-weight:200;
  font-family: var(--font-family);
    // no text wrap
    white-space: nowrap;
  @include gadgets-to(mobile){
    font-size: 12px;
  }

}
.wwServicesBenaemn{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  width:30%;
  font-size: 16px;
  font-weight:200;
  font-family: var(--font-family);
  padding-left:2vw;

}
.wwServicesCloseIcon{
font-size: 25px;
color:black;
cursor:pointer;
}
.wwServicesButtonWrapper{
width:20%;
display:flex;
flex-direction:row;
justify-content:flex-end;
padding-right:1vw;
}
.wwServicesButton{
width:8vw;
height:30px;
background-color:map-get($generalStyles,pinkRed);
color:white;
font-size: 12px;
font-weight:200;
font-family: var(--font-family);
border-radius: 6px;
cursor:pointer;
display: flex;
flex-direction:row;
justify-content:center;
align-items: center;
// hover
transition: 0.4s;
&:hover{
  background-color:map-get($generalStyles,transparentChangeableMenu5);
  border:2px solid map-get($generalStyles,transparentChangeableMenu5);
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
}
@include gadgets-to(mobile) {
  width:15vw;

}
}
.wwExtraInfo{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  color:red;
  font-family:var(--font-family);
  font-size:12px;
  font-weight:200;
  padding:2vh;
  @include gadgets-to(tablet){
    font-size: 16px;
  }
  @include gadgets-to(laptop){
    font-size: 16px;
  }
  @include gadgets-to(desktop){
    font-size: 16px;
  }
  @include gadgets-to(largeDesktop){
    font-size: 16px;
  }

}