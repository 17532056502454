
.agreement-container {
    max-width: 900px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: Arial, sans-serif;
  
    h1, h2 {
      color: #333;
      margin-bottom: 0.5rem;
    }
  
    h1 {
      font-size: 1.8rem;
      text-align: center;
    }
  
    h2 {
      font-size: 1.4rem;
      margin-top: 1rem;
      border-bottom: 1px solid #ddd;
    }
  
    p, li {
      line-height: 1.6;
      color: #555;
    }
  
    ul {
      list-style-type: disc;
      margin: 1rem 0;
      padding-left: 1.5rem;
    }
  
    hr {
      border: none;
      border-top: 1px solid #ccc;
      margin: 1rem 0;
    }
  
    .agreement-footer {
      margin-top: 1.5rem;
      text-align: center;
      font-size: 0.9rem;
      color: #777;
    }
  }
  