:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xqtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredTotalArea, .xqingredTotalArea1 {
    display: grid;
    grid-template-rows: 7% 4% 78% 18%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredTotalArea, .xqingredTotalArea1 {
    display: grid;
    grid-template-columns: 30% 45% 20%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 85%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredTotalArea, .xqingredTotalArea1 {
    display: grid;
    grid-template-columns: 30% 45% 20%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 85%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredTotalArea, .xqingredTotalArea1 {
    display: grid;
    grid-template-columns: 30% 45% 20%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 85%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredTotalArea, .xqingredTotalArea1 {
    display: grid;
    grid-template-columns: 30% 45% 20%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 85%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xqingredTotalArea1 {
  background-color: initial;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredTotalAreaA, .xqingredTotalAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 63vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredTotalAreaA, .xqingredTotalAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xqingredTotalAreaA, .xqingredTotalAreaB {
    height: 74vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredTotalAreaA, .xqingredTotalAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredTotalAreaA, .xqingredTotalAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredTotalAreaA, .xqingredTotalAreaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredTotalAreaB {
    grid-area: d;
    height: 10vh;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 360px) and (max-width: 370px) {
  .xqingredTotalAreaB {
    margin-top: calc(15px - var(--margin-def));
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xqingredTotalAreaB {
    margin-top: 10px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 392px) {
  .xqingredTotalAreaB {
    margin-top: calc(15px - var(--margin-def));
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 415px) and (min-height: 700px) and (max-height: 750px) {
  .xqingredTotalAreaB {
    margin-top: calc(15px - var(--margin-def2));
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 415px) and (min-height: 751px) {
  .xqingredTotalAreaB {
    margin-top: calc(15px - var(--margin-def2));
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredTotalAreaB {
    grid-area: d;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xqingredTotalAreaB {
    height: 74vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredTotalAreaB {
    grid-area: d;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredTotalAreaB {
    grid-area: d;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredTotalAreaB {
    grid-area: d;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xqheaderArea {
    height: 45px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 40px;
  }
}
@media only screen and (min-width:2303px) {
  .xqheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 7vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xqrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xqrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xqrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqrange1, .xqrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqrange1, .xqrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqrange1, .xqrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqrange1, .xqrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xqrange1, .xqrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xqrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xqcompName, .xqcompNameJAP {
  font-family: "Lobster";
  font-Size: 26px;
  color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqcompName, .xqcompNameJAP {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqcompName, .xqcompNameJAP {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqcompName, .xqcompNameJAP {
    margin-top: 1vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xqcompName, .xqcompNameJAP {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: white;
  }
}

.xqcompName1, .xqcompNameJAP1 {
  grid-area: a;
  font-family: "Lobster";
  font-Size: 26px;
  color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqcompName1, .xqcompNameJAP1 {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqcompName1, .xqcompNameJAP1 {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqcompName1, .xqcompNameJAP1 {
    margin-top: 1vh;
    font-family: "Lobster";
    font-Size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xqcompName1, .xqcompNameJAP1 {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqcompNameJAP {
    font-family: var(--font-family);
    font-size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqcompNameJAP {
    font-family: var(--font-family);
    font-size: 35px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqcompNameJAP {
    font-family: var(--font-family);
    font-size: 35px;
    color: white;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqcompNameJAP {
    font-family: var(--font-family);
    font-size: 40px;
    color: white;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xqcompNameJAP {
    font-family: var(--font-family);
    font-size: 50px;
    font-weight: 600;
    color: white;
    margin-top: 2vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqcompNameJAP1 {
    font-family: var(--font-family);
    font-size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqcompNameJAP1 {
    font-family: var(--font-family);
    font-size: 35px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqcompNameJAP1 {
    font-family: var(--font-family);
    font-size: 35px;
    color: var(--rgbaVal);
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqcompNameJAP1 {
    font-family: var(--font-family);
    font-size: 40px;
    color: var(--rgbaVal);
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xqcompNameJAP1 {
    font-family: var(--font-family);
    font-size: 50px;
    color: var(--rgbaVal);
    margin-top: 2vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredTop {
    margin-top: 5px;
    width: 100%;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredTop {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredTop {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredTop {
    margin-top: 5px;
    width: 100%;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredTop {
    margin-top: 5px;
    width: 100%;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.xqingredHeader {
  display: flex;
  flex-direction: row;
  width: 90%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredId, .xqingredSumma, .xqingredVikt, .xqingredBenaemn {
    width: 15%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    color: black;
    margin-left: 3px;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredId, .xqingredSumma, .xqingredVikt, .xqingredBenaemn {
    width: 15%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    color: black;
    margin-left: 3px;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredId, .xqingredSumma, .xqingredVikt, .xqingredBenaemn {
    width: 15%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    color: black;
    margin-left: 3px;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredId, .xqingredSumma, .xqingredVikt, .xqingredBenaemn {
    width: 15%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    color: black;
    margin-left: 3px;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredId, .xqingredSumma, .xqingredVikt, .xqingredBenaemn {
    width: 15%;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    color: black;
    margin-left: 3px;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 24px;
  }
}

.xqingredBenaemn {
  width: calc(55% - 2px);
  margin-left: 2px;
}

.xqingredVikt {
  width: calc(15% - 2px);
  margin-left: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredSumma {
    width: calc(15% - 2px);
    margin-left: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredSumma {
    margin-right: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredSumma {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredSumma {
    margin-right: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredSumma {
    margin-right: 8px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqdata {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    margin-bottom: 3px;
    height: 56vh;
    max-height: 56vh;
    overflow-y: scroll;
  }
  .xqdata::-webkit-scrollbar {
    width: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqdata {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    margin-left: 3px;
    margin-bottom: 3px;
    height: auto;
    max-height: 72vh;
    overflow-y: scroll;
  }
  .xqdata::-webkit-scrollbar {
    width: 4px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) and (min-width: 768px) {
  .xqdata {
    height: 68vh;
    max-height: 68vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xqdata {
    height: 68vh;
    max-height: 68vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xqdata {
    height: 64vh;
    max-height: 64vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqdata {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-bottom: 3px;
    height: auto;
    max-height: 72vh;
    overflow-y: scroll;
  }
  .xqdata::-webkit-scrollbar {
    width: 10px;
  }
  .xqdata::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xqdata::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqdata {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-bottom: 3px;
    height: auto;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .xqdata::-webkit-scrollbar {
    width: 10px;
  }
  .xqdata::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xqdata::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xqdata {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    margin-bottom: 3px;
    height: auto;
    max: height 70vh;
    overflow-y: scroll;
  }
  .xqdata::-webkit-scrollbar {
    width: 10px;
  }
  .xqdata::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xqdata::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
    cursor: pointer;
  }
}

.xqdatarows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqtotproduktionid, .xqtotsum, .xqtotsum1, .xqtotvikt, .xqtotvikt1, .xqtotbenaemn, .xqtotbenaemn1, .xqtotproduktionid1 {
    width: 15%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    color: black;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqtotproduktionid, .xqtotsum, .xqtotsum1, .xqtotvikt, .xqtotvikt1, .xqtotbenaemn, .xqtotbenaemn1, .xqtotproduktionid1 {
    width: 15%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    color: black;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqtotproduktionid, .xqtotsum, .xqtotsum1, .xqtotvikt, .xqtotvikt1, .xqtotbenaemn, .xqtotbenaemn1, .xqtotproduktionid1 {
    width: 15%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    color: black;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 16px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqtotproduktionid, .xqtotsum, .xqtotsum1, .xqtotvikt, .xqtotvikt1, .xqtotbenaemn, .xqtotbenaemn1, .xqtotproduktionid1 {
    width: 15%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    color: black;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 20px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width:2303px) {
  .xqtotproduktionid, .xqtotsum, .xqtotsum1, .xqtotvikt, .xqtotvikt1, .xqtotbenaemn, .xqtotbenaemn1, .xqtotproduktionid1 {
    width: 15%;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    color: black;
    margin-bottom: 3px;
    font-family: var(--font-family);
    font-size: 24px;
    background-color: var(--rgbaVal2);
  }
}

.xqtotproduktionid1 {
  background-color: var(--rgbaVal);
}

.xqtotbenaemn, .xqtotbenaemn1 {
  width: calc(55% - 2px);
  background-color: var(--rgbaVal2);
  margin-left: 2px;
}

.xqtotbenaemn1 {
  background-color: var(--rgbaVal);
}

.xqtotvikt, .xqtotvikt1 {
  width: calc(15% - 2px);
  background-color: var(--rgbaVal2);
  margin-left: 2px;
}

.xqtotvikt1 {
  background-color: var(--rgbaVal);
}

.xqtotsum, .xqtotsum1 {
  width: calc(15% - 2px);
  background-color: var(--rgbaVal2);
  margin-left: 2px;
}

.xqtotsum1 {
  background-color: var(--rgbaVal);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqheadertext {
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqheadertext {
    margin-top: 1vh;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 3.5vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqheadertext {
    margin-top: 1vh;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 3.5vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqheadertext {
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 3.5vw;
    margin-top: 1vh;
  }
}
@media only screen and (min-width:2303px) {
  .xqheadertext {
    font-family: var(--font-family);
    font-size: 24px;
    margin-left: 3.5vw;
    margin-top: 1vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredTotalSummary {
    margin-top: 2px;
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredTotalSummary {
    margin-top: 2px;
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xqingredTotalSummary {
    font-size: 14px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredTotalSummary {
    margin-top: 2px;
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 20px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredTotalSummary {
    margin-top: 2px;
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 24px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredTotalSummary {
    margin-top: 2px;
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 28px;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xqingredTotalSummaryBottomAntal {
    margin-top: 2px;
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xqingredTotalSummaryBottomAntal {
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xqingredTotalSummaryBottomAntal {
    font-size: 14px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xqingredTotalSummaryBottomAntal {
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 20px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xqingredTotalSummaryBottomAntal {
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 24px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xqingredTotalSummaryBottomAntal {
    margin-left: 15px;
    font-family: var(--font-family);
    font-size: 28px;
    margin-top: 2vh;
  }
}

.xqingredTotalSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xqbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}/*# sourceMappingURL=IngredTotNew1.css.map */