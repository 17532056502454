@import '../style/MainStyles.scss';/* styles.css */

.avtotWrapper{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     height:80vh;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     height:70vh;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     height:70vh;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     height:70vh;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     height:70vh;
}
}
.avtotalArea {
@include gadgets-to(mobile) {
    display: grid;
   // grid-template-rows: 3% 5% 26% 17% 30%;
    grid-template-rows:auto auto;

    grid-template-areas:
        "a"
        "b"
 ;
    width: 100%;
    height:auto;
    object-fit: cover;



}


@include gadgets-to(tablet) {
//     display: grid;
//   //  grid-template-columns:30% 47% 22%;
//     grid-template-rows: auto auto;
//     grid-template-areas:
//       "a a a"
//       "b b b";
//       column-gap:7px;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
display: grid;
// grid-template-rows: 3% 5% 26% 17% 30%;
 grid-template-rows:auto auto;

 grid-template-areas:
     "a"
     "b"
;
 width: 100%;
 height:auto;
 object-fit: cover;


}
@include gadgets-to(laptop) {
    display: grid;
    // grid-template-rows: 3% 5% 26% 17% 30%;
     grid-template-rows:auto auto;
    
     grid-template-areas:
         "a"
         "b"
    ;
     width: 100%;
     height:auto;
     object-fit: cover;

}
@include gadgets-to(desktop) {
    display: grid;
    // grid-template-rows: 3% 5% 26% 17% 30%;
     grid-template-rows:auto auto;
    
     grid-template-areas:
         "a"
         "b"
    ;
     width: 100%;
     height:auto;
     object-fit: cover;



}
@include gadgets-to(largeDesktop) {
    display: grid;
    // grid-template-rows: 3% 5% 26% 17% 30%;
     grid-template-rows:auto auto;
    
     grid-template-areas:
         "a"
         "b"
    ;
     width: 100%;
     height:auto;
     object-fit: cover;

}
}

.avlogoutTop{
grid-area:a;
font-size:16px;
font-family:var(--font-family);
@include gadgets-to(tablet) {
    font-size:16px;
    margin-top:2vh;

}
@include gadgets-to(laptop) {
    font-size:18px;

}
@include gadgets-to(desktop) {
    font-size:16px;

}
@include gadgets-to(largeDesktop) {
    font-size:24px;

}
}
.avlogoutLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.avlogoutImage{

    grid-area:b;
    margin-top:10px;
    margin-bottom:10px;
    cursor:pointer;
    width:70%;
    height:auto;
    border-radius:50%;
    border:3px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
@include gadgets-to(tablet) {
    width:25%;
    height:auto;

}
@include gadgets-to(laptop) {
    width:20%;
    height:auto;

}
@include gadgets-to(desktop) {
    width:20%;
    height:auto;

}
@include gadgets-to(largeDesktop) {
    width:20%;
    height:auto;

}
}

.avareaA{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:2vh;

}
.avareaB{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.avareaC{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:1vh;
}
.avlogoutBottom{
  font-size: 16px;
  font-family:var(--font-family);
  text-decoration: underline;
  @include gadgets-to(tablet) {
    font-size:16px;
    margin-top:2vh;

}
@include gadgets-to(laptop) {
    font-size:18px;

}
@include gadgets-to(desktop) {
    font-size:16px;

}
@include gadgets-to(largeDesktop) {
    font-size:16px;

}
}
.avloginButton{
    height:30px;
    width:180px;
    border:none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles,pinkRed);
   // margin-left:5px;
    padding:6px;
    padding-top:5px;
    padding-bottom:5px;
    color:white;
    cursor:pointer;
}