:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xvtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvtotalArea, .xvtotalArea1 {
    display: grid;
    grid-template-rows: 6% 4% 73% 18%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvtotalArea, .xvtotalArea1 {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a" "b b" "c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvtotalArea, .xvtotalArea1 {
    display: grid;
    grid-template-columns: 30% 45% 20%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvtotalArea, .xvtotalArea1 {
    display: grid;
    grid-template-columns: 30% 45% 20%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xvtotalArea, .xvtotalArea1 {
    display: grid;
    grid-template-columns: 30% 45% 20%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c c d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 85%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xvtotalArea1 {
  background-color: white;
}

.xvareaA {
  grid-area: c;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  width: 99.4%;
  height: 63vh;
  background-color: var(--val);
  margin-top: 5px;
  border-radius: 6px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvareaA {
    height: 80vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xvareaA {
    height: 77vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1270px) and (max-height: 632px) {
  .xvareaA {
    height: 72vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvareaA {
    height: 80vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvareaA {
    height: 80vh;
  }
}
@media only screen and (min-width:2303px) {
  .xvareaA {
    height: 80vh;
  }
}

.xvareaB {
  grid-area: d;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  width: 100%;
  height: 15vh;
  background-color: var(--val);
  margin-top: 5px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 415px) {
  .xvareaB {
    margin-top: 18px;
    height: 12vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-height: 732px) {
  .xvareaB {
    margin-top: 10px;
    height: 14vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvareaB {
    height: 80vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xvareaB {
    height: 77vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1270px) and (max-height: 632px) {
  .xvareaB {
    height: 72vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvareaB {
    height: 80vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvareaB {
    height: 80vh;
  }
}
@media only screen and (min-width:2303px) {
  .xvareaB {
    height: 80vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xvheaderArea {
    height: 45px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 40px;
  }
}
@media only screen and (min-width:2303px) {
  .xvheaderArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99%;
    margin-top: 2px;
    height: 7vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xvcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xvcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xvcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xvcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xvrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xvrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xvrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvrange1, .xvrange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvrange1, .xvrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvrange1, .xvrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvrange1, .xvrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xvrange1, .xvrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xvrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xvselectArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
@media only screen and (min-width:2303px) {
  .xvselectArea {
    height: 5vh;
  }
}

.xvselectfieldset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
  font-family: var(--font-family);
  font-size: 12px;
  border: none;
  height: 40px;
}

.xvselectlegend {
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
  margin-top: 5px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvselectlegend {
    font-size: 14px;
    margin-left: 7%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1025px) and (max-width: 1120px) {
  .xvselectlegend {
    font-size: 14px;
    margin-left: 9%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1190px) and (max-width: 1198px) {
  .xvselectlegend {
    font-size: 14px;
    margin-left: 12%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1199px) {
  .xvselectlegend {
    font-size: 14px;
    margin-left: 18%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (orientation: portrait) {
  .xvselectlegend {
    font-size: 14px;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvselectlegend {
    font-size: 14px;
    margin-left: 6%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvselectlegend {
    font-size: 14px;
    margin-left: 6%;
  }
}
@media only screen and (min-width:2303px) {
  .xvselectlegend {
    font-size: 18px;
    margin-left: 1%;
  }
}

.xvselect {
  width: 250px;
  height: 30px;
  color: black;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  background-color: white;
  font-family: var(--font-family);
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvselect {
    width: 25vw;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvselect {
    width: 25vw;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xvselect {
    width: 25vw;
    height: 35px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvheaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvheaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvheaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xvheaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.xvheader {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
  height: 25px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvheader {
    width: 95%;
    height: 25px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvheader {
    width: 95%;
    height: 25px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvheader {
    width: 80%;
    height: 25px;
  }
}
@media only screen and (min-width:2303px) {
  .xvheader {
    width: 80%;
    height: 35px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvproduktid1, .xvspara1, .xvinkmoms1, .xvmargin1, .xvpris1, .xvbenaemn1 {
    width: calc(12% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-bottom: 2px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvproduktid1, .xvspara1, .xvinkmoms1, .xvmargin1, .xvpris1, .xvbenaemn1 {
    width: calc(12% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-bottom: 2px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvproduktid1, .xvspara1, .xvinkmoms1, .xvmargin1, .xvpris1, .xvbenaemn1 {
    width: calc(12% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-bottom: 2px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvproduktid1, .xvspara1, .xvinkmoms1, .xvmargin1, .xvpris1, .xvbenaemn1 {
    width: calc(12% - 2px);
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-bottom: 2px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xvproduktid1, .xvspara1, .xvinkmoms1, .xvmargin1, .xvpris1, .xvbenaemn1 {
    width: calc(12% - 2px);
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    margin-bottom: 2px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 16px;
  }
}

.xvbenaemn1 {
  width: calc(37% - 2px);
  margin-left: 2px;
}

.xvpris1 {
  width: calc(12% - 2px);
  margin-left: 2px;
}

.xvmargin1 {
  width: calc(12% - 2px);
  margin-left: 2px;
}

.xvinkmoms1 {
  width: calc(12% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xvspara1 {
  width: calc(15% - 2px);
  margin-left: 2px;
  padding: 1px;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvspara1 {
    width: calc(15% - 2px);
    margin-left: 2px;
    padding: 1px;
    margin-right: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvspara1 {
    width: calc(15% - 2px);
    margin-left: 2px;
    padding: 1px;
    margin-right: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .xvspara1 {
    width: calc(15% - 2px);
    margin-left: 2px;
    padding: 1px;
    margin-right: 21px;
  }
}

.xvpricelistArea {
  width: 100%;
  padding: 0;
  margin: 0;
}
.xvpricelistArea ::-webkit-scrollbar {
  width: 1px;
}
.xvpricelistArea ::-webkit-scrollbar-track {
  background: var(--rgbaVal);
}
.xvpricelistArea ::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvpricelistArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvpricelistArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .xvpricelistArea ::-webkit-scrollbar {
    width: 10px;
  }
  .xvpricelistArea ::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xvpricelistArea ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvpricelistArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .xvpricelistArea ::-webkit-scrollbar {
    width: 10px;
  }
  .xvpricelistArea ::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xvpricelistArea ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xvpricelistArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .xvpricelistArea ::-webkit-scrollbar {
    width: 10px;
  }
  .xvpricelistArea ::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xvpricelistArea ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

.xvpricelistData {
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  max-height: 47vh;
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvpricelistData {
    width: 95%;
    max-height: 65vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1270px) and (max-height: 632px) {
  .xvpricelistData {
    max-height: 56vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvpricelistData {
    width: 95%;
    max-height: 70vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvpricelistData {
    width: 80%;
    max-height: 65vh;
  }
}
@media only screen and (min-width:2303px) {
  .xvpricelistData {
    width: 80%;
    max-height: 65vh;
  }
}

.xvoptionstyle {
  font-family: var(--font-family);
  font-size: 14px;
}

.xvAiOutlinePlusCircle,
.xvAiOutlineMinusCircle {
  color: black;
  font-size: 26px;
  cursor: pointer;
  opacity: 0.5;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvAiOutlinePlusCircle,
  .xvAiOutlineMinusCircle {
    font-size: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xvAiOutlinePlusCircle,
  .xvAiOutlineMinusCircle {
    font-size: 40px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvAiOutlinePlusCircle,
  .xvAiOutlineMinusCircle {
    font-size: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvAiOutlinePlusCircle,
  .xvAiOutlineMinusCircle {
    font-size: 40px;
  }
}
@media only screen and (min-width:2303px) {
  .xvAiOutlinePlusCircle,
  .xvAiOutlineMinusCircle {
    font-size: 50px;
  }
}

.xvplussign,
.xvminussign {
  font-family: var(--font-family);
  font-size: 30px;
  margin-top: 8px;
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) {
  .xvplussign,
  .xvminussign {
    padding-left: 1px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 361px) and (max-width: 430px) {
  .xvplussign,
  .xvminussign {
    padding-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 768px) and (max-width: 1024px) {
  .xvplussign,
  .xvminussign {
    padding-left: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1025px) and (max-width: 1120px) {
  .xvplussign,
  .xvminussign {
    padding-left: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1121px) and (max-width: 1190px) {
  .xvplussign,
  .xvminussign {
    padding-left: 4px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1191px) and (max-width: 1198px) {
  .xvplussign,
  .xvminussign {
    padding-left: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1199px) {
  .xvplussign,
  .xvminussign {
    padding-left: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvplussign,
  .xvminussign {
    padding-left: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvplussign,
  .xvminussign {
    padding-left: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .xvplussign,
  .xvminussign {
    padding-left: 12px;
  }
}

.xvplustext {
  font-family: var(--font-family);
  font-size: 20px;
  margin-top: 6px;
  margin-left: 5px;
  color: black;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvplustext {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvplustext {
    font-size: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xvplustext {
    font-size: 40px;
  }
}

.xveditproductTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.xveditproductArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 95%;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  height: 7vh;
  margin-top: 2px;
  background-color: var(--rgbaVal);
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px white;
}

.xvaverageArea {
  width: 100%;
}

.xvaverageText {
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--rgbaVal);
  margin-top: 10px;
  text-align: center;
}
@media only screen and (min-width:2303px) {
  .xvaverageText {
    margin-top: 2vh;
    font-size: 16px;
  }
}

.xvaveragePercentage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 42px;
  color: var(--rgbaVal);
  margin-left: 1px;
  margin-top: 8px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xvaveragePercentage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 40px;
    color: var(--rgbaVal);
    margin-left: 1px;
    margin-top: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xvaveragePercentage {
    font-size: 46px;
  }
}

.xvpricelistSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(1.5);
  z-index: 10;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvpricelistSpinnerContainer {
    left: 45%;
  }
}
@media only screen and (min-width:2303px) {
  .xvpricelistSpinnerContainer {
    left: 45%;
  }
}

.xvspinner-text {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  font-size: 8px;
  font-family: var(--font-family);
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--rgbaVal);
  padding: 3px;
}

.xvpidbutton, .xvsparabutton, .xvsparabutton2, .xvsparabutton1, .xvinkmomsbutton, .xvinkmomsbutton1, .xvmarginbutton, .xvmarginbutton1, .xvprisbutton, .xvprisbutton1, .xvbenaemnbutton, .xvbenaemnbutton1, .xvpidbutton1 {
  width: calc(12% - 2px);
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  margin-bottom: 2px;
  background-color: var(--rgbaVal2);
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvpidbutton, .xvsparabutton, .xvsparabutton2, .xvsparabutton1, .xvinkmomsbutton, .xvinkmomsbutton1, .xvmarginbutton, .xvmarginbutton1, .xvprisbutton, .xvprisbutton1, .xvbenaemnbutton, .xvbenaemnbutton1, .xvpidbutton1 {
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvpidbutton, .xvsparabutton, .xvsparabutton2, .xvsparabutton1, .xvinkmomsbutton, .xvinkmomsbutton1, .xvmarginbutton, .xvmarginbutton1, .xvprisbutton, .xvprisbutton1, .xvbenaemnbutton, .xvbenaemnbutton1, .xvpidbutton1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xvpidbutton, .xvsparabutton, .xvsparabutton2, .xvsparabutton1, .xvinkmomsbutton, .xvinkmomsbutton1, .xvmarginbutton, .xvmarginbutton1, .xvprisbutton, .xvprisbutton1, .xvbenaemnbutton, .xvbenaemnbutton1, .xvpidbutton1 {
    height: 40px;
    font-size: 20px;
  }
}

.xvpidbutton1 {
  border-radius: 3px;
  background-color: var(--rgbaVal);
}

.xvbenaemnbutton, .xvbenaemnbutton1 {
  width: calc(37% - 2px);
  color: black;
  cursor: pointer;
  text-align: left;
  margin-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xvbenaemnbutton1 {
  background-color: var(--rgbaVal);
}

.xvprisbutton, .xvprisbutton1 {
  width: calc(12% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xvprisbutton1 {
  background-color: var(--rgbaVal);
}

.xvmarginbutton, .xvmarginbutton1 {
  width: calc(12% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xvmarginbutton1 {
  background-color: var(--rgbaVal);
}

.xvinkmomsbutton, .xvinkmomsbutton1 {
  width: calc(12% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xvinkmomsbutton1 {
  background-color: var(--rgbaVal);
}

.xvsparabutton, .xvsparabutton2, .xvsparabutton1 {
  color: black;
  cursor: pointer;
  width: calc(15% - 2px);
  margin-left: 2px;
  opacity: 0.8;
  padding: 1px;
}

.xvsparabutton1 {
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--rgbaVal);
}

.xvsparabutton2 {
  background-color: red;
  border: none;
  opacity: 1;
}

.xvbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.xvanimateddivPricelistNew {
  width: 100%;
  transform: rotate(0deg);
  animation-name: identifierCreateProduct;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1360px) and (max-width: 1367px) {
  .xvanimateddivPricelistNew {
    animation-name: identifierCreateProduct1;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xvanimateddivPricelistNew {
    animation-name: identifierCreateProduct2;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xvanimateddivPricelistNew {
    animation-name: identifierCreateProduct3;
  }
}
@media only screen and (min-width:2303px) {
  .xvanimateddivPricelistNew {
    animation-name: identifierCreateProduct4;
  }
}

@keyframes identifierCreateProduct {
  0% {
    height: 0px;
  }
  100% {
    height: 63px;
  }
}
@keyframes identifierCreateProduct1 {
  0% {
    height: 0px;
  }
  100% {
    height: 73px;
  }
}
@keyframes identifierCreateProduct2 {
  0% {
    height: 0px;
  }
  100% {
    height: 80px;
  }
}
@keyframes identifierCreateProduct3 {
  0% {
    height: 0px;
  }
  100% {
    height: 80px;
  }
}
@keyframes identifierCreateProduct4 {
  0% {
    height: 0px;
  }
  100% {
    height: 110px;
  }
}/*# sourceMappingURL=PriceListNew1.css.map */