
@import '../style/MainStyles.scss';/* styles.css */


.xhtotalArea {
    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows: 3% 5% 40% 52%;
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            ;
        width: 100%;
        height: 710px;
        object-fit: cover;

        @media (max-height:640px){
            grid-template-rows: 3% 5% 29% 53%;
        }

        
        @media(max-width:362px){
            grid-template-rows: 3% 5% 31% 53%;
        }
        
        @media(min-width:380px) and (max-width:386px){
            grid-template-rows: 3% 5% 42% 53%;
        }

        @media(min-width:392px) and (max-width:399px){
            grid-template-rows: 3% 5% 42% 53%;
        }

        @media(min-width:410px) and (max-width:415px){
            grid-template-rows: 3% 5% 42% 53%;
        }

        @media(min-width:425px) and (max-width:429px){
            grid-template-rows: 3% 5% 42% 53%;
        }
        @media(min-width:429px) and (max-width:431px){
            grid-template-rows: 3% 5% 42% 53%;
        }




  
    }
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: 2% auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: 2% auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
@include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: 2% auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: 2% auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.xhtotalArea1 {
    @extend .xhtotalArea;

    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, backgroundColor);
    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, backgroundColor);
    }
    @include gadgets-to(laptop) {
        background-color: map-get($generalStyles, backgroundColor);
    }
@include gadgets-to(desktop) {
        background-color: map-get($generalStyles, backgroundColor);
    }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, backgroundColor);
    }
}

  .xhbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
   
  }

.xhcompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 36px;
        color: white;
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xhcompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 36px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xhcompNameJAP{
    @extend .xhcompName;
    font-family: var(--font-family);
}
.xhcompNameJAP1{
    @extend .xhcompName1;
    font-family: var(--font-family);
    color:map-get($generalStyles,transparentChangeableMenu5);
}
.xhheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;
    }
@include gadgets-to(laptop) {
        grid-area: a;
        height:26px;
    }
@include gadgets-to(desktop) {
        grid-area: a;
        height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
        height:30px;
    }

}

.xhrangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:3%;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:3vh;
    }
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}

.xhareaA {
    @include gadgets-to(mobile) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:4%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        border-radius: map-get($generalStyles,sixBorderRadius);

        @media(max-height:640px){
            margin-top:5px;
            justify-content:flex-start;
            height:35vh;
        }

    }
@include gadgets-to(tablet) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(laptop) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(desktop) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(largeDesktop) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
}

.xhareaB {

    @include gadgets-to(mobile) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
      //  height:51vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);

       @media(max-height:640px){
        margin-top:1px;
        height:43vh;
       }
       @media(max-width:375px){
        margin-top:1px;
       }
       @media(min-height:600px) and (max-height:650px){
        margin-top:27px;
       }
       @media(min-width:414px) and (max-height:700px){
        margin-top:22px;
       }
    }
@include gadgets-to(tablet) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(laptop) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }

@include gadgets-to(desktop) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(largeDesktop) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
}
}


.xhareaC {
    @include gadgets-to(mobile) {
        // styles commented out
    }
    @include gadgets-to(tablet) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(laptop) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(desktop) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(largeDesktop) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
 }




.xhrange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xhrange2 {
    @extend .xhrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}
.xhCategoryDatalist{
    @include gadgets-to(mobile) {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;

        @media (max-height:640px){
            margin-top:10px;
        }
    }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    }
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    }
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    }

}

.xhCategoryTop{
    @include gadgets-to(mobile) {
        width:100%;
        display:flex;
        flex-direction:row;
       // align-items:center;
       // justify-content:center;
        flex-wrap:wrap;
        margin-top:5px;
     
    
    }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        flex-wrap:wrap;
        margin-top:2vh;
   
    }
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    margin-top:2vh;
    }
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    margin-top:2vh;
    }
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    margin-top:2vh;
    }
}

.xhrowsdata{
    @include gadgets-to(mobile) {
    
        width:100%;
        // margin-top:8%;
         min-height:1px;
         max-height:26vh;
         height:auto;
         overflow-x:hidden;
         overflow-y:scroll;
         align-self:center;
         // --webkit scrollbar
         &::-webkit-scrollbar {
             display:none;
           }

           @media(min-width:380px)
           and (max-width:385px)
           {
               max-height:32vh;
           }

        @media(min-width:390px)
        and (max-width:393px)
        {
            max-height:34vh;
        }
        @media(min-width:410px)
        and (max-width:413px)
        {
            max-height:28vh;
        }

        @media(min-width:425px)
        and (max-width:429px)
        {
            max-height:30vh;
        }

        @media(min-width:429px)
        and (max-width:431px)
        {
            max-height:30vh;
        }
        @media(min-width:478px)
        and (max-width:481px)
        {
            max-height:28vh;
        }

    }
    @include gadgets-to(tablet) {
        width:100%;
        // margin-top:8%;
         min-height:1px;
         max-height:65vh;
         height:auto;
         overflow-x:hidden;
         overflow-y:scroll;
         align-self:center;
         // --webkit scrollbar
         &::-webkit-scrollbar {
             display:none;
           }
    }
@include gadgets-to(laptop) {
    width:100%;
    // margin-top:8%;
     min-height:1px;
     max-height:72vh;
     height:auto;
     overflow-x:hidden;
     overflow-y:scroll;
     align-self:center;
     &::-webkit-scrollbar {
         display:none;
       }
    }
@include gadgets-to(desktop) {
    width:100%;
    // margin-top:8%;
     min-height:1px;
     max-height:75vh;
     height:auto;
     overflow-x:hidden;
     overflow-y:scroll;
     align-self:center;
     &::-webkit-scrollbar {
         display:none;
       }
}

@include gadgets-to(largeDesktop) {
    width:100%;
    // margin-top:8%;
     min-height:1px;
     max-height:75vh;
     height:auto;
     overflow-x:hidden;
     overflow-y:scroll;
     align-self:center;
     &::-webkit-scrollbar {
         display:none;
       }
}
}

.xhrows{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
@include gadgets-to(laptop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
@include gadgets-to(desktop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
@include gadgets-to(largeDesktop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
}


.xhBenaemnHeader {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
        margin-left:12%;
        width:55%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
      //  margin-left:12%;
        width:55%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
      //  margin-left:12%;
        width:55%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include  gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size:18px;
        color: grey;
        margin-bottom: 3px;
        margin-top: 3px;
        width:55%;
        height: 35px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size:24px;
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
      //  margin-left:12%;
        width:55%;
        height: 40px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
}

.xhAntalHeader {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
        margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:20%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
    @include gadgets-to(tablet) {
   
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        @media (min-width:1180px)and (orientation:landscape){
            margin-left:3px;
        }
        }

 
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 18px;
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height: 35px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size:24px;
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height:40px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
}



.xhdataBenaemn {
    @include gadgets-to(mobile) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
    @include gadgets-to(tablet) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 24px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.xhdataBenaemn1 {
    @include gadgets-to(mobile) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(tablet) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 26px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.xhdataAntal {
    @include gadgets-to(mobile) {
        width:20%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        width:25%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
        width:25%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size:24px;
        color: map-get($generalStyles, colorblack);
    }
}

.xhdataAntal1 {
    @include gadgets-to(mobile) {
        width:20%;
        height:30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        width:25%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
        width:25%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size:24px;
        color: map-get($generalStyles, colorblack);
    }
}



.xhrightdataArea {
    @include gadgets-to(mobile) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:15vh;
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }

        @media (max-height:640px){
            max-height:11vh;
        }
    }
    @include gadgets-to(tablet) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
@include gadgets-to(laptop) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
@include gadgets-to(desktop) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
@include gadgets-to(largeDesktop) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
}

.xhcategoryrightButton {
    @include gadgets-to(mobile) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(tablet) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        display: inline-block;
        cursor:pointer;
        // font-family:map-get($generalStyles,fontFamily);
        // font-size: 60px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        // padding-bottom: 3px;
        // padding-top: 4px;
        // padding-left: 5px;
        // padding-right: 5px;
        padding:5px;
        width:auto;
        height:30px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        display: inline-block;
        cursor:pointer;
        // font-family: map-get($generalStyles, fontFamily);
        // font-size:50px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
       border-radius: map-get($generalStyles,sixBorderRadius);
       margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.xhcategoryrightButton1 {
    @include gadgets-to(mobile) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);

    }
@include gadgets-to(tablet) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        display: inline-block;
        cursor:pointer;
        // font-family: map-get($generalStyles, fontFamily);
        // font-size:50px!important;
        // font-weight: bold;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        // padding-bottom: 3px;
        // padding-top: 4px;
        // padding-left: 5px;
        // padding-right: 5px;
        padding:5px;
        width:auto;
        height:30px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        display: inline-block;
        cursor:pointer;
        // font-family: map-get($generalStyles, fontFamily);
        // font-size:50px!important;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
      //  border-radius: map-get($generalStyles,sixBorderRadius);
       border-radius:10px;
      margin-bottom: 5px;
      padding-bottom: 3px;
      padding-top: 4px;
      padding-left: 5px;
      padding-right: 5px;
        margin-left: 5px;
        border:1px solid;;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.xhcategoryrightButtontext {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size: 16px;
    }
@include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size: 16px;
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size: 16px;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size:26px;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size:30px;
        
    }
}
.xhingredmoveTop{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction: row;
        justify-content:center;
    }
  @include gadgets-to(tablet) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5px;
    }
@include gadgets-to(laptop) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5px;
    }
@include gadgets-to(desktop) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5px;
    }
@include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5px;
    }
}

.ingredcatTrash {
    @include gadgets-to(mobile) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(tablet) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(laptop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(desktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(largeDesktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
}

.ingredcatTrash1 {
    @include gadgets-to(mobile) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(tablet) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(laptop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(desktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(largeDesktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
}

.xhcreatecatTop{
@include gadgets-to(mobile) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
}

.xhcatcreateCategoryTop{
    @include gadgets-to(mobile) {
        width:100%;
    }
    @include gadgets-to(tablet) {
        width:100%;
    }
@include gadgets-to(laptop) {
        width:100%;
        margin-top:10px;
        display:flex;
        flex-direction:row;
        justify-content:flex-start;

    }
@include gadgets-to(desktop) {
        width:100%;
        margin-top:10px;
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
    }
@include gadgets-to(largeDesktop) {
        width:100%;
        margin-top:20px;
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
    }

}

.xhcatcreateCategoryInput {
    @include gadgets-to(mobile) {
        width:60%;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 16px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(tablet) {
        width:60%;
        height:25px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 12px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(laptop) {
        width:60%;
        height:25px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 20px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      //  margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(desktop) {
        width:60%;
        height:30px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 18px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
       // margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(largeDesktop) {
        width:60%;
        height:50px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size:30px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      //  margin-top:3%;
        margin-left:4%;
    }
}

.xhproductcatcreateCategoryButton {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles,pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color:white;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:30%;
        height:29px;
       // margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:25%;
        height:36px;
      //  margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:25%;
        height:52px;
     //   margin-top: 3px;
        margin-bottom: 5px;
        border-radius:14px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
}

.xhproductcatcreateCategoryButton1 {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color:black;
        margin-left:3px;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:30%;
        height:29px;
        //margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:25%;
        height:34px;
      //  margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:25%;
        height:52px;
       // margin-top: 3px;
        margin-bottom: 5px;
        border-radius:14px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
}

.xhingredcatcreateCategoryTop {
    @include gadgets-to(mobile) {
    
    }
@include gadgets-to(tablet) {

    }
@include gadgets-to(laptop) {

    }
@include gadgets-to(desktop) {

    }
@include gadgets-to(largeDesktop) {
    margin-top:1.8%;
display:flex;
flex-direction:row;
justify-content:flex-start;
    }
}

.xhcreateCat {
    @include gadgets-to(mobile) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(tablet) {
        color: black;
        font-size: 35px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(laptop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(desktop) {
        color: black;
        font-size: 40px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(largeDesktop) {
        color: black;
        font-size: 50px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
}

.xhcreateCat1 {
    @include gadgets-to(mobile) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(tablet) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(laptop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(desktop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(largeDesktop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
}

.xhtextAreaMessage {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 200px;
        height: 430px;
        margin:10px;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size:18px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size:18px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;

    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size:18px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size:24px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;
    }
}

.xhSpinnerContainer {
    @include gadgets-to(mobile) {
        position: absolute;
        top: 290px;
        left: 170px;
        background-color: transparent;
        transform: scale(2.5);
        z-index: 10;
    }
@include gadgets-to(tablet) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(laptop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(desktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(largeDesktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
}

.xhSpinner {
    @include gadgets-to(mobile) {
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(laptop) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(desktop) {
        color: map-get($generalStyles, colorblack);
    }
}



.ingredmoveitemTop {
    @include gadgets-to(mobile) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
   
    }
@include gadgets-to(tablet) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(laptop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(desktop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(largeDesktop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
}

.xhreceptmoveitemTop {
    @include gadgets-to(mobile) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
   
    }
@include gadgets-to(tablet) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(laptop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(desktop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(largeDesktop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
}


.xhreceptmoveitemFieldset {
    @include gadgets-to(mobile) {
        max-height:10vh;
      
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
   
   
   
    }
@include gadgets-to(tablet) {
        margin-top:10px;
        max-height:21vh;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
@include gadgets-to(laptop) {
        margin-top:10px;
        max-height:25vh;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
@include gadgets-to(desktop) {
        margin-top:10px;
        max-height:25vh;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
@include gadgets-to(largeDesktop) {
        margin-top:10px;
        max-height:25vh;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        overflow-y:auto;
        //webkit-scrollbar
        &::-webkit-scrollbar {
            width:10px;
          }
        //track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
          }
        //handle
        &::-webkit-scrollbar-thumb {
            background:white;
            border-radius: 6px;
            border:1px solid grey;
          }
    }
}

.xhreceptmoveitemLegend {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
}

.xhmoveitemSelect {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
     //   margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
       // margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 18px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
       // margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
}

.xhmoveitemSaveButton {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 32px;
       // margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
        //margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
}

.xhmoveitemSaveButton1 {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.5;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 32px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.5;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.6;
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
     //   margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.6;
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
    //    margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.6;
        color:map-get($generalStyles, colorblack);
    }
}

// .test {
//     @include gadgets-to(mobile) {
//         position: absolute;
//         top: 100px;
//         left: 100px;
//         width: 100px;
//         height: 100px;
//         background-color: red;
//     }
// }

.xhCategoryInputKanji {
    @include gadgets-to(mobile) {
        grid-area: 'b';
        width:100%;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(tablet) {
        grid-area: 'b';
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(laptop) {
        grid-area: 'b';
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(desktop) {
        grid-area: 'b';
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
}
@include gadgets-to(largeDesktop) {
        grid-area: 'b';
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
}

.xhCategoryInputFurigana {
    @include gadgets-to(mobile) {
        width:100%;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(tablet) {
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(laptop) {
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(desktop) {
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(largeDesktop) {
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
}

.xhCategoryTopJAP {
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        margin-top:5px;
    }
@include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:5px;
    }
@include gadgets-to(laptop) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:10px;
    }
@include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:10px;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:10px;
    }
}

.xhcreateCategoryButtonJAP1 {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left:66.3%;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
}

.xhcreateCategoryButtonJAP {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px rgb(85, 68, 68);
        margin-left:66.3%;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);         
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
}
