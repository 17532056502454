:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
html {
  scrollbar-width: thin; /* For Firefox */
  overflow: overlay; /* For Chrome, Safari */
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdproduktArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdproduktArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdproduktArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdproduktArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width:2303px) {
  .xdproduktArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}

.backgroundImageListProdukt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
  opacity: 0.8;
}

.backgroundImageListProdukt1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: white;
}

.xdheaderArea {
  grid-area: a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 99%;
  margin-top: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdrangeArea {
    grid-area: b;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xdrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}

.xdProduktListB {
  grid-area: c;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--val);
  width: 99%;
  border: 1px solid white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdProduktListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
    height: 78vh;
    margin-left: 2px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdProduktListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 80%;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdProduktListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 70%;
    border-radius: 6px;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdProduktListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 60%;
    border-radius: 6px;
    margin-top: 10px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xdProduktListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 60%;
    border-radius: 6px;
    margin-top: 10px;
    border-color: rgb(223, 212, 212);
  }
}

.xdcompName {
  font-family: "Lobster";
  font-Size: 26px;
  color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdcompName {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdcompName {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdcompName {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xdcompName {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: white;
  }
}

.xdcompName1 {
  grid-area: a;
  font-family: "Lobster";
  font-Size: 26px;
  color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdcompName1 {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdcompName1 {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdcompName1 {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xdcompName1 {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

.xdcompNameJAP {
  grid-area: a;
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdcompNameJAP {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdcompNameJAP {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdcompNameJAP {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xdcompNameJAP {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

.xdcompNameJAP1 {
  grid-area: a;
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdcompNameJAP1 {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdcompNameJAP1 {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdcompNameJAP1 {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 35px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xdcompNameJAP1 {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

.xdrange1 {
  grid-area: b;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xdrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.xdrange2 {
  grid-area: b;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xdrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xdproduktselectTop {
  grid-area: c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 99%;
}

.xdproduktselectFieldset {
  grid-area: c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-top: 3vh;
  border: none;
}

.xdproduktselectLegend {
  margin-left: 5px;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdproduktselectLegend {
    margin-left: 2.5%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdproduktselectLegend {
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdproduktselectLegend {
    margin-left: 3.1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdproduktselectLegend {
    margin-left: 5.5%;
  }
}
@media only screen and (min-width:2303px) {
  .xdproduktselectLegend {
    margin-left: 2.5%;
  }
}

.xdproduktselectLegendJAP {
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdproduktselectLegendJAP {
    margin-left: 10%;
  }
}

.xdproduktselectProdukt {
  width: 95%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdproduktselectProdukt {
    width: 80%;
  }
}

.xdproduktselectProduktJAP {
  width: 100%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdproduktselectProduktJAP {
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xdproduktselectProduktJAP {
    width: 75%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xddatalistTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xddatalistTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xddatalistTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xddatalistTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
  }
}
@media only screen and (min-width:2303px) {
  .xddatalistTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdlistproduktid, .xdlistproduktidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdlistproduktid, .xdlistproduktidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdlistproduktid, .xdlistproduktidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdlistproduktid, .xdlistproduktidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xdlistproduktid, .xdlistproduktidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.xdlistproduktidJAP {
  padding-top: 1px;
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdlistproduktbenaemn, .xdlistproduktbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdlistproduktbenaemn, .xdlistproduktbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdlistproduktbenaemn, .xdlistproduktbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdlistproduktbenaemn, .xdlistproduktbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xdlistproduktbenaemn, .xdlistproduktbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.xdlistproduktbenaemnJAP {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdlistproduktactive, .xdlistproduktactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdlistproduktactive, .xdlistproduktactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdlistproduktactive, .xdlistproduktactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdlistproduktactive, .xdlistproduktactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xdlistproduktactive, .xdlistproduktactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.xdlistproduktactiveJAP {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdlistproduktSave, .xdlistproduktSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    margin-bottom: 3px;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdlistproduktSave, .xdlistproduktSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdlistproduktSave, .xdlistproduktSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdlistproduktSave, .xdlistproduktSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xdlistproduktSave, .xdlistproduktSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}

.xdlistproduktSaveJAP {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xddatalistData, .xddatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 98%;
  }
  .xddatalistData::-webkit-scrollbar, .xddatalistDataJAP::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xddatalistData::-webkit-scrollbar-track, .xddatalistDataJAP::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xddatalistData, .xddatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 80%;
  }
  .xddatalistData::-webkit-scrollbar, .xddatalistDataJAP::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xddatalistData::-webkit-scrollbar-track, .xddatalistDataJAP::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xddatalistData, .xddatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .xddatalistData::-webkit-scrollbar, .xddatalistDataJAP::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xddatalistData::-webkit-scrollbar-track, .xddatalistDataJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xddatalistData::-webkit-scrollbar-thumb, .xddatalistDataJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xddatalistData, .xddatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .xddatalistData::-webkit-scrollbar, .xddatalistDataJAP::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xddatalistData::-webkit-scrollbar-track, .xddatalistDataJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xddatalistData::-webkit-scrollbar-thumb, .xddatalistDataJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xddatalistData, .xddatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .xddatalistData::-webkit-scrollbar, .xddatalistDataJAP::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
  }
  .xddatalistData::-webkit-scrollbar-track, .xddatalistDataJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xddatalistData::-webkit-scrollbar-thumb, .xddatalistDataJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xditemId, .xditemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xditemId, .xditemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xditemId, .xditemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xditemId, .xditemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xditemId, .xditemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}

.xditemId1 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdbenaemn, .xdbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdbenaemn, .xdbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdbenaemn, .xdbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdbenaemn, .xdbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xdbenaemn, .xdbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}

.xdbenaemn1 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdactive, .xdactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .xdactive .xddataitemChecked, .xdactive1 .xddataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xdactive .xddataitemUnchecked, .xdactive1 .xddataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdactive, .xdactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .xdactive .xddataitemChecked, .xdactive1 .xddataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xdactive .xddataitemUnchecked, .xdactive1 .xddataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdactive, .xdactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
  }
  .xdactive .xddataitemChecked, .xdactive1 .xddataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xdactive .xddataitemUnchecked, .xdactive1 .xddataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdactive, .xdactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
  }
  .xdactive .xddataitemChecked, .xdactive1 .xddataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xdactive .xddataitemUnchecked, .xdactive1 .xddataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width:2303px) {
  .xdactive, .xdactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
  }
  .xdactive .xddataitemChecked, .xdactive1 .xddataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xdactive .xddataitemUnchecked, .xdactive1 .xddataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}

.xdactive1 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdsaveButton1, .xdsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdsaveButton1, .xdsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdsaveButton1, .xdsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdsaveButton1, .xdsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xdsaveButton1, .xdsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xdsaveButton2, .xdsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xdsaveButton2, .xdsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xdsaveButton2, .xdsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xdsaveButton2, .xdsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xdsaveButton2, .xdsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}

.xdsaveButtonJAP1 {
  font-size: 12px;
}

.xdsaveButtonJAP2 {
  font-size: 12px;
}

.xdlistSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  background-color: transparent;
}

.xbtotalArea1, .xbtotalArea {
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-areas: "a" "b";
  width: 99%;
}
@media (min-width: 834px) {
  .xbtotalArea1, .xbtotalArea {
    display: grid;
    grid-template-rows: 8% 92%;
    grid-template-areas: "a" "b";
    width: 99%;
    justify-items: center;
  }
}
@media (min-width: 1200px) {
  .xbtotalArea1, .xbtotalArea {
    display: grid;
    grid-template-rows: 8% 92%;
    grid-template-areas: "a" "b";
    width: 99%;
    justify-items: center;
    height: 91vh;
  }
}

.xbtotalArea {
  background-color: rgb(245, 245, 237);
}

.backgroundImageListProduct {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
  opacity: 0.8;
}

.xbareaA {
  grid-area: a;
}
@media (max-width: 575.98px) {
  .xbareaA {
    height: 10vh;
    margin-top: 70px;
    width: 99%;
    border-radius: 6px;
  }
}
@media (min-width: 834px) {
  .xbareaA {
    height: 50vh;
    width: 99%;
    border-radius: 6px;
  }
}
@media (min-width: 1200px) {
  .xbareaA {
    height: 50vh;
    width: 99%;
    border-radius: 6px;
  }
}

.xbareaB {
  grid-area: b;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--val);
  width: 99%;
}
@media (max-width: 575.98px) {
  .xbareaB {
    height: 78vh;
    margin-top: 5px;
    width: 99%;
    margin-left: 2px;
    border-radius: 6px;
  }
}
@media (min-width: 834px) {
  .xbareaB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 80%;
    border-radius: 6px;
  }
}
@media (min-width: 1200px) {
  .xbareaB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 70%;
    border-radius: 6px;
    margin-top: 50px;
  }
}
@media (max-height: 632px) and (max-width: 1280px) and (orientation: landscape) {
  .xbareaB {
    margin-top: 5px;
    height: 70vh;
  }
}

.xbCompName {
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Lobster";
  font-Size: 26px;
  color: white;
}
@media (min-width: 1200px) {
  .xbCompName {
    top: 90px;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media (min-width: 1200px) {
  .xbCompName {
    top: 130px;
    font-family: "Lobster";
    font-Size: 30px;
    color: white;
  }
}
@media (min-width: 2303px) {
  .xbCompName {
    top: 130px;
    font-family: "Lobster";
    font-Size: 40px;
    color: white;
  }
}
@media (max-height: 632px) and (max-width: 1280px) and (orientation: landscape) {
  .xbCompName {
    top: 70px;
    font-family: "Lobster";
    font-Size: 30px;
    color: white;
  }
}

.xbCompName1 {
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Lobster";
  font-Size: 26px;
  color: var(--rgbaVal);
}
@media (min-width: 1200px) {
  .xbCompName1 {
    top: 130px;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media (min-width: 2303px) {
  .xbCompName1 {
    top: 130px;
    font-family: "Lobster";
    font-Size: 40px;
    color: var(--rgbaVal);
  }
}
@media (max-height: 632px) and (max-width: 1280px) and (orientation: landscape) {
  .xbCompName1 {
    top: 70px;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}

.xbCompNameJAP {
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: var(--rgbaVal);
}
@media (min-width: 1200px) {
  .xbCompNameJAP {
    top: 90px;
    font-family: "Lobster";
    font-Size: 30px;
  }
}
@media (min-width: 2303px) {
  .xbCompNameJAP {
    top: 130px;
    font-family: "Lobster";
    font-Size: 49px;
  }
}

.xbCompNameJAP1 {
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: var(--rgbaVal);
}
@media (min-width: 1200px) {
  .xbCompNameJAP1 {
    top: 90px;
  }
}
@media (min-width: 2303px) {
  .xbCompNameJAP1 {
    top: 130px;
    font-family: "Lobster";
    font-Size: 40px;
  }
}

.xbproduktselectTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 99%;
}

.xbproduktselectFieldset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-top: 3vh;
  border: none;
}
@media (min-width: 834px) {
  .xbproduktselectFieldset {
    margin-top: 1vh;
  }
}

.xbproduktselectLegend {
  margin-left: 5px;
  font-family: var(--font-family);
  font-size: 14px;
}
@media (min-width: 834px) {
  .xbproduktselectLegend {
    margin-left: 20px;
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .xbproduktselectLegend {
    margin-left: 30px;
    font-size: 14px;
  }
}

.xbproduktselectLegendJAP {
  font-family: var(--font-family);
  font-size: 14px;
}

.xbproduktselectProdukt {
  width: 95%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}

.xbproduktselectProduktJAP {
  width: 100%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}

.xbdatalistTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
  margin: 0 2px;
  width: 98%;
}

.xblistproduktid, .xblistproduktidJAP {
  width: 15%;
  height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: var(--rgbaVal);
  padding-top: 5px;
  text-align: center;
  color: black;
}

.xblistproduktidJAP {
  padding-top: 1px;
  color: black;
}

.xblistproduktbenaemn, .xblistproduktbenaemnJAP {
  width: 55%;
  height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: var(--rgbaVal);
  padding-top: 5px;
  text-align: center;
  color: black;
}
@media (min-width: 834px) {
  .xblistproduktbenaemn, .xblistproduktbenaemnJAP {
    width: 62%;
  }
}

.xblistproduktbenaemnJAP {
  padding-top: 1px;
}

.xblistproduktactive, .xblistproduktactiveJAP {
  width: 15%;
  height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: var(--rgbaVal);
  padding-top: 5px;
  text-align: center;
  color: black;
}
@media (min-width: 834px) {
  .xblistproduktactive, .xblistproduktactiveJAP {
    width: 7%;
  }
}
@media (min-width: 1200px) {
  .xblistproduktactive, .xblistproduktactiveJAP {
    width: 7%;
  }
}

.xblistproduktactiveJAP {
  padding-top: 1px;
}

.xblistproduktSave, .xblistproduktSaveJAP {
  width: 15%;
  height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  background-color: var(--rgbaVal);
  padding-top: 5px;
  text-align: center;
  margin-bottom: 3px;
  color: black;
}

.xblistproduktSaveJAP {
  padding-top: 1px;
}

.xbdatalistData, .xbdatalistDataJAP {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 0 2px;
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
}
@media (min-width: 834px) {
  .xbdatalistData, .xbdatalistDataJAP {
    width: 97.5%;
    max-height: 419px;
    overflow-y: scroll;
  }
}
@media (min-width: 1200px) {
  .xbdatalistData, .xbdatalistDataJAP {
    max-height: 555px;
    overflow-y: scroll;
  }
}
@media (min-width: 2303px) {
  .xbdatalistData, .xbdatalistDataJAP {
    max-height: 855px;
    overflow-y: scroll;
  }
}

.xbdatalistDataItemId1 {
  height: 34px;
}

.xbdatalistDataItemId, .xbdatalistDataItemId1 {
  width: 15%;
  height: 30px;
  border-radius: 6px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal2);
  margin-bottom: 3px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
}
@media (min-width: 834px) {
  .xbdatalistDataItemId, .xbdatalistDataItemId1 {
    width: 15%;
  }
}
@media (min-width: 1200px) {
  .xbdatalistDataItemId, .xbdatalistDataItemId1 {
    width: 15.1%;
  }
}
@media (min-width: 2303px) {
  .xbdatalistDataItemId, .xbdatalistDataItemId1 {
    width: 15.1%;
    height: 35px;
  }
}

.xbdatalistDataItemId1 {
  background-color: var(--rgbaVal);
}

.xbdatalistbenaemn1, .xbdatalistbenaemn {
  width: 55%;
  height: 30px;
  border-radius: 6px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  margin-bottom: 3px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  margin-left: 3px;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 834px) {
  .xbdatalistbenaemn1, .xbdatalistbenaemn {
    width: 62.2%;
  }
}
@media (min-width: 1200px) {
  .xbdatalistbenaemn1, .xbdatalistbenaemn {
    width: 62.9%;
  }
}
@media (min-width: 2303px) {
  .xbdatalistbenaemn1, .xbdatalistbenaemn {
    width: 62.9%;
    height: 35px;
  }
}

.xbdatalistbenaemn {
  background-color: var(--rgbaVal2);
}

.xbDatalistDataItemActive {
  width: 12%;
  cursor: pointer;
}
@media (min-width: 834px) and (max-width: 1180px) {
  .xbDatalistDataItemActive {
    width: 3%;
    margin-left: 25px;
  }
}
@media (min-width: 1200px) {
  .xbDatalistDataItemActive {
    margin-left: 40px;
    width: 16px;
    height: 16px;
  }
}
@media (min-width: 2303px) {
  .xbDatalistDataItemActive {
    margin-left: 50px;
    width: 18px;
    height: 18px;
  }
}
@media (min-width: 834px) and (max-width: 1112px) {
  .xbDatalistDataItemActive {
    width: 3%;
    margin-left: 30px;
  }
}
@media (max-height: 632px) and (max-width: 1280px) and (orientation: landscape) {
  .xbDatalistDataItemActive {
    width: 2%;
    margin-left: 25px;
  }
}

.xbDatalistDataItemSaveButton1, .xbDatalistDataItemSaveButtonJAP {
  width: 13.5%;
  height: 30px;
  border-radius: 6px;
  margin-left: 5px;
  border-color: rgb(223, 212, 212);
  border: 1px solid;
  background-color: rgb(229, 64, 68);
  color: white;
  font-family: var(--font-family);
  font-size: 14px;
  padding: 1px;
}
@media (min-width: 834px) {
  .xbDatalistDataItemSaveButton1, .xbDatalistDataItemSaveButtonJAP {
    width: 14%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 15px;
  }
}
@media (min-width: 834px) and (max-width: 1112px) {
  .xbDatalistDataItemSaveButton1, .xbDatalistDataItemSaveButtonJAP {
    width: 13.5%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 13px;
  }
}
@media (min-width: 1200px) {
  .xbDatalistDataItemSaveButton1, .xbDatalistDataItemSaveButtonJAP {
    width: 14%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 35px;
  }
}
@media (min-width: 2303px) {
  .xbDatalistDataItemSaveButton1, .xbDatalistDataItemSaveButtonJAP {
    width: 14%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 41px;
  }
}
@media (max-height: 632px) and (max-width: 1280px) and (orientation: landscape) {
  .xbDatalistDataItemSaveButton1, .xbDatalistDataItemSaveButtonJAP {
    width: 12%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}

.xbDatalistDataItemSaveButton2, .xbDatalistDataItemSaveButtonJAP1 {
  border-radius: 6px;
  margin-left: 5px;
  width: 13.5%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: white;
  font-family: var(--font-family);
  font-size: 14px;
  padding: 1px;
  color: black;
}
@media (min-width: 834px) {
  .xbDatalistDataItemSaveButton2, .xbDatalistDataItemSaveButtonJAP1 {
    width: 14.7%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 12px;
  }
}
@media (min-width: 834px) and (max-width: 1112px) {
  .xbDatalistDataItemSaveButton2, .xbDatalistDataItemSaveButtonJAP1 {
    width: 13.5%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 13px;
  }
}
@media (min-width: 1200px) {
  .xbDatalistDataItemSaveButton2, .xbDatalistDataItemSaveButtonJAP1 {
    width: 14%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 35px;
  }
}
@media (min-width: 2303px) {
  .xbDatalistDataItemSaveButton2, .xbDatalistDataItemSaveButtonJAP1 {
    width: 14%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 41px;
  }
}
@media (max-height: 632px) and (max-width: 1280px) and (orientation: landscape) {
  .xbDatalistDataItemSaveButton2, .xbDatalistDataItemSaveButtonJAP1 {
    width: 13%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-left: 21px;
  }
}

.xblistSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  background-color: transparent;
}/*# sourceMappingURL=ListProdukt1.css.map */