:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xrtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrwindowArea, .xrwindowArea1 {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas: "a" "b" "c" "d" "e";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrwindowArea, .xrwindowArea1 {
    display: grid;
    grid-template-columns: 28% 43% 28%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrwindowArea, .xrwindowArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrwindowArea, .xrwindowArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xrwindowArea, .xrwindowArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 99.8%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xrwindowArea1 {
  background-color: rgb(245, 245, 237);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrwindowAreaA {
    grid-area: c;
    border: none;
    width: 100%;
    height: 30vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrwindowAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xrwindowAreaA {
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrwindowAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrwindowAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xrwindowAreaA {
    grid-area: c;
    border: 1px solid;
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrwindowAreaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 50vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrwindowAreaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xrwindowAreaB {
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrwindowAreaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrwindowAreaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xrwindowAreaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrwindowAreaC {
    grid-area: e;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 98%;
    height: 320px;
    margin-left: 1px;
    background-color: transparent;
    margin-top: 20px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) {
  .xrwindowAreaC {
    margin-top: 30px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 390px) and (max-width: 420px) {
  .xrwindowAreaC {
    margin-top: 30px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 421px) {
  .xrwindowAreaC {
    margin-top: 35px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrwindowAreaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xrwindowAreaC {
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrwindowAreaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrwindowAreaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xrwindowAreaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrheaderArea2 {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrheaderArea2 {
    grid-area: a;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrheaderArea2 {
    grid-area: a;
    height: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrheaderArea2 {
    grid-area: a;
    height: 50px;
  }
}
@media only screen and (min-width:2303px) {
  .xrheaderArea2 {
    grid-area: a;
    height: 50px;
  }
}

.xrheaderArea1 {
  width: 95%;
  height: 30px;
}
@media only screen and (min-width:2303px) {
  .xrheaderArea1 {
    display: flex;
    flex-direction: row;
    width: 95%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xrcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xrcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xrcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xrcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xrrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xrrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xrrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrrange1, .xrrange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrrange1, .xrrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrrange1, .xrrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrrange1, .xrrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xrrange1, .xrrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xrrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xrleftTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xrleftFieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-color: rgb(223, 212, 212);
  width: 100%;
  margin-top: 10px;
  height: auto;
}

.xrleftLegend {
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
  margin-left: 5%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrleftLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 5px;
    margin-left: 14%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrleftLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-left: 15%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrleftLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-left: 15%;
  }
}
@media only screen and (min-width:2303px) {
  .xrleftLegend {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-left: 15%;
  }
}

.xrleftSelect {
  width: 90%;
  height: 30px;
  outline: none;
  border-radius: 6px;
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrleftSelect {
    font-family: var(--font-family);
    font-size: 14px;
    width: 70%;
    height: 30px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xrleftSelect {
    margin: 0;
    padding: 0;
    height: 30px;
    line-height: 25px;
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrleftSelect {
    font-family: var(--font-family);
    font-size: 14px;
    width: 70%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrleftSelect {
    font-family: var(--font-family);
    font-size: 14px;
    width: 70%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-right: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .xrleftSelect {
    font-family: var(--font-family);
    font-size: 14px;
    width: 70%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    margin-right: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrdataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 18vh;
    max-height: 18vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;
  }
  .xrdataTop::-webkit-scrollbar {
    width: 10px;
  }
  .xrdataTop::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrdataTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrdataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;
  }
  .xrdataTop::-webkit-scrollbar {
    width: 1px;
  }
  .xrdataTop::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrdataTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrdataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    max-height: 60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;
  }
  .xrdataTop::-webkit-scrollbar {
    width: 10px;
  }
  .xrdataTop::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrdataTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrdataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    max-height: 60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;
  }
  .xrdataTop::-webkit-scrollbar {
    width: 10px;
  }
  .xrdataTop::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrdataTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xrdataTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    max-height: 60vh;
    overflow-y: scroll;
    margin-top: 1px;
    border: none;
  }
  .xrdataTop::-webkit-scrollbar {
    width: 10px;
  }
  .xrdataTop::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrdataTop::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrreceptidButton, .xrreceptidButton1 {
    width: 240px;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrreceptidButton, .xrreceptidButton1 {
    width: 80%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrreceptidButton, .xrreceptidButton1 {
    width: 80%;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrreceptidButton, .xrreceptidButton1 {
    width: 80%;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xrreceptidButton, .xrreceptidButton1 {
    width: 80%;
    height: 50px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 3px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
  }
}

.xrreceptidButton1 {
  background-color: var(--rgbaVal2);
}

.xrdatatop2 {
  width: 100%;
}

.xrdatafieldset {
  border: none;
  border-color: rgb(223, 212, 212);
  width: 100%;
  margin-top: 10px;
  padding: 0;
}
@media only screen and (min-width:2303px) {
  .xrdatafieldset {
    margin-left: 1.5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrdatalegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 3%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrdatalegend {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-left: 3%;
    padding-bottom: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrdatalegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-left: 2.6%;
    padding-bottom: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrdatalegend {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-left: 2.5%;
  }
}
@media only screen and (min-width:2303px) {
  .xrdatalegend {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-left: 2.5%;
  }
}

.xrheaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width:2303px) {
  .xrheaderWrapper {
    width: 95%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrheaderArea {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrheaderArea {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 3px;
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrheaderArea {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrheaderArea {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 3px;
    height: 25px;
  }
}
@media only screen and (min-width:2303px) {
  .xrheaderArea {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 3px;
    height: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrheaderIngredid, .xrheaderArborder, .xrheaderIngredArbvikt, .xrheaderKoeff, .xrheaderMangd, .xrheaderBenaemn {
    width: 13%;
    height: 25px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrheaderIngredid, .xrheaderArborder, .xrheaderIngredArbvikt, .xrheaderKoeff, .xrheaderMangd, .xrheaderBenaemn {
    width: 13%;
    height: 30px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrheaderIngredid, .xrheaderArborder, .xrheaderIngredArbvikt, .xrheaderKoeff, .xrheaderMangd, .xrheaderBenaemn {
    width: 13%;
    height: 30px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrheaderIngredid, .xrheaderArborder, .xrheaderIngredArbvikt, .xrheaderKoeff, .xrheaderMangd, .xrheaderBenaemn {
    width: 13%;
    height: 30px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xrheaderIngredid, .xrheaderArborder, .xrheaderIngredArbvikt, .xrheaderKoeff, .xrheaderMangd, .xrheaderBenaemn {
    width: 13%;
    height: 30px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
  }
}

.xrheaderBenaemn {
  width: calc(35% - 2px);
  margin-left: 2px;
}

.xrheaderMangd {
  width: calc(13% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xrheaderKoeff {
  width: calc(13% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xrheaderIngredArbvikt {
  width: calc(13% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xrheaderArborder {
  width: calc(13% - 2px);
  margin-left: 2px;
  padding: 1px;
}
@media only screen and (min-width:2303px) {
  .xrheaderArborder {
    width: calc(13% - 2px);
    margin-left: 2px;
    padding: 1px;
    margin-right: 15px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrdataAreaTop {
    width: 100%;
    height: auto;
    max-height: 22vh;
    overflow-y: scroll;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xrdataAreaTop::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xrdataAreaTop::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xrdataAreaTop::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrdataAreaTop {
    width: 100%;
    height: auto;
    max-height: 35vh;
    overflow-y: scroll;
    font-family: var(--font-family);
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xrdataAreaTop::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xrdataAreaTop::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xrdataAreaTop::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1195px) {
  .xrdataAreaTop {
    max-height: 31vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrdataAreaTop {
    width: 100%;
    height: auto;
    max-height: 40vh;
    overflow-y: scroll;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xrdataAreaTop::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xrdataAreaTop::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xrdataAreaTop::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrdataAreaTop {
    width: 100%;
    height: auto;
    max-height: 35vh;
    overflow-y: scroll;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xrdataAreaTop::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xrdataAreaTop::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xrdataAreaTop::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xrdataAreaTop {
    width: 95%;
    height: auto;
    max-height: 35vh;
    overflow-y: scroll;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xrdataAreaTop::-webkit-scrollbar {
    width: 15px; /* width of the entire scrollbar */
  }
  .xrdataAreaTop::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xrdataAreaTop::-webkit-scrollbar-thumb {
    background-color: white;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

.xrdatarows {
  grid-area: b;
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdataingredid, .xrsamdataarborder, .xrsamdataarborder1, .xrsamdataarbvikt, .xrsamdataarbvikt1, .xrsamdatakoeff, .xrsamdatakoeff1, .xrsamdatamangd, .xrsamdatamangd1, .xrsamdatabenaemn, .xrsamdatabenaemn1, .xrsamdataingredid1 {
    width: 13%;
    height: 25px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdataingredid, .xrsamdataarborder, .xrsamdataarborder1, .xrsamdataarbvikt, .xrsamdataarbvikt1, .xrsamdatakoeff, .xrsamdatakoeff1, .xrsamdatamangd, .xrsamdatamangd1, .xrsamdatabenaemn, .xrsamdatabenaemn1, .xrsamdataingredid1 {
    width: 13%;
    height: 30px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 3px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdataingredid, .xrsamdataarborder, .xrsamdataarborder1, .xrsamdataarbvikt, .xrsamdataarbvikt1, .xrsamdatakoeff, .xrsamdatakoeff1, .xrsamdatamangd, .xrsamdatamangd1, .xrsamdatabenaemn, .xrsamdatabenaemn1, .xrsamdataingredid1 {
    width: 13%;
    height: 30px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdataingredid, .xrsamdataarborder, .xrsamdataarborder1, .xrsamdataarbvikt, .xrsamdataarbvikt1, .xrsamdatakoeff, .xrsamdatakoeff1, .xrsamdatamangd, .xrsamdatamangd1, .xrsamdatabenaemn, .xrsamdatabenaemn1, .xrsamdataingredid1 {
    width: 13%;
    height: 40px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdataingredid, .xrsamdataarborder, .xrsamdataarborder1, .xrsamdataarbvikt, .xrsamdataarbvikt1, .xrsamdatakoeff, .xrsamdatakoeff1, .xrsamdatamangd, .xrsamdatamangd1, .xrsamdatabenaemn, .xrsamdatabenaemn1, .xrsamdataingredid1 {
    width: 13%;
    height: 45px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 20px;
    margin-top: 3px;
  }
}

.xrsamdataingredid1 {
  background-color: var(--rgbaVal);
  width: 13%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdatabenaemn, .xrsamdatabenaemn1 {
    width: calc(35% - 2px);
    margin-left: 2px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdatabenaemn, .xrsamdatabenaemn1 {
    width: calc(35% - 2px);
    margin-left: 2px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdatabenaemn, .xrsamdatabenaemn1 {
    width: calc(35% - 2px);
    margin-left: 2px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdatabenaemn, .xrsamdatabenaemn1 {
    width: calc(35% - 2px);
    margin-left: 2px;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdatabenaemn, .xrsamdatabenaemn1 {
    width: calc(35% - 2px);
    margin-left: 2px;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdatabenaemn1 {
    background-color: var(--rgbaVal);
    width: calc(35% - 2px);
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdatabenaemn1 {
    background-color: var(--rgbaVal);
    width: calc(35% - 2px);
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdatabenaemn1 {
    background-color: var(--rgbaVal);
    width: calc(35% - 2px);
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdatabenaemn1 {
    background-color: var(--rgbaVal);
    width: calc(35% - 2px);
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdatabenaemn1 {
    background-color: var(--rgbaVal);
    width: calc(35% - 2px);
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdatamangd, .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdatamangd, .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdatamangd, .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdatamangd, .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdatamangd, .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    padding: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdatamangd1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    background-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdatakoeff, .xrsamdatakoeff1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 12px;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdatakoeff, .xrsamdatakoeff1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 14px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdatakoeff, .xrsamdatakoeff1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 14px;
    padding: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdatakoeff, .xrsamdatakoeff1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 18px;
    padding: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdatakoeff, .xrsamdatakoeff1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 20px;
    padding: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdatakoeff1 {
    background-color: var(--rgbaVal);
    width: calc(13% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdatakoeff1 {
    background-color: var(--rgbaVal);
    width: calc(13% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdatakoeff1 {
    background-color: var(--rgbaVal);
    width: calc(13% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdatakoeff1 {
    background-color: var(--rgbaVal);
    width: calc(13% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdatakoeff1 {
    background-color: var(--rgbaVal);
    width: calc(13% - 2px);
    margin-left: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdataarbvikt, .xrsamdataarbvikt1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 12px;
    border-color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdataarbvikt, .xrsamdataarbvikt1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 14px;
    border-color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdataarbvikt, .xrsamdataarbvikt1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 14px;
    border-color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdataarbvikt, .xrsamdataarbvikt1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 18px;
    border-color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdataarbvikt, .xrsamdataarbvikt1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 20px;
    border-color: black;
  }
}

.xrsamdataarbvikt1 {
  background-color: var(--rgbaVal);
  width: calc(13% - 2px);
  margin-left: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrsamdataarborder, .xrsamdataarborder1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrsamdataarborder, .xrsamdataarborder1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrsamdataarborder, .xrsamdataarborder1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrsamdataarborder, .xrsamdataarborder1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .xrsamdataarborder, .xrsamdataarborder1 {
    width: calc(13% - 2px);
    margin-left: 2px;
    font-size: 20px;
  }
}

.xrsamdataarborder1 {
  background-color: var(--rgbaVal);
  width: calc(13% - 2px);
  margin-left: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrfooterWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrfooterWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrfooterWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrfooterWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xrfooterWrapper {
    display: flex;
    flex-direction: column;
    width: 93.5%;
  }
}

.xrfooter {
  display: flex;
  flex-direction: row;
  margin-top: 1px;
  padding: 0;
  margin: 0;
  width: 95%;
}
@media only screen and (min-width:2303px) {
  .xrfooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    margin-left: 2.5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrfooterIngredid, .xrfooterArborder, .xrfooterIngredArbvikt, .xrfooterKoeff, .xrfooterMangd, .xrfooterBenaemn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 13%;
    height: 20px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-top: 3px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrfooterIngredid, .xrfooterArborder, .xrfooterIngredArbvikt, .xrfooterKoeff, .xrfooterMangd, .xrfooterBenaemn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 13%;
    height: 25px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrfooterIngredid, .xrfooterArborder, .xrfooterIngredArbvikt, .xrfooterKoeff, .xrfooterMangd, .xrfooterBenaemn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 13%;
    height: 20px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrfooterIngredid, .xrfooterArborder, .xrfooterIngredArbvikt, .xrfooterKoeff, .xrfooterMangd, .xrfooterBenaemn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 13%;
    height: 30px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xrfooterIngredid, .xrfooterArborder, .xrfooterIngredArbvikt, .xrfooterKoeff, .xrfooterMangd, .xrfooterBenaemn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 13%;
    height: 40px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 3px;
  }
}

.xrfooterBenaemn {
  width: calc(35% - 2px);
  margin-left: 2px;
}

.xrfooterMangd {
  width: calc(13% - 2px);
  margin-left: 2px;
}

.xrfooterKoeff {
  width: calc(13% - 2px);
  margin-left: 2px;
}

.xrfooterIngredArbvikt {
  width: calc(13% - 2px);
  margin-left: 2px;
}

.xrfooterArborder {
  width: calc(13% - 2px);
  margin-left: 2px;
}

.xrprodsamDescriptionTop {
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrprodsamDescriptionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrprodsamDescriptionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrprodsamDescriptionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    margin-top: 5px;
    padding: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrprodsamDescriptionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    margin-top: 5px;
    padding: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xrprodsamDescriptionFieldset {
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    margin-top: 5px;
    padding: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrprodsamDescriptionLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrprodsamDescriptionLegend {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrprodsamDescriptionLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-left: 2.5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrprodsamDescriptionLegend {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-left: 3%;
  }
}
@media only screen and (min-width:2303px) {
  .xrprodsamDescriptionLegend {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-left: 3%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrprodsamDescriptionArea {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrprodsamDescriptionArea {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrprodsamDescriptionArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrprodsamDescriptionArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xrprodsamDescriptionArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrprodsamDescriptionTextArea, .xrprodsamDescriptionTextAreaChange {
    width: 93%;
    height: 10vh;
    max-height: 10vh;
    overflow-y: scroll;
    border: none;
    border-color: lightgrey;
    outline: none;
    background-color: var(--val);
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar {
    width: 10px;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-track, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-thumb, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrprodsamDescriptionTextArea, .xrprodsamDescriptionTextAreaChange {
    width: 93%;
    height: 25vh;
    max-height: 25vh;
    overflow-y: scroll;
    border: none;
    border-color: lightgrey;
    outline: none;
    background-color: var(--val);
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar {
    width: 10px;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-track, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-thumb, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrprodsamDescriptionTextArea, .xrprodsamDescriptionTextAreaChange {
    width: 93%;
    height: 30vh;
    max-height: 30vh;
    overflow-y: scroll;
    border: none;
    border-color: lightgrey;
    outline: none;
    background-color: var(--val);
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar {
    width: 10px;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-track, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-thumb, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrprodsamDescriptionTextArea, .xrprodsamDescriptionTextAreaChange {
    width: 93%;
    height: 30vh;
    max-height: 30vh;
    overflow-y: scroll;
    border: none;
    border-color: lightgrey;
    outline: none;
    background-color: var(--val);
    font-family: var(--font-family);
    font-size: 16px;
    resize: none;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar {
    width: 10px;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-track, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-thumb, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xrprodsamDescriptionTextArea, .xrprodsamDescriptionTextAreaChange {
    width: 93%;
    height: 30vh;
    max-height: 30vh;
    overflow-y: scroll;
    border: none;
    border-color: lightgrey;
    outline: none;
    background-color: var(--val);
    font-family: var(--font-family);
    font-size: 16px;
    resize: none;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar {
    width: 10px;
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-track, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrprodsamDescriptionTextArea::-webkit-scrollbar-thumb, .xrprodsamDescriptionTextAreaChange::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
    cursor: pointer;
  }
}

.xrprodsamDescriptionTextAreaChange {
  border: 1px solid;
}

.xrprodarbsumDescriptionArea {
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrproductMenuTop {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrproductMenuTop {
    display: flex;
    flex-direction: row;
    width: 95%;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrproductMenuTop {
    display: flex;
    flex-direction: row;
    width: 95%;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrproductMenuTop {
    display: flex;
    flex-direction: row;
    width: 95%;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xrproductMenuTop {
    display: flex;
    flex-direction: row;
    width: 95%;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrproductMenuFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrproductMenuFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 95%;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrproductMenuFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrproductMenuFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xrproductMenuFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-color: rgb(223, 212, 212);
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrproductMenuData {
    height: auto;
    max-height: 38vh;
    overflow-y: scroll;
    border: none;
    width: 100%;
  }
  .xrproductMenuData::-webkit-scrollbar {
    width: 1px;
  }
  .xrproductMenuData::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrproductMenuData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrproductMenuData {
    height: auto;
    max-height: 60vh;
    overflow-y: scroll;
    border: none;
    width: 95%;
  }
  .xrproductMenuData::-webkit-scrollbar {
    width: 1px;
  }
  .xrproductMenuData::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrproductMenuData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrproductMenuData {
    height: auto;
    max-height: 60vh;
    overflow-y: scroll;
    border: none;
    width: 95%;
  }
  .xrproductMenuData::-webkit-scrollbar {
    width: 1px;
  }
  .xrproductMenuData::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrproductMenuData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrproductMenuData {
    height: auto;
    max-height: 64vh;
    overflow-y: scroll;
    border: none;
    width: 95%;
  }
  .xrproductMenuData::-webkit-scrollbar {
    width: 1px;
  }
  .xrproductMenuData::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrproductMenuData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xrproductMenuData {
    height: auto;
    max-height: 70vh;
    overflow-y: scroll;
    border: none;
    width: 95%;
  }
  .xrproductMenuData::-webkit-scrollbar {
    width: 1px;
  }
  .xrproductMenuData::-webkit-scrollbar-track {
    background: rgb(245, 245, 237);
  }
  .xrproductMenuData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrproductMenuDataArea {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrproductMenuDataArea {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrproductMenuDataArea {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrproductMenuDataArea {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xrproductMenuDataArea {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrproductMenuLegend {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrproductMenuLegend {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-left: 3%;
    padding-top: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrproductMenuLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-left: 3%;
    padding-top: 7px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrproductMenuLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-left: 3%;
    padding-top: 9px;
  }
}
@media only screen and (min-width:2303px) {
  .xrproductMenuLegend {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    padding-top: 5px;
    margin-left: 3%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrmenuProduktid, .xrbenaemnData, .xrbenaemnData1, .xrproduktidData, .xrproduktidData1, .xrmenuAntal, .xrmenuBenaemn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 25%;
    height: 25px;
    margin-left: 3px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    margin-top: 2px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrmenuProduktid, .xrbenaemnData, .xrbenaemnData1, .xrproduktidData, .xrproduktidData1, .xrmenuAntal, .xrmenuBenaemn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 25%;
    height: 30px;
    margin-left: 3px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 2px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrmenuProduktid, .xrbenaemnData, .xrbenaemnData1, .xrproduktidData, .xrproduktidData1, .xrmenuAntal, .xrmenuBenaemn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 25%;
    height: 30px;
    margin-left: 3px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 2px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrmenuProduktid, .xrbenaemnData, .xrbenaemnData1, .xrproduktidData, .xrproduktidData1, .xrmenuAntal, .xrmenuBenaemn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 25%;
    height: 30px;
    margin-left: 3px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 2px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xrmenuProduktid, .xrbenaemnData, .xrbenaemnData1, .xrproduktidData, .xrproduktidData1, .xrmenuAntal, .xrmenuBenaemn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 25%;
    height: 30px;
    margin-left: 3px;
    border-radius: 6px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 2px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrmenuBenaemn {
    width: calc(50% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrmenuBenaemn {
    width: calc(50% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrmenuBenaemn {
    width: calc(50% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrmenuBenaemn {
    width: calc(50% - 2px);
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xrmenuBenaemn {
    width: calc(50% - 2px);
    margin-left: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrmenuAntal {
    width: calc(25% - 2px);
    margin-right: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrmenuAntal {
    width: calc(25% - 2px);
    margin-right: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrmenuAntal {
    width: calc(25% - 2px);
    margin-right: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrmenuAntal {
    width: calc(25% - 2px);
    margin-right: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .xrmenuAntal {
    width: calc(25% - 2px);
    margin-right: 1px;
  }
}

.xrproduktidData, .xrproduktidData1 {
  width: 25%;
  background-color: var(--rgbaVal2);
}

.xrproduktidData1 {
  background-color: var(--rgbaVal);
  width: 25%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrbenaemnData, .xrbenaemnData1 {
    width: calc(50% - 2px);
    background-color: var(--rgbaVal2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrbenaemnData, .xrbenaemnData1 {
    width: calc(50% - 2px);
    background-color: var(--rgbaVal2);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 30px;
    padding-top: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrbenaemnData, .xrbenaemnData1 {
    width: calc(50% - 2px);
    background-color: var(--rgbaVal2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrbenaemnData, .xrbenaemnData1 {
    width: calc(50% - 2px);
    background-color: var(--rgbaVal2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width:2303px) {
  .xrbenaemnData, .xrbenaemnData1 {
    width: calc(50% - 2px);
    background-color: var(--rgbaVal2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.xrbenaemnData1 {
  width: calc(50% - 2px);
  background-color: var(--rgbaVal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xrantalData, .xrantalData1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  width: calc(25% - 2px);
  height: 25px;
  border-radius: 6px;
  margin-top: 2px;
  margin-left: 3px;
  background-color: var(--rgbaVal2);
  font-family: var(--font-family);
  font-size: 12px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xrantalData, .xrantalData1 {
    width: calc(25% - 2px);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xrantalData, .xrantalData1 {
    width: calc(25% - 2px);
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xrantalData, .xrantalData1 {
    width: calc(25% - 2px);
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xrantalData, .xrantalData1 {
    width: calc(25% - 2px);
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xrantalData, .xrantalData1 {
    width: calc(25% - 2px);
    height: 30px;
  }
}

.xrantalData1 {
  background-color: var(--rgbaVal);
  width: calc(25% - 2px);
}

.xrarbsumNewSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xrArbsumNewSpinner {
  background-color: transparent;
}

.xrbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}/*# sourceMappingURL=ProdArbSumNew1.css.map */