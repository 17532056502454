@import '../style/MainStyles.scss';/* styles.css */

.resettotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}


.resetPassTotalArea{
    @include gadgets-to(mobile) {

        display: grid;
        // grid-template-rows: 3% 5% 26% 17% 30%;
         grid-template-rows:auto;
    
         grid-template-areas:
             "a";
         width: 100%;
         height:auto;
         object-fit: cover;
    }
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:100%;
        grid-template-rows: auto;
        grid-template-areas:
          "a";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

      
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:100%;
        grid-template-rows: auto;
        grid-template-areas:
          "a";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

      
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:100%;
        grid-template-rows: auto;
        grid-template-areas:
          "a";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

      
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:100%;
        grid-template-rows: auto;
        grid-template-areas:
          "a";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

      
    }


}
.resetPassAreaA{
    @include gadgets-to(mobile) {

    width:100%;
    grid-area: a;

}
    @include gadgets-to(tablet) {
        width:40%;
        grid-area: a;

    }
    @include gadgets-to(laptop) {
        width:30%;
        grid-area: a;

    }
    @include gadgets-to(desktop) {
        width:20%;
        grid-area: a;

    }
    @include gadgets-to(largeDesktop) {
        width:20%;
        grid-area: a;

    }
}
.resetPassHeaderWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
.resetPassHeader{
    font-family:var(--font-family);
    font-size:20px;

}
.resetPassFormWrapper{
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:20vh;
}

.resetPassFieldset{
    width:90%;
  
    flex-grow:1;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
border:1px solid;
border-color:map-get($generalStyles,datalistBorderColor);


}

.resetPassLegend{
    width:50%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family:var(--font-family);
    font-size:14px;

}
.resetPassInput{
    width:70%;
    height:25px;
    margin-top:5px;
    margin-bottom:5px;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius:5px;
    padding:5px;
    font-family:var(--font-family);
    font-size:14px;
    outline:none;

}
// on active
.resetPassInput:active{
    border:1px solid;
    border-color:map-get($generalStyles,colorGold);
}
// onfocus
.resetPassInput:focus{
    border:1px solid;
    border-color:map-get($generalStyles,colorGold);
}

.resetPassConfirm{
     @extend .resetPassInput;

}
.resetPassButton{
    width:70%;
    height:30px;
    margin-top:5px;
    margin-bottom:5px;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius:5px;
    padding:5px;
    font-family:var(--font-family);
    font-size:14px;
    background-color:map-get($generalStyles,buttonColor);
    color:map-get($generalStyles,buttonTextColor);
    cursor:pointer;
    transition:0.4s;
    // pseudo on hover here
    &:hover{
        background-color:map-get($generalStyles,pinkRed);
        border:1px solid;
        color:white;
    }



}