@import '../style/MainStyles.scss';/* styles.css */


.screenCover{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999; // T
    background-color: white;
}

.screenCover__content{
display:flex;
flex-direction:column;
justify-content:center;
border:1px solid transparent;
border-radius: 6px;
margin:5px;
padding-left:9px;
}

.screenCover__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top:20vh;
    font-family: map-get($generalStyles,fontFamilyLobster);
    font-size:40px;
}