@import '../style/variabels.scss';
@import url('https://fonts.googleapis.com/css2?family=Zen+Old+Mincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');


.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 4;
  }
  
  .alert-content {
  display:flex;
  flex-direction: column;
  align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    

  }

  .alert-button{
    display:inline-block;
    cursor:pointer;
    text-decoration: none;
    font-size:18px;
    line-height: 2;
    color:#333;
    width:200px;
  border-radius: 6px;
  border-width: 1px;
  border-color: grey;
  font-family: $primary-font-family;
  background-color:$chocolateBorder;
  z-index:4;
  }
  .alertYes-button{
  //  display:inline-block;
    cursor:pointer;
    text-decoration: none;
    font-size:18px;
   // line-height: 2;
    color:#333;
    width:200px;
    height:30px;
    margin-bottom: 5px;
    padding-top: 2px;
  border-radius: 6px;
  border:none;
  //border-color:grey;
  font-family: 'Zen Old Mincho', serif;
  font-size: 15px;
  background-color:#06C755;
  z-index:4;
  }
  .alertNo-button{
      //  display:inline-block;
      cursor:pointer;
      text-decoration: none;
      font-size:18px;
     // line-height: 2;
      color:#333;
      width:200px;
      height:30px;
      margin-bottom: 5px;
      padding-top:2px;
    border-radius: 6px;
    border:none;
    //border-color:grey;
    font-family: 'Zen Old Mincho', serif;
    font-size: 15px;
    background-color:#06C755;
    z-index:4;

  }
  .alert-message{
     display:flex;
     justify-content: center;
     width:350px;
    
  
    margin-bottom: 10px;
   
    font-family: 'Zen Old Mincho', serif;
    font-size:16px;
  }
  .bakesoftlogo{
    font-family:'Lobster', cursive;
    font-size: 20px;
  }
  
  .bakesoftuserdatapolicy{
    font-family:'Lobster', cursive;
    font-size: 14px;
    text-decoration: none;

  }