:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xktotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktotalArea, .xktotalArea2, .xktotalArea1 {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas: "a" "b" "c" "d" "e";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktotalArea, .xktotalArea2, .xktotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .xktotalArea .area-f, .xktotalArea2 .area-f, .xktotalArea1 .area-f {
    grid-area: d;
  }
  .xktotalArea .area-f .tabs, .xktotalArea2 .area-f .tabs, .xktotalArea1 .area-f .tabs {
    display: flex;
    justify-content: space-between;
  }
  .xktotalArea .area-f .tab-content, .xktotalArea2 .area-f .tab-content, .xktotalArea1 .area-f .tab-content {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotalArea, .xktotalArea2, .xktotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotalArea, .xktotalArea2, .xktotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 95%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xktotalArea, .xktotalArea2, .xktotalArea1 {
    display: grid;
    grid-template-columns: 30% 47% 22%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 99.8%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.xktotalArea1 {
  background-color: rgb(245, 245, 237);
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktotalArea2 {
    grid-template-columns: 0% 50% 47%;
    grid-template-areas: "a a a" "b b b" "c d e";
  }
}
.xktotalArea2 .c {
  display: none;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotalArea2 {
    grid-template-columns: 0% 50% 50%;
    grid-template-areas: "a a a" "b b b" "c d e";
  }
}
.xktotalArea2 .c {
  display: none;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotalArea2 {
    grid-template-columns: 0% 50% 50%;
    grid-template-areas: "a a a" "b b b" "c d e";
  }
}
.xktotalArea2 .c {
  display: none;
}
@media only screen and (min-width:2303px) {
  .xktotalArea2 {
    grid-template-columns: 0% 50% 50%;
    grid-template-areas: "a a a" "b b b" "c d e";
  }
}
.xktotalArea2 .c {
  display: none;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xkheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xkcompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xkcompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xkrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkrangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xkrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 190px;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 75vh;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    height: 83vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    height: 76.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    height: 84vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xkareaA, .xkareaC, .xkareaC1, .xkareaB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkareaB {
    grid-area: d;
    height: 53vh;
    margin-top: 3px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (max-height: 690px) {
  .xkareaB {
    margin-top: 1vh;
    height: 47.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) and (min-height: 730px) {
  .xkareaB {
    height: 57.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 370px) and (max-width: 380px) {
  .xkareaB {
    height: 52vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 381px) and (max-width: 385px) {
  .xkareaB {
    height: 60.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .xkareaB {
    height: 53vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 395px) and (max-width: 411px) {
  .xkareaB {
    height: 45vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 413px) and (max-width: 414px) and (max-height: 750px) {
  .xkareaB {
    height: 56vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 413px) {
  .xkareaB {
    height: 55vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 413px) and (min-height: 650px) and (max-height: 751px) {
  .xkareaB {
    height: 56vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 413px) and (min-height: 752px) and (max-height: 990px) {
  .xkareaB {
    height: 54vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) {
  .xkareaB {
    height: 61vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) and (max-height: 926px) {
  .xkareaB {
    height: 58vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 480px) {
  .xkareaB {
    height: 58.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkareaB {
    grid-area: d;
    height: 75vh;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xkareaB {
    height: 83vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xkareaB {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkareaB {
    height: 76.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkareaB {
    height: 84vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkareaB {
    grid-area: d;
    height: 100%;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkareaB {
    grid-area: d;
    height: 100%;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xkareaB {
    grid-area: d;
    height: 100%;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkareaC, .xkareaC1 {
    grid-area: e;
    height: 75vh;
    background-color: white;
    margin-top: 1.5vh;
    height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkareaC, .xkareaC1 {
    grid-area: e;
    height: 75vh;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xkareaC, .xkareaC1 {
    height: 83vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xkareaC, .xkareaC1 {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkareaC, .xkareaC1 {
    height: 76.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkareaC, .xkareaC1 {
    height: 84vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkareaC, .xkareaC1 {
    grid-area: e;
    height: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkareaC, .xkareaC1 {
    grid-area: e;
    height: 100%;
    background-color: var(--val);
  }
}
@media only screen and (min-width:2303px) {
  .xkareaC, .xkareaC1 {
    grid-area: e;
    height: 100%;
    background-color: var(--val);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkareaC1 {
    grid-area: e;
    height: 20vh;
    margin-left: 11px;
    background: white;
    margin-top: 50px;
    z-index: 10;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkareaC1 {
    grid-area: e;
    background: white;
    z-index: 10;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xkareaC1 {
    height: 83vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xkareaC1 {
    height: 84vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkareaC1 {
    height: 76.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkareaC1 {
    height: 84vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkareaC1 {
    grid-area: e;
    height: 100%;
    z-index: 10;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkareaC1 {
    grid-area: e;
    height: 100%;
    z-index: 10;
  }
}
@media only screen and (min-width:2303px) {
  .xkareaC1 {
    grid-area: e;
    height: 100%;
    z-index: 10;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkareaATop {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkareaATop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkareaATop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkareaATop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xkareaATop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.xkareaBTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkcheckboxWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkcheckboxWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkcheckboxWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xkcheckboxWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkcheckpseudo {
    margin-top: 1vh;
    margin-right: 10%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkcheckpseudo {
    margin-top: 1vh;
    margin-right: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkcheckpseudo {
    margin-top: 1vh;
    margin-right: 10%;
  }
}
@media only screen and (min-width:2303px) {
  .xkcheckpseudo {
    margin-top: 1vh;
    margin-right: 10%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkcheckpseudoLabel {
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkcheckpseudoLabel {
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkcheckpseudoLabel {
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .xkcheckpseudoLabel {
    font-family: var(--font-family);
    font-size: 12px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrange1, .xkrange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrange1, .xkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrange1, .xkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrange1, .xkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xkrange1, .xkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkSelect {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkSelect {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkSelect {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkSelect {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xkSelect {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkSelect1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkSelect1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkSelect1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkSelect1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xkSelect1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkSelectIngredFieldset {
    width: 100%;
    height: 30px;
    border: none;
    border-color: white;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkSelectIngredFieldset {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkSelectIngredFieldset {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkSelectIngredFieldset {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xkSelectIngredFieldset {
    width: 100%;
    height: 30px;
    border: none;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkSelectIngredLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 11%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkSelectIngredLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 11%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkSelectIngredLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 11%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkSelectIngredLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 11%;
  }
}
@media only screen and (min-width:2303px) {
  .xkSelectIngredLegend {
    color: black;
    font-size: 12px;
    font-family: var(--font-family);
    margin-left: 11%;
  }
}

.xkrecepySelectTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.xkSelectRecepyFieldset {
  width: 100%;
  height: 30px;
  border: none;
  border-color: white;
  margin-top: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.xkselectRecepyTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.xkSelectRecepyLegend {
  color: black;
  font-size: 12px;
  font-family: var(--font-family);
  margin-left: 11%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkSelectRecepySelect {
    width: 80%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkSelectRecepySelect {
    width: 80%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkSelectRecepySelect {
    width: 80%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkSelectRecepySelect {
    width: 80%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xkSelectRecepySelect {
    width: 80%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border-radius: 6px;
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkingredButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
    width: 92%;
    max-height: 68px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .xkingredButtonContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkingredButtonContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkingredButtonContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkingredButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
    width: 92%;
    max-height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 1vh;
  }
  .xkingredButtonContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkingredButtonContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkingredButtonContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xkingredButtonContainer {
    max-height: 53vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkingredButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
    width: 92%;
    height: auto;
    min-height: 1px;
    max-height: 57vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1vh;
  }
  .xkingredButtonContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkingredButtonContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkingredButtonContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkingredButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
    width: 92%;
    max-height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 1vh;
  }
  .xkingredButtonContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkingredButtonContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkingredButtonContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xkingredButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
    width: 92%;
    max-height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 1vh;
  }
  .xkingredButtonContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkingredButtonContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkingredButtonContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

.xkshowIngredButton, .xkshowIngredButton1 {
  position: relative;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkshowIngredButton, .xkshowIngredButton1 {
    width: auto;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 20px;
    border-radius: 20px;
    margin-top: 1px;
    cursor: pointer;
    margin-left: 3px;
    margin-bottom: 3px;
    white-space: nowrap;
  }
  .xkshowIngredButton::after, .xkshowIngredButton1::after {
    content: attr(data-price);
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    line-height: 20px;
    background-color: var(--rgbaVal2);
    color: black;
    padding: 1px;
  }
  .xkshowIngredButton::after, .xkshowIngredButton1::after {
    background-color: white;
  }
  .xkshowIngredButton[data-lowest="1"]::after, [data-lowest="1"].xkshowIngredButton1::after {
    background-color: green;
  }
  .xkshowIngredButton[data-highest="1"]::after, [data-highest="1"].xkshowIngredButton1::after {
    background-color: red;
  }
  .xkshowIngredButton[data-mid="1"]::after, [data-mid="1"].xkshowIngredButton1::after {
    background-color: pink;
  }
  .xkshowIngredButton[data-mid1="1"]::after, [data-mid1="1"].xkshowIngredButton1::after {
    background-color: yellow;
  }
  .xkshowIngredButton.hide-pseudo::after, .hide-pseudo.xkshowIngredButton1::after {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkshowIngredButton, .xkshowIngredButton1 {
    width: auto;
    height: 40px;
    padding: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    border-radius: 20px;
    font-size: 20px;
    margin-left: 2px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .xkshowIngredButton::after, .xkshowIngredButton1::after {
    content: attr(data-price);
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 20px;
    min-height: 20px;
    width: auto;
    height: auto;
    border: 1px solid white;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    line-height: 20px;
    color: black;
    padding: 1px;
    cursor: pointer;
  }
  .xkshowIngredButton::after, .xkshowIngredButton1::after {
    background-color: white;
  }
  .xkshowIngredButton[data-lowest="1"]::after, [data-lowest="1"].xkshowIngredButton1::after {
    background-color: green;
  }
  .xkshowIngredButton[data-highest="1"]::after, [data-highest="1"].xkshowIngredButton1::after {
    background-color: red;
  }
  .xkshowIngredButton[data-mid="1"]::after, [data-mid="1"].xkshowIngredButton1::after {
    background-color: pink;
  }
  .xkshowIngredButton[data-mid1="1"]::after, [data-mid1="1"].xkshowIngredButton1::after {
    background-color: yellow;
  }
  .xkshowIngredButton.hide-pseudo::after, .hide-pseudo.xkshowIngredButton1::after {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkshowIngredButton, .xkshowIngredButton1 {
    width: auto;
    height: 40px;
    padding: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    border-radius: 20px;
    font-size: 20px;
    margin-left: 2px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .xkshowIngredButton::after, .xkshowIngredButton1::after {
    content: attr(data-price);
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 20px;
    min-height: 20px;
    width: auto;
    height: auto;
    border: 1px solid white;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    line-height: 20px;
    color: black;
    padding: 1px;
    cursor: pointer;
  }
}
.xkshowIngredButton::after, .xkshowIngredButton1::after {
  background-color: white;
}
.xkshowIngredButton[data-lowest="1"]::after, [data-lowest="1"].xkshowIngredButton1::after {
  background-color: green;
}
.xkshowIngredButton[data-highest="1"]::after, [data-highest="1"].xkshowIngredButton1::after {
  background-color: red;
}
.xkshowIngredButton[data-mid="1"]::after, [data-mid="1"].xkshowIngredButton1::after {
  background-color: pink;
}
.xkshowIngredButton[data-mid1="1"]::after, [data-mid1="1"].xkshowIngredButton1::after {
  background-color: yellow;
}
.xkshowIngredButton.hide-pseudo::after, .hide-pseudo.xkshowIngredButton1::after {
  display: none;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkshowIngredButton, .xkshowIngredButton1 {
    width: auto;
    height: 40px;
    padding: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    border-radius: 20px;
    font-size: 20px;
    margin-left: 2px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .xkshowIngredButton::after, .xkshowIngredButton1::after {
    content: attr(data-price);
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 20px;
    min-height: 20px;
    width: auto;
    height: auto;
    border: 1px solid white;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    line-height: 20px;
    color: black;
    padding: 1px;
    cursor: pointer;
  }
}
.xkshowIngredButton::after, .xkshowIngredButton1::after {
  background-color: white;
}
.xkshowIngredButton[data-lowest="1"]::after, [data-lowest="1"].xkshowIngredButton1::after {
  background-color: green;
}
.xkshowIngredButton[data-highest="1"]::after, [data-highest="1"].xkshowIngredButton1::after {
  background-color: red;
}
.xkshowIngredButton[data-mid="1"]::after, [data-mid="1"].xkshowIngredButton1::after {
  background-color: pink;
}
.xkshowIngredButton[data-mid1="1"]::after, [data-mid1="1"].xkshowIngredButton1::after {
  background-color: yellow;
}
.xkshowIngredButton.hide-pseudo::after, .hide-pseudo.xkshowIngredButton1::after {
  display: none;
}
@media only screen and (min-width:2303px) {
  .xkshowIngredButton, .xkshowIngredButton1 {
    width: auto;
    height: 60px;
    padding: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    border-radius: 20px;
    font-size: 24px;
    margin-left: 2px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .xkshowIngredButton::after, .xkshowIngredButton1::after {
    content: attr(data-price);
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 20px;
    min-height: 20px;
    width: auto;
    height: auto;
    border: 1px solid white;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    line-height: 20px;
    color: black;
    padding: 1px;
    cursor: pointer;
  }
}
.xkshowIngredButton::after, .xkshowIngredButton1::after {
  background-color: white;
}
.xkshowIngredButton[data-lowest="1"]::after, [data-lowest="1"].xkshowIngredButton1::after {
  background-color: green;
}
.xkshowIngredButton[data-highest="1"]::after, [data-highest="1"].xkshowIngredButton1::after {
  background-color: red;
}
.xkshowIngredButton[data-mid="1"]::after, [data-mid="1"].xkshowIngredButton1::after {
  background-color: pink;
}
.xkshowIngredButton[data-mid1="1"]::after, [data-mid1="1"].xkshowIngredButton1::after {
  background-color: yellow;
}
.xkshowIngredButton.hide-pseudo::after, .hide-pseudo.xkshowIngredButton1::after {
  display: none;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkshowIngredButton1 {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkshowIngredButton1 {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkshowIngredButton1 {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkshowIngredButton1 {
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width:2303px) {
  .xkshowIngredButton1 {
    background-color: var(--rgbaVal2);
  }
}

.xkrecepyHeaderTop {
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrecepyHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrecepyHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrecepyHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrecepyHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 10%;
  }
}
@media only screen and (min-width:2303px) {
  .xkrecepyHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 2%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrecepyHeader, .xkrecepyHeaderJAP, .xkrecepyHeaderMANJAP {
    width: 98%;
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrecepyHeader, .xkrecepyHeaderJAP, .xkrecepyHeaderMANJAP {
    width: 98%;
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 24px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrecepyHeader, .xkrecepyHeaderJAP, .xkrecepyHeaderMANJAP {
    width: 98%;
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 24px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrecepyHeader, .xkrecepyHeaderJAP, .xkrecepyHeaderMANJAP {
    width: 98%;
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 28px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
  }
}
@media only screen and (min-width:2303px) {
  .xkrecepyHeader, .xkrecepyHeaderJAP, .xkrecepyHeaderMANJAP {
    width: 98%;
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 30px;
    font-style: italic;
    font-weight: bold;
    margin-top: 5px;
    background-color: transparent;
    margin-left: 4%;
  }
}

.xkrecepyHeaderMANJAP {
  font-size: 16px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrecepyHeaderMANJAP {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrecepyHeaderMANJAP {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrecepyHeaderMANJAP {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrecepyHeaderMANJAP {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .xkrecepyHeaderMANJAP {
    font-size: 24px;
  }
}

.xkrecepyHeaderJAP {
  font-size: 12px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrecepyHeaderJAP {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrecepyHeaderJAP {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrecepyHeaderJAP {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrecepyHeaderJAP {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .xkrecepyHeaderJAP {
    font-size: 18px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkheaderButtonIngredid, .xkHeaderButtonUpdate, .xkheaderDelete, .xkheaderOrder, .xkheaderKcals, .xkheaderButton, .xkheaderButtonAmount, .xkheaderButtonBenaemn {
    width: 30px;
    height: 20px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 10px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkheaderButtonIngredid, .xkHeaderButtonUpdate, .xkheaderDelete, .xkheaderOrder, .xkheaderKcals, .xkheaderButton, .xkheaderButtonAmount, .xkheaderButtonBenaemn {
    width: 30px;
    height: 20px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 10px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkheaderButtonIngredid, .xkHeaderButtonUpdate, .xkheaderDelete, .xkheaderOrder, .xkheaderKcals, .xkheaderButton, .xkheaderButtonAmount, .xkheaderButtonBenaemn {
    width: 30px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkheaderButtonIngredid, .xkHeaderButtonUpdate, .xkheaderDelete, .xkheaderOrder, .xkheaderKcals, .xkheaderButton, .xkheaderButtonAmount, .xkheaderButtonBenaemn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 30px;
    height: 30px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xkheaderButtonIngredid, .xkHeaderButtonUpdate, .xkheaderDelete, .xkheaderOrder, .xkheaderKcals, .xkheaderButton, .xkheaderButtonAmount, .xkheaderButtonBenaemn {
    width: 30px;
    height: 40px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
  }
}

.xkheaderButtonBenaemn {
  width: 40%;
  text-align: left;
  cursor: pointer;
}

.xkheaderButtonAmount {
  width: 15%;
}

.xkheaderButton {
  width: 15%;
}

.xkheaderKcals {
  width: 10%;
  cursor: pointer;
}

.xkheaderOrder {
  width: 10%;
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkheaderDelete {
    width: 7%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkheaderDelete {
    width: 5.5%;
    background-color: transparent;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkheaderDelete {
    width: 5.5%;
    background-color: transparent;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkheaderDelete {
    width: 5.5%;
    background-color: transparent;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xkheaderDelete {
    width: 5.5%;
    cursor: pointer;
  }
}

.xkHeaderButtonUpdate {
  width: 190px;
  height: 30px;
  background-color: rgb(236, 228, 228);
  border-radius: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkHeaderTop {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkHeaderTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkHeaderTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkHeaderTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xkHeaderTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkheader {
    grid-area: b;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkheader {
    grid-area: b;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkheader {
    grid-area: b;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkheader {
    grid-area: b;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-left: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xkheader {
    grid-area: b;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkreceivedContainer {
    height: 32vh;
    max-height: 32vh;
    overflow-y: scroll;
    width: 100%;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xkreceivedContainer::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xkreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkreceivedContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 359px) and (max-width: 370px) {
  .xkreceivedContainer {
    height: 20vh;
    max-height: 20vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 370px) and (max-width: 380px) {
  .xkreceivedContainer {
    height: 27vh;
    max-height: 27vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 381px) and (max-width: 385px) {
  .xkreceivedContainer {
    height: 34vh;
    max-height: 34vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .xkreceivedContainer {
    height: 28.5vh;
    max-height: 28.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 414px) and (max-height: 750px) {
  .xkreceivedContainer {
    height: 35vh;
    max-height: 35vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 413px) and (max-width: 414px) and (max-height: 950px) {
  .xkreceivedContainer {
    height: 32vh;
    max-height: 32vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 413px) and (max-width: 414px) and (min-height: 750px) and (max-height: 900px) {
  .xkreceivedContainer {
    height: 35vh;
    max-height: 35vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 413px) and (min-height: 650px) and (max-height: 751px) {
  .xkreceivedContainer {
    height: 30vh;
    max-height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 413px) and (min-height: 752px) and (max-height: 900px) {
  .xkreceivedContainer {
    height: 31vh;
    max-height: 31vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) and (max-width: 413px) and (min-height: 901px) and (max-height: 990px) {
  .xkreceivedContainer {
    height: 31vh;
    max-height: 31vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) and (min-height: 820px) {
  .xkreceivedContainer {
    height: 32vh;
    max-height: 32vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) {
  .xkreceivedContainer {
    height: 34vh;
    max-height: 34vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 480px) {
  .xkreceivedContainer {
    height: 35vh;
    max-height: 35vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkreceivedContainer {
    height: 53vh;
    max-height: 53vh;
    overflow-y: scroll;
    width: 100%;
    font-family: var(--font-family);
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xkreceivedContainer::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xkreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkreceivedContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xkreceivedContainer {
    height: 62vh;
    max-height: 62vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xkreceivedContainer {
    height: 64vh;
    max-height: 64vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkreceivedContainer {
    height: 51vh;
    max-height: 51vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkreceivedContainer {
    height: 64vh;
    max-height: 64vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkreceivedContainer {
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    width: 95%;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xkreceivedContainer::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xkreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkreceivedContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkreceivedContainer {
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    width: 95%;
    font-family: var(--font-family);
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xkreceivedContainer::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xkreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkreceivedContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xkreceivedContainer {
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    width: 95%;
    font-family: var(--font-family);
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xkreceivedContainer::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }
  .xkreceivedContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkreceivedContainer::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkdatarows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkdatarows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkdatarows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkdatarows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xkdatarows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobuttonIngredid, .xkdelete1, .xkdelete, .xkarborderButton, .xkarborderButton2, .xkkcalsButton, .xkkcalsButton2, .xkinfobutton, .xkinfobutton2, .xkinfobuttonAmount, .xkinfobuttonAmount2, .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2, .xkinfobuttonIngredid2 {
    width: 30px;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobuttonIngredid, .xkdelete1, .xkdelete, .xkarborderButton, .xkarborderButton2, .xkkcalsButton, .xkkcalsButton2, .xkinfobutton, .xkinfobutton2, .xkinfobuttonAmount, .xkinfobuttonAmount2, .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2, .xkinfobuttonIngredid2 {
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobuttonIngredid, .xkdelete1, .xkdelete, .xkarborderButton, .xkarborderButton2, .xkkcalsButton, .xkkcalsButton2, .xkinfobutton, .xkinfobutton2, .xkinfobuttonAmount, .xkinfobuttonAmount2, .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2, .xkinfobuttonIngredid2 {
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobuttonIngredid, .xkdelete1, .xkdelete, .xkarborderButton, .xkarborderButton2, .xkkcalsButton, .xkkcalsButton2, .xkinfobutton, .xkinfobutton2, .xkinfobuttonAmount, .xkinfobuttonAmount2, .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2, .xkinfobuttonIngredid2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 30px;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
    padding-left: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobuttonIngredid, .xkdelete1, .xkdelete, .xkarborderButton, .xkarborderButton2, .xkkcalsButton, .xkkcalsButton2, .xkinfobutton, .xkinfobutton2, .xkinfobuttonAmount, .xkinfobuttonAmount2, .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2, .xkinfobuttonIngredid2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 30px;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal2);
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    border-radius: 6px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 1px;
    padding-left: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobuttonIngredid2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobuttonIngredid2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobuttonIngredid2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobuttonIngredid2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobuttonIngredid2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2 {
    width: 40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2 {
    width: 40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2 {
    width: 40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2 {
    width: 40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobuttonBenaemn, .xkinfobuttonBenaemn2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 2%;
    width: 40%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobuttonBenaemn2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobuttonAmount, .xkinfobuttonAmount2 {
    width: 15%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobuttonAmount, .xkinfobuttonAmount2 {
    height: 30px;
    width: 15%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobuttonAmount, .xkinfobuttonAmount2 {
    height: 30px;
    width: 15%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobuttonAmount, .xkinfobuttonAmount2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    width: 15%;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobuttonAmount, .xkinfobuttonAmount2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    width: 15%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobuttonAmount2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobuttonAmount2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobuttonAmount2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobuttonAmount2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobuttonAmount2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobutton, .xkinfobutton2 {
    width: 15%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobutton, .xkinfobutton2 {
    height: 30px;
    width: 15%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobutton, .xkinfobutton2 {
    height: 30px;
    width: 15%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobutton, .xkinfobutton2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    width: 15%;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobutton, .xkinfobutton2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    width: 15%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkinfobutton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkinfobutton2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkinfobutton2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkinfobutton2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .xkinfobutton2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkkcalsButton, .xkkcalsButton2 {
    width: 10%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkkcalsButton, .xkkcalsButton2 {
    height: 30px;
    width: 10%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkkcalsButton, .xkkcalsButton2 {
    height: 30px;
    width: 10%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkkcalsButton, .xkkcalsButton2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    width: 10%;
  }
}
@media only screen and (min-width:2303px) {
  .xkkcalsButton, .xkkcalsButton2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    width: 10%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkkcalsButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkkcalsButton2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkkcalsButton2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkkcalsButton2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .xkkcalsButton2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkarborderButton, .xkarborderButton2 {
    width: 10%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkarborderButton, .xkarborderButton2 {
    width: 10%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkarborderButton, .xkarborderButton2 {
    width: 10%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkarborderButton, .xkarborderButton2 {
    width: 10%;
    cursor: pointer;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}
@media only screen and (min-width:2303px) {
  .xkarborderButton, .xkarborderButton2 {
    width: 10%;
    cursor: pointer;
    wheight: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkarborderButton2 {
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkarborderButton2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkarborderButton2 {
    background-color: var(--rgbaVal);
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkarborderButton2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .xkarborderButton2 {
    background-color: var(--rgbaVal);
    height: 35px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkdelete {
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkdelete {
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkdelete {
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkdelete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width:2303px) {
  .xkdelete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal2);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkdelete1 {
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkdelete1 {
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkdelete1 {
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkdelete1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xkdelete1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 6%;
    padding: 1px;
    background-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkdeletebackline {
    font-size: 14px;
    cursor: pointer;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkdeletebackline {
    font-size: 14px;
    cursor: pointer;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkdeletebackline {
    font-size: 14px;
    cursor: pointer;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkdeletebackline {
    font-size: 18px;
    cursor: pointer;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xkdeletebackline {
    font-size: 18px;
    cursor: pointer;
    color: black;
  }
}

.ingredcommentTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.ingredcommentWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredcommentWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredcommentWrapper {
    display: flex;
    flex-direction: row;
    width: 97%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredcommentWrapper {
    display: flex;
    flex-direction: row;
    width: 97%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredcommentWrapper {
    display: flex;
    flex-direction: row;
    width: 97%;
  }
}
@media only screen and (min-width:2303px) {
  .ingredcommentWrapper {
    display: flex;
    flex-direction: row;
    width: 97%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktotalCostContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktotalCostContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotalCostContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotalCostContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xktotalCostContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktotalcostBenaemn, .xkavgcaloriesdelete, .xkavgcaloriesButton, .xktotalcaloriesButton, .xktotalcostButton, .xktotalamountButton {
    width: 40%;
    height: 20px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktotalcostBenaemn, .xkavgcaloriesdelete, .xkavgcaloriesButton, .xktotalcaloriesButton, .xktotalcostButton, .xktotalamountButton {
    width: 40%;
    height: 20px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotalcostBenaemn, .xkavgcaloriesdelete, .xkavgcaloriesButton, .xktotalcaloriesButton, .xktotalcostButton, .xktotalamountButton {
    width: 40%;
    height: 30px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotalcostBenaemn, .xkavgcaloriesdelete, .xkavgcaloriesButton, .xktotalcaloriesButton, .xktotalcostButton, .xktotalamountButton {
    width: 40%;
    height: 30px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xktotalcostBenaemn, .xkavgcaloriesdelete, .xkavgcaloriesButton, .xktotalcaloriesButton, .xktotalcostButton, .xktotalamountButton {
    width: 40%;
    height: 40px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-style: italic;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktotalamountButton {
    width: 15%;
    padding: 1px;
    text-align: center;
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktotalamountButton {
    width: 15%;
    padding: 1px;
    text-align: center;
    font-size: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotalamountButton {
    width: 15%;
    padding: 1px;
    text-align: center;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotalamountButton {
    text-align: center;
    width: 15%;
    padding: 1px;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xktotalamountButton {
    width: 15%;
    padding: 1px;
    font-size: 20px;
    text-align: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktotalcostButton {
    width: 15%;
    text-align: center;
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktotalcostButton {
    width: 15%;
    text-align: center;
    font-size: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotalcostButton {
    width: 15%;
    text-align: center;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotalcostButton {
    width: 15%;
    text-align: center;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xktotalcostButton {
    width: 15%;
    text-align: center;
    font-size: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktotalcaloriesButton {
    width: 10%;
    text-align: center;
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktotalcaloriesButton {
    width: 10%;
    text-align: center;
    font-size: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktotalcaloriesButton {
    width: 10%;
    text-align: center;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktotalcaloriesButton {
    width: 10%;
    text-align: center;
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xktotalcaloriesButton {
    width: 10%;
    text-align: center;
    font-size: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkavgcaloriesButton {
    width: 10%;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkavgcaloriesButton {
    width: 10%;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkavgcaloriesButton {
    width: 10%;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkavgcaloriesButton {
    width: 10%;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
  }
}
@media only screen and (min-width:2303px) {
  .xkavgcaloriesButton {
    width: 10%;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkavgcaloriesdelete {
    width: 6%;
    padding: 1px;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkavgcaloriesdelete {
    width: 6%;
    padding: 1px;
    text-align: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkavgcaloriesdelete {
    width: 6%;
    padding: 1px;
    text-align: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkavgcaloriesdelete {
    width: 6%;
    padding: 1px;
    text-align: center;
  }
}
@media only screen and (min-width:2303px) {
  .xkavgcaloriesdelete {
    width: 6%;
    padding: 1px;
    text-align: center;
  }
}

.xkselectnewingredientContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 187px;
    height: 25px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 1px;
    background-color: white;
    color: black;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 187px;
    height: 25px;
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 1px;
    background-color: white;
    color: black;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 40%;
    height: 30px;
    outline: none;
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 1px;
    background-color: white;
    color: black;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 40%;
    height: 35px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 1px;
    background-color: white;
    color: black;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xkselectdbOwnerIngredient {
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 40%;
    height: 35px;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 1px;
    background-color: white;
    color: black;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xksaveRecepyButton, .xksaveRecepyButtonActive {
    width: 100px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xksaveRecepyButton, .xksaveRecepyButtonActive {
    width: 100px;
    height: 25px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xksaveRecepyButton, .xksaveRecepyButtonActive {
    width: 130px;
    height: 30px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xksaveRecepyButton, .xksaveRecepyButtonActive {
    width: 150px;
    height: 35px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
  }
}
@media only screen and (min-width:2303px) {
  .xksaveRecepyButton, .xksaveRecepyButtonActive {
    width: 150px;
    height: 35px;
    border: none;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 6px;
    margin-left: 3px;
    cursor: pointer;
    opacity: 0.8;
  }
}

.xksaveRecepyButtonActive {
  background-color: rgb(235, 18, 18);
  color: white;
  opacity: 1;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xknutrientSummaryContainer {
    width: 98%;
    height: 11vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: var(--rgbaVal);
    border-radius: 6px;
    margin: 1%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 360px) {
  .xknutrientSummaryContainer {
    height: 12vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xknutrientSummaryContainer {
    width: 98%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xknutrientSummaryContainer {
    width: 98%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xknutrientSummaryContainer {
    width: 98%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 1%;
  }
}
@media only screen and (min-width:2303px) {
  .xknutrientSummaryContainer {
    width: 98%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 1%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkviewNutrientData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 95%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 95%;
  }
}
@media only screen and (min-width:2303px) {
  .xkviewNutrientData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 95%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xknutrientSummaryBFats,
  .xknutrientSummaryButtonProtein,
  .xknutrientSummaryButtonKcals,
  .xknutrientSummaryButtonCarb,
  .xknutrientSummaryButtonFats {
    width: 25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xknutrientSummaryBFats,
  .xknutrientSummaryButtonProtein,
  .xknutrientSummaryButtonKcals,
  .xknutrientSummaryButtonCarb,
  .xknutrientSummaryButtonFats {
    width: 25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xknutrientSummaryBFats,
  .xknutrientSummaryButtonProtein,
  .xknutrientSummaryButtonKcals,
  .xknutrientSummaryButtonCarb,
  .xknutrientSummaryButtonFats {
    width: 25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: black;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: italic;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xknutrientSummaryBFats,
  .xknutrientSummaryButtonProtein,
  .xknutrientSummaryButtonKcals,
  .xknutrientSummaryButtonCarb,
  .xknutrientSummaryButtonFats {
    width: 25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    font-style: italic;
    margin-top: 2px;
    text-align: left;
  }
}
@media only screen and (min-width:2303px) {
  .xknutrientSummaryBFats,
  .xknutrientSummaryButtonProtein,
  .xknutrientSummaryButtonKcals,
  .xknutrientSummaryButtonCarb,
  .xknutrientSummaryButtonFats {
    width: 25%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: black;
    font-family: var(--font-family);
    font-size: 18px;
    font-style: italic;
    margin-top: 2px;
    text-align: left;
  }
}

.xkNutrientSummaryButtonCarb {
  width: 80px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkmeatIcon,
  .xkcheeseIcon,
  .xkwheatIcon,
  .xkfireIcon {
    width: 25%;
    height: 25px;
    font-size: 22px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkmeatIcon,
  .xkcheeseIcon,
  .xkwheatIcon,
  .xkfireIcon {
    width: 25%;
    height: 25px;
    font-size: 22px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkmeatIcon,
  .xkcheeseIcon,
  .xkwheatIcon,
  .xkfireIcon {
    width: 25%;
    height: 30px;
    font-size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkmeatIcon,
  .xkcheeseIcon,
  .xkwheatIcon,
  .xkfireIcon {
    width: 25%;
    height: 30px;
    font-size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xkmeatIcon,
  .xkcheeseIcon,
  .xkwheatIcon,
  .xkfireIcon {
    width: 25%;
    height: 25px;
    font-size: 30px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkFireIcon {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkFireIcon {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkFireIcon {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkFireIcon {
    margin-left: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .xkFireIcon {
    margin-left: 12px;
  }
}

.xksearchSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xksearchSpinner {
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrecepyDescriptionHeader {
    grid-area: c;
    font-family: "Lobster";
    margin-top: 3px;
    margin-left: 4px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrecepyDescriptionHeader {
    grid-area: c;
    font-family: "Lobster";
    margin-top: 3px;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrecepyDescriptionHeader {
    grid-area: c;
    font-family: "Lobster";
    margin-top: 3px;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrecepyDescriptionHeader {
    grid-area: c;
    font-family: "Lobster";
    margin-top: 3px;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width:2303px) {
  .xkrecepyDescriptionHeader {
    grid-area: c;
    font-family: "Lobster";
    margin-top: 3px;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkrecepyDescriptionHeader1 {
    grid-area: c;
    font-family: "Lobster";
    font-size: 24px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkrecepyDescriptionHeader1 {
    grid-area: c;
    font-family: "Lobster";
    font-size: 24px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkrecepyDescriptionHeader1 {
    grid-area: c;
    font-family: "Lobster";
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99%;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkrecepyDescriptionHeader1 {
    grid-area: c;
    font-family: "Lobster";
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99%;
    margin-left: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xkrecepyDescriptionHeader1 {
    grid-area: c;
    font-family: "Lobster";
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    margin-left: 5px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkresizeBookIcon {
    font-size: 22px;
    margin-top: 4px;
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkresizeBookIcon {
    font-size: 22px;
    margin-top: 4px;
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkresizeBookIcon {
    font-size: 22px;
    margin-top: 4px;
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xkresizeBookIcon {
    font-size: 22px;
    margin-top: 4px;
    margin-right: 5px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkresizeBookIcon1 {
    font-size: 26px;
    margin-top: 4px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkresizeBookIcon1 {
    font-size: 26px;
    margin-top: 4px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkresizeBookIcon1 {
    font-size: 26px;
    margin-top: 4px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xkresizeBookIcon1 {
    font-size: 26px;
    margin-top: 4px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkkviewdescription {
    grid-area: c;
    width: 99%;
    height: 290px;
    margin-top: 2px;
    padding: 3px;
    border: none;
    border-color: white;
    outline: none;
    background: white;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    max-height: 290px;
    overflow-y: scroll;
  }
  .xkkviewdescription::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkkviewdescription::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkkviewdescription::-webkit-scrollbar-thumb {
    background-color: white;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkkviewdescription {
    grid-area: c;
    width: 99%;
    height: 70vh;
    margin-top: 2px;
    border: none;
    outline: none;
    background: transparent;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    max-height: 70vh;
    overflow-y: auto;
  }
  .xkkviewdescription::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkkviewdescription::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkkviewdescription::-webkit-scrollbar-thumb {
    background-color: white;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1080px) {
  .xkkviewdescription {
    height: 75vh;
    max-height: 75vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xkkviewdescription {
    height: 75vh;
    max-height: 75vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkkviewdescription {
    height: 68vh;
    max-height: 68vh;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkkviewdescription {
    height: 77vh;
    max-height: 77vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkkviewdescription {
    grid-area: c;
    width: 99%;
    height: 72vh;
    margin-top: 2px;
    border: none;
    resize: none;
    outline: none;
    background: transparent;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    min-height: 1px;
    max-height: 72vh;
    overflow-y: auto;
    padding: 5px;
  }
  .xkkviewdescription::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkkviewdescription::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkkviewdescription::-webkit-scrollbar-thumb {
    background-color: white;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkkviewdescription {
    grid-area: c;
    width: 97%;
    height: 77vh;
    margin-top: 2px;
    border: none;
    outline: none;
    resize: none;
    background: transparent;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    max-height: 77vh;
    overflow-y: auto;
    padding: 5px;
  }
  .xkkviewdescription::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkkviewdescription::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkkviewdescription::-webkit-scrollbar-thumb {
    background-color: white;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xkkviewdescription {
    grid-area: c;
    width: 99%;
    height: 75vh;
    margin-top: 2px;
    border: none;
    resize: none;
    outline: none;
    background: transparent;
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
    max-height: 75vh;
    overflow-y: auto;
    padding: 5px;
  }
  .xkkviewdescription::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkkviewdescription::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xkkviewdescription::-webkit-scrollbar-thumb {
    background-color: white;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkkviewdescription1 {
    grid-area: c;
    width: 99%;
    height: 580px;
    overflow-y: scroll;
    margin-top: 8px;
    margin-left: 35px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background: white;
    font-family: var(--font-family);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkkviewdescription1 {
    grid-area: c;
    width: 99%;
    height: 73vh;
    overflow-y: auto;
    margin-top: 8px;
    padding: 3px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background: white;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkkviewdescription1 {
    height: 68vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xkkviewdescription1 {
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkkviewdescription1 {
    grid-area: c;
    width: 98%;
    height: 73vh;
    overflow-y: scroll;
    margin-top: 8px;
    padding: 3px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background: transparent;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkkviewdescription1 {
    grid-area: c;
    width: 98%;
    height: 73vh;
    overflow-y: scroll;
    margin-top: 8px;
    padding: 3px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    padding: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xkkviewdescription1 {
    grid-area: c;
    width: 99%;
    height: 73vh;
    overflow-y: scroll;
    margin-top: 8px;
    padding: 3px;
    border: none;
    border-color: white;
    resize: none;
    outline: none;
    background: white;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    padding: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xknumTecken1,
  .xknumTecken2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xknumTecken1,
  .xknumTecken2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px;
    align-items: center;
    width: 97%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xknumTecken1,
  .xknumTecken2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px;
    align-items: center;
    width: 97%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xknumTecken1,
  .xknumTecken2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px;
    align-items: center;
    width: 97%;
  }
}
@media only screen and (min-width:2303px) {
  .xknumTecken1,
  .xknumTecken2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px;
    align-items: center;
    width: 97%;
  }
}

.xknumTecken {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: var(--font-family);
  font-size: 12px;
  align-items: center;
  width: 100%;
}

.xktextareaContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.xktextareaComments {
  width: 60%;
  height: 50px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: white;
  color: black;
  font-family: var(--font-family);
  border-radius: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
  font-weight: bold;
  padding: 1px;
  outline: none;
  resize: none;
}

.xkAddamountIngramMinus,
.xkAddamountIngramPlus {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal2);
  color: black;
  font-family: var(--font-family);
  margin-left: 2px;
  margin-bottom: 2px;
  font-weight: bold;
  cursor: pointer;
}

.xkAddamountIngramPlus {
  padding-left: 7px;
}

.xkbuttonFoodInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.xkbuttonFoodInfo {
  width: 61%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  color: black;
  font-size: 12px;
  border-radius: 6px;
  margin-left: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: pointer;
  align-self: flex-start;
}

.xkfoodArea {
  width: 100%;
}

.nutrientButton, .xkheaderButtonUnit, .xkheaderButtonQuantity, .xkheaderButtonNutrient {
  text-align: left;
  margin-top: 1px;
  border: none;
  background-color: var(--rgbaVal);
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  color: black;
}

.xkheaderButtonNutrient {
  width: 50%;
}

.xkheaderButtonQuantity {
  width: calc(25% - 2px);
  margin-left: 2px;
}

.xkheaderButtonUnit {
  width: calc(25% - 2px);
  margin-left: 2px;
}

.xkHeaderButtonQuantity,
.xkHeaderButtonUnit {
  width: 70px;
}

.xkfoodAreaHeader {
  width: 100%;
}

.xkfoodareaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.xkfoodareaContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 98%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: white;
}
.xkfoodareaContainer::-webkit-scrollbar {
  width: 1px; /* width of the entire scrollbar */
}
.xkfoodareaContainer::-webkit-scrollbar-track {
  background: var(--rgbaVal); /* color of the tracking area */
}
.xkfoodareaContainer::-webkit-scrollbar-thumb {
  background-color: white;
}

.xknutrientvalueButton, .xknutrientUnit2, .xknutrientUnit, .xknutrientQuantity2, .xknutrientQuantity, .xknutrientValue2, .xknutrientValue {
  text-align: left;
  margin-top: 1px;
  border: none;
  background-color: var(--rgbaVal);
  border-radius: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  height: 20px;
}

.xknutrientValue {
  width: 50%;
}

.xknutrientValue2 {
  width: 50%;
  background-color: var(--rgbaVal2);
}

.xknutrientQuantity {
  width: calc(25% - 2px);
  margin-left: 2px;
}

.xknutrientQuantity2 {
  width: calc(25% - 2px);
  margin-left: 2px;
  background-color: var(--rgbaVal2);
}

.xknutrientUnit {
  width: calc(25% - 2px);
  margin-left: 2px;
}

.xknutrientUnit2 {
  width: calc(25% - 2px);
  margin-left: 2px;
  background-color: var(--rgbaVal2);
}

.xkCompName,
.xkCompName1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  font-family: "Lobster";
}

.xkCompName {
  color: white;
}

.xkCompName1 {
  color: var(--rgbaVal);
}

.xkAiOutlinePlusCircle {
  color: var(--rgbaVal);
  font-size: 50px;
  cursor: pointer;
}

.xkanimateddiv20 {
  transform: rotate(0deg);
  animation-name: xjingredientNew20;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes xjingredientNew20 {
  0% {
    height: 0px;
  }
  100% {
    height: 105px;
  }
}
.animateddiv21 {
  animation-name: xjidentifier21;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes xjidentifier21 {
  0% {
    height: 0px;
  }
  100% {
    height: 305px;
  }
}
.xktabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktabs {
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktabs {
    height: 4vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktabs {
    height: 4vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktabs {
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xktabs {
    height: 5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 3px 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xktab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xktab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(235, 231, 231);
    padding: 3px 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xktab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(235, 231, 231);
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xktab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(235, 231, 231);
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xktab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(235, 231, 231);
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .xktab-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(235, 231, 231);
    padding: 8px 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid transparent;
    width: 50%;
    margin: 2px;
  }
}

.xktab-button:hover {
  background-color: white;
}

.xktab-button.active {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkImageIcon {
    font-size: 15px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xkImageIcon {
    font-size: 25px;
    color: var(--rgbaVal);
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkRecipeIcon {
    font-size: 15px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xkRecipeIcon {
    font-size: 25px;
    cursor: pointer;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 10px;
    margin-left: 3px;
    color: black;
  }
  .xkIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xkIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xkIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    color: black;
  }
  .xkIconText:hover {
    font-weight: bold;
  }
}
@media only screen and (min-width:2303px) {
  .xkIconText {
    font-family: var(--font-family);
    cursor: pointer;
    font-size: 16px;
    margin-left: 3px;
    color: black;
  }
  .xkIconText:hover {
    font-weight: bold;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkgenerateImageButton, .xkgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkgenerateImageButton, .xkgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkgenerateImageButton, .xkgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkgenerateImageButton, .xkgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xkgenerateImageButton, .xkgenerateImageButton1 {
    width: 25%;
    height: 5svh;
    background-color: var(--rgbaVal);
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    border: none;
    opacity: 0.8;
    margin-left: 3px;
  }
}

.xkgenerateImageButton1 {
  background-color: rgb(229, 64, 68);
  color: white;
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkimageinput {
    width: 70%;
    height: 4.5svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    resize: none;
  }
}
@media only screen and (min-width:2303px) {
  .xkimageinput {
    width: 70%;
    height: 4.7svh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 24px;
    resize: none;
  }
}

.xkgenerateImageTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.xkgenerateImageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .s3imagedata, .s3imagedataNotFound, .s3imagedata1 {
    margin-top: 1vh;
    width: 90%;
    height: auto;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .s3imagedata, .s3imagedataNotFound, .s3imagedata1 {
    margin-top: 2vh;
    width: 70%;
    height: auto;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .s3imagedata, .s3imagedataNotFound, .s3imagedata1 {
    margin-top: 2vh;
    width: 70%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .s3imagedata, .s3imagedataNotFound, .s3imagedata1 {
    margin-top: 2vh;
    width: 70%;
    height: auto;
    border-radius: 6px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .s3imagedata, .s3imagedataNotFound, .s3imagedata1 {
    width: 70%;
    height: 70%;
    border-radius: 6px;
    margin-top: 2vh;
    cursor: pointer;
  }
}

.s3imagedata1 {
  border: 5px solid rgb(229, 64, 68);
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 44vh;
    max-height: 44vh;
    overflow-y: auto;
  }
  .xkImageContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkImageContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 370px) and (max-width: 380px) {
  .xkImageContainer {
    height: 36vh;
    max-height: 36vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .xkImageContainer {
    height: 38vh;
    max-height: 38vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 411px) and (max-width: 414px) and (max-height: 750px) {
  .xkImageContainer {
    height: 40vh;
    max-height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 381px) and (max-width: 385px) {
  .xkImageContainer {
    height: 43vh;
    max-height: 43vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) and (min-height: 820px) {
  .xkImageContainer {
    height: 32vh;
    max-height: 32vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 415px) and (max-width: 429px) {
  .xkImageContainer {
    height: 43vh;
    max-height: 43vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 480px) {
  .xkImageContainer {
    height: 45vh;
    max-height: 45vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
  .xkImageContainer::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xkImageContainer::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1171px) and (max-width: 1195px) {
  .xkImageContainer {
    height: 59vh;
    max-height: 59vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xkImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
  }
}

.xkimageInformationTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.xkimageInformationText {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: 5px;
  font-family: var(--font-family);
  font-size: 12px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkimageInformationText {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkimageInformationText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkimageInformationText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkimageInformationText {
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xkimageInformationText {
    font-size: 14px;
  }
}

.xkimageIconUpload {
  font-size: 22px;
  cursor: pointer;
  color: var(--rgbaVal);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkimageIconUpload {
    font-size: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkimageIconUpload {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkimageIconUpload {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkimageIconUpload {
    font-size: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .xkimageIconUpload {
    font-size: 22px;
  }
}

.xkimageUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}

.xkimageUploadWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.xkinputimageUpload {
  display: none;
}

.xkfileLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 5px;
  height: 25px;
  width: 120px;
  background-color: var(--rgbaVal);
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
}

.xkbuttonUploadImageWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 50%;
}

.xkbuttonUploadImage, .xkbuttonUploadImage1 {
  margin-left: 5px;
  width: 120px;
  height: 30px;
  background-color: var(--rgbaVal);
  color: black;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  opacity: 0.7;
}

.xkbuttonUploadImage1 {
  background-color: rgb(229, 64, 68);
  color: white;
  cursor: pointer;
}

.xkfileName {
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 3px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.xkimageUploadMessage {
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 7px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xkimageUploadMessage {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xkimageUploadMessage {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xkimageUploadMessage {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xkimageUploadMessage {
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .xkimageUploadMessage {
    font-size: 14px;
  }
}

.xkbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}/*# sourceMappingURL=CreateManRecepyNew2.css.map */