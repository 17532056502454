:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infototWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infototWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infototWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infototWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infototWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infototalArea {
    display: grid;
    grid-template-rows: 0.1% 99.5%;
    grid-template-areas: "a" "b";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infototalArea {
    display: grid;
    grid-template-columns: 35% 64.5%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 5px;
         column-gap: 5px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infototalArea {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infototalArea {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .infototalArea {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: auto;
    margin-top: 5px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoA {
    grid-area: a;
    height: 1px;
    background-color: transparent;
    width: 100%;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoA {
    grid-area: a;
    height: 90vh;
    background-color: rgb(234, 213, 186);
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoA {
    grid-area: a;
    height: 90vh;
    background-color: rgb(234, 213, 186);
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoA {
    grid-area: a;
    height: 90vh;
    background-color: rgb(234, 213, 186);
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infoA {
    grid-area: a;
    height: 90vh;
    background-color: rgb(234, 213, 186);
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoB {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
    z-index: 3;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoB {
    display: flex;
    flex-direction: column;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 98%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoB {
    display: flex;
    flex-direction: column;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 98%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoB {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover;
    background-position: center top;
    width: 98%;
  }
}
@media only screen and (min-width:2303px) {
  .infoB {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 98%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBJAP {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBJAP {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBJAP {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBJAP {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover;
    background-position: center top;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infoBJAP {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBEnglish {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBEnglish {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBEnglish {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBEnglish {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover;
    background-position: center top;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infoBEnglish {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .corporateBasBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .corporateBasBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .corporateBasBackgroundImage {
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .corporateBasBackgroundImage {
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .corporateBasBackgroundImage {
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .corporateTotalBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .corporateTotalBackgroundImage {
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .corporateTotalBackgroundImage {
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .corporateTotalBackgroundImage {
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .corporateTotalBackgroundImage {
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .faqBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .faqBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .faqBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .faqBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .faqBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .faqtransparentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .faqtransparentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 74%;
    height: 72%;
    margin-top: 4vh;
    border-radius: 12px;
    z-index: 2;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1020px) and (max-width: 1024px) {
  .faqtransparentBox {
    width: 74%;
    height: 85%;
    margin-top: 4vh;
    border-radius: 12px;
    z-index: 2;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) and (max-width: 1080px) {
  .faqtransparentBox {
    width: 74%;
    height: 90%;
    margin-top: 4vh;
    border-radius: 12px;
    z-index: 2;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) {
  .faqtransparentBox {
    width: 80%;
    height: 85%;
    margin-top: 3vh;
    border-radius: 12px;
    z-index: 2;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .faqtransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 74%;
    height: 72%;
    margin-top: 5vh;
    border-radius: 12px;
    z-index: 2;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .faqtransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 74%;
    height: 82%;
    margin-top: 5vh;
    border-radius: 12px;
    z-index: 2;
  }
}
@media only screen and (min-width:2303px) {
  .faqtransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 74%;
    height: 85%;
    margin-top: 5vh;
    border-radius: 12px;
    z-index: 2;
  }
}

.faqSurroundBox {
  border: 1px solid rgb(223, 212, 212);
  width: 80%;
  height: 52vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
  max-height: 50vh;
  overflow-y: scroll;
}
.faqSurroundBox::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .faqSurroundBox {
    width: 96%;
    height: 80vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height: 68vh;
    overflow-y: scroll;
    margin-top: 2vh;
  }
  .faqSurroundBox::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .faqSurroundBox {
    width: 80%;
    height: 68vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height: 68vh;
    overflow-y: scroll;
  }
  .faqSurroundBox::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) and (max-width: 1080px) {
  .faqSurroundBox {
    height: 72vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) {
  .faqSurroundBox {
    height: 80vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .faqSurroundBox {
    width: 80%;
    height: 60vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height: 60vh;
    overflow-y: scroll;
  }
  .faqSurroundBox::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .faqSurroundBox {
    width: 85%;
    height: 63vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height: 68vh;
    overflow-y: scroll;
  }
  .faqSurroundBox::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 90%;
    z-index: 2;
    height: 40%;
    margin-top: 2vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 65%;
    height: 40%;
    margin-top: 4vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 55%;
    height: 50%;
    margin-top: 4vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBox {
    background-color: rgba(255, 255, 255, 0.7);
    width: 54%;
    height: 60vh;
    margin-top: 4vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 65%;
    height: 60%;
    margin-top: 4vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxSwedishTotal {
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 82%;
    margin-top: 1vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxSwedishTotal {
    background-color: rgba(255, 255, 255, 0.7);
    width: 35%;
    margin-left: 26%;
    margin-top: 2vh;
    height: 70vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxSwedishTotal {
    background-color: rgba(255, 255, 255, 0.7);
    width: 35%;
    margin-left: 38%;
    margin-top: 2vh;
    height: 77vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxSwedishTotal {
    background-color: rgba(255, 255, 255, 0.5);
    width: 35%;
    margin-left: 38%;
    margin-top: 2vh;
    height: 77vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxSwedishTotal {
    background-color: rgba(255, 255, 255, 0.5);
    width: 35%;
    margin-left: 38%;
    margin-top: 2vh;
    height: 77vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxCorporateBas {
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 82%;
    margin-top: 1vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxCorporateBas {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 85%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxCorporateBas {
    background-color: rgba(255, 255, 255, 0.7);
    width: 40%;
    margin-left: 32%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxCorporateBas {
    background-color: rgba(255, 255, 255, 0.5);
    width: 34%;
    margin-left: 38%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxCorporateBas {
    background-color: rgba(255, 255, 255, 0.5);
    width: 34%;
    margin-left: 38%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxCorporateTotal {
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 85%;
    margin-top: 1vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxCorporateTotal {
    background-color: rgba(255, 255, 255, 0.7);
    width: 55%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxCorporateTotal {
    background-color: rgba(255, 255, 255, 0.7);
    width: 40%;
    margin-left: 4%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxCorporateTotal {
    background-color: rgba(255, 255, 255, 0.7);
    width: 40%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxCorporateTotal {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 4%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxCorporateTotalFeatures {
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 70vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxCorporateTotalFeatures {
    background-color: rgba(255, 255, 255, 0.8);
    width: 45%;
    height: 75vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxCorporateTotalFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 4%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxCorporateTotalFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 4%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxCorporateTotalFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 4%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxTotalFeatures {
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 82%;
    margin-top: 1vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxTotalFeatures {
    background-color: rgba(255, 255, 255, 0.8);
    width: 74%;
    height: 68vh;
    margin-top: 4vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxTotalFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 38%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxTotalFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 38%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxTotalFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 38%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxCorporateBasFeatures {
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 70%;
    margin-top: 1vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxCorporateBasFeatures {
    background-color: rgba(255, 255, 255, 0.8);
    width: 55%;
    height: 70%;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxCorporateBasFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 38%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxCorporateBasFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 38%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxCorporateBasFeatures {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 38%;
    height: 70vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxBasList {
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    margin-top: 2vh;
    height: 79%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxBasList {
    background-color: rgba(255, 255, 255, 0.7);
    width: 50%;
    margin-top: 2vh;
    height: 70%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxBasList {
    background-color: rgba(255, 255, 255, 0.7);
    width: 45%;
    margin-left: 2%;
    margin-top: 2vh;
    height: 70%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxBasList {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 2%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxBasList {
    background-color: rgba(255, 255, 255, 0.7);
    width: 34%;
    margin-left: 2%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxPlus {
    background-color: rgba(255, 255, 255, 0.9);
    width: 94%;
    height: 90%;
    margin-top: 2vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 87vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 12px;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  }
  .transparentBoxPlus::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxPlus {
    background-color: rgba(255, 255, 255, 0.9);
    width: 55%;
    height: 77%;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1200px) {
  .transparentBoxPlus {
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxPlus {
    background-color: rgba(255, 255, 255, 0.9);
    width: 65%;
    height: 82vh;
    margin-top: 3vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxPlus {
    background-color: rgba(255, 255, 255, 0.9);
    width: 54%;
    margin-left: 2%;
    height: 82vh;
    margin-top: 3vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxPlus {
    background-color: rgba(255, 255, 255, 0.9);
    width: 54%;
    margin-left: 2%;
    height: 82vh;
    margin-top: 3vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxAIServices {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxAIServices {
    background-color: rgba(255, 255, 255, 0.8);
    width: 74%;
    height: 65vh;
    margin-top: 9vh;
    border-radius: 12px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxAIServices {
    background-color: rgba(255, 255, 255, 0.8);
    width: 74%;
    height: 65vh;
    margin-top: 10vh;
    border-radius: 12px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxAIServices {
    background-color: rgba(255, 255, 255, 0.8);
    width: 74%;
    height: 65vh;
    margin-top: 9vh;
    border-radius: 12px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxAIServices {
    background-color: rgba(255, 255, 255, 0.8);
    width: 70%;
    height: 80%;
    margin-top: 7vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .transparentBoxBs {
    background-color: rgba(255, 255, 255, 0.8);
    width: 87%;
    height: 77%;
    margin-top: 1vh;
    z-index: 2;
    position: absolute;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .transparentBoxBs {
    background-color: rgba(255, 255, 255, 0.8);
    width: 65%;
    height: 90%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .transparentBoxBs {
    background-color: rgba(255, 255, 255, 0.5);
    width: 40%;
    height: 78vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .transparentBoxBs {
    background-color: rgba(255, 255, 255, 0.5);
    width: 40%;
    height: 78vh;
    margin-top: 4vh;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .transparentBoxBs {
    background-color: rgba(255, 255, 255, 0.7);
    width: 50%;
    height: 80vh;
    margin-top: 2vh;
    z-index: 2;
    position: absolute;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aboutUsTransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aboutUsTransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 80%;
    height: 90%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
    border-radius: 12px;
    box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1200px) {
  .aboutUsTransparentBox {
    height: 80vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aboutUsTransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 80%;
    height: 90%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
    border-radius: 12px;
    box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aboutUsTransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 80%;
    height: 90%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4vh;
    border-radius: 12px;
    box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width:2303px) {
  .aboutUsTransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 80%;
    height: 90%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
    border-radius: 12px;
    box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
  }
}

.aboutUsSurroundBorder {
  border: 1px solid rgb(223, 212, 212);
  background-color: white;
  width: 80%;
  height: 70vh;
  border-radius: 12px;
  box-shadow: 12px 12px 15px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .aboutUsSurroundBorder {
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    width: 95%;
    height: 80vh;
    border-radius: 12px;
    box-shadow: 12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.7vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aboutUsSurroundBorder {
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    width: 80%;
    height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    border-radius: 12px;
    box-shadow: 12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .aboutUsSurroundBorder::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aboutUsSurroundBorder {
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    width: 80%;
    height: 70vh;
    border-radius: 12px;
    box-shadow: 12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aboutUsSurroundBorder {
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    width: 80%;
    height: 70vh;
    border-radius: 12px;
    box-shadow: 12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .aboutUsSurroundBorder {
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    width: 80%;
    height: 70vh;
    border-radius: 12px;
    box-shadow: 12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.aboutUsChapter {
  height: 8vh;
  width: 97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .aboutUsChapter {
    height: 10vh;
    width: 97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .aboutUsChapter {
    height: 10vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aboutUsChapter {
    height: 11vh;
    width: 97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1020px) and (max-width: 1024px) {
  .aboutUsChapter {
    height: 15vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) and (max-width: 1100px) {
  .aboutUsChapter {
    height: 14vh;
  }
}

.aboutUsChapterJP {
  height: 8vh;
  width: 97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .aboutUsChapterJP {
    height: 10vh;
    width: 97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .aboutUsChapterJP {
    height: 11vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aboutUsChapterJP {
    height: 11vh;
    width: 97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1020px) and (max-width: 1024px) {
  .aboutUsChapterJP {
    height: 15vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) and (max-width: 1100px) {
  .aboutUsChapterJP {
    height: 14vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoButtonTopWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoButtonTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoButtonTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoButtonTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoButtonTopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoButton {
    flex: 1;
    height: 26px;
    border: none;
    background-color: rgb(223, 196, 167);
    border-radius: 6px;
    font-family: Lobster;
    font-size: 16px;
    white-space: nowrap;
    outline: none;
    color: black;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) and (max-width: 413px) {
  .infoButton {
    height: 33px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 40px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    margin-top: 5px;
    padding: 10px;
    cursor: pointer;
    color: black;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  .infoButton:active {
    transform: scale(1.1);
    background-color: rgb(243, 216, 187);
  }
  .infoButton:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    width: 70%;
    height: 40px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    margin-top: 5px;
    cursor: pointer;
    color: black;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  .infoButton:active {
    transform: scale(1.1);
    background-color: rgb(243, 216, 187);
  }
  .infoButton:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    width: 65%;
    height: 40px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    margin-top: 5px;
    cursor: pointer;
    color: black;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  .infoButton:active {
    transform: scale(1.1);
    background-color: rgb(243, 216, 187);
  }
  .infoButton:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .infoButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    width: 60%;
    height: 40px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    margin-top: 5px;
    cursor: pointer;
    color: black;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  .infoButton:active {
    transform: scale(1.1);
    background-color: rgb(243, 216, 187);
  }
  .infoButton:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}

.activeButton {
  background-color: rgb(77, 43, 29);
  color: white;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoButtonJAP {
    flex: 1;
    width: auto;
    height: 25px;
    border: none;
    background-color: rgb(223, 196, 167);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoButtonJAP {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    width: 55%;
    height: 30px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    margin-top: 5px;
    cursor: pointer;
    color: black;
  }
  .infoButtonJAP:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoButtonJAP {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    width: 55%;
    height: 30px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    margin-top: 5px;
    cursor: pointer;
    color: black;
  }
  .infoButtonJAP:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoButtonJAP {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    width: 55%;
    height: 40px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    margin-top: 5px;
    cursor: pointer;
    color: black;
  }
  .infoButtonJAP:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .infoButtonJAP {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    width: 55%;
    height: 40px;
    border: none;
    background-color: rgb(212, 177, 145);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    margin-top: 10px;
    cursor: pointer;
    color: black;
  }
  .infoButtonJAP:hover {
    background-color: rgb(77, 43, 29);
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoStartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoStartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoStartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoStartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infoStartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoStartHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 28px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoStartHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 28px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoStartHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 30px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoStartHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 30px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoStartHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 40px;
    color: black;
    margin-top: 0.5vh;
  }
}

.infoStartHeaderJAP {
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 28px;
  color: black;
  margin-top: 0.5vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoStartText {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoStartText {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoStartText {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoStartText {
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width:2303px) {
  .infoStartText {
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoStartTextSwedish {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoStartTextSwedish {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoStartTextSwedish {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoStartTextSwedish {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}
@media only screen and (min-width:2303px) {
  .infoStartTextSwedish {
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 1vh;
    margin-left: 5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoStartTextJapanese {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoStartTextJapanese {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoStartTextJapanese {
    font-family: var(--font-family);
    font-size: 15px;
    color: black;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoStartTextJapanese {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-left: 5%;
  }
}
@media only screen and (min-width:2303px) {
  .infoStartTextJapanese {
    font-family: var(--font-family);
    font-size: 17px;
    color: black;
    margin-left: 5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoSignUpWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoSignUpWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoSignUpWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoSignUpWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infoSignUpWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoSignUpHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 20px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoSignUpHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 28px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoSignUpHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 30px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoSignUpHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 30px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoSignUpHeader {
    display: flex;
    justify-content: center;
    font-family: Lobster;
    font-size: 40px;
    color: black;
    margin-top: 0.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoSignUpHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoSignUpHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoSignUpHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoSignUpHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoSignUpHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    margin-top: 0.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoSignUpText {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoSignUpText {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoSignUpText {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoSignUpText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width:2303px) {
  .infoSignUpText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoSignUpArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoSignUpArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoSignUpArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoSignUpArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
}
@media only screen and (min-width:2303px) {
  .infoSignUpArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .signupinput {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 3px;
    margin-top: 1vh;
    outline: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .signupinput {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 3px;
    margin-top: 1vh;
    outline: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .signupinput {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 3px;
    margin-top: 1vh;
    outline: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .signupinput {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 3px;
    margin-top: 1vh;
    outline: none;
  }
}
@media only screen and (min-width:2303px) {
  .signupinput {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 3px;
    margin-top: 1vh;
    outline: none;
  }
}

.signupmandatoryText {
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
  margin-top: 1vh;
  text-align: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoservicesArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoservicesArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoservicesArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoservicesArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infoservicesArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoselect {
    width: 61%;
    height: 30px;
    border: none;
    border-radius: 3px;
    margin-top: 1vh;
    outline: none;
    background-color: white;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoselect {
    width: 61%;
    height: 25px;
    border: none;
    border-radius: 3px;
    margin-top: 1vh;
    outline: none;
    background-color: white;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoselect {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 6px;
    margin-top: 1vh;
    outline: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoselect {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 6px;
    margin-top: 1vh;
    outline: none;
  }
}
@media only screen and (min-width:2303px) {
  .infoselect {
    width: 60%;
    height: 3vh;
    border: none;
    border-radius: 6px;
    margin-top: 1vh;
    outline: none;
  }
}

.infosignupButton {
  width: 90%;
  height: 4vh;
  border: none;
  border-radius: 6px;
  margin-top: 2vh;
  outline: none;
  background-color: rgb(197, 161, 127);
  color: white;
}
.infosignupButton:hover {
  background-color: rgb(77, 43, 29);
  color: white;
  cursor: pointer;
}
.infosignupButton:active {
  background-color: green;
  color: white;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 85vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 87vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 87vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 87vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 87vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsTopArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70vh;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .infoBsTopArea::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsTopArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 90%;
    max-height: 90%;
    overflow-y: scroll;
  }
  .infoBsTopArea::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsTopArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsTopArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsTopArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 3.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 30px;
    color: black;
    margin-top: 0.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 3.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesHeaderJAP {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesText {
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesText {
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesText {
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesText {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesText {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    text-align: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesBaseArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesBaseArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesBaseArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesBaseArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesBaseArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesBaseHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesBaseHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesBaseHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesBaseHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesBaseHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesBaseHeaderJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesBaseHeaderJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesBaseHeaderJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesBaseHeaderJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesBaseHeaderJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesBaseHeaderPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesBaseHeaderPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesBaseHeaderPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesBaseHeaderPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesBaseHeaderPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    margin-top: 0.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesBaseHeaderPriceJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesBaseHeaderPriceJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesBaseHeaderPriceJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesBaseHeaderPriceJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesBaseHeaderPriceJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: black;
    margin-top: 0.5vh;
    font-family: var(--font-family);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBsServicesBaseText {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBsServicesBaseText {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBsServicesBaseText {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBsServicesBaseText {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
  }
}
@media only screen and (min-width:2303px) {
  .infoBsServicesBaseText {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
  }
}

.infoBsServicesRows {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .baseServicesTop {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .baseServicesTop {
    width: 100%;
    height: 80vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .baseServicesTop {
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .baseServicesTop {
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width:2303px) {
  .baseServicesTop {
    width: 100%;
    height: 90vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .baseServicesTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .baseServicesTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    background: rgb(234, 213, 186);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .baseServicesTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3.8vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    background: rgb(162, 139, 109);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .baseServicesTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3.8vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    background: rgb(162, 139, 109);
  }
}
@media only screen and (min-width:2303px) {
  .baseServicesTabTop {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3.8vh;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    background: rgb(162, 139, 109);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .baseServicesTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    width: 100%;
    border: none;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .baseServicesTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .baseServicesTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .baseServicesTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .baseServicesTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .baseServicesTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .baseServicesTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .baseServicesTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .baseServicesTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: var(--rgbaVal2);
  }
  .baseServicesTab.active {
    background-color: var(--rgbaVal);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }
}

.baseServicesfirstButton {
  border-top-left-radius: 6px;
}

.baseServiceslastButton {
  border-top-right-radius: 6px;
}

.baseServicesmobileTab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4vh;
  width: 100%;
  border: 1px solid rgb(240, 232, 232);
  cursor: pointer;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  font-family: var(--font-family);
  font-size: 13px;
  background-color: rgb(197, 161, 127);
  white-space: nowrap;
  margin-right: -10px; /* Added to make tabs overlap horizontally */
  position: relative;
  z-index: 1; /* Base z-index for stacking */
}
.baseServicesmobileTab.active {
  border: 1px solid white;
  color: white;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  z-index: 2; /* Ensure the active tab is above others */
  margin-right: 0; /* Adjust margin for the active tab */
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .baseServicesmobileTab {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    margin-left: 1px;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 13px;
    background-color: rgb(223, 196, 167);
    margin-right: -20px; /* Added to make tabs overlap horizontally */
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .baseServicesmobileTab.active {
    background-color: rgb(223, 196, 167);
    border: 1px solid white;
    color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-right: -20px;
    border-color: rgb(223, 212, 212);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .baseServicesmobileTab {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    margin-left: 2px;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 18px;
    background-color: rgb(218, 200, 172);
    margin-right: -20px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: 5px;
  }
  .baseServicesmobileTab.active {
    border: 1px solid white;
    color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-right: -20px;
    height: 4vh;
    border-color: rgb(223, 212, 212);
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .baseServicesmobileTab {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    margin-left: 2px;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 18px;
    background-color: rgb(218, 200, 172);
    margin-right: -20px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: 5px;
  }
  .baseServicesmobileTab.active {
    border: 1px solid white;
    color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-right: -20px;
    height: 4vh;
    border-color: rgb(223, 212, 212);
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .baseServicesmobileTab {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    margin-left: 2px;
    border: none;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 22px;
    background-color: rgb(218, 200, 172);
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: 8px;
  }
  .baseServicesmobileTab.active {
    border: 1px solid white;
    color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    height: 3.5vh;
    border-color: rgb(223, 212, 212);
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .corporateBasServiceCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 71vh;
    max-height: 71vh;
    font-family: var(--font-family);
    font-size: 14px;
    overflow-y: auto;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .corporateBasServiceCommentText {
    text-align: center;
    width: 90%;
    height: 70vh;
    max-height: 80vh;
    font-family: var(--font-family);
    font-size: 15px;
    overflow-y: scroll;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .corporateBasServiceCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 75vh;
    max-height: 90vh;
    font-family: var(--font-family);
    font-size: 15px;
    overflow-y: auto;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar {
    display: none;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .corporateBasServiceCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .corporateBasServiceCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 2%;
    height: 75vh;
    max-height: 80vh;
    font-family: var(--font-family);
    font-size: 20px;
    overflow-y: auto;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar {
    display: none;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .corporateBasServiceCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .corporateBasServiceCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 2%;
    height: 75vh;
    max-height: 80vh;
    font-family: var(--font-family);
    font-size: 22px;
    overflow-y: auto;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar {
    display: none;
  }
  .corporateBasServiceCommentText::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .corporateBasServiceCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .corporateTotalServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
    height: 70vh;
    max-height: 70vh;
    font-family: var(--font-family);
    font-size: 15px;
    overflow-y: auto;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .corporateTotalServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 70vh;
    max-height: 70vh;
    font-family: var(--font-family);
    font-size: 15px;
    overflow-y: scroll;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .corporateTotalServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 90vh;
    max-height: 90vh;
    font-family: var(--font-family);
    font-size: 15px;
    overflow-y: auto;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .corporateTotalServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 2%;
    height: 80vh;
    max-height: 80vh;
    font-family: var(--font-family);
    font-size: 20px;
    overflow-y: auto;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .corporateTotalServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 2%;
    height: 80vh;
    max-height: 80vh;
    font-family: var(--font-family);
    font-size: 22px;
    overflow-y: auto;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar-track {
    background: var(--rgbaVal2);
  }
  .corporateTotalServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aboutUsServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    font-size: 10px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .aboutUsServicesCommentText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aboutUsServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aboutUsServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    font-size: 15px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aboutUsServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width:2303px) {
  .aboutUsServicesCommentText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-family: var(--font-family);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesWrapper {
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 85vh;
    max-height: 85vh;
    overflow-y: scroll;
  }
  .basicServicesWrapper::-webkit-scrollbar {
    width: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesWrapper {
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 90vh;
    max-height: 90vh;
    overflow-y: scroll;
  }
  .basicServicesWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesWrapper {
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 90vh;
    max-height: 90vh;
    overflow-y: scroll;
  }
  .basicServicesWrapper::-webkit-scrollbar {
    width: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesWrapper {
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 90vh;
    max-height: 90vh;
    overflow-y: scroll;
  }
  .basicServicesWrapper::-webkit-scrollbar {
    width: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesWrapper {
    width: 100%;
    background-color: rgb(223, 196, 167);
    height: 90vh;
    max-height: 90vh;
    overflow-y: scroll;
  }
  .basicServicesWrapper::-webkit-scrollbar {
    width: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 4vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    padding-top: 4vh;
    font-style: italic;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    padding-top: 4vh;
    font-style: italic;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    margin-top: 4vh;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .plusServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 4.5vh;
    width: 80%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .plusServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 17px;
    font-weight: 100;
    color: black;
    margin-top: 4vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .plusServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 4.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .plusServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 4.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .plusServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    margin-top: 4.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aiServicesSurround {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    width: 80%;
    height: 40vh;
    padding: 10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aiServicesSurround {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    width: 80%;
    height: 40vh;
    padding: 10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aiServicesSurround {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    width: 80%;
    height: 40vh;
    padding: 10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aiServicesSurround {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    width: 80%;
    height: 40vh;
    padding: 10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .aiServicesSurround {
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    width: 80%;
    height: 50vh;
    padding: 10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aiServicesText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 500;
    width: 98%;
    padding-left: 12vw;
    margin-top: 15px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .aiServicesText {
    font-size: 14px;
    padding-left: 20vw;
    margin-top: 3vh;
    width: 90%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aiServicesText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-family: var(--font-family);
    font-weight: 500;
    width: 70%;
    padding-left: 5vw;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aiServicesText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-family: var(--font-family);
    font-weight: 500;
    width: 58%;
    padding-left: 9vw;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aiServicesText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    font-size: 22px;
    font-family: var(--font-family);
    font-weight: 500;
    width: 58%;
    padding-left: 12vw;
    margin-top: 15px;
  }
}
@media only screen and (min-width:2303px) {
  .aiServicesText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    font-size: 24px;
    font-family: var(--font-family);
    font-weight: 500;
    width: 58%;
    padding-left: 5vw;
    margin-top: 15px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .totalServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .totalServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 4vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .totalServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 4vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .totalServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .totalServicesHeader {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesPlusHeader {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 2vh;
    height: 35px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesPlusHeader {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesPlusHeader {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 1.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesPlusHeader {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesPlusHeader {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1.5vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesPlusHeaderMoreInfo {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 0.5vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesPlusHeaderMoreInfo {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    font-style: italic;
    margin-top: 0.5;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesPlusHeaderMoreInfo {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesPlusHeaderMoreInfo {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesPlusHeaderMoreInfo {
    width: 96%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    font-style: italic;
  }
}

.basicServicesPlusHeaderJAP {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  font-style: italic;
  margin-top: 2vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesHeaderJAPTEXT {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesHeaderJAPTEXT {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesHeaderJAPTEXT {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesHeaderJAPTEXT {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesHeaderJAPTEXT {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1.5vh;
    font-style: italic;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    width: 98%;
    zoom: 0.8;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
  }
  .basicServicesText::-webkit-scrollbar {
    width: 5px;
  }
  .basicServicesText::-webkit-scrollbar-track {
    background: lightblue;
  }
  .basicServicesText::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    border: 1px solid;
    border-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    text-align: left;
    width: 90%;
    border-radius: 8px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    margin-left: 5px;
    border-color: rgb(223, 212, 212);
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border: 1px solid rgb(223, 212, 212);
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    margin-left: 1vw;
    border-color: rgb(223, 212, 212);
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    width: 70%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    margin-left: 1vw;
    border-color: rgb(223, 212, 212);
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    width: 60%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    margin-left: 1vw;
    border-color: rgb(223, 212, 212);
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border: 1px solid rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }
}

.basicServicesListText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  font-size: 16px;
  font-family: var(--font-family);
  margin-top: 2vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesList {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesList {
    text-align: left;
    margin-left: 10%;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesList {
    text-align: left;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesList {
    text-align: left;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesList {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 15%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 22px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 22px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .plusServicesListMid {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 25%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .plusServicesListMid {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 32%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .plusServicesListMid {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 38%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .plusServicesListMid {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 42%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .plusServicesListMid {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 32%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aiservicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aiservicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aiservicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aiservicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .aiservicesListMid {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 5%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesListJAP {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 30%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 10%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 15%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .totalServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 5%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .totalServicesListJAP {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .totalServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 18%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 15px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .totalServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 25%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .totalServicesListJAP {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left: 20%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesCommentText {
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
    margin-top: 1vh;
    text-align: left;
    width: 90%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 75vh;
    overflow-y: scroll;
  }
  .basicServicesCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesCommentText {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 75vh;
    overflow-y: scroll;
  }
  .basicServicesCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesCommentText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    text-align: center;
    width: 85%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .basicServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .basicServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesCommentText {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    text-align: center;
    width: 75%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .basicServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .basicServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesCommentText {
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
    text-align: center;
    width: 85%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .basicServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .basicServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
    margin-top: 1vh;
    text-align: left;
    width: 92%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 80vh;
    overflow-y: scroll;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    text-align: left;
    width: 94%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 94%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar {
    width: 2px;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 80%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 80%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
  .basicServicesCommentTextJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aboutUsCommentText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 80%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .aboutUsCommentText::-webkit-scrollbar {
    display: none;
  }
  .aboutUsCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aboutUsCommentText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 80%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .aboutUsCommentText::-webkit-scrollbar {
    display: none;
  }
  .aboutUsCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aboutUsCommentText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 80%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .aboutUsCommentText::-webkit-scrollbar {
    display: none;
  }
  .aboutUsCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aboutUsCommentText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 80%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .aboutUsCommentText::-webkit-scrollbar {
    display: none;
  }
  .aboutUsCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width:2303px) {
  .aboutUsCommentText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 80%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .aboutUsCommentText::-webkit-scrollbar {
    display: none;
  }
  .aboutUsCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .totalServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
    margin-top: 1vh;
    text-align: left;
    width: 80%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 80vh;
    overflow-y: scroll;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .totalServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    text-align: left;
    width: 94%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .totalServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 94%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar {
    width: 2px;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .totalServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width:2303px) {
  .totalServicesCommentTextJAP {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 76vh;
    overflow-y: scroll;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar {
    display: none;
  }
  .totalServicesCommentTextJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .totalServicesCommentText {
    font-family: var(--font-family);
    font-size: 13px;
    color: black;
    margin-top: 1vh;
    text-align: left;
    width: 94%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .totalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .totalServicesCommentText {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    margin-top: 1vh;
    text-align: left;
    width: 94%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 60vh;
    overflow-y: scroll;
  }
  .totalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .totalServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .totalServicesCommentText {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    margin-top: 1vh;
    text-align: center;
    width: 90%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 67vh;
    overflow-y: scroll;
  }
  .totalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .totalServicesCommentText {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    text-align: center;
    width: 95%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .totalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .totalServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}
@media only screen and (min-width:2303px) {
  .totalServicesCommentText {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    text-align: center;
    width: 95%;
    padding-right: 5px;
    padding-left: 5px;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .totalServicesCommentText::-webkit-scrollbar {
    display: none;
  }
  .totalServicesCommentText::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(223, 196, 167);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesHr {
    width: 70%;
    background-color: white;
    border: none;
    height: 1px;
    margin-bottom: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesHr {
    width: 70%;
    background-color: white;
    border: none;
    height: 1px;
    margin-bottom: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesHr {
    width: 70%;
    background-color: white;
    border: none;
    height: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesHr {
    width: 70%;
    background-color: white;
    border: none;
    height: 1px;
    margin-bottom: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesHr {
    width: 70%;
    background-color: white;
    border: none;
    height: 1px;
    margin-bottom: 2vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesCheckIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    color: green;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesCheckIcon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    color: green;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesCheckIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aiServicesIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aiServicesIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aiServicesIcon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: black;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aiServicesIcon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: black;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .aiServicesIcon {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    color: black;
    margin-top: 3px;
  }
}

.aboutUsLeafIcon, .aboutUsLeafIconJP {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  color: black;
  margin-top: 5vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .aboutUsLeafIcon, .aboutUsLeafIconJP {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: black;
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aboutUsLeafIcon, .aboutUsLeafIconJP {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: black;
    margin-top: 4vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aboutUsLeafIcon, .aboutUsLeafIconJP {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: black;
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aboutUsLeafIcon, .aboutUsLeafIconJP {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: black;
    margin-top: 5vh;
  }
}
@media only screen and (min-width:2303px) {
  .aboutUsLeafIcon, .aboutUsLeafIconJP {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: black;
    margin-top: 5vh;
  }
}

.aboutUsLeafIconJP {
  margin-top: 3vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .aiServicesCheckIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    color: green;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .aiServicesCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .aiServicesCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .aiServicesCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: green;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .aiServicesCheckIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .plusServicesIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .plusServicesIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 60vh;
    max-height: 58vh;
    overflow-y: auto;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
    padding-top: 15px;
    background-color: white;
  }
  .basicServicesListWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 60vh;
    max-height: 58vh;
    overflow-y: auto;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
    padding-top: 15px;
    background-color: white;
  }
  .basicServicesListWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 60vh;
    max-height: 58vh;
    overflow-y: auto;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
    padding-top: 15px;
    background-color: white;
  }
  .basicServicesListWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 60vh;
    max-height: 58vh;
    overflow-y: auto;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
    padding-top: 15px;
    background-color: white;
  }
  .basicServicesListWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 60vh;
    max-height: 58vh;
    overflow-y: auto;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    border: 1px solid rgb(223, 212, 212);
    padding-top: 15px;
    background-color: white;
  }
  .basicServicesListWrapper::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesDataList {
    display: flex;
    flex-direction: column;
    width: 96%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicDataListHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicDataListHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicDataListHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicDataListHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
  }
}
@media only screen and (min-width:2303px) {
  .basicDataListHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicDataHeaderServiceId {
    width: 10%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicDataHeaderServiceId {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicDataHeaderServiceId {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicDataHeaderServiceId {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .basicDataHeaderServiceId {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicDataHeaderBenaemn {
    width: calc(75% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicDataHeaderBenaemn {
    width: calc(75% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicDataHeaderBenaemn {
    width: calc(75% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicDataHeaderBenaemn {
    width: calc(75% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .basicDataHeaderBenaemn {
    width: calc(75% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicDataHeaderPrice {
    width: calc(20% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicDataHeaderPrice {
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicDataHeaderPrice {
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicDataHeaderPrice {
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .basicDataHeaderPrice {
    width: calc(15% - 2px);
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesData {
    margin-top: 2px;
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesData {
    margin-top: 2px;
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesData {
    margin-top: 2px;
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesData {
    margin-top: 2px;
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesData {
    margin-top: 2px;
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 6px;
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesListId {
    width: 10%;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesListId {
    width: 10%;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesListId {
    width: 10%;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesListId {
    width: 10%;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesListId {
    width: 10%;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
}

.basicServicesListBenaemn {
  width: calc(75% - 2px);
  margin-left: 2px;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesListPrice {
    width: calc(15% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesListPrice {
    width: calc(25% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesListPrice {
    width: calc(22% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesListPrice {
    width: calc(22% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesListPrice {
    width: calc(22% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesListBenaemn {
    transition: transform 0.5s ease;
    transform-origin: top;
    width: calc(70% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesListBenaemn {
    transition: transform 0.5s ease;
    transform-origin: top;
    width: calc(70% - 2px);
    margin-left: 2px;
    margin-right: 10px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    overflow-wrap: break-word;
    white-space: normal;
    flex: 1 1 auto;
    min-width: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesListBenaemn {
    transition: transform 0.5s ease;
    transform-origin: top;
    width: calc(70% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesListBenaemn {
    transition: transform 0.5s ease;
    transform-origin: top;
    width: calc(70% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesListBenaemn {
    transition: transform 0.5s ease;
    transform-origin: top;
    width: calc(70% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .basicServicesBaseInfo {
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .basicServicesBaseInfo {
    width: 100%;
    font-family: var(--font-family);
    font-size: 15px;
    border-radius: 3px;
    padding: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .basicServicesBaseInfo {
    width: 100%;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .basicServicesBaseInfo {
    width: 100%;
    font-family: var(--font-family);
    font-size: 16px;
    border-radius: 3px;
    padding: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .basicServicesBaseInfo {
    width: 100%;
    font-family: var(--font-family);
    font-size: 24px;
    border-radius: 3px;
    padding: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tjansterInfoAnimated {
    transform: rotate(0deg);
    animation-name: tjanster55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    height: 20vh;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .tjansterInfoAnimated::-webkit-scrollbar {
    width: 3px;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-track {
    background: gold;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tjansterInfoAnimated {
    transform: rotate(0deg);
    animation-name: tjanster55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    height: 25vh;
    max-height: 25vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    border-radius: 6px;
    border: 2px solid rgb(223, 212, 212);
  }
  .tjansterInfoAnimated::-webkit-scrollbar {
    width: 3px;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-track {
    background: white;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tjansterInfoAnimated {
    transform: rotate(0deg);
    animation-name: tjanster55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    height: 20vh;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    border-radius: 6px;
    border: 2px solid rgb(223, 212, 212);
  }
  .tjansterInfoAnimated::-webkit-scrollbar {
    width: 3px;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-track {
    background: white;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tjansterInfoAnimated {
    transform: rotate(0deg);
    animation-name: tjanster55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    height: 20vh;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    border-radius: 6px;
    border: 2px solid rgb(223, 212, 212);
  }
  .tjansterInfoAnimated::-webkit-scrollbar {
    width: 3px;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-track {
    background: white;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}
@media only screen and (min-width:2303px) {
  .tjansterInfoAnimated {
    transform: rotate(0deg);
    animation-name: tjanster55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    height: 20vh;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
    border-radius: 6px;
    border: 2px solid rgb(223, 212, 212);
  }
  .tjansterInfoAnimated::-webkit-scrollbar {
    width: 3px;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-track {
    background: white;
  }
  .tjansterInfoAnimated::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}

@keyframes tjanster55 {
  0% {
    height: 0px;
  }
  100% {
    height: 100px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoFaqWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(234, 213, 186);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoFaqWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoFaqWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoFaqWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}
@media only screen and (min-width:2303px) {
  .infoFaqWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoFaqHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lobster;
    font-size: 20px;
    color: black;
    margin-top: 2.5vh;
    width: 97%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoFaqHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lobster;
    font-size: 22px;
    color: black;
    margin-top: 4vh;
    width: 97%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoFaqHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lobster;
    font-size: 26px;
    color: black;
    margin-top: 4vh;
    width: 97%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoFaqHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lobster;
    font-size: 26px;
    color: black;
    margin-top: 4vh;
    width: 97%;
  }
}
@media only screen and (min-width:2303px) {
  .infoFaqHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lobster;
    font-size: 26px;
    color: black;
    margin-top: 4vh;
    width: 97%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoFaqHeaderJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: black;
    margin-top: 0.5vh;
    width: 97%;
    font-family: var(--font-family);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoFaqHeaderJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: black;
    margin-top: 0.5vh;
    width: 97%;
    font-family: var(--font-family);
    margin-top: 1vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infofaqDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infofaqDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 7%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infofaqDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 1vh;
    margin-left: 7%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infofaqDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 7%;
    margin-top: 1vh;
  }
}
@media only screen and (min-width:2303px) {
  .infofaqDataList {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 1vh;
    margin-left: 7%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infofaqData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2px;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infofaqData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 8px;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infofaqData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 8px;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infofaqData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 8px;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .infofaqData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 8px;
    align-items: center;
    width: 90%;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infofaqId {
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .infofaqId {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infofaqId {
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infofaqId {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infofaqId {
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .infofaqId {
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infofaqQuestion {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    height: auto;
    margin-top: 5px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .infofaqQuestion {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infofaqQuestion {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    height: auto;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infofaqQuestion {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    height: auto;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infofaqQuestion {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    height: auto;
  }
}
@media only screen and (min-width:2303px) {
  .infofaqQuestion {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    height: auto;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infofaqAnswer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    height: auto;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infofaqAnswer::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infofaqAnswer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    height: auto;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infofaqAnswer::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infofaqAnswer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    height: auto;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infofaqAnswer::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infofaqAnswer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    height: auto;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infofaqAnswer::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .infofaqAnswer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70%;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    height: auto;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infofaqAnswer::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infofaqAnimated {
    transform: rotate(0deg);
    animation-name: faqinfo55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding: 2px;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infofaqAnimated {
    transform: rotate(0deg);
    animation-name: faqinfo55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding: 2px;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infofaqAnimated {
    transform: rotate(0deg);
    animation-name: faqinfo55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding: 2px;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infofaqAnimated {
    transform: rotate(0deg);
    animation-name: faqinfo55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding: 2px;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .infofaqAnimated {
    transform: rotate(0deg);
    animation-name: faqinfo55;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding: 2px;
    border: 1px solid rgb(223, 212, 212);
    width: 100%;
    font-family: var(--font-family);
    font-size: 20px;
  }
}

@keyframes faqinfo55 {
  0% {
    height: 0px;
  }
  100% {
    height: 100px;
  }
}
.infoSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.infoSpinner {
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .payPalWrapper {
    width: 90%;
    background-color: transparent;
    height: 85vh;
    max-height: 85vh;
    margin-top: 1vh;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .payPalWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .payPalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: transparent;
    height: 85vh;
    max-height: 85vh;
    margin-top: 1vh;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .payPalWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .payPalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    background-color: transparent;
    height: 85vh;
    max-height: 85vh;
    margin-top: 1vh;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .payPalWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .payPalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    background-color: transparent;
    height: 85vh;
    max-height: 85vh;
    margin-top: 1vh;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .payPalWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .payPalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    background-color: transparent;
    height: 85vh;
    max-height: 85vh;
    margin-top: 1vh;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .payPalWrapper::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .payPalBakesoftHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    height: 25px;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}

.ppBakesoftListWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  margin-top: 3px;
  margin-bottom: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .payPalBakesoftHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .payPalBakesoftHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .payPalBakesoftHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .payPalBakesoftHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .payPalBakesoftHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .payPalBakesoftHeader {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .payPalBakesoftHeader {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .payPalBakesoftHeader {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .payPalBakesoftHeader {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: bold;
  }
}
@media only screen and (min-width:2303px) {
  .payPalBakesoftHeader {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ppBakesoftTextWrapper {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 5px;
    border: 2px dotted grey;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ppBakesoftTextWrapper {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 5px;
    border: 2px dotted grey;
    width: 50%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ppBakesoftTextWrapper {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 5px;
    border: 2px dotted grey;
    width: 60%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ppBakesoftTextWrapper {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 5px;
    border: 2px dotted grey;
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .ppBakesoftTextWrapper {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 5px;
    border: 2px dotted grey;
    width: 60%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ppBakesofttext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    height: 25px;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
    padding-top: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ppBakesofttext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    height: 25px;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ppBakesofttext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    height: 25px;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ppBakesofttext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    height: 25px;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .ppBakesofttext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    height: 25px;
    width: 100%;
    font-family: var(--font-family);
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .payPalButtonWrapper {
    width: 100%;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .payPalButtonWrapper {
    width: 50%;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .payPalButtonWrapper {
    width: 60%;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .payPalButtonWrapper {
    width: 60%;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width:2303px) {
  .payPalButtonWrapper {
    width: 60%;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}

.paymentOptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  width: 100%;
}

.payPalInitialButtonInfo {
  width: 60%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  background-color: white;
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  cursor: pointer;
  border: none;
  color: #F9F8F5;
  outline: none;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .payPalInitialButtonInfo {
    width: 50%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .payPalInitialButtonInfo {
    width: 30%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .payPalInitialButtonInfo {
    width: 30%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .payPalInitialButtonInfo {
    width: 30%;
    height: 50px;
  }
}
@media only screen and (min-width:2303px) {
  .payPalInitialButtonInfo {
    width: 30%;
    height: 80px;
  }
}

.payPalMessageInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: var(--font-family);
  font-size: 13px;
  margin-top: 1vh;
  margin-bottom: 3px;
  color: black;
}
@media only screen and (min-width:2303px) {
  .payPalMessageInfo {
    font-size: 26px;
  }
}

.paypallogoWrapperInfo {
  width: 40%;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media only screen and (min-width:2303px) {
  .paypallogoWrapperInfo {
    height: 80px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .payPalButtonImageInfo {
    width: 70%;
    height: auto;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .payPalButtonImageInfo {
    width: 60%;
    height: auto;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .payPalButtonImageInfo {
    width: 70%; /* Adjust the size as needed */
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .payPalButtonImageInfo {
    width: 60%; /* Adjust the size as needed */
    height: auto;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .payPalButtonImageInfo {
    height: 40px;
    width: 100px;
  }
}
@media only screen and (min-width:2303px) {
  .payPalButtonImageInfo {
    height: 70px;
    width: 140px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .finalPaymentInfoTopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .finalPaymentInfoTopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .finalPaymentInfoTopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .finalPaymentInfoTopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}
@media only screen and (min-width:2303px) {
  .finalPaymentInfoTopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}

.finalPaymentMessageInfo {
  font-size: 20px;
  font-family: var(--font-family);
  text-decoration: none;
  color: black;
}

.finalPaymentLinkInfo {
  font-size: 20px;
  font-family: var(--font-family);
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .slidingMenuTopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;
    margin-top: 3vh;
  }
}

.sliding-menu {
  position: fixed;
  top: 0;
  right: -280px; /* Adjust width as needed */
  width: 300px;
  height: 2vh;
  background-color: transparent;
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
  margin-top: 20vh;
}

.slidingMenuIcon {
  width: 25px;
  height: 25px;
  color: black;
}

.sliding-menu.open {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 2vh;
  margin-top: 20vh;
  right: 0;
}
.sliding-menu.open .close-button {
  position: absolute;
  top: 20px;
  right: 22px;
}

.sliding-menu.partially-open {
  right: -50px; /* Adjust to show a portion of the menu */
}
.sliding-menu.partially-open .close-button {
  position: absolute;
  top: 25px;
  right: 62px;
}

.close-button {
  position: absolute;
  top: 40px;
  right: 30px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
@media (max-width: 370px) {
  .close-button {
    position: absolute;
    top: 25px;
    right: 62px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
}
@media (min-width: 371px) {
  .close-button {
    position: absolute;
    top: 20px;
    right: 60px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoHeadermenuButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 30px;
    margin-top: 3px;
    border: none;
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .slidingMenuWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid rgb(223, 212, 212);
    box-shadow: 12px 12px 15px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    padding: 10px;
    width: 80%;
    background-color: white;
    border: 1px solid grey;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .slidingMenuButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-top: 3px;
    background-color: white;
    border: none;
    font-size: 14px;
    font-family: var(--font-family);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .menuCountryFlagWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    height: auto;
    margin-top: 2vh;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoCountryFlagWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoCountryFlagWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoCountryFlagWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoCountryFlagWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3vh;
  }
}

.slidingMenuPaymentPartners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}
@media (min-width: 359px) and (max-width: 361px) {
  .slidingMenuPaymentPartners {
    margin-top: 2vh;
  }
}

.slidingMenuPaymentHeader {
  font-size: 14px;
  font-family: var(--font-family);
  margin-bottom: 1vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .slidingMenuKlarnaPartner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2vh;
    margin-top: 11vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 359px) and (max-width: 361px) {
  .slidingMenuKlarnaPartner {
    margin-top: 4vh;
    width: 80%;
    margin-top: 15vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .slidingMenuKlarnaPartnerPromo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 40%;
    height: 2vh;
    margin-top: 3vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .slidingMenuPayPalPartner {
    width: 100%;
    height: 2vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
  }
}
@media only screen and (min-width:2303px) {
  .infoPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoPaymentPayPal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoPaymentKlarna {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoPaymentKlarna {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoPaymentKlarna {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoPaymentKlarna {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 25px;
  }
}
@media only screen and (min-width:2303px) {
  .infoPaymentKlarna {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .integrityPolicyTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .integrityPolicyTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .integrityPolicyTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .integrityPolicyTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width:2303px) {
  .integrityPolicyTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .integrityPolicyHeaderText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 19px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .integrityPolicyHeaderText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .integrityPolicyHeaderText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 24px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .integrityPolicyHeaderText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 24px;
    margin-top: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .integrityPolicyHeaderText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 20px;
    margin-top: 4vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .integrityPolicyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 30vh;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    width: 85%;
    height: 50vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 15vh;
    margin-top: 10vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .integrityPolicyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 30vh;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    width: 85%;
    height: 45vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 20vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .integrityPolicyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    width: 85%;
    height: 35vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 15vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .integrityPolicyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    width: 85%;
    height: 40vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 15vh;
  }
}
@media only screen and (min-width:2303px) {
  .integrityPolicyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(223, 212, 212);
    border-radius: 12px;
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    width: 85%;
    height: 50vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 15vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .integrityPolicyDoc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .integrityPolicyDoc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .integrityPolicyDoc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .integrityPolicyDoc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .integrityPolicyDoc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 20px;
    margin-top: 2vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .integrityPolicyBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .integrityPolicyBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .integrityPolicyBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .integrityPolicyBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .integrityPolicyBackgroundImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: b;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactInfoTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vh;
    margin-top: 3vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 20px;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoContactInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 20px;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1200px) {
  .infoContactInfoHeader {
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoContactInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 22px;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoContactInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 22px;
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .infoContactInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 20px;
    margin-top: 1px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 60vh;
    border-radius: 6px;
    margin-top: 2vh;
    padding-top: 14vh;
    border: 1px solid rgb(223, 212, 212);
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoContactInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 55vh;
    border-radius: 6px;
    margin-top: 2px;
    padding-top: 14vh;
    border: 1px solid rgb(223, 212, 212);
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1200px) {
  .infoContactInfoWrapper {
    width: 80%;
    height: 56vh;
    margin-top: 1px;
    padding-top: 10vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoContactInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 40vh;
    border-radius: 6px;
    margin-top: 1px;
    padding-top: 14vh;
    border: 1px solid rgb(223, 212, 212);
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoContactInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 40vh;
    border-radius: 6px;
    margin-top: 1px;
    padding-top: 14vh;
    border: 1px solid rgb(223, 212, 212);
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width:2303px) {
  .infoContactInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 50vh;
    border-radius: 6px;
    margin-top: 1px;
    padding-top: 14vh;
    border: 1px solid rgb(223, 212, 212);
    box-shadow: 12px 12px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.8);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoContactInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1200px) {
  .infoContactInfoText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoContactInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoContactInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 20px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .infoContactInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 26px;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactInfoTextJapan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    height: auto;
    font-family: var(--font-family);
    font-size: 12px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoContactInfoTextJapan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1200px) {
  .infoContactInfoTextJapan {
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactEmailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 12px;
    border-color: rgb(223, 212, 212);
    width: 95%;
    padding-bottom: 12px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1vh;
  }
  .infoContactEmailWrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgb(175, 159, 159);
    outline: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoContactEmailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 12px;
    border-color: rgb(223, 212, 212);
    width: 60%;
    padding-bottom: 12px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1vh;
  }
  .infoContactEmailWrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgb(175, 159, 159);
    outline: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoContactEmailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 12px;
    border-color: rgb(223, 212, 212);
    width: 60%;
    padding-bottom: 12px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1vh;
  }
  .infoContactEmailWrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgb(175, 159, 159);
    outline: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoContactEmailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 12px;
    border-color: rgb(223, 212, 212);
    width: 60%;
    padding-bottom: 12px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1vh;
  }
  .infoContactEmailWrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgb(175, 159, 159);
    outline: none;
  }
}
@media only screen and (min-width:2303px) {
  .infoContactEmailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 12px;
    border-color: rgb(223, 212, 212);
    width: 60%;
    padding-bottom: 12px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1vh;
  }
  .infoContactEmailWrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgb(175, 159, 159);
    outline: none;
  }
}

.infoContactEmailIcon {
  width: 30px;
  height: 30px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactEmailIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoContactEmailIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoContactEmailIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoContactEmailIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .infoContactEmailIcon {
    width: 20px;
    height: 20px;
  }
}

.infoContactEmailLink {
  font-size: 20px;
  font-family: var(--font-family);
  color: black;
  text-decoration: none;
}
.infoContactEmailLink:hover, .infoContactEmailLink:visited, .infoContactEmailLink:active {
  color: red;
}
@media (min-width: 1170px) {
  .infoContactEmailLink {
    font-size: 14px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoContactEmailLink {
    font-size: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBuyingTermsText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBuyingTermsText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin-top: 5px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBuyingTermsText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin-top: 5px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBuyingTermsText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin-top: 5px;
    padding: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .infoBuyingTermsText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin-top: 5px;
    padding: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBuyingTermsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
    border: 2px solid rgb(223, 212, 212);
    margin-top: 1vh;
    height: 79vh;
    max-height: 79vh;
    overflow-y: scroll;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBuyingTermsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    border: 2px solid rgb(223, 212, 212);
    height: 75vh;
    max-height: 75vh;
    overflow-y: scroll;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBuyingTermsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 63vh;
    max-height: 63vh;
    border: 1px solid rgb(223, 212, 212);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infoBuyingTermsWrapper ::-webkit-scrollbar {
    width: 10px;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar-track {
    background: beige;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBuyingTermsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 90vh;
    border: 2px solid rgb(223, 212, 212);
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infoBuyingTermsWrapper ::-webkit-scrollbar {
    width: 10px;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar-track {
    background: beige;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}
@media only screen and (min-width:2303px) {
  .infoBuyingTermsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 90vh;
    border: 2px solid rgb(223, 212, 212);
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .infoBuyingTermsWrapper ::-webkit-scrollbar {
    width: 10px;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar-track {
    background: beige;
  }
  .infoBuyingTermsWrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: grey;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .buyingTermstransparentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 90vh;
    margin-top: 2vh;
    border-radius: 12px;
    z-index: 2;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .buyingTermstransparentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    height: 90vh;
    margin-top: 2vh;
    border-radius: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .buyingTermstransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 90vh;
    margin-top: 2vh;
    border-radius: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .buyingTermstransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 44%;
    height: 80vh;
    margin-left: 3%;
    margin-top: 2vh;
    border-radius: 12px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .buyingTermstransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 50%;
    height: 80vh;
    margin-top: 2vh;
    border-radius: 12px;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoBuyingTermsHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 20px;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoBuyingTermsHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 24px;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoBuyingTermsHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 26px;
    margin-top: 3vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoBuyingTermsHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 26px;
    margin-top: 3vh;
  }
}
@media only screen and (min-width:2303px) {
  .infoBuyingTermsHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: "Lobster";
    font-size: 26px;
    margin-top: 3vh;
  }
}

.allmant-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .allmant-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .allmant-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.6;
    height: auto;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .allmant-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .allmant-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .allmant-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .allmant-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .allmant-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .allmant-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .allmant-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .allmant-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .allmant-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .allmant-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .allmant-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .allmant-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .allmant-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.bestallning-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bestallning-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bestallning-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bestallning-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bestallning-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .bestallning-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bestallning-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bestallning-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bestallning-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bestallning-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .bestallning-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bestallning-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bestallning-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bestallning-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bestallning-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .bestallning-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.bestallning-list {
  list-style: none;
  padding: 0;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bestallning-list li {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bestallning-list li {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bestallning-list li {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bestallning-list li {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bestallning-list li {
    font-size: 20px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bestallning-subheading {
    font-size: 16px;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bestallning-subheading {
    font-size: 18px;
    margin-top: 1.5rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bestallning-subheading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bestallning-subheading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .bestallning-subheading {
    font-size: 28px;
  }
}

.priser-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .priser-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .priser-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .priser-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .priser-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .priser-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .priser-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .priser-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .priser-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .priser-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .priser-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .priser-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .priser-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .priser-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .priser-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .priser-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.priser-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .priser-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .priser-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .priser-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .priser-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .priser-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .priser-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .priser-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .priser-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .priser-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .priser-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .priser-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .priser-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .priser-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .priser-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .priser-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.priser-list {
  list-style: none;
  padding: 0;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .priser-list li {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .priser-list li {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .priser-list li {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .priser-list li {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .priser-list li {
    font-size: 20px;
  }
}

.betalning-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .betalning-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .betalning-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .betalning-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .betalning-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .betalning-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .betalning-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .betalning-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .betalning-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .betalning-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .betalning-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .betalning-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .betalning-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .betalning-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .betalning-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .betalning-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.betalning-list {
  list-style: none;
  padding: 0;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .betalning-list li {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .betalning-list li {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .betalning-list li {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .betalning-list li {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .betalning-list li {
    font-size: 20px;
  }
}

.leverans-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .leverans-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .leverans-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .leverans-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .leverans-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .leverans-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .leverans-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .leverans-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .leverans-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .leverans-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .leverans-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .leverans-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .leverans-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .leverans-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .leverans-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .leverans-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.angerratt-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .angerratt-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .angerratt-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .angerratt-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .angerratt-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .angerratt-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .angerratt-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .angerratt-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .angerratt-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .angerratt-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .angerratt-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .angerratt-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .angerratt-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .angerratt-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .angerratt-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .angerratt-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.reklamation-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .reklamation-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .reklamation-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .reklamation-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .reklamation-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .reklamation-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .reklamation-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .reklamation-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .reklamation-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .reklamation-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .reklamation-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .reklamation-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .reklamation-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .reklamation-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .reklamation-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .reklamation-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.force-majeure-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .force-majeure-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .force-majeure-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .force-majeure-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .force-majeure-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .force-majeure-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .force-majeure-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .force-majeure-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .force-majeure-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .force-majeure-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .force-majeure-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .force-majeure-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .force-majeure-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .force-majeure-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .force-majeure-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .force-majeure-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.tvist-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .tvist-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tvist-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tvist-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tvist-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .tvist-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tvist-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tvist-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tvist-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tvist-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .tvist-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .tvist-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .tvist-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .tvist-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .tvist-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .tvist-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

.personuppgifter-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .personuppgifter-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .personuppgifter-section {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .personuppgifter-section {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .personuppgifter-section {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .personuppgifter-section {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .personuppgifter-heading {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .personuppgifter-heading {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .personuppgifter-heading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .personuppgifter-heading {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .personuppgifter-heading {
    font-size: 28px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .personuppgifter-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .personuppgifter-paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .personuppgifter-paragraph {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .personuppgifter-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width:2303px) {
  .personuppgifter-paragraph {
    font-size: 20px;
    line-height: 1.5;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .signUptransparentBox {
    background-color: rgba(255, 255, 255, 0.8);
    width: 87%;
    z-index: 2;
    height: 90%;
    margin-top: 2vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .signUptransparentBox {
    background-color: rgba(255, 255, 255, 0.7);
    width: 80%;
    z-index: 2;
    height: 90%;
    margin-top: 2vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .signUptransparentBox {
    background-color: rgba(255, 255, 255, 0.7);
    width: 70%;
    z-index: 2;
    height: 90%;
    margin-top: 2vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .signUptransparentBox {
    background-color: rgba(255, 255, 255, 0.7);
    width: 60%;
    z-index: 2;
    height: 85%;
    margin-top: 2vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .signUptransparentBox {
    background-color: rgba(255, 255, 255, 0.7);
    width: 60%;
    z-index: 2;
    height: 80%;
    margin-top: 2vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ppKlarnaTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ppKlarnaTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-top: 2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ppKlarnaTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-top: 2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ppKlarnaTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin-top: 2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width:2303px) {
  .ppKlarnaTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin-top: 2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.infospfTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  height: 90vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfTopWrapper {
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfTopWrapper {
    width: 90%;
    height: 90vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfTopWrapper {
    width: 90%;
    height: 90vh;
  }
}
@media only screen and (min-width:2303px) {
  .infospfTopWrapper {
    width: 90%;
    height: 90vh;
  }
}

.infospfBoxWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfBoxWrapper {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infospfBoxWrapper {
    width: 100%;
  }
}

.infospfLeftBox {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 90vh;
  background-color: transparent;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfLeftBox {
    width: 55%;
    height: 90vh;
    border-radius: 8px;
    background-color: transparent;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfLeftBox {
    width: 60%;
    height: 90vh;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 1px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfLeftBox {
    width: 60%;
    height: 90vh;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 1px;
  }
}
@media only screen and (min-width:2303px) {
  .infospfLeftBox {
    width: 40%;
    height: 90vh;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 1px;
  }
}

.infospfLeftBoxFull {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  border-radius: 8px;
  box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  background-color: rgb(234, 213, 186);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfLeftBoxFull {
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfLeftBoxFull {
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfLeftBoxFull {
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}
@media only screen and (min-width:2303px) {
  .infospfLeftBoxFull {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    background-color: rgb(234, 213, 186);
  }
}

.infospfHeaderText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6vh;
  font-size: 26px;
  font-weight: bold;
  font-family: var(--font-family);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfHeaderText {
    font-size: 22px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfHeaderText {
    font-size: 26px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfHeaderText {
    font-size: 26px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;
  }
}
@media only screen and (min-width:2303px) {
  .infospfHeaderText {
    font-size: 26px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;
  }
}

.infospfRightBox {
  width: 58%;
  height: 90vh;
  margin-left: 5px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfRightBox {
    width: 55%;
    height: 90vh;
    max-height: 90vh;
    overflow-y: auto;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6vh;
  }
  .infospfRightBox::-webkit-scrollbar {
    width: 10px;
  }
  .infospfRightBox::-webkit-scrollbar-track {
    background: white;
    border: 1px solid grey;
  }
  .infospfRightBox::-webkit-scrollbar-thumb {
    background: lightblue;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfRightBox {
    width: 58%;
    height: 90vh;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 1px;
    padding-top: 6vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfRightBox {
    width: 59%;
    height: 90vh;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 1px;
    padding-top: 6.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .infospfRightBox {
    width: 49%;
    height: 90vh;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 1px;
    padding-top: 6vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.infospfRightBoxSmall {
  width: 1px;
}

.infospfServicesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfServicesWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfServicesWrapper {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .infospfServicesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.infospfServicesTop {
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfServicesTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfServicesTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfServicesTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .infospfServicesTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.infospfServicesBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 10vh;
  border-radius: 8px;
  border: 1px solid rgb(213, 182, 153);
  margin-bottom: 3px;
  background-color: white;
  margin-bottom: 1vh;
  box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
}
.infospfServicesBox.selected {
  border: 3px solid;
  border-color: green;
  height: 11vh;
  transition: all 0.6s;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4);
}
.infospfServicesBox.selected .infospfCheckboxCircle {
  font-weight: 600;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfServicesBox {
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfServicesBox {
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfServicesBox {
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .infospfServicesBox {
    width: 90%;
  }
}

.infospfInsideBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.infospfInsideBoxText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25%;
  font-size: 16px;
  font-family: var(--font-family);
}

.infospfInsideBoxTextA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 94%;
  height: 25%;
  font-size: 16px;
  font-family: var(--font-family);
  color: grey;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfInsideBoxTextA {
    font-size: 12px;
  }
}

.infospfInsideBoxTextB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 25%;
  font-size: 16px;
  font-family: var(--font-family);
  cursor: pointer;
}

.infospfInsideBoxTextC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 95%;
  height: 60%;
  font-size: 18px;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 2vh;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfInsideBoxTextC {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfInsideBoxTextC {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfInsideBoxTextC {
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .infospfInsideBoxTextC {
    font-size: 18px;
  }
}

.infospfCheckboxCircle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  height: 20%;
  margin-bottom: 3px;
  font-size: 24px;
  font-family: var(--font-family);
  font-weight: 400;
  margin-top: 1vh;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfCheckboxCircle {
    font-size: 16px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfCheckboxCircle {
    font-size: 20px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfCheckboxCircle {
    font-size: 18px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .infospfCheckboxCircle {
    font-size: 24px;
    margin-top: 2vh;
  }
}

.infospfCheckCircle {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  color: green;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfCheckCircle {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    color: green;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfCheckCircle {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfCheckCircle {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .infospfCheckCircle {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}

.infospfCheckCircleFail {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  color: red;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfCheckCircleFail {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    color: red;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfCheckCircleFail {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: red;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfCheckCircleFail {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: red;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .infospfCheckCircleFail {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    color: red;
    margin-top: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .infospfCheckIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    color: green;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfCheckIcon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    color: green;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .infospfCheckIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    color: green;
    margin-top: 3px;
  }
}

.infospfList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  height: 50%;
  font-size: 16px;
  font-family: var(--font-family);
  color: grey;
  cursor: pointer;
  margin-top: 5px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfList {
    font-size: 14px;
  }
}

.infospfListHeaderThick {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  height: 50%;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: bold;
  color: black;
  cursor: pointer;
  margin-top: 5px;
  font-weight: 600;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfListHeaderThick {
    font-size: 14px;
  }
}

.infospfTextRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 20%;
  font-size: 16px;
  font-family: var(--font-family);
  color: black;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfTextRow {
    font-size: 14px;
  }
}

.infospfSelectAreaWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infospfselect {
  width: 95%;
  padding: 0.7vh 1vh;
  font-size: 14px;
  font-family: var(--font-family);
  color: #333;
  border: 1px solid rgb(234, 213, 186);
  border-radius: 6px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; /* Remove default select arrow */
  background-color: white;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KPHBhdGggZD0iTTEgNEw3IDlMMTMsNCIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiLz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  transition: border-color 0.3s ease;
  cursor: pointer;
  margin-top: 3px;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.4);
}
.infospfselect:focus {
  outline: none;
  border-color: #888;
}
.infospfselect:hover {
  border-color: #888;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfselect {
    width: 90%;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfselect {
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfselect {
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .infospfselect {
    width: 90%;
    padding: 10px 14px;
  }
}

.infoprecashTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
}

.infoprecashWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99%;
  height: 88vh;
  border: 1px solid blue;
  margin-left: 2px;
}

.infoprecashTextRowWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 40vh;
  border: 1px solid red;
  border-radius: 12px;
  margin-left: 2px;
  margin-top: 2vh;
}

.infoprecashTextRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  height: 2vh;
  font-size: 20px;
  font-family: var(--font-family);
  color: black;
  cursor: pointer;
  margin-top: 2vh;
}

.infoprecashPayInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2vh;
}

.signUpTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
  margin-top: 2vh;
}

.signUpInputHeight {
  height: 48px;
}

.signupwithimage {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.signupimageTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.signupnameTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 100;
}

.signupemailTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 100;
}

.signuptoverifyTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .signuptoverifyTop {
    width: 100%;
    height: 90vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .signuptoverifyTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .signuptoverifyTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .signuptoverifyTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .signuptoverifyTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    border-radius: 8px;
  }
}

.infospfCardHolder {
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infospfCardHolder {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infospfCardHolder {
    width: 100;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infospfCardHolder {
    width: 100;
  }
}
@media only screen and (min-width:2303px) {
  .infospfCardHolder {
    width: 100;
  }
}

.infoppDetailsTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoppDetailsTop {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoppDetailsTop {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infoppDetailsTop {
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .infoppDetailsTop {
    width: 60%;
  }
}

.infoppMessageBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70vh;
  height: 60vh;
  margin-top: 2vh;
  margin-top: 4vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoppMessageBoxWrapper {
    width: 100%;
  }
}

.infoppMessageBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 40vh;
  border-radius: 12px;
  box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: 2vh;
  margin-top: 4vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoppMessageBox {
    width: 95%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoppMessageBox {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoppMessageBox {
    width: 60%;
  }
}

.infoppMessageBoxFail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 40vh;
  border-radius: 12px;
  border: 1px dotted red;
  box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: 2vh;
  margin-top: 4vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infoppMessageBoxFail {
    width: 95%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infoppMessageBoxFail {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infoppMessageBoxFail {
    width: 60%;
  }
}

.infosppHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: bold;
  color: green;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infosppHeader {
    font-size: 16px;
  }
}

.infosppConfirmMessageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infosppConfirmMessageWrapper {
    width: 90%;
    margin-top: 1px;
  }
}

.infosppConfirmMessage {
  font-size: 12px;
  font-family: var(--font-family);
}

.infosppConfirmMessageFail {
  font-size: 14px;
  font-family: var(--font-family);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infosppConfirmMessageFail {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infosppConfirmMessageFail {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infosppConfirmMessageFail {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infosppConfirmMessageFail {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .infosppConfirmMessageFail {
    font-size: 20px;
  }
}

.infosppHeaderText {
  font-size: 20px;
  font-weight: bold;
  font-family: var(--font-family);
  color: green;
  margin-left: 1vw;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infosppHeaderText {
    font-size: 22px;
  }
}

.infosppHeaderTextFail {
  font-size: 20px;
  font-weight: bold;
  font-family: var(--font-family);
  color: red;
  margin-left: 1vw;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infosppHeaderTextFail {
    font-size: 22px;
  }
}

.infosppLoginIcon {
  color: green;
  font-size: 40px;
  cursor: pointer;
  margin-top: 3vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infosppLoginIcon {
    font-size: 28px;
    margin-top: 3vh;
  }
}

.infosppStripeConfirmBoxWrapper {
  background-color: white; /* Light grey background */
  padding: 20px;
  border-radius: 8px;
  max-width: 40vw;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.6); /* Adds a shadow */
  height: auto;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infosppStripeConfirmBoxWrapper {
    margin-top: 2vh;
    height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    max-width: 80vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infosppStripeConfirmBoxWrapper {
    margin-top: 2vh;
    height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    border: 1px solid rgb(223, 212, 212);
    background-color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infosppStripeConfirmBoxWrapper {
    margin-top: 2vh;
    height: auto;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infosppStripeConfirmBoxWrapper {
    margin-top: 5vh;
    height: auto;
    width: 40%;
  }
}
@media only screen and (min-width:2303px) {
  .infosppStripeConfirmBoxWrapper {
    margin-top: 5vh;
    height: auto;
    width: 35%;
  }
}

.infosppStripeConfirmBox {
  background-color: white; /* Light blue background for the confirmation box */
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infosppConfirmBoxHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 3vh;
  margin-bottom: 1vh;
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-familyNoto);
  text-align: center !important;
  border-radius: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1.2vw;
  padding-right: 1.2vw;
  border: 1px solid rgb(223, 212, 212);
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.infospfConfirmBoxTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.infospfConfirmBoxText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 300;
  font-family: var(--font-family);
  margin-top: 2vh;
}

.infospfConfirmBoxTextSmall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  font-family: var(--font-family);
  margin-top: 3px;
}

.infospfConfirmBoxTextSmallBold {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  font-family: var(--font-family);
  margin-top: 3px;
}

.infocompleteTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infocompleteMessage {
  font-size: 20px;
  font-family: var(--font-family);
}

.infocompleteMessageEmail {
  font-size: 18px;
  font-family: var(--font-family);
  font-weight: 200;
  border-radius: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1.2vw;
  padding-right: 1.2vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocompleteMessageEmail {
    font-size: 16px;
  }
}

.infocompleteLogin {
  margin-top: 2vh;
}

.infocompleteLoginIcon {
  color: green;
  font-size: 30px;
  cursor: pointer;
}

.infocompleteWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  width: 80%;
  height: 30vh;
  background-color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocompleteWrapper {
    width: 100%;
  }
}

.infosppConfirmEmailMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infosppEmailIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infopre-container {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1vh;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3; /* Adjust as needed */
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.6);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infopre-container {
    width: 90%;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infopre-container {
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infopre-container {
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infopre-container {
    width: 85%;
  }
}
@media only screen and (min-width:2303px) {
  .infopre-container {
    width: 80%;
  }
}

.infopre-card {
  width: 100%;
  max-width: 768px; /* Default max width */
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infopre-card {
    padding: 2px;
  }
}

.infopre-header, .infopre-footer {
  text-align: center;
  border: 1px solid rgb(223, 212, 212);
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infopre-header, .infopre-footer {
    padding: 2px;
    border: none;
    box-shadow: none;
  }
}

.infopre-title {
  font-size: 1.5rem;
  font-weight: bold;
}
@media (min-width: 640px) {
  .infopre-title {
    font-size: 1.875rem;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infopre-title {
    font-size: 1.5rem;
    padding: 1px;
  }
}

.infopre-payment-label {
  font-size: 16px;
  font-weight: bold;
  font-family: var(--font-family);
}

.infopre-signup-image-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-style: bold;
  font-family: var(--font-family);
}

.infopre-signup-image {
  width: 1.75rem;
  height: 1.75rem;
  margin-left: 0.5rem;
  border-radius: 50%;
}

.infopre-signup-name-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 200;
  font-family: var(--font-family);
}

.infopre-signup-email-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 200;
  font-family: var(--font-family);
}

.infopre-plan-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 600;
  font-family: var(--font-family);
}

.infopre-content {
  margin: 1.5rem 0;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infopre-content {
    margin: 0.5rem 0;
  }
}

.infopre-plan-details, .infopre-payment-details {
  margin-bottom: 5px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infopre-plan-details, .infopre-payment-details {
    margin-bottom: 1px;
  }
}

.infopre-plan-card {
  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infopre-plan-card {
    padding: 0.5rem;
  }
}

.infopre-plan-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
}

.infopre-icon {
  margin-right: 0.5rem;
}

.infopre-plan-text, .infopre-discount-text, .infopre-total-info span {
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
}

.infopre-payment-text {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family);
  margin-top: 5px;
}

.infopre-payment-value {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family);
  margin-top: 5px;
}

.infopre-payment-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
}

.infopre-discount-info {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-family);
}

.infopre-total-info {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
}

.infopre-discount-value {
  color: #28a745; /* Green for discounts */
}

.infopre-button {
  width: 100%;
  padding: 0.75rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.infopre-button:hover {
  border: 4px solid black;
}
.infopre-button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.infocontinueTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
  margin-top: 5vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueTopWrapper {
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infocontinueTopWrapper {
    margin-top: 2vh;
  }
}

.infocontinueBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infocontinueBoxWrapper {
    height: 80vh;
  }
}

.infocontinueLoginBoxOuter {
  width: 60%;
  height: 70vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.4);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueLoginBoxOuter {
    width: 90%;
    height: 80vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infocontinueLoginBoxOuter {
    width: 80%;
    height: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infocontinueLoginBoxOuter {
    width: 70%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infocontinueLoginBoxOuter {
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .infocontinueLoginBoxOuter {
    width: 60%;
  }
}

.infocontinueLoginBox {
  width: 80%;
  height: 60vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueLoginBox {
    width: 90%;
    height: 70vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .infocontinueLoginBox {
    width: 85%;
    height: 70vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .infocontinueLoginBox {
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .infocontinueLoginBox {
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .infocontinueLoginBox {
    width: 80%;
  }
}

.infocontinueHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5vh;
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: bold;
  color: grey;
  font-weight: 300;
  margin-top: 3vh;
}

.infocontinueHeaderText, .infocontinueHeaderTextSmall {
  font-size: 20px;
  font-family: var(--font-family);
  color: grey;
  font-weight: 200;
  margin-bottom: 5px;
}

.infocontinueHeaderTextSmall {
  font-size: 16px;
}

.infocontinueHRWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infocontinueHR {
  height: 1px;
  background-color: grey;
  width: 60%;
  margin: 20px 0;
  border: none;
}

.infocontinueORText {
  font-size: 16px;
  font-family: var(--font-family);
  color: grey;
  font-weight: 200;
  margin-top: 15px;
}

.infocontinueMidWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50vh;
  margin-top: 3px;
}

.infocontinueSocialWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.infocontinueGoogleButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 40px;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  outline: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.infocontinueGoogleButton:hover {
  padding: 2px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueGoogleButton {
    width: 90%;
  }
}

.infocontinueGoogleIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.infocontinueGoogleText {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 500;
  color: black;
}

.infocontinueFacebookButton {
  background-color: #4267B2;
  color: white;
  width: 50%;
  margin-top: 5px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.infocontinueFacebookButton:hover {
  border: 2px solid #4267B2;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueFacebookButton {
    width: 90%;
  }
}

.infocontinueFacebookIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.infocontinueFacebookText {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 500;
  color: white;
}

.infocontinueLineButton {
  background-color: #06C755;
  color: white;
  font-size: 16px;
  width: 50%;
  margin-top: 5px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.infocontinueLineButton:hover {
  border: 2px solid #06C755;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueLineButton {
    width: 90%;
  }
}

.infocontinueLineIcon {
  height: 30px;
  width: 30px;
  margin-right: 5px;
}

.infocontinueLineText {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 500;
  color: white;
}

.infocontinueInput {
  width: 49%;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 6px;
  height: 35px;
  margin-top: 10px;
  font-size: 13px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueInput {
    width: 90%;
  }
}

.infocontinueButton, .infocontinueButton1 {
  width: 50%;
  height: 40px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: var(--font-family);
}
.infocontinueButton:hover, .infocontinueButton1:hover {
  border: 3px solid black;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  height: 50px;
  color: lightgreen;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .infocontinueButton, .infocontinueButton1 {
    width: 90%;
  }
}

.infocontinueButton1 {
  border: 3px solid red;
}
.infocontinueButton1:hover {
  border: 3px solid red;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  height: 50px;
  color: red;
  cursor: not-allowed;
}

.infoPayFormButton {
  width: 100%;
  height: 40px;
  background-color: blue;
  color: white;
  margin-top: 5px;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: var(--font-family);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
}
.infoPayFormButton:hover {
  border: 2px solid blue;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.6);
  height: 50px;
}

.infoSignUpMobileTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
  margin-top: 1vh;
}

.infoSignUpMobileServiceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.infoSignUpMobileSelectBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.infoSignUpMobileHeaderText {
  font-size: 16px;
  font-family: var(--font-family);
}

.infoSignUpMobileSelect {
  width: 95%;
  height: 30px;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
}

.infoSignUpMobileServiceInfo {
  margin-top: 5px;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  width: 94%;
  height: auto;
  border-radius: 12px;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.preCheckWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 93%;
  height: 65vh;
  margin-top: 1vh;
  border-radius: 8px;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  box-shadow: 10px 10px 12px rgba(0, 0, 0, 0.4);
}

.preCheckHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 97%;
  height: 2vh;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 1vh;
}

.preCheckHeader {
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: bold;
  padding: 1vh;
}

.preCheckImage {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 50%;
  margin-right: 5px;
  animation: moveLeftRight 2s ease-in-out; /* Adjust timing as needed */
}

.preCheckNameInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 91%;
  height: 6vh;
  margin-top: 1vh;
}

.preCheckHR {
  height: 1px;
  background-color: grey;
  width: 90%;
  margin: 5px 0;
  border: none;
}

.preCheckName {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 200;
  margin-top: 5px;
}

.preCheckmEmail {
  font-size: 10px;
  font-family: var(--font-family);
  font-weight: 200;
  margin-top: 1vh;
}

.preCheckMidWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 85%;
  margin-top: 1vh;
}

.preCheckOutMobileWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  height: 5vh;
  margin-top: 0.5vh;
}

.preCheckPlanLabel {
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 200;
  font-weight: bold;
  margin-top: 1vh;
  margin-bottom: 1px;
}

.preCheckPlanText {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 200;
}

.preCheckPlanTextBold {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: bold;
}

.preCheckPlanTextBoldGreen {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: bold;
  color: green;
}

.preCheckPayText {
  font-size: 18px;
  font-family: var(--font-family);
  font-weight: 200;
}

.preCheckIcon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.preCheckIconMid {
  width: 22px;
  height: 16px;
}

.preCheckIconLarge {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.preCheckMidSize {
  width: 100%;
  height: 10px;
}

.preCheckIconRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 2vh;
}

.preCheckRowWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 2.5vh;
}

.preCheckRowWrapperLow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  height: 2vh;
  gap: 3px;
  margin-top: 4px;
}

.preCheckColumnWrapperLow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 2vh;
  gap: 3px;
  margin-top: 4px;
}

.preCheckFootWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.preCheckPayWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
}

.preCheckButton {
  width: 100%;
  padding: 0.65rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.preCheckButton:hover {
  border: 4px solid black;
}
.preCheckButton:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.preCheckButtonArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(-100%); /* Move 20% to the left */
  }
  100% {
    transform: translateX(0); /* Move back to the original position */
  }
}
.acknowledgeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 87%;
  background-color: white;
  margin-top: 5px;
  border-radius: 12px;
  padding: 5px;
  border: 1px solid rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .acknowledgeWrapper {
    width: 92%;
    padding: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .acknowledgeWrapper {
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .acknowledgeWrapper {
    width: 78%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .acknowledgeWrapper {
    width: 58%;
  }
}
@media only screen and (min-width:2303px) {
  .acknowledgeWrapper {
    width: 88%;
  }
}

.acknowledgeCheckbox {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .acknowledgeCheckbox {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .acknowledgeCheckbox {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .acknowledgeCheckbox {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .acknowledgeCheckbox {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .acknowledgeCheckbox {
    width: 25px;
    height: 25px;
  }
}

.acknowledgeText {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 200;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .acknowledgeText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .acknowledgeText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .acknowledgeText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .acknowledgeText {
    font-size: 14px;
  }
}
@media only screen and (min-width:2303px) {
  .acknowledgeText {
    font-size: 16px;
  }
}

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #0055DE;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #0055DE;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}

.infoCheckoutButton {
  background-color: #0055DE;
  color: white;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 8px;
  margin-top: 1vh;
  cursor: pointer;
}

.infoCompleteContinueWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
  width: 50%;
  border-radius: 6px;
}

.infoCompleteContinueHeader {
  font-size: 16px;
  font-family: var(--font-family);
  margin-top: 1vh;
}

.infoCompleteContinueText {
  font-size: 16px;
  font-family: var(--font-family);
  margin-top: 1vh;
}/*# sourceMappingURL=info.css.map */