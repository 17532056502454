:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xitotalArea, .xitotalArea1 {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 71vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xitotalArea, .xitotalArea1 {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 71vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xitotalArea, .xitotalArea1 {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 71vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xitotalArea, .xitotalArea1 {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 71vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xitotalArea, .xitotalArea1 {
    display: grid;
    grid-template-rows: 3% 5% 70%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 71vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xitotalArea1 {
    background-color: rgb(245, 245, 237);
  }
}

@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xiareaA {
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 98.5%;
    height: 70vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xiareaA {
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 80%;
    height: 70vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xiareaA {
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 65%;
    height: 70vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xiareaA {
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 60%;
    height: 70vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xiareaA {
    grid-area: c;
    border: 1px solid rgb(223, 212, 212);
    width: 60%;
    height: 70vh;
    margin-left: 1px;
    background-color: var(--val);
    margin-top: 10px;
  }
}

.xibackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xiheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xiheaderArea {
    grid-area: a;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xiheaderArea {
    grid-area: a;
    height: 26px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xiheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xiheaderArea {
    grid-area: a;
    height: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xirangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xirangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xirangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xirangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xirangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xirange1, .xirange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xirange1, .xirange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xirange1, .xirange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xirange1, .xirange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xirange1, .xirange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xirange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xirange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xirange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xirange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xirange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xisearchTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1%;
}

.xisearchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 9px;
}

.xisearchHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-left: 5px;
  font-family: var(--font-family);
  font-size: 14px;
}

.xisearchFieldset {
  border: none;
  font-family: var(--font-family);
}

.xisearchLegend {
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
}

.xisearchInput {
  width: 200px;
  height: 75px;
  font-size: 14px;
  font-family: var(--font-family);
  border-radius: 6px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
}

.xisearchButton {
  width: 100px;
  height: 80px;
  font-size: 18px;
  font-family: var(--font-family);
  border-radius: 6px;
  outline: none;
  border: none;
  background-color: var(--rgbaVal);
  margin-left: 3px;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xisearchButton {
    width: 100px;
    height: 80px;
    font-size: 18px;
    font-family: var(--font-family);
    border-radius: 6px;
    outline: none;
    border: none;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    cursor: pointer;
  }
}

.xirecepyIdeasArea {
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border: none;
}

.headerrow {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.xirecepyHeaderId {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 10%;
  height: 25px;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  border-right: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal);
  border-radius: 6px;
  text-align: center;
}

.xirecepyHeaderName {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: calc(80% - 3px);
  height: 25px;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  background-color: var(--rgbaVal);
  border-radius: 6px;
  text-align: center;
  margin-left: 3px;
}

.xirecepyDataId, .xirecepyDataId1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 10%;
  height: 25px;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  border-right: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  text-align: center;
  margin-top: 3px;
}

.xirecepyDataId1 {
  background-color: var(--rgbaVal);
}

.xirecepyDataName, .xirecepyDataName1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  width: calc(80% - 3px);
  height: 25px;
  font-family: var(--font-family);
  font-size: 14px;
  color: black;
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  text-align: center;
  margin-top: 3px;
  margin-left: 3px;
  padding-left: 10%;
}
.xirecepyDataName .ellipsis-text, .xirecepyDataName1 .ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
}
@media (max-width: 360px) {
  .xirecepyDataName, .xirecepyDataName1 {
    min-width: 1px;
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.xirecepyDataName1 {
  background-color: var(--rgbaVal);
}

.xirecepyIdeasData {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1px;
  border: none;
  width: 100%;
  max-height: 40vh;
  overflow-x: scroll;
}

.xidatarows {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.xidatarowstop {
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xiSpinnerContainer {
    position: absolute;
    top: 290px;
    left: 170px;
    background-color: transparent;
    transform: scale(1.5);
    z-index: 100;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xiSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 100;
  }
  .xiSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xiSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xiSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xiSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 100;
  }
  .xiSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width:2303px) {
  .xiSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2.5);
    z-index: 100;
  }
  .xiSpinnerContainer.active {
    z-index: 9999;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xiSpinner {
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xiSpinner {
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xiSpinner {
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xiSpinner {
    color: black;
  }
}

.xiconfirmationArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.xiconfirmationButton {
  width: 50%;
  height: 30px;
  font-size: 14px;
  font-family: var(--font-family);
  border-radius: 6px;
  background-color: var(--rgbaVal2);
  outline: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xicompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xicompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 36px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xicompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xicompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xicompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xicompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xicompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 36px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xicompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xicompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xicompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

.xiChecfHat {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.xipopup-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 600px;
  height: 35vh;
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 10;
  border-radius: 10px;
}
.xipopup-box p {
  font-size: 14px;
  color: #333;
}
.xipopup-box .close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.xipopup-box .close-btn:hover {
  background: #0056b3;
}

.xipopup-box-content {
  width: 100%;
  height: 30vh;
  border-radius: 10px;
  font-family: var(--font-family);
  font-size: 16px;
}/*# sourceMappingURL=RecepyIdeas2.css.map */