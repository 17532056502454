@import '../style/MainStyles.scss';/* styles.css */


.xfingredtotalArea {
    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows: 3% 5% 40% 52%;
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            ;
        width: 100%;
        height: 710px;
        object-fit: cover;
    }
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: 2% auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: 3% auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
@include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: 3% auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:30% 40% 29%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.xfingredtotalArea1 {
    @extend .xfingredtotalArea;

    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, backgroundColor);
    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, backgroundColor);
    }
    @include gadgets-to(laptop) {
        background-color: map-get($generalStyles, backgroundColor);
    }
@include gadgets-to(desktop) {
        background-color: map-get($generalStyles, backgroundColor);
    }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, backgroundColor);
    }
}

.xfcompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 32px;
        color: white;
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xfcompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xfcompNameJAP{
    @extend .xfcompName;
    font-family:var(--font-family);
}
.xfcompNameJAP1{
    @extend .xfcompName1;
    font-family:var(--font-family);
    color:map-get($generalStyles,transparentChangeableMenu5);

}
.xfheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
    }
@include gadgets-to(laptop) {
        grid-area: a;
    }
@include gadgets-to(desktop) {
        grid-area: a;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
    }

}

.xfrangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:3%;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:3vh;
    }
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}

.xfingredareaA {
    @include gadgets-to(mobile) {
        grid-area: c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        background-color: map-get($generalStyles, transparentChangeable);
      //  margin-top:4%;
        display:flex;
        flex-direction:column;
       // justify-content:center;
        border-radius: map-get($generalStyles,sixBorderRadius);

    }
@include gadgets-to(tablet) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(laptop) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(desktop) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(largeDesktop) {
        grid-area:c;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        margin-top:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
}

.ingredareaB {

    @include gadgets-to(mobile) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
      //  height:51vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(tablet) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(laptop) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }

@include gadgets-to(desktop) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(largeDesktop) {
        grid-area:d;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
}
}


.ingredareaC {
    @include gadgets-to(mobile) {
        // styles commented out
    }
    @include gadgets-to(tablet) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:80vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(laptop) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(desktop) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
@include gadgets-to(largeDesktop) {
        grid-area:e;
        border: 1px solid;  
        border-color: map-get($generalStyles, datalistBorderColor);
        width:99%;
        height:82vh;
        background-color: map-get($generalStyles, transparentChangeable);
        z-index: 1;  
       margin-top:1%;
       border-radius: map-get($generalStyles,sixBorderRadius);
    }
 }




.xfingredrange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xfingredrange2 {
    @extend .xfingredrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}
.xfingredCategoryDatalist{
    @include gadgets-to(mobile) {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;

    }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;

    }
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    }
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    }
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    }

}
.xfingredDataList{
    @include gadgets-to(mobile) {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
    }
}
.xfingredDataList1{
    @include gadgets-to(mobile) {
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
       // justify-content:center;
        width:100%;
    }
}


.xfingredCategoryTop{
    @include gadgets-to(mobile) {
        width:100%;
        display:flex;
        flex-direction:row;
       // align-items:center;
       // justify-content:center;
        flex-wrap:wrap;
        margin-top:5px;
    
    }
    @include gadgets-to(tablet) {
        width:95%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        flex-wrap:wrap;
        margin-top:2vh;
        
   

   
    }
@include gadgets-to(laptop) {
    width:95%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    margin-top:2vh;
    }
@include gadgets-to(desktop) {
    width:95%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    margin-top:2vh;
    }
@include gadgets-to(largeDesktop) {
    width:95%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        flex-wrap:wrap;
        margin-top:2vh;
    }
}

.xfingredcatrowsdata{
    @include gadgets-to(mobile) {
    
        width:100%;
        // margin-top:8%;
         min-height:1px;
         max-height:37vh;
         height:auto;
         overflow-x:hidden;
         overflow-y:scroll;
         align-self:center;
         // --webkit scrollbar
         &::-webkit-scrollbar {
             display:none;
           }

           @media(min-width:380px)
           and (max-width:385px)
           {
               max-height:32vh;
           }

        @media(min-width:390px)
        and (max-width:393px)
        {
            max-height:34vh;
        }
        @media(min-width:410px)
        and (max-width:413px)
        {
            max-height:28vh;
        }

        @media(min-width:425px)
        and (max-width:429px)
        {
            max-height:30vh;
        }

        @media(min-width:429px)
        and (max-width:431px)
        {
            max-height:30vh;
        }
        @media(min-width:478px)
        and (max-width:481px)
        {
            max-height:28vh;
        }

    }
    @include gadgets-to(tablet) {
        width:100%;
       // margin-top:8%;
        min-height:1px;
        max-height:65vh;
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        align-self:center;
        // --webkit scrollbar
        &::-webkit-scrollbar {
            display:none;
          }
        

    }
@include gadgets-to(laptop) {
        width:100%;
       // margin-top:8%;
        min-height:1px;
        max-height:72vh;
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        align-self:center;
        &::-webkit-scrollbar {
            display:none;
          }
    }
@include gadgets-to(desktop) {
        width:100%;
       // margin-top:8%;
        min-height:1px;
        max-height:72vh;
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        align-self:center;
        &::-webkit-scrollbar {
            display:none;
          }
    }
@include gadgets-to(largeDesktop) {
        width:100%;
       // margin-top:8%;
        min-height:1px;
        max-height:75vh;
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        align-self:center;
        &::-webkit-scrollbar {
            display:none;
          }
    }
}

.xfrows{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
    @include gadgets-to(tablet) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
@include gadgets-to(laptop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
@include gadgets-to(desktop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
@include gadgets-to(largeDesktop) {
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:center;
    }
}


.xfingredcategoryBenaemnHeader {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
        margin-left:12%;
        width:55%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
      //  margin-left:12%;
        width:55%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
      //  margin-left:12%;
        width:55%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include  gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size:18px;
        color: grey;
        margin-bottom: 3px;
        margin-top: 3px;
        width:55%;
        height: 35px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size:24px;
        color: grey;
       // margin-left: 55px;
        margin-bottom: 3px;
        margin-top: 3px;
      //  margin-left:12%;
        width:55%;
        height: 40px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
}

.xfingredcategoryAntalHeader {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
        margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:20%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
    @include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height: 25px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 18px;
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height: 35px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size:24px;
        color: grey;
       margin-left: 3px;
        margin-bottom: 3px;
        margin-top: 3px;
        width:25%;
        height:40px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
    }
}



.xfingredcategorydataBenaemn {
    @include gadgets-to(mobile) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
    @include gadgets-to(tablet) {
        width:55%;
        height:27px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
       // margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 24px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.xfingredcategorydataBenaemn1 {
    @include gadgets-to(mobile) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(tablet) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
        font-family: map-get($generalStyles,fontFamily);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        width:55%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        width:55%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
  //      margin-left: 55px;
        margin-bottom: 3px;
        border-radius:map-get($generalStyles,sixBorderRadius);
        padding: 3px;
        padding-left: 10px;
        cursor: pointer;
      font-family:var(--font-family);
        font-size: 26px;
        color: map-get($generalStyles, colorblack);
        text-align: left;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.xfingredcategorydataAntal {
    @include gadgets-to(mobile) {
        width:20%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        width:25%;
        height:27px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
        width:25%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size:24px;
        color: map-get($generalStyles, colorblack);
    }
}

.xfingredcategorydataAntal1 {
    @include gadgets-to(mobile) {
        width:20%;
        height:30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        width:25%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
        width:25%;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
        width:25%;
        height: 40px;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        margin-left: 3px;
        margin-bottom: 3px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size:24px;
        color: map-get($generalStyles, colorblack);
    }
}



.ingredcatrightdataArea {
    @include gadgets-to(mobile) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:15vh;
        overflow-y:auto;
              // --webkit scrollbar
              &::-webkit-scrollbar {
                width:3px;
              }
            // track
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
        }
        &::-webkit-scrollbar-thumb {
            background:white;
            border:1px solid grey;
            }
    }
    @include gadgets-to(tablet) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y: scroll;
        // --webkit scrollbar
        &::-webkit-scrollbar {
            display:none;
          }
    }
@include gadgets-to(laptop) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y:auto;
    // --webkit scrollbar
    &::-webkit-scrollbar {
    width:5px;
}
    // track
    &::-webkit-scrollbar-track {
    background:map-get($generalStyles,transparentChangeableMenu5);
    }
    &::-webkit-scrollbar-thumb {
        background:white;
        border:1px solid grey;
        }
}

@include gadgets-to(desktop) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y:auto;
    // --webkit scrollbar
    &::-webkit-scrollbar {
        width:5px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        }
    // track
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
        }
    // handle
    &::-webkit-scrollbar-thumb {
        background:white;
        border:1px solid grey;
        }
    }
@include gadgets-to(largeDesktop) {
        width: 95%;
        height: 64vh;
        background-color: map-get($generalStyles, colorTransparent);
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 10px;
        margin-left: 5px;
        padding-top: 5px;
        padding-left: 10px;
        max-height:35vh;
        overflow-y:auto;
    // --webkit scrollbar
    &::-webkit-scrollbar {
        width:10px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        }
    // track    
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
        }
    // handle
    &::-webkit-scrollbar-thumb {
        background:white;
        border:1px solid grey;
        }
    }
}

.xfingredcategoryrightButton {
    @include gadgets-to(mobile) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(tablet) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        display: inline-block;
        cursor:pointer;
        // font-family:map-get($generalStyles,fontFamily);
        // font-size: 60px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        // padding-bottom: 3px;
        // padding-top: 4px;
        // padding-left: 5px;
        // padding-right: 5px;
        padding:5px;
        width:auto;
        height:30px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        display: inline-block;
        cursor:pointer;
        // font-family: map-get($generalStyles, fontFamily);
        // font-size:50px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:map-get($generalStyles, colorblack);
       border-radius: map-get($generalStyles,sixBorderRadius);
       margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.xfingredcategoryrightButton1 {
    @include gadgets-to(mobile) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);

    }
@include gadgets-to(tablet) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(laptop) {
        display: inline-block;
        cursor:pointer;
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(desktop) {
        display: inline-block;
        cursor:pointer;
        // font-family: map-get($generalStyles, fontFamily);
        // font-size:50px!important;
        // font-weight: bold;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-bottom: 3px;
        // padding-bottom: 3px;
        // padding-top: 4px;
        // padding-left: 5px;
        // padding-right: 5px;
        padding:5px;
        width:auto;
        height:30px;
        margin-left: 5px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(largeDesktop) {
        display: inline-block;
        cursor:pointer;
        // font-family: map-get($generalStyles, fontFamily);
        // font-size:50px!important;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
      //  border-radius: map-get($generalStyles,sixBorderRadius);
       border-radius:10px;
      margin-bottom: 5px;
      padding-bottom: 3px;
      padding-top: 4px;
      padding-left: 5px;
      padding-right: 5px;
        margin-left: 5px;
        border:1px solid;;
        border-color:map-get($generalStyles, datalistBorderColor);
    }
}

.ingredcategoryrightButtontext {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size: 16px;
    }
@include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size: 16px;
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size: 16px;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size:26px;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
      font-family:var(--font-family);
        font-size:30px;
        
    }
}
.xfingredmoveTop{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction: row;
        justify-content:center;
    }
  @include gadgets-to(tablet) {
        display:flex;
        flex-direction: row;
        justify-content:center;
    }
@include gadgets-to(laptop) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5px;
    }
@include gadgets-to(desktop) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5px;
    }
@include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:5px;
    }
}

.ingredcatTrash {
    @include gadgets-to(mobile) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(tablet) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(laptop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(desktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(largeDesktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
}

.ingredcatTrash1 {
    @include gadgets-to(mobile) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(tablet) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(laptop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(desktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
@include gadgets-to(largeDesktop) {
        position: absolute;
        top: 56px;
        left: 195px;
        cursor: not-allowed;
        color: black;
        font-size: 26px;
        opacity: 0.8;
    }
}

.ingredcreatecatTop{
@include gadgets-to(mobile) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    }
}

.xfingredcatcreateCategoryTop{
    @include gadgets-to(mobile) {
        width:100%;
    }
    @include gadgets-to(tablet) {
        width:100%;
    }
@include gadgets-to(laptop) {
        width:100%;
    }
@include gadgets-to(desktop) {
        width:100%;
    }
@include gadgets-to(largeDesktop) {
        width:100%;
    }

}

.xfingredcatcreateCategoryInput {
    @include gadgets-to(mobile) {
        width:60%;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 16px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(tablet) {
        width:60%;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 12px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(laptop) {
        width:60%;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 12px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(desktop) {
        width:60%;
        height:40px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size: 12px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top:3%;
        margin-left:4%;
    }
@include gadgets-to(largeDesktop) {
        width:60%;
        height:35px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family:var(--font-family);
        font-size:20px;
        color: black;
      //  padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      //  margin-top:3%;
        margin-left:4%;
    }
}

.xfingredcatcreateCategoryButton {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles,pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color:white;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:25%;
        height:43px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
     //   margin-right: 3px;
        width:25%;
        height:38px;
     //   margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
        margin-left:3px;
    }
}

.xfingredcatcreateCategoryButton1 {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color:black;
        margin-left:3px;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:30%;
        height:28px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:25%;
        height:43px;
        margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-right: 3px;
        width:25%;
        height:40px;
       // margin-top: 3px;
        margin-bottom: 5px;
        border-radius:map-get($generalStyles, sixBorderRadius);
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        margin-left:3px;
        opacity: 0.8;
    }
}

.xfingredcatcreateCategoryTop {
    @include gadgets-to(mobile) {
    
    }
@include gadgets-to(tablet) {

    }
@include gadgets-to(laptop) {

    }
@include gadgets-to(desktop) {

    }
@include gadgets-to(largeDesktop) {
    margin-top:1.8%;
display:flex;
flex-direction:row;
justify-content:flex-start;
    }
}

.xfingredcreateCat {
    @include gadgets-to(mobile) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(tablet) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(laptop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(desktop) {
        color: black;
        font-size: 40px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
@include gadgets-to(largeDesktop) {
        color: black;
        font-size: 50px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0.6;
    }
}

.xfingredcreateCat1 {
    @include gadgets-to(mobile) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(tablet) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(laptop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(desktop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
@include gadgets-to(largeDesktop) {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-top: 6px;
        margin-left: 5px;
        opacity: 0;
    }
}

.ingredcattextAreaMessage {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 200px;
        height: 430px;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size:18px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size:18px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size:18px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size:24px;
        color: map-get($generalStyles, colorblack);
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorTransparent);
        outline: none;
        width: 90%;
        height:75vh;
        margin:10px;
    }
}

.ingredSpinnerContainer {
    @include gadgets-to(mobile) {
        position: absolute;
        top: 290px;
        left: 170px;
        background-color: transparent;
        transform: scale(2.5);
        z-index: 10;
    }
@include gadgets-to(tablet) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(laptop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(desktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
@include gadgets-to(largeDesktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1; // low value when not active

    &.active {
        z-index: 9999; // high value when active
    }
    }
}

.ingredSpinner {
    @include gadgets-to(mobile) {
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(laptop) {
        color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(desktop) {
        color: map-get($generalStyles, colorblack);
    }
}



.ingredmoveitemTop {
    @include gadgets-to(mobile) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
   
    }
@include gadgets-to(tablet) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(laptop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(desktop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
@include gadgets-to(largeDesktop) {
        margin-top: 10px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
}

.ingredmoveitemFieldset {
    @include gadgets-to(mobile) {
        max-height:14vh;
        overflow-y:auto;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        //webkit scrollbar
        &::-webkit-scrollbar {
            display:none;
        }
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
        }
        &::-webkit-scrollbar-thumb {
            background: map-get($generalStyles,colorwhite);
            border-radius: 10px;
            border:1px solid grey;
        }
    }
@include gadgets-to(tablet) {
        margin-top:10px;
        max-height:21vh;
        overflow-y:auto;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
          //webkit scrollbar
          &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
        }
        &::-webkit-scrollbar-thumb {
            background: map-get($generalStyles,colorwhite);
            border-radius: 10px;
            border:1px solid grey;
        }
    }
@include gadgets-to(laptop) {
        margin-top:10px;
        max-height:25vh;
        overflow-y:auto;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu5);
    }
    &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,colorwhite);
        border-radius: 10px;
        border:1px solid grey;
    }
  
  
    }
@include gadgets-to(desktop) {
        margin-top:10px;
        max-height:25vh;
        overflow-y:auto;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        //webkit scrollbar
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
        }
        &::-webkit-scrollbar-thumb {
            background: map-get($generalStyles,colorwhite);
            border-radius: 10px;
            border:1px solid grey;
        }
    }
@include gadgets-to(largeDesktop) {
        margin-top:10px;
        max-height:25vh;
        overflow-y:auto;
        width: 90%;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        //webkit scrollbar
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu5);
        }
        &::-webkit-scrollbar-thumb {
            background: map-get($generalStyles,colorwhite);
            border-radius: 10px;
            border:1px solid grey;
        }
    }
}

.ingredmoveitemLegend {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles, colorblack);
    }
}

.ingredmoveitemSelect {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
       // margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
      //  height: 35px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: white;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: 1px solid;
        border-color: map-get($generalStyles, datalistitemColor);
        width: 200px;
        height: 35px;
       // margin-top: 5px;
        outline: none;
        margin-right: 5px;
        margin-left: 3px;
    }
}

.ingredmoveitemSaveButton {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 32px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 32px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
        //margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
    }
}

.ingredmoveitemSaveButton1 {
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 32px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.5;
    }
@include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 32px;
        margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.5;
    }
@include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
      //  margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.6;
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(desktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
     //   margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.6;
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
        font-size: 14px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100px;
        height: 34px;
    //    margin-top: 5px;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.6;
        color:map-get($generalStyles, colorblack);
    }
}

// .test {
//     @include gadgets-to(mobile) {
//         position: absolute;
//         top: 100px;
//         left: 100px;
//         width: 100px;
//         height: 100px;
//         background-color: red;
//     }
// }

.ingredCategoryInputKanji {
    @include gadgets-to(mobile) {
        grid-area: 'b';
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(tablet) {
        grid-area: 'b';
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(laptop) {
        grid-area: 'b';
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(desktop) {
        grid-area: 'b';
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
}
@include gadgets-to(largeDesktop) {
        grid-area: 'b';
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
}

.ingredCategoryInputFurigana {
    @include gadgets-to(mobile) {
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(tablet) {
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(laptop) {
        width: 300px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(desktop) {
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
@include gadgets-to(largeDesktop) {
        width: 400px;
        height: 25px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
      font-family:var(--font-family);
        font-size: map-get($generalStyles, legendFontsize);
        color: black;
        padding-left: 5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
        margin-top: 1px;
        margin-left: 7px;
    }
}

.ingredCategoryTopJAP {
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
    }
@include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:5px;
    }
@include gadgets-to(laptop) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:10px;
    }
@include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:10px;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin-top:10px;
    }
}

.ingredCategoryButtonJAP1 {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
}

.ingredCategoryButtonJAP {
    @include gadgets-to(mobile) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, pinkRed);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: white;
    }
@include gadgets-to(tablet) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(laptop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 195px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(desktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
@include gadgets-to(largeDesktop) {
        cursor: pointer;
        border: solid 1px darkgrey;
        margin-left: 295px;
        width: 120px;
        height: 34px;
        margin-top: 4px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 5px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
      font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        color: black;
        opacity: 0.7;
    }
}

.xfbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }
