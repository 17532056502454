@import '../style/MainStyles.scss';/* styles.css */


.xjtotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}




.xjtotalArea {

    // @include gadgets-to(mobile) {
    //     display: grid;
    //    // grid-template-rows: 3% 5% 26% 17% 30%;
    //     grid-template-rows:auto auto auto auto auto;
       
    //     grid-template-areas:
    //         "a"
    //         "b"
    //         "c"
    //         "d"
    //         "e"
    //         ;
    //     width: 100%;
    //     height:auto;
    //     object-fit: cover;

 

    // }


    // @include gadgets-to(tablet) {
    //     display: grid;
    //     grid-template-columns:30% 47% 22%;
    //     grid-template-rows: auto auto auto;
    //     grid-template-areas:
    //       "a a a"
    //       "b b b"
    //       "c d e";
    //       column-gap:7px;
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }
    // @include gadgets-to(laptop) {
    //     display: grid;
    //     grid-template-columns:30% 47% 22%;
    //     grid-template-rows: auto auto auto;
    //     grid-template-areas:
    //       "a a a"
    //       "b b b"
    //       "c d e";
    //       column-gap:7px;
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }
    // @include gadgets-to(desktop) {
    //     display: grid;
    //     grid-template-columns:30% 47% 22%;
    //     grid-template-rows: auto auto auto;
    //     grid-template-areas:
    //       "a a a"
    //       "b b b"
    //       "c d e";
    //       column-gap:7px;
    //     width:99.8%;
    //     height: 100%;
    //     object-fit: cover;
     
    
    // }
    // @include gadgets-to(largeDesktop) {
    //     display: grid;
    //     grid-template-columns:30% 47% 22%;
    //     grid-template-rows: auto auto auto;
    //     grid-template-areas:
    //       "a a a"
    //       "b b b"
    //       "c d e";
    //       column-gap:7px;
    //     width: 99.8%;
    //     height: 100%;
    //     object-fit: cover;
    // }

    @include gadgets-to(mobile) {
        display: grid;
       // grid-template-rows: 3% 5% 26% 17% 30%;
        grid-template-rows:auto auto auto auto auto;;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "e";
            ;
        width: 100%;
        height:auto;
        object-fit: cover;
        .area-f {
            grid-area: d;
            // style for the tabs
            .tabs {
              display: flex;
              justify-content: space-between;
              height:3vh;
              @include gadgets-to(mobile) {
                height:4vh;
                @media (max-width: 360px) {
                  height:4vh;
                  max-width:300px;
                }
                @media(min-width:361px) and (max-width: 370px) {
                  height:4vh;
                  max-width:320px;
                }
                @media(min-width:371px) and (max-width: 386px) {
                    height:4vh;
                    max-width:320px;
                  }
                  @media(min-width:400px) {
                    height:3.5vh;
                   // max-width:320px;
                  }


            }
            }   
            .tab-content {
              display: none;
            }
          }


    }


    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        .area-f {
            grid-area: d;
            // style for the tabs
            .tabs {
              display: flex;
              justify-content: space-between;
              height:4vh;
            }
            .tab-content {
              display: none;
            }
          }
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        .area-f {
            grid-area: d;
            // style for the tabs
            .tabs {
              display: flex;
              justify-content: space-between;
              height:3vh;
            }
            .tab-content {
              display: none;
            }
          }
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width:95%;
        height: 100%;
        object-fit: cover;
        .area-f {
            grid-area: d;
            // style for the tabs
            .tabs {
              display: flex;
              justify-content: space-between;
              height:3vh;
            }
            .tab-content {
              display: none;
            }
          }


    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:30% 47% 22%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 99.8%;
        height: 100%;
        object-fit: cover;
        .area-f {
            grid-area: d;
            // style for the tabs
            .tabs {
              display: flex;
              justify-content: space-between;
              height:3vh;
            }
            .tab-content {
              display: none;
            }
          }
    }
}

.xjtotalArea1 {
  @extend .xjtotalArea;
  background-color: map-get($generalStyles, backgroundColor);
}

.xjtotalArea2 {
  @extend .xjtotalArea;
  @include gadgets-to(tablet) {
    grid-template-columns:30% 0.1% 69%;
   // background-color:white;
}
@include gadgets-to(laptop) {
    grid-template-columns:30% 0.1% 69%;
}
@include gadgets-to(desktop) {
    grid-template-columns:30% 0.1% 69%;
}
@include gadgets-to(largeDesktop) {
    grid-template-columns:30% 0.1% 69%;
}
}


.xjbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }
  
.xjcompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:34px;
        color: white;
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}

.xjcompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}
.xjcompNameJAP{
    @extend .xjcompName;
    font-family: var(--font-family);

}
.xjcompNameJAP1{
    @extend .xjcompName1;
    font-family: var(--font-family);
    color:map-get($generalStyles, transparentChangeableMenu5);

}

  .xjheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;
    }
@include gadgets-to(laptop) {
        grid-area: a;
    }
@include gadgets-to(desktop) {
        grid-area: a;
        height:40px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
    }

}

.xjrangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}


.xjrange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xjrange2 {
    @extend .xjrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}


.xjareaA {
    @include gadgets-to(mobile) {
        grid-area:c;
        height:34vh;
       // margin-top:1vh;
        border: 1px solid;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        background-color: map-get($generalStyles, transparentChangeable);
      
    }
    @include gadgets-to(tablet) {
        grid-area:c;
        height:72vh;
       // margin-top:1vh;
        border: 1px solid;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        background-color: map-get($generalStyles, transparentChangeable);
      
        @media (max-width: 1080px) {
            height:83vh;
        }
        @media (min-width: 1082px) {
            height:72vh;
        }
    }
@include gadgets-to(laptop) {
    grid-area:c;
    height:map-get($generalStyles,applicationHeightLaptop);
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);

}
@include gadgets-to(desktop) {
    grid-area:c;
    height:map-get($generalStyles,applicationHeightLaptop);
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
}
@include gadgets-to(largeDesktop) {
    grid-area:c;
    height:map-get($generalStyles,applicationHeightLaptop);
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
}
}



.xjareaATop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow:hidden;
}

.xjsearchFieldset {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin-top:0.5vh;
        width:90%;
       // margin-left:10px;
        border:none;
        height:37px;
      font-family:var(--font-family);
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:center;
        margin-top:1vh;
        width:90%;
       // margin-left:10px;
        border:none;
        height:37px;
      font-family:var(--font-family);
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:center;
        margin-top:1vh;
        width:90%;
       // margin-left:10px;
        border:none;
        height:37px;
      font-family:var(--font-family);
    }
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    margin-top:1vh;
    width:90%;
   // margin-left:10px;
    border:none;
    height:37px;
  font-family:var(--font-family);
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    margin-top:1vh;
    width:90%;
   // margin-left:10px;
    border:none;
    height:37px;
  font-family:var(--font-family);
}
 
}

.xjsearchLegend {
   @include gadgets-to(mobile) {
    display:flex;
    flex-direction: row;
    margin-left:3.8vw;
  font-family:var(--font-family);
    font-size:12px;
    color: map-get($generalStyles, colorblack);
  //  margin-left:5px;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction: row;
      font-family:var(--font-family);
        font-size:12px;
        color: map-get($generalStyles, colorblack);
        margin-left:4%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: row;
  font-family:var(--font-family);
    font-size:12px;
    color: map-get($generalStyles, colorblack);
    margin-left:5%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
  font-family:var(--font-family);
    font-size:12px;
    color: map-get($generalStyles, colorblack);
    margin-left:5%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
  font-family:var(--font-family);
    font-size:12px;
    color: map-get($generalStyles, colorblack);
    margin-left:5%;
}
}



.xjsearchInput {
    @include gadgets-to(mobile) {
        width:70%;
        height:25px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
       // margin-left:5px;
        outline:none;
      font-family:var(--font-family);
        font-size: 14px;
    }
@include gadgets-to(tablet) {
        width:70%;
        height:20px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
       // margin-left:5px;
        outline:none;
      font-family:var(--font-family);
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        width:70%;
        height:30px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
       // margin-left:5px;
        outline:none;
      font-family:var(--font-family);
        font-size: 14px;
    }
@include gadgets-to(desktop) {
        width:70%;
        height:30px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
       // margin-left:5px;
        outline:none;
      font-family:var(--font-family);
        font-size: 14px;
}
@include gadgets-to(largeDesktop) {
        width:70%;
        height:30px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
       // margin-left:5px;
        outline:none;
      font-family:var(--font-family);
        font-size: 14px;
}
}


.xjsearchButton {
    @include gadgets-to(mobile) {
        width:calc(20% - 5px);
        height:28px;
        border:none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left:5px;
        padding:1px;
    }
    @include gadgets-to(tablet) {
        width:calc(20% - 5px);
        height:25px;
        border:none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left:5px;
        padding:1px;
        color:map-get($generalStyles, colorblack);
    }
@include gadgets-to(laptop) {
        width:calc(20% - 5px);
        height:30px;
        border:none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left:5px;
        padding:1px;
    }
@include gadgets-to(desktop) {
        width:calc(20% - 5px);
        height:30px;
        border:none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left:5px;
        padding:1px;
    }
@include gadgets-to(largeDesktop) {
        width:calc(20% - 5px);
        height:30px;
        border:none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left:5px;
        padding:1px;
    }

} 

.xjSelectRecepyFieldset {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction: row;
        justify-content:center;
        width:90%;
        height: 38px;
        border: none;
        margin-top: 1px;
        border-color: map-get($generalStyles, datalistBorderColor);
    

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    width:93%;
    height: 38px;
    border: none;
    margin-top: 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    width:93%;
    height: 38px;
    border: none;
    margin-top: 5px;
    border-color: map-get($generalStyles, datalistBorderColor);
    
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    width:93%;
    height: 38px;
    border: none;
    margin-top: 5px;
    border-color: map-get($generalStyles, datalistBorderColor);
    
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    width:93%;
    height: 38px;
    border: none;
    margin-top: 5px;
    border-color: map-get($generalStyles, datalistBorderColor);
}
}

.xjSelectRecepyLegend {
    @include gadgets-to(mobile) {
        color: map-get($generalStyles, colorblack);
        font-size: map-get($generalStyles, fontsizeSmall);
        font-weight: map-get($generalStyles, fontWeight);
      font-family:var(--font-family);
        margin-left:3.8vw;
    }
    @include gadgets-to(tablet) {
        color: map-get($generalStyles, colorblack);
        font-size: map-get($generalStyles, fontsizeSmall);
        font-weight: map-get($generalStyles, fontWeight);
      font-family:var(--font-family);
        margin-left:5%;
    }
@include gadgets-to(laptop) {
        color: map-get($generalStyles, colorblack);
        font-size: map-get($generalStyles, fontsizeSmall);
        font-weight: map-get($generalStyles, fontWeight);
      font-family:var(--font-family);
        margin-left:5%;

}
@include gadgets-to(desktop) {
        color: map-get($generalStyles, colorblack);
        font-size: map-get($generalStyles, fontsizeSmall);
        font-weight: map-get($generalStyles, fontWeight);
      font-family:var(--font-family);
        margin-left:5%;

}
@include gadgets-to(largeDesktop) {
        color: map-get($generalStyles, colorblack);
        font-size: map-get($generalStyles, fontsizeSmall);
        font-weight: map-get($generalStyles, fontWeight);
      font-family:var(--font-family);
        margin-left:5%;

    }
}


.SelectAreaTop{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content:center;
}
.xjSelectRecepySelect {
    @include gadgets-to(mobile) {
width:92%;
height: 30px;
border: solid 1px;
border-color: map-get($generalStyles, datalistBorderColor);
outline: none;
font-family: map-get($generalStyles, fontFamily);
font-size:14px;
color: map-get($generalStyles, colorblack);
border-radius: map-get($generalStyles, sixBorderRadius);
background-color: white;
    }
    @include gadgets-to(tablet) {
        width:90%;
        height: 25px;
        border: solid 1px;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size:14px;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: white;
    }
@include gadgets-to(laptop) {
        width:89%;
        height:30px;
        border: solid 1px;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size:14px;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: white;
    }
@include gadgets-to(desktop) {
        width:89%;
        height:30px;
        border: solid 1px;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size:14px;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: white;
    }
@include gadgets-to(largeDesktop) {
        width:89%;
        height:30px;
        border: solid 1px;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size:14px;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: white;
    }
}

.xjareaB {
    @include gadgets-to(mobile) {
        grid-area:d;
        height:45vh;
        margin-top:5px;
        border: 1px solid;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        background-color: map-get($generalStyles, transparentChangeable);      
        
        //360
        @media(max-width:360px) and (max-height:690px) {
            margin-top:1vh; 
            height:47.5vh;
        }
        // 375
        @media(min-width:370px) and (max-width:380px) {
            height:47vh;
        }
        //384
        @media(min-width:381px) and (max-width:385px) {
            height:50vh;
        }
        //393
        @media(min-width:386px) and (max-width:394px) {
            height:49vh;
        }
        @media(min-width:395px) and (max-width:411px) {
            height:45vh;
        }
        // 412px , a phone
        @media(min-width:411px) and (max-width:413px) {
            @media(max-height:732px){
                height:49vh;
            }
            height:45.5vh;
         
        }
        //
        @media(min-width:414px) and (max-width:440px) {
            height:50vh;
        }

}
@include gadgets-to(tablet) {
    grid-area:d;
    height:72vh;
  //  margin-top:5px;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);      
    
    @media (max-width: 1080px) {
        height:83vh;
    }

}
@include gadgets-to(laptop) {
    grid-area:d;
    height:map-get($generalStyles,applicationHeightLaptop);
  //  margin-top:5px;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);      
    
}
@include gadgets-to(desktop) {
    grid-area:d;
    height:map-get($generalStyles,applicationHeightLaptop);
  //  margin-top:5px;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);      
    
}
@include gadgets-to(largeDesktop) {
    grid-area:d;
    height:map-get($generalStyles,applicationHeightLaptop);
  //  margin-top:5px;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);      
    
}
}
.xjareaB1{
    @include gadgets-to(mobile) {
        grid-area:d;
        height:65vh;
        margin-top:5px;
        border: 1px solid;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        background-color: map-get($generalStyles, transparentChangeable);      
        
        //360
        @media(max-width:360px) and (max-height:690px) {
            margin-top:1vh; 
            height:87.5vh;
        }
        // 375
        @media(min-width:370px) and (max-width:380px) {
            height:87vh;
        }
        //384
        @media(min-width:381px) and (max-width:385px) {
            height:87vh;
        }
        //393
        @media(min-width:386px) and (max-width:394px) {
            height:87vh;
        }
        @media(min-width:395px) and (max-width:411px) {
            height:87vh;
        }
        // 412px , a phone
        @media(min-width:411px) and (max-width:413px) {
            @media(max-height:732px){
                height:49vh;
            }
            height:65.5vh;
            margin-bottom:15px;
         
        }
        //
        @media(min-width:414px) and (max-width:440px) {
            height:87vh;
        }

}

}

.xjareaC {
    @include gadgets-to(mobile) {
        grid-area: e;
        width: 99%;
        height:30vh;
        //margin-left: 2px;
        margin-top:1vh;
      //  border: 1px solid;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        background-color: map-get($generalStyles, transparentChangeable);
   
    }
@include gadgets-to(tablet) {
    grid-area: e;
    width: 99%;
    height:72vh;
    //margin-left: 2px;
    //margin-top: 1vh;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
    
    @media (max-width: 1080px) {
        height:83vh;
    }

}
@include gadgets-to(laptop) {
    grid-area: e;
    width: 99%;
    height:map-get($generalStyles,applicationHeightLaptop);
    //margin-left: 2px;
    //margin-top: 1vh;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
    
}
@include gadgets-to(desktop) {
    grid-area: e;
    width: 99%;
    height:map-get($generalStyles,applicationHeightLaptop);
 //   margin-right:5%;
    //margin-top: 1vh;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
    
}
@include gadgets-to(largeDesktop) {
    grid-area: e;
    width: 99%;
    height:map-get($generalStyles,applicationHeightLaptop);
 //   margin-right:5%;
    //margin-top: 1vh;
    border: 1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);
    
}
}

.areaC1 {
  @extend .xjareaC;
  height: 820px;
  margin-left: 20px;
  background: white;
  margin-top: 50px;
  z-index: 10;
}




.areaBMiddle {
    grid-area: b;
    height: 30px;
}

.xjareaCTop {}

// .compName2, .compName {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     top: 70px;
//     left: 0;
//     right: 0;
//     font-family: map-get($generalStyles, fontFamilyLobster);
//     font-size: 28px;
// }

// .compName2 {
//     color: white;
// }

// .compName {
//     color: map-get($generalStyles, transparentChangeableMenu5);
// }

.SelectRecepyFieldset {
    width: 300px;
    height: 38px;
    border: none;
    margin-top: 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
}

.SelectRecepyLegend {
    color: map-get($generalStyles, colorblack);
    font-size: map-get($generalStyles, fontsizeSmall);
    font-weight: map-get($generalStyles, fontWeight);
  font-family:var(--font-family);
}

.SelectRecepySelect {
    width: 300px;
    height: 30px;
    border: solid 1px;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: map-get($generalStyles, fontSizeSixteen);
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
}

.xjrecepiesByCategorySelectButton {
    width: auto;
    height: 30px;
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 3px;
    margin-bottom: 2px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    cursor: pointer;
  font-family:var(--font-family);
    color:map-get($generalStyles, colorblack);

    @include gadgets-to(laptop) {
        width: auto;
        height: 35px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 2px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        cursor: pointer;
      font-family:var(--font-family);
        color:map-get($generalStyles, colorblack);
        font-size:15px;
    }
    @include gadgets-to(desktop) {
        width: auto;
        height: 35px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 2px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        cursor: pointer;
      font-family:var(--font-family);
        color:map-get($generalStyles, colorblack);
        font-size:15px;
    }
    @include gadgets-to(largeDesktop) {
        width: auto;
        height: 45px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-left: 3px;
        margin-bottom: 2px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        cursor: pointer;
      font-family:var(--font-family);
        color:map-get($generalStyles, colorblack);
        font-size:18px;
    }
}


.xjbuttonIcon1 {
    position: absolute;
    top: 0;
    right: 0;
    color:gold;
    font-size:18px;
    // border: 1px solid white;
    // border-radius: 50%; /* Makes the border circular */
    // padding: 2px; /* Adjust as needed */
 
}

.xjbuttonIcon {
    position: absolute;
    top: 0;
    right: 0;
    color:white;
    font-size:18px;
    // border: 1px solid white;
    // border-radius: 50%; /* Makes the border circular */
    // padding: 2px; /* Adjust as needed */
 
}
.xjbuttonContent{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 10px; // Adjust as needed
    overflow: hidden; // Add this line
}


.xjrecepiesByCategorySelectButton2 {
    @extend .xjrecepiesByCategorySelectButton;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}

.xjbuttonIcon {
    position: absolute;
    top: 0;
    right: 0;
  }
  .xjbuttonContent{
    position: relative;
    width: 100%;
    height: 100%;
  }

.xjshowRecepiesTop {
    @include gadgets-to(mobile) {
        grid-area: c;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 87%;
        height:auto;
        max-height:15vh;
        overflow-y: scroll;
        overflow-x:hidden;

        &::-webkit-scrollbar {
            width: 5px;
        }
          
        /* Track */
        &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background:white;

        }
          
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }

         @media(max-height:690px) and (max-width:360px) {
            max-height:13vh;
    
    }
    }

@include gadgets-to(tablet) {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height:auto;
    min-height:1px;
    max-height:50vh;
    overflow-y: scroll;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;

    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

}
@include gadgets-to(laptop) {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height:auto;
    min-height:1px;
    max-height:62vh;
    overflow-y: scroll;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;

    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

}
@include gadgets-to(desktop) {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height:auto;
    min-height:1px;
    max-height:62vh;
    overflow-y: scroll;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;

    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

}
@include gadgets-to(largeDesktop) {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 87%;
    height:auto;
    min-height:1px;
    max-height:62vh;
    overflow-y: scroll;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;

    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

}
}
.xjrecepyHeaderContainer{

    @include gadgets-to(tablet) {
height:3vh;
@media(max-width:1080px){
    height:4vh;
    padding:3px;
}
@media(min-width: 1100px) and (max-width:1300px) {
    height:5vh;
    padding:3px;
}
@media(min-width: 1301px) and (max-width:1438px) {
     height:3.3vh; 
    }

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
}
}

.xjrecepyHeaderContainerJAP{
    @include gadgets-to(tablet) {
        height:3vh;
        @media(max-width:1024px){
            height:4.5vh;
        }
        @media(min-width:1025px) and (max-width:1080px){
            height:4.6vh;
        }
        @media(min-width: 1100px) and (max-width:1300px) {
            height:4.5vh;
        }
        @media(min-width: 1301px) and (max-width:1438px) {
             height:3.3vh; 
            }
        
        }
}


.xjrecepyHeader {
    @include gadgets-to(mobile) {
width:80vw;
font-family: map-get($generalStyles, fontFamily);
font-size: 18px;
font-style: italic;
font-weight: bold;
outline: none;
background-color: transparent;
border: none;
margin-left:1vw;
margin-top: 4px;
color: map-get($generalStyles, colorblack);

    }
@include gadgets-to(tablet) {
    width:95%;
  font-family:var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left:5px;
    margin-top: 4px;
    color: map-get($generalStyles, colorblack);

    @media(max-width:1080px){
        margin-left:5px;
    }
    @media(min-width: 1100px) and (max-width:1300px) {
        margin-left:-2px;
    }
}
@include gadgets-to(laptop) {
    width:95%;
  font-family:var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left:1.5%;
    margin-top: 4px;
    color: map-get($generalStyles, colorblack);

}
@include gadgets-to(desktop) {
    width:88%;
  font-family:var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left:6%;
    margin-top: 4px;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(largeDesktop) {
    width:88%;
  font-family:var(--font-family);
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left:6%;
    margin-top: 4px;
    color: map-get($generalStyles, colorblack);
}
}

.xjrecepyHeaderJ1{
    @extend .xjrecepyHeader;
    @include gadgets-to(tablet) {
    height:30px;
}
@include gadgets-to(laptop) {
    height:30px;
}
@include gadgets-to(desktop) {
    height:30px;
}
@include gadgets-to(largeDesktop) {
    height:30px;
}
}



.xjrecepyHeaderJAP {
    @include gadgets-to(mobile) {

    width:95%;
    font-family: arial; // Assuming arial font is available
    font-size:map-get($generalStyles, fontsizeFurigana);
    font-style: italic;
    font-weight: bold;
    z-index: 1;
    outline: none;
    background-color: transparent;
    border: none;
   margin-left:2px;
   // margin-top: 70px;
    margin-bottom: 3px;
    color: black; // Assuming black color is desired
    background-color: white;
    border-radius:6px; // Assuming 3px border radius is desired
    
  
    &::placeholder { // Targeting placeholder text
      color:black;
      font-size: 12px; // Adjust the font size of the placeholder
    }
}
@include gadgets-to(tablet) {
    position:relative;
    width:95%;
    font-family: arial; // Assuming arial font is available
    font-size:map-get($generalStyles, fontsizeFurigana);
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left:2px;
   // margin-top: 70px;
    margin-bottom: 3px;
    color: black; // Assuming black color is desired
    background-color: white;
    border-radius:6px; // Assuming 3px border radius is desired
    z-index:10;
  
    &::placeholder { // Targeting placeholder text
      color:black;
      font-size: 12px; // Adjust the font size of the placeholder
    }
  }
@include gadgets-to(laptop) {
    position:relative;
    width:95%;
    font-family: arial; // Assuming arial font is available
    font-size:map-get($generalStyles, fontsizeFurigana);
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left:1.5%;
   // margin-top: 70px;
    margin-bottom: 3px;
    color: black; // Assuming black color is desired
    background-color: white;
    border-radius:6px; // Assuming 3px border radius is desired
    z-index:10;
  
    &::placeholder { // Targeting placeholder text
      color:black;
      font-size: 12px; // Adjust the font size of the placeholder
    }
  }
  @include gadgets-to(desktop) {
    position:relative;
    width:95%;
    font-family: arial; // Assuming arial font is available
    font-size:map-get($generalStyles, fontsizeFurigana);
    font-style: italic;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin-left:1.5%;
   // margin-top: 70px;
    margin-bottom: 3px;
    color: black; // Assuming black color is desired
    background-color: white;
    border-radius:6px; // Assuming 3px border radius is desired
    z-index:10;
  
    &::placeholder { // Targeting placeholder text
      color:black;
      font-size: 12px; // Adjust the font size of the placeholder
    }
  }
    @include gadgets-to(largeDesktop) {
        position:relative;
        width:95%;
        font-family: arial; // Assuming arial font is available
        font-size:map-get($generalStyles, fontsizeFurigana);
        font-style: italic;
        font-weight: bold;
        outline: none;
        background-color: transparent;
        border: none;
        margin-left:1.5%;
     // margin-top: 70px;
        margin-bottom: 3px;
        color: black; // Assuming black color is desired
        background-color: white;
        border-radius:6px; // Assuming 3px border radius is desired
        z-index:10;
    
        &::placeholder { // Targeting placeholder text
        color:black;
        font-size: 12px; // Adjust the font size of the placeholder
        }
    }
}


.xjheader {
   // grid-area: b;
   // margin-left: 7px;
   display:flex;
    flex-direction:column;
    justify-content: center;
    width:100%;
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items: center;
        width:100%;
    }
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    width:100%;
}
}
.xjheaderContainer{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        //justify-content:center;
        width:100%;
    }
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
   // justify-content:center;
    width:100%;
    margin-left:1%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
   // justify-content:center;
    width:100%;
    margin-left:1.5%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
   // justify-content:center;
    width:90%;
    margin-left:1.5%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
   // justify-content:center;
    width:90%;
    margin-left:1.5%;
}

}



.xjheaderButtonBenaemn {
    width:37%;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 10px;
    border-radius: map-get($generalStyles, sixBorderRadius);
  //  margin-left: 1px;
    margin-bottom: 2px;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
}
.xjheaderButtonAmount{
    width:calc(15% - 2px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 10px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
}

.xjheaderButton{
    width:calc(17% - 1px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 10px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 1px;
    margin-bottom: 2px;
    font-weight: bold;
}

.xjheaderKcals{
    width:calc(14% - 1px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 10px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 1px;
    margin-bottom: 2px;
    font-weight: bold;
    cursor: pointer;
}

.xjheaderOrder {
    width:calc(12% - 1px);
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 10px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 1px;
    margin-bottom: 2px;
    font-weight: bold;
    cursor: pointer;
    cursor: pointer;
}
.xjheaderDelete{
    width:calc(7% - 2px);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border:1px solid;
    border-color:map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    height:25px;
    margin-right:1px;
    margin-left:2px;
}
.xjheaderDelete1{
@extend .xjheaderDelete;
    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        width:calc(8.1% - 1px);
    }
}
.xjreceivedTop{
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
   
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    align-items: center;
}
}


.xjreceivedContainer {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-content: center;
        min-height:1px;
        max-height:34vh;
        height:34vh;
        overflow-y: scroll;
        width:100%;
        font-family:var(--font-family);
        font-size: 12px;
        //360
        @media(max-width:360px) and (max-height:690px) {
            height:32vh;
        }
        @media(max-width:360px) and (max-height:790px) {
            height:31vh;
        }

        //375
        @media(min-width:375px) and (max-width:380px){
            height:30vh;
            max-height:30vh;
        }
        //384
        @media(min-width:381px) and (max-width:385px){
            height:38vh;
            max-height:36vh;
        }
        //412
        @media(min-width:411px) and (max-width:413px)
        and (min-height:750px)
        {
            height:30vh;
            max-height:30vh;
        }
             //412
             @media(min-width:411px) and (max-width:413px)
             and (max-height:700px)
             {
                 height:32vh;
                 max-height:32vh;
             }

             //428
             @media(min-width:428px) and (max-width:430px)
             {
                 height:37vh;
                 max-height:37vh;
             }
             

        



        &::-webkit-scrollbar {
            width: 1px;
        }
          
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888; 
        }
          
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }

       
   


    }
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    //align-content: center;
    min-height:1px;
    max-height:58vh;
    height:58vh;
    overflow-y:auto;
    width:100%;
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:1%;
    @media (min-width: 1100px) {
        height:48vh;
    }


    &::-webkit-scrollbar {
        width: 1px;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, transparentChangeableMenu5);
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    //align-content: center;
    min-height:1px;
    max-height:60vh;
    height:60vh;
    overflow-y:auto;
    width:100%;
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:1.5%;

    &::-webkit-scrollbar {
        width:10px;
        cursor:pointer;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, transparentChangeableMenu5);
       
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
     
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background:white;
        cursor:pointer;
     
    }

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    //align-content: center;
    min-height:1px;
    max-height:57vh;
    height:57vh;
    overflow-y:auto;
    width:90%;
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:1.5%;

    &::-webkit-scrollbar {
        width:1px;
        cursor:pointer;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, transparentChangeableMenu5);
       
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
     
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background:white;
        cursor:pointer;
     
    }

}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    //align-content: center;
    min-height:1px;
    max-height:65vh;
    height:65vh;
    overflow-y:auto;
    width:90%;
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:1.5%;

    &::-webkit-scrollbar {
        width:1px;
        cursor:pointer;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, transparentChangeableMenu5);
       
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
     
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background:white;
        cursor:pointer;
     
    }

}
}

.xjdatarows{
    display:flex;
    flex-direction:row;
  align-items:center;
    justify-content:center;
 //   margin-left:2px;

}


.xjinfobuttonBenaemn{
    @include gadgets-to(mobile) {
        max-width:37%;
        min-width:1px;
        width:42%;
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
       // margin-left: 1px;
        margin-bottom: 2px;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
    }
    @include gadgets-to(tablet) {
        max-width:37%;
        min-width:1px;
        width:42%;
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
       // margin-left: 1px;
        margin-bottom: 2px;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
@include gadgets-to(laptop) {
    max-width:37%;
    min-width:1px;
    width:42%;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 14px;
    border-radius: map-get($generalStyles, sixBorderRadius);
   // margin-left: 1px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}
@include gadgets-to(desktop) {
    max-width:37%;
    min-width:1px;
    width:42%;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 14px;
    border-radius: map-get($generalStyles, sixBorderRadius);
   // margin-left: 1px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@include gadgets-to(largeDesktop) {
    max-width:37%;
    min-width:1px;
    width:42%;
    height:40px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 16px;
    border-radius: map-get($generalStyles, sixBorderRadius);
   // margin-left: 1px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
}

.xjinfobuttonBenaemn2{
    @extend .xjinfobuttonBenaemn;
    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(laptop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(desktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }

}
.xjinfobuttonAmount{
    @include gadgets-to(mobile) {
        width:calc(15% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
@include gadgets-to(tablet) {
        width:calc(15% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
@include gadgets-to(laptop) {
        width:calc(15% - 2px);
        height: 30px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
    @include gadgets-to(desktop) {
        width:calc(15% - 2px);
        height: 35px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
    @include gadgets-to(largeDesktop) {
        width:calc(15% - 2px);
        height: 40px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 16px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }

}

.xjinfobuttonAmount2{
    @extend .xjinfobuttonAmount;
    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(laptop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(desktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }

}
.xjinfoButton{
    @include gadgets-to(mobile) {
        width:calc(17% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
    @include gadgets-to(tablet) {
        width:calc(17% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
@include gadgets-to(laptop) {
        width:calc(17% - 2px);
        height: 30px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
@include gadgets-to(desktop) {
        width:calc(17% - 2px);
        height: 35px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }
    @include gadgets-to(largeDesktop) {
        width:calc(17% - 2px);
        height: 40px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 16px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
   
    }

}
.xjinfoButton2{
    @extend .xjinfoButton;
    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(laptop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(desktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }

}
.xjkcalsButton{
    @include gadgets-to(mobile) {
        width:calc(14% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(tablet) {
        width:calc(14% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(laptop) {
        width:calc(14% - 2px);
        height: 30px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(desktop) {
        width:calc(14% - 2px);
        height: 35px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(largeDesktop) {
        width:calc(14% - 2px);
        height: 40px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 16px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;

}
}

.xjkcalsButton2{
    @extend .xjkcalsButton;
    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(tablet) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(laptop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(desktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
}
.xjarborderButton{
    @include gadgets-to(mobile) {
        width:calc(12% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(tablet) {
        width:calc(12% - 2px);
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(laptop) {
        width:calc(12% - 2px);
        height: 30px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(desktop) {
        width:calc(12% - 2px);
        height: 35px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }
@include gadgets-to(largeDesktop) {
        width:calc(12% - 2px);
        height: 40px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 16px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        padding: 1px;
    }

}
.xjarborderButton2{
    @extend .xjarborderButton;
    @include gadgets-to(mobile) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(tablet) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(laptop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(desktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);

    }
}
// .xjButtonDelete{
//     width:7.4%;
//     height:25px;
//     border:1px solid;
//     border-color:map-get($generalStyles, datalistitemColor);
//     background-color: map-get($generalStyles, transparentChangeableMenu5);
//     border-radius: map-get($generalStyles, sixBorderRadius);
// }



.xjdeletebackline {
    @include gadgets-to(mobile) {
    font-size: 12px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
    border:0;
    margin:0;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    font-size: 16px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
    border:0;
    margin:0;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    font-size: 16px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
    border:0;
    margin:0;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(desktop) {
    font-size: 16px;
    color: map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
    border:0;
    margin:0;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    font-size: 24px;
    cursor: pointer;
    border:0;
    margin:0;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
}

// .range1 {
//     position: absolute;
//     top: 108px;
//     left: 220px;
//     accent-color: green;
//     background-color: red;
//     color: red;
// }

// .range2 {
//     position: absolute;
//     top: 108px;
//     left: 60px;
//     accent-color: rgb(251, 239, 132);
//     background: red;
// }

.xjtotalCostContainer {
    @include gadgets-to(mobile) {
        // display: flex;
        // flex-direction: row;
        // width: 99%;
    }
    @include gadgets-to(tablet) {
        // display: flex;
        // flex-direction: row;
        // width: 99%;
    }
@include gadgets-to(laptop) {
        // display: flex;
        // flex-direction: row;
        // width: 99%;
        // margin-left:5px;
  
    }
@include gadgets-to(desktop) {
        // display: flex;
        // flex-direction: row;
        // width: 99%;
        // margin-left:5px;
    }
@include gadgets-to(largeDesktop) {
        // display: flex;
        // flex-direction: row;
        // width: 99%;
        // margin-left:5px;
    }
}

.avgcaloriesButton {
    height: 20px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
}

.xjtotalcostBenaemn {
    @include gadgets-to(mobile) {
        width:36.4%;
        text-align: left;
        height: 20px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 2px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
@include gadgets-to(tablet) {
    width:36.4%;
    text-align: left;
    height: 20px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
}
@include gadgets-to(laptop) {
    width:36.6%;
    text-align: left;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
}
@include gadgets-to(desktop) {
    width:36.6%;
    text-align: left;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
}
@include gadgets-to(largeDesktop) {
    width:36.6%;
    text-align: left;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 2px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
}

}

.xjtotalamountButton{
    width:calc(15% - 3px);
    height: 20px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left:3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
    @include gadgets-to(laptop) {
        width:calc(15% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
@include gadgets-to(desktop) {
        width:calc(15% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
@include gadgets-to(largeDesktop) {
        width:calc(15% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
}

.xjtotalcostButton{
    width:calc(17% - 3px);
    height: 20px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;
    @include gadgets-to(laptop) {
        width:calc(17% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
@include gadgets-to(desktop) {
        width:calc(17% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
@include gadgets-to(largeDesktop) {
        width:calc(17% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
}



.xjtotalcaloriesButton {
    width:calc(14.2% - 3px);
    height: 20px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 3px;
    margin-bottom: 2px;
    text-align: middle;
    white-space: nowrap;

    @include gadgets-to(laptop) {
        width:calc(14.2% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
@include gadgets-to(desktop) {
        width:calc(14.2% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(14.2% - 3px);
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:3px;
        margin-bottom: 2px;
        text-align: middle;
        white-space: nowrap;
    }
}

.xjavgcaloriesButton {
    @include gadgets-to(mobile) {
        width:calc(12% - 2px);
        height:22px;
        border:1px solid;
        border-radius: map-get($generalStyles, sixBorderRadius);
        border-color:map-get($generalStyles, datalistitemColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        //font-weight: bold;
        cursor: pointer;
        text-align: left;
        margin-left:2px;
        font-size:9px;
        padding:1px;

}
@include gadgets-to(tablet) {
    width:calc(12% - 2px);
    height:22px;
    border:1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color:map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    //font-weight: bold;
    cursor: pointer;
    font-size:11px;
    padding:1px;
    color:map-get($generalStyles, colorblack);
    text-align: center;
    // here we do elipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin:1px;
}
@include gadgets-to(laptop) {
    width:calc(12% - 2px);
    height:26px;
    border:1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color:map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    //font-weight: bold;
    cursor: pointer;
    font-size:14px;
    font-weight:bold;
    font-style: italic;
   // padding:1px;
    color:map-get($generalStyles, colorblack);
    text-align: center;
    // here we do elipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left:2px;
}
@include gadgets-to(desktop) {
    width:calc(12% - 2px);
    height:26px;
    border:1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color:map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    //font-weight: bold;
    cursor: pointer;
    font-size:14px;
    font-weight:bold;
    font-style: italic;
   // padding:1px;
    color:map-get($generalStyles, colorblack);
    text-align: center;
    // here we do elipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left:2px;
}
@include gadgets-to(largeDesktop) {
    width:calc(12% - 2px);
    height:26px;
    border:1px solid;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border-color:map-get($generalStyles, datalistitemColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    //font-weight: bold;
    cursor: pointer;
    font-size:14px;
    font-weight:bold;
    font-style: italic;
   // padding:1px;
    color:map-get($generalStyles, colorblack);
    text-align: center;
    // here we do elipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left:2px;
}

}

.xjselectnewingredientContainer {
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 3px;
        width: 99%;
        border: none;
    }
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3px;
    width: 99%;
    border:none;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content:center;
    margin-top: 3px;
    width: 99%;
    border:none;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content:center;
    margin-top: 3px;
    width: 99%;
    border:none;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content:center;
    margin-top: 3px;
    width: 99%;
    border:none;
}
}

.xjselectnewingredientFieldset {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        width:24%;
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        height: 40px;
    }
@include gadgets-to(tablet) {
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    border:none;
   // border-color: map-get($generalStyles, datalistBorderColor);
    width:20%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 40px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    border:none;
   // border-color: map-get($generalStyles, datalistBorderColor);
    width:20%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 40px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    border:none;
   // border-color: map-get($generalStyles, datalistBorderColor);
    width:20%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 40px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    border:none;
   // border-color: map-get($generalStyles, datalistBorderColor);
    width:20%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 40px;
}
 
}

.xjselectnewingredientLegend{
    @include gadgets-to(mobile) {
        // font-family: map-get($generalStyles, fontFamily);
        // font-size: 12px;
        // white-space: nowrap;
    
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
   // margin-left:1%;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
   // margin-left:1%;
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
   // margin-left:1%;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
   // margin-left:1%;
}

}


.xjselectdbOwnerIngredient {
    @include gadgets-to(mobile) {
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 100%;
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        height: 25px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: white;
    }
@include gadgets-to(tablet) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
}
@include gadgets-to(laptop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
}
@include gadgets-to(desktop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
}
@include gadgets-to(largeDesktop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    height: 25px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
}
}

.xjnumPortionsContainer {
    @include gadgets-to(mobile) {
        width:17%;
        border: none;
        height: 50px;
    }
    @include gadgets-to(tablet) {
        width:22%;
        border:none;
        height: 50px;
    }
@include gadgets-to(laptop) {
        width:22%;
        border:none;
        height: 50px;
    }
    @include gadgets-to(desktop) {
        width:22%;
        border:none;
        height: 50px;
    }
@include gadgets-to(largeDesktop) {
        width:22%;
        border:none;
        height: 50px;
    }
}

.xjselectPortions {
    @include gadgets-to(mobile) {
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        width: 50px;
        outline: none;
        height: 25px;
        background-color: white;
        border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(tablet) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:map-get($generalStyles, colorblack);
}
@include gadgets-to(laptop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:map-get($generalStyles, colorblack);
}
@include gadgets-to(desktop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:map-get($generalStyles, colorblack);
}
@include gadgets-to(largeDesktop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 25px;
    background-color: white;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:map-get($generalStyles, colorblack);
}
}


.xjnumPortionsFieldset {
    @include gadgets-to(mobile) {
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        width: 50px;
        outline: none;
        height: 40px;
        color: map-get($generalStyles, colorblack);
    }
@include gadgets-to(tablet) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:60%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(laptop) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:60%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(desktop) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:60%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(largeDesktop) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:60%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
}

}

.xjnumPortionsLegend{
    @include gadgets-to(mobile) {
        // font-family: map-get($generalStyles, fontFamily);
        // font-size: 12px;
    

}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
}
}


.xjsizeportionsIngramsFieldset {
    @include gadgets-to(mobile) {
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        width:20%;
        outline: none;
        height: 40px;
        color: map-get($generalStyles, colorblack);
        margin-left: 2px;
    }
@include gadgets-to(tablet) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:25%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
    margin-left: 2px;

}
@include gadgets-to(laptop) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:25%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
    margin-left: 2px;

}
@include gadgets-to(desktop) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:25%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
    margin-left: 2px;

}
@include gadgets-to(largeDesktop) {
    border:none;
  //  border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:25%;
    outline: none;
    height: 40px;
    color: map-get($generalStyles, colorblack);
    margin-left: 2px;
}
}
.xjsizeportionsIngramsLegend{
    @include gadgets-to(mobile) {
        // font-family: map-get($generalStyles, fontFamily);
        // font-size: 12px;
    
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;

}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
    font-size: 12px;
    white-space: nowrap;
}
}





.xjsizeportionsIngramsInput {
    @include gadgets-to(mobile) {
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
      font-family:var(--font-family);
        font-size: 12px;
        width: 88%;
        outline: none;
        height: 22px;
        color: map-get($generalStyles, colorblack);
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: white;
 
}
@include gadgets-to(tablet) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 20px;
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
    &::placeholder{
        color:black;
        font-size:11px;
    }
} 
@include gadgets-to(laptop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 20px;
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
    &::placeholder{
        color:black;
        font-size:11px;
    }
}
@include gadgets-to(desktop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 20px;
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
    &::placeholder{
        color:black;
        font-size:11px;
    }
}
@include gadgets-to(largeDesktop) {
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
  font-family:var(--font-family);
    font-size: 12px;
    width:100%;
    outline: none;
    height: 20px;
    color: map-get($generalStyles, colorblack);
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: white;
    color:map-get($generalStyles, colorblack);
    &::placeholder{
        color:black;
        font-size:11px;
    }
}
}

.xjsaveRecepyButton {
    @include gadgets-to(mobile) {
        width:70px;
        height: 25px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: 3px;
        margin-left: 1px;
        margin-right: 4px;
        margin-top: 15px;
        cursor: pointer;
        opacity: 0.8;
        padding: 1px;
      

}
@include  gadgets-to(tablet) {
    width:100%;
    height: 25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
}
@include gadgets-to(laptop) {
    width:100%;
    height:25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
    padding:3px;
}
@include gadgets-to(desktop) {
    width:100%;
    height:25px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
    padding:3px;
}
@include gadgets-to(largeDesktop) {
    width:100%;
    height:40px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 16px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    margin-left: 1px;
    margin-right: 4px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;
    padding: 1px;
    white-space: nowrap;
    padding:3px;
}
}



.xjsaveRecepyButtonActive {
    @extend .xjsaveRecepyButton;
    background-color:map-get($generalStyles,pinkRed);
    color:white;
    opacity:1;

}

.xjsaverecepybuttoncontainer{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        width:100%;
        height:45px;
    }
    @include gadgets-to(tablet) {
        // display:flex;
        // flex-direction:column;
        // justify-content:flex-end;
        width:100%;
        height:45px;
        border:none;
        margin-top:3px;
        margin-left:1vw;
    }
@include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        width:100%;
        height:45px;
        border:none;
        margin-top:3px;
        margin-left:1vw;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        width:100%;
        height:45px;
        border:none;
        margin-top:3px;
        margin-left:1vw;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        width:100%;
        height:45px;
        border:none;
        margin-top:3px;
        margin-left:1vw;
    }
}

.xjreceptSizeFieldset {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-items: center;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
  font-family:var(--font-family);
    font-size: 12px;
    width:90%;
    height: 30px;
    color: map-get($generalStyles, colorblack);
    margin-left: 10px;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:column;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        width:90%;
        height: 30px;
        color: map-get($generalStyles, colorblack);
        margin-left: 10px;
    }

    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        width:90%;
        height: 30px;
        color: map-get($generalStyles, colorblack);
        margin-left: 10px;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        width:90%;
        height: 30px;
        color: map-get($generalStyles, colorblack);
        margin-left: 10px;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        outline: none;
      font-family:var(--font-family);
        font-size: 12px;
        width:90%;
        height: 30px;
        color: map-get($generalStyles, colorblack);
        margin-left: 10px;
    }

}

.xjreceptSizeLegend {
    @include gadgets-to(mobile) {
        // font-family: map-get($generalStyles, fontFamily);
        // font-size: 12px;
        // white-space: nowrap;
        margin-left:13vw;
    }
@include gadgets-to(tablet) {
    // font-family: map-get($generalStyles, fontFamily);
    // font-size: 12px;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:6.5vw;
    
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:6.5vw;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:6.5vw;
}

}

.xjrangeGrid {
    display: flex;
    justify-content: space-between;
    width:70%;
    border: none;
}

.xjrangeinput {
    width:70%;
}

.xjnutrientSummaryContainer {
    @include gadgets-to(mobile) {
        grid-area: d;
        // display:flex;
        // flex-direction:column;
        // justify-content:center;
        width:99%;
        height:10vh;
       // margin-top:2vh;
        border: none;
      //  margin-left:2%;
      margin-bottom:30px;
    }
@include gadgets-to(tablet) {
    grid-area: d;
    // display:flex;
    // flex-direction:column;
    // justify-content:center;
    width:99%;
    height:10vh;
   // margin-top:2vh;
    border: none;
  //  margin-left:2%;
  margin-bottom:30px;


}
@include gadgets-to(laptop) {
    grid-area: d;
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:99%;
    height:10vh;
   // margin-top:2vh;
    border: none;
  //  margin-left:2%;
  margin-bottom:30px;

}
@include gadgets-to(desktop) {
    grid-area: d;
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:99%;
    height:10vh;
   // margin-top:2vh;
    border: none;
  //  margin-left:2%;
  margin-bottom:30px;

}
@include gadgets-to(largeDesktop) {
    grid-area: d;
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:99%;
    height:10vh;
   // margin-top:2vh;
    border: none;
  //  margin-left:2%;
  margin-bottom:30px;

}
}

.xjviewNutrientData {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        border: none;
        //  margin-left:1%;
          width:99%;
          overflow:hidden;
          height:50px;
          margin-top:10px;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        border: none;
        margin-top:1vh;
        //  margin-left:1%;
        width:99%;
        overflow:hidden;
        height:50px;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        border: none;
        margin-top:1vh;
        //  margin-left:1%;
        width:99%;
        overflow:hidden;
        height:50px;
    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        border: none;
        margin-top:1vh;
        //  margin-left:1%;
        width:99%;
        overflow:hidden;
        height:50px;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        border: none;
        margin-top:1vh;
        //  margin-left:1%;
        width:99%;
        overflow:hidden;
        height:50px;
    }


}

.xjnutrientsummaryButton{
    width:15%;
    height: 30px;
    border: none;
    background-color: transparent;
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-top: 2px;
}

.xjnutrientSummaryButtonKcals {
    @extend .xjnutrientsummaryButton;
    @include gadgets-to(mobile) {
        white-space: nowrap;
    }
    @include gadgets-to(tablet) {
        white-space: nowrap;
    }
    @include gadgets-to(laptop) {
        white-space: nowrap;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        white-space: nowrap;
        font-size: 14px;
}
@include gadgets-to(largeDesktop) {
        white-space: nowrap;
        font-size: 14px;
}
}

.xjnutrientSummaryButtonProtein{
    @extend .xjnutrientsummaryButton;
    @include gadgets-to(mobile) {
        white-space: nowrap;
    }
    @include gadgets-to(tablet) {
        white-space: nowrap;
    }
    @include gadgets-to(laptop) {
        white-space: nowrap;
        font-size: 14px;
    } 
    @include gadgets-to(desktop) {
        white-space: nowrap;
        font-size: 14px;
    }
    @include gadgets-to(largeDesktop) {
        white-space: nowrap;
        font-size: 14px;
    }

}
.xjnutrientSummaryButtonCarb {
    @extend .xjnutrientsummaryButton;
     width:15%;
     @include gadgets-to(mobile) {
        white-space: nowrap;
        width:18%;
    }
    @include gadgets-to(tablet) {
        white-space: nowrap;
    }
    @include gadgets-to(laptop) {
        white-space: nowrap;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        white-space: nowrap;
        font-size: 14px;
    }
    @include gadgets-to(largeDesktop) {
        white-space: nowrap;
        font-size: 14px;
    }
}
.xjnutrientSummaryBFats {
    @extend .xjnutrientsummaryButton;
    @include gadgets-to(mobile) {
        white-space: nowrap;
    }
    @include gadgets-to(tablet) {
        white-space: nowrap;
    }
    @include gadgets-to(laptop) {
        white-space: nowrap;
        font-size: 14px;
    }
@include gadgets-to(desktop) {
        white-space: nowrap;
        font-size: 14px;
    }
@include gadgets-to(largeDesktop) {
        white-space: nowrap;
        font-size: 14px;
    }
}

// .xjmeatIcon, .xjcheeseIcon, .xjwheatIcon, .xjfireIcon {
//     font-size: 22px;
//     color: map-get($generalStyles, colorblack);
//     margin-left: 10px;
// }

.xjfireIcon {
    @include gadgets-to(mobile) {
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }
    @include gadgets-to(tablet) {
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }
    @include gadgets-to(laptop) {
        font-size:30px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }
    @include gadgets-to(desktop) {
        font-size:30px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }
    @include gadgets-to(largeDesktop) {
        font-size:36px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }

}
.xjcheeseIcon {
    @include gadgets-to(mobile) {
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }
@include gadgets-to(tablet) {
    font-size: 22px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(laptop) {
    font-size: 30px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(desktop) {
    font-size: 30px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(largeDesktop) {
    font-size: 40px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
}
.xjmeatIcon{
    @include gadgets-to(mobile) {
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }
@include gadgets-to(tablet) {
    font-size: 22px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(laptop) {
    font-size: 30px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(desktop) {
    font-size: 30px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(largeDesktop) {
    font-size: 40px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
}

.xjwheatIcon{
    @include gadgets-to(mobile) {
        font-size: 22px;
        color: map-get($generalStyles, colorblack);
        margin-left:1%;
    }
@include gadgets-to(tablet) {
    font-size: 22px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(laptop) {
    font-size: 30px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(desktop) {
    font-size: 30px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
@include gadgets-to(largeDesktop) {
    font-size: 36px;
    color: map-get($generalStyles, colorblack);
    margin-left:1%;
}
}

.xjtextareaContainer {
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width:98%;
        border: 1px solid;
        height: 70px;
        border-color: map-get($generalStyles, datalistBorderColor);
    }
@include gadgets-to(tablet) {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:98%;
    border: 1px solid;
    height:14.5vh;
    border-color: map-get($generalStyles, datalistBorderColor);
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:98%;
    border: 1px solid;
    height:14.5vh;
    border-color: map-get($generalStyles, datalistBorderColor);
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:98%;
    border: 1px solid;
    height:14.5vh;
    border-color: map-get($generalStyles, datalistBorderColor);
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:98%;
    border: 1px solid;
    height:14.5vh;
    border-color: map-get($generalStyles, datalistBorderColor);
}
}

          

.xjtextareaComments {
    @include gadgets-to(mobile) {
        width:48%;
        height: 45px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        background-color: map-get($generalStyles, datalistbackgroundColor);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: 3px;
        margin-left: 2px;
        margin-bottom: 2px;
        font-weight: bold;
        padding: 1px;
        outline: none;
        resize: none;
    }
    @include gadgets-to(tablet) {
        width:52%;
        height:12vh;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        background-color: map-get($generalStyles, datalistbackgroundColor);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size: 12px;
        border-radius: 3px;
        margin-left: 2px;
        margin-bottom: 2px;
        font-weight: bold;
        padding: 1px;
        outline: none;
        resize: none;
    }
@include gadgets-to(laptop) {
    width:52%;
    height:12vh;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
}
@include gadgets-to(desktop) {
    width:52%;
    height:12vh;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
}
@include gadgets-to(largeDesktop) {
    width:60.5%;
    height:12vh;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, datalistbackgroundColor);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: bold;
    padding: 1px;
    outline: none;
    resize: none;
}

}

.xjAiOutlinePlusCircle {

    @include gadgets-to(mobile) {
        color: map-get($generalStyles, transparentChangeableMenu5);
        font-size: 38px;
        cursor: pointer;
    }
    @include gadgets-to(tablet) {
        color: map-get($generalStyles, transparentChangeableMenu5);
        font-size: 38px;
        cursor: pointer;
    }
@include gadgets-to(laptop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 46px;
    cursor: pointer;
}
@include gadgets-to(desktop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 46px;
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 46px;
    cursor: pointer;
}

}

.xjbuttonFoodInfo {
    width:25%;
    height:30px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size: 10px;
    border-radius: 3px;
    margin-left:2%;
    margin-bottom: 2px;
    margin-top: 2px;
    cursor: pointer;
}
.xjnumTecken{
  font-family:var(--font-family);
    font-size: 12px;

}
.xjfoodAreaHeader {
    @include gadgets-to(mobile) {
        margin-top: 5px;
        width:100%;
    }
    @include gadgets-to(tablet) {
        margin-top: 5px;
        width:100%;
    
    }
@include gadgets-to(laptop) {
        margin-top: 5px;
        width:100%;
    
    }
    @include gadgets-to(desktop) {
        margin-top: 5px;
        width:100%;
    
    }
    @include gadgets-to(largeDesktop) {
        margin-top: 5px;
        width:100%;
    
    }
}

.xjfoodArea {
    @include gadgets-to(mobile) {
        width:100%;
    }
    @include gadgets-to(tablet) {
        width:100%;
      
    }
@include gadgets-to(laptop) {
        width:100%;
      
    }
    @include gadgets-to(desktop) {
        width:100%;
      
    }
    @include gadgets-to(largeDesktop) {
        width:100%;
      
    }


}

.xjfoodarearows{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;

    

}

.xjheaderButtonNutrient {
    width:70%;
    text-align: left;
    margin-top: 1px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
  font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    color:map-get($generalStyles, colorblack);
}

.xjheaderButtonQuantity {
    width:calc(15% - 2px);
    text-align: left;
    margin-top: 1px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
  font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    margin-left: 2px;
    color:map-get($generalStyles, colorblack);
}
.xjheaderButtonUnit{
    width:14%;
    text-align: left;
    margin-top: 1px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
  font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    margin-left:2px;
    color:map-get($generalStyles, colorblack);
}

.xjfoodareaContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: transparent;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width:3px;
        
    }
    &::-webkit-scrollbar-track {
        background-color:map-get($generalStyles,transparentChangeableMenu5);
    
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

@include gadgets-to(laptop) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: transparent;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width:8px;
        
    }
    &::-webkit-scrollbar-track {
        background-color:map-get($generalStyles,transparentChangeableMenu5);
    
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
        cursor:pointer;
    }

}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: transparent;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width:8px;
        
    }
    &::-webkit-scrollbar-track {
        background-color:map-get($generalStyles,transparentChangeableMenu5);
    
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
        cursor:pointer;
    }

}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: transparent;
    overflow-x:hidden;

    &::-webkit-scrollbar {
        width:8px;
        
    }
    &::-webkit-scrollbar-track {
        background-color:map-get($generalStyles,transparentChangeableMenu5);
    
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
        cursor:pointer;
    }

}
}

.xjnutrientValue{
    width:70%;
    height: 20px;
    text-align: left;
    margin-top: 2px;
    border: none;
    padding-top: 3px;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    color:map-get($generalStyles, colorblack);
}
.xjnutrientValue2{
    @extend .xjnutrientValue;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xjnutrientQuantity{
    width:calc(15% - 2px);
    height:20px;
    text-align: left;
    margin-top: 2px;
    border: none;
    padding-top: 3px;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    margin-left:2px;
    color:map-get($generalStyles, colorblack);
}
.xjnutrientQuantity2{
@extend .xjnutrientQuantity;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xjnutrientUnit{
    width:calc(15% - 2px);
    height: 20px;
    text-align: left;
    margin-top: 2px;
    border: none;
    padding-top: 3px;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  font-family:var(--font-family);
    font-size: 12px;
    margin-left:2px;
    font-style: italic;
    color:map-get($generalStyles, colorblack);
}
.xjnutrientUnit2{
    @extend .xjnutrientUnit;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}


.xjviewRecepyCommentFromAiContainer {
    @include gadgets-to(mobile) {
grid-area:c;
    width:98%;
    height:5vh;
}
@include gadgets-to(desktop) {
    grid-area:c;

}
}


.xjviewRecepyCommentFromAiButton {
    @include gadgets-to(mobile) {
        width:90%;;
        height:23px;
        border:1px solid;
        border-color: map-get($generalStyles, datalistitemColor2);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size:14px;
        border-radius:3px;
        margin-left:2px;
        background-color: map-get($generalStyles, transparentChangeable);
        box-shadow:1vh 0.5vh 0.5vh 0.5vh rgba(0,0,0,0.2);
    }
    @include gadgets-to(tablet) {
        width:70%;
        height:50px;
        border:1px solid;

        border-color: map-get($generalStyles,transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
      font-family:var(--font-family);
        font-size:18px;
        border-radius:3px;
        margin-left:2px;
        background-color: map-get($generalStyles, transparentChangeable);
        box-shadow:1vh 0.2vh 0.5vh 0.5vh rgba(0,0,0,0.2);
    }    
@include gadgets-to(laptop) {
    width:80%;
    height:60px;
    border:2px solid map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size:20px;
    border-radius:6px;
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeable);
    box-shadow:0.5vh 0.3vh 0.3vh 0.2vh rgba(0,0,0,0.2);

}    
@include gadgets-to(desktop) {
    width:80%;
    height:50px;
    border:2px solid map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size:20px;
    border-radius:6px;
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeable);
    box-shadow:0.5vh 0.3vh 0.3vh 0.2vh rgba(0,0,0,0.2);

}
@include gadgets-to(largeDesktop) {
    width:80%;
    height:50px;
    border:2px solid map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family:var(--font-family);
    font-size:24px;
    border-radius:6px;
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeable);
    box-shadow:0.5vh 0.3vh 0.3vh 0.2vh rgba(0,0,0,0.2);

}    

}

.xjviewRecepyCommentFromAiHeader {
    @include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size:10px;
        color:map-get($generalStyles, colorblack);
        margin-left:3vw;
        margin-top:2px;
    }
    @include gadgets-to(laptop) {
      font-family:var(--font-family);
        font-size:10px;
        color:map-get($generalStyles, colorblack);
        margin-left:5.7vw;
        margin-top:2px;

}
@include gadgets-to(mobile) {
  font-family:var(--font-family);
    font-size:10px;
    color:map-get($generalStyles, colorblack);
    margin-left:3vw;
    margin-top:2px;
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
    font-size:10px;
    color:map-get($generalStyles, colorblack);
    margin-left:5.7vw;
    margin-top:2px;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
    font-size:10px;
    color:map-get($generalStyles, colorblack);
    margin-left:5.7vw;
    margin-top:2px;
}
}


.xjviewRecepyCommentFromAiHeader1 {
@extend .xjviewRecepyCommentFromAiHeader;
 color: map-get($generalStyles, colorblack);
}

.xjviewRecepyCommentFromAiFieldset {
    @include gadgets-to(mobile) {
        border:none;
        display:flex;
        flex-direction:row;
        justify-content:center;
       align-items:center;
    }
    @include gadgets-to(tablet) {
        border:none;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        height:10%;
    }
@include gadgets-to(laptop) {
    border:none;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:10%;
}
@include gadgets-to(desktop) {
    border:none;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:10%;
}
@include gadgets-to(largeDesktop) {
    border:none;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:10%;
}

}

.xjviewRecepyCommentFromAiLegend {
    @include gadgets-to(tablet) {
      font-family:var(--font-family);
        font-size: '14px';
        color: map-get($generalStyles, colorblack);
    
    }
@include gadgets-to(laptop) {
  font-family:var(--font-family);
    font-size: '14px';
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(mobile) {
  font-family:var(--font-family);
    font-size: '14px';
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
    font-size: '14px';
    color: map-get($generalStyles, colorblack);
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
    font-size: '14px';
    color: map-get($generalStyles, colorblack);
}

}

.xjviewdescription {
    @include gadgets-to(mobile) {
        grid-area:e;
        width:99%;
        height:41.3vh;
        overflow-y:scroll;
        margin-top:3px;
        border:none;
        resize:none;
        outline:none;
        background:white;
        border-radius: map-get($generalStyles, sixBorderRadius);
      font-family:var(--font-family);
        font-size:16px;
        color: map-get($generalStyles, colorblack);
        padding:3px;
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background-color:map-get($generalStyles,transparentChangeableMenu5);
       
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background:white;
        }
          
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }
    }
    @include gadgets-to(tablet) {
        grid-area:e;
        width:95%;
        height:65vh;
        max-height:65vh;
        overflow-y:auto;
        margin-top:3px;
        border:none;
        resize:none;
        outline:none;
        background:transparent;
        padding:10px;
       // border-radius: map-get($generalStyles, sixBorderRadius);
      font-family:var(--font-family);
        font-size:16px;
        color: map-get($generalStyles, colorblack);

        @media (max-width: 1080px) {
            height:75vh;
        }
        @media (min-width:1081) {
            height:78vh;
        }
       


        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background-color:map-get($generalStyles,transparentChangeableMenu5);
       
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background:white;
        }
          
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }
  
    }
@include gadgets-to(laptop) {
    grid-area:e;
    width:95%;
    height:60vh;
    max-height:60vh;
    overflow-y:auto;
    margin-top:3px;
    border:none;
    resize:none;
    outline:none;
    background:transparent;
    padding:10px;
   // border-radius: map-get($generalStyles, sixBorderRadius);
  font-family:var(--font-family);
    font-size:16px;
    color: map-get($generalStyles, colorblack);

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color:map-get($generalStyles,transparentChangeableMenu5);
   
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background:map-get($generalStyles,pinkRed);
        cursor:pointer;

    }


}
@include gadgets-to(desktop) {
    grid-area:e;
    width:95%;
    height:60vh;
    max-height:60vh;
    overflow-y:auto;
    margin-top:3px;
    border:none;
    resize:none;
    outline:none;
    background:transparent;
    padding:15px;
   // border-radius: map-get($generalStyles, sixBorderRadius);
  font-family:var(--font-family);
    font-size:16px;
    color: map-get($generalStyles, colorblack);

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color:map-get($generalStyles,transparentChangeableMenu5);
   
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background:map-get($generalStyles,pinkRed);
        cursor:pointer;

    }
}
@include gadgets-to(largeDesktop) {
    grid-area:e;
    width:90%;
    height:60vh;
    overflow-y:auto;
    margin-top:3px;
    border:none;
    resize:none;
    outline:none;
    background:transparent;
    padding:20px;
   // border-radius: map-get($generalStyles, sixBorderRadius);
  font-family:var(--font-family);
    font-size:18px;
    color: map-get($generalStyles, colorblack);

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color:map-get($generalStyles,transparentChangeableMenu5);
   
    }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background:white;
    }
      
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background:map-get($generalStyles,pinkRed);
        cursor:pointer;

    }
}
}
.xjviewdescription1 {
@include gadgets-to(mobile) {
    font-size: 20px;
    color: map-get($generalStyles, colorblack);
    background: white;
    margin-top:0px;
    height:64vh;
}
@include gadgets-to(tablet) {
    font-size: 20px;
    color: map-get($generalStyles, colorblack);
    background: white;
    margin-top:0px;
    height:63vh;
    margin-left:3px;
    padding:3px;

    @media (max-width: 1080px) {
        height:77vh;
    }
}
@include gadgets-to(laptop) {
    font-size: 22px;
    color: map-get($generalStyles, colorblack);
    background: white;
    margin-top:0px;
    height:63vh;
    margin-left:3px;
    padding:3px;

 
}
@include gadgets-to(desktop) {
    font-size: 22px;
    color: map-get($generalStyles, colorblack);
    background: white;
    margin-top:0px;
    height:60vh;
    margin-left:3px;
    padding:3px;

 
}
@include gadgets-to(largeDesktop) {
    font-size: 22px;
    color: map-get($generalStyles, colorblack);
    background: white;
    margin-top:0px;
    height:60vh;
    margin-left:3px;
    padding:3px;

 
}
}
.numTeckenTop{
    @include gadgets-to(mobile) {
// display:flex;
// flex-direction:row;
// justify-content:flex-end;
    
}
@include gadgets-to(tablet) {
    // display:flex;
    // flex-direction:row;
    // justify-content:flex-end;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    margin-right:1%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    margin-right:1%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    margin-right:1%;
}
}


.xjnumTecken1 {
    @include gadgets-to(mobile) {
        grid-area:e;
      font-family:var(--font-family);
        font-size:13px;
        margin-left:1vw;
        margin-top:2.5vh;
         
    @media(max-width:360px) and (max-height:690px) {
        margin-top:1vh; 
    }
}

@include gadgets-to(tablet) {
 grid-area:e;
  font-family:var(--font-family);
    font-size:13px;
    margin-left:1vw;
  //  margin-top:1.5vh;

    
    }
@include gadgets-to(laptop) {
    grid-area:e;
  font-family:var(--font-family);
    font-size:11px;
    margin-left:5px;
  //  margin-top:1.5vh;

    }
@include gadgets-to(desktop) {
    grid-area:e;
  font-family:var(--font-family);
    font-size:11px;
    margin-left:5px;
  //  margin-top:1.5vh;

    }
@include gadgets-to(largeDesktop) {
    grid-area:e;
  font-family:var(--font-family);
    font-size:11px;
    margin-left:5px;
  //  margin-top:1.5vh;

    }

}

.xjnumTecken2{
    @include gadgets-to(mobile) {
        grid-area:e;
      font-family:var(--font-family);
        font-size:13px;
        margin-left:1vw;
        margin-top:2.5vh;
        @media(max-width:360px) and (max-height:690px) {
            margin-top:1vh; 
        }
    }
@include gadgets-to(tablet) {
    grid-area:e;
  font-family:var(--font-family);
    font-size:13px;
    margin-left:1vw;
   // background: white;
   // margin-top:2.5vh;
 
}
@include gadgets-to(laptop) {
    grid-area:e;
  font-family:var(--font-family);
    font-size:11px;
    margin-left:1vw;
   // background: white;
   // margin-top:2.5vh;
 
}
@include gadgets-to(desktop) {
    grid-area:e;
  font-family:var(--font-family);
    font-size:11px;
    margin-left:1vw;
   // background: white;
   // margin-top:2.5vh;
 
}
@include gadgets-to(largeDesktop) {
    grid-area:e;
  font-family:var(--font-family);
    font-size:11px;
    margin-left:1vw;
   // background: white;
   // margin-top:2.5vh;
 
}
}

.xjrecepyDescriptionHeader {
    @include gadgets-to(mobile) {
        grid-area:e;
      font-family:var(--font-family);
        font-size:16px;
        font-weight:bold;
        margin-top:4px;
        margin-left:1vw;
    }
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    grid-area:e;
  font-family:var(--font-family);
    font-size:16px;
    font-weight:bold;
    margin-top:4px;
    margin-left:3px;

}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    grid-area:e;
  font-family:var(--font-family);
    font-size:22px;
    font-weight:bold;
    margin-top:4px;
    margin-left:3px;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    grid-area:e;
  font-family:var(--font-family);
    font-size:18px;
    font-weight:bold;
    margin-top:4px;
    margin-left:3px;

}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    grid-area:e;
  font-family:var(--font-family);
    font-size:18px;
    font-weight:bold;
    margin-top:4px;
    margin-left:3px;
}
}
.xjrecepyDescriptionHeader1 {
  @extend .xjrecepyDescriptionHeader;
  @include gadgets-to(mobile) {
    font-size:30px;

}
@include gadgets-to(tablet) {
    font-size:22px;
    background: white;
    border-top-left-radius: map-get($generalStyles, sixBorderRadius);
    width:100%;
    
//     display:flex;
//     flex-direction:row;
//   //  justify-content:space-around;
}
@include gadgets-to(laptop) {
    font-size:22px;
    background: white;
    border-top-left-radius: map-get($generalStyles, sixBorderRadius);
    width:100%;

}
@include gadgets-to(desktop) {
    font-size:22px;
    background: white;
    border-top-left-radius: map-get($generalStyles, sixBorderRadius);
    width:100%;

}
@include gadgets-to(largeDesktop) {
    font-size:22px;
    background: white;
    border-top-left-radius: map-get($generalStyles, sixBorderRadius);
    width:100%;

}
}

.xjresizeDescriptionText {
    @include gadgets-to(mobile) {
        position: 'absolute';
        top: '800px';
        left: '380px';
        font-size: '20px';
        cursor: 'pointer';

}
@include gadgets-to(tablet) {
    cursor:pointer;
    font-size:18px;
    margin-left:2%;
}
@include gadgets-to(laptop) {
    cursor:pointer;
    font-size:22px;
    margin-left:2%;
}
@include gadgets-to(desktop) {
    cursor:pointer;
    font-size:22px;
    margin-left:2%;
}
@include gadgets-to(largeDesktop) {
    cursor:pointer;
    font-size:22px;
    margin-left:2%;
}
}




.xjresizeDescriptionText1 {
    @include gadgets-to(mobile) {
        position: 'absolute';
        top: '800px';
        left: '120px';
        font-size: '20px';
        cursor: 'pointer';
    }
    @include gadgets-to(tablet) {
        font-size:26px;
       // margin-top:1vh;
        margin-right:3%;
        width:90%;
    }
    @include gadgets-to(laptop) {
        font-size:28px;
       // margin-top:1vh;
        margin-right:3%;
        width:90%;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
    font-size:28px;
   // margin-top:1vh;
    margin-right:3%;
    width:90%;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    font-size:28px;
   // margin-top:1vh;
    margin-right:3%;
    width:90%;
    cursor:pointer;
}
}

.xjsearchSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xjsearchSpinner {
    color: map-get($generalStyles, colorblack);
}

.xjdeleteRecepy {
@include gadgets-to(mobile) {
    font-size:22px;
  color:map-get($generalStyles,transparentChangeableMenu5);
    cursor: pointer;
    margin-right:4%;
    border:none;
    border-color:map-get($generalStyles,transparentChangeableMenu5);
    padding:7px;
    border-radius: 50%;
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    color:white;
 //   margin-bottom:5px;

}
@include gadgets-to(tablet) {
    font-size:26px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    cursor: pointer;
    margin-right:4%;
    border:none;
    border-color:map-get($generalStyles,transparentChangeableMenu5);
    padding:9px;
    border-radius: 50%;
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    color:white;
}
@include gadgets-to(laptop) {
    font-size:26px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    cursor: pointer;
    margin-right:4%;
    border:none;
    border-color:map-get($generalStyles,transparentChangeableMenu5);
    padding:9px;
    border-radius: 50%;
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    color:white;
}
@include gadgets-to(desktop) {
    font-size:26px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    cursor: pointer;
    margin-right:4%;
    border:none;
    border-color:map-get($generalStyles,transparentChangeableMenu5);
    padding:9px;
    border-radius: 50%;
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    color:white;
}
@include gadgets-to(largeDesktop) {
    font-size:30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    cursor: pointer;
    margin-right:4%;
    border:none;
    border-color:map-get($generalStyles,transparentChangeableMenu5);
    padding:11px;
    border-radius: 50%;
    background-color:map-get($generalStyles,transparentChangeableMenu5);
    color:white;
}

}

.xjtrashcontainer{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        height:30px;
        background-color:transparent;
    }
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    background-color:transparent;
    cursor:pointer;
    height:50px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    background-color:transparent;
    cursor:pointer;
    height:50px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    background-color:transparent;
    cursor:pointer;
    height:50px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    background-color:transparent;
    cursor:pointer;
    height:50px;
}


}

.xjanimateddiv50{ 
    @include gadgets-to(mobile) {
        transform:rotate(0deg);
        animation-name:xjingredientNew50;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
@include gadgets-to(tablet) {
    transform:rotate(0deg);
    animation-name:xjingredientNew50tablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }
@include gadgets-to(laptop) {
    transform:rotate(0deg);
    animation-name:xjingredientNew50laptop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }

@include gadgets-to(desktop) {
    transform:rotate(0deg);
    animation-name:xjingredientNew50desktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }
@include gadgets-to(largeDesktop) {
    transform:rotate(0deg);
    animation-name:xjingredientNew50largedesktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }
}


@keyframes xjingredientNew50 {
0%{ height:0px }
100%{height:75px}
}


@keyframes xjingredientNew50tablet {
    0%{ height:0px }
    100%{height:140px}
    }

@keyframes xjingredientNew50laptop {
    0%{ height:0px }
    100%{height:150px}
    }

    @keyframes xjingredientNew50desktop {
        0%{ height:0px }
        100%{height:158px}
        }
        @keyframes xjingredientNew50largedesktop {
            0%{ height:0px }
            100%{height:210px}
            }


.xjanimateddiv60{ 
    @include gadgets-to(mobile) {
            transform:rotate(0deg);
            animation-name:xjingredientNew60;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
    }    
@include gadgets-to(tablet) {
    transform:rotate(0deg);
    animation-name:xjingredientNew60tablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }
 }


@keyframes xjingredientNew60 {
0%{ height:0px }
100%{height:105px}
}

@keyframes xjingredientNew60tablet {
    0%{ height:0px }
    100%{height:120px}
    }
    
    
    
.xjanimateddiv105{
    @include gadgets-to(mobile) {
        transform:rotate(0deg);
        animation-name:ingredientNew5;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
@include gadgets-to(tablet) {
    transform:rotate(0deg);
    animation-name:ingredientNew5tablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }
}

@keyframes ingredientNew5 {
0%{ height:0px }
100%{height:50px}
}

@keyframes ingredientNew5tablet {
    0%{ height:0px }
    100%{height:125px}
    }

    .xjtabs{
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        @include gadgets-to(tablet) {
            height:3vh;
        }
         @include gadgets-to(laptop) {
          height:4vh;
        }
        @include gadgets-to(desktop) {
            height:4vh;
        }
        @include gadgets-to(largeDesktop) {
        height:5vh;
        }
    }
    
    
.xjtab-button {
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        background-color:white;
        padding:3px 4px;
        cursor: pointer;
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
         border-top-left-radius: 6px;
        // border-top-right-radius:6px;
       border:1px solid transparent;
      //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
       // border-bottom-color:transparent;
        width:50%;
        margin:2px;
    }

    @include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
     border-top-left-radius: 6px;
    // border-top-right-radius:6px;
   border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
}

.xjtab-button1 {
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:3px 4px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
   // border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
   // border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:white;
    padding:8px 10px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    
}
}

.xjtab-button2{

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    background-color:rgb(235, 231, 231);
    padding:3px 4px;
    cursor: pointer;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
   // border-top-left-radius:6px;
    border-top-right-radius:6px;
    border:1px solid transparent;
  //  border:1px solid map-get($generalStyles,transparentChangeableMenu5);
   // border-bottom-color:transparent;
    width:50%;
    margin:2px;
    border-top-right-radius:12px;
 
 
  }


.xjImageIcon{
    @include gadgets-to(mobile) {
  font-size: 15px;
  color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(tablet) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(laptop) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(desktop) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    font-size: 25px;
    color:map-get($generalStyles, transparentChangeableMenu5);
    cursor: pointer;
}
}
.xjRecipeIcon{
    @include gadgets-to(mobile) {
    font-size: 15px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(tablet) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(desktop) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    font-size: 25px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
}
}

.xjIconText{
    @include gadgets-to(mobile) {

font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 10px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 10px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 12px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    cursor: pointer;
    font-size: 16px;
    margin-left:3px;
    color:black;
    &:hover {
        font-weight:bold;
    }
}
}


.xjimageIconUpload{
    font-size: 22px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
@include gadgets-to(mobile) {
font-size:18px;
}
@include gadgets-to(tablet) {
    font-size: 22px;
}
@include gadgets-to(laptop) {
    font-size: 22px;
}
@include gadgets-to(desktop) {
    font-size: 22px;
}
@include gadgets-to(largeDesktop) {
    font-size: 22px;
}
}
.xjrecipeDesc{
    font-size: 22px;
    cursor: pointer;
    color: map-get($generalStyles, transparentChangeableMenu5);
@include gadgets-to(mobile) {
font-size:18px;
}
@include gadgets-to(tablet) {
    font-size: 22px;
}
@include gadgets-to(laptop) {
    font-size: 22px;
}
@include gadgets-to(desktop) {
    font-size: 22px;
}
@include gadgets-to(largeDesktop) {
    font-size: 22px;
}
}

.xjImagesContainerWrapper{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
}
.xjimagesListWrapper{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
}
}
.xjImageContainer{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
    margin-top:1vh;
    height:auto;
    max-height:40vh;
    overflow-y:auto;


    // here we will fix the width of the scrollbar and
    // the color of the scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }


    
// 375
@media(min-width:370px) and (max-width:380px) {
    height:38vh;
    max-height:38vh;
}
    //393
    @media(min-width:386px) and (max-width:394px) {
    height:38vh;
    max-height:38vh;
}

@media(min-width:411px) and (max-width:414px) {
    @media(max-height:750px){
        height:40vh;
        max-height:40vh;
    }
}


//384
@media(min-width:381px) and (max-width:385px) {
height:43vh;
max-height:43vh;
}



    //428x926
    @media(min-width:415px) and (max-width:429px)
    and (min-height:820px)
    {
        height:32vh;
        max-height:32vh;
    }


    @media(min-width:415px) and (max-width:429px) {
        height:43vh;
        max-height:43vh;
    }
        //430x932
    @media(min-width:430px) and (max-width:480px) {
        height:45vh;
        max-height:45vh;
    }

    

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:70%;
    margin-top:1vh;
    height:65vh;
    max-height:65vh;
    overflow-y:auto;
    cursor:pointer;

    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }

    


         // as ipad
         @media(min-width:1171px) and (max-width:1195px)
         {
            height: 59vh;
            max-height:59vh;
         }
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:80%;
    margin-top:1vh;
    height:78h;
    max-height:78vh;
    overflow-y:auto;
    cursor:pointer;
        //webkit scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of handle
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
        }
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:70%;
    margin-top:1vh;
    height:78h;
    max-height:78vh;
    overflow-y:auto;
    cursor: pointer;
        //webkit scrollbar
        &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of handle
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
        }
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items:center;
    width:70%;
    margin-top:1vh;
    height:78h;
    max-height:78vh;
    overflow-y:auto;
    cursor:pointer;
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of handle
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
}
}
.xjImageContainer1{
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap:10px;
        width: 80%;
        margin-top: 1vh;
        height: 55svh;
        max-height: 55svh;
        overflow-y: auto;
        cursor: pointer;
        align-items: start;
            // webkit scrollbar
    &::-webkit-scrollbar {
        width: 1px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of handle
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
    }
@include gadgets-to(laptop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of handle
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
}

@include gadgets-to(desktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
        // webkit scrollbar
        &::-webkit-scrollbar {
            width: 10px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of handle
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
        }
}

@include gadgets-to(largeDesktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width:80%;
    margin-top: 1vh;
    height: 65vh;
    max-height: 65vh;
    overflow-y: auto;
    cursor: pointer;
        // webkit scrollbar
        &::-webkit-scrollbar {
            width: 10px; /* width of the entire scrollbar */
        }
        //color of track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
        }
        //color of handle
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
        }
}
}
.xjImageContainerOneItem{
    @include gadgets-to(tablet) {

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:auto;
    margin-top:1vh;
    max-height:50vh;
    overflow-x:hidden;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 1px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of handle
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
}

@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:auto;
    max-height:40vh;
    margin-top:1vh;
    overflow-x:hidden;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of handle
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:auto;
    max-height:40vh;
    overflow-x:hidden;
    margin-top:1vh;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 1px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of handle
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    height:auto;
    max-height:40vh;
    margin-top:1vh;
    overflow-x:hidden;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 1px; /* width of the entire scrollbar */
    }
    //color of track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5); /* color of the tracking area */
    }
    //color of handle
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeableMenu5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
}
}

.xjImageContainerTwoItems{
    display:flex;
    flex-direction:row;
    justify-content:center;
    //align-items:center;
    width:400px;
    height:400px;
    gap:4px;
}
.xjs3imagedata{
    @include gadgets-to(mobile) {
        margin-top:1vh;
        width:90%;
        height:auto;
        border-radius: 6px;
    
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        margin-top:2vh;
        width:100%;
        height:auto;
        border-radius: 6px;
    }
    @include gadgets-to(laptop) {
        margin-top:1vh;
        width:100%;
        height:auto;
        border-radius: 6px;
        cursor: pointer;
      
    }
    @include gadgets-to(desktop) {
        margin-top:1vh;
        width:100%;
        height:auto;
        border-radius: 6px;
        cursor: pointer;
        
    }
    @include gadgets-to(largeDesktop) {
        width:100%;
        height:auto;
        border-radius: 6px;
        margin-top:1vh;
        cursor: pointer;
        
    }
    }
    .xjs3imagedata1{
    @extend .xjs3imagedata;
    border:2px solid map-get($generalStyles, pinkRed);
    cursor:pointer;
    
    
    }
    .xjs3imagedataFor2{
        @include gadgets-to(mobile) {
            // margin-top:1vh;
            // width:90%;
            // height:auto;
            // border-radius: 6px;
        
        }
        @include gadgets-to(tablet) {
            display:flex;
            flex-direction:column;
            margin-top:2vh;
            width:410px;
            height:auto;
            border-radius: 6px;
        }
        @include gadgets-to(laptop) {
            margin-top:2vh;
            width:100%;
            height:auto;
            border-radius: 6px;
            cursor: pointer;
          
        }
        @include gadgets-to(desktop) {
            margin-top:2vh;
            width:100%;
            height:auto;
            border-radius: 6px;
            cursor: pointer;
            
        }
        @include gadgets-to(largeDesktop) {
            width:100%;
            height:auto;
            border-radius: 6px;
            margin-top:2vh;
            cursor: pointer;
            
        }
        }
        .xjs3imagedata1For2{
        @extend .xjs3imagedataFor2;
        border:5px solid map-get($generalStyles, pinkRed);
        cursor:pointer;
        }

        .xjdataNotFoundWrapper{
            display:flex;
            flex-direction:column;
            //justify-content:center;
            align-items:center;
            width:100%;
            height:100%;
        }
    
    .xjs3imagedataNotFound{
        @include gadgets-to(mobile) {
            margin-top:1vh;
            width:90%;
            height:auto;
            border-radius: 6px;
        
        }
        @include gadgets-to(tablet) {
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            margin-top:2vh;
            width:80%;
            height:auto;
            border-radius: 6px;
        }
        @include gadgets-to(laptop) {
            margin-top:2vh;
            width:80%;
            height:auto;
            border-radius: 6px;
            cursor: pointer;
          
        }
        @include gadgets-to(desktop) {
            margin-top:2vh;
            width:80%;
            height:auto;
            border-radius: 6px;
            cursor: pointer;
            
        }
        @include gadgets-to(largeDesktop) {
            width:80%;
            height:auto;
            border-radius: 6px;
            margin-top:2vh;
            cursor: pointer;
            
        }
    }
    .xjgenerateImageTop{
        display:flex;
        flex-direction:row;
        justify-content: center;
    }
    
    .xjgenerateImageWrapper{
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:100%;
        margin-top:8px;
    }
    
    .xjimageUploadContainer{
        display:flex;
        flex-direction:column;
       // justify-content:flex-start;
        align-items:flex-start;
        width:100%;
        margin-top:5px;
    
    }
    .xjimageUploadWrapper{
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;
        width:100%;
        margin-top:5px;
    }
    .xjinputimageUpload{
        display:none;
    }

    .xjfileLabel{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        flex-shrink: 0;
        margin-left:5px;
        height:25px;
        width:120px;
        //padding:3px;
        border-radius: map-get($generalStyles, sixborderRadius);
        background-color:map-get($generalStyles, transparentChangeableMenu5);
        color: white;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        font-family:map-get($generalStyles,fontFamily);
        font-size:14px;
    }
    

    .xjbuttonUploadImageWrapper{
        display:flex;
        flex-direction:row;
        align-items:flex-start;
        width:50%;
    }
    .xjbuttonUploadImage{
        margin-left:5px;
        width:120px;
        height: 30px;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        border-radius: 6px;
        border: none;
        cursor:pointer;
        margin-top: 5px;
        opacity:0.7;
    }
    .xjbuttonUploadImage1{
        @extend .xjbuttonUploadImage;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        cursor:pointer;
    }
    
    
    .xjfileName{
        font-family:map-get($generalStyles,fontFamily);
        font-size: 12px;
        margin-left:3px;
        //elippsis
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        width:100%;
        display:flex;
        flex-wrap:wrap;
    }
    
    .xjimageUploadMessage{
    
        font-family:map-get($generalStyles,fontFamily);
        font-size:12px;
        margin-top:5px;
        margin-left:7px;
        @include gadgets-to(mobile) {
            font-size: 11px;
        }
        @include gadgets-to(tablet) {
            font-size: 12px;
        }
        @include gadgets-to(laptop) {
            font-size: 14px;
        }
        @include gadgets-to(desktop) {
            font-size: 14px;
        }
        @include gadgets-to(largeDesktop) {
            font-size: 14px;
        }
    }
    

    
.xjimageinput{
    @include gadgets-to(mobile) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(tablet) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(laptop) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(desktop) {
    width:70%;
    height:4.5svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
}
@include gadgets-to(largeDesktop) {
    width:70%;
    height:4.7svh;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius: 6px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:24px;
    resize:none;
}
}

.xjgenerateImageButton{
    @include gadgets-to(mobile) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
 
}
@include gadgets-to(tablet) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
@include gadgets-to(laptop) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
@include gadgets-to(desktop) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
@include gadgets-to(largeDesktop) {
    width:25%;
    height:5svh;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius:6px;
    border:none;
    opacity:0.8;
    margin-left:3px;
}
}
.xjgenerateImageButton1{
    @extend .xjgenerateImageButton;
    background-color: map-get($generalStyles,pinkRed);
    color:white;
    cursor:pointer;
}


.xjimageInformationTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
   margin-top:5px;
}
.xjimageInformationText{

    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    justify-content:center;
   align-items:center;
    width:95%;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;

    @include gadgets-to(mobile) {
        font-size: 11px;
    }
    @include gadgets-to(tablet) {
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        font-size: 14px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 14px;
    }
}

.bsLivePubslishWrapper{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    width:90%;
  //  margin-top:5px;
  background-color: transparent;

}




// .overlay-container {
//     position: relative;
  
//     .hamburger-btn {
//       background-color: transparent;
//       border: none;
//       font-size:20px;
//       cursor: pointer;
//       outline: none;
//       @include gadgets-to(mobile) {
//         font-size: 15px;
//       }
//     }
//   }
  
//   .overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     background-color: rgba(0, 0, 0, 0.7);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 1000;
  
//     .overlay-content {
//       width:95%;
//       height: 50vh;
//       background-color: #fff;
//       border-radius: 8px;
//       display: flex;
//       flex-direction: column;
//      // justify-content: center;
//       align-items: center;
//       position: relative;
//       box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
//       @include gadgets-to(mobile) {
//         width: 95%;
//         height: 50vh;
//       }
//         @include gadgets-to(tablet) {
//             width: 50%;
//             height: 50vh;
//         }
//         @include gadgets-to(laptop) {
//             width: 40%;
//             height: 50vh;
//         }
//         @include gadgets-to(desktop) {
//             width: 30%;
//             height: 50vh;
//         }
//         @include gadgets-to(largeDesktop) {
//             width: 30%;
//             height: 50vh;
//         }
//       .close-btn {
//         position: absolute;
//         top: 10px;
//         right: 10px;
//         background-color: transparent;
//         border: none;
//         font-size: 1.5rem;
//         cursor: pointer;
//         outline: none;
//       }
  
//       p {
//         font-size: 1rem;
//         text-align: center;
//         margin: 0;
//       }
//     }
//   }

//   .xjLivePublishWrapper{
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:center;
//     width:90%;
//     margin-top:5px;
//     background-color: transparent;
//     @include gadgets-to(tablet) {
//         width:90%;
//     }
//   }
//   .xjLivePublishHeader{
//     display:flex;
//     flex-direction:row;
//     justify-content:flex-start;
//     align-items:center;
//     width:100%;
//     font-family:map-get($generalStyles,fontFamily);
//     font-size:14px;
//     font-weight:bold;
//     margin-top:2vh;
//     margin-bottom:2vh;
//     @include gadgets-to(mobile) {
//         font-size: 12px;
//     }
//     @include gadgets-to(tablet) {
//         font-size: 20px;
//     }
//     @include gadgets-to(laptop) {
//         font-size: 20px;
//     }
//     @include gadgets-to(desktop) {
//         font-size: 20px;
//     }
//     @include gadgets-to(largeDesktop) {
//         font-size: 16px;
//     }
//   }
//   .xjLiveInput{
//     width:70%;
//     height:4vh;
//     border:1px solid map-get($generalStyles,datalistBorderColor)!important;
//     border-radius: 4px;
//     font-family:map-get($generalStyles,fontFamily);
//     font-size:13px;
//     font-weight:200;
//     resize:none;
//     margin-bottom:2vh;
//     background-color: white;
//     @include gadgets-to(mobile) {
//         font-size: 12px;
//     }
//     @include gadgets-to(tablet) {
//         font-size: 16px;
//     }
//     @include gadgets-to(laptop) {
//         font-size: 16px;
//     }
//     @include gadgets-to(desktop) {
//         font-size: 16px;
//     }
//     @include gadgets-to(largeDesktop) {
//         font-size: 16px;
//     }

// }
//   .xjLiveSelect{
//     width:72%;
//     height:4.5svh;
//     border:1px solid;
//     border-color:map-get($generalStyles,datalistBorderColor);
//     border-radius: 6px;
//     font-family:map-get($generalStyles,fontFamily);
//     font-size:14px;
//     resize:none;
//     color:black;
//     background-color: white;
//   }
//   .xjLivePublishText{
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//    // align-items:center;
//     width:70%;
//     margin-top:5px;
//     font-family:map-get($generalStyles,fontFamily);
//     font-size:12px;
//     font-weight:bold;
//     color:black;
//     margin-bottom:6px;
//     @include gadgets-to(mobile) {
//         font-size: 12px;
//     }
//     @include gadgets-to(tablet) {
//         font-size: 16px;
//     }
    
//   }
//   .xjLivePublishButton{
//     width:72%;
//     height: 35px;
//     background-color:black;
//     color:white;
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 12px;
//     border-radius: 4px;
//     border:none;
//     cursor:pointer;
//     margin-top: 5px;
//     // hover
//     &:hover {
//         background-color:black;
//         opacity: 0.8;
   
//     }
//     @include gadgets-to(tablet) {
//         font-size: 12px;
//         width:90%;
//         height:25px;
//     }
//     @include gadgets-to(laptop) {
//         font-size: 12px;
//         height:25px;
//     }

//   }
//   .xjLivePublishButton:disabled{
//     background-color: #555; /* Dull black */
//     color: #ccc; /* Optional: make text lighter */
//     cursor: not-allowed; 
//   }

  .xjLiveWrapper{
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
    height:65vh;
    background-color:white;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius:8px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    @include gadgets-to(mobile) {
        width:99%;
        height:84vh;
        @media(max-width:370px){
            height:83vh;
        }
        @media(min-width:371px){
            height:60vh;
        }

    }
    @include gadgets-to(tablet) {
        width:100%;
        height:68.6vh;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        @media(min-width:1180px)and (max-width:1430px){
            height:58.6vh;
        }
        @media(min-width:1431px)and (max-width:1600px){
            height:60.3vh;
        }
   
    }
  }
  .xjLiveHeader{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:5vh;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
    font-size:32px;
    font-weight:bold;
    margin-top:1vh;
    @include gadgets-to(mobile) {
        font-size:14px;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        font-size:16px;
    }
    @include gadgets-to(laptop) {
        font-size: 20px;
    }
    @include gadgets-to(desktop) {
        font-size: 20px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
    }
  }
  .xjLiveCreatorStatusWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
   // margin-top:5px;
    height:4vh;
    @include gadgets-to(mobile) {
        @media(min-width:400px){
            height:2vh;
        }
    }
    @include gadgets-to(tablet) {
        margin-left:3vw;
        width:90%;
    }   
    @include gadgets-to(laptop) {
        margin-left:3vw;
        width:90%;
    }
    @include gadgets-to(desktop) {
        margin-left:4vw;
        width:90%;
    }
    @include gadgets-to(largeDesktop) {
        margin-left:5vw;
        width:90%;
    }
  }
  .xjLiveCreatorStatusHeader{
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
    width:95%;
    height:3vh;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
    font-size:14px;
    font-weight:bold;


    @include gadgets-to(mobile) {
        font-size: 10px;
    }
    @include gadgets-to(tablet) {
        font-size:14px;
    }
    @include gadgets-to(laptop) {
        font-size: 20px;
    }
    @include gadgets-to(desktop) {
        font-size: 20px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
    }
  }
  .xjLiveCreatorStatusText{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:70%;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    @include gadgets-to(mobile) {
        font-size: 10px;
    }
 
  }
  .xjLiveCreatorStatusText1{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    width:35%;
    white-space: nowrap;
    @include gadgets-to(mobile) {
        font-size: 10px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size:16px;
    }

  
}
.xjLiveCreatorStatusText2{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    width:35%;
    white-space: nowrap;
    @include gadgets-to(mobile) {
        font-size: 10px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size:16px;
    }

  
}
.xjLiveCreatorStatusText3{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    width:25%;
    white-space: nowrap;
    @include gadgets-to(mobile) {
        font-size: 10px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }

    @include gadgets-to(largeDesktop) {
        font-size:16px;
    }

  
}

  .xjLiveRecepyHeaderToPublish{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:95%;
    height:2vh;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    font-weight:200;
    margin-top:1vh;
    @include gadgets-to(mobile) {
        font-size: 12px;
        margin-top:unset;
        margin-bottom:3vh;
    }
    @include gadgets-to(tablet) {
        font-size:16px;
    }
    @include gadgets-to(laptop) {
        font-size:16px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
    }
  }
  .xjLiveDoneIcon{
    font-size: 22px;
    cursor: pointer;
    color:green;
    @include gadgets-to(mobile) {
    font-size:14px;
    }
    @include gadgets-to(tablet) {
        font-size: 22px;
    }
    @include gadgets-to(laptop) {
        font-size: 22px;
    }
    @include gadgets-to(desktop) {
        font-size: 22px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 22px;
    }
  }

  .xjLiveRecipeToPublishWrapper{
    display: flex;
    flex-direction: column;
   // justify-content: center;
    align-items: center;
    width:95%;
    height:30vh;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius:8px;  
    @include gadgets-to(mobile) {
        width:100%;
        height:30vh;
        @media(max-width:370px){
            height:30vh;
            border-radius: unset;
        }
        @media(min-width:371px){
            height:26vh;
            border-radius: unset;
        }
    }
    @include gadgets-to(tablet) {
        width:95%;
        height:30vh;
    }
    @include gadgets-to(laptop) {
        width:80%;
        height:25vh;
    }
    @include gadgets-to(desktop) {
        width:80%;
        height:25vh;
    }
    @include gadgets-to(largeDesktop) {
        width:70%;
        height:35vh;
    }
}
  .xjLiveInputPriceWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:6vh;
    margin-top:2vh;
    @include gadgets-to(mobile) {
        height:3.5vh;
        margin-top:unset;
        align-items:unset;
    }
    @include gadgets-to(tablet) {
        height:4vh;
        width:70%;
    }
    @include gadgets-to(laptop) {
        height:4vh;
        width:70%;
    }
    @include gadgets-to(desktop) {
        height:4vh;
        width:70%;
    }
  }
  .xjLiveInputPrice{
    width:50%;
    height:20px;
    border:1px solid map-get($generalStyles,datalistBorderColor)!important;
    border-radius: 4px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:13px;
    font-weight:200;
    resize:none;
    margin-bottom:2vh;
    background-color: white;
    @include gadgets-to(mobile) {
        font-size: 12px;
        margin-bottom:1vh;
        height:2.5vh;
        width:50%;
    }
    @include gadgets-to(tablet) {
        font-size: 16px;
        margin-bottom:1vh;
        width:190px;
        height:20px;
    }
    @include gadgets-to(laptop) {
        font-size: 16px;
        margin-bottom:1vh;
        width:51.5%;
    }
    @include gadgets-to(desktop) {
        font-size: 16px;
        margin-bottom:1vh;
        width:42%;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
        margin-bottom:1vh;
        height:30px;

    }
  }
  .xjLiveInputPriceText{
    display:flex;
    flex-direction:column;
    justify-content:center;
   // align-items:center;
    width:20%;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    @include gadgets-to(mobile) {
        font-size: 10px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:flex-end;
        width:25%;
        height:2.5vh;
    }
    @include gadgets-to(tablet) {
        font-size:14px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:flex-end;
        width:40%;
    }
    @include gadgets-to(laptop) {
        font-size:14px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:flex-end;
        width:40%;
        
    }
    @include gadgets-to(desktop) {
        font-size: 16px;
        width:30%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:flex-end;
        width:30%;
    }
  }
.xjLiveInputDifficultyText{
    display:flex;
    flex-direction:column;
    justify-content:center;
   // align-items:center;
    width:20%;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    @include gadgets-to(mobile) {
        font-size: 10px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
         align-items:center;
         width:25%;
    }
    @include gadgets-to(tablet) {
        font-size:14px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
         align-items:center;
        width:40%;
    }
    @include gadgets-to(laptop) {
        font-size:14px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:flex-end;
        width:40%;
        
    }
    @include gadgets-to(desktop) {
        font-size: 16px;
        width:21%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
        width:30%;
    }
  }
  .xjLiveSelectDifficultyWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:3vh;
    @include gadgets-to(mobile) {
        height:6vh;
        margin-top:unset;
    }
   // margin-top:2vh;
   @include gadgets-to(tablet) {
        height:3vh;
        margin-top:unset;
        width:70%;
    }
    @include gadgets-to(laptop) {
        height:3vh;
        margin-top:unset;
        width:70%;
    }

  }

.xjLiveInputTimeText{
    display:flex;
    flex-direction:column;
    justify-content:center;
   // align-items:center;
    width:20%;
    margin-top:5px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    @include gadgets-to(mobile) {
        font-size: 10px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
        width:25%;
    }
    @include gadgets-to(tablet) {
        font-size:14px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
        width:28%;
    }
    @include gadgets-to(laptop) {
        font-size:14px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
        width:40%;
        
    }
    @include gadgets-to(desktop) {
        font-size: 16px;
        width:30%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items: center;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items: center;
        width:30%;
    }
  }


  .xjLiveSelectDifficulty{
    width:52%;
    height:20px;
    border:1px solid map-get($generalStyles,datalistBorderColor)!important;
    border-radius: 4px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:13px;
    font-weight:200;
    resize:none;
    margin-bottom:1vh;
    background-color: white;
    @include gadgets-to(mobile) {
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        font-size: 16px;
        width:203px;
        height:22px;
    }
    @include gadgets-to(laptop) {
        font-size: 14px;
        height:25px;
    }
    @include gadgets-to(desktop) {
        font-size: 16px;
        width:30%;
        height:30px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
        height:30px;
        width:50%;
    }
  }

  .xjLiveInputTimeWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:4vh;
    margin-top:2vh;
    @include gadgets-to(mobile) {
        height:3vh;
        margin-top:unset;
    }
    @include gadgets-to(tablet) {
        height:4vh;
        margin-top:unset;
    }
    @include gadgets-to(laptop) {
        height:4vh;
        margin-top:unset;
        width:70%;
    }
    @include gadgets-to(desktop) {
        height:4vh;
        margin-top:unset;
        width:70%;
    }
  }
  .xjLivePublishButton{
    width:72%;
    height: 35px;
    background-color:black;
    color:white;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 4px;
    border:none;
    cursor:pointer;
    margin-top: 5px;
    // hover
    &:hover {
        background-color:black;
        opacity: 0.8;
   
    }
    @include gadgets-to(mobile) {
        font-size: 12px;
        width:78%;
        height:25px;
    }

    @include gadgets-to(tablet) {
        width:64%;
        font-size:14px;
        height:30px;
    }
    @include gadgets-to(laptop) {
        width:73%;
        font-size:14px;
    }
    @include gadgets-to(desktop) {
        width:72%;
        font-size:14px;
    }
    @include gadgets-to(largeDesktop) {
        width:81%;
        font-size:14px;
    }

  }
  .xjLiveUnpublishWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:5vh;
   // border:1px solid red;
    margin-top:1vh;
    @include gadgets-to(mobile) {
        width:77%;
        height:5vh;
        margin-bottom:0.5vh;
        margin-top:0.5vh;
    }
    @include gadgets-to(tablet) {
        width:64%;
        height:5vh;
        margin-bottom:0.5vh;
        margin-top:0.5vh;
    }
    @include gadgets-to(laptop) {
        width:72%;
        height:4vh;
        margin-bottom:0.5vh;
        margin-top:0.5vh;
    }
    @include gadgets-to(desktop) {
        width:72%;
        height:5vh;
        margin-bottom:0.5vh;
        margin-top:0.5vh;
    }
    @include gadgets-to(largeDesktop) {
        width:80%;
        height:5vh;
        margin-bottom:0.5vh;
        margin-top:0.5vh;
    }
  }

  .xjLiveUsersHasAccesstext{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:65%;
    height:2vh;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
    font-size:18px;
    font-weight:200;
   // margin-top:1vh;
    @include gadgets-to(mobile) {
        font-size: 10px;
    }
    @include gadgets-to(tablet) {
        font-size:12px;
    }
    @include gadgets-to(laptop) {
        font-size:16px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
    }
  }

  .xjLiveUnpublishButton{
    width:40%;
    height:25px;
    background-color:red;
    color:white;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 4px;
    border:none;
    cursor:pointer;
    margin-top: 5px;
    // hover
    &:hover {
        background-color:red;
        opacity: 0.8;
   
    }

    @include gadgets-to(tablet) {
        width:200px;
        font-size:14px;
        height:30px;
    }
    @include gadgets-to(laptop) {
        width:44%;
        font-size:14px;
    }

  }

  .xjLiveUnpublishButton1{
    @extend .xjLiveUnpublishButton;
    background-color:rgb(228, 130, 130);
    cursor:pointer;
  }

  .xjLiveCloseIcon{
    font-size: 22px;
    cursor: pointer;
    color:red;
    @include gadgets-to(mobile) {
    font-size:16px;
    }
    @include gadgets-to(tablet) {
        font-size: 22px;
    }
    @include gadgets-to(laptop) {
        font-size: 22px;
    }
    @include gadgets-to(desktop) {
        font-size: 24px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 26px;
    }
  }
.xjLiveRecepyHeaderToPublishText{

    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    font-weight:200;
    margin-top:1vh;
    @include gadgets-to(mobile) {
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        font-size:14px;
    }
    @include gadgets-to(laptop) {
        font-size:16px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 20px;
    }
  }
  .xjLiveRecepyHeaderToPublishText1{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:95%;
    height:2vh;
    font-family:map-get($generalStyles,fontFamily);
    font-size:18px;
    font-weight:200;
    margin-top:1vh;
    @include gadgets-to(mobile) {
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        font-size:16px;
    }
    @include gadgets-to(laptop) {
        font-size:16px;
    }
    @include gadgets-to(desktop) {
        font-size:16px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 16px;
    }
  }

  .xjInfoText{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    font-family:map-get($generalStyles,fontFamily);
    font-size:12px;
    font-weight:bold;
    color:black;
    margin-bottom:6px;
    @include gadgets-to(mobile) {
        @media(max-width:370px){
            font-size: 10px;
            height:0.5vh;
        }
        font-size: 10px;
        height:0.1vh;
    }
    @include gadgets-to(tablet) {
        font-size:16px;
    }
    @include gadgets-to(laptop) {
        font-size:16px;
    }
    @include gadgets-to(desktop) {
        font-size: 18px;
    }
    @include gadgets-to(largeDesktop) {
        font-size:20px;
    }
  }

  .xjLiveTranslateWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
  }
.xjLiveTranslateHeaderText{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    padding:0.25rem;
    @include gadgets-to(mobile) {
        padding:0.5rem;
    }
}
  .xjLiveTranslateSelect{
    width:50%;
    height:25px;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    border-radius:4px;
    font-family:map-get($generalStyles,fontFamily);
    font-size:14px;
    resize:none;
    color:black;
    background-color: white;
    margin-top:1px;
    @include gadgets-to(mobile) {
        width:80%;
    }

    @include gadgets-to(tablet) {
        @media(max-width:1026px){
            height:20px;
            width:60%;
        }
        @media(min-width:1026px){
            height:20px;
            width:58%;
        }
    }


  }
  .xjLiveRecipeToPublishWrapper2{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    margin-top:4px;
    align-items:center;
    width:60%;
    height:4.5vh;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius:4px;
    @include gadgets-to(mobile) {
        width:80%
    }
    @include gadgets-to(tablet) {
     @media(min-width:1026px){
        height:3vh;
        width:58%;
     }

    }
  }
  .xjLiveRecepyToPublishButton{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:54px;
    height:20px;
    margin-left:2px;
   // margin-top:3px;
    background-color:map-get($generalStyles,transparentChangeableMenu4);
    color:white;
    outline:none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:20px;
    border-radius:6px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
  //  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  @include gadgets-to(mobile) {
    @media(max-width:370px){
        width:39px;
    }
    @media(min-width:400px){
       width:44px;
   }
   width:35px;
}
  @include gadgets-to(tablet) {
        @media(max-width:1025px){
            height:18px;
            width:42px;
            font-size:14px;
        }
        @media(min-width:1026px){
            height:15px;
            width:42px;
            font-size:16px;
        }
    
    }
}
.xjLivePublishLanguageButton{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:58.4%;
    height:22px;
    color:white;
    background-color: black;
    border-radius:4px;
    margin-top:4px;
    font-family: #{map-get($generalStyles, fontFamilyAnders)};
    font-size:11px;
    cursor:pointer;
    //hover
    &:hover {
        background-color: black;
        opacity: 0.8;
    }
    @include gadgets-to(mobile) {
      width:80%;
      height:25px;
      font-size:12px;
    }
    @include gadgets-to(tablet) {
        @media(max-width:1025px){
            height:20px;
            width:60.5%;
            font-size:12px;
        }
        @media(min-width:1026px){
            height:20px;
            width:58.5%;
            font-size:12px;
        }
    }

}

.xjLiveTranslateGlobeIcon{
    width:15px;
    height:15px;
    color:lightgrey;
    margin-right:5px;
}