@import '../style/MainStyles.scss';/* styles.css */



html {
    scrollbar-width: thin; /* For Firefox */
    overflow: overlay; /* For Chrome, Safari */
  }

.xdproduktArea1{
   

    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }
        // Use the mixin for tablet devices
        @include gadgets-to(tablet) {
    
            display: grid;
            grid-template-rows:auto auto auto;
            grid-template-areas: 
              "a"
              "b"
              "c";
            width: 100%;
            justify-items: center;
            gap:10px;
            grid-auto-rows: minmax(5px,auto);
        
        }

    @include gadgets-to(laptop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }

    @include gadgets-to(desktop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }

    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }







  }



.backgroundImageListProdukt{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1);
    opacity: 0.8;
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
   
}
.backgroundImageListProdukt1{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    z-index: -1;
    // transform:scale(1);
    // opacity: 0.8;
background-color: white;
}

.xdheaderArea{
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
}
.xdrangeArea{
  @include gadgets-to(mobile) {
    grid-area:b;
    // display:flex;
    // flex-direction:row;
    // justify-content: space-around;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content:center;
    
}
@include gadgets-to(tablet) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(laptop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(desktop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
}




.xdProduktListB{
    grid-area: c;
    border:map-get($generalStyles,prefferedBorderStyle );
    border-color:map-get($generalStyles,prefferedBorderColor);
    background-color:map-get($generalStyles,transparentChangeable);
    width:99%;
    border:1px solid white;
    
    //mobile
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-items: center;
        width:99%;
        height:78vh;
        margin-left:2px;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:80%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        border:map-get($generalStyles,prefferedBorderStyle );
        border-color:map-get($generalStyles,prefferedBorderColor);
    }

    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:70%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border:map-get($generalStyles,prefferedBorderStyle);
        border-color:map-get($generalStyles,prefferedBorderColor);

    }

    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:60%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border-color:map-get($generalStyles,prefferedBorderColor);
    }

    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:60%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border-color:map-get($generalStyles,prefferedBorderColor);
    }
    

   

}

.xdcompName {

    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:white;
    //pc
    @include gadgets-to(tablet) {
        margin-top:2px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
        color:white;
    }
    @include gadgets-to(laptop) {
        margin-top:2vh;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:35px;
        color:white;
    }

    @include gadgets-to(desktop) {
        margin-top:3vh;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:35px;
        color:white;
    }

    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:45px;
        color:white;
    }


}

.xdcompName1 {
 grid-area: a;

    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:map-get($generalStyles,transparentChangeableMenu5);
   

   @include gadgets-to(tablet) {
    margin-top:2px;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    margin-top:2vh;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}


@include gadgets-to(desktop) {
    margin-top:3vh;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:35px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}

@include gadgets-to(largeDesktop) {
    margin-top:3vh;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:45px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}


}

.xdcompNameJAP {
    grid-area:a;

    font-family: var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:white;

    @include gadgets-to(tablet) {
        margin-top:2vh;
        font-family: var(--font-family);
        font-Size:30px;
        color:white;

      }
    @include gadgets-to(laptop) {
        margin-top:2vh;
        font-family: var(--font-family);
        font-Size:30px;
        color:white;
      }



    @include gadgets-to(desktop) {
        margin-top:3vh;
        font-family: var(--font-family);
        font-Size:35px;
        color:white;
    }
    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
        font-family: var(--font-family);
        font-Size:45px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    


}

.xdcompNameJAP1 {
    grid-area:a;

    font-family: var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:map-get($generalStyles,transparentChangeableMenu5);

    @include gadgets-to(tablet) {
        margin-top:2vh;
        font-family: var(--font-family);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }

    @include gadgets-to(laptop) {
        margin-top:2vh;
        font-family: var(--font-family);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }


    @include gadgets-to(desktop) {
        margin-top:3vh;
        font-family: var(--font-family);
        font-Size:35px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
        font-family: var(--font-family);
        font-Size:45px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    
}

.xdrange1 {
    grid-area:b;
    @include gadgets-to(mobile) {
   //   margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
  }
  @include gadgets-to(tablet) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      margin-right:2%;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(desktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(largeDesktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
}

.xdrange2 {
    grid-area:b;
    @include gadgets-to(mobile) {
      accent-color: rgb(251, 239, 132);
      width:125px;

  }
  @include gadgets-to(tablet) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(desktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(largeDesktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
}

.xdproduktselectTop{
    grid-area:c;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
  }
  
  .xdproduktselectFieldset {
    grid-area:c;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:90%;
    margin-top:3vh;
    border:none;
 
}
  
  .xdproduktselectLegend {
    margin-left:5px;
   font-family: var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);

    @include gadgets-to(mobile) {
        margin-left:2.5%;
       }
          
    @include gadgets-to(tablet) {
        margin-left:10%;
       }
       @include gadgets-to(laptop) {
        margin-left:3.1%;
       }
          

       @include gadgets-to(desktop) {
        margin-left:5.5%;
       }
       @include gadgets-to(largeDesktop) {
        margin-left:2.5%;
       }
}
  
  .xdproduktselectLegendJAP {
   font-family: var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);

    @include gadgets-to(tablet) {
        margin-left:10%;
       }
  
  }
  
  .xdproduktselectProdukt{
    width:95%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
   font-family: var(--font-family);
    background-color: white;

    @include gadgets-to(tablet) {
        width:80%;
       }

}
  
  .xdproduktselectProduktJAP {
    width: 100%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
   font-family: var(--font-family);
     background-color: white;

     
    @include gadgets-to(tablet) {
        width:80%;
       }

       @include gadgets-to(largeDesktop) {
        width:75%;
       }
}
  
  .xddatalistTop{
    @include gadgets-to(mobile){
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:98%;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width:80%;
    
    }

@include gadgets-to(laptop) {
  display: flex;
  flex-direction: row;
  justify-content: center;
        width:70%;
       }
             
@include gadgets-to(desktop) {
  display: flex;
  flex-direction: row;
  justify-content: center;
width:70%;
}
      
@include gadgets-to(largeDesktop) {
  display: flex;
  flex-direction: row;
  justify-content: center;
width:70%;
}
  }

  
.xdlistproduktid {
    @include gadgets-to(mobile){
    width:15%;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }


    @include gadgets-to(tablet){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }
    @include gadgets-to(laptop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }

    @include gadgets-to(desktop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }

    @include gadgets-to(largeDesktop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }



  }
  
  .xdlistproduktidJAP {
    @extend .xdlistproduktid;
    padding-top: 1px;
    color:black;
  }
  
  .xdlistproduktbenaemn {
    @include gadgets-to(mobile){
    width:calc(55% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }

    @include gadgets-to(tablet){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(laptop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(desktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(largeDesktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    // @include gadgets-to(tablet){
    //   width:55%;
    // }

  }
  
  .xdlistproduktbenaemnJAP {
    @extend .xdlistproduktbenaemn;
    padding-top: 1px;
  }
  
  .xdlistproduktactive {

    @include gadgets-to(mobile){
    width:calc(15% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size: 12px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }

    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
       font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
       font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
       font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
       font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

  }
  
  .xdlistproduktactiveJAP {
    @extend .xdlistproduktactive;
    padding-top: 1px;
  }

  .xdlistproduktSave {
    @include gadgets-to(mobile){
    width:calc(15% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size:12px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    margin-bottom: 3px;
    color:black;
    padding:1px;
    }
 

    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }   

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }   

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }  
    
    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
       font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }   

  }
  
  .xdlistproduktSaveJAP {
    @extend .xdlistproduktSave;
    padding-top: 1px;
  }

  .xddatalistData,.xddatalistDataJAP {

     
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: column;
        // gap:2px;
        // margin: 0 2px;
        max-height:53vh;
        overflow-y:scroll;
         width:98%;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }
       }


    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        // gap:2px;
        // margin: 0 2px;
        max-height:53vh;
        overflow-y:scroll;
        width:80%;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }
       }

       @include gadgets-to(laptop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
     //thumb
      &::-webkit-scrollbar-thumb {
          background:white;
          border-radius: 10px;
          border:1px solid;
          border-color:map-get($generalStyles,transparentChangeableMenu5);
        }
      }



       @include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        &::-webkit-scrollbar {
          width: 10px; /* Adjust as needed */
        }
        &::-webkit-scrollbar-track {
          background:map-get($generalStyles,transparentChangeableMenu5);
        }
   //thumb
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
      }


       }

       @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        &::-webkit-scrollbar {
          width: 10px; /* Adjust as needed */
        }
        &::-webkit-scrollbar-track {
          background:map-get($generalStyles,transparentChangeableMenu5);
        }
   //thumb
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,transparentChangeableMenu5);
      }


       }

  
  }
  
//   .datalistDataItem10 {
//     height: 34px;
//   }
  
  .xditemId {
    @include gadgets-to(mobile){
    width:15%;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 3px;
    text-align: center;
   font-family: var(--font-family);
    font-size:12px;
    color:black;

  }
  @include gadgets-to(tablet){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
   font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(laptop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
   font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(desktop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
   font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(largeDesktop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
   font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }
}

  
  .xditemId1 {
    @extend .xditemId;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  }
  
  .xdbenaemn {
    @include gadgets-to(mobile){
    width:calc(55% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 3px;
    text-align: center;
   font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    color:black;

       overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }


    @include gadgets-to(tablet){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @include gadgets-to(laptop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
    }

    @include gadgets-to(desktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
    }

    
    @include gadgets-to(largeDesktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
    }
  
  }
  
  .xdbenaemn1 {
    @extend .xdbenaemn;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  }




  .xdactive,.xdactive1 {

    @include gadgets-to(mobile){
 width:calc(15% - 3px);
 margin-left:3px;
 height:map-get($generalStyles,prefferedTabletListHeight);
 border-radius: map-get($generalStyles,sixBorderRadius);
 border: map-get($generalStyles,prefferedBorderStyle);
 border-color: map-get($generalStyles,prefferedBorderColor);
 background-color: map-get($generalStyles,transparentChangeableMenu5);
 margin-bottom:3px;
 margin-top:3px;

 .xddataitemChecked{
    width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

      .xddataitemUnchecked{
        width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }
    }

    @include gadgets-to(tablet){
 width:calc(15% - 3px);
 margin-left:3px;
 height:map-get($generalStyles,prefferedTabletListHeight);
 border-radius: map-get($generalStyles,sixBorderRadius);
 border: map-get($generalStyles,prefferedBorderStyle);
 border-color: map-get($generalStyles,prefferedBorderColor);
 background-color: map-get($generalStyles,transparentChangeableMenu5);
 margin-bottom:3px;
 margin-top:3px;


 .xddataitemChecked{
    width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

      .xddataitemUnchecked{
        width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-bottom:3px;
        margin-top:3px;
        cursor:pointer;
       
       
        .xddataitemChecked{
           width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
             .xddataitemUnchecked{
               width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
           }

              @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-bottom:3px;
        margin-top:3px;
        cursor:pointer;
       
       
        .xddataitemChecked{
           width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
             .xddataitemUnchecked{
               width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
           }

           @include gadgets-to(largeDesktop){
            width:calc(15% - 3px);
            margin-left:3px;
            height:map-get($generalStyles,prefferedTabletListHeight);
            border-radius: map-get($generalStyles,sixBorderRadius);
            border: map-get($generalStyles,prefferedBorderStyle);
            border-color: map-get($generalStyles,prefferedBorderColor);
            background-color: map-get($generalStyles,transparentChangeableMenu5);
            margin-bottom:3px;
            margin-top:3px;
            cursor:pointer;
           
           
            .xddataitemChecked{
               width:12px;
               height:12px;
               padding:0;
               margin:0;
               color:blue;
                 }
           
                 .xddataitemUnchecked{
                   width:12px;
               height:12px;
               padding:0;
               margin:0;
               color:blue;
                 }
           
               }

}


.xdactive1{
    @extend .xdactive;
    background-color: map-get($generalStyles,transparentChangeableMenu4);

  }

.xdsaveButton1 {
    @include gadgets-to(mobile){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
       font-family: var(--font-family);
        font-size: 14px;
    }


    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
       font-family: var(--font-family);
        font-size: 14px;
        
    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
       font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
       font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer
        
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
       font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }




}
  
  .xdsaveButton2{
    @include gadgets-to(mobile){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
       font-family: var(--font-family);
        font-size: 14px;
    }


    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
       font-family: var(--font-family);
        font-size: 14px;
    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
       font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;

    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
       font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
       font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }
}

    
  .xdsaveButtonJAP1{
    @extend .xdsaveButton1;
    font-size: 12px;
  }



  .xdsaveButtonJAP2{
    @extend .xdsaveButton2;
    font-size:12px;
  }
  
  .xdlistSpinnerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        background-color: transparent;
  }



















































































































.xbtotalArea1,.xbtotalArea {
    display: grid;
    grid-template-rows: 10% 90%;
    grid-template-areas: 
      "a"
      "b";
    width: 99%;
    @media(min-width:834px){
    
        display: grid;
        grid-template-rows: 8% 92%;
        grid-template-areas: 
          "a"
          "b";
        width: 99%;
        justify-items: center;

    
    }
    @media(min-width:1200px){
        display: grid;
        grid-template-rows: 8% 92%;
        grid-template-areas: 
          "a"
          "b";
        width: 99%;
        justify-items: center;
        height:91vh;
    }

  }


  
  .xbtotalArea {
    background-color: map-get($generalStyles, backgroundColor);
  }

.backgroundImageListProduct{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1);
    opacity: 0.8;
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
   
}

.xbareaA{
    grid-area: a;
   //mobile
    @media(max-width:575.98px){
        height:10vh;
        margin-top:70px;
        width:99%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
    //ipad11
    @media(min-width:834px){
        height:50vh;
        width:99%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    
    }
    //pc
    @media(min-width:1200px){
        height:50vh;
        width:99%;
        border-radius: map-get($generalStyles,sixBorderRadius);
      
    }


}


.xbareaB{
    grid-area: b;
    border:map-get($generalStyles,prefferedBorderStyle );
    border-color:map-get($generalStyles,prefferedBorderColor);
    background-color:map-get($generalStyles,transparentChangeable);
    width:99%;
    
    //mobile
    @media(max-width:575.98px){
        height:78vh;
        margin-top:5px;
        width:99%;
        margin-left:2px;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
    //ipad11
    @media(min-width:834px){
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:80%;
        border-radius: map-get($generalStyles,sixBorderRadius);
    
    }
    //pc
    @media(min-width:1200px){
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:70%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:50px;
    }
        //lenovo
        @media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
            margin-top:5px;
            height:70vh;
        }

   

}

.xbCompName {
    position:absolute;
    top:65px;
    left:50%;
    transform:translateX(-50%);
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:white;
    //pc
    @media(min-width:1200px){
        top:90px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @media(min-width:1200px){
        top:130px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
        color:white;
    }
    //macbook
    @media(min-width:2303px){
        top:130px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:40px;
        color:white;
    }
         //lenovo
         @media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
            top:70px;
            font-family:map-get($generalStyles,fontFamilyLobster);
            font-Size:30px;
            color:white;
            }
}

.xbCompName1 {
 
    position:absolute;
    top:65px;
    left:50%;
    transform:translateX(-50%);
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:map-get($generalStyles,transparentChangeableMenu5);
   //pc
    @media(min-width:1200px){
        top:130px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }//macbook
    @media(min-width:2303px){
        top:130px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:40px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
//lenovo
@media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
top:70px;
font-family:map-get($generalStyles,fontFamilyLobster);
font-Size:30px;
color:map-get($generalStyles,transparentChangeableMenu5);
}
}

.xbCompNameJAP {
    position:absolute;
    top:68px;
    left:50%;
    transform:translateX(-50%);
    font-family: var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:map-get($generalStyles,transparentChangeableMenu5);
 //pc
    @media(min-width:1200px){
        top:90px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
    } //macbook
    @media(min-width:2303px){
        top:130px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:49px;
    }
}

.xbCompNameJAP1 {
    position:absolute;
    top:68px;
    left:50%;
    transform:translateX(-50%);
    font-family: var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:map-get($generalStyles,transparentChangeableMenu5);
//pc
    @media(min-width:1200px){
        top:90px;
    }
    //macbook
    @media(min-width:2303px){
        top:130px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:40px;
    }
}

// .range1 {
//     position:absolute;
//     top:110px;
//     left:65%;
//     transform:translateX(-50%);

//     //ipad11
//     @media(min-width:834px){
//         left:69%;
//     }
//     //pc
//     // @media(min-width:1200px){
//     //     top:120px;
//     //     left:92%;
//     // }
//     //macbook
//     @media(min-width:2303px){
//         top:200px;
//     }


//         // Add your custom styles for the thumb track
//         &::-webkit-slider-thumb {
//         background-color:white; // Change this to your desired color
//         height:20px;
//         width:20px;
//         border-radius:3px;
//     }

//     &::-moz-range-thumb {
//         background-color:white; // Change this to your desired color
//         height:20px;
//         width:20px;
//         border-radius:0;
//     }
//     &::-webkit-slider-runnable-track {
//         width:250px; // Change this to your desired width
//         background-color: map-get($generalStyles,transparentChangeableMenu5); // Change this to your desired color
//         border-radius: 0;
   
//     }

//     &::-moz-range-track {
//         width:250px; // Change this to your desired width
//         background-color:map-get($generalStyles,transparentChangeableMenu5); // Change this to your desired color
//         border-radius: 0;

//     }
//     //mobile
//     @media(max-width:575.98px){
//         left:70%;
//     }
//     //ipad11
//     @media(min-width:834px){
//         width:200px;
//     }
//     //ipad10
//     @media(min-width:834px) and (max-width:1112px){
//         left:90%;
//         width:150px;
//         }
//         //lenovo
//     @media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
//     top:75px;
//     left:92%;
//     width:150px;

//     }
//     @media(min-width:1200px){
//         top:120px;
//         left:92%;
//         &::-moz-range-track {
//               border-radius: 0;
    
//         }
//         &::-webkit-slider-runnable-track {
//             border-radius: 0;
       
//         }
//     }
    
// }

// .range2 {
//     position:absolute;
//     top:110px;
//     left:30%;
//     transform:translateX(-50%);
 

//        // Add your custom styles for the thumb track
//        &::-webkit-slider-thumb {
//         background-color:white; // Change this to your desired color
//         height:20px;
//         width:20px;
//         border-radius:3px;
//     }

//     &::-moz-range-thumb {
//         background-color:white; // Change this to your desired color
//         height:20px;
//         width:20px;
//         border-radius:0;
//     }
//     &::-webkit-slider-runnable-track {
//         width:250px; // Change this to your desired width
//         background-color: map-get($generalStyles,transparentChangeableMenu5); // Change this to your desired color
//         border-radius: 0;
//     }

//     &::-moz-range-track {
//         width:250px; // Change this to your desired width
//         background-color:map-get($generalStyles,transparentChangeableMenu5); // Change this to your desired color
//         border-radius: 0;
//     }
//     //ipad11
// @media(min-width:834px){
//     width:200px;
// }
// //ipad10
// @media(min-width:834px) and (max-width:1112px){
// left:73%;
// width:150px;
// }

// //pc
// @media(min-width:1200px){
//     top:120px;
//     left:80%;
// }
// //macbook
// @media(min-width:2303px){
//     top:200px;
// }
// //lenovo
// @media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
// top:75px;
// left:78%;
// width:150px;

// }


// }

.xbproduktselectTop {
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
  }
  
  .xbproduktselectFieldset {
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:90%;
    margin-top:3vh;
    border:none;
    @media(min-width:834px){
    margin-top:1vh;
    }
 
}
  
  .xbproduktselectLegend {
    margin-left:5px;
   font-family: var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);
    //ipad11
    @media(min-width:834px){
        margin-left:20px;
        font-size:12px;
    }
    //pc
    @media(min-width:1200px){
        margin-left:30px;
        font-size:14px;
    }
}
  
  .xbproduktselectLegendJAP {
   font-family: var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);
  }
  
  .xbproduktselectProdukt {
    width:95%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
   font-family: var(--font-family);
    background-color: white;
}
  
  .xbproduktselectProduktJAP {
    width: 100%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
   font-family: var(--font-family);
     background-color: white;
}
  
  .xbdatalistTop{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:2px;
    margin:0 2px;
    width:98%;
    // @media(min-width:834px){
    //     width:80%;
    // }
  }

  
.xblistproduktid{
    width:15%;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;


  }
  
  .xblistproduktidJAP {
    @extend .xblistproduktid;
    padding-top: 1px;
    color:black;
  }
  
  .xblistproduktbenaemn{
    width:55%;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    //ipad11
    @media(min-width:834px){
        width:62%;
    }
  }
  
  .xblistproduktbenaemnJAP{
    @extend .xblistproduktbenaemn;
    padding-top: 1px;
  }
  
  .xblistproduktactive {
    width:15%;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    //ipad11
    @media(min-width:834px){
        width:7%;
    }
    //laptop pc
    @media(min-width:1200px){
        width:7%;
    }
   
  }
  
  .xblistproduktactiveJAP{
    @extend .xblistproduktactive;
    padding-top: 1px;
  }

  .xblistproduktSave {
    width:15%;
    height: map-get($generalStyles, prefferedHeaderHeight);
   font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    margin-bottom: 3px;
    color:black;

  }
  
  .xblistproduktSaveJAP{
    @extend .xblistproduktSave;
    padding-top: 1px;
  }

  .xbdatalistData,.xbdatalistDataJAP {
    display: flex;
    flex-direction: column;
    gap:2px;
    margin: 0 2px;
    max-height: 500px;
    overflow-y:scroll;
    width:100%;
    //ipad11
    @media(min-width:834px){
        width:97.5%;
        max-height:419px;
        overflow-y:scroll;
    }
    //pc
    @media(min-width:1200px){

        max-height: 555px;
        overflow-y:scroll;
  
    }
    //macbook
    @media(min-width:2303px){

        max-height: 855px;
        overflow-y:scroll;
  
    }
  }
  

  .xbdatalistDataItemId1 {
    height: 34px;
  }
  
  .xbdatalistDataItemId {
    width:15%;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-bottom: 3px;
    text-align: center;
   font-family: var(--font-family);
    font-size: 14px;
    color:black;
    //ipad11
    @media(min-width:834px){
        width:15%;
    }
    //pc
    @media(min-width:1200px){
        width:15.1%;
    }
    //macbook
    @media(min-width:2303px){
        width:15.1%;
        height:35px;
    }
  }
  
  .xbdatalistDataItemId1 {
    @extend .xbdatalistDataItemId;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
  }
  
  .xbdatalistbenaemn1{
    width:55%;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 3px;
    text-align:center;
   font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    color:black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
//ipad11
    @media(min-width:834px){
        width:62.2%;
    }
    //pc
    @media(min-width:1200px){
 
        width:62.9%;
    
        
     
        
        
    }
    //macbook
    @media(min-width:2303px){
        width:62.9%;
        height:35px;
    }
  
  }
  
  .xbdatalistbenaemn {
    @extend .xbdatalistbenaemn1;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  }
  
  .xbDatalistDataItemActive {
width:12%;
   // margin-left: 23px;
    cursor: pointer;
//ipad11
    @media(min-width:834px) and (max-width:1180px){
        width:3%;
        margin-left:25px;
    }//pc
    @media(min-width:1200px){
        margin-left:40px;
        width:16px;
        height:16px;
    }
    //macbook
    @media(min-width:2303px){
        margin-left:50px;
        width:18px;
        height:18px;
    }
    //ipad10
    @media(min-width:834px) and (max-width:1112px){
        width:3%;
        margin-left:30px;
    }
    //lenovo
    @media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
        width:2%;
        margin-left:25px;
     //   width:3%;
     //   margin-left:5px;
    }
 



  }

.xbDatalistDataItemSaveButton1,.xbDatalistDataItemSaveButtonJAP {
    width:13.5%;
    height:30px;
    border-radius: 6px;
    margin-left:5px;
    border-color:map-get($generalStyles,datalistBorderColor);
    border:1px solid;
    background-color:map-get($generalStyles,pinkRed);
    color:white;
    font-family: var(--font-family);
    font-size:14px;
    padding:1px;
    //ipad 11
    @media(min-width:834px){
        width:14%;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:15px;
    }


    //ipad10
    @media(min-width:834px) and (max-width:1112px){
        width:13.5%;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:13px;
    }
    //pc
    @media(min-width:1200px){
        width:14%;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:35px;
    }
    //macbook
    @media(min-width:2303px){
        width:14%;
        height:35px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:41px;
    }

    //lenovo
    @media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
        width:12%;
        height:35px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
   //     margin-left:13px;
    }




}
  
  .xbDatalistDataItemSaveButton2,.xbDatalistDataItemSaveButtonJAP1{
    border-radius:6px;
    margin-left:5px;
    width:13.5%;
    height:30px;
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    background-color:white;
   font-family: var(--font-family);
    font-size: 14px;
    padding:1px;
    color:black;
    //ipad 11
    @media(min-width:834px){
        width:14.7%;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:12px;
}


//ipad10
@media(min-width:834px) and (max-width:1112px){
    width:13.5%;
    border:1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    margin-left:13px;
}
//pc
    @media(min-width:1200px){
        width:14%;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:35px;
    }
    //macbook
    @media(min-width:2303px){
        width:14%;
        height:35px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:41px;
    }
//lenovo
    @media(max-height:632px) and (max-width:1280px) and (orientation:landscape){
        width:13%;
        height:35px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-left:21px;
    }


  }
  
  .xblistSpinnerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        background-color: transparent;
  }

