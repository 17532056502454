:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
html {
  scrollbar-width: thin; /* For Firefox */
  overflow: overlay; /* For Chrome, Safari */
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .totalReceptListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .totalReceptListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .totalReceptListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .totalReceptListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}
@media only screen and (min-width:2303px) {
  .totalReceptListArea1 {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    justify-items: center;
    gap: 10px;
    grid-auto-rows: minmax(5px, auto);
  }
}

.backgroundImageListRecept {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
  opacity: 0.8;
}

.backgroundImageListRecept1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: white;
}

.xcheaderArea {
  grid-area: a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 99%;
  margin-top: 2px;
}

.xcrangeArea {
  grid-area: b;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcrangeArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xcrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}

.xcareaReceptListB {
  grid-area: c;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--val);
  width: 99%;
  border: 1px solid white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcareaReceptListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
    height: 78vh;
    margin-left: 2px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcareaReceptListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 80%;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcareaReceptListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 70%;
    border-radius: 6px;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcareaReceptListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 60%;
    border-radius: 6px;
    margin-top: 10px;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xcareaReceptListB {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    width: 60%;
    border-radius: 6px;
    margin-top: 10px;
    border-color: rgb(223, 212, 212);
  }
}

.xccompName {
  font-family: "Lobster";
  font-Size: 26px;
  color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xccompName {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xccompName {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xccompName {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xccompName {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: white;
  }
}

.xccompName1 {
  grid-area: a;
  font-family: "Lobster";
  font-Size: 26px;
  color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xccompName1 {
    margin-top: 2px;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xccompName1 {
    margin-top: 2vh;
    font-family: "Lobster";
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xccompName1 {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 35px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xccompName1 {
    margin-top: 3vh;
    font-family: "Lobster";
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

.xccompNameJAP {
  grid-area: a;
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xccompNameJAP {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xccompNameJAP {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xccompNameJAP {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 35px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xccompNameJAP {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 45px;
    color: white;
  }
}

.xccompNameJAP1 {
  grid-area: a;
  font-family: var(--font-family);
  font-size: 22px;
  white-space: nowrap;
  color: var(--rgbaVal);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xccompNameJAP1 {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xccompNameJAP1 {
    margin-top: 2vh;
    font-family: var(--font-family);
    font-Size: 30px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xccompNameJAP1 {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 35px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xccompNameJAP1 {
    margin-top: 3vh;
    font-family: var(--font-family);
    font-Size: 45px;
    color: var(--rgbaVal);
  }
}

.xcrange1 {
  grid-area: b;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xcrange1 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.xcrange2 {
  grid-area: b;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xcrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xcreceptselectTop {
  grid-area: c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 99%;
}

.xcreceptselectFieldset {
  grid-area: c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-top: 3vh;
  border: none;
}

.xcreceptselectLegend {
  margin-left: 5px;
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcreceptselectLegend {
    margin-left: 2.5%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcreceptselectLegend {
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcreceptselectLegend {
    margin-left: 3.1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcreceptselectLegend {
    margin-left: 5.5%;
  }
}
@media only screen and (min-width:2303px) {
  .xcreceptselectLegend {
    margin-left: 2.5%;
  }
}

.xcreceptselectLegendJAP {
  font-family: var(--font-family);
  font-size: 14px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcreceptselectLegendJAP {
    margin-left: 10%;
  }
}

.xcreceptselectrecept {
  width: 95%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcreceptselectrecept {
    width: 80%;
  }
}

.xcreceptselectreceptJAP {
  width: 100%;
  height: 30px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  border-radius: 6px;
  color: black;
  font-family: var(--font-family);
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcreceptselectreceptJAP {
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .xcreceptselectreceptJAP {
    width: 75%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcdatalistTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcdatalistTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcdatalistTop {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcdatalistTop {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xcdatalistTop {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xclistreceptid, .xclistreceptidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xclistreceptid, .xclistreceptidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xclistreceptid, .xclistreceptidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xclistreceptid, .xclistreceptidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xclistreceptid, .xclistreceptidJAP {
    width: 15%;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.xclistreceptidJAP {
  padding-top: 1px;
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xclistreceptbenaemn, .xclistreceptbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xclistreceptbenaemn, .xclistreceptbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xclistreceptbenaemn, .xclistreceptbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xclistreceptbenaemn, .xclistreceptbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xclistreceptbenaemn, .xclistreceptbenaemnJAP {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.xclistreceptbenaemnJAP {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xclistreceptactive, .xclistreceptactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xclistreceptactive, .xclistreceptactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xclistreceptactive, .xclistreceptactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xclistreceptactive, .xclistreceptactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xclistreceptactive, .xclistreceptactiveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}

.xclistreceptactiveJAP {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xclistreceptSave, .xclistreceptSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    padding-top: 5px;
    text-align: center;
    margin-bottom: 3px;
    color: black;
    padding: 1px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xclistreceptSave, .xclistreceptSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xclistreceptSave, .xclistreceptSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xclistreceptSave, .xclistreceptSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xclistreceptSave, .xclistreceptSaveJAP {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    padding: 1px;
    color: black;
    margin-right: 10px;
  }
}

.xclistreceptSaveJAP {
  padding-top: 1px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcdatalistData, .xcdatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 98%;
  }
  .xcdatalistData::-webkit-scrollbar, .xcdatalistDataJAP::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xcdatalistData::-webkit-scrollbar-track, .xcdatalistDataJAP::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcdatalistData, .xcdatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 80%;
  }
  .xcdatalistData::-webkit-scrollbar, .xcdatalistDataJAP::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xcdatalistData::-webkit-scrollbar-track, .xcdatalistDataJAP::-webkit-scrollbar-track {
    background: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcdatalistData, .xcdatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .xcdatalistData::-webkit-scrollbar, .xcdatalistDataJAP::-webkit-scrollbar {
    width: 10px;
  }
  .xcdatalistData::-webkit-scrollbar-track, .xcdatalistDataJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xcdatalistData::-webkit-scrollbar-thumb, .xcdatalistDataJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcdatalistData, .xcdatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .xcdatalistData::-webkit-scrollbar, .xcdatalistDataJAP::-webkit-scrollbar {
    width: 10px;
  }
  .xcdatalistData::-webkit-scrollbar-track, .xcdatalistDataJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xcdatalistData::-webkit-scrollbar-thumb, .xcdatalistDataJAP::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xcdatalistData, .xcdatalistDataJAP {
    display: flex;
    flex-direction: column;
    max-height: 53vh;
    overflow-y: scroll;
    width: 70%;
  }
  .xcdatalistData::-webkit-scrollbar, .xcdatalistDataJAP::-webkit-scrollbar {
    width: 1px; /* Adjust as needed */
  }
  .xcdatalistData::-webkit-scrollbar-track, .xcdatalistDataJAP::-webkit-scrollbar-track {
    background: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcitemId, .xcitemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcitemId, .xcitemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcitemId, .xcitemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcitemId, .xcitemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xcitemId, .xcitemId1 {
    width: 15%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 2px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}

.xcitemId1 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcbenaemn, .xcbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    color: black;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcbenaemn, .xcbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .xcbenaemn:hover, .xcbenaemn1:hover {
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcbenaemn, .xcbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
  .xcbenaemn:hover, .xcbenaemn1:hover {
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcbenaemn, .xcbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
  .xcbenaemn:hover, .xcbenaemn1:hover {
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xcbenaemn, .xcbenaemn1 {
    width: calc(55% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
  .xcbenaemn:hover, .xcbenaemn1:hover {
    color: white;
  }
}

.xcbenaemn1 {
  background-color: var(--rgbaVal2);
}
.xcbenaemn1:hover {
  background-color: var(--rgbaVal);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcactive, .xcactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .xcactive .xcdataitemChecked, .xcactive1 .xcdataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xcactive .xcdataitemUnchecked, .xcactive1 .xcdataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcactive, .xcactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .xcactive .xcdataitemChecked, .xcactive1 .xcdataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xcactive .xcdataitemUnchecked, .xcactive1 .xcdataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcactive, .xcactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
  }
  .xcactive .xcdataitemChecked, .xcactive1 .xcdataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xcactive .xcdataitemUnchecked, .xcactive1 .xcdataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcactive, .xcactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
  }
  .xcactive .xcdataitemChecked, .xcactive1 .xcdataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xcactive .xcdataitemUnchecked, .xcactive1 .xcdataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}
@media only screen and (min-width:2303px) {
  .xcactive, .xcactive1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    margin-top: 3px;
    cursor: pointer;
  }
  .xcactive .xcdataitemChecked, .xcactive1 .xcdataitemChecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
  .xcactive .xcdataitemUnchecked, .xcactive1 .xcdataitemUnchecked {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    color: blue;
  }
}

.xcactive1 {
  background-color: var(--rgbaVal2);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcsaveButton1, .xcsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcsaveButton1, .xcsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcsaveButton1, .xcsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcsaveButton1, .xcsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xcsaveButton1, .xcsaveButtonJAP1 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: rgb(229, 64, 68);
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xcsaveButton2, .xcsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xcsaveButton2, .xcsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xcsaveButton2, .xcsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xcsaveButton2, .xcsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xcsaveButton2, .xcsaveButtonJAP2 {
    width: calc(15% - 3px);
    margin-left: 3px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    background-color: white;
    margin-bottom: 3px;
    margin-top: 3px;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
  }
}

.xcsaveButtonJAP1 {
  font-size: 12px;
}

.xcsaveButtonJAP2 {
  font-size: 12px;
}

.xclistSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  background-color: transparent;
}/*# sourceMappingURL=ListRecept1.css.map */