:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgtotalArea, .xgtotalArea1 {
    display: grid;
    grid-template-rows: 3% 5% 33% 52%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: 710px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 362px) {
  .xgtotalArea, .xgtotalArea1 {
    grid-template-rows: 3% 5% 32% 53%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 380px) and (max-width: 386px) {
  .xgtotalArea, .xgtotalArea1 {
    grid-template-rows: 3% 5% 42% 53%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 392px) and (max-width: 399px) {
  .xgtotalArea, .xgtotalArea1 {
    grid-template-rows: 3% 5% 42% 53%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 415px) {
  .xgtotalArea, .xgtotalArea1 {
    grid-template-rows: 3% 5% 42% 53%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 425px) and (max-width: 429px) {
  .xgtotalArea, .xgtotalArea1 {
    grid-template-rows: 3% 5% 42% 53%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 429px) and (max-width: 431px) {
  .xgtotalArea, .xgtotalArea1 {
    grid-template-rows: 3% 5% 42% 53%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgtotalArea, .xgtotalArea1 {
    display: grid;
    grid-template-columns: 30% 40% 29%;
    grid-template-rows: 3% auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgtotalArea, .xgtotalArea1 {
    display: grid;
    grid-template-columns: 30% 40% 29%;
    grid-template-rows: 3% auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgtotalArea, .xgtotalArea1 {
    display: grid;
    grid-template-columns: 30% 40% 29%;
    grid-template-rows: 3% auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xgtotalArea, .xgtotalArea1 {
    display: grid;
    grid-template-columns: 30% 40% 29%;
    grid-template-rows: 3% auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgtotalArea1 {
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgtotalArea1 {
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgtotalArea1 {
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgtotalArea1 {
    background-color: rgb(245, 245, 237);
  }
}
@media only screen and (min-width:2303px) {
  .xgtotalArea1 {
    background-color: rgb(245, 245, 237);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcompName, .xgcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcompName, .xgcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcompName, .xgcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcompName, .xgcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xgcompName, .xgcompNameJAP {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcompName1, .xgcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcompName1, .xgcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcompName1, .xgcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcompName1, .xgcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xgcompName1, .xgcompNameJAP1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

.xgcompNameJAP {
  font-family: var(--font-family);
}

.xgcompNameJAP1 {
  font-family: var(--font-family);
  color: var(--rgbaVal);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width:2303px) {
  .xgheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xgrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgareaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    background-color: var(--val);
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-height: 640px) {
  .xgareaA {
    margin-top: 5px;
    justify-content: flex-start;
    height: 35vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgareaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 80vh;
    background-color: var(--val);
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgareaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgareaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xgareaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    margin-top: 1%;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgareaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 53vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-height: 640px) {
  .xgareaB {
    margin-top: 1px;
    height: 43vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgareaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 80vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgareaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgareaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xgareaB {
    grid-area: d;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgareaC {
    height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgareaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 80vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgareaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgareaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xgareaC {
    grid-area: e;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 99%;
    height: 82vh;
    background-color: var(--val);
    z-index: 1;
    margin-top: 1%;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgrange1, .xgrange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgrange1, .xgrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgrange1, .xgrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgrange1, .xgrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xgrange1, .xgrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xgrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgCategoryDatalist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-height: 640px) {
  .xgCategoryDatalist {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgCategoryDatalist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgCategoryDatalist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgCategoryDatalist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xgCategoryDatalist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgCategoryTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgCategoryTop {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgCategoryTop {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgCategoryTop {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .xgCategoryTop {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgrowsdata {
    width: 100%;
    min-height: 1px;
    max-height: 26vh;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    align-self: center;
  }
  .xgrowsdata::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 380px) and (max-width: 385px) {
  .xgrowsdata {
    max-height: 32vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 390px) and (max-width: 393px) {
  .xgrowsdata {
    max-height: 34vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .xgrowsdata {
    max-height: 28vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 425px) and (max-width: 429px) {
  .xgrowsdata {
    max-height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 429px) and (max-width: 431px) {
  .xgrowsdata {
    max-height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 478px) and (max-width: 481px) {
  .xgrowsdata {
    max-height: 28vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgrowsdata {
    width: 100%;
    min-height: 1px;
    max-height: 65vh;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    align-self: center;
  }
  .xgrowsdata::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgrowsdata {
    width: 100%;
    min-height: 1px;
    max-height: 72vh;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    align-self: center;
  }
  .xgrowsdata::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgrowsdata {
    width: 100%;
    min-height: 1px;
    max-height: 72vh;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    align-self: center;
  }
  .xgrowsdata::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .xgrowsdata {
    width: 100%;
    min-height: 1px;
    max-height: 72vh;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    align-self: center;
  }
  .xgrowsdata::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgrows {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgrows {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgrows {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgrows {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .xgrows {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgBenaemnHeader {
    font-family: var(--font-family);
    font-size: 14px;
    color: grey;
    margin-bottom: 3px;
    margin-top: 3px;
    margin-left: 12%;
    width: 55%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgBenaemnHeader {
    font-family: var(--font-family);
    font-size: 14px;
    color: grey;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 55%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgBenaemnHeader {
    font-family: var(--font-family);
    font-size: 14px;
    color: grey;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 55%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgBenaemnHeader {
    font-family: var(--font-family);
    font-size: 18px;
    color: grey;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 55%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xgBenaemnHeader {
    font-family: var(--font-family);
    font-size: 24px;
    color: grey;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 55%;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgAntalHeader {
    font-family: var(--font-family);
    font-size: 14px;
    color: grey;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 20%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgAntalHeader {
    font-family: var(--font-family);
    font-size: 14px;
    color: grey;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 25%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1194px) {
  .xgAntalHeader {
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgAntalHeader {
    font-family: var(--font-family);
    font-size: 14px;
    color: grey;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 25%;
    height: 25px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgAntalHeader {
    font-family: var(--font-family);
    font-size: 18px;
    color: grey;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 25%;
    height: 35px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xgAntalHeader {
    font-family: var(--font-family);
    font-size: 24px;
    color: grey;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 25%;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    background-color: var(--rgbaVal);
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgdataBenaemn {
    width: 55%;
    height: 30px;
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgdataBenaemn {
    width: 55%;
    height: 30px;
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgdataBenaemn {
    width: 55%;
    height: 30px;
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgdataBenaemn {
    width: 55%;
    height: 40px;
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xgdataBenaemn {
    width: 55%;
    height: 40px;
    background-color: var(--rgbaVal);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgdataBenaemn1 {
    width: 55%;
    height: 30px;
    background-color: var(--rgbaVal2);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgdataBenaemn1 {
    width: 55%;
    height: 30px;
    background-color: var(--rgbaVal2);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgdataBenaemn1 {
    width: 55%;
    height: 30px;
    background-color: var(--rgbaVal2);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgdataBenaemn1 {
    width: 55%;
    height: 40px;
    background-color: var(--rgbaVal2);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xgdataBenaemn1 {
    width: 55%;
    height: 40px;
    background-color: var(--rgbaVal2);
    margin-bottom: 3px;
    border-radius: 6px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 26px;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgdataAntal {
    width: 20%;
    height: 30px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgdataAntal {
    width: 25%;
    height: 30px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgdataAntal {
    width: 25%;
    height: 30px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgdataAntal {
    width: 25%;
    height: 40px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xgdataAntal {
    width: 25%;
    height: 40px;
    background-color: var(--rgbaVal);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgdataAntal1 {
    width: 20%;
    height: 30px;
    background-color: var(--rgbaVal2);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgdataAntal1 {
    width: 25%;
    height: 30px;
    background-color: var(--rgbaVal2);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgdataAntal1 {
    width: 25%;
    height: 30px;
    background-color: var(--rgbaVal2);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgdataAntal1 {
    width: 25%;
    height: 40px;
    background-color: var(--rgbaVal2);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 22px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xgdataAntal1 {
    width: 25%;
    height: 40px;
    background-color: var(--rgbaVal2);
    margin-left: 3px;
    margin-bottom: 3px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    border-color: rgb(223, 212, 212);
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgrightdataArea {
    width: 95%;
    height: 64vh;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    margin-left: 5px;
    padding-top: 5px;
    padding-left: 10px;
    max-height: 15vh;
    overflow-y: auto;
  }
  .xgrightdataArea::-webkit-scrollbar {
    display: none;
  }
  .xgrightdataArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgrightdataArea::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-height: 640px) {
  .xgrightdataArea {
    max-height: 11vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgrightdataArea {
    width: 95%;
    height: 64vh;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    margin-left: 5px;
    padding-top: 5px;
    padding-left: 10px;
    max-height: 35vh;
    overflow-y: auto;
  }
  .xgrightdataArea::-webkit-scrollbar {
    width: 10px;
  }
  .xgrightdataArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgrightdataArea::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgrightdataArea {
    width: 95%;
    height: 35vh;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    margin-left: 5px;
    padding-top: 5px;
    padding-left: 10px;
    max-height: 35vh;
    overflow-y: auto;
  }
  .xgrightdataArea::-webkit-scrollbar {
    width: 10px;
  }
  .xgrightdataArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgrightdataArea::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgrightdataArea {
    width: 95%;
    height: 35vh;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    margin-left: 5px;
    padding-top: 5px;
    padding-left: 10px;
    max-height: 35vh;
    overflow-y: auto;
  }
  .xgrightdataArea::-webkit-scrollbar {
    width: 10px;
  }
  .xgrightdataArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgrightdataArea::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width:2303px) {
  .xgrightdataArea {
    width: 95%;
    height: 64vh;
    background-color: transparent;
    border: none;
    border-color: rgb(223, 212, 212);
    margin-top: 10px;
    margin-left: 5px;
    padding-top: 5px;
    padding-left: 10px;
    max-height: 35vh;
    overflow-y: auto;
  }
  .xgrightdataArea::-webkit-scrollbar {
    width: 10px;
  }
  .xgrightdataArea::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgrightdataArea::-webkit-scrollbar-thumb {
    background: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcategoryrightButton {
    display: inline-block;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcategoryrightButton {
    display: inline-block;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcategoryrightButton {
    display: inline-block;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcategoryrightButton {
    display: inline-block;
    cursor: pointer;
    background-color: var(--rgbaVal);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding: 5px;
    width: auto;
    height: 30px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xgcategoryrightButton {
    display: inline-block;
    cursor: pointer;
    background-color: var(--rgbaVal);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcategoryrightButton1 {
    display: inline-block;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal2);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcategoryrightButton1 {
    display: inline-block;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal2);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcategoryrightButton1 {
    display: inline-block;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal2);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcategoryrightButton1 {
    display: inline-block;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    color: black;
    border-radius: 6px;
    margin-bottom: 3px;
    padding: 5px;
    width: auto;
    height: 30px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}
@media only screen and (min-width:2303px) {
  .xgcategoryrightButton1 {
    display: inline-block;
    cursor: pointer;
    background-color: var(--rgbaVal2);
    color: black;
    border-radius: 10px;
    margin-bottom: 5px;
    padding-bottom: 3px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcategoryrightButtontext {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcategoryrightButtontext {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcategoryrightButtontext {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcategoryrightButtontext {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 26px;
  }
}
@media only screen and (min-width:2303px) {
  .xgcategoryrightButtontext {
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgingredmoveTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgingredmoveTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgingredmoveTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgingredmoveTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xgingredmoveTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredcatTrash {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredcatTrash {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredcatTrash {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredcatTrash {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width:2303px) {
  .ingredcatTrash {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredcatTrash1 {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredcatTrash1 {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredcatTrash1 {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredcatTrash1 {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}
@media only screen and (min-width:2303px) {
  .ingredcatTrash1 {
    position: absolute;
    top: 56px;
    left: 195px;
    cursor: not-allowed;
    color: black;
    font-size: 26px;
    opacity: 0.8;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcreatecatTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcreatecatTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcreatecatTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcreatecatTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media only screen and (min-width:2303px) {
  .xgcreatecatTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgingredcatcreateCategoryTop {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgingredcatcreateCategoryTop {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgingredcatcreateCategoryTop {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgingredcatcreateCategoryTop {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xgingredcatcreateCategoryTop {
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgingredcatcreateCategoryInput {
    width: 60%;
    height: 25px;
    border: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 3%;
    margin-left: 4%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgingredcatcreateCategoryInput {
    width: 60%;
    height: 25px;
    border: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 3%;
    margin-left: 4%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgingredcatcreateCategoryInput {
    width: 60%;
    height: 25px;
    border: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 3%;
    margin-left: 4%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgingredcatcreateCategoryInput {
    width: 60%;
    height: 40px;
    border: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 12px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 3%;
    margin-left: 4%;
  }
}
@media only screen and (min-width:2303px) {
  .xgingredcatcreateCategoryInput {
    width: 60%;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 20px;
    color: black;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-left: 4%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgingredcatcreateCategoryButton {
    cursor: pointer;
    border: solid 1px darkgrey;
    width: 30%;
    height: 28px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
    margin-left: 3px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgingredcatcreateCategoryButton {
    cursor: pointer;
    border: solid 1px darkgrey;
    width: 30%;
    height: 28px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgingredcatcreateCategoryButton {
    cursor: pointer;
    border: solid 1px darkgrey;
    width: 30%;
    height: 28px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgingredcatcreateCategoryButton {
    cursor: pointer;
    border: solid 1px darkgrey;
    width: 25%;
    height: 43px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
    margin-left: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xgingredcatcreateCategoryButton {
    cursor: pointer;
    border: solid 1px darkgrey;
    width: 25%;
    height: 38px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
    margin-left: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgingredcatcreateCategoryButton1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-right: 3px;
    width: 30%;
    height: 28px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgingredcatcreateCategoryButton1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-right: 3px;
    width: 30%;
    height: 28px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    margin-left: 3px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgingredcatcreateCategoryButton1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-right: 3px;
    width: 30%;
    height: 28px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    margin-left: 3px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgingredcatcreateCategoryButton1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-right: 3px;
    width: 25%;
    height: 43px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    margin-left: 3px;
    opacity: 0.8;
  }
}
@media only screen and (min-width:2303px) {
  .xgingredcatcreateCategoryButton1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-right: 3px;
    width: 25%;
    height: 40px;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    margin-left: 3px;
    opacity: 0.8;
  }
}

@media only screen and (min-width:2303px) {
  .xgingredcatcreateCategoryTop {
    margin-top: 1.8%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcreateCat {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.6;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcreateCat {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.6;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcreateCat {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.6;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcreateCat {
    color: black;
    font-size: 40px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.6;
  }
}
@media only screen and (min-width:2303px) {
  .xgcreateCat {
    color: black;
    font-size: 50px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.6;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcreateCat1 {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcreateCat1 {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcreateCat1 {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcreateCat1 {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xgcreateCat1 {
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgtextAreaMessage {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    width: 200px;
    height: 430px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgtextAreaMessage {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    width: 90%;
    height: 75vh;
    margin: 10px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgtextAreaMessage {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    width: 90%;
    height: 75vh;
    margin: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgtextAreaMessage {
    font-family: var(--font-family);
    font-size: 18px;
    color: black;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    width: 90%;
    height: 75vh;
    margin: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xgtextAreaMessage {
    font-family: var(--font-family);
    font-size: 24px;
    color: black;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    width: 90%;
    height: 75vh;
    margin: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgSpinnerContainer {
    position: absolute;
    top: 290px;
    left: 170px;
    background-color: transparent;
    transform: scale(2.5);
    z-index: 10;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xgSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xgSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xgSpinnerContainer.active {
    z-index: 9999;
  }
}
@media only screen and (min-width:2303px) {
  .xgSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 1;
  }
  .xgSpinnerContainer.active {
    z-index: 9999;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgSpinner {
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgSpinner {
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgSpinner {
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgSpinner {
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .ingredmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .ingredmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .ingredmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .ingredmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .ingredmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgreceptmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgreceptmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgreceptmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgreceptmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xgreceptmoveitemTop {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgreceptmoveitemFieldset {
    max-height: 10vh;
    overflow-y: scroll;
    width: 90%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar {
    width: 5px;
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgreceptmoveitemFieldset {
    margin-top: 10px;
    max-height: 21vh;
    overflow-y: scroll;
    width: 90%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar {
    width: 5px;
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgreceptmoveitemFieldset {
    margin-top: 10px;
    max-height: 25vh;
    overflow-y: scroll;
    width: 90%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar {
    width: 5px;
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgreceptmoveitemFieldset {
    margin-top: 10px;
    max-height: 25vh;
    overflow-y: scroll;
    width: 90%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar {
    width: 10px;
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}
@media only screen and (min-width:2303px) {
  .xgreceptmoveitemFieldset {
    margin-top: 10px;
    max-height: 25vh;
    overflow-y: scroll;
    width: 90%;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar {
    width: 10px;
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xgreceptmoveitemFieldset::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid grey;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgreceptmoveitemLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgreceptmoveitemLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgreceptmoveitemLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgreceptmoveitemLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xgreceptmoveitemLegend {
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgmoveitemSelect {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: white;
    color: black;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    width: 200px;
    height: 35px;
    margin-top: 5px;
    outline: none;
    margin-right: 5px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgmoveitemSelect {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: white;
    color: black;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    width: 200px;
    height: 35px;
    outline: none;
    margin-right: 5px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgmoveitemSelect {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: white;
    color: black;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    width: 200px;
    height: 35px;
    outline: none;
    margin-right: 5px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgmoveitemSelect {
    font-family: var(--font-family);
    font-size: 18px;
    background-color: white;
    color: black;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    width: 200px;
    height: 35px;
    outline: none;
    margin-right: 5px;
    margin-left: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .xgmoveitemSelect {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: white;
    color: black;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(236, 228, 228);
    width: 200px;
    height: 35px;
    outline: none;
    margin-right: 5px;
    margin-left: 3px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgmoveitemSaveButton {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: rgb(229, 64, 68);
    color: white;
    border-radius: 6px;
    border: rgb(223, 212, 212);
    width: 100px;
    height: 32px;
    margin-top: 5px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgmoveitemSaveButton {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: rgb(229, 64, 68);
    color: white;
    border-radius: 6px;
    border: rgb(223, 212, 212);
    width: 100px;
    height: 32px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgmoveitemSaveButton {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: rgb(229, 64, 68);
    color: white;
    border-radius: 6px;
    border: rgb(223, 212, 212);
    width: 100px;
    height: 34px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgmoveitemSaveButton {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: rgb(229, 64, 68);
    color: white;
    border-radius: 6px;
    border: rgb(223, 212, 212);
    width: 100px;
    height: 34px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xgmoveitemSaveButton {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: rgb(229, 64, 68);
    color: white;
    border-radius: 6px;
    border: rgb(223, 212, 212);
    width: 100px;
    height: 34px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgmoveitemSaveButton1 {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: white;
    border-radius: 6px;
    border: rgb(223, 212, 212);
    width: 100px;
    height: 32px;
    margin-top: 5px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.5;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgmoveitemSaveButton1 {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: white;
    border-radius: 6px;
    border: rgb(223, 212, 212);
    width: 100px;
    height: 32px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.5;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgmoveitemSaveButton1 {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: white;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100px;
    height: 34px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgmoveitemSaveButton1 {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: white;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100px;
    height: 34px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
  }
}
@media only screen and (min-width:2303px) {
  .xgmoveitemSaveButton1 {
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--rgbaVal);
    color: white;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100px;
    height: 34px;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgCategoryInputKanji {
    grid-area: "b";
    width: 300px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgCategoryInputKanji {
    grid-area: "b";
    width: 300px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgCategoryInputKanji {
    grid-area: "b";
    width: 300px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgCategoryInputKanji {
    grid-area: "b";
    width: 400px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width:2303px) {
  .xgCategoryInputKanji {
    grid-area: "b";
    width: 400px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgCategoryInputFurigana {
    width: 300px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgCategoryInputFurigana {
    width: 300px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgCategoryInputFurigana {
    width: 300px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgCategoryInputFurigana {
    width: 400px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}
@media only screen and (min-width:2303px) {
  .xgCategoryInputFurigana {
    width: 400px;
    height: 25px;
    border: none;
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 14px;
    color: black;
    padding-left: 5px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    margin-top: 1px;
    margin-left: 7px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgCategoryTopJAP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgCategoryTopJAP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgCategoryTopJAP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgCategoryTopJAP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    margin-top: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xgCategoryTopJAP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    margin-top: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcreateCategoryButtonJAP1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 195px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcreateCategoryButtonJAP1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 195px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcreateCategoryButtonJAP1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 195px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcreateCategoryButtonJAP1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 295px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xgcreateCategoryButtonJAP1 {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 295px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xgcreateCategoryButtonJAP {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 195px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: rgb(229, 64, 68);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xgcreateCategoryButtonJAP {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 195px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xgcreateCategoryButtonJAP {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 195px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xgcreateCategoryButtonJAP {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 295px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    opacity: 0.7;
  }
}
@media only screen and (min-width:2303px) {
  .xgcreateCategoryButtonJAP {
    cursor: pointer;
    border: solid 1px darkgrey;
    margin-left: 295px;
    width: 120px;
    height: 34px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 5px;
    background-color: var(--rgbaVal);
    font-family: var(--font-family);
    font-size: 12px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    color: black;
    opacity: 0.7;
  }
}

.xgbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}/*# sourceMappingURL=RecepyCategoriesNew2.css.map */