$primary-color: #0070f3;
$text-color: #333;
$muted-color: #666;
$background-color: #ffffff;
$border-color: #ccc;
$link-color: #0070f3;

.stripe-onboarding {
  max-width: 400px;
  margin: 0 auto;
  margin-top:5vh;
  padding: 2rem;
  background-color: $background-color;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
    color: $text-color;
  }

  &__description {
    text-align: center;
    color: $muted-color;
    margin-bottom: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__info {
    text-align: center;
    font-size: 0.875rem;
    color: $muted-color;
  }

  &__button {
    width: 100%;
    padding: 0.75rem;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  &__link {
    display: block;
    padding: 0.75rem;
    border: 2px solid $border-color;
    border-radius: 4px;
    text-align: center;
    color: $link-color;
    text-decoration: none;
    font-size: 0.875rem;
    transition: all 0.3s ease;

    &:hover {
      background-color: lighten($link-color, 45%);
      border-color: $link-color;
    }
  }

  &__id-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__label {
    font-size: 0.875rem;
    color: $text-color;
  }

  &__input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 0.875rem;
  }

  &__footer {
    margin-top: 1.5rem;
    text-align: center;
    font-size:1rem;
    color: $muted-color;
  }
}


  