@import '../style/MainStyles.scss';/* styles.css */


html {
    scrollbar-width: thin; /* For Firefox */
    overflow: overlay; /* For Chrome, Safari */
  }

.totalReceptListArea1{
   

    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }
        // Use the mixin for tablet devices
        @include gadgets-to(tablet) {
    
            display: grid;
            grid-template-rows:auto auto auto;
            grid-template-areas: 
              "a"
              "b"
              "c";
            width: 100%;
            justify-items: center;
            gap:10px;
            grid-auto-rows: minmax(5px,auto);
        
        }

    @include gadgets-to(laptop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }

    @include gadgets-to(desktop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }

    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-rows:auto auto auto;
        grid-template-areas: 
          "a"
          "b"
          "c";
        width: 100%;
        justify-items: center;
        gap:10px;
        grid-auto-rows: minmax(5px,auto);
    }







  }



.backgroundImageListRecept{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1);
    opacity: 0.8;
    // border-bottom-left-radius: $preffered-ipad-component-outline-border-bottom-left-radius;
    // border-bottom-right-radius: $preffered-ipad-component-outline-border-bottom-right-radius;
   
}
.backgroundImageListRecept1{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    z-index: -1;
    // transform:scale(1);
    // opacity: 0.8;
background-color: white;
}

.xcheaderArea{
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
}
.xcrangeArea{
  grid-area:b;
  @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content: space-around;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content:center;
    
}
@include gadgets-to(tablet) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(laptop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(desktop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
}
}




.xcareaReceptListB{
    grid-area: c;
    border:map-get($generalStyles,prefferedBorderStyle );
    border-color:map-get($generalStyles,prefferedBorderColor);
    background-color:map-get($generalStyles,transparentChangeable);
    width:99%;
    border:1px solid white;
    
    //mobile
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction:column;
        align-items: center;
        width:99%;
        height:78vh;
        margin-left:2px;
        border-radius: map-get($generalStyles,sixBorderRadius);
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:80%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        border:map-get($generalStyles,prefferedBorderStyle );
        border-color:map-get($generalStyles,prefferedBorderColor);
    }

    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:70%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border:map-get($generalStyles,prefferedBorderStyle);
        border-color:map-get($generalStyles,prefferedBorderColor);

    }

    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:60%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border-color:map-get($generalStyles,prefferedBorderColor);
    }

    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        align-items: center;
        height:70vh;
        width:60%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:10px;
        border-color:map-get($generalStyles,prefferedBorderColor);
    }
    

   

}

.xccompName {

    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:white;
    //pc
    @include gadgets-to(tablet) {
        margin-top:2px;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
        color:white;
    }
    @include gadgets-to(laptop) {
        margin-top:2vh;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:35px;
        color:white;
    }

    @include gadgets-to(desktop) {
        margin-top:3vh;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:35px;
        color:white;
    }

    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
        font-family:map-get($generalStyles,fontFamilyLobster);
        font-Size:45px;
        color:white;
    }


}

.xccompName1 {
 grid-area: a;

    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:map-get($generalStyles,transparentChangeableMenu5);
   

   @include gadgets-to(tablet) {
    margin-top:2px;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    margin-top:2vh;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:30px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}


@include gadgets-to(desktop) {
    margin-top:3vh;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:35px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}

@include gadgets-to(largeDesktop) {
    margin-top:3vh;
    font-family:map-get($generalStyles,fontFamilyLobster);
    font-Size:45px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}


}

.xccompNameJAP {
    grid-area:a;

    font-family: var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:white;

    @include gadgets-to(tablet) {
        margin-top:2vh;
        font-family: var(--font-family);
        font-Size:30px;
        color:white;
      }
    @include gadgets-to(laptop) {
        margin-top:2vh;
        font-family: var(--font-family);
        font-Size:30px;
        color:white;
    
      }



    @include gadgets-to(desktop) {
        margin-top:3vh;
        font-family: var(--font-family);
        font-Size:35px;
        color:white;
    }
    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
        font-family: var(--font-family);
        font-Size:45px;
        color:white;
      }
    


}

.xccompNameJAP1 {
    grid-area:a;

  font-family: var(--font-family);
    font-size: 22px;
    white-space: nowrap;
    color:map-get($generalStyles,transparentChangeableMenu5);

    @include gadgets-to(tablet) {
        margin-top:2vh;
      font-family: var(--font-family);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }

    @include gadgets-to(laptop) {
        margin-top:2vh;
      font-family: var(--font-family);
        font-Size:30px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }


    @include gadgets-to(desktop) {
        margin-top:3vh;
      font-family: var(--font-family);
        font-Size:35px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
      font-family: var(--font-family);
        font-Size:45px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    
}

.xcrange1 {
    grid-area:b;
    @include gadgets-to(mobile) {
   //   margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
  }
  @include gadgets-to(tablet) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      margin-right:2%;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(desktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
@include gadgets-to(largeDesktop) {
    //  margin-top:3%;
      accent-color: blue;
      width:125px;
      margin-left:10px;
      cursor:pointer;
  }
}

.xcrange2 {
    grid-area:b;
    @include gadgets-to(mobile) {
      accent-color: rgb(251, 239, 132);
      width:125px;

  }
  @include gadgets-to(tablet) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
@include gadgets-to(laptop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(desktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
  @include gadgets-to(largeDesktop) {
      accent-color: rgb(251, 239, 132);
      width:125px;
      cursor:pointer;
  }
}

.xcreceptselectTop{
    grid-area:c;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
  }
  
  .xcreceptselectFieldset {
    grid-area:c;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:90%;
    margin-top:3vh;
    border:none;
 
}
  
  .xcreceptselectLegend {
    margin-left:5px;
font-family: var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);

    @include gadgets-to(mobile) {
        margin-left:2.5%;
       }
          
    @include gadgets-to(tablet) {
        margin-left:10%;
       }
       @include gadgets-to(laptop) {
        margin-left:3.1%;
       }
          

       @include gadgets-to(desktop) {
        margin-left:5.5%;
       }
       @include gadgets-to(largeDesktop) {
        margin-left:2.5%;
       }
}
  
  .xcreceptselectLegendJAP {
font-family: var(--font-family);
    font-size: map-get($generalStyles, legendFontsize);

    @include gadgets-to(tablet) {
        margin-left:10%;
       }
  
  }
  
  .xcreceptselectrecept{
    width:95%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
font-family: var(--font-family);
    background-color: white;

    @include gadgets-to(tablet) {
        width:80%;
       }

}
  
  .xcreceptselectreceptJAP {
    width: 100%;
    height: map-get($generalStyles, selectHeight);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
font-family: var(--font-family);
     background-color: white;

     
    @include gadgets-to(tablet) {
        width:80%;
       }

       @include gadgets-to(largeDesktop) {
        width:75%;
       }
}
  
  .xcdatalistTop{
    @include gadgets-to(mobile){
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:98%;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width:80%;
    
    }

@include gadgets-to(laptop) {
        width:70%;
        display: flex;
        flex-direction: row;
        justify-content: center;
   
       }
             
@include gadgets-to(desktop) {
width:70%;
display: flex;
flex-direction: row;
justify-content: center;

}
      
@include gadgets-to(largeDesktop) {
width:70%;
display: flex;
flex-direction: row;
justify-content: center;

}
  }

  
.xclistreceptid {
    @include gadgets-to(mobile){
    width:15%;
    height: map-get($generalStyles, prefferedHeaderHeight);
font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }


    @include gadgets-to(tablet){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }
    @include gadgets-to(laptop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }

    @include gadgets-to(desktop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }

    @include gadgets-to(largeDesktop){
        width:15%;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        
    }



  }
  
  .xclistreceptidJAP {
    @extend .xclistreceptid;
    padding-top: 1px;
    color:black;
  }
  
  .xclistreceptbenaemn {
    @include gadgets-to(mobile){
    width:calc(55% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
font-family: var(--font-family);
    font-size: map-get($generalStyles, prefferedHeaderFontsize);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }

    @include gadgets-to(tablet){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(laptop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(desktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(largeDesktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    // @include gadgets-to(tablet){
    //   width:55%;
    // }

  }
  
  .xclistreceptbenaemnJAP {
    @extend .xclistreceptbenaemn;
    padding-top: 1px;
  }
  
  .xclistreceptactive {

    @include gadgets-to(mobile){
    width:calc(15% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
font-family: var(--font-family);
    font-size: 12px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    color:black;
    padding:1px;
    }

    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
    font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
    font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
    font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedTabletListHeight);
    font-family: var(--font-family);
        font-size:map-get($generalStyles,prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
    }

  }
  
  .xclistreceptactiveJAP {
    @extend .xclistreceptactive;
    padding-top: 1px;
  }

  .xclistreceptSave {
    @include gadgets-to(mobile){
    width:calc(15% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedHeaderHeight);
font-family: var(--font-family);
    font-size:12px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    padding-top: 5px;
    text-align: center;
    margin-bottom: 3px;
    color:black;
    padding:1px;
    }
 

    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;

    }   

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
        font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }   

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }  
    
    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height: map-get($generalStyles, prefferedHeaderHeight);
    font-family: var(--font-family);
        font-size: map-get($generalStyles, prefferedHeaderFontsize);
        border-radius: map-get($generalStyles, sixBorderRadius);
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        padding:1px;
        color:black;
        margin-right:10px;


    }   

  }
  
  .xclistreceptSaveJAP {
    @extend .xclistreceptSave;
    padding-top: 1px;
  }

  .xcdatalistData,.xcdatalistDataJAP {

     
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: column;
        // gap:2px;
        // margin: 0 2px;
        max-height:53vh;
        overflow-y:scroll;
         width:98%;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }
       }


    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        // gap:2px;
        // margin: 0 2px;
        max-height:53vh;
        overflow-y:scroll;
        width:80%;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }
       }

       @include gadgets-to(laptop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        &::-webkit-scrollbar {
          width: 10px;
     }
     &::-webkit-scrollbar-track {
          background:map-get($generalStyles, transparentChangeableMenu5);
     }
     &::-webkit-scrollbar-thumb {
          background:white;
          border-radius:10px;
          border:1px solid map-get($generalStyles,transparentChangeableMenu5);       
      }

       }

       @include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
          &::-webkit-scrollbar {
            width: 10px;
       }
       &::-webkit-scrollbar-track {
            background:map-get($generalStyles, transparentChangeableMenu5);
       }
       &::-webkit-scrollbar-thumb {
            background:white;
            border-radius:10px;
            border:1px solid map-get($generalStyles,transparentChangeableMenu5);       
        }


       }

       @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        max-height:53vh;
        overflow-y:scroll;
        width:70%;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }


       }

  
  }
  
//   .datalistDataItem10 {
//     height: 34px;
//   }
  
  .xcitemId {
    @include gadgets-to(mobile){
    width:15%;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 3px;
    text-align: center;
font-family: var(--font-family);
    font-size:12px;
    color:black;

  }
  @include gadgets-to(tablet){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(laptop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(desktop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }

  @include gadgets-to(largeDesktop){
    width:15%;
    height: map-get($generalStyles, prefferedTabletListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
    text-align: center;
font-family: var(--font-family);
    font-size: 14px;
    color:black;
  }
}

  
  .xcitemId1 {
    @extend .xcitemId;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
  }
  
  .xcbenaemn {
    @include gadgets-to(mobile){
    width:calc(55% - 3px);
    margin-left:3px;
    height: map-get($generalStyles, prefferedListHeight);
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 3px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 3px;
    color:black;
    cursor:pointer;

       overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }


    @include gadgets-to(tablet){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor:pointer;
        //hover
        &:hover {
            color:white;
          }
    }

    @include gadgets-to(laptop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        cursor:pointer;
        //hover
        &:hover {
    
            color:white;
          }
    }

    @include gadgets-to(desktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        cursor:pointer;
        //hover
        &:hover {
            color:white;
          }
    }

    
    @include gadgets-to(largeDesktop){
        width:calc(55% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        font-family: map-get($generalStyles,fontFamily);
        font-size:map-get($generalStyles,prefferedTabletListFontSize);
        color:black;
        cursor:pointer;
        //hover
        &:hover {
            color:white;
          }
    }
  
  }
  
  .xcbenaemn1 {
    @extend .xcbenaemn;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    //hover
    &:hover {
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorBlack);
      }
  }




  .xcactive,.xcactive1 {

    @include gadgets-to(mobile){
 width:calc(15% - 3px);
 margin-left:3px;
 height:map-get($generalStyles,prefferedTabletListHeight);
 border-radius: map-get($generalStyles,sixBorderRadius);
 border: map-get($generalStyles,prefferedBorderStyle);
 border-color: map-get($generalStyles,prefferedBorderColor);
 background-color: map-get($generalStyles,transparentChangeableMenu5);
 margin-bottom:3px;
 margin-top:3px;

 .xcdataitemChecked{
    width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

      .xcdataitemUnchecked{
        width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }
    }

    @include gadgets-to(tablet){
 width:calc(15% - 3px);
 margin-left:3px;
 height:map-get($generalStyles,prefferedTabletListHeight);
 border-radius: map-get($generalStyles,sixBorderRadius);
 border: map-get($generalStyles,prefferedBorderStyle);
 border-color: map-get($generalStyles,prefferedBorderColor);
 background-color: map-get($generalStyles,transparentChangeableMenu5);
 margin-bottom:3px;
 margin-top:3px;


 .xcdataitemChecked{
    width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

      .xcdataitemUnchecked{
        width:12px;
    height:12px;
    padding:0;
    margin:0;
    color:blue;
      }

    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-bottom:3px;
        margin-top:3px;
        cursor:pointer;
       
       
        .xcdataitemChecked{
           width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
             .xcdataitemUnchecked{
               width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
           }

              @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-bottom:3px;
        margin-top:3px;
        cursor:pointer;
       
       
        .xcdataitemChecked{
           width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
             .xcdataitemUnchecked{
               width:12px;
           height:12px;
           padding:0;
           margin:0;
           color:blue;
             }
       
           }

           @include gadgets-to(largeDesktop){
            width:calc(15% - 3px);
            margin-left:3px;
            height:map-get($generalStyles,prefferedTabletListHeight);
            border-radius: map-get($generalStyles,sixBorderRadius);
            border: map-get($generalStyles,prefferedBorderStyle);
            border-color: map-get($generalStyles,prefferedBorderColor);
            background-color: map-get($generalStyles,transparentChangeableMenu5);
            margin-bottom:3px;
            margin-top:3px;
            cursor:pointer;
           
           
            .xcdataitemChecked{
               width:12px;
               height:12px;
               padding:0;
               margin:0;
               color:blue;
                 }
           
                 .xcdataitemUnchecked{
                   width:12px;
               height:12px;
               padding:0;
               margin:0;
               color:blue;
                 }
           
               }

}


.xcactive1{
    @extend .xcactive;
    background-color: map-get($generalStyles,transparentChangeableMenu4);

  }

.xcsaveButton1 {
    @include gadgets-to(mobile){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
    font-family: var(--font-family);
        font-size: 14px;
    }


    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
    font-family: var(--font-family);
        font-size: 14px;
        
    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
    font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
    font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer
        
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: map-get($generalStyles,pinkRed);
        margin-bottom:3px;
        margin-top:3px;
        color:white;
    font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }




}
  
  .xcsaveButton2{
    @include gadgets-to(mobile){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
    font-family: var(--font-family);
        font-size: 14px;
    }


    @include gadgets-to(tablet){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
    font-family: var(--font-family);
        font-size: 14px;
    }

    @include gadgets-to(laptop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
    font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;

    }

    @include gadgets-to(desktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
    font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }

    @include gadgets-to(largeDesktop){
        width:calc(15% - 3px);
        margin-left:3px;
        height:map-get($generalStyles,prefferedTabletListHeight);
        border-radius: map-get($generalStyles,sixBorderRadius);
        border: map-get($generalStyles,prefferedBorderStyle);
        border-color: map-get($generalStyles,prefferedBorderColor);
        background-color: white;
        margin-bottom:3px;
        margin-top:3px;
        color:black;
    font-family: var(--font-family);
        font-size: 14px;
        cursor:pointer;
        
    }
}

    
  .xcsaveButtonJAP1{
    @extend .xcsaveButton1;
    font-size: 12px;
  }



  .xcsaveButtonJAP2{
    @extend .xcsaveButton2;
    font-size:12px;
  }
  
  .xclistSpinnerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        background-color: transparent;
  }































