:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width:359px) and (max-width: 599px) {
  .avtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .avtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .avtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .avtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
}
@media only screen and (min-width:2303px) {
  .avtotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .avtotalArea {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "a" "b";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .avtotalArea {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "a" "b";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .avtotalArea {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "a" "b";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .avtotalArea {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "a" "b";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .avtotalArea {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "a" "b";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.avlogoutTop {
  grid-area: a;
  font-size: 16px;
  font-family: var(--font-family);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .avlogoutTop {
    font-size: 16px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .avlogoutTop {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .avlogoutTop {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .avlogoutTop {
    font-size: 24px;
  }
}

.avlogoutLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.avlogoutImage {
  grid-area: b;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 70%;
  height: auto;
  border-radius: 50%;
  border: 3px solid;
  border-color: rgb(223, 212, 212);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .avlogoutImage {
    width: 25%;
    height: auto;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .avlogoutImage {
    width: 20%;
    height: auto;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .avlogoutImage {
    width: 20%;
    height: auto;
  }
}
@media only screen and (min-width:2303px) {
  .avlogoutImage {
    width: 20%;
    height: auto;
  }
}

.avareaA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.avareaB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avareaC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
}

.avlogoutBottom {
  font-size: 16px;
  font-family: var(--font-family);
  text-decoration: underline;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .avlogoutBottom {
    font-size: 16px;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .avlogoutBottom {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .avlogoutBottom {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .avlogoutBottom {
    font-size: 16px;
  }
}

.avloginButton {
  height: 30px;
  width: 180px;
  border: none;
  border-radius: 6px;
  background-color: rgb(229, 64, 68);
  padding: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  cursor: pointer;
}/*# sourceMappingURL=Logout.css.map */