@import '../style/MainStyles.scss';




.infototWrapper{
@include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
     width:100%;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
     height:90vh;
     width:100%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
     width:100%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;

     width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
     width:100%;
}
}

.infototalArea {
    @include gadgets-to(mobile) {
        display: grid;
       grid-template-rows: 0.1% 99.5%;
        grid-template-areas:
            "a"
            "b";
        width: 100%;
        height:auto;
        object-fit: cover;



    }

        @include gadgets-to(tablet) {
            display: grid;
            grid-template-columns: 35% 64.5%; // Adjust as needed
            grid-template-rows: auto auto;
            grid-template-areas:
              "a b";
            column-gap: 5px;
            width:100%;
            height:auto;
            margin-top:5px;
            object-fit: cover;

     
          }
          @include gadgets-to(laptop) {
            display: grid;
            grid-template-columns:25% 75%;
            grid-template-rows: auto auto;
            grid-template-areas:
              "a b";
      
              column-gap:7px;
            width: 100%;
            height:auto;
            margin-top:5px;
            object-fit: cover;
        
        }
        @include gadgets-to(desktop) {
            display: grid;
            grid-template-columns:25% 75%;
            grid-template-rows: auto auto;
            grid-template-areas:
              "a b";
       
              column-gap:7px;
            width: 100%;
            height:auto;
            margin-top:5px;
            object-fit: cover;
        
        }
       
    
        @include gadgets-to(largeDesktop) {
            display: grid;
            grid-template-columns:25% 75%;
            grid-template-rows: auto auto;
            grid-template-areas:
              "a b";
         
              column-gap:7px;
            width: 100%;
            height:auto;
            margin-top:5px;
            object-fit: cover;
        
        }
}

.infoA{
  @include gadgets-to(mobile) {
  grid-area:a;
  height:1px;
  background-color:transparent;
  width:100%;
  border:none;

}
@include gadgets-to(tablet) {
  grid-area:a;
  height:90vh;
  background-color:rgb(234, 213, 186);
  width:100%;
}
@include gadgets-to(laptop) {
  grid-area:a;
  height:90vh;
  background-color:rgb(234, 213, 186);
  width:100%;
}
@include gadgets-to(desktop) {
  grid-area:a;
  height:90vh;
  background-color:rgb(234, 213, 186);
  width:100%;

}
@include gadgets-to(largeDesktop) {
  grid-area:a;
  height:90vh;
  background-color:rgb(234, 213, 186);
  width:100%;

}
}
.infoB{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
 width:100%;
 z-index:3;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
  //  align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
 width:98%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
 // align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
  width:98%;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area: b;
  height: 90vh;
  background-size: cover; // Cover the entire div
  background-position: center top; // Adjust position towards the top
  width:98%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
 // align-items:center;
 justify-content: flex-start;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
  width:98%;
}
}
.infoBJAP{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
 width:100%;
  }
  @include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
 width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
  width:100%;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area: b;
  height: 90vh;
  background-size: cover; // Cover the entire div
  background-position: center top; // Adjust position towards the top
  width:100%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
  width:100%;
}
}




.infoBEnglish{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
 width:100%;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
  width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
  width:100%;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area: b;
  height:90vh;
  background-size: cover; // Cover the entire div
  background-position: center top; // Adjust position towards the top
  width: 100%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
  width:100%;
}
}
.corporateBasBackgroundImage{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(laptop) {
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(desktop) {
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(largeDesktop) {
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
}

@mixin setCorporateTotalBackgroundImageStyles {
  grid-area: b;
  height: 90vh;
  background-size: cover; /* Cover the entire div */
  background-position: center; /* Center the background image */
  width: 100%;
}

.corporateTotalBackgroundImage {
  @include gadgets-to(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include setCorporateTotalBackgroundImageStyles;
  }
  @include gadgets-to(tablet) {
    @include setCorporateTotalBackgroundImageStyles;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include gadgets-to(laptop) {
    @include setCorporateTotalBackgroundImageStyles;
  }
  @include gadgets-to(desktop) {
    @include setCorporateTotalBackgroundImageStyles;
  }
  @include gadgets-to(largeDesktop) {
    @include setCorporateTotalBackgroundImageStyles;
  }
}

.faqBackgroundImage{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
}



.faqtransparentBox{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
    //justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  width:100%;
  height:100%;
 // margin-top:2vh;
 // border-radius:12px;
  z-index:2;
 // padding-top:10vh;
}
@include gadgets-to(tablet) {
display:flex;
flex-direction:column;
align-items:center;
justify-content: center;
background-color: rgba(255, 255, 255, 0.8);
width:74%;
height:72%;
margin-top:4vh;
border-radius:12px;
z-index:2;
@media(min-width:1020px) and (max-width:1024px){
  width:74%;
  height:85%;
  margin-top:4vh;
  border-radius:12px;
  z-index:2;

}
@media(min-width:1070px) and (max-width:1080px){
  width:74%;
  height:90%;
  margin-top:4vh;
  border-radius:12px;
  z-index:2;

}
@media(min-width:1180px){
  width:80%;
  height:85%;
  margin-top:3vh;
  border-radius:12px;
  z-index:2;

}
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.8);
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:74%;
  height:72%;
  margin-top:5vh;
  border-radius:12px;
  z-index:2;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.8);
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:74%;
  height:82%;
  margin-top:5vh;
  border-radius:12px;
  z-index:2;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.8);
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:74%;
  height:85%;
  margin-top:5vh;
  border-radius:12px;
  z-index:2;
}
}
.faqSurroundBox{
  border:1px solid map-get($generalStyles,datalistBorderColor);
  width:80%;
  height:52vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
  max-height:50vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }

  @include gadgets-to(mobile) {
    width:96%;
    height:80vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height:68vh;
    overflow-y:scroll;
    margin-top:2vh;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
  }
  @include gadgets-to(tablet) {
    width:80%;
    height:68vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height:68vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
    @media(min-width:1070px) and (max-width:1080px){
      height:72vh;
    
    }
    @media(min-width:1180px){
      height:80vh;
    
    }
  } 

  @include gadgets-to(desktop) {
    width:80%;
    height:60vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height:60vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }

  } 

  @include gadgets-to(largeDesktop) {
    width:85%;
    height:63vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: 10px 12px 15px 5px rgba(0, 0, 0, 0.5);
    max-height:68vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
  }

}

.transparentBox{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:90%;
  z-index:2;
  height:40%;
  margin-top:2vh;
 // border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.8);
  width:65%;
  height:40%;
  margin-top:4vh;
 // border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:55%;
  height:50%;
  margin-top:4vh;
 // border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:54%;
  height:60vh;
  margin-top:4vh;
  //border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:65%;
  height:60%;
  //border-radius:12px;
  margin-top:4vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
}
}
.transparentBoxSwedishTotal{
    @include gadgets-to(mobile) {
    background-color: rgba(255, 255, 255, 0.8);
    width:94%;
    height:82%;
    margin-top:1vh;
    z-index:2;
    display:flex;
  flex-direction:column;
  align-items:center;
  }
  @include gadgets-to(tablet) {
    background-color: rgba(255, 255, 255, 0.7);
  width:35%;
  margin-left:26%;
  margin-top:2vh;
  height:70vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;

}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:35%;
  margin-left:38%;
  margin-top:2vh;
  height:77vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.5);
  width:35%;
  margin-left:38%;
  margin-top:2vh;
  height:77vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.5);
  width:35%;
  margin-left:38%;
  margin-top:2vh;
  height:77vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
}
}
.transparentBoxCorporateBas{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:94%;
  height:82%;
  margin-top:1vh;
  z-index:2;
  display:flex;
  flex-direction: column;
  align-items:center;

}
@include gadgets-to(tablet) {
display:flex;
flex-direction: column;
align-items:center;
  background-color: rgba(255, 255, 255, 0.8);
  width:85%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  //position:absolute;
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:40%;
  margin-left:32%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.5);
  width:34%;
  margin-left:38%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.5);
  width:34%;
  margin-left:38%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
}
.transparentBoxCorporateTotal{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:94%;
  height:85%;
  margin-top:1vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;

}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.7);
  width:55%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:40%;
  margin-left:4%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:40%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:4%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
}
.transparentBoxCorporateTotalFeatures{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:94%;
  height:70vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:1vh;
}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.8);
  width:45%;
  height:75vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:4%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
 
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:4%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;

}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:4%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;

}
}


.transparentBoxTotalFeatures{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:94%;
  height:82%;
  margin-top:1vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;

}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.8);
  width:74%;
  height:68vh;
  margin-top:4vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;

}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:38%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;

}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:38%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:38%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;

}
}
.transparentBoxCorporateBasFeatures{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:94%;
  height:70%;
  margin-top:1vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;

}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.8);
  width:55%;
  height:70%;
  margin-top:2vh;
  z-index:2;
  position:absolute;
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:38%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
 
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:38%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
 
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:38%;
  height:70vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;

}
}
.transparentBoxBasList{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:94%;
  margin-top:2vh;
  height:79%;  
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.7);
  width:50%;
  margin-top:2vh;
  height:70%;  
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:45%;
  margin-left:2%;
  margin-top:2vh;
  height:70%;  
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:2%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
 
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:34%;
  margin-left:2%;
  height:78vh;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
 }
}

// .transparentBoxBasText{
//   @include gadgets-to(mobile) {
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: rgba(255, 255, 255, 0.8);
//   width:94%;
//   height:82%;
//  // border-radius:12px;
//   margin-top:1vh;
//   z-index:2;
// }
// @include gadgets-to(tablet) {
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: rgba(255, 255, 255, 0.7);
//   width:83%;
//   height:100%;
//   margin-top:2vh;
//  // border-radius:12px;
//   height:78vh;
//   z-index:2;

// }
// @include gadgets-to(laptop) {
//   background-color: rgba(255, 255, 255, 0.7);
//   width:50%;
//   margin-left:2%;
//   margin-top:2vh;
//   //border-radius:12px;
//   height:78%;  
//   z-index:2;
//   position:absolute;
//   display:flex;
//   flex-direction:column;
//   //justify-content:center;
//   align-items:center;
// }
// @include gadgets-to(desktop) {
//   background-color: rgba(255, 255, 255, 0.7);
//   width:44%;
//   height:78vh;
//   margin-top:2vh;
//   z-index:2;
//   position:absolute;
//   display:flex;
//   flex-direction:column;
//   //justify-content:center;
//   align-items:center;
//  // border-radius:10px;
// }
// @include gadgets-to(largeDesktop) {
//   background-color: rgba(255, 255, 255, 0.7);
//   width:44%;
//   margin-left:2%;
//   height:78vh;
//   margin-top:2vh;
//   z-index:2;
//   position:absolute;
//   display:flex;
//   flex-direction:column;
//   //justify-content:center;
//   align-items:center;
//  // border-radius:10px;
// }
// }


.transparentBoxPlus{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.9);
  width:94%;
  height:90%;
  margin-top:2vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  max-height:87vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
  border-radius: 12px;
  box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.9);
  width:55%;
  height:77%;
  margin-top:2vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-radius: 12px;
  box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
 @media (min-width:1170px) and (max-width:1200px) {
 height:75vh;
 

  }	
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.9);
  width:65%;
  height:82vh;
  margin-top:3vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-radius: 12px;
 }
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.9);
  width:54%;
  margin-left:2%;
  height:82vh;
  margin-top:3vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-radius: 12px;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.9);
  width:54%;
  margin-left:2%;
  height:82vh;
  margin-top:3vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-radius:12px;
}
}

.transparentBoxAIServices{
  @include gadgets-to(mobile) {
    background-color: rgba(255, 255, 255, 0.8);
    width:100%;
    height:100%;
    z-index:2;
    display:flex;
    flex-direction:column;
    align-items:center;   
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);

  }
  @include gadgets-to(tablet) {
    background-color: rgba(255, 255, 255, 0.8);
    width:74%;
    height:65vh;
    margin-top:9vh;
    border-radius:12px;
    z-index:2;
    display:flex;
    flex-direction:column;
    align-items:center;
    box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  

}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:74%;
  height:65vh;
  margin-top:10vh;
  border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:74%;
  height:65vh;
  margin-top:9vh;
  border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  border-radius:12px;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:70%;
  height:80%;
  margin-top:7vh;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-radius:12px;
  box-shadow: 16px 16px 15px 5px rgba(0, 0, 0, 0.5);
  
}
}
.transparentBoxBs{
  @include gadgets-to(mobile) {
    background-color: rgba(255, 255, 255, 0.8);
    width:87%;
    height:77%;
   // border-radius:12px;
    margin-top:1vh;
    z-index:2;
    position:absolute;
  }
  @include gadgets-to(tablet) {
    background-color: rgba(255, 255, 255, 0.8);
    width:65%;
    height:90%;
    z-index:2;
    display:flex;
    flex-direction:column;
    align-items:center;
   // border-radius:12px;
    margin-top:2vh;

}
@include gadgets-to(laptop) {
    background-color: rgba(255, 255, 255, 0.5);
    width:40%;
    height:78vh;
    margin-top:2vh;
    z-index:2;
    position:absolute;
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items:center;
  //  border-radius:10px;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.5);
  width:40%;
  height:78vh;
  margin-top:4vh;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
 // border-radius:10px;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:50%;
  height:80vh;
  margin-top:2vh;
 // border-radius:12px;
  z-index:2;
  position:absolute;
}
}

.aboutUsTransparentBox{
  @include gadgets-to(mobile) {
    background-color: rgba(255, 255, 255, 0.8);
    width:100%;
    height:100vh;
    z-index:2;
    display:flex;
    flex-direction:column;
    align-items:center;
    box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
  
}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.8);
  width:80%;
  height:90%;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  //justify-content: center;
  margin-top:5vh;
  border-radius: 12px;
  box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
@media(min-width:1170px) and (max-width:1200px){
height:80vh;
}
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:80%;
  height:90%;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  //justify-content: center;
  margin-top:2vh;
  border-radius: 12px;
  box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);

}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:80%;
  height:90%;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  //justify-content: center;
  margin-top:4vh;
  border-radius: 12px;
  box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);

}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:80%;
  height:90%;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
  //justify-content: center;
  margin-top:5vh;
  border-radius: 12px;
  box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);

}
}
.aboutUsSurroundBorder{

  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:white;
  width:80%;
  height:70vh;
  border-radius: 12px;
  box-shadow:12px 12px 15px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  @include gadgets-to(mobile) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    background-color:white;
    width:95%;
    height:80vh;
    border-radius: 12px;
    box-shadow:12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:0.7vh;
  }
  @include gadgets-to(tablet) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    background-color:white;
    width:80%;
    height:70vh;
    max-height:70vh;
    overflow-y:auto;
    //webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
    
    border-radius: 12px;
    box-shadow:12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  @include gadgets-to(laptop) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    background-color:white;
    width:80%;
    height:70vh;
    border-radius: 12px;
    box-shadow:12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include gadgets-to(desktop) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    background-color:white;
    width:80%;
    height:70vh;
    border-radius: 12px;
    box-shadow:12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  @include gadgets-to(largeDesktop) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    background-color:white;
    width:80%;
    height:70vh;
    border-radius: 12px;
    box-shadow:12px 12px 15px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }


}
.aboutUsChapter{
  height:8vh;
  width:97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include gadgets-to(mobile) {
    height:10vh;
    width:97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(min-width:400px){
      height:10vh;
    }
  }
  @include gadgets-to(tablet) {
    height:11vh;
    width:97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(min-width:1020px)and (max-width:1024px){
      height:15vh;
    }

    @media(min-width:1070px)and (max-width:1100px){
      height:14vh;
    }

  }
}
.aboutUsChapterJP{
height:8vh;
width:97%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
@include gadgets-to(mobile) {
  height:10vh;
  width:97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media(min-width:400px){
    height:11vh;
  }
}
@include gadgets-to(tablet) {
  height:11vh;
  width:97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media(min-width:1020px)and (max-width:1024px){
    height:15vh;
  }

  @media(min-width:1070px)and (max-width:1100px){
    height:14vh;
  }

}
}
.infoButtonTopWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  gap:1px;
  width:100%;

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top:10px;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top:5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top:5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top:5vh;
}
}

.infoButton{
  @include gadgets-to(mobile) {
flex:1;
 // width:auto;
  height:26px;
  border:none;
 // border-color:map-get($generalStyles,datalistBorderColor);
  background-color: rgb(223, 196, 167);
  border-radius:6px;
  font-family:Lobster;
  font-size:16px;
   white-space:nowrap;
  outline:none;
  color:black;
  @media(min-width:400px) and (max-width:413px){
    height:33px;
  }
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  width:60%;
  height:40px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  margin-top:5px;
  padding:10px;
  cursor:pointer;
  color:black;
  transition: transform 0.2s ease, background-color 0.2s ease; 
  &:active {
    transform: scale(1.1); // Slightly increase the size of the button
    background-color: rgb(243, 216, 187); // Lighten the background color on click
  }
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  padding:1vw;
  width:70%;
  height:40px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  user-select: none;
  margin-top:5px;
  cursor:pointer;
  color:black;
  transition: transform 0.2s ease, background-color 0.2s ease; 
  &:active {
    transform: scale(1.1); // Slightly increase the size of the button
    background-color: rgb(243, 216, 187); // Lighten the background color on click
  }
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  padding:1vw;
  width:65%;
  height:40px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  user-select: none;
  margin-top:5px;
  cursor:pointer;
  color:black;
  transition: transform 0.2s ease, background-color 0.2s ease; 
  &:active {
    transform: scale(1.1); // Slightly increase the size of the button
    background-color: rgb(243, 216, 187); // Lighten the background color on click
  }
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  padding:1vw;

  width:60%;
  height:40px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  user-select: none;
  margin-top:5px;
  cursor:pointer;
  color:black;
  transition: transform 0.2s ease, background-color 0.2s ease; 
  &:active {
    transform: scale(1.1); // Slightly increase the size of the button
    background-color: rgb(243, 216, 187); // Lighten the background color on click
  }
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }
}
}
.activeButton{
  background-color:rgb(77, 43, 29);
  color:white;
}

.infoButtonJAP{
  @include gadgets-to(mobile) {
  flex:1;
  width:auto;
  height:25px;
  //height:auto;
  border:none;
 // border-color:map-get($generalStyles,datalistBorderColor);
  background-color: rgb(223, 196, 167);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
 white-space:nowrap;
  outline:none;
  color:black;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  padding:1vw;
  width:55%;
  height:30px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  margin-top:5px;
  cursor:pointer;
  color:black;
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  padding:1vw;
  width:55%;
  height:30px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  margin-top:5px;
  cursor:pointer;
  color:black;
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  padding:1vw;
  width:55%;
  height:40px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  margin-top:5px;
  cursor:pointer;
  color:black;
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  padding:1vw;
  width:55%;
  height:40px;
  border:none;
  // border-color:map-get($generalStyles,datalistBorderColor);
  background-color:rgb(212, 177, 145);
  border-radius:6px;
  font-family:var(--font-family);
  font-size:14px;
  white-space:nowrap;
  outline:none;
  margin-top:10px;
  cursor:pointer;
  color:black;
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
  }
}
}
.infoStartWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  //align-items:center;
  width:100%;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  //align-items:center;
  width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  //align-items:center;
  width:100%;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  //align-items:center;
  width:100%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  //align-items:center;
  width:100%;
}
}
.infoStartHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:28px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:28px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:30px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:30px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:40px;
  color:black;
  margin-top:0.5vh;
}
}

.infoStartHeaderJAP{
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:28px;
  color:black;
  margin-top:0.5vh;

}
.infoStartText{
  @include gadgets-to(mobile) {
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  margin-top:1vh;
 // text-align:center;
margin-left:5%;
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:1vh;
 // text-align:center;
margin-left:5%;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1vh;
 // text-align:center;
 margin-left:5%;

}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
  font-size:22px;
  color:black;
  margin-top:1vh;
 // text-align:center;
 margin-left:5%;

}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
  font-size:22px;
  color:black;
  margin-top:1vh;
 // text-align:center;
 margin-left:5%;

}
}

.infoStartTextSwedish{
  @include gadgets-to(mobile) {
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  margin-top:1vh;
 // text-align:center;
margin-left:5%;
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1vh;
 // text-align:center;
margin-left:5%;

}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  margin-top:1vh;
 // text-align:center;
 margin-left:5%;

}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1vh;
 // text-align:center;
 margin-left:5%;

}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:1vh;
 // text-align:center;
 margin-left:5%;

}
}

.infoStartTextJapanese{
  @include gadgets-to(mobile) {
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  //margin-top:1vh;
 // text-align:center;
margin-left:5%;
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  //margin-top:1vh;
 // text-align:center;
margin-left:5%;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
  font-size:15px;
  color:black;
  //margin-top:1vh;
 // text-align:center;
 margin-left:5%;
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  //margin-top:1vh;
 // text-align:center;
 margin-left:5%;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
  font-size:17px;
  color:black;
  //margin-top:1vh;
 // text-align:center;
 margin-left:5%;
}
}
.infoSignUpWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;

}
}

.infoSignUpHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:20px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:28px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:30px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:30px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:Lobster;
  font-size:40px;
  color:black;
  margin-top:0.5vh;
}
}

.infoSignUpHeaderJAP{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:24px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:24px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:24px;
  color:black;
  margin-top:0.5vh;
}
}


.infoSignUpText{
  @include gadgets-to(mobile) {
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
}


.infoSignUpArea{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:70%;
  margin-top:1vh;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:60%;
  margin-top:1vh;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:50%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:70%;
}
}
.signupinput{
  @include gadgets-to(mobile) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:3px;
  margin-top:1vh;
  outline:none;
}
@include gadgets-to(tablet) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:3px;
  margin-top:1vh;
  outline:none;
}
@include gadgets-to(laptop) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:3px;
  margin-top:1vh;
  outline:none;
}
@include gadgets-to(desktop) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:3px;
  margin-top:1vh;
  outline:none;

}
@include gadgets-to(largeDesktop) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:3px;
  margin-top:1vh;
  outline:none;

}
}

.signupmandatoryText{
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
.infoservicesArea{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
}
.infoselect{
  @include gadgets-to(mobile) {
  width:61%;
  height:30px;
  border:none;
  border-radius:3px;
  margin-top:1vh;
  outline:none;
  background-color: white;
  color:black;
}
@include gadgets-to(tablet) {
  width:61%;
  height:25px;
  border:none;
  border-radius:3px;
  margin-top:1vh;
  outline:none;
  background-color:white;
  color:black;
}
@include gadgets-to(laptop) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:6px;
  margin-top:1vh;
  outline:none;

}
@include gadgets-to(desktop) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:6px;
  margin-top:1vh;
  outline:none;

}
@include gadgets-to(largeDesktop) {
  width:60%;
  height:3vh;
  border:none;
  border-radius:6px;
  margin-top:1vh;
  outline:none;

}
}


.infosignupButton{
  width:90%;
  height:4vh;
  border:none;
  border-radius:6px;
  margin-top:2vh;
  outline:none;
  background-color:rgb(197, 161, 127);
  color:white;

  // hover
  &:hover{
    background-color:rgb(77, 43, 29);
    color:white;
    cursor:pointer;
  }
  &:active{
    background-color:green;
    color:white
  }
}
.infoBsServicesWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
 // justify-content:center;
  align-items:center;
  width:100%;
  background-color: rgb(223, 196, 167);
  height:85vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  background-color: rgb(223, 196, 167);
  height:87vh;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  background-color: rgb(223, 196, 167);
  height:87vh;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  background-color: rgb(223, 196, 167);
  height:87vh;
  

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  background-color: rgb(223, 196, 167);
  height:87vh;

}
}
.infoBsTopArea{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  height:70vh;
  max-height:70vh;
  overflow-y:scroll;
  //webkit -scrollbar
  &::-webkit-scrollbar {
    display:none;
  }

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
 // justify-content:center;
  align-items:center;
  width:70%;
  height:90%;
  max-height:90%;
  overflow-y:scroll;
  //webkit -scrollbar
  &::-webkit-scrollbar {
    display:none;
  }

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:70%;
  margin-top:2vh;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:60%;
  margin-top:2vh;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  margin-top:5vh;

}
}


.infoBsServicesHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:17px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:3.5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:30px;
  color:black;
  margin-top:0.5vh;
}
}

.infoBsServicesHeaderJAP{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:3.5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:0.5vh;
}
}
.infoBsServicesText{
  @include gadgets-to(mobile) {
  font-family:var(--font-family);
  font-size:13px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
  font-size:13px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
  font-size:13px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1vh;
  text-align:center;
}
}

.infoBsServicesBaseArea{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;

}
}
.infoBsServicesBaseHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:0.5vh;

}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:17px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:0.5vh;
}
}

.infoBsServicesBaseHeaderJAP{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:17px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);

}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:17px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:17px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:26px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:26px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
}

.infoBsServicesBaseHeaderPrice{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:17px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:0.5vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-family:var(--font-family);
  font-size:26px;
  color:black;
  margin-top:0.5vh;
}
}

.infoBsServicesBaseHeaderPriceJAP{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:17px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:17px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:17px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:22px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:22px;
  color:black;
  margin-top:0.5vh;
  font-family: var(--font-family);
}
}


.infoBsServicesBaseText{
  @include gadgets-to(mobile) {
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  margin-top:1vh;
  text-align:center;
  width:90%
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  margin-top:1vh;
  text-align:center;
  width:90%
}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  margin-top:1vh;
  text-align:center;
  width:90%
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1vh;
  text-align:center;
  width:90%
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1vh;
  text-align:center;
  width:90%
}
}
.infoBsServicesRows{
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
}

.baseServicesTop{
  @include gadgets-to(mobile) {
      width:100%;
  }
  @include gadgets-to(tablet) {
      width:100%;
      height:80vh;
  }
  @include gadgets-to(laptop) {
      width:100%;
      height:90vh;
  
  }
  @include gadgets-to(desktop) {
      width:100%;
      height:90vh;
  
  }
  @include gadgets-to(largeDesktop) {
      width:100%;
      height:90vh;

  }
}

.baseServicesTabTop{
  @include gadgets-to(mobile) {
  grid-area: b;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:4vh;
  width:100%;
 // border:1px solid red;
  font-family: map-get($generalStyles,fontFamily);
  font-size:12px;
 // background-color: map-get($generalStyles,transparentChangeableMenu4);
   //background:white;

}
@include gadgets-to(tablet) {
  grid-area: b;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:4vh;
  width:100%;
  //border:1px solid red;
  font-family: map-get($generalStyles,fontFamily);
  font-size:12px;
 // background-color: map-get($generalStyles,transparentChangeableMenu4);
   background:rgb(234, 213, 186);
}
@include gadgets-to(laptop) {
  grid-area: b;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:3.8vh;
  width:100%;
  font-family: map-get($generalStyles,fontFamily);
  font-size:12px;
 background:rgb(162, 139, 109);
}
@include gadgets-to(desktop) {
  grid-area: b;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:3.8vh;
  width:100%;
  font-family: map-get($generalStyles,fontFamily);
  font-size:12px;
  background:rgb(162, 139, 109);
}
@include gadgets-to(largeDesktop) {
  grid-area: b;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:3.8vh;
  width:100%;
  font-family: map-get($generalStyles,fontFamily);
  font-size:12px;
  background:rgb(162, 139, 109);
}
}

.baseServicesTab{
@include gadgets-to(mobile) {

display:flex;
flex-direction: row;
justify-content:center;
align-items: center;
height:4vh;
width:100%;
border:none;
cursor:pointer;
border-top-right-radius: 4px;
border-top-left-radius: 4px;
font-family: map-get($generalStyles,fontFamily);
font-size:13px;
background-color: map-get($generalStyles,transparentChangeableMenu4);
&.active{
  background-color: map-get($generalStyles,transparentChangeableMenu5);
  border:1px solid map-get($generalStyles,colorwhite);
color:white;
border-top-right-radius: 12px;
border-top-left-radius: 12px;
cursor:pointer;


}
}

@include gadgets-to(tablet) {
display:flex;
flex-direction: row;
justify-content:center;
align-items: center;
height:3vh;
width:100%;
border:none;
cursor:pointer;
font-family: map-get($generalStyles,fontFamily);
font-size:13px;
background-color: map-get($generalStyles,transparentChangeableMenu4);
&.active{
  background-color: map-get($generalStyles,transparentChangeableMenu5);
  border:1px solid map-get($generalStyles,colorwhite);
color:white;
border-top-right-radius: 12px;
border-top-left-radius: 12px;
cursor:pointer;
}
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:3vh;
  width:100%;
  border:none;
  cursor:pointer;
  font-family: map-get($generalStyles,fontFamily);
  font-size:13px;
  background-color: map-get($generalStyles,transparentChangeableMenu4);
  &.active{
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border:1px solid map-get($generalStyles,colorwhite);
  color:white;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  cursor:pointer;
}
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:3vh;
  width:100%;
  border:none;
  cursor:pointer;
  font-family: map-get($generalStyles,fontFamily);
  font-size:13px;
  background-color: map-get($generalStyles,transparentChangeableMenu4);
  &.active{
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border:1px solid map-get($generalStyles,colorwhite);
  color:white;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  cursor:pointer;
}
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:3vh;
  width:100%;
  border:none;
  cursor:pointer;
  font-family: map-get($generalStyles,fontFamily);
  font-size:13px;
  background-color: map-get($generalStyles,transparentChangeableMenu4);
  &.active{
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border:1px solid map-get($generalStyles,colorwhite);
  color:white;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  cursor:pointer;
}
}
}
.baseServicesfirstButton {
  border-top-left-radius:map-get($generalStyles,sixBorderRadius);
 // border-top-right-radius: 12px;
 //border-radius:12px;


}
.baseServiceslastButton {
  border-top-right-radius:map-get($generalStyles,sixBorderRadius);
//   border-top-right-radius: 12px;


}

.baseServicesmobileTab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4vh;
  width: 100%;
  border: 1px solid rgb(240, 232, 232);
  cursor: pointer;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  font-family: map-get($generalStyles, fontFamily);
  font-size: 13px;
  background-color: rgb(197, 161, 127);
  white-space: nowrap;
  margin-right: -10px; /* Added to make tabs overlap horizontally */
  position: relative;
  z-index: 1; /* Base z-index for stacking */

  &.active {
    border: 1px solid map-get($generalStyles, colorwhite);
    color: white;
   // background-color: rgb(150, 100, 80); /* Optional: Add background for active tab */
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    z-index: 2; /* Ensure the active tab is above others */
    margin-right: 0; /* Adjust margin for the active tab */
  }


@include gadgets-to(tablet) {
  flex:1;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:4vh;
  margin-left:1px;
 // width:99%;
  border:none;
  cursor:pointer;
  font-family: map-get($generalStyles,fontFamily);
  font-size:13px;
  background-color:map-get($generalStyles,infoBeige);
  margin-right: -20px; /* Added to make tabs overlap horizontally */

  border:1px solid;
  border-color:map-get($generalStyles,datalistBorderColor);
  color:black;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  &.active{
      background-color:map-get($generalStyles,infoBeige);
      border:1px solid map-get($generalStyles,colorwhite);
  color:white;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  margin-right:-20px;
  border-color:map-get($generalStyles,datalistBorderColor);
  cursor:pointer;

  }
}
@include gadgets-to(laptop) {
  flex:1;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:4vh;
  margin-left:2px;
 // width:99%;
  border:none;
  cursor:pointer;
  font-family: map-get($generalStyles,fontFamily);
  font-size:18px;
  background-color:rgb(218, 200, 172); 
  margin-right: -20px;
  border:1px solid;
  border-color:map-get($generalStyles,datalistBorderColor);
  color:black;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-top:5px;
  &.active{
     // background-color:map-get($generalStyles,infoBeige);
     border:1px solid map-get($generalStyles,colorwhite);
  color:white;
  border-top-right-radius:30px;
  border-top-left-radius:30px;
  margin-right: -20px;
  height:4vh;
  border-color:map-get($generalStyles,datalistBorderColor);
  cursor:pointer;

  }
}
@include gadgets-to(desktop) {
  flex:1;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:4vh;
  margin-left:2px;
 // width:99%;
  border:none;
  cursor:pointer;
  font-family: map-get($generalStyles,fontFamily);
  font-size:18px;
  background-color:rgb(218, 200, 172); 
  margin-right: -20px;
  border:1px solid;
  border-color:map-get($generalStyles,datalistBorderColor);
  color:black;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-top:5px;
  &.active{
     // background-color:map-get($generalStyles,infoBeige);
     border:1px solid map-get($generalStyles,colorwhite);
  color:white;
  border-top-right-radius:30px;
  border-top-left-radius:30px;
  margin-right: -20px;
  height:4vh;
  border-color:map-get($generalStyles,datalistBorderColor);
  cursor:pointer;

  }
}
@include gadgets-to(largeDesktop) {
  flex:1;
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  height:4vh;
  margin-left:2px;
 // width:99%;
  border:none;
  cursor:pointer;
  font-family: map-get($generalStyles,fontFamily);
  font-size:22px;
  background-color:rgb(218, 200, 172); 
  border:1px solid;
  border-color:map-get($generalStyles,datalistBorderColor);
  color:black;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-top:8px;
  &.active{
     // background-color:map-get($generalStyles,infoBeige);
     border:1px solid map-get($generalStyles,colorwhite);
  color:white;
  border-top-right-radius:30px;
  border-top-left-radius:30px;
  height:3.5vh;
  border-color:map-get($generalStyles,datalistBorderColor);
  cursor:pointer;

  }
}

}

// .baseServicesTabContent{
//   @include gadgets-to(mobile) {
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   height:85vh;
//   max-height:85vh;
//  // border:1px solid map-get($generalStyles,datalistBorderColor);
//   font-family: map-get($generalStyles,fontFamily);
//   font-size: 15px;
//   //background-color: map-get($generalStyles,transparent);
//   //background-color: white;
// //  border:1px solid map-get($generalStyles,datalistBorderColor);
// //  margin-top:1px;
//   overflow-y:scroll;

//   // small webkit scroll
//   &::-webkit-scrollbar {
//       width: 1px;
//   }
//   &::-webkit-scrollbar-track {
//       background: map-get($generalStyles,transparentChangeableMenu4);
//   }
//   &::-webkit-scrollbar-thumb {
//       background: white;
//    //   background: map-get($generalStyles,transparentChangeableMenu5);
//       border-radius: 10px;
//       border:1px solid;
//       border-color: map-get($generalStyles,transparentChangeableMenu5);
//   }
// }
// @include gadgets-to(tablet) {
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   width:100%;
//   height:86vh;
//   max-height:86vh;
//   font-family: map-get($generalStyles,fontFamily);
//   font-size: 15px;
//   background-color: map-get($generalStyles,transparent);
//  overflow-y:auto;

//   // small webkit scroll
//   &::-webkit-scrollbar {
//       width: 1px;
//   }
//   &::-webkit-scrollbar-track {
//       background: map-get($generalStyles,transparentChangeableMenu4);
//   }
//   &::-webkit-scrollbar-thumb {
//       background: white;
//    //   background: map-get($generalStyles,transparentChangeableMenu5);
//       border-radius: 10px;
//       border:1px solid;
//       border-color: map-get($generalStyles,transparentChangeableMenu5);
//   }
// }
// @include gadgets-to(laptop) {
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   width:100%;
//   height:86.1vh;
//   max-height:86.1vh;
//   font-family: map-get($generalStyles,fontFamily);
//   font-size: 15px;
//   background-color: map-get($generalStyles,transparent);
//  overflow-y:auto;

//   // small webkit scroll
//   &::-webkit-scrollbar {
//       width: 1px;
//   }
//   &::-webkit-scrollbar-track {
//       background: map-get($generalStyles,transparentChangeableMenu4);
//   }
//   &::-webkit-scrollbar-thumb {
//       background: white;
//    //   background: map-get($generalStyles,transparentChangeableMenu5);
//       border-radius: 10px;
//       border:1px solid;
//       border-color: map-get($generalStyles,transparentChangeableMenu5);
//   }
// }
// @include gadgets-to(desktop) {
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   width:100%;
//   height:86.1vh;
//   max-height:86.1vh;
//   font-family: map-get($generalStyles,fontFamily);
//   font-size: 15px;
//   background-color: map-get($generalStyles,transparent);
//  overflow-y:auto;

//   // small webkit scroll
//   &::-webkit-scrollbar {
//       width: 1px;
//   }
//   &::-webkit-scrollbar-track {
//       background: map-get($generalStyles,transparentChangeableMenu4);
//   }
//   &::-webkit-scrollbar-thumb {
//       background: white;
//    //   background: map-get($generalStyles,transparentChangeableMenu5);
//       border-radius: 10px;
//       border:1px solid;
//       border-color: map-get($generalStyles,transparentChangeableMenu5);
//   }
// }
// @include gadgets-to(largeDesktop) {
//   display:flex;
//   flex-direction: column;
//   align-items: center;
//   width:100%;
//   height:86.1vh;
//   max-height:86.1vh;
//   font-family: map-get($generalStyles,fontFamily);
//   font-size: 15px;
//   background-color: map-get($generalStyles,transparent);
//  overflow-y:auto;

//   // small webkit scroll
//   &::-webkit-scrollbar {
//       width: 1px;
//   }
//   &::-webkit-scrollbar-track {
//       background: map-get($generalStyles,transparentChangeableMenu4);
//   }
//   &::-webkit-scrollbar-thumb {
//       background: white;
//    //   background: map-get($generalStyles,transparentChangeableMenu5);
//       border-radius: 10px;
//       border:1px solid;
//       border-color: map-get($generalStyles,transparentChangeableMenu5);
//   }

// }
// }

.corporateBasServiceCommentText{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  height:71vh;
  max-height:71vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 14px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
      display:none;
  }


}
@include gadgets-to(tablet) {
  text-align: center;
  width:90%;
  height:70vh;
  max-height:80vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 15px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:scroll;

  // small webkit scroll
  &::-webkit-scrollbar {
      display: none;
  }

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  height:75vh;
  max-height:90vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 15px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
      display:none;
  }
  &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
  }
  &::-webkit-scrollbar-thumb {
      background: white;
   //   background: map-get($generalStyles,transparentChangeableMenu5);
      border-radius: 10px;
      border:1px solid;
      border-color: map-get($generalStyles,transparentChangeableMenu5);
  }
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  margin-left:2%;
  height:75vh;
  max-height:80vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 20px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
      display:none;
  }
  &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
  }
  &::-webkit-scrollbar-thumb {
      background: white;
   //   background: map-get($generalStyles,transparentChangeableMenu5);
      border-radius: 10px;
      border:1px solid;
      border-color: map-get($generalStyles,transparentChangeableMenu5);
  }
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  margin-left:2%;
  height:75vh;
  max-height:80vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 22px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
      display:none;
  }
  &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
  }
  &::-webkit-scrollbar-thumb {
      background: white;
   //   background: map-get($generalStyles,transparentChangeableMenu5);
      border-radius: 10px;
      border:1px solid;
      border-color: map-get($generalStyles,transparentChangeableMenu5);
  }
}
}

.corporateTotalServicesCommentText{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:92%;
  height:70vh;
  max-height:70vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 15px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }



}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  height:70vh;
  max-height:70vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 15px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:scroll;

  // small webkit scroll
  &::-webkit-scrollbar {
      display:none;
  }

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  height:90vh;
  max-height:90vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 15px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
      display:none;
  }
  &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
  }
  &::-webkit-scrollbar-thumb {
      background: white;
   //   background: map-get($generalStyles,transparentChangeableMenu5);
      border-radius: 10px;
      border:1px solid;
      border-color: map-get($generalStyles,transparentChangeableMenu5);
  }
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  margin-left:2%;
  height:80vh;
  max-height:80vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 20px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
     display:none;
  }
  &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
  }
  &::-webkit-scrollbar-thumb {
      background: white;
   //   background: map-get($generalStyles,transparentChangeableMenu5);
      border-radius: 10px;
      border:1px solid;
      border-color: map-get($generalStyles,transparentChangeableMenu5);
  }
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  margin-left:2%;
  height:80vh;
  max-height:80vh;
  font-family: map-get($generalStyles,fontFamily);
  font-size: 22px;
  background-color: map-get($generalStyles,transparent);
 overflow-y:auto;

  // small webkit scroll
  &::-webkit-scrollbar {
     display:none;
  }
  &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
  }
  &::-webkit-scrollbar-thumb {
      background: white;
   //   background: map-get($generalStyles,transparentChangeableMenu5);
      border-radius: 10px;
      border:1px solid;
      border-color: map-get($generalStyles,transparentChangeableMenu5);
  }
}
}
.aboutUsServicesCommentText{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    width:100%;
    //border:1px solid map-get($generalStyles,datalistBorderColor);
    padding:10px;
    font-size:10px;
    font-family: var(--font-family);

    @media (min-width: 400px) {
      font-size:12px;
    }
    


}
@include gadgets-to(tablet) {
display:flex;
flex-direction: column;
align-items:flex-start;
justify-content: flex-start;
width:100%;
//border:1px solid map-get($generalStyles,datalistBorderColor);
padding:10px;
font-size:13px;
font-family: var(--font-family);

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: flex-start;
  width:100%;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  padding:10px;
  font-size:15px;
  font-family: var(--font-family);

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: flex-start;
  width:100%;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  padding:10px;
  font-size:18px;
  font-family: var(--font-family);

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: flex-start;
  width:100%;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  padding:10px;
  font-size:20px;
  font-family: var(--font-family);

}
}

.basicServicesWrapper{
  @include gadgets-to(mobile) {
    width:100%;
    background-color: rgb(223, 196, 167);
    height:85vh;
    max-height:85vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      width: 1px;
    }
 
  }
  @include gadgets-to(tablet) {
      width:100%;
      background-color: rgb(223, 196, 167);
      height:90vh;
      max-height:90vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
      display:none;
      }
   
  }
  @include gadgets-to(laptop) {
      width:100%;
     background-color: rgb(223, 196, 167);
    height:90vh;
      max-height:90vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        width: 1px;
      }
   
  }
  @include gadgets-to(desktop) {
      width:100%;
      background-color: rgb(223, 196, 167);
      height:90vh;
      max-height:90vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        width: 1px;
      }
   
  }
  @include gadgets-to(largeDesktop) {
    //  display:flex;
     // flex-direction:column;
   //  justify-content:center;
     // align-items:center;
      width:100%;
      background-color: rgb(223, 196, 167);
      height:90vh;
      max-height:90vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        width: 1px;
      }
   
  }
}

.basicServicesHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;

}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:4vh;
  //font-style:italic;

}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  padding-top:4vh;
  font-style:italic;
  width:100%;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  //margin-top:2vh;
  padding-top:4vh;
  font-style:italic;
  width:100%;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:24px;
  color:black;
  margin-top:4vh;
  font-style:italic;
}
}


.plusServicesHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:4.5vh;
 // font-style:italic;
  width:80%;

}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:17px;
  font-weight:100;
  color:black;  
  margin-top:4vh;

 // font-style:italic;


}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  margin-top:4.5vh;
  //font-style:italic;

}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:4.5vh;
  //font-style:italic;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:24px;
  color:black;
  margin-top:4.5vh;
  //font-style:italic;

}
}

.aiServicesSurround{

  @include gadgets-to(mobile) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: 12px;
    width:80%;
    height:40vh;
   padding:10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
  
  }
  @include gadgets-to(tablet) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: 12px;
    width:80%;
    height:40vh;
    padding:10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
  }
  @include gadgets-to(laptop) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: 12px;
    width:80%;
    height:40vh;
    padding:10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
  }
  @include gadgets-to(desktop) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: 12px;
    width:80%;
    height:40vh;
    padding:10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
  }
  @include gadgets-to(largeDesktop) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: 12px;
    width:80%;
    height:50vh;
    padding:10px;
    box-shadow: 10px 10px 5px rgba(124, 113, 113, 0.6);
    background-color: white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
  }

}

.aiServicesText{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
  //margin-top:10px;
  font-size:16px;
  font-family: var(--font-family);
  font-weight:500;
  width:98%;
   padding-left:12vw;
   margin-top:15px;

   @media (min-width: 400px) {
    font-size:14px;
    padding-left:20vw;
    margin-top:3vh;
    width:90%;
    
}
}
  @include gadgets-to(tablet) {
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
 // margin-top:10px;
  font-size:18px;
  font-family: var(--font-family);
  font-weight:500;
  width:70%;
   padding-left:5vw;
   margin-top:15px;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
  //margin-top:10px;
  font-size:20px;
  font-family: var(--font-family);
  font-weight:500;
  width:58%;
  padding-left:9vw;
  margin-top:15px;


}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
  margin-top:10px;
  font-size:22px;
  font-family: var(--font-family);
  font-weight:500;
  width:58%;
  padding-left:12vw;
  margin-top:15px;


}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
  margin-top:10px;
  font-size:24px;
  font-family: var(--font-family);
  font-weight:500;
  width:58%;
  padding-left:5vw;
  margin-top:15px;


}
}

.totalServicesHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;

}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  margin-top:4vh;
  font-style:italic;

}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:4vh;
  font-style:italic;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:24px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;
  width:60%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;
}
}

.basicServicesPlusHeader{
  @include gadgets-to(mobile) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
   margin-top:2vh;
 // font-style:italic;
  height:35px;
}
@include gadgets-to(tablet) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  //font-style:italic;
  margin-top:1vh;

}
@include gadgets-to(laptop) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  margin-top:1.5vh;
 // font-style:italic;
}
@include gadgets-to(desktop) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1.5vh;
  //font-style:italic;
}
@include gadgets-to(largeDesktop) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1.5vh;
  //font-style:italic;
}
}
.basicServicesPlusHeaderMoreInfo{
  @include gadgets-to(mobile) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:0.5vh;
  font-style:italic;
}
@include gadgets-to(tablet) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  font-style:italic;
  margin-top:0.5;
  height:20px;

}
@include gadgets-to(laptop) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:1vh;
  font-style:italic;
}
@include gadgets-to(desktop) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1vh;
  font-style:italic;
}
@include gadgets-to(largeDesktop) {
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1vh;
  font-style:italic;
}
}

.basicServicesPlusHeaderJAP{
  width:96%;
   text-align: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  font-style:italic;
  margin-top:2vh;

}
.basicServicesHeaderJAPTEXT{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;

}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;

}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;
}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  font-family:var(--font-family);
  font-size:20px;
  color:black;
  margin-top:1.5vh;
  font-style:italic;
}
}

.basicServicesText{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    align-items: center;
    font-family:var(--font-family);
    font-size:14px;
    color:black;
    text-align:left;
    width:98%;
    zoom:0.8;
    height:auto;
    max-height:70vh;
    overflow-y:auto;
    // small webkit scroll
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
        background:lightblue;
    }
    &::-webkit-scrollbar-thumb {
        background:grey;
        border-radius: 10px;
        border:1px solid;
        border-color:white;

    }

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction: column;
  align-items: center;
  font-family:var(--font-family);
  font-size:14px;
 // margin-top:1vh;
  text-align:left;
  width:90%;
  border-radius: 8px;
  padding-bottom:2vh;
  padding-top:2vh;
  margin-left:5px;
  border-color:map-get($generalStyles,datalistBorderColor);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
  cursor:pointer;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color: rgba(255,255,255,0.8)
}

@include gadgets-to(laptop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
 // margin-top:1vh;
  text-align:left;
  width:80%;
  background-color: rgba(255,255,255,0.8);
  border-radius: 8px;
  padding-bottom:2vh;
  padding-top:2vh;
  margin-left:1vw;
  border-color:map-get($generalStyles,datalistBorderColor);
   box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
  cursor:pointer;
  border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
 // margin-top:1vh;
  text-align:left;
  width:70%;
  background-color: rgba(255,255,255,0.8);
  border-radius: 8px;
  padding-bottom:2vh;
  padding-top:2vh;
  margin-left:1vw;
  border-color:map-get($generalStyles,datalistBorderColor);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
  cursor:pointer;
  border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: column;
  align-items: center;
  font-family:var(--font-family);
  font-size:14px;
  color:black;
 // margin-top:1vh;
  text-align:left;
  width:60%;
  background-color: rgba(255,255,255,0.8);
  border-radius: 8px;
  padding-bottom:2vh;
  padding-top:2vh;
  margin-left:1vw;
  border-color:map-get($generalStyles,datalistBorderColor);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.6);
  cursor:pointer;
  border:1px solid map-get($generalStyles,datalistBorderColor);
}
}

.basicServicesListWrapper{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:95%;

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:95%;


}
}


.basicServicesListText{
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width:95%;
  font-size:16px;
  font-family:var(--font-family);
  margin-top:2vh;

}
.basicServicesList{
  @include gadgets-to(mobile) {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size:12px;
}
@include gadgets-to(tablet) {
  text-align: left;
  margin-left:10%;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:14px;

}
@include gadgets-to(laptop) {
  text-align: left;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:14px;
}
@include gadgets-to(desktop) {
  text-align: left;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:18px;
}
@include gadgets-to(largeDesktop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:15%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:22px;
}
}
.basicServicesListMid{
  @include gadgets-to(mobile) {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size:14px;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}
@include gadgets-to(tablet) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:14px;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;

}
@include gadgets-to(laptop) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:20px;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}
@include gadgets-to(desktop) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:22px;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}
@include gadgets-to(largeDesktop) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:20px;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}
}
.plusServicesListMid{
  @include gadgets-to(mobile) {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left:25%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size:14px;
}
@include gadgets-to(tablet) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:32%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:14px;

}
@include gadgets-to(laptop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:38%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:14px;
}
@include gadgets-to(desktop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:42%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:22px;
}
@include gadgets-to(largeDesktop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:32%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:20px;
}
}

.aiservicesListMid{
  @include gadgets-to(mobile) {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    font-family: var(--font-family);
    font-size:14px;
}
@include gadgets-to(tablet) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:14px;

}
@include gadgets-to(laptop) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:14px;
}
@include gadgets-to(desktop) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:18px;

}
@include gadgets-to(largeDesktop) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:20px;
}
}




.basicServicesListJAP{
  @include gadgets-to(mobile) {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left:5%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size:13px;
    color:black;
}
@include gadgets-to(tablet) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:13px;
  color:black;

}
@include gadgets-to(laptop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:30%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:13px;
  color:black;
}
@include gadgets-to(desktop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:10%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:18px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:15%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:22px;
  color:black;
}
}
.totalServicesListJAP{
  @include gadgets-to(mobile) {
    text-align: left;
    /* Reset any margin or padding that might be centering it */
    margin-left:5%;
    padding: 0;
    /* Ensure the list starts at the left edge of its container */
    list-style-position: inside;
    font-family: var(--font-family);
    font-size:13px;
    color:black;
}
@include gadgets-to(tablet) {
  text-align: left;
  padding: 0;
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:13px;
  color:black;

}
@include gadgets-to(laptop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:18%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:15px;
  color:black;
}
@include gadgets-to(desktop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:25%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:18px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  text-align: left;
  /* Reset any margin or padding that might be centering it */
  margin-left:20%;
  padding: 0;
  /* Ensure the list starts at the left edge of its container */
  list-style-position: inside;
  font-family: var(--font-family);
  font-size:22px;
  color:black;
}
}

.basicServicesCommentText{
  @include gadgets-to(mobile) {
  font-family:var(--font-family);
  font-size:13px;
  color:black;
  margin-top:1vh;
  text-align:left;
  width:90%;
  padding-right:5px;
  padding-left:5px;
  max-height:75vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
}
@include gadgets-to(tablet) {
  font-family:var(--font-family);
  font-size:14px;
  color:black;
  margin-top:1vh;
  text-align:center;
  width:90%;
  //margin-left:2%;
  padding-right:5px; 
  padding-left:5px;
  max-height:75vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }

}
@include gadgets-to(laptop) {
  font-family:var(--font-family);
  font-size:16px;
  color:black;
  //margin-top:1vh;
  text-align:center;
  width:85%;
  margin-left:2%;
  padding-right:5px; 
  padding-left:5px;
  max-height:76vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
  // thumb
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border:1px solid;
    border-color:map-get($generalStyles,infoBeige);
  }
}
@include gadgets-to(desktop) {
  font-family:var(--font-family);
  font-size:18px;
  color:black;
  //margin-top:1vh;
  text-align:center;
  width:75%;
  padding-right:5px; 
  padding-left:5px;
  max-height:76vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
  // thumb
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border:1px solid;
    border-color:map-get($generalStyles,infoBeige);
  }
}
@include gadgets-to(largeDesktop) {
  font-family:var(--font-family);
  font-size:22px;
  color:black;
  //margin-top:1vh;
  text-align:center;
  width:85%;
  padding-right:5px; 
  padding-left:5px;
  max-height:76vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
  // thumb
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border:1px solid;
    border-color:map-get($generalStyles,infoBeige);
  }
}
}
.basicServicesCommentTextJAP{
  @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size:13px;
    color:black;
    margin-top:1vh;
    text-align:left;
    width:92%;
    margin-left:2%;
    padding-right:5px;
    padding-left:5px;
    max-height:80vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
  }
  @include gadgets-to(tablet) {
    font-family:var(--font-family);
    font-size:14px;
    color:black;
    margin-top:1vh;
    text-align:left;
    width:94%;
    padding-right:5px; 
    padding-left:5px;
    max-height:70vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }

  }
  @include gadgets-to(laptop) {
    font-family:var(--font-family);
    font-size:18px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:94%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      width: 2px;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  @include gadgets-to(desktop) {
  
    font-family:var(--font-family);
    font-size:16px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:80%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
    display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  @include gadgets-to(largeDesktop) {
    font-family:var(--font-family);
    font-size:20px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:80%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  }
  .aboutUsCommentText{
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size:16px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:80%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
    display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }

  }
  @include gadgets-to(tablet) {
    font-family:var(--font-family);
    font-size:16px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:80%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
    display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  @include gadgets-to(laptop) {
    font-family:var(--font-family);
    font-size:16px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:80%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
    display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  @include gadgets-to(desktop) {
    font-family:var(--font-family);
    font-size:16px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:80%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
    display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  @include gadgets-to(largeDesktop) {
    font-family:var(--font-family);
    font-size:16px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:80%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:76vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
    display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  }

  .totalServicesCommentTextJAP{
    @include gadgets-to(mobile) {
      font-family:var(--font-family);
      font-size:13px;
      color:black;
      margin-top:1vh;
      text-align:left;
      width:80%;
      padding-right:5px;
      padding-left:5px;
      max-height:80vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        display:none;
      }
    }
    @include gadgets-to(tablet) {
      font-family:var(--font-family);
      font-size:14px;
      color:black;
      margin-top:1vh;
      text-align:left;
      width:94%;
      margin-left:2%;
      padding-right:5px; 
      padding-left:5px;
      max-height:70vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        display:none;
      }

    }
    @include gadgets-to(laptop) {
      font-family:var(--font-family);
      font-size:18px;
      color:black;
      margin-top:1vh;
      text-align:center;
      width:94%;
      margin-left:2%;
      padding-right:5px; 
      padding-left:5px;
      max-height:76vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        width: 2px;
      }
      // thumb
      &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,infoBeige);
      }
    }
    @include gadgets-to(desktop) {
    
      font-family:var(--font-family);
      font-size:16px;
      color:black;
      margin-top:1vh;
      text-align:center;
      width:90%;
      margin-left:2%;
      padding-right:5px; 
      padding-left:5px;
      max-height:76vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        display:none;
      }
      // thumb
      &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,infoBeige);
      }
    }
    @include gadgets-to(largeDesktop) {
      font-family:var(--font-family);
      font-size:18px;
      color:black;
      margin-top:1vh;
      text-align:center;
      width:90%;
      margin-left:2%;
      padding-right:5px; 
      padding-left:5px;
      max-height:76vh;
      overflow-y:scroll;
      // small webkit scroll
      &::-webkit-scrollbar {
        display:none;
      }
      // thumb
      &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
        border:1px solid;
        border-color:map-get($generalStyles,infoBeige);
      }
    }
    }


  .totalServicesCommentText{
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size:13px;
    color:black;
    margin-top:1vh;
    text-align:left;
    width:94%;
    padding-right:5px;
    padding-left:5px;
    max-height:65vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
  }
  @include gadgets-to(tablet) {
    font-family:var(--font-family);
    font-size:14px;
    color:black;
    margin-top:1vh;
    text-align:left;
    width:94%;
    margin-left:2%;
    padding-right:5px; 
    padding-left:5px;
    max-height:60vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  @include gadgets-to(laptop) {
    font-family:var(--font-family);
    font-size:16px;
    color:black;
    margin-top:1vh;
    text-align:center;
    width:90%;
    padding-right:5px; 
    padding-left:5px;
    max-height:67vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }

  }
  @include gadgets-to(desktop) {
    font-family:var(--font-family);
    font-size:20px;
    color:black;
    //margin-top:1vh;
    text-align:center;
    width:95%;
    //margin-left:57%;
    padding-right:5px; 
    padding-left:5px;
    max-height:70vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  @include gadgets-to(largeDesktop) {
    font-family:var(--font-family);
    font-size:20px;
    color:black;
    //margin-top:1vh;
    text-align:center;
    width:95%;
    //margin-left:57%;
    padding-right:5px; 
    padding-left:5px;
    max-height:70vh;
    overflow-y:scroll;
    // small webkit scroll
    &::-webkit-scrollbar {
      display:none;
    }
    // thumb
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
      border:1px solid;
      border-color:map-get($generalStyles,infoBeige);
    }
  }
  }




.basicServicesHr{
  @include gadgets-to(mobile) {
  width:70%;
background-color: white;
border:none;
height:1px;
margin-bottom:2vh;

}
@include gadgets-to(tablet) {
  width:70%;
  background-color: white;
  border:none;
  height:1px;
  margin-bottom:2vh;
}
@include gadgets-to(laptop) {
  width:70%;
  background-color: white;
  border:none;
  height:1px;
}
@include gadgets-to(desktop) {
  width:70%;
  background-color: white;
  border:none;
  height:1px;
  margin-bottom:2vh;
}
@include gadgets-to(largeDesktop) {
  width:70%;
  background-color: white;
  border:none;
  height:1px;
  margin-bottom:2vh;
}
}

.basicServicesCheckIcon{
  @include gadgets-to(mobile) {
  width:15px;
  height:15px;
  margin-right:5px;
  color:green;
}
@include gadgets-to(tablet) {
  width:18px;
  height:18px;
  margin-right:5px;
  color:green;
 // margin-top:3px;
}
@include gadgets-to(laptop) {
  width:20px;
  height:20px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
@include gadgets-to(desktop) {
  width:20px;
  height:20px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
@include gadgets-to(largeDesktop) {
  width:25px;
  height:25px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
}
.aiServicesIcon{
  @include gadgets-to(mobile) {
  width:20px;
  height:20px;
  margin-right:5px;
  color:black;
}

  @include gadgets-to(tablet) {
  width:25px;
  height:25px;
  margin-right:5px;
  color:black;
}
@include gadgets-to(laptop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:3px;
}
@include gadgets-to(desktop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:3px;
}
@include gadgets-to(largeDesktop) {
  width:35px;
  height:35px;
  margin-right:5px;
  color:black;
  margin-top:3px;
}
}
.aboutUsLeafIcon{
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:5vh;
  @include gadgets-to(mobile) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:5vh;

}
  @include gadgets-to(tablet) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:4vh;

  }
@include gadgets-to(laptop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:5vh;
}
@include gadgets-to(desktop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:5vh;
}
@include gadgets-to(largeDesktop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:black;
  margin-top:5vh;
}
}

.aboutUsLeafIconJP{
  @extend .aboutUsLeafIcon;
  margin-top:3vh;
}


.aiServicesCheckIcon{
  @include gadgets-to(mobile) {
  width:15px;
  height:15px;
  margin-right:5px;
  color:green;
  
  }
  @include gadgets-to(tablet) {
    width:20px;
    height:20px;
    margin-right:5px;
    color:green;
    margin-top:3px;
  }
  @include gadgets-to(laptop) {
    width:20px;
    height:20px;
    margin-right:5px;
    color:green;
    margin-top:3px;
  }
  @include gadgets-to(desktop) {
    width:20px;
    height:20px;
    margin-right:5px;
    color:green;
    margin-top:5px;
  }
  @include gadgets-to(largeDesktop) {
    width:25px;
    height:25px;
    margin-right:5px;
    color:green;
    margin-top:3px;
  }
  }
.plusServicesIcon{
  @include gadgets-to(mobile) {
  width:20px;
  height:20px;
  margin-right:5px;
  color:black;
}
@include gadgets-to(tablet) {
  width:25px;
  height:25px;
  margin-right:5px;
  color:black;
}
}

.basicServicesListWrapper{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items:center;
    width:90%;
    height:60vh;
    max-height:58vh;
    overflow-y:auto;
   box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
   border-radius:12px;
   border:1px solid map-get($generalStyles,datalistBorderColor);
   padding-top:15px;
   &::-webkit-scrollbar {
    display:none;
  }
  background-color: white;
  
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:80%;
  height:60vh;
  max-height:58vh;
  overflow-y:auto;
 box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
 border-radius:12px;
 border:1px solid map-get($generalStyles,datalistBorderColor);

 padding-top:15px;
 &::-webkit-scrollbar {
  display:none;
}
background-color: white;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:80%;
  height:60vh;
  max-height:58vh;
  overflow-y:auto;
 box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
 border-radius:12px;
 border:1px solid map-get($generalStyles,datalistBorderColor);
 padding-top:15px;
 &::-webkit-scrollbar {
  display:none;
}
background-color: white;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:80%;
  height:60vh;
  max-height:58vh;
  overflow-y:auto;
 box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
 border-radius:12px;
 border:1px solid map-get($generalStyles,datalistBorderColor);
 padding-top:15px;
 &::-webkit-scrollbar {
  display:none;
}
background-color: white;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:80%;
  height:60vh;
  max-height:58vh;
  overflow-y:auto;
 box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
 border-radius:12px;
 border:1px solid map-get($generalStyles,datalistBorderColor);
 padding-top:15px;
 &::-webkit-scrollbar {
  display:none;
}
background-color: white;
}
}

.basicServicesDataList{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    //flex-wrap: wrap;
    width:96%;



}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  //flex-wrap: wrap;
  width:90%;


}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  //flex-wrap: wrap;
  width:90%;



}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  //flex-wrap: wrap;
  width:90%;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  //flex-wrap: wrap;
  width:90%;


}
}
.basicDataListHeader{
  @include gadgets-to(mobile) {
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top:1vh;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  }
@include gadgets-to(tablet) {
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top:1vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(laptop) {
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top:1vh;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(desktop) {
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top:1vh;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(largeDesktop) {
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top:1vh;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
}
}
.basicDataHeaderServiceId{
  @include gadgets-to(mobile) {
  width:10%;
  display:flex;
  flex-direction:row;
 // justify-content:center;
  align-items:flex-start;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  
}
@include gadgets-to(tablet) {
  width:10%;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
}
@include gadgets-to(laptop) {
  width:10%;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
}
@include gadgets-to(desktop) {
  width:10%;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  width:10%;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
}
}
.basicDataHeaderBenaemn{
  @include gadgets-to(mobile) {
  width:calc(75% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  //justify-content:center;
  align-items:flex-start;
  font-family:var(--font-family);
  font-size:12px;
  color:black;

}
@include gadgets-to(tablet) {
  width:calc(75% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;

}
@include gadgets-to(laptop) {
  width:calc(75% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
}
@include gadgets-to(desktop) {
  width:calc(75% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  width:calc(75% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
}
}
.basicDataHeaderPrice{
  @include gadgets-to(mobile) {
  width:calc(20% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
 // justify-content:center;
  align-items:flex-start;
  font-family:var(--font-family);
  font-size:12px;
  color:black;

}
@include gadgets-to(tablet) {
  width:calc(15% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;

}
@include gadgets-to(laptop) {
  width:calc(15% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:16px;
  color:black;
}
@include gadgets-to(desktop) {
  width:calc(15% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  width:calc(15% - 2px);
  margin-left:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
}
}

.basicServicesData{
  @include gadgets-to(mobile) {

    margin-top:2px;
    width:100%;
    height:5vh;
    box-sizing: border-box; // Ensures padding and border are included in the width
    padding: 5px; // Optional: Add some p
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius:map-get($generalStyles,sixBorderRadius);
    margin-left:2px;
    margin-top:2px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
  
}
@include gadgets-to(tablet) {

  margin-top:2px;
  width:100%;
  height:5vh;
  box-sizing: border-box; // Ensures padding and border are included in the width
  padding: 5px; // Optional: Add some p
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;


}
@include gadgets-to(laptop) {
  margin-top:2px;
  width:100%;
  height:5vh;
  box-sizing: border-box; // Ensures padding and border are included in the width
  padding: 5px; // Optional: Add some p
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;

}
@include gadgets-to(desktop) {
  margin-top:2px;
  width:100%;
  height:5vh;
  box-sizing: border-box; // Ensures padding and border are included in the width
  padding: 5px; // Optional: Add some p
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  margin-top:2px;
  width:100%;
  height:5vh;
  box-sizing: border-box; // Ensures padding and border are included in the width
  padding: 5px; // Optional: Add some p
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
}
}
.basicServicesListId{
  @include gadgets-to(mobile) {
  width:10%;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
//  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:3px;
  font-family: var(--font-family);
  font-size:12px;
  color:black;
}
@include gadgets-to(tablet) {
  width:10%;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
//  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:3px;
  font-family: var(--font-family);
  font-size:14px;
  font-weight: bold;
  color:black;
}
@include gadgets-to(laptop) {
  width:10%;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
//  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:3px;
  font-family: var(--font-family);
  font-size:16px;
  font-weight: bold;
  color:black;
}
@include gadgets-to(desktop) {
  width:10%;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
//  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:3px;
  font-family: var(--font-family);
  font-size:16px;
  font-weight:bold;
  color:black;
}
@include gadgets-to(largeDesktop) {
  width:10%;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
//  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:3px;
  font-family: var(--font-family);
  font-size:24px;
  font-weight:bold;
  color:black;
}
}

 











.basicServicesListBenaemn{
  width:calc(75% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
 // border-radius:3px;
  font-family:var(--font-family);
  font-size:12px;
  color:black;
  cursor:pointer;
}
.basicServicesListPrice{
  @include gadgets-to(mobile) {
  width:calc(15% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:12px;
  color:black;
}
@include gadgets-to(tablet) {
  width:calc(25% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:14px;
  color:black;
 // padding-left:5px;
}
@include gadgets-to(laptop) {
  width:calc(22% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:16px;
  color:black;
}
@include gadgets-to(desktop) {
  width:calc(22% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:16px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  width:calc(22% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:20px;
  color:black;
}
}


.basicServicesListBenaemn{
  @include gadgets-to(mobile) {
  transition: transform 0.5s ease; // Smooth transition for the transform property
  transform-origin: top; // Ensures the scaling is from the top
  width:calc(70% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:12px;
  color:black;
}
@include gadgets-to(tablet) {
  transition: transform 0.5s ease; // Smooth transition for the transform property
  transform-origin: top; // Ensures the scaling is from the top
  width:calc(70% - 2px);
  margin-left:2px;
  margin-right:10px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:14px;
  color:black;
  // overflow-wrap: break-word;
  // white-space: normal;
  overflow-wrap: break-word;
  white-space: normal;
  flex: 1 1 auto; // Allows the container to grow and shrink as needed
  min-width: 0; // Prevents 
}
@include gadgets-to(laptop) {
  transition: transform 0.5s ease; // Smooth transition for the transform property
  transform-origin: top; // Ensures the scaling is from the top
  width:calc(70% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:16px;
  color:black;
}
@include gadgets-to(desktop) {
  transition: transform 0.5s ease; // Smooth transition for the transform property
  transform-origin: top; // Ensures the scaling is from the top
  width:calc(70% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:16px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  transition: transform 0.5s ease; // Smooth transition for the transform property
  transform-origin: top; // Ensures the scaling is from the top
  width:calc(70% - 2px);
  margin-left:2px;
  margin-top:2px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: var(--font-family);
  font-size:24px;
  color:black;
}
}

.basicServicesBaseInfo{
  @include gadgets-to(mobile) {
  width:100%;
  font-family:var(--font-family);
  font-size:14px;
border-radius:3px;


}
@include gadgets-to(tablet) {
  width:100%;
  font-family:var(--font-family);
  font-size:15px;
  border-radius:3px;
  padding:5px;
}
@include gadgets-to(laptop) {
  width:100%;
  font-family:var(--font-family);
  font-size:16px;
  border-radius:3px;
  padding:5px;
}
@include gadgets-to(desktop) {
  width:100%;
  font-family:var(--font-family);
  font-size:16px;
  border-radius:3px;
  padding:5px;
}
@include gadgets-to(largeDesktop) {
  width:100%;
  font-family:var(--font-family);
  font-size:24px;
  border-radius:3px;
  padding:5px;
}
}



.tjansterInfoAnimated{ 
  @include gadgets-to(mobile) {
  
  transform:rotate(0deg);
  animation-name:tjanster55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height:20vh;
max-height:20vh;
overflow-y:scroll;
overflow-x:hidden;

// webkit small scroll
&::-webkit-scrollbar {
  width: 3px;
}
&::-webkit-scrollbar-track {
  background:gold;
  }
&::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border:1px solid;
  border-color:grey;
}
}
@include gadgets-to(tablet) {
  transform:rotate(0deg);
  animation-name:tjanster55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height:25vh;
  max-height:25vh;
  overflow-y:scroll;
  overflow-x:hidden;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color: white;
  border-radius:6px;
  border:2px solid map-get($generalStyles,datalistBorderColor);
// webkit small scroll
&::-webkit-scrollbar {
  width: 3px;
}
&::-webkit-scrollbar-track {
  background:white;
  }
&::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border:1px solid;
  border-color:grey;
}
}
@include gadgets-to(laptop) {
  transform:rotate(0deg);
  animation-name:tjanster55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height:20vh;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color: white;
  border-radius:6px;
  border:2px solid map-get($generalStyles,datalistBorderColor);
// webkit small scroll
&::-webkit-scrollbar {
  width: 3px;
}
&::-webkit-scrollbar-track {
  background:white;
  }
&::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border:1px solid;
  border-color:grey;
}
}
@include gadgets-to(desktop) {
  transform:rotate(0deg);
  animation-name:tjanster55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height:20vh;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color: white;
  border-radius:6px;
  border:2px solid map-get($generalStyles,datalistBorderColor);
// webkit small scroll
&::-webkit-scrollbar {
  width: 3px;
}
&::-webkit-scrollbar-track {
  background:white;
  }
&::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border:1px solid;
  border-color:grey;
}
}
@include gadgets-to(largeDesktop) {
  transform:rotate(0deg);
  animation-name:tjanster55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height:20vh;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color: white;
  border-radius:6px;
  border:2px solid map-get($generalStyles,datalistBorderColor);
// webkit small scroll
&::-webkit-scrollbar {
  width: 3px;
}
&::-webkit-scrollbar-track {
  background:white;
  }
&::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border:1px solid;
  border-color:grey;
}
}
}

    @keyframes tjanster55 {
      0%{ height:0px }
      100%{height:100px;}
      }

.infoFaqWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  background-color:rgb(234, 213, 186);
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);
}
}
.infoFaqHeader{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  align-items:center;
  font-family:Lobster;
  font-size:20px;
  color:black;
  margin-top:2.5vh;
  width:97%;
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  align-items:center;
  font-family:Lobster;
  font-size:22px;
  color:black;
  margin-top:4vh;
  width:97%;
}
@include gadgets-to(laptop) {
  display:flex;
  justify-content:center;
  align-items:center;
  font-family:Lobster;
  font-size:26px;
  color:black;
  margin-top:4vh;
  width:97%;

}
@include gadgets-to(desktop) {
  display:flex;
  justify-content:center;
  align-items:center;
  font-family:Lobster;
  font-size:26px;
  color:black;
  margin-top:4vh;
  width:97%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  justify-content:center;
  align-items:center;
  font-family:Lobster;
  font-size:26px;
  color:black;
  margin-top:4vh;
  width:97%;
}
}

.infoFaqHeaderJAP{
  @include gadgets-to(mobile) {
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:20px;
  color:black;
  margin-top:0.5vh;
  width:97%;
  font-family:var(--font-family);
 
}
@include gadgets-to(tablet) {
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:20px;
  color:black;
  margin-top:0.5vh;
  width:97%;
  font-family:var(--font-family);
  margin-top:1vh;
}
}

.infofaqDataList{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  width:90%;
  margin-left:5%;
  margin-top:2vh;

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  width:90%;
  margin-left:7%;
  margin-top:1vh;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  width:90%;
  margin-top:1vh;
  margin-left:7%;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  width:90%;
  margin-left:7%;
  margin-top:1vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  width:90%;
  margin-top:1vh;
  margin-left:7%;
}
}
.infofaqData{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  margin-top:2px;
  align-items:center;
  width:100%; 
 // border:1px solid map-get($generalStyles,datalistBorderColor);

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  margin-top:8px;
  align-items:center;
  width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  margin-top:8px;
  align-items:center;
  width:100%;
  cursor: pointer;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  margin-top:8px;
  align-items:center;
  width:100%;
  cursor:pointer;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  margin-top:8px;
  align-items:center;
  width:90%;
  cursor:pointer;
}
}
.infofaqId{
  @include gadgets-to(mobile) {
  font-family: var(--font-family);
  font-size:12px;
  color:black;

  @media (min-width:400px) {
    font-size:14px;
  }

}
@include gadgets-to(tablet) {
  font-family: var(--font-family);
  font-size:16px;
  color:black;

}
@include gadgets-to(laptop) {
  font-family: var(--font-family);
  font-size:18px;
  color:black;
}
@include gadgets-to(desktop) {
  font-family: var(--font-family);
  font-size:20px;
  color:black;
}
@include gadgets-to(largeDesktop) {
  font-family: var(--font-family);
  font-size:22px;
  color:black;
}
}
.infofaqQuestion{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
  font-family: var(--font-family);
  font-size:12px;
  color:black;
  height:auto;
  margin-top:5px;

  @media(min-width:400px){
    font-size:14px;
  }


}

@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  //border-radius:3px;
  font-family: var(--font-family);
  font-size:16px;
 // font-style: italic;
  color:black;
  //margin-top:5px;
  //padding:5px;
  height:auto;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  //border-radius:3px;
  font-family: var(--font-family);
  font-size:18px;
 // font-style: italic;
  color:black;
  //margin-top:5px;
  //padding:5px;
  height:auto;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  //border-radius:3px;
  font-family: var(--font-family);
  font-size:20px;
 // font-style: italic;
  color:black;
  //margin-top:5px;
  //padding:5px;
  height:auto;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
  font-family: var(--font-family);
  font-size:24px;
 // font-style: italic;
  color:black;
  height:auto;
 // margin-top:5px;
}
}

.infofaqAnswer{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
  font-family: var(--font-family);
  font-size:14px;
  color:black;
  height:auto;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit small scroll
  &::-webkit-scrollbar {
    display:none;
  }


}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
  font-family: var(--font-family);
  font-size:14px;
  color:black;
  height:auto;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit small scroll
  &::-webkit-scrollbar {
    display:none;
  }

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:90%;
  font-family: var(--font-family);
  font-size:14px;
  color:black;
  height:auto;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit small scroll
  &::-webkit-scrollbar {
    display:none;
  }

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:100%;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  //border-radius:3px;
  font-family: var(--font-family);
  font-size:18px;
  color:black;
  //margin-top:2px;
  //padding:5px;
  height:auto;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit small scroll
  &::-webkit-scrollbar {
    display:none;
  }

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:70%;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  //border-radius:3px;
  font-family: var(--font-family);
  font-size:24px;
  color:black;
  //margin-top:2px;
  //padding:5px;
  height:auto;
  max-height:20vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit small scroll
  &::-webkit-scrollbar {
    display:none;
  }

}
}

.infofaqAnimated{ 
  @include gadgets-to(mobile) {
  transform:rotate(0deg);
  animation-name:faqinfo55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  padding:2px;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  width:100%;
  font-family: var(--font-family);
  font-size:14px;
  }
@include gadgets-to(tablet) {
  transform:rotate(0deg);
  animation-name:faqinfo55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  padding:2px;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  width:100%;
  font-family: var(--font-family);
  font-size:14px;
}
@include gadgets-to(laptop) {
  transform:rotate(0deg);
  animation-name:faqinfo55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  padding:2px;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  width:100%;
  font-family: var(--font-family);
  font-size:14px;
}
@include gadgets-to(desktop) {
  transform:rotate(0deg);
  animation-name:faqinfo55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  padding:2px;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  width:100%;
  font-family: var(--font-family);
  font-size:16px;
}
@include gadgets-to(largeDesktop) {
  transform:rotate(0deg);
  animation-name:faqinfo55;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  padding:2px;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  width:100%;
  font-family: var(--font-family);
  font-size:20px;
}
}




    @keyframes faqinfo55 {
      0%{ height:0px }
      100%{height:100px;}
      }



  .infoSpinnerContainer{
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: transparent;
      transform: translate(-50%, -50%) scale(2);
      z-index: 10;
  }
  .infoSpinner{
    color: map-get($generalStyles, colorblack);
  }

.payPalWrapper{
  @include gadgets-to(mobile) {
  width:90%;
  background-color:transparent;
  height:85vh;
  max-height:85vh;
  margin-top:1vh;
  border-radius:3px;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  background-color:transparent;
  height:85vh;
  max-height:85vh;
  margin-top:1vh;
  border-radius:3px;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:60%;
  background-color:transparent;
  height:85vh;
  max-height:85vh;
  margin-top:1vh;
  border-radius:3px;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:60%;
  background-color:transparent;
  height:85vh;
  max-height:85vh;
  margin-top:1vh;
  border-radius:3px;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:60%;
  background-color:transparent;
  height:85vh;
  max-height:85vh;
  margin-top:1vh;
  border-radius:3px;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }
}
}

.payPalBakesoftHeaderWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: rgba(255, 255, 255, 0.8);
  //border-radius:12px;
  height:25px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 14px;
}
}

.ppBakesoftListWrapper{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;
}
.payPalBakesoftHeaderWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:50%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:60%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:60%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:60%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
}

.payPalBakesoftHeader{
  @include gadgets-to(mobile) {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight:bold;
}
@include gadgets-to(tablet) {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight:bold;
}
@include gadgets-to(laptop) {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight:bold;
}
@include gadgets-to(desktop) {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight:bold;
}
@include gadgets-to(largeDesktop) {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight:bold;
}
}

.ppBakesoftTextWrapper{
  @include gadgets-to(mobile) {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 5px;
  border:2px dotted grey;
}
@include gadgets-to(tablet) {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 5px;
  border:2px dotted grey;
  width:50%;


}
@include gadgets-to(laptop) {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 5px;
  border:2px dotted grey;
  width:60%;
}
@include gadgets-to(desktop) {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 5px;
  border:2px dotted grey;
  width:60%;
}
@include gadgets-to(largeDesktop) {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 5px;
  border:2px dotted grey;
  width:60%;
}
}
.ppBakesofttext{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: rgba(255, 255, 255, 0.5);
  height:25px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 14px;
padding-top:2px;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: rgba(255, 255, 255, 0.5);
  height:25px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 14px;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: rgba(255, 255, 255, 0.5);
  height:25px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 14px;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: rgba(255, 255, 255, 0.5);
  height:25px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 14px;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: rgba(255, 255, 255, 0.5);
  height:25px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 14px;
}
}
.payPalButtonWrapper{
  @include gadgets-to(mobile) {
  // display:flex;
  // flex-direction:column;
  // justify-content:center;
  // align-items:center;
  width:100%;
 // background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(tablet) {
  width:50%;
 // background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(laptop) {
  // display:flex;
  // flex-direction:column;
  // justify-content:center;
  // align-items:center;
  width:60%;
 // background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(desktop) {
  // display:flex;
  // flex-direction:column;
  // justify-content:center;
  // align-items:center;
  width:60%;
 // background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
@include gadgets-to(largeDesktop) {
  // display:flex;
  // flex-direction:column;
  // justify-content:center;
  // align-items:center;
  width:60%;
  //background-color: rgba(255, 255, 255, 0.8);
  border-radius:3px;
  margin-top:3px;
  margin-bottom:2px;

}
}
.paymentOptionWrapper{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:5vh;
  width:100%;
}

.payPalInitialButtonInfo{
  width:60%;
  height:40px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top:5px;
  background-color:white;
  border-radius:6px;
  font-family: var(--font-family);
  font-size: 12px;
  cursor:pointer;
  border:none;
  color:#F9F8F5;
  outline:none;

@include gadgets-to(mobile) {
width:50%;
}
@include gadgets-to(tablet) {
width:30%;

}
@include gadgets-to(laptop) {
width:30%;
}
@include gadgets-to(desktop) {
width:30%;
height:50px;
}
@include gadgets-to(largeDesktop) {
width:30%;
height:80px;
}
}

.payPalMessageInfo{
  width:50%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-end;
  font-family: var(--font-family);
  font-size: 13px;
//   font-weight: bold;
  margin-top:1vh;
//  font-style: italic;
  margin-bottom:3px;
  color:black;
  @include gadgets-to(largeDesktop) {
    font-size: 26px;
  }
}

.paypallogoWrapperInfo{
  width:40%;
  height:35px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  //margin-left:1vh;
  @include gadgets-to(largeDesktop) {
  height:80px;
}
}


.payPalButtonImageInfo{
  @include gadgets-to(mobile) {
  width:70%;
  height: auto;

  @media(min-width:410px) and (max-width:413px){
      width:60%;
      height: auto;
  }

}
@include gadgets-to(tablet) {
  width:70%; /* Adjust the size as needed */
  height:30px;

}
@include gadgets-to(laptop) {
  width:60%; /* Adjust the size as needed */
  height: auto;

}
@include gadgets-to(desktop) {
  //width:60%; /* Adjust the size as needed */
  height: 40px;
  width:100px;

}
@include gadgets-to(largeDesktop) {
  //width:70%; /* Adjust the size as needed */
  height:70px;
  width:140px;

}
}

.finalPaymentInfoTopWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;

}
}

.finalPaymentMessageInfo{
  font-size:20px;
  font-family: var(--font-family);
  // no underline
  text-decoration: none;
  color:black;
}

.finalPaymentLinkInfo{
  font-size:20px;
  font-family: var(--font-family);
  // no underline
  color:black;
}	

//sliding menu

.slidingMenuTopWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:40vh;
  margin-top:3vh;
}
}

.sliding-menu {
  position: fixed;
  top: 0;
  right: -280px; /* Adjust width as needed */
  width: 300px;
  height:2vh;
  background-color:transparent;
 // box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
  margin-top:20vh;
}

.slidingMenuIcon{
  width:25px;
  height:25px;
  color:black;
}
.sliding-menu.open {
  display:flex;
  flex-direction: column;
  //justify-content: center;
  align-items:flex-end;
  padding-top:2vh;
  margin-top:20vh;
  right: 0;
 // background-color: white;
 .close-button {
  position: absolute;
  top: 20px;
  right: 22px;
}
}
.sliding-menu.partially-open {
  right: -50px; /* Adjust to show a portion of the menu */
  
  .close-button {
    position: absolute;
    top: 25px;
    right: 62px;
  }
}
.close-button {
  position: absolute;
  top: 40px;
  right: 30px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  @media (max-width:370px) {
    position: absolute;
    top: 25px;
    right: 62px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  @media (min-width:371px) {
    position: absolute;
    top: 20px;
    right:60px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
}
.infoHeadermenuButton{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-end;
  width:100%;
  height:30px;
  margin-top:3px;
 // background-color: rgb(197, 161, 127);
  border:none;
  background-color:white;
}
}

.slidingMenuWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
 // justify-content:center;
 // align-items:flex-start;
  width:100%;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  box-shadow: 12px 12px 15px rgba(0,0,0,0.5);
  //margin-top:3vh;
  border-radius: 12px;
  padding:10px;
  width:80%;
  background-color: white;
  border:1px solid grey;
}
}

.slidingMenuButton{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  width:100%;
  height:30px;
  margin-top:3px;
  background-color:white;
  border:none;
  font-size:14px;
  font-family: var(--font-family);
}
}
.menuCountryFlagWrapper{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    width:80%;
    height: auto;
    margin-top:2vh;
  }

}

.infoCountryFlagWrapper{

  @include gadgets-to(tablet) {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  margin-top:3vh;
}
}

.slidingMenuPaymentPartners{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  margin-top:5vh;
  
  @media(min-width:359px) and (max-width:361px){
   margin-top:2vh;
  }


}

.slidingMenuPaymentHeader{

  font-size:14px;
  font-family: var(--font-family);
  margin-bottom:1vh;
}


.slidingMenuKlarnaPartner{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:2vh;
  margin-top:11vh;

  @media(min-width:359px) and (max-width:361px){
   margin-top:4vh;
   width:80%;
   margin-top:15vh;
  }
}
}

.slidingMenuKlarnaPartnerPromo{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  width:40%;
  height:2vh;
  margin-top:3vh;
}
}
.slidingMenuPayPalPartner{
  @include gadgets-to(mobile) {
  // display:flex;
  // flex-direction:column;
  // justify-content:center;
  // align-items:center;
  width:100%;
 height:2vh;
  //margin-top:1vh;
}
}
.infoPaymentWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
 // height: auto;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:80%;
  height: auto;
  margin-top:2vh;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:80%;
  height: auto;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:80%;
  height: auto;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:80%;
  height: auto;
}
}

.infoPaymentPayPal{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:40px;
}
}
.infoPaymentKlarna{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  margin-top:8px;
 // height: auto;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  margin-top:8px;
 // height: auto;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  margin-top:20px;
 // height: auto;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  margin-top:25px;
 // height: auto;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  margin-top:20px;
 // height: auto;
}
}

.integrityPolicyTop{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
 // margin-top:3vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
 // align-items:center;
  width:100%;
  height:90vh;
 // margin-top:3vh;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
 // margin-top:3vh;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
 // margin-top:3vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
 // margin-top:3vh;
}
}
.integrityPolicyHeaderText{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size:19px;
  margin-top:2vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 20px;
 // margin-top:4vh;
 

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 24px;
 // margin-top:1px;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 24px;
  margin-top:4vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 20px;
  margin-top:4vh;
}
}

.integrityPolicyWrapper{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items:center;
    width:100%;
    height:30vh;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius:12px;
    box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
    width:85%;
    height:50vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top:15vh;
    margin-top:10vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  height:30vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:12px;
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  width:85%;
  height:45vh;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top:20vh;
} 
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:12px;
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  width:85%;
  height:35vh;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top:15vh;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:12px;
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  width:85%;
  height:40vh;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top:15vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:12px;
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  width:85%;
  height:50vh;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top:15vh;
}
}

.integrityPolicyDoc{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:14px;
  margin-top:2vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:16px;
  margin-top:2vh;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:16px;
  margin-top:2vh;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:16px;
  margin-top:2vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:20px;
  margin-top:2vh;
}
}
.integrityPolicyBackgroundImage{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
    background-position:center; /* Center the background image */
 width:100%;

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
   align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
 //   background-position:center; /* Center the background image */
 width:100%;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
   align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
 //   background-position:center; /* Center the background image */
 width:100%;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
   align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
 //   background-position:center; /* Center the background image */
 width:100%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
   align-items:center;
  grid-area:b;
  height:90vh;
    background-size:cover; /* Cover the entire div */
 //   background-position:center; /* Center the background image */
 width:100%;
}
}

.infoContactInfoTop{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  width:100%;
  height:90vh;
  margin-top:3vh;
}
}

.infoContactInfoHeader{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 20px;
  margin-top:3vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 20px;
  margin-top:1px;
  @media(min-width:1170px) and (max-width:1200px){
    margin-top:1px;
  }
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 22px;
  margin-top:1px;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 22px;
  margin-top:1px;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 20px;
  margin-top:1px;
}
}
.infoContactInfoWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  width:80%;
  height:60vh;
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-top:2vh;
  padding-top:14vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  background-color:rgba(255,255,255,0.8);
 
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
 // justify-content:center;
  align-items:center;
  width:80%;
  height:55vh;
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-top:2px;
  padding-top:14vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  background-color:rgba(255,255,255,0.8);
 
  @media(min-width:1170px) and (max-width:1200px){
  width:80%;
  height:56vh;
  margin-top:1px;
  padding-top:10vh;
  background-color: rgba(255,255,255,0.8)


}
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
 // justify-content:center;
  align-items:center;
  width:80%;
  height:40vh;
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-top:1px;
  padding-top:14vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  background-color:rgba(255,255,255,0.8);
 

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
 // justify-content:center;
  align-items:center;
  width:80%;
  height:40vh;
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-top:1px;
  padding-top:14vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  background-color:rgba(255,255,255,0.8);
 
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
 // justify-content:center;
  align-items:center;
  width:80%;
  height:50vh;
  border-radius:map-get($generalStyles,sixBorderRadius);
  margin-top:1px;
  padding-top:14vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  box-shadow: 12px 12px 15px 0 rgba(0,0,0,0.5);
  background-color:rgba(255,255,255,0.8);
 
}
}

.infoContactInfoText{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size: 14px;
  margin-top:5px;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:18px;
  margin-top:5px;
  @media(min-width:1170px) and (max-width:1200px){
    font-size:14px;
  }
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:16px;
  margin-top:5px;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:20px;
  margin-top:5px;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:26px;
  margin-top:5px;
}
}
.infoContactInfoTextJapan{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:98%;
  height: auto;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top:2vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  font-family: var(--font-family);
  font-size:18px;
  margin-top:2vh;
  @media(min-width:1170px) and (max-width:1200px){
    font-size:14px;
  }
}
}



.infoContactEmailWrapper{

@include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border:1px solid;
  border-radius:12px;
  border-color:map-get($generalStyles,datalistBorderColor);
  width:95%;
  padding-bottom:12px;
  padding-top:3px;
  padding-left:10px;
  padding-right:10px;
  margin-top:1vh;
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  &:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    background-color:rgba(255, 255, 255, 0.5);
    border-color:rgb(175, 159, 159);
    outline:none;
    
  }

}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border:1px solid;
  border-radius:12px;
  border-color:map-get($generalStyles,datalistBorderColor);
  width:60%;
  padding-bottom:12px;
  padding-top:3px;
  padding-left:10px;
  padding-right:10px;
  margin-top:1vh;
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  &:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    background-color:rgba(255, 255, 255, 0.5);
    border-color:rgb(175, 159, 159);
    outline:none;
    
  }
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border:1px solid;
  border-radius:12px;
  border-color:map-get($generalStyles,datalistBorderColor);
  width:60%;
  padding-bottom:12px;
  padding-top:3px;
  padding-left:10px;
  padding-right:10px;
  margin-top:1vh;
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  &:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    background-color:rgba(255, 255, 255, 0.5);
    border-color:rgb(175, 159, 159);
    outline:none;
    
  }
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border:1px solid;
  border-radius:12px;
  border-color:map-get($generalStyles,datalistBorderColor);
  width:60%;
  padding-bottom:12px;
  padding-top:3px;
  padding-left:10px;
  padding-right:10px;
  margin-top:1vh;
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  &:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    background-color:rgba(255, 255, 255, 0.5);
    border-color:rgb(175, 159, 159);
    outline:none;
    
  }
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border:1px solid;
  border-radius:12px;
  border-color:map-get($generalStyles,datalistBorderColor);
  width:60%;
  padding-bottom:12px;
  padding-top:3px;
  padding-left:10px;
  padding-right:10px;
  margin-top:1vh;
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  &:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    background-color:rgba(255, 255, 255, 0.5);
    border-color:rgb(175, 159, 159);
    outline:none;
    
  }
}
}



.infoContactEmailIcon{
  width:30px;
  height:30px;
  @include gadgets-to(mobile) {
  width:20px;
  height:20px;
}
  @include gadgets-to(tablet) {
  width:20px;
  height:20px;
}
@include gadgets-to(laptop) {
  width:20px;
  height:20px;
}
@include gadgets-to(desktop) {
  width:20px;
  height:20px;
}
@include gadgets-to(largeDesktop) {
  width:20px;
  height:20px;
}
}
.infoContactEmailLink{
  font-size:20px;
  font-family: var(--font-family);
  color:black;
  text-decoration:none;
  &:hover,
  &:visited,
  &:active {
    color: red;  // Ensure link changes to red on all relevant states
  }
  @media (min-width:1170px) {
    font-size: 14px;
  }
  @include gadgets-to(mobile) {
    font-size: 14px;
  }
}

.infoBuyingTermsText{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  margin-top:5px;
}

@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  // font-family: var(--font-family);
  // font-size: 16px;
  margin-top:5px;
  padding:10px;

}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  // font-family: var(--font-family);
  // font-size: 16px;
  margin-top:5px;
  padding:10px;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  // font-family: var(--font-family);
  // font-size: 16px;
  margin-top:5px;
  padding:10px;

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  width:90%;
  height: auto;
  // font-family: var(--font-family);
  // font-size: 16px;
  margin-top:5px;
  padding:10px;

}
}


.infoBuyingTermsWrapper{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
//  justify-content:center;
  align-items:center;
  width:92%;
  border:2px solid map-get($generalStyles,datalistBorderColor);
  margin-top:1vh;
  height:79vh;
  max-height:79vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }


}
@include gadgets-to(tablet){
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  margin-top:1vh;
  border:2px solid map-get($generalStyles,datalistBorderColor);
  height:75vh;
  max-height:75vh;
  overflow-y:scroll;
  // small webkit scroll
  &::-webkit-scrollbar {
    display:none;
  }


}
@include gadgets-to(laptop){
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  margin-top:1vh;
  height:63vh;
  max-height:63vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit scroll
  ::-webkit-scrollbar {
    width:10px;
  }
  &::-webkit-scrollbar-track {
    background:beige;
    }
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border:1px solid;
    border-color:grey;
  }
}
@include gadgets-to(desktop){
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  width:100%;
  margin-top:1vh;
  height:90vh;
  border:2px solid map-get($generalStyles,datalistBorderColor);
 
  max-height:90vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit scroll
  ::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background:beige;
    }
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border:1px solid;
    border-color:grey;
  }
}

@include gadgets-to(largeDesktop){
  display:flex;
  flex-direction:column;
 // justify-content:center;
  align-items:center;
  width:100%;
  margin-top:1vh;
  height:90vh;
  border:2px solid map-get($generalStyles,datalistBorderColor);
  max-height:90vh;
  overflow-y:scroll;
  overflow-x:hidden;
  // webkit scroll
  ::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background:beige;
    }
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border:1px solid;
    border-color:grey;
  }
}


}





.buyingTermstransparentBox{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
  background-color: rgba(255, 255, 255, 0.8);
  width:94%;
  height:90vh;
  margin-top:2vh;
  border-radius:12px;
  z-index:2;
}
@include gadgets-to(tablet) {
display:flex;
flex-direction:column;
align-items:center;
background-color: rgba(255, 255, 255, 0.8);
width:94%;
height:90vh;
margin-top:2vh;
border-radius:12px;

}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.8);
  display:flex;
  flex-direction:column;
  align-items:center;
  width:80%;
  height:90vh;
  margin-top:2vh;
  border-radius:12px;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:44%;
  height:80vh;
  margin-left:3%;
  margin-top:2vh;
  border-radius:12px;
  position:absolute;
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.8);
  width:50%;
  height:80vh;
  margin-top:2vh;
  border-radius:12px;
  z-index:2;
  position:absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
}
}

.infoBuyingTermsHeader{
  @include gadgets-to(mobile) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 20px;
  margin-top:3vh;
}
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 24px;
  margin-top:3vh;
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 26px;
  margin-top:3vh;
}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 26px;
  margin-top:3vh;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: auto;
  font-family:map-get($generalStyles,fontFamilyLobster);
  font-size: 26px;
  margin-top:3vh;
}
}


//Buying terms

.allmant-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5; // Added line-height to prevent overlap
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.6; // Increased line-height for better spacing
    height: auto; // Remove fixed height to avoid cutting off text
    padding: 1rem; // Added padding for extra spacing
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5; // Consistent line-height
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.allmant-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem; // Added margin to create space between heading and paragraph
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.allmant-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6; // Ensure extra spacing between lines
    margin-bottom: 1rem; // Extra margin to prevent overlap with the next paragraph
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}


.bestallning-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5; // Added line-height to prevent overlap
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6; // Increased line-height for better spacing
    padding: 1rem; // Added padding for extra spacing
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5; // Consistent line-height
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.bestallning-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem; // Added margin to create space between heading and content
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.bestallning-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6; // Ensure extra spacing between lines
    margin-bottom: 1rem; // Extra margin to prevent overlap with the next paragraph
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.bestallning-list {
  list-style: none;
  padding: 0;

  li {
    @include gadgets-to(mobile) {
      font-size: 14px;
      line-height: 1.5;
    }

    @include gadgets-to(tablet) {
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 0.5rem;
    }

    @include gadgets-to(laptop) {
      font-size: 14px;
    }

    @include gadgets-to(desktop) {
      font-size: 16px;
    }

    @include gadgets-to(largeDesktop) {
      font-size: 20px;
    }
  }
}

.bestallning-subheading {
  @include gadgets-to(mobile) {
    font-size: 16px;
    margin-top: 1rem;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-top: 1.5rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

// 3 Prices

.priser-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.priser-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.priser-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

// prices
.priser-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.priser-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.priser-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.priser-list {
  list-style: none;
  padding: 0;

  li {
    @include gadgets-to(mobile) {
      font-size: 14px;
      line-height: 1.5;
    }

    @include gadgets-to(tablet) {
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 0.5rem;
    }

    @include gadgets-to(laptop) {
      font-size: 14px;
    }

    @include gadgets-to(desktop) {
      font-size: 16px;
    }

    @include gadgets-to(largeDesktop) {
      font-size: 20px;
    }
  }
}



// payment

.betalning-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.betalning-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.betalning-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.betalning-list {
  list-style: none;
  padding: 0;

  li {
    @include gadgets-to(mobile) {
      font-size: 14px;
      line-height: 1.5;
    }

    @include gadgets-to(tablet) {
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 0.5rem;
    }

    @include gadgets-to(laptop) {
      font-size: 14px;
    }

    @include gadgets-to(desktop) {
      font-size: 16px;
    }

    @include gadgets-to(largeDesktop) {
      font-size: 20px;
    }
  }
}

// 5 delivery

.leverans-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.leverans-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.leverans-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

//6 return
.angerratt-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.angerratt-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.angerratt-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

//reklamation
.reklamation-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.reklamation-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.reklamation-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

// 8 force majeure

.force-majeure-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.force-majeure-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.force-majeure-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

// 9 tvist
.tvist-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.tvist-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.tvist-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

// 10 personuppgifter
.personuppgifter-section {
  width: 96%;
  text-align: center;
  font-family: var(--font-family);
  color: black;
  margin-top: 1vh;

  @include gadgets-to(mobile) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    padding: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
  }
}

.personuppgifter-heading {
  @include gadgets-to(mobile) {
    font-size: 16px;
  }

  @include gadgets-to(tablet) {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 20px;
  }

  @include gadgets-to(desktop) {
    font-size: 24px;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 28px;
  }
}

.personuppgifter-paragraph {
  @include gadgets-to(mobile) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(tablet) {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @include gadgets-to(laptop) {
    font-size: 14px;
    line-height: 1.5;
  }

  @include gadgets-to(desktop) {
    font-size: 16px;
    line-height: 1.5;
  }

  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.signUptransparentBox{
  @include gadgets-to(mobile) {
  background-color: rgba(255, 255, 255, 0.8);
  width:87%;
  z-index:2;
  height:90%;
  margin-top:2vh;
 // border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(tablet) {
  background-color: rgba(255, 255, 255, 0.7);
  width:80%;
  z-index:2;
  height:90%;
  margin-top:2vh;
  //border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(laptop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:70%;
  z-index:2;
  height:90%;
  margin-top:2vh;
  //border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(desktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:60%;
  z-index:2;
  height:85%;
  margin-top:2vh;
  //border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
@include gadgets-to(largeDesktop) {
  background-color: rgba(255, 255, 255, 0.7);
  width:60%;
  z-index:2;
  height:80%;
  margin-top:2vh;
  //border-radius:12px;
  z-index:2;
  display:flex;
  flex-direction:column;
  align-items:center;
}
}

.ppKlarnaTextWrapper{
  @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
    width:90%;
    margin-top:2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
  @include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    align-items:center;
    width:50%;
    margin-top:2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
  @include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    align-items:center;
    width:50%;
    margin-top:2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
  @include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    align-items:center;
    width:40%;
    margin-top:2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
  @include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    align-items:center;
    width:40%;
    margin-top:2vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
}




// signUpPrivateFast
.infospfTopWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:98%;
  height:90vh;
 @include gadgets-to(tablet) {
  width:100%;
  height:90vh;
}
@include gadgets-to(laptop) {
  width:90%;
  height:90vh;
}
@include gadgets-to(desktop) {
  width:90%;
  height:90vh;
}
@include gadgets-to(largeDesktop) {
  width:90%;
  height:90vh;
}
}

.infospfBoxWrapper{
  display:flex;
  flex-direction: row;
 // justify-content: center;
 // align-items: center;
  width:100%;
  @include gadgets-to(desktop) {
    width:100%;
  }
  @include gadgets-to(largeDesktop) {
    width:100%;
  }

}
.infospfLeftBox{
  display:flex;
  flex-direction:column;
  // justify-content: center;
   width:40%;
   height:90vh;
   background-color:transparent;

@include gadgets-to(tablet) {
  width:55%;
  height:90vh;
  border-radius:8px;
//  box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  //background-color:rgb(234, 213, 186);
  background-color:transparent;
  margin-top:1px;
}
@include gadgets-to(laptop) {
  width:60%;
  height:90vh;
  background-color:transparent;
  border-radius:8px;
  //box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  margin-top:1px;

}
@include gadgets-to(desktop) {
  width:60%;
  height:90vh;
  background-color:transparent;
  border-radius:8px;
 // box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  margin-top:1px;

}
@include gadgets-to(largeDesktop) {
  width:40%;
  height:90vh;
  background-color:transparent;
  border-radius:8px;
  //box-shadow: 6px 9px 2px rgba(0, 0, 0, 0.2);
  margin-top:1px;

}
}

.infospfLeftBoxFull{
  display:flex;
  flex-direction:column;
  //justify-content: center;
width:100%;
height:90vh;
border-radius:8px;
box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
background-color:rgb(234, 213, 186);

@include gadgets-to(tablet) {
  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);

}
@include gadgets-to(laptop) {
  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);

}
@include gadgets-to(desktop) {
  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);

}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  //align-items:center;
 // justify-content: center;

  width:100%;
  height:90vh;
  background-color:rgb(234, 213, 186);

}
}


.infospfHeaderText{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  height:6vh;
  font-size:26px;
  font-weight:bold;
  font-family:map-get($generalStyles,fontFamily);
  //border:1px solid red;
  @include gadgets-to(tablet) {
    font-size: 22px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;


  }
  @include gadgets-to(laptop) {
    font-size: 26px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;
  }
  @include gadgets-to(desktop) {
    font-size: 26px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 26px;
    font-family: var(--font-family);
    font-weight: 200;
    font-style: normal;
  }
}
.infospfRightBox{

 // border:2px solid rgb(213, 182, 153);
  width:58%;
height:90vh;
margin-left:5px;
//background-color:rgb(234, 213, 186);
@include gadgets-to(tablet) {
  width:55%;
  height:90vh;
  max-height:90vh;
  overflow-y:auto;
  // webkit scrollbar
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: white;
    border:1px solid grey;
  }
  &::-webkit-scrollbar-thumb {
    background:lightblue;
  }

  background-color:transparent;
  border-radius: 8px;
  margin-top:1px;
 // box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  padding-top:6vh;

}
@include gadgets-to(laptop) {
  width:58%;
  height:90vh;
  background-color:transparent;
  border-radius: 8px;
  margin-top:1px;
  //box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  padding-top:6vh;
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;

}
@include gadgets-to(desktop) {
  width:59%;
  height:90vh;
  background-color:transparent;
  border-radius: 8px;
  margin-top:1px;
  //box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
 padding-top:6.5vh;
 display:flex;
 flex-direction:column;
 align-items:center;
// justify-content: center;

}
@include gadgets-to(largeDesktop) {
  width:49%;
  height:90vh;
  background-color:transparent;
  border-radius: 8px;
  margin-top:1px;
  padding-top:6vh;
 // box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;

}
}
.infospfRightBoxSmall{
width:1px;
 }

.infospfServicesWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 justify-content: center;
  width:100%;
  @include gadgets-to(tablet) {
    width:100%;
  }
  @include gadgets-to(desktop) {
    width:100%;
  }
  @include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:100%;
    
  }

 
}
.infospfServicesTop{
  width:100%;
  @include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:100%;

  }
  @include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
  }
  @include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
  }
  @include gadgets-to(largeDesktop) {
display:flex;
flex-direction:column;
align-items:center;
justify-content: center;

  }

  
}
.infospfServicesBox{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:95%;
  height:10vh;
  border-radius:8px;
  border:1px solid rgb(213, 182, 153);
  margin-bottom:3px;
  background-color: white;
  margin-bottom:1vh;
  box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
  &.selected {
    border:3px solid;
    border-color:green;
    height:11vh;
    transition: all 0.6s ;
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4);
    .infospfCheckboxCircle{
      font-weight:600;
    }
  }
  @include gadgets-to(tablet) {
    width:90%;
  }
  @include gadgets-to(laptop) {
    width:80%;
  }
  @include gadgets-to(desktop) {
    width:60%;
  }
  @include gadgets-to(largeDesktop) {
    width:90%;
  }
}
.infospfInsideBox{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  height:100%;
  border-radius:8px;
}
.infospfInsideBoxText{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  height:25%;
  font-size: 16px;
  font-family: map-get($generalStyles,fontFamily);
}
.infospfInsideBoxTextA{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content: center;
  width:94%;
  height:25%;
  font-size: 16px;
  font-family: map-get($generalStyles,fontFamily);
  color:grey;
  cursor:pointer;
  @include gadgets-to(tablet) {
    font-size: 12px;
  }

}
.infospfInsideBoxTextB{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content: center;
  width:100%;
  height:25%;
  font-size: 16px;
  font-family: map-get($generalStyles,fontFamily);
cursor:pointer;
}
.infospfInsideBoxTextC{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-end;
  width:95%;
  height:60%;
  font-size: 18px;
  font-weight: 300;
  font-family: map-get($generalStyles,fontFamily);
  margin-bottom:2vh;
  cursor:pointer;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 14px;
  }
  @include gadgets-to(desktop) {
    font-size: 14px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
  }
}
.infospfCheckboxCircle{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  width:94%;
  height:20%;
  margin-bottom:3px;
  //border:1px solid red;
  font-size:24px;
  font-family:map-get($generalStyles,fontFamily); 
  font-weight:400;
  margin-top:1vh;
cursor:pointer;
@include gadgets-to(tablet) {
  font-size: 16px;
  margin-top:2vh;
}
@include gadgets-to(laptop) {
  font-size:20px;
  margin-top:2vh;

}
@include gadgets-to(desktop) {
  font-size:18px;
  margin-top:2vh;

}
@include gadgets-to(largeDesktop) {
  font-size: 24px;
  margin-top:2vh;


}
}
.infospfCheckCircle{
  width:15px;
  height:15px;
  margin-right:5px;
  color:green;
  @include gadgets-to(tablet) {
  width:24px;
  height:24px;
  margin-right:5px;
  color:green;
}
@include gadgets-to(laptop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
@include gadgets-to(desktop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
@include gadgets-to(largeDesktop) {
  width:35px;
  height:35px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
}
.infospfCheckCircleFail{
  width:15px;
  height:15px;
  margin-right:5px;
  color:red;
  @include gadgets-to(tablet) {
  width:24px;
  height:24px;
  margin-right:5px;
  color:red;
}
@include gadgets-to(laptop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:red;
  margin-top:3px;
}
@include gadgets-to(desktop) {
  width:30px;
  height:30px;
  margin-right:5px;
  color:red;
  margin-top:3px;
}
@include gadgets-to(largeDesktop) {
  width:35px;
  height:35px;
  margin-right:5px;
  color:red;
  margin-top:3px;
}
}

.infospfCheckIcon{
  @include gadgets-to(mobile) {
  width:15px;
  height:15px;
  margin-right:5px;
  color:green;
}
@include gadgets-to(tablet) {
  width:18px;
  height:18px;
  margin-right:5px;
  color:green;
 // margin-top:3px;
}
@include gadgets-to(laptop) {
  width:20px;
  height:20px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
@include gadgets-to(desktop) {
  width:20px;
  height:20px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
@include gadgets-to(largeDesktop) {
  width:25px;
  height:25px;
  margin-right:5px;
  color:green;
  margin-top:3px;
}
}


.infospfList{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content: center;
  width:95%;
  height:50%;
  font-size: 16px;
  font-family: map-get($generalStyles,fontFamily);
  color:grey;
  cursor:pointer;
  margin-top:5px;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
}
.infospfListHeaderThick{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content: center;
  width:95%;
  height:50%;
  font-size: 16px;
  font-family: map-get($generalStyles,fontFamily);
  font-weight:bold;
  color:black;
  cursor:pointer;
  margin-top:5px;
  font-weight:600;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
}
.infospfTextRow{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  width:95%;
  height:20%;
  font-size: 16px;
  font-family: map-get($generalStyles,fontFamily);
  color:black;
  cursor:pointer;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
}


.infospfSelectAreaWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  
}


.infospfselect{
  width: 95%;
  padding: 0.7vh 1vh;
  font-size: 14px;
  font-family: var(--font-family);
  color: #333;
  border:1px solid map-get($generalStyles,colorBgBeige);
  border-radius: 6px;
  appearance: none; /* Remove default select arrow */
  background-color:white;//#e3e8ec;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KPHBhdGggZD0iTTEgNEw3IDlMMTMsNCIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiLz4KPC9zdmc+');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  transition: border-color 0.3s ease ;
  cursor: pointer;
  margin-top: 3px;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.4);


&:focus {
//   border-color: #007BFF; /* Change border color on focus */
  outline: none;
  border-color: #888;
}

&:hover {
  border-color: #888;
}

@include gadgets-to(tablet) {
width:90%;
font-size: 12px;
}
@include gadgets-to(laptop) {
width:80%;
}
@include gadgets-to(desktop) {
width:60%;
}
@include gadgets-to(largeDesktop) {
width:90%;
padding: 10px 14px;

}

}


.infoprecashTopWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  height:90vh;
 // border:1px solid red;

}

.infoprecashWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:99%;
  height:88vh;
  //border:1px solid red;
  //margin-top:3vh;
  border:1px solid blue;
  margin-left:2px;
}
.infoprecashTextRowWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:90%;
  height:40vh;
  border:1px solid red;
  border-radius:12px;
  margin-left:2px;
  margin-top:2vh;
}
.infoprecashTextRow{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  width:95%;
  height:2vh;
  font-size: 20px;

  font-family: map-get($generalStyles,fontFamily);
  color:black;
  cursor:pointer;
  margin-top:2vh;

}

.infoprecashPayInfoWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  margin-top:2vh;
}

.signUpTopWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:100%;
  height:90vh;
  margin-top:2vh;
}

.signUpInputHeight{
  height:48px;
}
.signupwithimage{
  display:inline-block;
  
width:40px;
height:40px;
border-radius: 50%;

}

.signupimageTop{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
}
.signupnameTop{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
  font-size:14px;
  font-family: var(--font-family);
  font-weight:100;
}
.signupemailTop{
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  width:100%;
  font-size:12px;
  font-family: var(--font-family);
  font-weight:100;
}

.signuptoverifyTop{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:100%;
  height:90vh;
  @include gadgets-to(mobile) {
    width:100%;
    height:90vh;
  }
  @include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
   align-items:center;
   height:90vh;
   //background-color:map-get($generalStyles,colorBgBeige);
   border-radius:8px;
  }
  @include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
   align-items:center;
   height:90vh;
  // background-color:map-get($generalStyles,colorBgBeige);
   border-radius:8px;
  
  }
  @include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
   align-items:center;
   height:90vh;
   //background-color:map-get($generalStyles,colorBgBeige);
   border-radius:8px;
  }
  @include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
   align-items:center;
   height:90vh;
   //background-color:map-get($generalStyles,colorBgBeige);
   border-radius:8px;
  }


}

.infospfCardHolder{
width:100%;
@include gadgets-to(tablet) {
  width:80%;
}
@include gadgets-to(laptop) {
  width:100
}
@include gadgets-to(desktop) {
  width:100
}
@include gadgets-to(largeDesktop) {
  width:100
}
}





//stripe callback 

.infoppDetailsTop{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  @include gadgets-to(tablet) {
    width:100%;
  }
  @include gadgets-to(laptop) {
    width:100%;
  }
  @include gadgets-to(desktop) {
    width:60%;
  }
  @include gadgets-to(largeDesktop) {
    width:60%;
  }
}

.infoppMessageBoxWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:100%;
  height:70vh;
  height:60vh;
  margin-top:2vh;
  margin-top:4vh;
  @include gadgets-to(mobile) {
    width:100%;
  }
  
}
.infoppMessageBox{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:70%;
  height:40vh;
  border-radius:12px;
  box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.2);
  background-color:white;
  margin-top:2vh;
  margin-top:4vh;
  @include gadgets-to(mobile) {
    width:95%;
  }
  @include gadgets-to(tablet) {
    width:80%;
  }
  @include gadgets-to(laptop) {
    width:60%;
  }
}
.infoppMessageBoxFail{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:70%;
  height:40vh;
  border-radius:12px;
  border:1px dotted red;
  box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.2);
  background-color:white;
  margin-top:2vh;
  margin-top:4vh;
  @include gadgets-to(mobile) {
    width:95%;
  }
  @include gadgets-to(tablet) {
    width:80%;
  }
  @include gadgets-to(laptop) {
    width:60%;
  }
}
.infosppHeader{
display:flex;
flex-direction: row;
justify-content: center;
align-items: center;
font-family:var(--font-family);
font-size:20px;
font-weight:bold;
color:green;
@include gadgets-to(mobile) {
  font-size: 16px;
}
}
.infosppConfirmMessageWrapper{
display:flex;
flex-direction:column;
align-items:center;
justify-content: center;
margin-top:1vh;
@include gadgets-to(mobile) {
  width:90%;
  margin-top:1px;
}
}
.infosppConfirmMessage{
  font-size: 12px;
  font-family: var(--font-family);

}
.infosppConfirmMessageFail{
  font-size: 14px;
  font-family: var(--font-family);
  @include gadgets-to(mobile) {
    font-size: 12px;
  }
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 20px;
  }

}

.infosppHeaderText{
font-size: 20px;
font-weight: bold;
font-family: var(--font-family);
color: green;
margin-left:1vw;
@include gadgets-to(laptop) {
  font-size: 22px;


}
}
.infosppHeaderTextFail{
  font-size: 20px;
  font-weight: bold;
  font-family: var(--font-family);
  color:red;
  margin-left:1vw;
  @include gadgets-to(laptop) {
    font-size: 22px;
  
  
  }
  }

.infosppLoginIcon{
color:green;
font-size:40px;
cursor: pointer;
margin-top:3vh;
@include gadgets-to(mobile) {
  font-size: 28px;
  margin-top:3vh;
}
}



.infosppStripeConfirmBoxWrapper {
  background-color:white; /* Light grey background */
  padding: 20px;
  border-radius: 8px;
  max-width:40vw;
//  margin: 0 auto;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.6);  /* Adds a shadow */
  height:auto;
  max-height:90vh;
  overflow-y:scroll;
  overflow-x:hidden;
  background-color:rgba(255,255,255,0.9);
  border-radius:8px;
  @include gadgets-to(mobile) {
    margin-top:2vh;
    height:80vh;
    max-height:80vh;
    overflow-y:auto;
    width:100%;
    max-width:80vw;
  }
  @include gadgets-to(tablet) {
    margin-top:2vh;
    height:80vh;
    max-height:80vh;
    overflow-y:auto;
    width:100%;border:1px solid map-get($generalStyles,datalistBorderColor);
    background-color: white;
  }
  @include gadgets-to(laptop) {
    margin-top:2vh;
    height:auto;
    width:100%;

  }
  @include gadgets-to(desktop) {
    margin-top:5vh;
    height:auto;
    width:40%;

  }
  @include gadgets-to(largeDesktop) {
    margin-top:5vh;
    height:auto;
    width:35%;
  
    
  }

}

.infosppStripeConfirmBox {
  background-color:white; /* Light blue background for the confirmation box */
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}
.infosppConfirmBoxHeader{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:80%;
  height:3vh;
  margin-bottom:1vh;
  font-size: 18px;
  font-weight:400;
  font-family:map-get($generalStyles,fontFamilyNoto);
  text-align: center !important;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:8px;
  padding-top:3px;
  padding-bottom:3px;
  padding-left:1.2vw;
  padding-right:1.2vw;
  border:1px solid map-get($generalStyles,datalistBorderColor);
 // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), /* Larger shadow for lift */
 // 0px 1px 3px rgba(0, 0, 0, 0.06); /* Subtle shadow for depth */
//border: 1px solid #ddd; /* Soft, thin border */
transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
}
.infospfConfirmBoxTop{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content: center;
  width:100%;

}
.infospfConfirmBoxText{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  font-size:18px;
  font-weight:300;
  font-family: var(--font-family);
  margin-top:2vh;
}
.infospfConfirmBoxTextSmall{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  font-size:16px;
  font-weight:300;
  font-family: var(--font-family);
  margin-top:3px;
}
.infospfConfirmBoxTextSmallBold{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  font-size:16px;
  font-weight:bold;
  font-family: var(--font-family);
  margin-top:3px;
}

.infocompleteTopWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  
}
.infocompleteMessage{
 font-size:20px;
 font-family: var(--font-family);
}
.infocompleteMessageEmail{
  font-size:18px;
  font-family: var(--font-family);
  font-weight:200;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:8px;
  padding-top:3px;
  padding-bottom:3px;
  padding-left:1.2vw;
  padding-right:1.2vw;
  @include gadgets-to(mobile) {
    font-size: 16px;
  }
 }
.infocompleteLogin{
  margin-top:2vh;
}
.infocompleteLoginIcon{
  color:green;
  font-size:30px;
  cursor: pointer;
}
.infocompleteWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  width:80%;
  height:30vh;
  background-color: white;
 
  @include gadgets-to(mobile) {
    width:100%;
  }

}
.infosppConfirmEmailMessage{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
width:100%;

}
.infosppEmailIconWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
}







//info pre
.infopre-container {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
 // padding: 1rem;
  padding-top:1vh;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3; /* Adjust as needed */
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.6);
 @include gadgets-to(mobile) {
    width:90%;
    margin-top:0.5vh;
   // padding:0.5rem;
  }
  @include gadgets-to(tablet) {
    width:90%;
  }
  @include gadgets-to(laptop) {
    width:90%;
  }
  @include gadgets-to(desktop) {
    width:85%;
  }
  @include gadgets-to(largeDesktop) {
    width:80%;
  }
}

.infopre-card {
  width: 100%;
  max-width: 768px; /* Default max width */
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
@include gadgets-to(mobile) {
padding:2px;
}
}

.infopre-header, .infopre-footer {
  text-align: center;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  padding:0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  @include gadgets-to(mobile) {
    padding:2px;
    border:none;
    box-shadow:none;
  }
}

.infopre-title {
  font-size: 1.5rem;
  font-weight: bold;

  @media (min-width: 640px) {
    font-size: 1.875rem;
  }
  @include gadgets-to(mobile) {
    font-size: 1.5rem;
    padding:1px;

  }
}
.infopre-payment-label{
  font-size:16px;
  font-weight: bold;
  font-family: var(--font-family);

}

.infopre-signup-image-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-style:bold;
  font-family: var(--font-family);
}

.infopre-signup-image {
  width: 1.75rem;
  height: 1.75rem;
  margin-left: 0.5rem;
  border-radius:50%;
}
.infopre-signup-name-top{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight:200;
  font-family: var(--font-family);
}
.infopre-signup-email-top{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight:200;
  font-family: var(--font-family);
}
.infopre-plan-label{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight:600;
  font-family: var(--font-family);
}
.infopre-content {
  margin: 1.5rem 0;
  @include gadgets-to(mobile) {
    margin:0.5rem 0;
  }
}

.infopre-plan-details, .infopre-payment-details {
  margin-bottom:5px;
  @include gadgets-to(mobile) {
    margin-bottom:1px;
  }
}

.infopre-plan-card {
  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
@include gadgets-to(mobile) {
padding:0.5rem;
}
}

.infopre-plan-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size:16px;
  font-weight: 200;
  font-family:var(--font-family);
}

.infopre-icon {
  margin-right: 0.5rem;
}

.infopre-plan-text, .infopre-discount-text, .infopre-total-info span {
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
}
.infopre-payment-text{
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family);
  margin-top:5px;
}
.infopre-payment-value{
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family);
  margin-top:5px;
}

.infopre-payment-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
}
.infopre-discount-info{
  display: flex;
  justify-content:flex-start;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-family);
}

.infopre-total-info {
  display: flex;
  justify-content: space-between;
  font-size:20px;
  font-weight: bold;
}

.infopre-discount-value {
  color: #28a745; /* Green for discounts */
}

.infopre-button {
  width: 100%;
  padding: 0.75rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
   // background-color: #4a4a4a;
    border:4px solid black;

  }

  &:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
}


.infocontinueTopWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
//  justify-content: center;
  width:100%;
  height:90vh;
  margin-top:5vh;
  @include gadgets-to(mobile) {
   margin-top:2vh;
  }
  @include gadgets-to(tablet) {
    margin-top:2vh;
  }
}
.infocontinueBoxWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  @include gadgets-to(tablet) {
    height:80vh;
  }

 // margin-top:5vh;
}
.infocontinueLoginBoxOuter{
  width:60%;
  height:70vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:8px;
  background-color:rgba(255,255,255,0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.4);
  @include gadgets-to(mobile) {
    width:90%;
    height:80vh;
  }
 @include gadgets-to(tablet) {
  width:80%;
  height:100%;
}
@include gadgets-to(laptop) {
  width:70%;
}
@include gadgets-to(desktop) {
  width:60%;
}
@include gadgets-to(largeDesktop) {
  width:60%;
}
}

.infocontinueLoginBox{
width:80%;
height:60vh;
border:1px solid map-get($generalStyles,datalistBorderColor);
border-radius:8px;
background-color: white;
box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.2);
@include gadgets-to(mobile) {
  width:90%;
  height:70vh;
}
@include gadgets-to(tablet) {
  width:85%;
  height:70vh;
}
@include gadgets-to(laptop) {
  width:80%;
}
@include gadgets-to(desktop) {
  width:80%;
}
@include gadgets-to(largeDesktop) {
  width:80%;
}
}


.infocontinueHeaderWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
  height:5vh;
  font-size:20px;
  font-family: var(--font-family);
  font-weight:bold;
  color:grey;
  font-weight:300;
  margin-top:3vh;
}
.infocontinueHeaderText{
  font-size:20px;
  font-family: var(--font-family);
  color:grey;
  font-weight:200;
  margin-bottom: 5px;
}
.infocontinueHeaderTextSmall{
  @extend .infocontinueHeaderText;
  font-size:16px;
}
.infocontinueHRWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:100%;
}

.infocontinueHR{
  height: 1px;                // Set the height of the line
  background-color:grey;  // Set the color of the line (black in this case)
  width:60%;                 // Set the width of the line (full width in this case)
  margin:20px 0;              // Add vertical spacing around the line
  border: none;
}
.infocontinueORText{
  font-size:16px;
  font-family: var(--font-family);
  color:grey;
  font-weight:200;
  margin-top:15px;
}

.infocontinueMidWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  width:100%;
  height:50vh;
  margin-top:3px;
}
.infocontinueSocialWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  //justify-content: center;
  width:100%;
}
.infocontinueGoogleButton{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  width:50%;
  height:40px;
  background-color:white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  outline:none;
  border-radius:5px;
  color:black;
  cursor:pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    padding:2px;
  }
  @include gadgets-to(mobile) {
    width:90%;
  }
}
.infocontinueGoogleIcon{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  height:20px;
  width:20px;
  margin-right:10px;
}

.infocontinueGoogleText{
  font-size:14px;
  font-family: var(--font-family);
  font-weight:500;
  color:black;
}


.infocontinueFacebookButton {
  background-color: #4267B2;  // Facebook blue color
  color: white;
  width:50%;
  margin-top:5px;
  height:40px;
  border-radius:5px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  border:none;
  outline:none;
  cursor:pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    border:2px solid #4267B2;
  }
  @include gadgets-to(mobile) {
    width:90%;
  }


}

.infocontinueFacebookIcon{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  height:20px;
  width:20px;
  margin-right:10px;
}
.infocontinueFacebookText{
  font-size:14px;
  font-family: var(--font-family);
  font-weight:500;
  color:white;
}

.infocontinueLineButton {
  background-color:#06C755;// Facebook blue color
  color: white;
  font-size:16px;

  width:50%;
  margin-top:5px;
  height:40px;
  border-radius:5px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  border:none;
  outline:none;
  cursor:pointer;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;   
  &:hover {
    border:2px solid #06C755;
  }
  @include gadgets-to(mobile) {
    width:90%;
  }

}
.infocontinueLineIcon{
  height:30px;
  width:30px;
  margin-right:5px;
}
.infocontinueLineText{
  font-size:14px;
  font-family: var(--font-family);
  font-weight:500;
  color:white;
}
.infocontinueInput{
  width:49%;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:6px;
  height:35px;
  margin-top:10px;
  font-size:13px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
@include gadgets-to(mobile) {
  width:90%;
}

  
}
.infocontinueButton{
  width:50%;
  height:40px;
  background-color:black;
  color:white;
  border:none;
  border-radius:5px;
  cursor:pointer;
  margin-top:10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;  
  &:hover {
    border:3px solid black;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    height:50px;
    color:lightgreen;
  }
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-size: 14px;
  font-family: var(--font-family);
  @include gadgets-to(mobile) {
    width:90%;
  }
}
.infocontinueButton1{
  @extend .infocontinueButton;
border:3px solid red;
&:hover {
  border:3px solid red;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  height:50px;
  color:red;
  cursor:not-allowed;
}
}

.infoPayFormButton{
  width:100%;
  height:40px;
  background-color:blue;
  color:white;
  margin-top:5px;
  border-radius:6px;
  outline:none;
  border:none;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  cursor:pointer;
  font-size: 16px;
  font-family: var(--font-family);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
  &:hover {
    border:2px solid blue;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.6);
    height:50px;
  }

}


.infoSignUpMobileTopWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:100%;
  height:90vh;
  margin-top:1vh;
}

.infoSignUpMobileServiceWrapper{
display:flex;
flex-direction:column;
align-items:center;
justify-content: center;
width:100%;
gap:5px;

}
.infoSignUpMobileSelectBox{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:100%;
  height:90vh;

}
.infoSignUpMobileHeaderText{
  font-size: 16px;
  font-family: var(--font-family);
}
.infoSignUpMobileSelect{
width:95%;
height:30px;
background-color: white;
border:1px solid map-get($generalStyles,datalistBorderColor);
}
.infoSignUpMobileServiceInfo{
  margin-top:5px;
background-color: white;
border:1px solid map-get($generalStyles,datalistBorderColor);
width:94%;
height:auto;
border-radius: 12px;
padding-top:1.5vh;
padding-bottom:1.5vh;

}


// precheckout mobile
.preCheckWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
 // justify-content: center;
  width:93%;
  height:65vh;
  margin-top:1vh;
  border-radius:8px;
  background-color: white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  box-shadow: 10px 10px 12px rgba(0, 0, 0, 0.4);

}
.preCheckHeaderWrapper{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content:space-between;
  width:97%;
  height:2vh;
  padding-left:10px;
  padding-right:10px;
  margin-top:1vh;
  
}
.preCheckHeader{
  font-size:16px;
  font-family: var(--font-family);
  font-weight:bold;
  padding:1vh;
}
.preCheckImage{
  width:30px;
  height:30px;
  margin-right:5px;
  border-radius: 50%;
  margin-right:5px;
  animation: moveLeftRight 2s ease-in-out; /* Adjust timing as needed */
}
.preCheckNameInfoWrapper{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  width:91%;
  height:6vh;
  margin-top:1vh;
}
.preCheckHR{
  height: 1px;                // Set the height of the line
  background-color:grey;  // Set the color of the line (black in this case)
  width:90%;                 // Set the width of the line (full width in this case)
  margin:5px 0;              // Add vertical spacing around the line
  border: none;
}
.preCheckName{
  font-size:12px;
  font-family: var(--font-family);
  font-weight:200;
  margin-top:5px;
  
}
.preCheckmEmail{
  font-size:10px;
  font-family: var(--font-family);
  font-weight:200;
  margin-top:1vh;
}
.preCheckMidWrapper{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  width:85%;
  margin-top:1vh;

}
.preCheckOutMobileWrapper{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  width:95%;
  height:5vh;
  margin-top:0.5vh;
}
.preCheckPlanLabel{
  font-size:16px;
  font-family: var(--font-family);
  font-weight:200;
  font-weight:bold;
  margin-top:1vh;
  margin-bottom:1px;
}
.preCheckPlanText{
  font-size:14px;
  font-family: var(--font-family);
  font-weight:200;
 // margin-top:1vh;
}
.preCheckPlanTextBold{
  font-size:14px;
  font-family: var(--font-family);
  font-weight:bold;
 // margin-top:1vh;
}
.preCheckPlanTextBoldGreen{
  font-size:14px;
  font-family: var(--font-family);
  font-weight:bold;
  color:green;
}
.preCheckPayText{
  font-size:18px;
  font-family: var(--font-family);
  font-weight:200;
 // margin-top:1vh;
}
.preCheckIcon{
  width:16px;
  height:16px;
  margin-right:5px;
}
.preCheckIconMid{
  width:22px;
  height:16px;
}
.preCheckIconLarge{
  width:20px;
  height:20px;
  margin-right:5px;
}
.preCheckMidSize{
  width:100%;
  height:10px;
}
.preCheckIconRow{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  width:95%;
  height:2vh;
}
.preCheckRowWrapper{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  width:95%;
  height:2.5vh;
  
}
.preCheckRowWrapperLow{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  width:90%;
  height:2vh;
  gap:3px;
  margin-top:4px;
  
}
.preCheckColumnWrapperLow{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  width:90%;
  height:2vh;
  gap:3px;
  margin-top:4px;
  
}

.preCheckFootWrapper{
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:100%;
}
.preCheckPayWrapper{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  width:100%;
  margin-top:1vh;
}

.preCheckButton{
    width: 100%;
    padding: 0.65rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
     // background-color: #4a4a4a;
      border:4px solid black;
  
    }
  
    &:disabled {
      background-color: #9e9e9e;
      cursor: not-allowed;
    }
  }
  .preCheckButtonArea{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:90%;
    margin-top:1vh;
  }





  @keyframes moveLeftRight {
    0% {
      transform: translateX(0); /* Start position */
    }
    50% {
      transform: translateX(-100%); /* Move 20% to the left */
    }
    100% {
      transform: translateX(0); /* Move back to the original position */
    }
  }

  .acknowledgeWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:87%;
    background-color: white;
    margin-top:5px;
    border-radius: 12px;
    padding:5px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
  
    @include gadgets-to(mobile) {
      width:92%;
      padding:3px;
    }
    @include gadgets-to(tablet) {
      width:90%;
    }
    @include gadgets-to(laptop) {
      width:78%;
    }
    @include gadgets-to(desktop) {
      width:58%;
    }
    @include gadgets-to(largeDesktop) {
      width:88%;
    }
  }
  .acknowledgeCheckbox{
    width:20px;
    height:20px;
    margin-right:5px;
    cursor:pointer;
    @include gadgets-to(mobile) {
      width:15px;
      height:15px;
    }
    @include gadgets-to(tablet) {
      width:20px;
      height:20px;
    }
    @include gadgets-to(laptop) {
      width:20px;
      height:20px;
    }
    @include gadgets-to(desktop) {
      width:20px;
      height:20px;
    }
    @include gadgets-to(largeDesktop) {
      width:25px;
      height:25px;
    }
  } 
  .acknowledgeText{
    font-size:14px;
    font-family: var(--font-family);
    font-weight:200;
    @include gadgets-to(mobile) {
      font-size:12px;
    }
    @include gadgets-to(tablet) {
      font-size:14px;
    }
    @include gadgets-to(laptop) {
      font-size:14px;
    }
    @include gadgets-to(desktop) {
      font-size:14px;
    }
    @include gadgets-to(largeDesktop) {
      font-size:16px;
    }
  }




  //spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #0055DE;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #0055DE;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  .infoCheckoutButton{
    background-color: #0055DE;
    color:white;
    width:100%;
    height:40px;
    border:none;
    outline:none;
   // box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius:8px;
    margin-top:1vh;
    cursor:pointer;

  }
  
.infoCompleteContinueWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width:100%;
  margin-top:1vh;
  width:50%;
  border-radius: 6px;

} 
.infoCompleteContinueHeader{
  font-size:16px;
  font-family: var(--font-family);
  margin-top:1vh;
}
.infoCompleteContinueText{
  font-size:16px;
  font-family: var(--font-family);
  margin-top:1vh;
}
  