
  .backgroundFadeValue{
    position:absolute;
    top:80px;
    left:1700px;
    background-color: beige;
    background: yellow;
}





