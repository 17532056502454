
@import '../style/MainStyles.scss';
// slider
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
    margin-top:3px;
  }

  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded slider */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }





//slider 1

  .switch1 {
 
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
    margin-top:3px;

  }

  
  .switch1 input { 
    opacity: 0;
    width: 0;
    height: 0;
  }



  
  .slider1 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider1:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider1 {
    background-color: #2196F3;
  }
  
  input:checked + .slider1:before {
    transform: translateX(20px);
  }
  
  /* Rounded slider */
  .slider1.round {
    border-radius: 34px;
  }
  
  .slider1.round:before {
    border-radius: 50%;
  }

  




  .slider2 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider2:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider2 {
    background-color: map-get($generalStyles,pinkRed)
  }
  
  input:checked + .slider2:before {
    transform: translateX(20px);
  }
  
  /* Rounded slider */
  .slider2.round {
    border-radius: 34px;
  }
  
  .slider2.round:before {
    border-radius: 50%;
  }