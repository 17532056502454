:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xototWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xototWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .xototWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodStatArea, .xoprodStatArea1 {
    display: grid;
    grid-template-rows: 6% 4% 46% 40%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .xoprodStatArea, .xoprodStatArea1 {
    grid-template-rows: 6% 4% 43% 40%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodStatArea, .xoprodStatArea1 {
    display: grid;
    grid-template-columns: 24% 40% 35%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodStatArea, .xoprodStatArea1 {
    display: grid;
    grid-template-columns: 24% 40% 35%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodStatArea, .xoprodStatArea1 {
    display: grid;
    grid-template-columns: 24% 40% 35%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodStatArea, .xoprodStatArea1 {
    display: grid;
    grid-template-columns: 24% 40% 35%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xocompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xocompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 36px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xocompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xocompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xocompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xocompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xocompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 36px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xocompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xocompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xocompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

.xoprodStatArea1 {
  background-color: rgb(245, 245, 237);
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodStatAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    margin-top: 2px;
    border-radius: 3px;
    height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodStatAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
    height: 80vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xoprodStatAreaA {
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodStatAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
    height: 80vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodStatAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
    height: 80vh;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodStatAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
    height: 80vh;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodStatAreaB {
    grid-area: d;
    height: 44vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xoprodStatAreaB {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 392px) {
  .xoprodStatAreaB {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) and (max-width: 409px) {
  .xoprodStatAreaB {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .xoprodStatAreaB {
    height: 41vh;
    margin-top: 20px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) {
  .xoprodStatAreaB {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xoprodStatAreaB {
    height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodStatAreaB {
    grid-area: d;
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xoprodStatAreaB {
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodStatAreaB {
    grid-area: d;
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodStatAreaB {
    grid-area: d;
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodStatAreaB {
    grid-area: d;
    height: 80vh;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    background-color: var(--val);
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoheaderArea {
    grid-area: a;
    height: 20px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xoheaderArea {
    grid-area: a;
    height: 50px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xorangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xorangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xorangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xorangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xorangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xorange1, .xorange2 {
    grid-area: b;
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xorange1, .xorange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 20px;
    margin-right: 2%;
    cursor: pointer;
    grid-area: b;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xorange1, .xorange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xorange1, .xorange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xorange1, .xorange2 {
    accent-color: blue;
    width: 125px;
    margin-right: 10px;
    cursor: pointer;
    margin-left: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xorange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xorange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xorange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xorange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xorange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xoprodstatTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.xoprodstatFieldset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  border: none;
  padding: 0;
}

.xoprodstatLegend {
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 5%;
}

.xoprodstatFieldsetFrom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  border: none;
  margin-top: 5px;
  padding: 0;
}

.xoprodstatLegendFrom {
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 5%;
}

.xoprodstatFieldsetTo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  border: none;
  margin-top: 5px;
  padding: 0;
}

.xoprodstatLegendTo {
  font-family: var(--font-family);
  font-size: 12px;
  margin-left: 5%;
}

.xoprodstatSelect {
  width: 90%;
  height: 30px;
  background-color: white;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  outline: none;
  font-family: var(--font-family);
  font-size: 12px;
  border-radius: 6px;
  color: black;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodstatStartDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodstatStartDate {
    -webkit-appearance: none;
    width: 85%;
    height: 25px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatStartDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodstatStartDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodstatStartDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodstatEndDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodstatEndDate {
    -webkit-appearance: none;
    width: 85%;
    height: 25px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatEndDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
    color: black;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodstatEndDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodstatEndDate {
    width: 90%;
    height: 30px;
    background-color: white;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    outline: none;
    font-family: var(--font-family);
    font-size: 12px;
    border-radius: 6px;
  }
}

.xoprodstatOption {
  font-family: var(--font-family);
  font-size: 14px;
}

.xoprodstatDataArea {
  display: flex;
  flex-direction: row;
  margin-left: 29px;
}

.xoprodstatFromArea {
  width: 100%;
}

.xoprodstatoToArea {
  width: 100%;
}

.xoprodstatMidWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatMidWrapper {
    width: 100%;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodstatMid {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 14vh;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMid::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMid::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMid::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xoprodstatMid {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) and (max-height: 600px) {
  .xoprodstatMid {
    height: 8vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 392px) {
  .xoprodstatMid {
    max-height: 15vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) and (max-width: 409px) {
  .xoprodstatMid {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .xoprodstatMid {
    height: 40vh;
    max-height: 18vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) and (max-height: 667px) {
  .xoprodstatMid {
    max-height: 10vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) {
  .xoprodstatMid {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xoprodstatMid {
    height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodstatMid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 55vh;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMid::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMid::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMid::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xoprodstatMid {
    max-height: 50vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xoprodstatMid {
    max-height: 48vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatMid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 50vh;
    height: auto;
    overflow-y: scroll;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodstatMid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 50vh;
    height: auto;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMid::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMid::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMid::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodstatMid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 50vh;
    height: auto;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMid::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMid::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMid::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodstatMidJAP {
    grid-area: c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 10vh;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMidJAP::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) {
  .xoprodstatMidJAP {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 380px) and (max-height: 600px) {
  .xoprodstatMidJAP {
    height: 8vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 384px) and (max-width: 392px) {
  .xoprodstatMidJAP {
    max-height: 12vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 393px) and (max-width: 409px) {
  .xoprodstatMidJAP {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) {
  .xoprodstatMidJAP {
    height: 40vh;
    max-height: 15vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 413px) and (max-height: 667px) {
  .xoprodstatMidJAP {
    max-height: 10vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 414px) {
  .xoprodstatMidJAP {
    height: 40vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 428px) {
  .xoprodstatMidJAP {
    height: 40vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodstatMidJAP {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 55vh;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMidJAP::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .xoprodstatMidJAP {
    max-height: 50vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xoprodstatMidJAP {
    max-height: 48vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatMidJAP {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 50vh;
    height: auto;
    overflow-y: scroll;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodstatMidJAP {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 50vh;
    height: auto;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMidJAP::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodstatMidJAP {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 50vh;
    height: auto;
    overflow-y: scroll;
    width: 90%;
  }
  .xoprodstatMidJAP::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-track {
    background: var(--rgbaVal); /* color of the tracking area */
  }
  .xoprodstatMidJAP::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodstatButton {
    width: auto;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodstatButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodstatButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xoprodstatButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal);
  }
}

.xoprodstatButton1 {
  width: auto;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 16px;
  margin-left: 2px;
  margin-bottom: 2px;
  outline: none;
  padding: 5px;
  cursor: pointer;
  background-color: var(--rgbaVal2);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodstatButton1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatButton1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodstatButton1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
  }
}
@media only screen and (min-width:2303px) {
  .xoprodstatButton1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    border-radius: 6px;
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 2px;
    margin-bottom: 2px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: var(--rgbaVal2);
  }
}

.xoprodstatButtontext {
  color: black;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-family: var(--font-family);
}

.xoprodstatRightFieldset {
  border: none;
  font-family: var(--font-family);
  font-size: 12px;
  margin-top: 8px;
  margin-left: 10px;
}

.xoprodstatRightLegend, .xoprodstatHeader {
  color: black;
  font-family: var(--font-family);
}

.xoprodstatHeader {
  color: var(--rgbaVal);
  font-size: 18px;
}

.xoprodstatChartWrapper {
  height: 60vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xoprodstatChartWrapper {
    height: 27vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .xoprodstatChartWrapper {
    height: 22vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xoprodstatChartWrapper {
    height: 60vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xoprodstatChartWrapper {
    height: 65vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xoprodstatChartWrapper {
    height: 70vh;
  }
}
@media only screen and (min-width:2303px) {
  .xoprodstatChartWrapper {
    height: 68vh;
  }
}

.xoChartChoiceArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1vh;
}

.xochartFieldset {
  border: none;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.xochartbackgroundColor {
  background-color: var(--val);
}

.xochartLegend {
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
}

.xouseChartChoicechartbutton, .xouseChartChoicechartbuttonactive {
  margin: 2px;
  width: auto;
  height: 30px;
  border-radius: 6px;
  color: white;
  font-family: var(--font-family);
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.xouseChartChoicechartbutton {
  background-color: RGB(255, 128, 0);
}

.xouseChartChoicechartbuttonactive {
  background-color: red;
}

.xoprodStatSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xobackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.xobarChart {
  margin: 10px 30px 0 -15px;
  height: 30%;
  width: 70%;
}/*# sourceMappingURL=ProdStatNew1.css.map */