@import '../style/MainStyles.scss';/* styles.css */

.xntotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
     width:100%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}

}


.xnwindowArea {
    @include gadgets-to(mobile) {

        display: grid;
        // grid-template-rows: 3% 5% 26% 17% 30%;
         grid-template-rows:auto auto auto auto auto;
    
         grid-template-areas:
             "a"
             "b"
             "c"
             "d"
             "e"
             ;
         width: 100%;
         height:auto;
         object-fit: cover;
    }
    
    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:24% 40% 35%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    
       
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:24% 50% 25%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:24% 50% 25%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:25% 50% 24%;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c d e";
          column-gap:7px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.xnwindowArea1 {
    @extend .xnwindowArea;
    // background-color: map-get($generalStyles, backgroundColor);
}

.xnwindowAreaA {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:32.5vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top: 5px;
    border-radius:map-get($generalStyles, sixBorderRadius);
    @media(min-width:375px) and (max-width:380px){
        height: 33vh;
    
    }
    @media(min-width:393px){
        height: 33vh;
    
    }

    @media(min-width:393px){
        height: 33vh;
    
    }

    @media(min-width:410px) and (max-width:413px){
        height: 24vh;
    
    }

    @media(min-width:414px){
        height: 28vh;
    
    }
    @media(min-width:428px){
        height: 33vh;
    
    }
    @include gadgets-to(tablet) {
        height:84vh;
        @media(max-width:1024px){
        height:84vh;
    }
        @media(min-width:1170px) and (max-width:1190px)
        {
            height:74vh;
        }
    }
    @include gadgets-to(laptop) {
        height:84vh;
        margin-top:0;
    }
    @include gadgets-to(desktop) {
        height:84vh;
        margin-top:0;
    }
    @include gadgets-to(largeDesktop) {
        height:84vh;
        margin-top:0;
    }
}

.xnwindowAreaB {
    grid-area: d;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:48vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    @media(min-width:375px) and (max-width:380px){
        height: 48vh;
    
    }
    @media(min-width:384px) and (max-width:392px){
        height:50vh;
    
    }


    @media(min-width:393px) and (max-width:409px){
        height: 49vh;
    
    }

    @media(min-width:410px) and (max-width:413px){
        height: 52.5vh;
    
    }

    @media(min-width:414px){
        height: 54vh;
    
    }
    @media(min-width:428px){
        height: 50vh;
    
    }
    @include gadgets-to(tablet) {
        height:84vh;
        @media(max-width:1024px){
        height:84vh;
    }
    @media(min-width:1170px) and (max-width:1190px)
    {
        height:74vh;
    }
    }
    @include gadgets-to(laptop) {
        height:84vh;
        margin-top:0;
    }
    @include gadgets-to(desktop) {
        height:84vh;
        margin-top:0;

    }
    @include gadgets-to(largeDesktop) {
        height:84vh;
        margin-top:0;
    }
}

.xnwindowAreaC {
    @include gadgets-to(mobile) {

    grid-area: e;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:30vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:1.5vh;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(tablet) {
    grid-area: e;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:84vh;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:3px;
    border-radius: map-get($generalStyles, sixBorderRadius);

     @media(max-width:1024px){
        height:84vh;
    }
    @media(min-width:1170px) and (max-width:1190px)
    {
        height:74vh;
    }

}
@include gadgets-to(laptop) {
    grid-area: e;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:84vh;
    background-color: map-get($generalStyles, transparentChangeable);
   // margin-top:3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
    grid-area: e;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:84vh;
    background-color: map-get($generalStyles, transparentChangeable);
  //  margin-top:3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    grid-area: e;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 100%;
    height:84vh;
    background-color: map-get($generalStyles, transparentChangeable);
 //   margin-top:3px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
}

.xnheaderArea{
    @include gadgets-to(mobile) {
        grid-area: a;

    }
    @include gadgets-to(tablet) {
        grid-area: a;
        height:20px;

    }
@include gadgets-to(laptop) {
        grid-area: a;
    }
@include gadgets-to(desktop) {
        grid-area: a;
         height:30px;
    }
@include gadgets-to(largeDesktop) {
        grid-area: a;
    }

}

.xncompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: white;
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color: white;
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: white;
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: white;
    }
}
.xncompName1 {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 28px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles, transparentChangeableMenu5);
    }
}

.xnrangeArea {
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
        //  @media(min-width:1081px) and (max-width:1170px)
        //  {
        //     height:20px;
        //  }
        //  // as ipad
        // //  @media(min-width:1171px) and (max-width:1195px)
        // //  {
         
        // //     height:auto;
        // //  }

        //  @media(min-width:1196px) and (max-width:1400px)
        //  {
        //     height:20px;
        //  }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}

.xnrange1 {
    @include gadgets-to(mobile) {
        grid-area:b;
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:20px;
        margin-right:2%;
        cursor:pointer;
        grid-area:b;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-right:10px;
        cursor:pointer;
        margin-left:10px;
    }
}

.xnrange2 {
    @extend .xnrange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
        margin-right:1%;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}


.xndataContainer {
    @include gadgets-to(mobile) {

    display: flex;
    flex-direction: row;
    width: 100%;
    height:0.1vh;
  //  margin-left: 1px;
    margin-top: 5px;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height:18vh;
    margin-left: 1px;
    margin-top: 5px;
    @media(max-width:1024px){
        height:24vh;
    }
    @media(min-width:1025px) and (max-width:1169px){
        height:24vh;
    }

    @media(min-width:1170px) and (max-width:1195px)
    {
        height:22vh;
    }
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height:18vh;
    margin-left: 1px;
    margin-top: 5px;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height:22vh;
    margin-left: 1px;
    margin-top: 5px;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height:22vh;
    margin-left: 1px;
    margin-top: 5px;
}
}


.xnnewProductFieldset {
    @include gadgets-to(mobile) {
    border: none;
    height: 40px;
    width: 97%;
    margin-top: 1px;
}
@include gadgets-to(tablet) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    height:5.5vh;
    width:98%;
    margin-top: 1px;
    margin:0;
    padding:0;
    box-sizing: border-box;
}
@include gadgets-to(laptop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    height:5.5vh;
    width:98%;
    margin-top: 1px;
    margin:0;
    padding:0;
    box-sizing: border-box;

}
@include gadgets-to(desktop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    height:5.5vh;
    width:98%;
    margin-top: 1px;
    margin:0;
    padding:0;
    box-sizing: border-box;
}
@include gadgets-to(largeDesktop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    height:5.5vh;
    width:98%;
    margin-top: 1px;
    margin:0;
    padding:0;
    box-sizing: border-box;
}
}

.xnnewProductLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:14px;


}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:3px;
    padding:0;

}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:3px;
    padding:0;


}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left:10px;
    padding:0;

}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-left:5px;
   // margin:0;
    padding:0;

}
}

.xnnewProductionCategorySelect {
    @include gadgets-to(mobile) {

    width:45%;
    height: 30px;
    margin-left: 15px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
}
@include gadgets-to(tablet) {
    width:48%;
    height: 30px;
 //   margin-left: 15px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(laptop) {
    width:48%;
    height: 30px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
}
@include gadgets-to(desktop) {
    width:48%;
    height: 35px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
}
@include gadgets-to(largeDesktop) {
    width:48%;
    height: 40px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
}
}


.xnnewProductionProductSelect {
    @include gadgets-to(mobile) {
    width:45%;
    height: 30px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black
}
@include gadgets-to(tablet) {
    width:48%;
    height: 30px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(laptop) {
    width:48%;
    height: 30px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(desktop) {
    width:48%;
    height: 35px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 15px;
}
@include gadgets-to(largeDesktop) {
    width:48%;
    height: 40px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}
}


.xnongoingProductionFieldset {
    @include gadgets-to(mobile) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top: 10px;
    //width:100%;
}
@include gadgets-to(tablet) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top: 10px;
    width:98%;
  //  margin:0;
    padding:0;

}
@include gadgets-to(laptop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top: 10px;
    width:98%;
  //  margin:0;
    padding:0;

}
@include gadgets-to(desktop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top: 10px;
    width:98%;
  //  margin:0;
    padding:0;

}
@include gadgets-to(largeDesktop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top: 10px;
    width:98%;
  //  margin:0;
    padding:0;

}
}
.xnongoingProductionLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;

}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:3px;
    padding:0;

}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:3px;
    padding:0;

}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left:10px;
    padding:0;

}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-left:5px;
   // margin:0;
    padding:0;

}
}

.xnongoingProductionSelect {
    @include gadgets-to(mobile) {

    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(tablet) {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(laptop) {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;

}
@include gadgets-to(desktop) {
    width: 99%;
    height: 35px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width: 99%;
    height: 40px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}
}


.xnproductionHistoryFieldset {
    @include gadgets-to(mobile) {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top: 1px;
}
@include gadgets-to(tablet) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top:10px;
    width:98%;
   // margin:0;
    padding:0;
}
@include gadgets-to(laptop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top:10px;
    width:98%;
   // margin:0;
    padding:0;


}
@include gadgets-to(desktop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top:10px;
    width:98%;
   // margin:0;
    padding:0;

}
@include gadgets-to(largeDesktop) {
    border:none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top:10px;
    width:98%;
   // margin:0;
    padding:0;

}
}

.xnproductionHistoryLegend {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:3px;
    padding:0;

}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    margin-left:3px;
    padding:0;

}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-left:10px;
    padding:0;

}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-left:5px;
   // margin:0;
    padding:0;

}
}


.xnproductionHistorySelect {
    @include gadgets-to(mobile) {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(tablet) {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;
}
@include gadgets-to(laptop) {
    width: 99%;
    height: 30px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    color:black;

}
@include gadgets-to(desktop) {
    width: 99%;
    height: 35px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width: 99%;
    height: 40px;
    margin-top: 2px;
    background-color: map-get($generalStyles, datalistbackgroundColor);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}
}




.xnproductionHeaderArea {
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    width: 100%;
    height:45vh;
    border: none;
    background-color: transparent;
    max-height:45vh;
    overflow-y: scroll;
    overflow-x: hidden;

        // smaller scrollbar
        &::-webkit-scrollbar {
            width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles, datalistBorderColor);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: map-get($generalStyles, datalistbackgroundColor);
        }
    
}
@include gadgets-to(tablet) {
    width:100%;
    height:75vh;
    border: none;
    background-color: transparent;
    max-height:75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

        // smaller scrollbar
        &::-webkit-scrollbar {
            width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles, datalistBorderColor);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: map-get($generalStyles, datalistbackgroundColor);
        }
}
@include gadgets-to(laptop) {
    width:100%;
    height:75vh;
    border: none;
    background-color: transparent;
    max-height:75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

        // smaller scrollbar
        &::-webkit-scrollbar {
            width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles, datalistBorderColor);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: map-get($generalStyles, datalistbackgroundColor);
        }
}
@include gadgets-to(desktop) {
    width:100%;
    height:75vh;
    border: none;
    background-color: transparent;
    max-height:75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

        // smaller scrollbar
        &::-webkit-scrollbar {
            width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles, datalistBorderColor);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: map-get($generalStyles, datalistbackgroundColor);
        }
}
@include gadgets-to(largeDesktop) {
    width:100%;
    height:75vh;
    border: none;
    background-color: transparent;
    max-height:75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

        // smaller scrollbar
        &::-webkit-scrollbar {
            width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: map-get($generalStyles, datalistBorderColor);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: map-get($generalStyles, datalistbackgroundColor);
        }
}
}
.xnproductionHeaderArea1{
    @extend .xnproductionHeaderArea;
    @include gadgets-to(mobile) {

    height:10vh;
    max-height:10vh;


}
@include gadgets-to(tablet) {
    height:40vh;
    max-height:40vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@include gadgets-to(laptop) {
    height:40vh;
    max-height:40vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@include gadgets-to(desktop) {
    height:40vh;
    max-height:40vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@include gadgets-to(largeDesktop) {
    height:40vh;
    max-height:40vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}

.xnproductionHeader {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 99%;
    height: 30px;
    margin-top: 5px;
   // margin-left: 5px;
    background-color: transparent;
    outline: none;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width:98%;
    height: 30px;
    margin-top: 2px;
   // margin-left: 5px;
    background-color: transparent;
    outline: none;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width:90%;
    height: 30px;
    margin-top: 5px;
  //  margin-left: 5px;
    background-color: transparent;
    outline: none;

}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width:85%;
    height: 35px;
    margin-top: 5px;
   // margin-left: 5px;
    background-color: transparent;
    outline: none;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width:80%;
    height: 40px;
    margin-top: 5px;
   // margin-left: 5px;
    background-color: transparent;
    outline: none;
}
}
.xndatarows{
    @include gadgets-to(mobile) {
display:flex;
flex-direction:row;
flex-wrap:wrap;
width:100%;

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
     flex-wrap:wrap;
    width:100%;
}
}
.xnproductionDataAreas {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: column;
 //   margin-left: 15px;
    height:30vh;
    max-height:30vh;
    width:99%;
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: column;
 //  margin-left: 15px;
    height:75vh;
    max-height:75vh;
    width:100%;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: column;
   // margin-left: 15px;
    height:75vh;
    max-height:75vh;
    width:99%;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: column;
   // margin-left: 15px;
    height:75vh;
    max-height:75vh;
    width:99%;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: column;
   // margin-left: 15px;
    height:75vh;
    max-height:75vh;
    width:99%;
}
}


.xnproductionPid {
    @include gadgets-to(mobile) {
    width:calc(12%);
    height: 25px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size:12px;
    color:black;

}
@include gadgets-to(tablet) {
    width:calc(12%);
    height: 25px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(laptop) {
    width:calc(12%);
    height: 25px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(desktop) {
    width:calc(12%);
    height: 35px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width:calc(12%);
    height: 40px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}
}

.xnproductionBenaemn {
    @include gadgets-to(mobile) {
    width:calc(34% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;

}
@include gadgets-to(tablet) {
    width:calc(34% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(laptop) {
    width:calc(34% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;

}
@include gadgets-to(desktop) {
    width:calc(34% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;

}
@include gadgets-to(largeDesktop) {
    width:calc(34% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;

}

}

.xnproductionNum {
    @include gadgets-to(mobile) {
    width:calc(12% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;

}
@include gadgets-to(tablet) {
    width:calc(12% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(laptop) {
    width:calc(12% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(desktop) {
    width:calc(12% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width:calc(12% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}

}

// .xnproductionPrice {
//     @include gadgets-to(mobile) {
//     width:calc(12% - 2px);
//     height: 25px;
//     margin-left: 1px;
//     margin-top: 2px;
//     background-color: map-get($generalStyles, transparentChangeableMenu5);
//     border: 1px solid;
//     border-color: map-get($generalStyles, datalistBorderColor);
//     border-radius: map-get($generalStyles, sixBorderRadius);
//     outline: none;
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 12px;

// }
// @include gadgets-to(tablet) {
//     width:calc(12% - 2px);
//     height: 25px;
//     margin-left: 1px;
//     margin-top: 2px;
//     background-color: map-get($generalStyles, transparentChangeableMenu5);
//     border: 1px solid;
//     border-color: map-get($generalStyles, datalistBorderColor);
//     border-radius: map-get($generalStyles, sixBorderRadius);
//     outline: none;
//     font-family: map-get($generalStyles, fontFamily);
//     font-size: 12px;
// }
// }

.xnproductionTotal {
    @include gadgets-to(mobile) {
    width:calc(16% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(tablet) {
    width:calc(16% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(laptop) {
    width:calc(16% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(desktop) {
    width:calc(16% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width:calc(16% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}
}


.xnproductionStatus {
    @include gadgets-to(mobile) {
    width:calc(18% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;

}
@include gadgets-to(tablet) {
    width:calc(18% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(laptop) {
    width:calc(18% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(desktop) {
    width:calc(18% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width:calc(18% - 2px);
    height: 40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}
}
.xnproductionDelete {
    @include gadgets-to(mobile) {
    width:calc(8% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;


}
@include gadgets-to(tablet) {
    width:calc(8% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(laptop) {
    width:calc(8% - 2px);
    height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;
}
@include gadgets-to(desktop) {
    width:calc(8% - 2px);
    height: 35px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width:calc(8% - 2px);
    height:40px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    outline: none;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    color:black;
}
}
.xnproductionDataAreas {
  @include gadgets-to(mobile) {
width:100%;
}
@include gadgets-to(tablet) {
    width:98%;
}
@include gadgets-to(laptop) {
    width:90%;

}
@include gadgets-to(desktop) {
    width:85%;
}
@include gadgets-to(largeDesktop) {
    width:80%;
}
}

.xnprodbuttontemp{
    width:12%;
    border-radius: map-get($generalStyles, sixBorderRadius);
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-bottom: 2px;
    //margin-left: 1px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    color:black;

}

.xnproduktionid {
@extend .xnprodbuttontemp;

@include gadgets-to(mobile) {
    height:25px;
    font-size: 12px;
}
@include gadgets-to(tablet) {
    height:25px;
    font-size: 14px;
}
@include gadgets-to(laptop) {
    height:30px;
    font-size: 14px;
}
@include gadgets-to(desktop) {
    height:35px;
    font-size: 16px;
}
@include gadgets-to(largeDesktop) {
    height:50px;
    font-size: 24px;
}

}

.xnproduktionid1 {
@extend .xnproduktionid;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-bottom: 2px;
}


.xnbenaemn {
    @extend .xnprodbuttontemp;
    width:calc(34% - 2px);
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor:pointer;
    
@include gadgets-to(mobile) {
    height:25px;
    font-size: 12px;
    margin-left:2px;
}
@include gadgets-to(tablet) {
    height:25px;
    font-size: 14px;
    margin-left:2px;
}
@include gadgets-to(laptop) {
    height:30px;
    font-size: 14px;
    margin-left:2px;


}
@include gadgets-to(desktop) {
    height:35px;
    font-size: 16px;
    margin-left:2px;

}
@include gadgets-to(largeDesktop) {
    height:50px;
    font-size: 24px;
    margin-left:2px;

}
}

.xnbenaemn1 {
@extend .xnbenaemn;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xnantal {
    @extend .xnprodbuttontemp;
    width:calc(12% - 2px);
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
        margin-left:2px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
        margin-left:2px;
    }
    @include gadgets-to(largeDesktop) {
        height:50px;
        font-size: 24px;
        margin-left:2px;
    }



}

.xnantal1 {
@extend .xnantal;
    background-color: map-get($generalStyles, transparentChangeableMenu5);

}


.xntotal {
    @extend .xnprodbuttontemp;
    width:calc(16% - 2px);
    margin-left:2px;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
        margin-left:2px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
        margin-left:2px;
    }
    @include gadgets-to(largeDesktop) {
        height:50px;
        font-size: 24px;
        margin-left:2px;
    }

 
    
}

.xntotal1 {
@extend .xntotal;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xnstatus {
    @extend .xnprodbuttontemp;
    width:calc(18% - 2px);
    margin-left:2px;
    padding:1px;
    cursor:pointer;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
        margin-left:2px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
        margin-left:2px;
    }
    @include gadgets-to(largeDesktop) {
        height:50px;
        font-size:24px;
        margin-left:2px;
    }


}

.xnstatus1 {
@extend .xnstatus;
    background-color: map-get($generalStyles, transparentChangeableMenu5);

}

.xndelete{
    @extend .xnprodbuttontemp;
    width:calc(8% - 2px);
    margin-left:2px;
    padding:1px;
    cursor:pointer;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
        margin-left:2px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
        margin-left:2px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
        margin-left:2px;
    }
    @include gadgets-to(largeDesktop) {
        height:50px;
        font-size:24px;
        margin-left:2px;
    }


}

.xndelete1 {
@extend .xndelete;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xndeleteArrow {
    width:calc(8% - 2px);
    color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 2px;
    cursor: pointer;
    font-size: 14px;
    margin-left:2px;

}

.xneditproductTop {
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
}

.xneditproductArea {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:100%;
    height: 60px;
   // margin-left: 3px;
    margin-top:1px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:98%;
    height: 60px;
   // margin-left: 3px;
    margin-top:1px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:90%;
    height: 60px;
   // margin-left: 3px;
    margin-top:1px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:100%;
    height: 60px;
   // margin-left: 3px;
    margin-top:1px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:100%;
    height:70px;
   // margin-left: 3px;
    margin-top:1px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
}

.xnplussign {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 50px;
    margin-top: 8px;
    margin-left: 10px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
}


.xnminussign {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 30px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamily);
    font-size: 50px;
    margin-top: 6px;
    margin-left: 5px;
    opacity: 0.5;
    color: map-get($generalStyles, pinkRed);
}
}

.xnAiOutlinePlusCircle {
    @include gadgets-to(mobile) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(tablet) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(laptop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(desktop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 60px;
    cursor: pointer;
}
}


.xnAiOutlineMinusCircle {
    @include gadgets-to(mobile) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(tablet) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(laptop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(desktop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 45px;
    cursor: pointer;
}
@include gadgets-to(largeDesktop) {
    color: map-get($generalStyles, transparentChangeableMenu5);
    font-size: 60px;
    cursor: pointer;
}
}


.xnproductionbuttonTop {
    width: 88%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 27px;
}

.xnsaveproductionButton {
    width: 70px;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 5px;
    margin-top: 4px;
    cursor: pointer;
    opacity: 0.5;
}

.xnsaveproductionButtonActive {
    width: 70px;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: black;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 5px;
    margin-top: 4px;
    cursor: pointer;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
}

.xnsaveproductionButtonIcon {
    color: white;
    font-size: 26px;
    cursor: pointer;
    margin-left: 1px;
    opacity: 1;
}

.xnsaveproductionButtonIconActive {
    color: white;
    font-size: 26px;
    cursor: pointer;
    margin-left: 1px;
}



.xnsaveproductionMenuTop {
    @include gadgets-to(mobile) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
@include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
}

.xnsaveProductionMenuFieldset {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 90%;
    height: 165px;
    background-color: transparent;
    margin-top: 5px;
    margin-left: 4px;
}

.xnsaveProductionMenuLegend {
    font-size: 12px;
    font-family: map-get($generalStyles, fontFamily);
    color: map-get($generalStyles, colorblack);
    margin-left: 10px;
    margin-top: 10px;
}

.xnsaveProductionMenuArea {
    @include    gadgets-to(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:95%;
    height: 175px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:90%;
    height: 175px;
    border-radius: map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:85%;
    height: 175px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:80%;
    height: 175px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    width:75%;
    height: 175px;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
}

.xnsaveProductionMenuInput2 {
    width:91%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 10px;
    margin-left: 2px;
    outline: none;
    border-radius: map-get($generalStyles,threeBorderRadius);
}

.xnsaveProductionMenuInputJAP {
    width: 91%;
    height: 25px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 10px;
    margin-left: 2px;
    outline: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
}

.xnsaveProductionMenuInputFurigana {
    width: 91%;
    height: 15px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    margin-top: 2px;
    margin-left: 2px;
    outline: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
}

.xnsaveproductMenuButtonActive {
    width: 92.5%;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 2px;
    margin-top: 10px;
    cursor: pointer;
    opacity: 1;
}

.xnsaveproductMenuButtonActive1 {
    width: 92%;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 1px;
    margin-top: 7px;
    cursor: pointer;
    opacity: 1;
}

.xnsaveproductMenuButtonNotActive {
    width: 92.5%;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 1px;
    margin-top: 10px;
    cursor: not-allowed;
    opacity: 0.5;
}

.xnsaveproductMenuButtonKlarskriv {
    width: 92%;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 11px;
    margin-top: 7px;
    cursor: pointer;
    opacity: 1;
    margin-right: 10px;
}

.xnsaveproductMenuButtonKlarskriv1 {
    width: 92%;
    height: 30px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    font-family: map-get($generalStyles, fontFamily);
    font-size: 12px;
    border-radius: 3px;
    margin-left: 11px;
    margin-top: 7px;
    cursor: pointer;
    opacity: 0.5;
    margin-right: 10px;
}

.xnstatusheaderMain {
    margin-top: 5px;
    width:100%;
}

.xnstatusHeaderFieldset {
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    width:100%;
    height:30vh;
   // margin-left: 10px;
    background-color: transparent;
    margin-top: 2px;
   // margin-left: 1px;
}

.xnstatusHeaderLegend {
    font-size: 12px;
    font-family: map-get($generalStyles, fontFamily);
    color: map-get($generalStyles, colorblack);
  //  margin-left: 30px;
    margin-top: 10px;
}

.xnstatusheaderHeader {
@include gadgets-to(mobile) {

 width:99%;
 margin-bottom:2px;
}
@include gadgets-to(tablet) {
    margin-top:5px;
    width:96%;
    margin-bottom:2px;
}
@include gadgets-to(laptop) {
    width:99%;
    margin-bottom:2px;
}
@include gadgets-to(desktop) {
    width:99%;
    margin-bottom:2px;
}
@include gadgets-to(largeDesktop) {
    width:99%;
    margin-bottom:2px;
}
}

.xnstatusheaderData {

  height:23vh;
    max-height:23vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width:99%;
    //small scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles, datalistBorderColor);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: map-get($generalStyles, datalistbackgroundColor);
    }
    @include gadgets-to(mobile) {
        height:23vh;
        max-height:23vh;
        overflow-y: scroll;
        overflow-x: hidden;
        width:99%;
    }
    @include gadgets-to(tablet) {
        height:70vh;
        max-height:70vh;
        overflow-y: scroll;
        overflow-x: hidden;
        width:96%;
    }

}


.xnstatusButton{
    height: 30px;
   // margin-left: 1px;
    margin-bottom: 2px;
    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    color:black;
}


.xnproductionStatisticTop {
    width:100%;
    margin-top:3px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
}

.xnstatusheaderProduktionsid{
    @extend .xnstatusButton;
    width:20%;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
        color:black;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
        color:black;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
        color:black;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:35px;
        font-size:18px;
    }
}
.xnstatusheaderBenaemn{
    @extend .xnstatusButton;
    width:calc(40% - 2px);
    margin-left:2px;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:35px;
        font-size:18px;
    }

}
.xnstatusheaderSum{
    @extend .xnstatusButton;
    width:calc(20% - 2px);
    margin-left:2px;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:35px;
        font-size:18px;
    }

}
.xnstatusheaderNum{
    @extend .xnstatusButton;
    width:calc(20% - 2px);
    margin-left:2px;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:35px;
        font-size:18px;
    }


}
.xnsamproduktionid{
    @extend .xnstatusButton;
    width:20%;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;
    }

 

}
.xnsamproduktionid1{
    @extend .xnstatusButton;
    width:20%;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;
    }
    
    
}

.xnsambenaemn{
    @extend .xnstatusButton;
    width:calc(40% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;
    }

}
.xnsambenaemn1{
    @extend .xnstatusButton;
    width:calc(40% - 2px);
    margin-left:2px;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;
    }

    
}
.xnsamantal{
    @extend .xnstatusButton;
    width:calc(20% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;
    }

}
.xnsamantal1{
    @extend .xnstatusButton;
    width:calc(20% - 2px);
    margin-left:2px;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;
    }
    
}
.xnsamtotantal {
    @extend .xnstatusButton;
    width:calc(20% - 2px);
    margin-left:2px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;

}
}

.xnsamtotantal1 {
    @extend .xnstatusButton;
    width:calc(20% - 2px);
    margin-left:2px;
    @include gadgets-to(mobile) {
        height:25px;
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        height:25px;
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        height:30px;
        font-size: 14px;
    }
    @include gadgets-to(desktop) {
        height:35px;
        font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
        height:40px;
        font-size:24px;
  
}
}




.xnprodNewSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xnprodNewSpinner {
    background-color: transparent;
}

.xnbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }
.xnchangevalueWrapper{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;

}
  
  .xncreateProductionAnimatedDiv2{
 width:100%;
    transform:rotate(0deg);
    animation-name: identifier67;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    
      }

      @keyframes identifier67 {
        0%{height:0px}
        100%{height:67px}
        }

@include gadgets-to(mobile) {
    @keyframes identifier67 {
        0%{height:0px}
        100%{height:67px}
        }

          }
@include gadgets-to(tablet) {
    @keyframes identifier67 {
        0%{height:0px}
        100%{height:67px}
        }

          }
@include gadgets-to(laptop) {
    @keyframes identifier67 {
        0%{height:0px}
        100%{height:67px}
        }

          }
@include gadgets-to(desktop) {
    @keyframes identifier67 {
        0%{height:0px}
        100%{height:67px}
        }

          }
@include gadgets-to(largeDesktop) {
    @keyframes identifier67 {
        0%{height:0px}
        100%{height:77px}
        }

          }








.xnareaWrapper{
    @include   gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    align-items:center;
    flex-wrap:nowrap;
    width:100%;

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
   // align-items:center;
    justify-content:center;
    flex:1;
    width:100%;


}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
   // align-items:center;
    justify-content:center;
    width:100%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
   // align-items:center;
    justify-content:center;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
   // align-items:center;
    justify-content:center;
    width:100%;
}
}

.xnsaveWrapper{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    width:95%;
    margin-left:5px;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:90%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:85%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:80%;

}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:75%;

}
}

.xnsavemenuName{
    @include gadgets-to(mobile) {

    font-family: map-get($generalStyles, fontFamily);
    font-size: 14px;
}
@include gadgets-to(tablet) {

    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top:3px;
    margin-left:5px;
}
@include gadgets-to(laptop) {

    font-family: map-get($generalStyles, fontFamily);
    font-size: 16px;
    margin-top:3px;
    margin-left:5px;
}
@include gadgets-to(desktop) {

    font-family: map-get($generalStyles, fontFamily);
    font-size: 18px;
    margin-top:10px;
    margin-left:5px;
}
@include gadgets-to(largeDesktop) {

    font-family: map-get($generalStyles, fontFamily);
    font-size: 20px;
    margin-top:10px;
    margin-left:5px;
}
}

.xnabcwrapper{
    display: flex;
    flex-wrap:wrap;
    width:100%;

}
.xncreateproductionWrapper{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-end;
    width:100%;
    height:2vh;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
   // align-items:center;
    justify-content:flex-end;
    width:100%;
  
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
   // align-items:center;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
   // align-items:center;
    justify-content:flex-end;
    width:100%;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
  //  align-items:center;
    justify-content:flex-end;
    width:100%;
}
}
.xncreatenewItem{
    @include gadgets-to(mobile) {
    font-size:24px;
    color: map-get($generalStyles,transparentChangeableMenu5);
    margin-right:5px;
    cursor:pointer;
}
@include gadgets-to(tablet) {
    font-size:30px;
    color: map-get($generalStyles,transparentChangeableMenu5);
    margin-right:10px;
    cursor:pointer;
}
@include gadgets-to(laptop) {
    font-size:30px;
    color: map-get($generalStyles,transparentChangeableMenu5);
    margin-right:10px;
    cursor:pointer;
}
@include gadgets-to(desktop) {
    font-size:30px;
    color: map-get($generalStyles,transparentChangeableMenu5);
    margin-right:10px;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    font-size:40px;
    color: map-get($generalStyles,transparentChangeableMenu5);
    margin-right:10px;
    cursor:pointer;
}
}

.xncreatenewItem1{
    @extend .xncreatenewItem;
    color:black;
    margin-right:10px;
    cursor:pointer;
}

