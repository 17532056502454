@import '../style/MainStyles.scss';




.xxtotWrapper{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:column;
  //  justify-content:center;
    // align-items:center;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
     height:90vh;
     width:100%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
     height:80vh;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
     align-items:center;
}
}
.xxaTopArea{
    @include gadgets-to(mobile) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
       // height: 10%;
        margin-top: 2px;
    }

    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10%;
        margin-top: 2px;
    }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10%;
        margin-top: 2px;
    }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10%;
        margin-top: 2px;
    }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10%;
        margin-top: 2px;
    }
}
.xxatotalArea {
   // position: relative;
    @include gadgets-to(mobile) {
        display: grid;
       grid-template-rows:4% 4% 20% 71%;
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            ;
    //    display:flex;
    //    flex-direction:column;
    //    justify-content:center;
    //    align-items:center;
        width: 100%;
        height:100vh;
       object-fit: cover;
        background-color:transparent;

        @media(min-width:360px) and (max-width:375px){
            grid-template-rows: 3% 3% 22% 72%;
            grid-template-rows: auto auto auto auto;
         
         }
        @media(min-width:410px) and (max-width:413px){
           grid-template-rows: 3% 3% 19% 72%;
        
        }
        @media(min-width:414px) and (max-width:420px){
            grid-template-rows: 3% 3% 20% 72%;
         
         }
    
        @media(min-width:428px){
           grid-template-rows: 4% 4% 15% 72%;
        
        }
        @media(min-width:430px){
            grid-template-rows: 4% 4% 17% 72%;
         
         }



    }

        @include gadgets-to(tablet) {
            display: grid;
            grid-template-columns: 35% 65%; // Adjust as needed
            grid-template-rows: auto auto;
            grid-template-areas:
              "a a"
              "b b"
              "c d";
            column-gap: 7px;
            width: 90%;
            height: 100%;
            object-fit: cover;

     
          }
          @include gadgets-to(laptop) {
            display: grid;
            grid-template-columns:25% 75%;
            grid-template-rows: auto auto;
            grid-template-areas:
              "a a"
              "b b"
              "c d";
              column-gap:7px;
            width: 70%;
            height: 100%;
            object-fit: cover;
        
        }
        @include gadgets-to(desktop) {
            display: grid;
            grid-template-columns:25% 65%;
            grid-template-rows: auto auto;
            grid-template-areas:
              "a a"
              "b b"
              "c d";
              column-gap:7px;
            width: 70%;
            height: 100%;
            object-fit: cover;
        
        }
       
    
        @include gadgets-to(largeDesktop) {
            display: grid;
            grid-template-columns:25% 75%;
            grid-template-rows: auto auto;
            grid-template-areas:
              "a a"
              "b b"
              "c d";
              column-gap:7px;
            width: 60%;
            height: 100%;
            object-fit: cover;
        
        }
}

.xxatotalArea1 {
    @extend .xxatotalArea;
   // background-color: map-get($generalStyles, backgroundColor);
      background-color:white;
}


.areaA {
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction: row;
    justify-content: center;
    grid-area: c;
 //   border: 1px solid map-get($generalStyles,datalistBorderColor);
    width: 100%;
  height:23.3vh;
  margin-top:1vh;
   background-color: map-get($generalStyles,transparentChangeable);


}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction: row;
    // justify-content: center;
    grid-area: c;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    width: 100%;
    height:90vh;
    background-color: map-get($generalStyles,transparentChangeable);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:2px;

    @media(min-width:1170px) and (max-width:1195px)
    {
        height:90vh;
    }
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: row;
    // justify-content: center;
    grid-area: c;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    width: 100%;
    height:80vh;
    background-color: map-get($generalStyles,transparentChangeable);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:2px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    // justify-content: center;
    grid-area: c;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    width: 100%;
    height:80vh;
    background-color: map-get($generalStyles,transparentChangeable);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:2px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    // justify-content: center;
    grid-area: c;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    width: 100%;
    height:80vh;
    background-color: map-get($generalStyles,transparentChangeable);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:2px;
}
}



.areaB {
    @extend .areaA;
    display:flex;
    flex-direction: row;
    grid-area: d;
    @include gadgets-to(mobile) {
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
     margin-top:1px;
     height:90vh;
     min-height:1vh;
     max-height:90vh;
     overflow-y:auto;
     // small webkit scrollbar  
        &::-webkit-scrollbar {
            display:none;
        }

 
     @media(min-width:428px){
        height:72vh;
        max-height:72vh;
    
    }


     &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }

  
   
    }
    @include gadgets-to(tablet) {
        height:90vh;
        display:flex;
        flex-direction:column;
      //  justify-content:center;
        align-items:center;

       
    @media(min-width:1170px) and (max-width:1195px)
    {
        height:90vh;
    }
  
 
    } 
    @include gadgets-to(laptop) {
        height:80vh;
        display:flex;
        flex-direction:column;
      //  justify-content:center;
          align-items:center;
          background-color: white;
    }
    @include gadgets-to(desktop) {
      
        height:80vh;
        display:flex;
        flex-direction:column;
      //  justify-content:center;
         align-items:center;
    }
    @include gadgets-to(largeDesktop) {
     
        height:80vh;
        display:flex;
        flex-direction:column;
      //  justify-content:center;
          align-items:center;
    }

}
.menuTopWrapper{
    @include gadgets-to(mobile) {
        width:auto;
        height:20vh;
        margin-top:5px;
    }
    @include gadgets-to(tablet) {
    width:100%;
    height:auto;
    margin-top:5px;
}
@include gadgets-to(laptop) {
    width:100%;
    height:75vh;
    max-height:75vh;

    margin-top:5px;
}
@include gadgets-to(desktop) {
    width:100%;
    height:auto;
    margin-top:5px;
}
@include gadgets-to(largeDesktop) {
    width:100%;
    height:auto;
    margin-top:5px;
}
}


.areaStyleBox {
border:1px solid;
border-color: map-get($generalStyles,datalistBorderColor);


@media(max-width: 575.98px){
    border:none;
}


@media(min-width:834px  ){
    border:2px solid;
    margin-left:1%;
    width:48%;
    height:68%;
    margin-top:1%;
    border-radius: map-get($generalStyles,sixBorderRadius);
    border-color:map-get($generalStyles,datalistBorderColor);
}

@media(min-width: 1200px){
    margin-left:1%;
    width:50%;
    height:65%;
    margin-top:1%;
    border-radius: map-get($generalStyles,sixBorderRadius);
    border:2px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
}

    
}

.xxaheaderArea{
    @include gadgets-to(mobile) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-end;
        width:99%;
        margin-top:2px;
        height:30px;
    }
    @include gadgets-to(tablet) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:35px;
        @media(orientation:portrait){
            height:40px;
        }
    }
    @include gadgets-to(laptop) {
        grid-area:a;
        // display:flex;
        // flex-direction:column;
      //  justify-content:center;
        width:99%;
        margin-top:2px;
        height:40px;
    }
    @include gadgets-to(desktop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:60px;
    }
    @include gadgets-to(largeDesktop) {
        grid-area:a;
        display:flex;
        flex-direction:row;
        justify-content:center;
        width:99%;
        margin-top:2px;
        height:60px;
    }

}

.xxacompName {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 24px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size:36px;
        color:map-get($generalStyles,transparentChangeableMenu5);
   
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 38px;
        color: map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 44px;
        color: map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-size: 46px;
        color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
.xxacompName1{
    @extend .xxacompName;
    color:map-get($generalStyles,transparentChangeableMenu5);
}

.xxacompNameJAP {
    @include gadgets-to(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 24px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
    @include gadgets-to(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size:map-get($generalStyles,tabletHeaderFontsize);
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(laptop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 38px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 44px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
@include gadgets-to(largeDesktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family);
        font-size: 46px;
        color:map-get($generalStyles,transparentChangeableMenu5);
    }
}

.xxarangeArea{
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:flex-start;
        height:3vh;
     //   margin-top:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:20px;
         }
         // as ipad
        //  @media(min-width:1171px) and (max-width:1195px)
        //  {
         
        //     height:auto;
        //  }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:20px;
         }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:2vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}
.xxarangeAreaTextWrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:2vh;
    width:100%;
}
.xxarangeAreaText{
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    color: map-get($generalStyles,colorblack);
    margin-right: 2%;
}

.xxarange1 {
    @include gadgets-to(mobile) {
        margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-top:1vh;
    }
    @include gadgets-to(tablet) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        margin-right:2%;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(desktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
@include gadgets-to(largeDesktop) {
      //  margin-top:3%;
        accent-color: blue;
        width:125px;
        margin-left:10px;
        cursor:pointer;
    }
}

.xxarange2 {
    @extend .xxarange1;
    @include gadgets-to(mobile) {
        accent-color: rgb(251, 239, 132);
        width:125px;
       // margin-top:1vh;

    }
    @include gadgets-to(tablet) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
@include gadgets-to(laptop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        accent-color: rgb(251, 239, 132);
        width:125px;
        cursor:pointer;
    }
}




.settingsSpinnerContainer {
    position: absolute;
    top: 122px;
    left: 970px;
    background-color: transparent;
    transform: scale(0.8);
}

.settingsSpinner {
    background-color: transparent;
}
.menuTopWrapper{
    @include gadgets-to(tablet) {
        
    display:flex;
    flex-direction:column;
 //   justify-content:center;
    align-items:center;
    width:100%;
    height:90vh;
    margin-top:2px;
    }

    @include gadgets-to(laptop) {

    display:flex;
    flex-direction:column;
 //   justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    margin-top:2px;
}
    @include gadgets-to(desktop) {

    display:flex;
    flex-direction:column;
 //   justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    margin-top:2px;
}
    @include gadgets-to(largeDesktop) {

    display:flex;
    flex-direction:column;
 //   justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    margin-top:2px;
}
}


.menuTop {
    @include gadgets-to(mobile) {

    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
   // align-items: center;
   // margin-top: 10%;
  //  height: 20%;
 // height:100%;
    width:99%;
    padding-top:2px;
    // @media(min-width:360px) and (max-width:365px){
    //     height: 60%;
    
    // }
    @media(max-width:370px){
        height: 42%;
    
    }
    @media(min-width:410px) and (max-width:420px){
        height: 42%;
    
    }
    @media(min-width:428px){
        height: 50%;
    
    }
  
}
@include gadgets-to(tablet) {
    display: flex;
    flex-direction:column;
 //   flex-wrap:wrap;
  //  justify-content:center;
    align-items: center;
    height:90vh;
    width:95%;
    padding-top:1.7vh;
}
@include gadgets-to(laptop) {
    display: flex;
    flex-direction:column;
  //  flex-wrap:wrap;
   // justify-content:center;
    align-items: center;
    height:80vh;
    width:95%;
    padding-top:2.7vh;
}
@include gadgets-to(desktop) {
    display: flex;
    flex-direction:column;
  //  flex-wrap:wrap;
   // justify-content:center;
    align-items: center;
  
    height:80vh;
    width:95%;
    padding-top:2vh;
}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction:column;
  //  flex-wrap:wrap;
   // justify-content:center;
    align-items: center;
 
    height:80vh;
    width:95%;
    padding-top:2vh;
}
}



.menupersonal {
    @include gadgets-to(mobile) {

  flex:1;
  flex-grow: 1;
   // width:30%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 13px;
    transition: 0.3s;
    width:auto;
    height:35px;
    white-space: nowrap;
  //  margin-top:3px;
    border:none;
    margin-left:2px;
    margin-bottom:2px;
    margin-left:2px;
    user-select: none;
    @media(min-width:360px) and (max-width:376px){
        height:33px;
    
    }

}
@include gadgets-to(tablet) {
  //  flex:1;
    width:80%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 16px;
    transition: 0.3s;
    //width:auto;
    height:40px;
    white-space: nowrap;
    margin-top:3px;
    border:none;
    margin-left:2px;
    margin-bottom:2px;
    margin-left:2px;
    user-select: none;
}
@include gadgets-to(laptop) {
   // flex:1;
    width:100%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 14px;
    transition: 0.3s;
 //   width:auto;
    height:40px;
    white-space: nowrap;
    margin-top:3px;
    border:none;
    margin-left:2px;
    margin-bottom:2px;
    margin-left:2px;
    user-select: none;
}
@include gadgets-to(desktop) {
    //flex:1;
    width:100%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 16px;
    font-weight:200;
    transition: 0.3s;
    //width:auto;
    height:50px;
    white-space: nowrap;
    margin-top:3px;
    border:none;
    margin-left:2px;
    margin-bottom:2px;
    margin-left:2px;
    user-select: none;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
@include gadgets-to(largeDesktop) {
    //flex:1;
    width:100%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 14px;
    transition: 0.3s;
    //width:auto;
    height:50px;
    white-space: nowrap;
    margin-top:3px;
    border:none;
    margin-left:2px;
    margin-bottom:2px;
    margin-left:2px;
    user-select: none;
}
}


.menupersonal1 {
    @extend .menupersonal;
    @include gadgets-to(mobile) {
   
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles,colorwhite);
    border: none;

}
@include gadgets-to(tablet) {

    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles,colorwhite);
    border: none;

}
@include gadgets-to(laptop) {
 
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles,colorwhite);

 
}
@include gadgets-to(desktop) {
 
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles,colorwhite);
    border: none;
}
@include gadgets-to(largeDesktop) {
 
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: map-get($generalStyles,colorwhite);
    border: none;
}
}


.adressinfoTop {
    display: flex;
    flex-direction: column;
   // justify-content: center;
    align-items: center;
    margin-top:2vh;
    width:100%;

}
.xxaInfoTop{
display:flex;
flex-direction:column;
//justify-content:center;
align-items:center;
width:90%;
height:90vh;
@include gadgets-to(mobile) {
    width:98%;
    height:80vh;
}
}


.xxaInfoWrapper{
  display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    margin-top:2vh;
    width:100%;
    @include gadgets-to(mobile) {
margin-top:1px;
    }

}
.xxaadressinfoHeaderWrapper{
   display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:2vh;
    width:100%;
    @include gadgets-to(mobile) {
        margin-top:1px;
    }
}
.xxaadressinfoHeaderText{
    font-family:var(--font-family);
    font-size: 18px;
    font-weight: 300;
    color:black;
    margin-bottom:5px;;

}
.xxainfoSurround{
width:100%;
border-radius: 12px;
box-shadow: 12px 12px 12px 6px rgba(0, 0,0, .2);
background-color: white;
border-radius: 12px;
border:1px solid map-get($generalStyles,datalistBorderColor);


height:75vh;
max-height:75vh;
overflow-y:auto;
//webkit scrollbar
&::-webkit-scrollbar {
    width: 10px;
}
//track
&::-webkit-scrollbar-track {
    background: map-get($generalStyles,transparentChangeableMenu4);
}
//handle
&::-webkit-scrollbar-thumb {
    background: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: 10px;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(mobile) {
    width:100%;
    height:75vh;
    max-height:75vh;
    overflow-y:auto;
  //  margin-top:2vh;
    box-shadow: 12px 12px 12px 6px rgba(0, 0,0, .2);
    background-color: white;
    border-radius: 12px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    //webkit scrollbar
    &::-webkit-scrollbar {
        display:none;
    }
}

@include gadgets-to(laptop) {
    width:100%;
    height:65vh;
    max-height:65vh;
    overflow-y:auto;
  //  margin-top:2vh;
    box-shadow: 12px 12px 12px 6px rgba(0, 0,0, .2);
    background-color: white;
    border-radius: 12px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    //track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    //handle
    &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(desktop) {
    width:100%;
    height:72vh;
    max-height:72vh;
    overflow-y:auto;
  //  margin-top:2vh;
    box-shadow: 12px 12px 12px 6px rgba(0, 0,0, .2);
    background-color: white;
    border-radius: 12px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    //track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    //handle
    &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(largeDesktop) {
    width:100%;
    height:70vh;
    max-height:70vh;
    overflow-y:auto;
  //  margin-top:2vh;
    box-shadow: 12px 12px 12px 6px rgba(0, 0,0, .2);
    background-color: white;
    border-radius: 12px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    //webkit scrollbar
    &::-webkit-scrollbar {
        width: 10px;
    }
    //track
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    //handle
    &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}

}

.xxaInfoType{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    font-family:var(--font-family);
    font-size: 12px;
    font-weight:200;
    color:grey;
    margin-right:5px;
    width:80%;
    @include gadgets-to(mobile) {
        font-size: 10px;
        width:80%;
    }
    @include gadgets-to(tablet) {
        font-size: 12px;
        width:80%;
    }
    @include gadgets-to(laptop) {
        font-size: 14px;
        width:80%;
    }
    @include gadgets-to(desktop) {
        font-size: 16px;
        width:80%;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 18px;
        width:80%;
    }

}
.xxaInfoRow{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:60%;
   // border:1px solid red;
@include gadgets-to(mobile) {
    width:90%;
}
}

.xxaInfoRowWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    
}
.xxaInputWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}
.xxaInfoInput{
    width:80%;
    height:20px;
    font-size: 16px;
    font-family: var(--font-family);
    border-radius: 6px;
    padding: 2px;
    color:black;
    outline:none;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border:1px solid map-get($generalStyles,datalistBorderColor);
    padding-top:3px;
    margin-top:2px;
    @include gadgets-to(mobile) {
        width:80%;
        height:23px;
    }
    @include gadgets-to(tablet) {
        padding-top:2px;
        margin-top:1px;
    }
    @include gadgets-to(desktop) {
      height:25px;
    }
    @include gadgets-to(largeDesktop) {
      height:25px;
    }

    @media(min-width:1200px){
        padding-top:5px;
        margin-top:2px;
        height:25px;
    }

}
.xxaInfoInput1{
    @extend .xxaInfoInput;
    background-color:#d4cfcf;
}
// .adressinfoTop {
//     display: flex;
//     flex-direction: column;
   
// // justify-content: center;
//     margin-top: 1%;
//     height: 30%;
//     @include gadgets-to(tablet) {
//         width:50%;
//     }

    
//      @media(max-width: 575.98px){
   
//         margin-top: 1vh;
//         height:52vh;
//         max-height:52vh;
//         padding-right:10px;
    
//        }




//     .row {
//         @media(max-width: 575.98px){
//             display: grid;
//             grid-template-columns: 25% 75%;
//             gap: 3px;
//         }
  
//         @media(min-width:834px){
//             display: grid;
//             grid-template-columns: 25% 75%;
//             gap: 3px;
//             margin:2px;
//         }
//         @media(min-width: 1440px) {  
//             display: grid;
//             grid-template-columns: 25% 75%;
//             gap: 3px;
//             margin:1px;
//         }
       

    
//         .column-a {
//             display: flex;
//             align-items: flex-end;
//             justify-content:flex-end;

//             @media (min-width: 1200px) {  
//                 align-items: flex-end;
//                 justify-content:flex-end;
// }



//         }
    
//         .column-b {
//             display: flex;
//             align-items: center;
//             width:200px;
//             @include gadgets-to(mobile) {
//                 width:220px;
//             }
//         }
//     }

  


// }



// .input {
//     width:100%;
//     height:22px;
//     font-size: 13px;
//     font-family: map-get($generalStyles,fontFamily);
//     border-radius: 6px;
//    // padding: 3px;
//     color:map-get($generalStyles,colorBlack);
//     outline:none;
//     //border:1px solid map-get($generalStyles,datalistBorderColor);
//     border:1px solid;
//     border-color:map-get($generalStyles,datalistBorderColor);
//     padding-top:6px;
//     margin-top:2px;

// }

// .lblinputname{
//     font-size: 12px;
//     font-family: map-get($generalStyles,fontFamily);
//     border-radius: 6px;
//     padding: 3px;
//     color: map-get($generalStyles,colorBlack);
//     padding-top:5px;
//     white-space: nowrap;


// }

.saveAddressInfoButton{
    font-size: 14px;
    font-family: map-get($generalStyles,fontFamily);
    border-radius: 6px;
    padding: 3px;
    color:black;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
   // margin-left: 1%;
    margin-right: 1%;
    width:82%;
    height: 30px;
    margin-top: 1%;
    margin-bottom: 1%;
    //hover
    &:hover {
        background-color: map-get($generalStyles,pinkRed);
        color:white;
        border:2px solid white;
        box-shadow:6px 6px 12px 6px rgba(0, 0,0, .2);
        outline:auto;
    }



    @media (max-width: 575.98px) {
        font-size: 12px;
        width:100%;
        height: 30px;
      
    }
    @include gadgets-to(desktop) {
       height:35px;
    }
    @include gadgets-to(largeDesktop) {
     height:35px;
    }
    @include gadgets-to(mobile) {
        height:35px;
        width:82%;
        margin-top:5px;
    }

}
.abonnemangTop{
   display:flex;
    flex-direction: row;
  //  border:1px solid red;
  
    @media(max-width: 575.98px){
        display:flex;
        flex-direction: column;
    //    border:1px solid red;
        width:99%;
        height:45vh;
    }
    @media(min-width:834px){
        display:flex;
        flex-direction: row;
    //    border:1px solid red;
        width:99%;
        height:65vh;
    }

    @media(min-width:1200px){
        width:100%;
        height:65vh;
    }
}

   



.abonnemangBas{

@media(max-width: 575.98px){
    width:100%;
    height:33%;
}

@media(min-width:834px){
    width:33%;
    height:33%;
}
@media(min-width:1200px){
    width:31%;
   // height:33%;
}

.abonnemangTotal{

@media(max-width: 575.98px){
    width:100%;
    height:33%;
}
@media(min-width:834px){
    width:33%;
    height:30%;
}

}
@media(min-width:1200px){
    width:30%;
   // height:33%;
}
}

.abonnemangAI{
    width:30%;
    @media(max-width: 575.98px){
        width:100%;
        height:33%;
    }
    @media(min-width:834px){
        width:30%;
        height:33%;
    }
    @media(min-width:1200px){
        width:30%;
        height:33%;
    }
    

}

.abonnemangFieldset{
    width:90%;
    height:80vh;
    font-family: map-get($generalStyles, fontFamilyTimes);
    font-size: 20px;
  // border:1px solid green;
  border:none;

    @media(max-width: 575.98px){
       width:100%;
       height:100%;
       font-family: map-get($generalStyles, fontFamily);
       font-size: 14px;
    }
    @media(min-width:834px){
        width:100%;
        height:54vh;
        margin-top: 2vh;
       // margin-left: 1vh;
        //border:none;
    }

    @media(min-width:1200px){
        width:100%;
        height:62vh;
        margin-top: 4vh;
        margin-left: 1vh;
        
    }
}



.abonnemangHeader{
    font-size: 20px;
    font-family: map-get($generalStyles,fontFamily);
    margin-top: 1vh;
    margin-left: 1vh;
    @media(max-width: 575.98px){
        font-size: 14px;
        font-family: map-get($generalStyles,fontFamily);
        margin-top: 1vh;
        margin-left: 1vh;
    }
    @media(min-width:834px){
        font-size: 18px;
        font-family: map-get($generalStyles,fontFamily);
        margin-top: 1vh;
        margin-left: 5vh;
    }
   @media(min-width:1200px){
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    font-family: map-get($generalStyles,fontFamily);
    margin-top: 1vh;
   // margin-left:2vh;
   }
}

.listWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.listArea{

    @include gadgets-to(mobile) {
        font-family: map-get($generalStyles, fontFamily);
        font-size: 14px;
       padding:0;
       margin:0;
     
    }

 

    }

.listRow{
    font-size: 12px;
    @media(max-width: 575.98px){
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
    }
    @media(min-width:834px){
        font-family: map-get($generalStyles, fontFamily);
        font-size: 12px;
        margin-top:2px;
        text-align:left;
        
    
    }
    @media(min-width: 1200px){
    font-family: map-get($generalStyles, fontFamily);
        font-size: 15px;
        margin-top:4px;
        

        
        
    }
}






.abonnemangSaveButtonContainer{
    
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    margin-left: 1vh;
    margin-right: 1vh;

    @include gadgets-to(mobile) {
       margin-bottom:1vh;
    }

}
.abonnemangSaveButton{

    font-family: map-get($generalStyles,fontFamily);
    border-radius: 6px;
    padding: 3px;
    color:black;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
    height:30px;
    width:100px;



}

.abonnemangCheck{

    @media(max-width: 575.98px){

    }
    @media(min-width:834px){
        margin-left:5vh;
   
    }
    @media(min-width: 1200px){
    transform:scale(1.5)
    }
}

.labelWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.checkLabel{
    font-size: 14px;
    font-family: map-get($generalStyles,fontFamily);
    margin-left: 1vh;
    @media(max-width: 575.98px){
        font-size: 14px;
        font-family: map-get($generalStyles,fontFamily);
        margin-left: 1vh;
    }
    @media(min-width:834px){
        font-size: 10px;
        font-family: map-get($generalStyles,fontFamily);
        margin-left: 1vh;
        text-align:right;
    }
    @media(min-width: 1200px){
        font-size: 14px;
        font-family: map-get($generalStyles,fontFamily);
        margin-left: 1vh;
    }
}

.abonnemangPrisContainer{


    @media(min-width:834px){
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1vh;
        margin-left: 4vh;
 
    }
    @media(min-width:1200px){
        display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-left: 4vh;

    }


}
.abonnemangPris{
    font-size: 14px;
    font-family: map-get($generalStyles,fontFamily);
    margin-left: 1vh;
    @media(max-width: 575.98px){
        font-size: 14px;
        font-family: map-get($generalStyles,fontFamily);
        margin-left: 1vh;
    }
    @media(min-width:834px){
        font-size: 12px;
        font-family: map-get($generalStyles,fontFamily);
        margin-left: 1vh;
        text-align:right;
    }
    @media(min-width: 1200px){
        font-size: 14px;
        font-family: map-get($generalStyles,fontFamily);
       // margin-left: 1vh;
    }
}
.mobilAbonnemangTop{
width:100%;
}
.mobileTabTop{
    grid-area: b;
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:4vh;
    width:100%;
   // border:1px solid red;
    font-family: map-get($generalStyles,fontFamily);
    font-size:12px;
   // background-color: map-get($generalStyles,transparentChangeableMenu4);
     background:white;
}

.mobileTab{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:4vh;
    width:100%;
    border:none;
    cursor:pointer;
    border-top-right-radius:10px;
    border-top-left-radius:10px;
    margin-left:-10px;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  //  margin-left:10px;
    cursor:pointer;


    }
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:4vh;
    width:100%;
    border:none;
    cursor:pointer;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
   // margin-left:1px;
   // margin-right:1px;
   margin-right:-10px;
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius:20px;
    border-top-left-radius:20px;
    cursor:pointer;

    }
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:3vh;
    width:100%;
    border:none;
    cursor:pointer;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor:pointer;

    }
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:3vh;
    width:100%;
    border:none;
    cursor:pointer;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left:-10px;
    cursor:pointer;

    }
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:3vh;
    width:100%;
    border:none;
    cursor:pointer;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor:pointer;

    }
}
}



.firstButton {
    border-top-left-radius:map-get($generalStyles,sixBorderRadius);
   // border-top-right-radius: 12px;
  
}

.lastButton {
    border-top-right-radius:map-get($generalStyles,sixBorderRadius);
 //   border-top-right-radius: 12px;
}


.mobileTabContent{
    display:flex;
    flex-direction: column;
    align-items: center;
    height:60vh;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 15px;
    background-color: map-get($generalStyles,transparent);
    border:none;
    margin-top:4px;
    overflow-y:scroll;

    // small webkit scroll
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }



}

.mobilBasTop{
    width:100%;
    display:flex;
    flex-direction:column;
   // justify-content:space-around;
    flex-grow:1;
}
.mobileBasTop2{
    width:100%;
    display:flex;
    flex-direction:column;
   // flex-grow:1;

}
.mobileBasHeaderWrapper{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.mobilBasHeader{
    font-family: map-get($generalStyles,fontFamily);
    font-size: 20px;
    margin-left:10px;
   // margin-top:5px;
}
.mobileBasPrisWrapper{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.mobileBasPris{
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    margin-left:10px;
    margin-top:10px;

}

.daAnvandningTop{
    width:100%;

    @include gadgets-to(mobile) {
      width:100%;
      display:flex;
      flex-direction: column;
        //justify-content:center;
        align-items: center;
 
    }
    @include gadgets-to(tablet) {
        width:80%;
    }
    @include gadgets-to(laptop) {
        width:100%;
    }


}
.daAnvandningHeader{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    margin-top:2vh;

    @media(min-width:834px){
        margin-left:2vh
    }
    
}
.daAnvandningText{
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    margin-left:10px;
   // margin-top:10px;
}
// .daheaderTopWrapper{
//     @include gadgets-to(mobile) {
//         display: flex;
//         flex-direction:column;
//         justify-content:center;
//         align-items:center;
//         width:100%;
//         padding:0;
//         margin:0;
// }
// @include gadgets-to(tablet) {
//     width:100%;
// }
// @include gadgets-to(laptop) {
//     width:100%;
// }
// @include gadgets-to(desktop) {
//     width:100%;
// }
// @include gadgets-to(largeDesktop) {
//     width:100%;
// }
// }

// .daHeaderTop{
//     @include gadgets-to(mobile) {

//     display:flex;
//     flex-direction: row;
//     justify-content:center;
//     align-items:center;
//     height: 25px;
//     width:100%;
//     font-family: map-get($generalStyles,fontFamily);
//     font-size: 12px;
//     margin-top:1px;
//     flex:1;
// }
// @include gadgets-to(tablet) {
//     display:flex;
//     flex-direction: row;
//     height: 25px;
//     width: 89%;
//     font-family: map-get($generalStyles,fontFamily);
//     font-size: 12px;
//     margin-top:1px;
// }
// @include gadgets-to(laptop) {
//     display:flex;
//     flex-direction: row;
//     height: 25px;
//     width: 60%;
//     font-family: map-get($generalStyles,fontFamily);
//     font-size: 12px;
//     margin-top:1px;
// }
// @include gadgets-to(desktop) {
//     display:flex;
//     flex-direction: row;
//     height: 25px;
//     width:50%;
//     font-family: map-get($generalStyles,fontFamily);
//     font-size: 12px;
//     margin-top:1px;

// }
// @include gadgets-to(largeDesktop) {
//     display:flex;
//     flex-direction: row;
//     height: 25px;
//     width:50%;
//     font-family: map-get($generalStyles,fontFamily);
//     font-size: 12px;
//     margin-top:1px;
// }
// }

// .daHeaderTrackid{
//     @include gadgets-to(mobile) {
//     display:flex;
   
//     justify-content: center;
//     align-items: center;
//     width:20%;
//     height:25px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//   //  margin-left:3px;
//     border-radius: map-get($generalStyles,sixBorderRadius);

// }
// @include gadgets-to(tablet) {
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     width:21%;
//     height:25px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
// }
// @include gadgets-to(laptop) {
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     width:21%;
//     height:25px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
// }
// @include gadgets-to(desktop) {
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     width:20%;
//     height:25px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
// }
// @include gadgets-to(largeDesktop) {
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     width:21%;
//     height:25px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
// }
// }


// .daHeaderBenaemn{
//     @include gadgets-to(mobile) {
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     width:calc(60% - 2px);
//     height:25px;
//     margin-left:2px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
//     flex:1;
//     }
//     @include gadgets-to(tablet) {
//         display:flex;
//         justify-content: center;
//         align-items: center;
//         width:calc(64% - 2px);
//         height:25px;
//         margin-left:2px;
//         background-color: map-get($generalStyles,transparentChangeableMenu4);
//         border-radius: map-get($generalStyles,sixBorderRadius);
//     }
//     @include gadgets-to(laptop) {
//         display:flex;
//         justify-content: center;
//         align-items: center;
//         width:calc(65% - 2px);
//         height:25px;
//         margin-left:2px;
//         background-color: map-get($generalStyles,transparentChangeableMenu4);
//         border-radius: map-get($generalStyles,sixBorderRadius);
//     }
//     @include gadgets-to(desktop) {
//         display:flex;
//         justify-content: center;
//         align-items: center;
//         width:calc(62% - 2px);
//         height:25px;
//         margin-left:2px;
//         background-color: map-get($generalStyles,transparentChangeableMenu4);
//         border-radius: map-get($generalStyles,sixBorderRadius);
//     }
//     @include gadgets-to(largeDesktop) {
//         display:flex;
//         justify-content: center;
//         align-items: center;
//         width:calc(65% - 2px);
//         height:25px;
//         margin-left:2px;
//         background-color: map-get($generalStyles,transparentChangeableMenu4);
//         border-radius: map-get($generalStyles,sixBorderRadius);
//     }
// }

// .daHeaderAntal{
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     width:calc(20% - 2px);
//     height:25px;
//     margin-left:2px;
//     margin-right:5px;

//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
   

//     @include gadgets-to(mobile) {
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     width:calc(20% - 2px);
//     height:25px;
//     margin-left:2px;
//   //  margin-right:5px;

//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
   

// }
// }


// rows
.ddrowTopWrapper{
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;

}
.ddRowTop{
    @include gadgets-to(mobile) {
 
    display:flex;
    flex-direction:column;
    width:90%;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    background-color: transparent;
    height:80vh;
    max-height:80vh;
    overflow-y: auto;
    //webkit scroll
    &::-webkit-scrollbar {
        display:none;
    }
    margin-top:1px;

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    width: 90%;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    background-color: transparent;
    height:55vh;
    margin-top:1px;
    max-height:55vh;
    overflow-y: auto;
    //webkit scroll
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    width: 60%;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    background-color: transparent;
    height:55vh;
    margin-top:1px;
    max-height:55vh;
    overflow-y: auto;
    //webkit scroll
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    width: 50%;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    background-color: transparent;
    height:55vh;
    margin-top:1px;
    max-height:55vh;
    overflow-y: auto;
    //webkit scroll
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    width: 50%;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    background-color: transparent;
    height:55vh;
    margin-top:1px;
    max-height:55vh;
    overflow-y: auto;
    //webkit scroll
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
}
.ddRow{
    display:flex;
    flex-direction: row;
    width:100%;
}
.daRowTrackid{
    display:flex;
    align-items: center;
    justify-content: center;
    width:20%;
    height:25px;
    margin-top:3px;
   // margin-left:3px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu4);

 @include gadgets-to(mobile) {
     height:25px;
}
@include gadgets-to(tablet) {
height:30px;
}
@include gadgets-to(laptop) {
    height:30px;
}
@include gadgets-to(desktop) {
    height:30px;
}
@include gadgets-to(largeDesktop) {
    height:30px;
}
}


.daRowBenaemn{
    text-align: left;
    padding-left:10px;
    display:flex;
    align-items: center;
    width:calc(60% - 2px);
    height:30px;
    margin-left:2px;
    margin-top:3px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
  
    @include gadgets-to(mobile) {
        height:25px;
   }
   @include gadgets-to(tablet) {
   height:30px;
   }
   @include gadgets-to(laptop) {
       height:30px;
   }
   @include gadgets-to(desktop) {
       height:30px;
   }
   @include gadgets-to(largeDesktop) {
       height:30px;
   }
}
.daRowAntal{
    display:flex;
    align-items: center;
    justify-content: center;   
    width:calc(20% - 2px);
    height:30px;
    margin-left:2px;
    margin-top:3px;
    margin-right:3px;
    border-radius: map-get($generalStyles,threeBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    @include gadgets-to(mobile) {
        height:25px;
   }
   @include gadgets-to(tablet) {
   height:30px;
   }
   @include gadgets-to(laptop) {
       height:30px;
   }
   @include gadgets-to(desktop) {
       height:30px;
   }
   @include gadgets-to(largeDesktop) {
       height:30px;
   }
}
 
//min data
.minDataTop{
    @include gadgets-to(mobile) {
display:flex;
flex-direction:column;
//justify-content: center;
align-items:center;
width:90%;
}

@include gadgets-to(tablet) {
    display:flex;
    flex-direction: column;
    //justify-content: center;
    width:60%;
    height:33%;
    @media(min-width:768px) and (max-width:1199px){
        width:80%;
        height:33%;
    }
    @media(min-width:1200px)and (max-width:1300px){
        width:50%;
        height:33%;
    }
    @media(min-width:1301px){
        width:70%;
        height:33%;
    }


}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: column;
   // justify-content: center;
    width:60%;
    height:33%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: column;
   // justify-content: center;
    width:60%;
    height:33%;

}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
   // justify-content: center;
    width:40%;
    height:33%;

}
}


.minDataHeaderTextWrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 @include gadgets-to(mobile) {
    width:100%;
}
@include gadgets-to(tablet){
 width:100%;

}
@include gadgets-to(laptop){
 width:100%;

}

@include gadgets-to(desktop){
 width:100%;

}

@include gadgets-to(largeDesktop){
 width:100%;

}
}


.minDataHeaderText{
    font-family: map-get($generalStyles,fontFamily);
    font-size: 18px;
    margin-left:10px;
    margin-top:10px;
}

.minaDataInformation{
    width:100%;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    background-color: transparent;
    margin-top:1px;
    margin-bottom:5px;
    margin-left:6px;
    @include gadgets-to(mobile) {
    width:90%;
}
}

.minaDataHeaderTopWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items: center;
}

.minaDataHeaderTop{
    display:flex;
    flex-direction:row;
    height: 30px;
    width: 100%;
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    margin-top:3px;
}
.minaDataHeaderTop.header {
    margin-bottom:1px; // Adjust the value as needed
}
.minaDataHeaderServicesId1{
    display:flex;
   justify-content: center;
   align-items: center;
   width:20%;
   height:30px;
  // margin-top:2px;
   background-color: map-get($generalStyles,transparentChangeableMenu4);
   margin-left:3px;
   border-radius: map-get($generalStyles,sixBorderRadius);

}
.minaDataHeaderServicesBenaemn1{
   display:flex;
   justify-content:flex-start;
   align-items:center;
   width:60%;
   height:30px;
   margin-left:3px;
   padding-left:10px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
   border-radius: map-get($generalStyles,sixBorderRadius);
 
}


.minaDataHeaderServicesAntal1{
   display:flex;
   justify-content: center;
   align-items: center;
   width:20%;
   height:30px;
   margin-left:3px;
   margin-right:3px;
  background-color: map-get($generalStyles,transparentChangeableMenu4);
   border-radius: map-get($generalStyles,sixBorderRadius);
}

.minaDataHeaderServicesId{
     display:flex;
    justify-content: center;
    align-items: center;
    width:20%;
    height:30px;
   // margin-top:2px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    margin-left:3px;
    border-radius: map-get($generalStyles,sixBorderRadius);

}
.minaDataHeaderServicesBenaemn{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    width:60%;
    height:30px;
    margin-left:3px;
    padding-left:10px;
     background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);

}


.minaDataHeaderServicesAntal{
    display:flex;
    justify-content: center;
    align-items: center;
    width:20%;
    height:30px;
    margin-left:3px;
    margin-right:3px;
   background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);

}


.minaDataSendInfoText{
    font-family: map-get($generalStyles,fontFamily);
    font-size: 12px;
    margin-left:10px;
    margin-top:10px;
}
.minaDataSendInfoButton{
    font-family: map-get($generalStyles,fontFamily);
    font-size: 14px;
    border-radius: 6px;
    padding: 3px;
    color:black;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 1%;
    margin-right: 1%;
    width: 130px;
    height: 30px;
    margin-top: 1%;
    margin-bottom: 1%;

    
    &:hover {
        background-color: map-get($generalStyles,pinkRed);
        color:white;
    }
    &:active {
        background-color: map-get($generalStyles,pinkRed);
    }
}


.dabackgroundImageRecepyNew10 {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
   // height:map-get($generalStyles ,windowHeight);
  object-fit: cover;
    z-index: -1;
    transform:scale(1)

   
  }


.xxatjansterTop{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    @include gadgets-to(mobile) {
        width:100%;
        height:75vh;
    }
    @include gadgets-to(tablet) {
        width:80%;
    }
    @include gadgets-to(laptop) {
        width:100%;
    }
}



















.spinnerContainer {
    position: fixed; /* Make the spinner position relative to the viewport */
    top: 0;
    left: 0;
    width: 100vw; /* Take up the full viewport width */
    height: 100vh; /* Take up the full viewport height */
    display: flex; /* Use flexbox to center the spinner */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: rgba(0, 0, 0, 0.5); /* Add an overlay */
}


.psTop{
    @include gadgets-to(mobile) {
        width:100%; 
    }
    @include gadgets-to(tablet) {
        width:50%;
    }
    @include gadgets-to(laptop) {
        width:40%;
    }
    @include gadgets-to(desktop) {
        width:30%;
    }
    @include gadgets-to(largeDesktop) {
        width:30%;
    }
}
.psHeaderWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}
.psPassHeader{
    font-family:var(--font-family);
    font-size:20px;
    margin-top:5vh;
}
.pspassLabel{
        // display:block;
        // margin-bottom:5px;
        // text-align:left;
        font-family:var(--font-family);
        font-size: 14px;
        padding-top:5px;

    
}
.pspassLabel1{
    opacity:0;
    padding-top:5px;
}
.psInputWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    margin-top:3vh;
}
.psInput{
    width:80%;
    height:25px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius:6px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    padding:5px;
    margin-top:5px;
}
.psInput1{
  opacity:0;
}

.psInputNew{
    width:80%;
    height:25px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius:6px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    padding:5px;
    margin-top:5px;
    outline:none;
}
//on activ
.psInputNew:focus{
    outline: none;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
}
.psInputConfirm{
    @extend .psInputNew;
}

.pscheckbox{
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    font-family: var(--font-family);
    font-size: 10px;
    
}
.psSubmitbuttonWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:2vh;
}
.psSubmitbutton{
    width:100px;
    height:30px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius:6px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color:black;
    margin-top:5px;
    cursor:pointer;
    transition: 0.3s;
}
.psSubmitbutton:hover{
    background-color: map-get($generalStyles,pinkRed);
    color:white;
}
.psSubmitbutton:active{
    background-color: map-get($generalStyles,pinkRed);
    outline : none;
    border:red;
}
.psSubmitbutton1{
    @extend .psSubmitbutton;
    opacity:0.8;
}

.psInput:focus {
    outline: none;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
}

.psEmailFieldset{
    width:100%;
    height:100%;
    font-family: var(--font-family);
    font-size: 14px;
    border:none;
    margin-top: 10px;
    margin-left: 10px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.psEmailLegend{
    font-family: var(--font-family);
    font-size: 10px;
   // margin-top:10px;
 text-align:center;
}
.psEmailResetCheckbox{
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    font-family: var(--font-family);
    font-size: 10px;

}
.psEmailSendSubmitButton:hover{
    background-color: map-get($generalStyles,pinkRed);
    color:white;
}
.psEmailSendSubmitButton:active{
    background-color: map-get($generalStyles,pinkRed);
    outline : none;
}

.psInvoiceTop{
width:100%;
height:80vh;
}
.psinvoiceHeaderWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}
.psinvoiceHeader{
    font-family:var(--font-family);
    font-size:20px;
    margin-top:2vh;
}

.psinvoiceTop{
    @include gadgets-to(mobile) {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
    }
    @include gadgets-to(tablet) {
        width:95%;
        height:80vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @include gadgets-to(laptop) {
        width:100%;
        height:80vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:3vh;
        background-color: white;
    
    }
    @include gadgets-to(desktop) {
        height:65vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:90%;
        margin-top:1vh;
    
    }
    @include gadgets-to(largeDesktop) {
        height:60vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;

    }
}
    .psInvoiceTabTop{
        @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        height:4vh;
        width:99%;
       
       // border:1px solid red;
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
        background-color: map-get($generalStyles,transparentChangeable);
        // background:white;
        margin-top:4px;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        height:4vh;
        width:100%;
       // border:1px solid red;
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
       // background-color: map-get($generalStyles,transparentChangeableMenu4);
         background:white;
    }
    @include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        height:4vh;
        width:98%;
       // border:1px solid red;
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
       // background-color: map-get($generalStyles,transparentChangeableMenu4);
         background:white;
    }
    @include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        height:4vh;
        width:100%;
       // border:1px solid red;
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
       // background-color: map-get($generalStyles,transparentChangeableMenu4);
         background:white;
    }
    @include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        height:4vh;
        width:60%;
       // border:1px solid red;
        font-family: map-get($generalStyles,fontFamily);
        font-size:12px;
       // background-color: map-get($generalStyles,transparentChangeableMenu4);
         background:white;
    }
    }
    

.psinvoiceTab{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:4vh;
    width:100%;
    border:none;
    cursor:pointer;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor:pointer;


    }
}

@include gadgets-to(tablet) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:4vh;
    width:100%;
    border:none;
    cursor:pointer;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  //  margin-left:-10px;
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-left:-10px;
    cursor:pointer;
    }
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:3vh;
    width:100%;
    border:none;
    cursor:pointer;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left:-10px;
    cursor:pointer;
    }
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:3vh;
    width:100%;
    border:none;
    cursor:pointer;
    margin-left:-10px;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left:-10px;
    cursor:pointer;
    }
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    height:3vh;
    width:100%;
    border:none;
    cursor:pointer;
    font-family: map-get($generalStyles,fontFamily);
    font-size:13px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    &.active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border:1px solid map-get($generalStyles,colorwhite);
    color:white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left:-10px;
    cursor:pointer;
    }
}
}


.psinvoicefirstButton {
    border-top-left-radius:map-get($generalStyles,sixBorderRadius);
}

.psinvoicelastButton {
    border-top-right-radius:map-get($generalStyles,sixBorderRadius);
 //   border-top-right-radius: 12px;


}


.psinvoiceTabContent{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    align-items: center;
    height:85vh;
    max-height:85vh;
    width:100%;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 15px;
    background-color: map-get($generalStyles,transparent);
    //background-color: white;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    margin-top:1px;
    overflow-y:scroll;

    // small webkit scroll
    &::-webkit-scrollbar {
        display:none;
    }

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:68vh;
    max-height:68vh;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 15px;
    background-color: map-get($generalStyles,transparent);
    //background-color: white;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    margin-top:1px;
    border-radius:map-get($generalStyles,sixBorderRadius);
    overflow-y:auto;

    // small webkit scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:76vh;
    max-height:76vh;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 15px;
    background-color: map-get($generalStyles,transparent);
    //background-color: white;
    border-radius:map-get($generalStyles,sixBorderRadius);
  margin-top:1px;
    overflow-y:auto;

    // small webkit scroll
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:76vh;
    max-height:66vh;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 15px;
    background-color: map-get($generalStyles,transparent);
   // background-color: white;
    border-radius:map-get($generalStyles,sixBorderRadius);
    margin-top:1px;
    overflow-y:auto;

    // small webkit scroll
    &::-webkit-scrollbar {
    display:none;
    }
    // &::-webkit-scrollbar-track {
    //     background: map-get($generalStyles,transparentChangeableMenu4);
    // }
    // &::-webkit-scrollbar-thumb {
    //     background: white;
    //  //   background: map-get($generalStyles,transparentChangeableMenu5);
    //     border-radius: 10px;
    //     border:1px solid;
    //     border-color: map-get($generalStyles,transparentChangeableMenu5);
    // }
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:60%;
    height:46vh;
    max-height:46vh;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    font-family: map-get($generalStyles,fontFamily);
    font-size: 15px;
    background-color: map-get($generalStyles,transparent);
   // background-color: white;
    border-radius:map-get($generalStyles,sixBorderRadius);
    margin-top:1px;
    overflow-y:auto;

    // small webkit scroll
    &::-webkit-scrollbar {
        display:none;

}
}
}


.psinvoiceOverviewTop{
    @include gadgets-to(mobile) {
        width:100%;
    }
    @include gadgets-to(tablet) {
        width:60%;
    }
    @include gadgets-to(laptop) {
        width:60%;
    }
    @include gadgets-to(desktop) {
        width:60%;
    }
    @include gadgets-to(largeDesktop) {
        width:60%;
    }

}
.psinvoiceOverviewHeaderWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
   // border:2px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
}
.psinvoiceOverviewHeader{
    font-family:var(--font-family);
    font-size:12px;
    margin-top:2vh;
   // font-weight: bold;
 //  font-style: italic;
   white-space: nowrap;
   @include gadgets-to(mobile) {
    font-size: 12px;
}
@include gadgets-to(tablet) {
    font-size: 16px;
}
@include gadgets-to(laptop) {
    font-size: 16px;
}
@include gadgets-to(desktop) {
    font-size: 18px;

}
@include gadgets-to(largeDesktop) {
    font-size: 18px;
}
}

.psinvoiceOverviewSum{
    font-family:var(--font-family);
    font-size:14px;
    margin-top:5px;
   // font-weight: bold;
@include gadgets-to(mobile) {
    font-size: 14px;
}
@include gadgets-to(tablet) {
    font-size: 16px;
}
@include gadgets-to(laptop) {
    font-size: 16px;
}
@include gadgets-to(desktop) {
    font-size: 18px;

}
@include gadgets-to(largeDesktop) {
    font-size: 18px;
}
}

.psexplanationTextWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:98%;
  
}
.psexplanationText{
    width:90%;
font-size: 12px;
font-family: var(--font-family);
//margin:10px;
color:grey;
@include gadgets-to(mobile) {
    font-size: 12px;
}
@include gadgets-to(tablet) {
    font-size: 16px;
}
@include gadgets-to(laptop) {
    font-size: 14px;
}
@include gadgets-to(desktop) {
    font-size: 16px;

}
@include gadgets-to(largeDesktop) {
    font-size: 16px;
}
}
.pspaymentChoicesWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}
.pspaymentNextPaymentDue{
    font-family:var(--font-family);
    font-size:14px;
   // font-weight: bold;
    margin-top:2vh;
    white-space: nowrap;
    @include gadgets-to(mobile) {
        font-size: 14px;
    }
    @include gadgets-to(tablet) {
        font-size: 16px;
    }
    @include gadgets-to(laptop) {
        font-size: 16px;
    }
    @include gadgets-to(desktop) {
        font-size: 18px;
    
    }
    @include gadgets-to(largeDesktop) {
        font-size: 18px;
    }
}
.pspaymentNextPaymentDueDate{
    font-family:var(--font-family);
    font-size:14px;
   // font-weight: bold;
    margin-top:1vh;
    white-space: nowrap;
    @include gadgets-to(mobile) {
        font-size: 14px;
    }
    @include gadgets-to(tablet) {
        font-size: 16px;
    }
    @include gadgets-to(laptop) {
        font-size: 16px;
    }
    @include gadgets-to(desktop) {
        font-size: 18px;
    
    }
    @include gadgets-to(largeDesktop) {
        font-size: 18px;
    }
}

.psline {
    height: 1px;
    background-color:lightgrey;
    width: 70%;
    margin-top: 2vh;
   // color:red;
}
.psperiodPrePayment{
    font-family:var(--font-family);
    font-size:14px;
    margin-top:2vh;
}
.pspreriodPrePaymentDate{
    font-family:var(--font-family);
    font-size:14px;
    margin-top:1vh;
}
.pstotalyUsed{
    font-family:var(--font-family);
    font-size:14px;
    margin-top:2vh;
    white-space: nowrap;
}
.pstotalyUsedSum{
    font-family:var(--font-family);
    font-size:14px;
    margin-top:1vh;
}
.invoiceHistoryTop{
    width:100%;
}
.invoiceHistoryHeaderWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;

}
.invoiceHeaderTop{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.invoicelistWrapper{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:98%;
    margin-bottom: 2px;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;
    margin-bottom: 2px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;
    margin-bottom: 2px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;
    margin-bottom: 2px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;
    margin-bottom: 2px;
}
}

.invoiceHistoryHeader{
   font-family:var(--font-family);
    font-size:12px;
    margin-top:1vh;
    margin-bottom:1vh;
    //font-weight: bold;
    font-style: italic;
}





.invoiceHistoryHeader1{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex: 0.25;
    width:70%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    transition: 0.3s;
   // width: auto;
    height:25px;
    white-space: nowrap;
  //  margin-top:3px;
    border:none;
    margin-left:2px;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex: 0.20;
    width:70%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    transition: 0.3s;
   // width: auto;
    height:25px;
    white-space: nowrap;
  //  margin-top:3px;
    border:none;
    margin-left:2px;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex: 0.20;
    width:70%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    transition: 0.3s;
   // width: auto;
    height:25px;
    white-space: nowrap;
  //  margin-top:3px;
    border:none;
    margin-left:2px;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex: 0.20;
    width:70%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    transition: 0.3s;
   // width: auto;
    height:25px;
    white-space: nowrap;
  //  margin-top:3px;
    border:none;
    margin-left:2px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex: 0.20;
    width:70%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor: pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    transition: 0.3s;
   // width: auto;
    height:25px;
    white-space: nowrap;
  //  margin-top:3px;
    border:none;
    margin-left:2px;
}
}


.invoiceHistoryListWrapper{
    @include gadgets-to(mobile) {

    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
    height:60vh;
    max-height:60vh;
    overflow-y:scroll;
    // small scrollbar
    &::-webkit-scrollbar {
        display:none;
    }
    padding-bottom:10px;


    @media(max-width:360px){
        height:50vh;
        max-height:50vh;
    }
    @media(min-width:392px) and (max-width:394px){
        height:70vh;
        max-height:70vh;
    
    }

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
    height:52vh;
    max-height:52vh;
    margin-top:1px;
    overflow-y:scroll;
    // small scrollbar
    &::-webkit-scrollbar {
       display:none;
    }
    padding-bottom:10px;

}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
    height:80vh;
    max-height:80vh;
    overflow-y:scroll;
    // small scrollbar
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
    height:80vh;
    max-height:80vh;
    overflow-y:scroll;
    // small scrollbar
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    width:100%;
    height:80vh;
    max-height:80vh;
    overflow-y:scroll;
    // small scrollbar
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
}
}
.invoiceHistoryList{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:98%;

}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;

}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;

}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:95%;

}
}
.invoiceHistoryListData{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex: 0.20;
    width:70%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor:default;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    transition: 0.3s;
   // width: auto;
    height:25px;
    white-space: nowrap;
    margin-top:2px;
    border:none;
    margin-left:2px;
    margin-bottom:2px;
}
.invoiceHistoryListDataPDF{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex: 0.20;
    width:70%;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    color: map-get($generalStyles,colorblack);
    outline: none;
    cursor:pointer;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    transition: 0.3s;
   // width: auto;
    height:25px;
    white-space: nowrap;
    margin-top:2px;
    border:none;
    margin-left:2px;
    margin-bottom:2px;
}

.invoiceSettingsTop{
    @include gadgets-to(mobile) {

    width:100%;
    background-color: map-get($generalStyles,transparentChangeable);
    height:64vh;
    max-height:64vh;
    overflow-y:auto;

    @media(min-width:428px){
        height:72vh;
        max-height:72vh;
    
    }
    // small scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);


}
}
@include gadgets-to(tablet) {
    width:100%;
    background-color: map-get($generalStyles,transparentChangeable);
    height:64vh;
    max-height:64vh;
    overflow-y:auto;


    // small scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
}
}
@include gadgets-to(laptop) {
    width:100%;
    background-color:transparent;
    height:64vh;
    max-height:64vh;
    overflow-y:auto;

 
    // small scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
}
}
@include gadgets-to(desktop) {
    width:80%;
    background-color: map-get($generalStyles,transparentChangeable);
    height:64vh;
    max-height:64vh;
    overflow-y:auto;


    // small scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background-color: map-get($generalStyles,transparentChangeable);
        //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
}
}
@include gadgets-to(largeDesktop) {
    width:70%;
    background-color: map-get($generalStyles,transparentChangeable);
    height:64vh;
    max-height:64vh;
    overflow-y:auto;


    // small scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
}
}
}



.invoiceSettingsHeaderWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;

}
.invoiceSettingsHeader{
    display:flex;
    flex-direction: row;
    justify-content:center;
  //  align-items:space-between;
    font-family:var(--font-family);
    font-size:16px;
    margin-top:2vh;
    //font-weight: bold;
   // font-style: italic;
    width:100%;
    margin-bottom:1vh;
}

.invoiceSettingsHeader1{
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:center;
width:90%;
margin-bottom:1vh;

}

.invoiceSettinsHeaderText{
    font-family:var(--font-family);
    font-size: 14px;
  //  margin-left:5px;
  //  width:90%;
}
.invoiceSettingsListInfo{
font-family: var(--font-family);
font-size: 12px;
width:90%;
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:80%;
    margin-top:1vh;
}
}
.invoiceSettingsCustomerAddress{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
 //   margin-left:15%;
}
.invoiceSettingsCustomerAddressWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.invoiceSettingspLine{
    height:1px;
    background-color:lightgrey;
    width:70%;
 //   margin-top: 2vh;
   // color:red;
   margin-top:2vh;
}
.invoiceSettingsTaxIdWrapper{
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;

}
.invoiceSettingsTaxIdHeader{
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
   // font-style: italic;

}
.invoiceSettingsTaxIdText{
    font-family: var(--font-family);
    font-size: 12px;
    margin-top:1vh;
}
.invoiceSettingsTaxidSaveWrapper{
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:2px;

}
.invoiceSettingsTaxIdInput{
    width:66%;
    height:20px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius:6px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    padding:5px;
    margin-top:2vh;
    outline:none;

}
.invoiceSettingsTaxidSave{
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    padding: 3px;
    color:black;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 1%;
    margin-right: 1%;
    width:90%;
    height:40px;
    line-height:1;
    margin-top: 1%;
    margin-bottom: 1%;
    // do focus here
    &:hover {
        background-color: map-get($generalStyles,pinkRed);
        color:white;
    }
    &:active {
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    }
    // onclick
    &:focus {
        outline: none;
    }


}
.invoiceSettingsBillingAlertWrapper{
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
}
.invoiceSettingsBillingAlertHeader{
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
    font-style: italic;
}
.invoiceSettingspLine1{
    height: 1px;
    background-color:lightgrey;
    width: 70%;
    margin-top: 1vh;
   // color:red;
}
.invoiceSettingsSliderWrapper{
    width:50%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;

}
.invoiceSettingMaxValueAlertInput{
    width:36%;
    height:20px;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius:6px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    padding:5px;
    margin-top:5px;
    margin-bottom:3px;
    outline:none;
    margin-right: 1vw;

}
.invoiceSaveMaxValueButtonWrapper{
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;


}
.invoiceSaveMaxValueButton{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 6px;
    //padding: 3px;
    color:black;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
  //  margin-left: 1%;
  //  margin-right: 1%;
    width:80%;
    height:30px;
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: center;
    // do focus here
    &:hover {
        background-color: map-get($generalStyles,pinkRed);
        color:white;
    }
    &:active {
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    }
    // onclick
    &:focus {
        outline: none;
    }

}
.invoiceSaveMaxValueButton1{
    @extend .invoiceSaveMaxValueButton;
    opacity:0.8;
}

.invoiceEndFooter{
    width:100%;
    height:3vh;
}


.kontoTop{
    width:100%;
    @include gadgets-to(laptop) {
        box-shadow: 4px 4px 10px 10px rgba(112, 107, 107, 0.2);
        height:65vh;
        max-height:65vh;
        overflow-y:auto;
        justify-content:unset;
        border-radius:8px;
        background-color:white;
        width:96%;
        border:1px solid map-get($generalStyles,datalistBorderColor);
         cursor:pointer;
        // webkit scroll
        &::-webkit-scrollbar {
            width: 5px;
        }
        // webkit scroll track
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,transparentChangeableMenu4);
        }
        // webkit scroll thumb
        &::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 10px;
            border:1px solid;
            border-color: map-get($generalStyles,transparentChangeableMenu5);
        }
    }
}
.kontoHeaderWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;

}
.kontoHeader{
    font-family:var(--font-family);
    font-size:20px;
    margin-top:1vh;
}
.kontoInfoTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
.kontoInfoWrapper{
    width:97%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
    
}
.kontoInfoHeader{
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:2px;
    font-style: italic;
    margin-bottom:1vh;
}
.kontoInfoHeaderWrapper{
    width:98%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
   // gap:5px;
    

}
.kontoInfoService{
    width:15%;
    height:20px;
     font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
 
}
.kontoInfoFaktura{
 width:15%;
 height:20px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
}
.kontoInfoDebit{
 width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
}
.kontoInfoKredit{
   width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
}
.kontoInfoDatum{
   width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);


}
.kontoInfoKommentar{
 width:25%;
    height:20px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
}

.kontoInforHeader1{
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
    font-style: italic;
    margin-left:2px;
    border:1px solid map-get($generalStyles,datalistBorderColor);



}
.kontoInfoListWrapper{
    width:98%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    // @include gadgets-to(laptop) {
    //     height:30vh;
    //     justify-content: unset;
    // }
 
}
.kontoListWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;

}
.kontoInfoList{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
   // gap:15px;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:3px;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: 6px;
}

.kontolistService{
    width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;

}
.kontolistfaktura{
    width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    // border:1px solid map-get($generalStyles,datalistBorderColor);
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
}
.kontolistdebit{
  width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
}
.kontolistkredit{
  width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid map-get($generalStyles,datalistBorderColor);
}
.kontolistdate{
 width:15%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid map-get($generalStyles,datalistBorderColor);
}
.kontolistkommentar{
  width:25%;
    height:20px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid map-get($generalStyles,datalistBorderColor);
   // margin-left:10px;
}
.footerHeader{
    width:60%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:1.5vh;
}
.footerHeaderList{
    width:60%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 12px;
    margin-top:1vh;
    border-radius: map-get($generalStyles,sixBorderRadius);
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    background-color: white;
}
.footerHeaderDebit{
    width:40%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid map-get($generalStyles,datalistBorderColor);
}
.footerHeaderKredit{
    width:40%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid map-get($generalStyles,datalistBorderColor);
}
.footerHeaderSaldo{
    width:20%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid map-get($generalStyles,datalistBorderColor);
}













.kontoInfoServiceName{
    font-family: var(--font-family);
    font-size: 16px;
    margin-top:1vh;
}
.invoiceRowsTopWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family:var(--font-family);
    font-size: 16px;
    margin-top:1vh;
    margin-bottom:1vh;

}
.invoiceRowsTop{
    width:100%;
    font-family:var(--font-family);
    font-size: 10px;
    font-style: italic;
    text-align:center;
}
.invoiceSelectWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;

}
.invoiceSelectList{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:50%;
    height:20px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-top:1vh;
    border-radius: map-get($generalStyles,sixBorderRadius);
    border-color:map-get($generalStyles,datalistBorderColor);
    outline:none;
    background-color: white;
}
.invoiceRowsHeaderWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:98%;
    font-family:var(--font-family);
    font-size:10px;
    //gap:5px;

}
.invoiceRowfakturaid{
    @include gadgets-to(mobile) {
    width:20%;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);

}
    @include gadgets-to(tablet) {
        width:20%;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(laptop) {
        width:15%;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(desktop) {
        width:15%;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(largeDesktop) {
        width:15%;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
}


.invoiceRowServiceid{
    @include gadgets-to(mobile) {

    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
}
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }
    @include gadgets-to(laptop) {
        width:calc(15% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        width:calc(15% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(15% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }

}


// .invoiceRowServiceMax{
//     width:calc(14% - 2px);
//     margin-left:2px;
//     height:20px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
//     padding:1px;
//     font-family: var(--font-family);
//     font-size: 10px;
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     border:1px solid map-get($generalStyles,datalistBorderColor);

// }
// .invoiceRowRequests{
//     width:calc(14% - 2px);
//     margin-left:2px;
//     height:20px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
//     padding:1px;
//     font-family: var(--font-family);
//     font-size: 10px;
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     border:1px solid map-get($generalStyles,datalistBorderColor);

// }
// .invoiceRowDebitQty{
//     width:calc(14% - 2px);
//     margin-left:2px;
//     height:20px;
//     background-color: map-get($generalStyles,transparentChangeableMenu4);
//     border-radius: map-get($generalStyles,sixBorderRadius);
//     padding:1px;
//     font-family: var(--font-family);
//     font-size: 10px;
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     border:1px solid map-get($generalStyles,datalistBorderColor);

// }
.invoiceRowSumma{
    @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
}
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(laptop) {
        width:calc(10% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(desktop) {
        width:calc(10% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(largeDesktop) {
        width:calc(10% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
}

.invoiceRowDate{
    @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
}
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(laptop) {
        width:calc(10% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(desktop) {
        width:calc(10% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }
    @include gadgets-to(largeDesktop) {
        width:calc(10% - 2px);
        margin-left:2px;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
    }

}

.invoiceRowType{
    width:calc(5% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);

}

.invoiceRowsListWrapper{
    width:98%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
  //  margin-top:1vh;

}
.invoiceRowsList{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:3px;
    border-radius: 6px;
}

.invoiceServiceNameWrapper{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 12px;
    margin-top:1vh;
   
.invoiceServiceName{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:80%;
    font-family: var(--font-family);
    font-size: 12px;
    margin-top:1vh;
    font-style: italic;
    border:1px dotted map-get($generalStyles,datalistBorderColor);
    padding:10px;
}
}
.invoiceRowsTotalSum{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size:12px;
    font-style:italic;
    margin-top:1vh;
  //  gap:5px;

}

.tjansterTop{
    @include gadgets-to(mobile) {
width:100%;
height:80vh;
max-height:80vh;
overflow-y:scroll;
display:flex;
flex-direction:column;
align-items: center;
// small scrollbar
&::-webkit-scrollbar {
    display:none;
}
&::-webkit-scrollbar-track {
    background: map-get($generalStyles,transparentChangeableMenu4);
}
&::-webkit-scrollbar-thumb {
    background: white;
 //   background: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: 10px;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
}
@media(min-width:428px){
    height:68vh;
    max-height:68vh;

}

}
@include gadgets-to(tablet) {
    width:100%;
    height:80vh;
    max-height:80vh;
    display:flex;
    flex-direction:column;
    align-items: center;
    overflow-y:scroll;
    // small scrollbar
&::-webkit-scrollbar {
    width: 10px;
}
&::-webkit-scrollbar-track {
    background: map-get($generalStyles,transparentChangeableMenu4);
}
&::-webkit-scrollbar-thumb {
    background: white;
 //   background: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: 10px;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
}

}
@include gadgets-to(laptop) {
    width:100%;
    height:80vh;
     max-height:80vh;
    display:flex;
    flex-direction:column;
    align-items: center;

    overflow-y:scroll;
    // small scrollbar
&::-webkit-scrollbar {
    width: 10px;
}
&::-webkit-scrollbar-track {
    background: map-get($generalStyles,transparentChangeableMenu4);
}
&::-webkit-scrollbar-thumb {
    background: white;
 //   background: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: 10px;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
}


}
@include gadgets-to(desktop) {
    width:100%;
    height:80vh;
     max-height:80vh;
    display:flex;
    flex-direction:column;
    align-items: center;
    overflow-y:scroll;
    // small scrollbar
&::-webkit-scrollbar {
    display:none;
 }
// &::-webkit-scrollbar-track {
//     background: map-get($generalStyles,transparentChangeableMenu4);
// }
// &::-webkit-scrollbar-thumb {
//     background: white;
//  //   background: map-get($generalStyles,transparentChangeableMenu5);
//     border-radius: 10px;
//     border:1px solid;
//     border-color: map-get($generalStyles,transparentChangeableMenu5);
// }
}
@include gadgets-to(largeDesktop) {
    width:90%;
    height:80vh;
     max-height:80vh;
    display:flex;
    flex-direction:column;
  //  justify-content: center;
   // align-items: center;
    overflow-y:scroll;
    // small scrollbar
&::-webkit-scrollbar {
    display:none;
}
// &::-webkit-scrollbar-track {
//     background: map-get($generalStyles,transparentChangeableMenu4);
// }
// &::-webkit-scrollbar-thumb {
//     background: white;
//  //   background: map-get($generalStyles,transparentChangeableMenu5);
//     border-radius: 10px;
//     border:1px solid;
//     border-color: map-get($generalStyles,transparentChangeableMenu5);
// }
}
.tjansterHeaderWrapper{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:97%;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

width:95%;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:95%;

}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:95%;

}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:95%;
}
}

.tjansterWrapper{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:97%;
}
@include gadgets-to(tablet) {

width:95%;
}
@include gadgets-to(laptop) {
    width:95%;
    height:55vh;
    max-height:55vh;
    overflow-y:scroll;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }

}
@include gadgets-to(desktop) {
    width:95%;
    height:75vh;
    max-height:75vh;
    overflow-y:scroll;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
    }
    &::-webkit-scrollbar-thumb {
        background: white;
     //   background: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: 10px;
        border:1px solid;
        border-color: map-get($generalStyles,transparentChangeableMenu5);
    }
      


}
@include gadgets-to(largeDesktop) {
    width:95%;
    height:75vh;
    max-height:55vh;
    overflow-y:scroll;
}
}





.tjansterHeaderText{
    @include gadgets-to(mobile){
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
    margin-bottom:3px;
}
@include gadgets-to(tablet){
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
    margin-bottom:3px;
}
@include gadgets-to(laptop){
    font-family: var(--font-family);
    font-size: 16px;
    margin-top:1vh;
    margin-bottom:3px;
}
@include gadgets-to(desktop){
    font-family: var(--font-family);
    font-size: 16px;
    margin-top:1vh;
    margin-bottom:3px;
}
@include gadgets-to(largeDesktop){
    font-family: var(--font-family);
    font-size: 18px;
    margin-top:1vh;
    margin-bottom:3px;
}
} 

.tjansterRow{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    margin-top:3px;

}

.tjansterLine{
    width:80%;
    height:1px;
    background-color:lightgrey;
    margin-top:1vh;
}

.tjansterId{
    @include gadgets-to(mobile) {
    width:6%;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;

}
@include gadgets-to(tablet) {
    width:3%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
 //   border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
}
@include gadgets-to(laptop) {
    width:3%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
}
@include gadgets-to(desktop) {
    width:3%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    width:3%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
}
}
.tjansterId1{
    @include gadgets-to(mobile) {
        width:5%;
        height:20px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
      //  border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
}
    @include gadgets-to(tablet) {
        width:3%;
        height:25px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
     //   border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }
    @include gadgets-to(laptop) {
        width:3%;
        height:25px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
      //  border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }
    @include gadgets-to(desktop) {
        width:3%;
        height:25px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
      //  border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }
    @include gadgets-to(largeDesktop) {
        width:3%;
        height:25px;
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        border-radius: map-get($generalStyles,sixBorderRadius);
        padding:1px;
        font-family: var(--font-family);
        font-size: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
      //  border:1px solid map-get($generalStyles,datalistBorderColor);
        cursor:pointer;
    }
}



.tjansterBenaemn{
    @include gadgets-to(mobile) {
    width:calc(40% - 2px);
   margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    padding-left:3px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
    // elippsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
@include gadgets-to(tablet) {
    width:calc(42% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
   // border:1px solid grey;

}
@include gadgets-to(laptop) {
    width:calc(42% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;

}
@include gadgets-to(desktop) {
    width:calc(42% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;

}
@include gadgets-to(largeDesktop) {
    width:calc(42% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;

}
}
}
.tjansterBenaemn1{
    @include gadgets-to(mobile) {
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:center;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:none;
    width:calc(40% - 2px);
    margin-left:2px;
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
@include gadgets-to(tablet) {

    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:center;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:none;
    width:calc(42% - 2px);
    margin-left:2px;

}
@include gadgets-to(laptop) {
    width:calc(42% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:center;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:none;
}
@include gadgets-to(desktop) {
    width:calc(42% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:none;
}
@include gadgets-to(largeDesktop) {
    width:calc(42% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    padding-left:3px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:none;
}

}

.tjansterPris{
    @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    white-space: nowrap;
   
}
@include gadgets-to(tablet) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(laptop) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
 //   border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(desktop) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(largeDesktop) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
   // border:1px solid map-get($generalStyles,datalistBorderColor);
}
}
.tjansterPris1{
    @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:20px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
   // bord

}
@include gadgets-to(tablet) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(laptop) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(desktop) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(largeDesktop) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
}
}


.tjansterAdd{
    width:calc(15% - 2px);
    margin-left:2px;
    height:20px;
  //  background-color: map-get($generalStyles,transparentChangeableMenu4);
   // border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
   //border:1px solid map-get($generalStyles,datalistBorderColor);
}
.tjansterAdd1{
   @include gadgets-to(mobile) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:20px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:1px;

}
@include gadgets-to(tablet) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;
    justify-content:center;
    align-items: center;
    padding:1px;
}
@include gadgets-to(laptop) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;
    justify-content:center;
    align-items: center;
    padding:1px;

}
@include gadgets-to(desktop) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;
    justify-content:center;
    align-items: center;
    padding:1px;
}
@include gadgets-to(largeDesktop) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;
    justify-content:center;
    align-items: center;
    padding:1px;
}
}

.tjansterStat{
    width:calc(15% - 2px);
    margin-left:2px;
    height:20px;
  //  background-color: map-get($generalStyles,transparentChangeableMenu4);
   // border-radius: map-get($generalStyles,sixBorderRadius);
    padding:1px;
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
//border:1px solid map-get($generalStyles,datalistBorderColor);

}
.tjansterStat1{
  @include gadgets-to(mobile) {
    border:1px solid map-get($generalStyles,transparentChangeable);
   
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:20px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;   
    justify-content:center;
    align-items:center;


}
@include gadgets-to(tablet) {
    border:1px solid map-get($generalStyles,transparentChangeable);
  
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;   
    justify-content:center;
    align-items:center;
}
@include gadgets-to(laptop) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    justify-content: center;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;   
    justify-content:center;
    align-items:center;
}
@include gadgets-to(desktop) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    justify-content: center;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;   
    justify-content:center;
    align-items:center;
}
@include gadgets-to(largeDesktop) {
    border:1px solid map-get($generalStyles,transparentChangeable);
    justify-content: center;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    cursor:none;
    font-family: var(--font-family);
    font-size: 12px;
    width:calc(15% - 2px);
    margin-left:2px;
    display:flex;
    justify-content:center;
    align-items:center;
}
}


.tjansterInfoWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

}
.tjansterInfoTop{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;

}
.tjansterInfoText{
width:100%;
border:1px solid red;

}
.tjansteranimatedRow{
    width:100%;
    margin-top:2px;
    transition: 0.3s;
}
.tjansteranimatedBenaemn{
    @include gadgets-to(mobile) {
    padding-left:3px;
    font-family:var(--font-family);
    font-size:14px;

}
@include gadgets-to(tablet) {
    padding-left:3px;
    font-family:var(--font-family);
    font-size:12px;
}
}

.tjansteranimated{ 
  
    transform:rotate(0deg);
    animation-name:tjanster50;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    
      }

      @keyframes tjanster50 {
        0%{ height:0px }
        100%{height:auto}
        }

 .fakturaTop{
width:100%;
 }       
.fakturaWrapper{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

}
.fakturaSelect{
    width:50%;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-bottom: 3px;
    margin-top:5px;
    outline:none;
    background-color:white;
}

.fakturaArea{
width:100%;
height:66vh;
max-height:66vh;
overflow-y:scroll;
//transform: scale(1.1);
//transform-origin: top left;
// webkit scrollbar
&::-webkit-scrollbar {
    width: 10px;

}
&::-webkit-scrollbar-track {
    background: map-get($generalStyles,transparentChangeableMenu4);
}
&::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border:1px solid;
    border-color: map-get($generalStyles,transparentChangeableMenu5);
}
}

.fakturaAreaWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
.fakturaAreaGrid{
    display: grid;
    grid-template-rows:15% 16% 43% 16%;
     grid-template-areas:
         "a"
         "b"
         "c"
         "d"
         ;
     width: 95%;
     height:auto;
     object-fit: cover;
    // grid-template-rows:auto auto auto auto;
 border:1px solid map-get($generalStyles,datalistBorderColor);

}
.fakturaAreaA{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    grid-area:a;
    width:100%;
    height:16vh;
    background-color:white;
    //border:1px solid grey;

}
.fakturaHeaderWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
}

.fakturaHeaderName{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

    font-family:'Lobster,cursive';
    font-size: 70px;
    font-style: italic;
    font-weight: bold;
    margin-bottom:3px;
    width:55%;
    height:7vh;
    color:gold;
    padding-left:2%;

}
.fakturaHeaderType{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 50px;
    margin-top:1vh;
    font-style: italic;
    margin-bottom:3px;
    width:45%;
    color:grey;
   
}
.fakturaHeaderTypeJAP{
    @extend .fakturaHeaderType;
    font-size: 36px;
}
.fakturaHeaderBroughtToYou{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 10px;
    width:55%;

}
.fakturaHeaderInvoiceDue{
    width:45%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 45px;
    padding-left:10%;  
}

.fakturaAreaB{
    grid-area:b;
    width:100%;
    height:17vh;
    background-color:white;
  //  border:1px solid grey;
}
.fakturaHeaderLine{
    width:90%;
    height:2px;
    background-color:grey;
  //  margin-top:1vh;
}
.fakturacompanyInfo{
    width:60%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:1vh;
  

}
.fakturacustomerInfo{
    width:40%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.fakturainfo{
    width:100%;

}
.fakturaInfoTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  
}
.fakturainfoWrapper{
    width:80%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;



}
.fakturaOwnerInfo{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
   // align-items:center;
   font-family: var(--font-family);
    font-size: 16px;

 

}
.fakturaCustomerid{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    //align-items:center;
    font-family:var(--font-family);
    font-size: 16px;
}
.fakturacustomerDet{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
   // align-items:center;
    font-family:var(--font-family);
    font-size: 16px;
}

.fakturaAreaC{
    grid-area:c;
    width:100%;
    height:50vh;
    background-color:white;
   // border:1px solid grey;
}
.fakturaAreaCWrapper{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:3px;
}
.fakturaAreaCWrapper1{
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:3px;
}
.fakturaAreaCTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
.fakturaAreaCHeaderService{
    width:10%;  
    height:20px;
    //margin-left:12px;
  //  border:1px solid grey;
    font-family:var(--font-family);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content:center;
    align-items:center;
    white-space: nowrap;
}
.fakturaAreaCHeaderNamn{
    width:calc(40% - 2px);
    margin-left:2px;
    height:20px;
  //  border:1px solid grey;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content:center;
    align-items:center;
    white-space: nowrap;

}
.fakturaAreaCHeaderAntal{
    width:calc(8% - 2px);
    margin-left:2px;
    height:20px;
   // border:1px solid grey;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content:center;
    align-items:center;
}
.fakturaAreaCHeaderMaxAntal{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
    //border:1px solid grey;
    white-space: nowrap;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content:center;
    align-items:center;
}
.fakturaAreaCHeaderFakturerat{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
   // border:1px solid grey;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content:center;
    align-items:center;
}
.fakturaAreaCHeaderTotalt{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
  //  border:1px solid grey;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content:center;
    align-items:center;
}
.fakturaAreaCHeaderKredit{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
   // border:1px solid grey;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content:center;
    align-items:center;
}


.fakturaAreaList{
    width:95%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.fakturaAreaListRow{

    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}

// list
.fakturaAreaListService{
    width:10%;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
  // border:1px solid grey;
   margin-top:3px;
}
.fakturaAreaListNamn{
    width:calc(40% - 2px);
    margin-left:2px;
    height:20px;
    display:flex;
    justify-content:flex-start;
    text-align:left;
    white-space: nowrap;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
   // border:1px solid grey;
    margin-top:2px;
}
.fakturaAreaListAntal{
    width:calc(8% - 2px);
    margin-left:2px;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
   // border:1px solid grey;
    margin-top:3px;
}
.fakturaAreaListMaxAntal{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
  //  border:1px solid grey;
    margin-top:3px;
}
.fakturaAreaListFakturerat{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;

    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
   // border:1px solid grey;
    margin-top:3px;
}
.fakturaAreaListTotalt{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
    //border:1px solid grey;
    margin-top:3px;
  
}
.fakturaAreaListKredit{
    width:calc(12% - 2px);
    margin-left:2px;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom:1px solid map-get($generalStyles,datalistBorderColor);
   // border:1px solid grey;
    margin-top:3px;
}

.fakturaAreaD{
    grid-area:d;
    width:100%;
    height:21vh;
    background-color: white;
   // border:1px solid grey;
}
.fakturaFooterTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.fakturaFooterWrapper{
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.fakturaFooterHeader{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
}
.fakturaFooterHeadertext{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
    font-family: var(--font-family);    
    font-size: 12px;
}
.fakturaFooterHeadertextMid{
    width:32%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    font-family: var(--font-family);    
    font-size: 12px;
    margin-left:7%;
}
.fakturaFooterHeadertextMid1{
    width:60%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    font-family: var(--font-family);    
    font-size: 12px;
}
.fakturaFooterHeadertextIban{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    font-family: var(--font-family);    
    font-size: 12px;
    margin-left:7%;
}
.fakturaPayWrapper{
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:5px;


}
.fakturaPayNetSum{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    font-family: var(--font-family); 
    // font-family:lobster;
    font-size:18px;
    margin-top:5px;
    color:black;
}
.fakturaPayVAT{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    font-family: var(--font-family);    
    font-size: 18px;
    margin-top:5px;
    white-space: nowrap;
    color:black;
}
.fakturaPayTotal{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    font-family: var(--font-family);    
    font-size: 26px;
    margin-top:5px;
    color:grey;
}
.fakturaPayTotal1{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    font-family: var(--font-family);    
    font-size: 30px;
    margin-top:5px;
}
.fakturaPayTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
.abfakturaPrintButton{
    width:150px;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
    margin-top:5px;
    margin-bottom:3px;
    margin-left:3px;
    color:black;

}

// tjanster debitering

.tjansterDebTop{
    width:100%;
    display:flex;
    flex-direction:column;
    //justify-content:center;
    align-items:center;
}
.tjansterDebWrapper{
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.tjansterDebHeaderText{
    @include gadgets-to(mobile) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
   // font-style: italic;
    margin-bottom:3px;
    color:black;
}
@include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:1vh;
  //  font-style: italic;
    margin-bottom:3px;
    color:black;
}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top:1vh;
   // font-style: italic;
    margin-bottom:3px;
    color:black;
}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top:1vh;
   // font-style: italic;
    margin-bottom:3px;
    color:black;
}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 16px;
    margin-top:1vh;
   // font-style: italic;
    margin-bottom:3px;
    color:black;
}
}


.tjansterDebListWrapper{
    @include gadgets-to(mobile) {
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}
@include gadgets-to(tablet) {
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(laptop) {
    width: 90%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(desktop) {
    width: 90%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(largeDesktop) {
    width: 80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
}

.tjansterDebRow{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    @include gadgets-to(mobile) {
    margin-top:1px;
    width:100%;
}
@include gadgets-to(tablet) {
  margin-top:1px;
}
@include gadgets-to(laptop) {
    margin-top:1px;
}
@include gadgets-to(desktop) {
    margin-top:1px;
}
@include gadgets-to(largeDesktop) {
    margin-top:1px;
}
}


@mixin serviceStyle($width, $height, $font-size, $cursor,$radius) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius:$radius;
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.tjansterDebService {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include serviceStyle(8%, 25px, 10px, default,3px);
    
    }
    @include gadgets-to(tablet) {
        @include serviceStyle(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(laptop) {
        @include serviceStyle(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include serviceStyle(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include serviceStyle(12%, 25px, 12px, default,3px);
    }
}

.tjansterDebService1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include serviceStyle(8%, 25px, 10px, default,3px);
    }
    @include gadgets-to(tablet) {
        @include serviceStyle(12%, 25px, 12px,default,3px);
    }
    @include gadgets-to(laptop) {
        @include serviceStyle(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include serviceStyle(12%, 25px, 12px,default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include serviceStyle(12%, 25px, 12px, default,3px);
    }
}



// @mixin serviceStyle($height, $fontSize, $cursor) {
//     width: 15%;
//     height: $height;
//     background-color: map-get($generalStyles, transparentChangeableMenu4);
//     border-radius: map-get($generalStyles, sixBorderRadius);
//     padding: 1px;
//     font-family: var(--font-family);
//     font-size: $fontSize;
//     padding-left: 3px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: $cursor;

    

// }

// .tjansterDebService {
//     margin-bottom:3px;
//     @include gadgets-to(mobile) {
//         @include serviceStyle(20px, 10px, pointer);
//     }
//     @include gadgets-to(tablet) {
//         @include serviceStyle(25px, 12px, pointer);
//     }
//     @include gadgets-to(laptop) {
//         @include serviceStyle(25px, 12px, pointer);
//     }
//     @include gadgets-to(desktop) {
//         @include serviceStyle(25px, 12px, pointer);
//     }
//     @include gadgets-to(largeDesktop) {
//         @include serviceStyle(25px, 12px, pointer);
//     }
// }

// .tjansterDebService1 {
//     margin-bottom:3px;
  
//     @include gadgets-to(mobile) {
//         @include serviceStyle(20px, 10px, none);
//     }
//     @include gadgets-to(tablet) {
//         @include serviceStyle(25px, 12px, none);
//     }
//     @include gadgets-to(laptop) {
//         @include serviceStyle(25px, 12px, none);
//     }
//     @include gadgets-to(desktop) {
//         @include serviceStyle(25px, 12px, none);
//     }
//     @include gadgets-to(largeDesktop) {
//         @include serviceStyle(25px, 12px, none);
//     }
// }

@mixin tjansterDebBenaemnMixin($height, $font-size, $cursor,$justify,$paddingleft,$textalign,$radius) {
    width: calc(30% - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius:$radius;
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content:$justify;
    align-items: center;
    cursor: $cursor;
    padding-left:$paddingleft;
    // here i want to have 2 dots when the text is too long
   // display:block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   line-height: 2;
   text-align:$textalign
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
 
}

.tjansterDebBenaemn {
    margin-bottom:2px;
    margin-left:10px;
    @include gadgets-to(mobile) {
        @include tjansterDebBenaemnMixin(25px, 10px,default,flex-start,10px,left,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebBenaemnMixin(25px, 12px,default,flex-start,10px,left,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebBenaemnMixin(25px, 12px,default,flex-start,10px,left,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebBenaemnMixin(25px, 12px, default,flex-start,10px,lef,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebBenaemnMixin(25px, 12px, default,flex-start,10px,left,3px);
    }
}

.tjansterDebBenaemn1 {
    margin-bottom:3px;
    justify-content: center;
    @include gadgets-to(mobile) {
        @include tjansterDebBenaemnMixin(25px, 10px, default,center,10px,center,3px);
         
    }
    @include gadgets-to(tablet) {
        @include tjansterDebBenaemnMixin(25px, 12px, default,center,10px,cente1,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebBenaemnMixin(25px, 12px, default,center,10px,center,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebBenaemnMixin(25px, 12px, default,center,10px,center,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebBenaemnMixin(25px, 12px, default,center,10px,center,3px);
    }
}
@mixin tjansterDebMixin($width, $height, $font-size, $justify-content, $cursor,$radius) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius:$radius;
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: $justify-content;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.tjansterDebQty {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include tjansterDebMixin(10%, 25px, 10px, center, default,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebMixin(10%, 25px, 12px, center, default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebMixin(10%, 25px, 12px, center, default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebMixin(10%, 25px, 12px, center, default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebMixin(10%, 25px, 12px, center, default,3px);
    }
}

.tjansterDebQty1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include tjansterDebMixin(10%, 25px, 10px, center, default,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebMixin(10%, 25px, 12px, center,default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebMixin(10%, 25px, 12px, center,default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebMixin(10%, 25px, 12px, center,default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebMixin(10%, 25px, 12px, center,default,3px);
    }
}

@mixin tjansterDebMaxMixin($width, $height, $font-size, $cursor,$radius) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius:$radius;
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.tjansterDebMax {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include tjansterDebMaxMixin(12%, 25px, 10px, default,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
}

.tjansterDebMax1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include tjansterDebMaxMixin(12%, 25px, 10px, default,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebMaxMixin(12%, 25px, 12px, default,3px);
    }
}
@mixin tjansterDebActiveMixin($width, $height, $font-size, $cursor,$radius) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius:$radius;
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.tjansterDebActive {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include tjansterDebActiveMixin(22%, 25px, 10px,default,3px);
        // here we need to have dots when text is to long
     //   display:block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        line-height: 2;
        align-self:center;
        

    }
    @include gadgets-to(tablet) {
        @include tjansterDebActiveMixin(15%, 25px, 12px, default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebActiveMixin(15%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebActiveMixin(15%, 25px, 12px, default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebActiveMixin(15%, 25px, 12px, default,3px);
    }
}

.tjansterDebActive1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include tjansterDebActiveMixin(22%, 25px, 10px,default,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebActiveMixin(15%, 25px, 12px,default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebActiveMixin(15%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebActiveMixin(15%, 25px, 12px,default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebActiveMixin(15%, 25px, 12px,default,3px);
    }
}


@mixin tjansterDebUsableMixin($width, $height, $font-size, $cursor,$radius) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius:$radius;
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.tjansterDebUsable {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include tjansterDebUsableMixin(10%, 25px, 10px, default,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebUsableMixin(10%, 25px, 12px, default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebUsableMixin(10%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebUsableMixin(10%, 25px, 12px,default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebUsableMixin(10%, 25px, 12px, default,3px);
    }
}

.tjansterDebUsable1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include tjansterDebUsableMixin(10%, 25px, 10px, default,3px);
    }
    @include gadgets-to(tablet) {
        @include tjansterDebUsableMixin(10%, 25px, 12px, default,3px);
    }
    @include gadgets-to(laptop) {
        @include tjansterDebUsableMixin(10%, 25px, 12px, default,3px);
    }
    @include gadgets-to(desktop) {
        @include tjansterDebUsableMixin(10%, 25px, 12px, default,3px);
    }
    @include gadgets-to(largeDesktop) {
        @include tjansterDebUsableMixin(10%, 25px, 12px, default,3px);
    }
}

.supportHeaderTop{
    width:100%;
    display:flex;
    flex-direction:column;
 //   justify-content:center;
    align-items:center;
}
.supportHeaderWrapper{
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}


.supportTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 20px;
}
.supportMessageWrapper{
    width:95%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.supportMessageSelect{
    width:50%;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    font-family: var(--font-family);
    font-size: 12px;
    margin-bottom: 3px;
    margin-top:5px;
    outline:none;
    background-color: white;
}
.supportMessageHeader{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size:12px;
    margin-top:1vh;
    font-style: italic;
    margin-bottom:3px;
    color:grey;
}
.supportMessageInputWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.supportMessageTextArea{
    width:80%;
    height:140px;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    margin-bottom: 3px;
    margin-top:5px;
    outline:none;
    resize:none;
}
.supportMessageButtonWrapper{
    width:90%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.supportMessageButton{
    width:50%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
    margin-top:5px;
    margin-bottom:3px;
    color:black;
    // hover
    &:hover{
        background-color: map-get($generalStyles,pinkRed);
        color:white;
    }
    &:active{
        background-color: map-get($generalStyles,transparentChangeableMenu6);
    }

}.supportMessageButton1{
    width:90%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
    margin-top:5px;
    margin-bottom:3px;
    color:black;
    opacity:0.7;
    cursor:not-allowed;
    // hover
    &:hover{
        background-color: map-get($generalStyles,pinkRed);
        color:white;
    }
    //active
    &:active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    }
    //inactive


}

.supportHistoryTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.supportHistoryHeaderWrapper{
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.supportHistoryHeader{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 20px;
}
.supportHistoryListWrapper{
    width:95%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.supportHistoryList{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}

@mixin supportHistoryIdMixin($width, $height, $font-size, $cursor) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.supportHistoryServiceId {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include supportHistoryIdMixin(10%, 20px, 10px, default);
    }
    @include gadgets-to(tablet) {
        @include supportHistoryIdMixin(10%, 25px, 12px, default);
    }
    @include gadgets-to(laptop) {
        @include supportHistoryIdMixin(10%, 25px, 12px, default);
    }
    @include gadgets-to(desktop) {
        @include supportHistoryIdMixin(10%, 25px, 12px,default);
    }
    @include gadgets-to(largeDesktop) {
        @include supportHistoryIdMixin(10%, 25px, 12px, default);
    }
}

.supportHistoryServiceId1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include supportHistoryIdMixin(10%, 20px, 10px, default);
    }
    @include gadgets-to(tablet) {
        @include supportHistoryIdMixin(10%, 25px, 12px, default);
    }
    @include gadgets-to(laptop) {
        @include supportHistoryIdMixin(10%, 25px, 12px, default);
    }
    @include gadgets-to(desktop) {
        @include supportHistoryIdMixin(10%, 25px, 12px, default);
    }
    @include gadgets-to(largeDesktop) {
        @include supportHistoryIdMixin(10%, 25px, 12px, default);
    }
}

@mixin supportHistoryDateMixin($width, $height, $font-size, $cursor) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
   
}
.supportHistoryCreateDate {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include supportHistoryDateMixin(40%, 20px, 10px, pointer);
        // hover
        &:hover{
            background-color: map-get($generalStyles,pinkRed);
            color:white;
        }
    }
    @include gadgets-to(tablet) {
        @include supportHistoryDateMixin(40%, 25px, 12px,pointer);
        // hover
        &:hover{
            background-color: map-get($generalStyles,pinkRed);
            color:white;
        }
    }
    @include gadgets-to(laptop) {
        @include supportHistoryDateMixin(40%, 25px, 12px, pointer);
        // hover
        &:hover{
            background-color: map-get($generalStyles,pinkRed);
            color:white;
        }
    }
    @include gadgets-to(desktop) {
        @include supportHistoryDateMixin(40%, 25px, 12px,pointer);
        // hover
        &:hover{
            background-color: map-get($generalStyles,pinkRed);
            color:white;
        }
    }
    @include gadgets-to(largeDesktop) {
        @include supportHistoryDateMixin(40%, 25px, 12px, pointer);
        // hover
        &:hover{
            background-color: map-get($generalStyles,pinkRed);
            color:white;
        }
    }
}
.supportHistoryCreateDate1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include supportHistoryDateMixin(40%, 20px, 10px, default);
    }
    @include gadgets-to(tablet) {
        @include supportHistoryDateMixin(40%, 25px, 12px, default);
    }
    @include gadgets-to(laptop) {
        @include supportHistoryDateMixin(40%, 25px, 12px, default);
    }
    @include gadgets-to(desktop) {
        @include supportHistoryDateMixin(40%, 25px, 12px, default);
    }
    @include gadgets-to(largeDesktop) {
        @include supportHistoryDateMixin(40%, 25px, 12px, default);
    }
}

@mixin supportHistoryStatusMixin($width, $height, $font-size, $cursor) {
    width: calc(#{$width} - 2px);
    margin-left: 2px;
    height: $height;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    border-radius: map-get($generalStyles, sixBorderRadius);
    padding: 1px;
    font-family: var(--font-family);
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: $cursor;
    // border: 1px solid map-get($generalStyles, datalistBorderColor);
}

.supportHistoryStatus {
    margin-bottom:2px;
    @include gadgets-to(mobile) {
        @include supportHistoryStatusMixin(30%, 20px, 10px, default);
    }
    @include gadgets-to(tablet) {
        @include supportHistoryStatusMixin(30%, 25px, 12px, default);
    }
    @include gadgets-to(laptop) {
        @include supportHistoryStatusMixin(30%, 25px, 12px, default);
    }
    @include gadgets-to(desktop) {
        @include supportHistoryStatusMixin(30%, 25px, 12px,default);
    }
    @include gadgets-to(largeDesktop) {
        @include supportHistoryStatusMixin(30%, 25px, 12px, default);
    }
}
.supportHistoryStatus1 {
    margin-bottom:3px;
    @include gadgets-to(mobile) {
        @include supportHistoryStatusMixin(30%, 20px, 10px, default);
    }
    @include gadgets-to(tablet) {
        @include supportHistoryStatusMixin(30%, 25px, 12px, default);
    }
    @include gadgets-to(laptop) {
        @include supportHistoryStatusMixin(30%, 25px, 12px, default);
    }
    @include gadgets-to(desktop) {
        @include supportHistoryStatusMixin(30%, 25px, 12px, default);
    }
    @include gadgets-to(largeDesktop) {
        @include supportHistoryStatusMixin(30%, 25px, 12px, default);
    }
}

.supportShowMessageTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.supportShowMessageWrapper{
    width:95%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.supportShowMessageHeader{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-family: var(--font-family);
    font-size: 14px;
    margin-top:2vh;
}
.supportShowMessageTextArea{
    width:80%;
    height:140px;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    margin-bottom: 3px;
    margin-top:5px;
    outline:none;
    resize:none;
    @include gadgets-to(tablet) {
        width:90%;
        height:25vh;
    }
}

// .payPalInsideTop{
//     width:90%;
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:center;

// }
// .payPalSelectWrapper{
//     width:100%;
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:center;
// }


// .payPalSelect{
//     width:50%;
//     border-color:map-get($generalStyles,datalistBorderColor);
//     border-radius: map-get($generalStyles,sixBorderRadius);
//     height:25px;
//     font-family: var(--font-family);
//     font-size: 12px;
//     margin-bottom: 3px;
//     margin-top:5px;
//     outline:none;
//     background-color: white;

// }

// .payPalWrapperInside{
//     @include gadgets-to(mobile) {
//         width:100%;
//         background-color:transparent;
//         height:85vh;
//         max-height:85vh;
//         margin-top:3px;
//         border-radius:3px;
//         overflow-y:scroll;
//         // small webkit scroll
//         &::-webkit-scrollbar {
//           display:none;
//         }
//       }
//         @include gadgets-to(tablet) {
//             width:70%;
//             background-color:transparent;
//             height:65vh;
//             max-height:85vh;
//             margin-top:3px;
//             border-radius:3px;
//             overflow-y:scroll;
//             // small webkit scroll
//             &::-webkit-scrollbar {
//             display:none;
//             }
//         }
//         @include gadgets-to(laptop) {
//             width:70%;
//             background-color:transparent;
//             height:65vh;
//             max-height:85vh;
//             margin-top:3px;
//             border-radius:3px;
//             overflow-y:scroll;
//             // small webkit scroll
//             &::-webkit-scrollbar {
//             display:none;
//             }
//         }
//         @include gadgets-to(desktop) {
//             width:70%;
//             background-color:transparent;
//             height:65vh;
//             max-height:85vh;
//             margin-top:3px;
//             border-radius:3px;
//             overflow-y:scroll;
//             // small webkit scroll
//             &::-webkit-scrollbar {
//             display:none;
//             }
//         }
//         @include gadgets-to(largeDesktop) {
//             width:70%;
//             background-color:transparent;
//             height:85vh;
//             max-height:85vh;
//             margin-top:3px;
//             border-radius:3px;
//             overflow-y:scroll;
//             // small webkit scroll
//             &::-webkit-scrollbar {
//             display:none;
//             }
//         }

// }
// .payPalBakesoftHeaderWrapperInside{
// width:100%;
// display:flex;
// flex-direction:column;
// justify-content:center;
// align-items:center;


// }
// .insidepayPalBakesoftHeader{
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:center;
// width:100%;
// font-family:var(--font-family);
// font-size:14px;
// font-weight:bold;
// }

// .payPalCurrencyWrapper{
//     width:100%;
//     display:flex;
//     flex-direction:row;
//     justify-content:center;
//     align-items:center;
//     margin-top:5px;
// }
.ppBakesoftTextWrapperInside{
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 5px;
    border:2px dotted grey;
    width:99%;

}
.ppBakesoftButtonWrapperInside{
    @include gadgets-to(mobile) {
    width:100%;
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    height:60vh;
    max-height:60vh;
    overflow-y:auto;
    // small webkit scroll
    &::-webkit-scrollbar {
     display:none;
    }

}
@include gadgets-to(tablet) {
    width:100%;
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    height:60vh;
    max-height:60vh;
    overflow-y:auto;
    // small webkit scroll
    &::-webkit-scrollbar {
      width:5px;
    }
    // color for the bar and handle
    &::-webkit-scrollbar-thumb {
      background: map-get($generalStyles,transparentChangeableMenu5);
    }

    &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
    }
}
@include gadgets-to(laptop) {
    width:100%;
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    height:60vh;
    max-height:60vh;
    overflow-y:auto;
    // small webkit scroll
    &::-webkit-scrollbar {
      width:5px;
    }
    // color for the bar and handle
    &::-webkit-scrollbar-thumb {
      background: map-get($generalStyles,transparentChangeableMenu5);
    }

    &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
    }
}
@include gadgets-to(desktop) {
    width:100%;
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    height:60vh;
    max-height:60vh;
    overflow-y:auto;
    // small webkit scroll
    &::-webkit-scrollbar {
      width:5px;
    }
    // color for the bar and handle
    &::-webkit-scrollbar-thumb {
      background: map-get($generalStyles,transparentChangeableMenu5);
    }

    &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
    }
}
@include gadgets-to(largeDesktop) {
    width:100%;
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    height:60vh;
    max-height:60vh;
    overflow-y:auto;
    // small webkit scroll
    &::-webkit-scrollbar {
      width:5px;
    }
    // color for the bar and handle
    &::-webkit-scrollbar-thumb {
      background: map-get($generalStyles,transparentChangeableMenu5);
    }

    &::-webkit-scrollbar-track {
      background: map-get($generalStyles,transparentChangeableMenu4);
    }
}
}
    

.payPalButtonWrapperInside{
    width:100%;
   // display:flex;
    // flex-direction:row;
    // justify-content:center;
    // align-items:center;
    margin-top:5px;
}








.myAccountContainer{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}

.myaccountHamburgerButton {
    position: absolute;
    top: 5px;
    right: 25px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000; /* Ensure the button is above other content */
}

.myaccountHamburgerButton1 {
    position: absolute;
    top: 5px;
    right:5px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000; /* Ensure the button is above other content */
}

.myaccountSlidingMenu {
    position: fixed;
    top: 0;
    right: -300px; /* Adjust width as needed to ensure it is completely off-screen */
    width: 250px;
    height:100vh;
    background-color:white;
    box-shadow: -2px 0 15px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items:center;
    z-index: 999; /* Ensure the menu is above other content */
    border-radius:3px;
  
}

.myaccountSlidingMenu.open {
    right: 0;
}

.amenupersonal, .amenupersonal1 {
    margin: 10px 0;
    padding: 3px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    


    a {
        color: inherit; /* Ensure links inherit the button's color */
        text-decoration: none; /* Remove underline from links */
    }

    &:active {
        background-color: #ddd; /* Change background color when clicked */
        color: #000; /* Change text color when clicked */
    }
}

.amenupersonal1 {
    font-weight: bold;
}

// .myAccountbackgroundImageContainer{
//     background-size: cover; /* Adjusts the image to cover the entire container */
//     background-position: center; /* Centers the image */
//     width:100%;
//     height:100%;
//     border:2px solid black;
//     opacity:0.5;
// }
.myAccountButtonContainer{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

// .klarnaTop{
//     width:100%;
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:center;
//     margin-top:1vh;
// }
// .klarnaInitialButton{
//     width:30%;
//     height:35px;
//     display:flex;
//     flex-direction:row;
//     justify-content:center;
//     align-items:center;
//     margin-top:5px;
//     background-color:#0B051D;
//     border-radius:6px;
//     font-family: var(--font-family);
//     font-size: 12px;
//     cursor:pointer;
//     border:none;
//     color:#F9F8F5;
//     outline:none;

// @include gadgets-to(mobile) {
// width:100%;
// }
// @include gadgets-to(tablet) {
// width:100%;

// }
// @include gadgets-to(laptop) {
// width:100%;
// }
// @include gadgets-to(desktop) {
// width:100%;
// }
// @include gadgets-to(largeDesktop) {
// width:40%;
// }
// }

// .klarnaMessage{
//     width:50%;
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:flex-end;
//     font-family: var(--font-family);
//     font-size: 13px;
//  //   font-weight: bold;
//     margin-top:1vh;
//   //  font-style: italic;
//     margin-bottom:3px;
//     color:#F9F8F5;
// }
// .klarnalogoWrapper{
//     width:40%;
//     height:35px;
//     display:flex;
//     flex-direction:column;
//     justify-content:center;
//     align-items:flex-start;
//     //margin-left:1vh;

// }

// .klarnaButtonImage{
//     @include gadgets-to(mobile) {
//     width:70%;
//     height: auto;

//     @media(min-width:410px) and (max-width:413px){
//         width:60%;
//         height: auto;
//     }

// }
// @include gadgets-to(tablet) {
//     width:70%; /* Adjust the size as needed */
//     height: auto;

// }
// @include gadgets-to(laptop) {
//     width:60%; /* Adjust the size as needed */
//     height: auto;

// }
// @include gadgets-to(desktop) {
//     width:60%; /* Adjust the size as needed */
//     height: auto;

// }
// @include gadgets-to(largeDesktop) {
//     width:70%; /* Adjust the size as needed */
//     height: auto;

// }
// }
.supportShowMessageButton{
    width:80%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
    margin-top:5px;
    margin-bottom:3px;
    color:black;
    // hover
    &:hover{
        background-color: map-get($generalStyles,pinkRed);
        color:white;
    }
    &:active{
        background-color: map-get($generalStyles,transparentChangeableMenu6);
    }


    @include gadgets-to(tablet) {
        width:90%;
        height:30px;
    }

}


.supportShowMessageButton1{
    width:50%;
    height:25px;
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    border-radius: map-get($generalStyles,sixBorderRadius);
    font-family: var(--font-family);
    font-size: 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    cursor:pointer;
    margin-top:5px;
    margin-bottom:3px;
    color:black;
    opacity:0.7;
    cursor:not-allowed;
    // hover
    &:hover{
        background-color: map-get($generalStyles,pinkRed);
        color:white;
    }
    //active
    &:active{
        background-color: map-get($generalStyles,transparentChangeableMenu5);
    }
    //inactive
}


.generalPaymentTop{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
}
.generalPaymentHeaderWrapper{
    width:95%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}
.generalPaymentHeaderText{
    font-family: var(--font-family);
    font-size: 18px;
    @include gadgets-to(mobile) {
        font-family: var(--font-family);
        font-size: 14px;
    }
}
.generalPaymentBoxTopWrapper{
    width:95%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
    margin-bottom:1vh;
}
.generalPaymentBoxTop{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.generalPaymentSelect{
    width:50%;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    height:25px;
    font-family: var(--font-family);
    font-size: 14px;
    margin-bottom:3px;
    margin-top:5px;
    outline:none;
    background-color: white;
    @include gadgets-to(mobile) {
        width:95%;
        border-color:map-get($generalStyles,datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        height:25px;
        font-family: var(--font-family);
        font-size: 12px;
        margin-bottom:3px;
        margin-top:5px;
        outline:none;
        background-color: white;
    }
}
.generalPaymentInfo{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.generalPaymentInfoBoxWrapper{
    width:70%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
    border:2px dotted grey;
    background-color: white;
    @include gadgets-to(mobile) {
        width:95%;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        margin-top:5px;
        border:2px dotted grey;
        background-color: white;
    }
}
.generalPaymentInfoBox{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.generalPaymentInfoText{
    font-family: var(--font-family);
    font-size: 16px;
    margin-top:5px;
    margin-bottom:3px;
    color:black;
    @include gadgets-to(mobile) {
        font-family: var(--font-family);
        font-size: 12px;
        margin-top:5px;
        margin-bottom:3px;
        color:black;
    }
}

.generalPaymentFormTopWrapper{
    width:70%;
    background-color: white;
    height:auto;
    max-height:40vh;
    overflow-y:auto;
    overflow-x:hidden;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width:5px;
      }
      // color for the bar and handle
      &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,transparentChangeableMenu5);
      }
  
      &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
      }
@include gadgets-to(mobile) {
    width:95%;
    background-color: white;
    height:40vh;
    max-height:40vh;
    overflow-y:auto;
    overflow-x:hidden;
    // webkit scrollbar
    &::-webkit-scrollbar {
        width:5px;
      }
      // color for the bar and handle
      &::-webkit-scrollbar-thumb {
        background: map-get($generalStyles,transparentChangeableMenu5);
      }
  
      &::-webkit-scrollbar-track {
        background: map-get($generalStyles,transparentChangeableMenu4);
      }
}
}   

.completePageWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.infocompleteWrapper{
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
    font-size: 20px;
    font-family: var(--font-family);
}
.completePageIcon{
width:30px;
height:30px;
color:green;
margin-bottom:1vh;

}
.failPageIcon{
    width:30px;
    height:30px;
    color:red;
    margin-bottom:1vh;
    
    }
.cbcompletePageWrapper{
    width:100%;
    height:50vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.cbinfocompleteWrapper{
    width:95%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
    font-size: 16px;
    font-family: var(--font-family);
}

.invoiceHistoryListIcon{
    width:20px;
    height:20px;
    color:white;
    cursor:pointer;
}
.invoiceHistoryListIconPending{
    width:20px;
    height:20px;
    color:red;
}

.receipt-embed-wrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:5px;
}

.xjLiveUserImageWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:100%;
    margin-top:5px;
}



.xjLive-tabs-container {
    width: 100%;
    max-width:100%;
    height:85vh;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.xjLive-tabs-header {
    display: flex;
    justify-content: space-around;
    position: relative;
    width:95%;
}

.xjLive-tab {
    padding: 4px 10%;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    color:black;
    transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
    border: 2px solid transparent;
    border-top: 2px solid transparent;
  //  border:1px solid map-get($generalStyles,datalistBorderColor);
    border-bottom:unset;
    white-space: nowrap;
    @include gadgets-to(mobile) {
        padding: 4px 10px;
    }
}

.xjLive-tab:hover {
    color:black;
}

.xjLive-tab.xjLive-active {
    color:black;
    font-weight: bold;
  //  border-top: 2px solid #007bff;
    border-bottom: 2px solid black;
   // border:1px solid black;
    font-family: var(--font-family);
    font-size:16px;
}

.xjLive-tabs-content {
    padding: 20px;
    border: 1px solid white;
    border-top:1px solid black;
    background-color: #f9f9f9;
   // border-radius: 0 0 8px 8px;
   @include gadgets-to(mobile) {
    padding: 2px;

}
}
