:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
.wwtotalTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwtotalArea {
    display: grid;
    grid-template-rows: 99%;
    grid-template-areas: "b";
    width: 100%;
    height: 90vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwtotalArea {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b" "a b";
    -moz-column-gap: 10px;
         column-gap: 10px;
    width: 99%;
    height: 80vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .wwtotalArea {
    display: grid;
    grid-template-columns: 20% 78%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b" "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 98%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .wwtotalArea {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: auto auto;
    grid-template-areas: "a b" "a b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 99%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .wwtotalArea {
    display: grid;
    grid-template-columns: 99%;
    grid-template-areas: "b";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 98%;
    height: 90vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.wwbackgroundwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  background-color: var(--val);
  border-radius: 6px;
  margin-top: 2vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwbackgroundwrapper {
    height: 90vh;
    width: 100%;
    margin-top: unset;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwrangeArea {
    grid-area: a;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 10vh;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .wwrangeArea {
    height: 5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .wwrangeArea {
    height: 5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .wwrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .wwrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 5vh;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .wwrangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 5vh;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwrange1, .wwkrange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwrange1, .wwkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .wwrange1, .wwkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .wwrange1, .wwkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .wwrange1, .wwkrange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .wwkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .wwkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .wwkrange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.wwleftAreaWrapper {
  grid-area: a;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 75vh;
  margin-left: 1vw;
  margin-top: 2vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwleftAreaWrapper {
    margin-top: unset;
    height: 71vh;
  }
}

.wwrightAreaWrapper {
  grid-area: b;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 75vh;
  margin-top: 2vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwrightAreaWrapper {
    margin-top: unset;
    height: 70vh;
  }
}

.wwbuttonArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  height: 15vh;
  padding-top: 2vh;
  box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.5);
  background-color: white;
}

.wwbutton {
  grid-area: a;
  width: 90%;
  height: 40px;
  background-color: var(--rgbaVal);
  color: white;
  font-size: 12px;
  font-weight: 200;
  font-family: var(--font-family);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  transition: 0.4s;
}
.wwbutton:hover {
  background-color: var(--rgbaVal);
  border: 2px solid var(--rgbaVal);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.wwsearchSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 58%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.wwsearchSpinner {
  color: black;
}

.wwlivexvspinner-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 58%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 20%;
  height: 5vh;
  font-size: 16px;
  font-family: var(--font-family);
  background-color: white;
  border-radius: 12px;
  border: 3px solid rgb(225, 176, 68);
  padding: 3px;
  font-size: 12px;
  font-family: Lobster;
  box-shadow: 12px 12px 8px rgba(0, 0, 0, 0.5);
  z-index: 2006;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwlivexvspinner-text {
    width: 80%;
    height: 4vh;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwlivexvspinner-text {
    width: 40%;
    height: 6vh;
    font-size: 14px;
  }
}

.wwLiveloading-dots {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 3px;
  width: 40%;
}
.wwLiveloading-dots span {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  background-color: #f8b400;
  display: inline-block;
  animation: loading2 1s infinite ease-in-out;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  transform: rotate(90deg);
}
.wwLiveloading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.wwLiveloading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.wwLiveloading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading2 {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.areaBLogo {
  grid-area: b;
  font-size: 140px;
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  color: rgba(255, 255, 255, 0.8);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .areaBLogo {
    font-size: 70px;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .areaBLogo {
    font-size: 140px;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .areaBLogo {
    font-size: 140px;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .areaBLogo {
    font-size: 140px;
  }
}
@media only screen and (min-width:2303px) {
  .areaBLogo {
    font-size: 140px;
  }
}

.areaBTop {
  grid-area: b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 87vh;
  margin-top: 2vh;
  background-color: var(--val);
  border-radius: 6px;
}

.wwLogoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: var(--val);
  border-radius: 6px;
  margin-top: 2vh;
  font-family: "Lobster";
}

.wwServicesAreaTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid rgb(223, 212, 212);
  height: 50vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.5);
}

.wwServicesAreaHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 3vh;
  color: black;
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 200;
  padding: 2vh;
  padding-left: 3vw;
}

.wwServiceHeadertext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 100;
  font-family: var(--font-family);
  color: black;
  margin-bottom: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwServiceHeadertext {
    font-size: 12px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwServiceHeadertext {
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .wwServiceHeadertext {
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .wwServiceHeadertext {
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .wwServiceHeadertext {
    font-size: 16px;
    margin-top: 5px;
  }
}

.wwServicesAreaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  border: 1px solid rgb(223, 212, 212);
  height: auto;
  box-shadow: 12px 12px 10px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
}

.wwServicesRow, .wwServicesRow1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  color: black;
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 200;
  z-index: 99;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwServicesRow, .wwServicesRow1 {
    width: 95%;
    height: 6vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwServicesRow, .wwServicesRow1 {
    width: 95%;
    height: 6vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .wwServicesRow, .wwServicesRow1 {
    width: 95%;
    height: 6vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .wwServicesRow, .wwServicesRow1 {
    width: 95%;
    height: 6vh;
  }
}
@media only screen and (min-width:2303px) {
  .wwServicesRow, .wwServicesRow1 {
    width: 95%;
    height: 6vh;
  }
}

.wwServicesRow1 {
  background-color: lightgrey;
  border-radius: 10px;
  color: red;
}

.wwServicesInvoice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: black;
  width: 15%;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
  padding-left: 1vw;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwServicesInvoice {
    font-size: 12px;
  }
}

.wwServicesService {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  width: 15%;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
}

.wwServicesDueDate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10%;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
}

.wwServicesAmount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwServicesAmount {
    font-size: 12px;
  }
}

.wwServicesBenaemn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 30%;
  font-size: 16px;
  font-weight: 200;
  font-family: var(--font-family);
  padding-left: 2vw;
}

.wwServicesCloseIcon {
  font-size: 25px;
  color: black;
  cursor: pointer;
}

.wwServicesButtonWrapper {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 1vw;
}

.wwServicesButton {
  width: 8vw;
  height: 30px;
  background-color: rgb(229, 64, 68);
  color: white;
  font-size: 12px;
  font-weight: 200;
  font-family: var(--font-family);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.wwServicesButton:hover {
  background-color: var(--rgbaVal);
  border: 2px solid var(--rgbaVal);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .wwServicesButton {
    width: 15vw;
  }
}

.wwExtraInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: red;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 200;
  padding: 2vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .wwExtraInfo {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .wwExtraInfo {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .wwExtraInfo {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .wwExtraInfo {
    font-size: 16px;
  }
}/*# sourceMappingURL=welcomeimage.css.map */