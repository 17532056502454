@import '../style/MainStyles.scss';/* styles.css */

html {
    scrollbar-width: thin; /* For Firefox */
    overflow: overlay; /* For Chrome, Safari */
  }




.xetotalArea {
    @include gadgets-to(mobile) {
    display: grid;
    grid-template-rows: 5% 3% 17% 28.5% 43.5%;
    grid-template-areas:
        "a"
        "b"
        "c"
        "d"
        "e";
        ;
    width: 100%;
    height: 710px;
    object-fit: cover;
}

@include gadgets-to(tablet) {
    // display:grid;
    // grid-template-columns:20% 40% 40%;
    // grid-template-areas:
    //   "a b c";
    //   width:100%;
    //   height:100%;
    // object-fit: cover;
    display: grid;
    grid-template-columns:20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@include gadgets-to(laptop) {
    display: grid;
    grid-template-columns:20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;

}
@include gadgets-to(desktop) {
    display: grid;
    grid-template-columns:20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;

}
@include gadgets-to(largeDesktop) {
    display: grid;
    grid-template-columns:20% 40% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
}


.xetotalArea1{
@include gadgets-to(mobile) {

        display: grid;
        grid-template-rows: 5% 3% 17% 28.5% 43.5%;
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "e";
            ;
        width: 100%;
        height: 710px;
        object-fit: cover;
    background-color: map-get($generalStyles,backgroundColor);
    }

@include gadgets-to(tablet) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: map-get($generalStyles,backgroundColor);

}
@include gadgets-to(laptop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: map-get($generalStyles,backgroundColor);

}
@include gadgets-to(desktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: map-get($generalStyles,backgroundColor);

}
@include gadgets-to(largeDesktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "a a a"
      "b b b"
      "c d e";
      column-gap:7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: map-get($generalStyles,backgroundColor);
       
}
}



.xeheaderArea{
@include gadgets-to(mobile) {
grid-area:a;
display:flex;
flex-direction: row;
justify-content: center;
margin-top:2px;
}
@include gadgets-to(tablet) {
grid-area:a;
    display:flex;
    flex-direction: row;
    justify-content:center;
    margin-top:2px;
    margin-left:1px;
    
    }
    @include gadgets-to(laptop) {
        grid-area:a;
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:2px;
        margin-left:1px;
        
        }
    @include gadgets-to(desktop) {
        grid-area:a;
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:2px;
        margin-left:1px;
        
        }
    @include gadgets-to(largeDesktop) {
        grid-area:a;
        display:flex;
        flex-direction: row;
        justify-content:center;
        margin-top:2px;
        margin-left:1px;
        
        
}
}



// headernames
.xecompName {
    @include gadgets-to(mobile) {
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: 26px;
    color:white;    
    }
@include gadgets-to(tablet) {
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: 26px;
    color:white;

}
@include gadgets-to(laptop) {
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: 40px;
    color:white;
}
@include gadgets-to(desktop) {
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: 40px;
    color:white;
}
@include gadgets-to(largeDesktop) {
    font-family: map-get($generalStyles, fontFamilyLobster);
    font-size: 50px;
    color:white;
}
}



.xecompNameJAP{
    @include gadgets-to(mobile) {
      font-family: var(--font-family);
        font-size: 40px;
        color:white;    
        }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 40px;
        color:white;
    
    }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 40px;
        color:white;
    }
@include gadgets-to(desktop) {
  font-family: var(--font-family);
    font-size: 40px;
    color:white;
}
@include gadgets-to(largeDesktop) {
  font-family: var(--font-family);
    font-size: 50px;
    color:white;
}
}
.xecompNameJAP1{
    @include gadgets-to(mobile) {
      font-family: var(--font-family);
        font-size: 40px;
        color:map-get($generalStyles, transparentChangeableMenu5);    
        }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 40px;
        color:map-get($generalStyles, transparentChangeableMenu5); 
    
    }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 40px;
        color:map-get($generalStyles, transparentChangeableMenu5); 
    
}
@include gadgets-to(desktop) {
  font-family: var(--font-family);
    font-size: 40px;
    color:map-get($generalStyles, transparentChangeableMenu5); 
}
@include gadgets-to(largeDesktop) {
  font-family: var(--font-family);
    font-size: 50px;
     color:map-get($generalStyles, transparentChangeableMenu5); 
}
}



.xecompName1 {
    @extend .xecompName;
    @include gadgets-to(mobile) {
    color:white;
    }
    @include gadgets-to(tablet) {
        color:white;
        }

    @include gadgets-to(laptop) {
        color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(desktop) {
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(largeDesktop) {
    color:map-get($generalStyles,transparentChangeableMenu5);
}
}




// .xecompNameJAP, .xecompNameJAP1 {
//     @extend .xecompNameJAP;
//     @include gadgets-to(mobile) {
//   font-family: var(--font-family);
//     color: map-get($generalStyles, transparentChangeableMenu5);
//     }
//     @include gadgets-to(tablet) {
//       font-family: var(--font-family);
//         color: map-get($generalStyles, transparentChangeableMenu5);
//         }
//         @include gadgets-to(laptop) {
//           font-family: var(--font-family);
//             color: map-get($generalStyles, transparentChangeableMenu5);
//             }
//     @include gadgets-to(desktop) {
//       font-family: var(--font-family);
//         color: map-get($generalStyles, transparentChangeableMenu5);
//         }
//     @include gadgets-to(largeDesktop) {
//       font-family: var(--font-family);
//         color: map-get($generalStyles, transparentChangeableMenu5);
//         }
// }
// //-------------------


.xerangeArea{

@include gadgets-to(mobile) {
display:flex;
flex-direction: row;
justify-content:space-between;
grid-area:b;
}

@include gadgets-to(tablet) {
    grid-area:b;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    margin-right:1%;
    }
    @include gadgets-to(laptop) {
        grid-area:b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        margin-right:1%;
        }
    @include gadgets-to(desktop) {
        grid-area:b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        margin-right:1%;
        }
    @include gadgets-to(largeDesktop) {
        grid-area:b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        margin-right:1%;
        }

}

.xerange1 {
    @include gadgets-to(mobile) {
accent-color:blue;
cursor:pointer;
    }
    @include gadgets-to(tablet) {
        accent-color:blue;
        cursor:pointer;
 }
 @include gadgets-to(laptop) {
    accent-color:blue;
    cursor:pointer;
 }
@include gadgets-to(desktop) {
    accent-color:blue;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    accent-color:blue;
    cursor:pointer;
}
}

 
 .xerange2 {
    @include gadgets-to(mobile) {
accent-color: rgb(251, 239, 132);
margin-right:10px;
cursor:pointer;
 }
 @include gadgets-to(tablet) {
    accent-color: rgb(251, 239, 132);
    margin-right:10px;
    cursor:pointer;
 }
 @include gadgets-to(laptop) {
    accent-color: rgb(251, 239, 132);
    margin-right:10px;
    cursor:pointer;
 }
@include gadgets-to(desktop) {
    accent-color: rgb(251, 239, 132);
    margin-right:10px;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    accent-color: rgb(251, 239, 132);
    margin-right:10px;
    cursor:pointer;
}
 }




.xeareaA {
    @include gadgets-to(mobile) {
    margin-top:5px;
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius:map-get($generalStyles, sixBorderRadius);
    width: 100%;
    background-color: map-get($generalStyles, transparentChangeable);

    }
    @include gadgets-to(tablet) {
        width: 100%;
        height:76vh;
        margin-top:5px;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius:map-get($generalStyles, sixBorderRadius);
       
        background-color: map-get($generalStyles, transparentChangeable);
        
        }
@include gadgets-to(laptop) {
    width: 100%;
    height:80vh;
    margin-top:5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius:map-get($generalStyles, sixBorderRadius);
    
    background-color: map-get($generalStyles, transparentChangeable);
    
    }
@include gadgets-to(desktop) {
    width: 100%;
    height:80vh;
    margin-top:5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius:map-get($generalStyles, sixBorderRadius);
    
    background-color: map-get($generalStyles, transparentChangeable);
    
    }
@include gadgets-to(largeDesktop) {
    width: 100%;
    height:80vh;
    margin-top:5px;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius:map-get($generalStyles, sixBorderRadius);
    
    background-color: map-get($generalStyles, transparentChangeable);
    
    }
}

.xeareaMenuButton{
grid-area:c;
}

.xeareaB {
    @extend .xeareaA;
@include gadgets-to(mobile) {
    grid-area:d;
 
}
@include gadgets-to(tablet) {
    grid-area:d;
    }
    @include gadgets-to(laptop) {
        grid-area:d;
        }
    @include gadgets-to(desktop) {
        grid-area:d;
        }
@include gadgets-to(largeDesktop) {
    grid-area:d;
    }


}

.xeareaC {
    grid-area:e;
    @extend .xeareaA;
    
    @include gadgets-to(tablet){
        width:95%;
    }
    @include gadgets-to(laptop){
        width:95%;
    }
    @include gadgets-to(desktop){
        width:95%;
    }
 @include gadgets-to(largeDesktop){
        width:95%;
    }
}






.xeingredSpinnerContainer {

    @include gadgets-to(mobile) {
       position: absolute;
        top: 368px;
        left: 170px;
        background-color: transparent;
        transform: scale(2.5);
  
    }

    @include gadgets-to(tablet) {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%) scale(2);
        z-index: 1; // low value when not active
    
        &.active {
            z-index: 9999; // high value when active
        }
    }
    @include gadgets-to(laptop) {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%) scale(2);
        z-index: 1; // low value when not active
    
        &.active {
            z-index: 9999; // high value when active
        }
    }
    @include gadgets-to(desktop) {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%) scale(2);
        z-index: 1; // low value when not active
    
        &.active {
            z-index: 9999; // high value when active
        }
    }
    @include gadgets-to(largeDesktop) {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%) scale(2);
        z-index: 1; // low value when not active
    
        &.active {
            z-index: 9999; // high value when active
        }
    }
}



.hrsettings {
    width: 350px;
    height: 2px;
    background-color: transparent;
}

.xeingredmenubuttonsTop {
    @include gadgets-to(mobile) {   
    grid-area: a;
    border-color: map-get($generalStyles,datalistBorderColor);
}
@include gadgets-to(tablet) {
 grid-area:a;
 margin-top:20%;
    }
    @include gadgets-to(laptop) {
        grid-area:a;
        margin-top:20%;
        }
    @include gadgets-to(desktop) {
        grid-area:a;
        margin-top:20%;
        }
    @include gadgets-to(largeDesktop) {
        grid-area:a;
        margin-top:20%;
        }
}

.xeingredmenubuttonsData {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction:column;
       // flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        width: 100%;
        }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction:column;
       // flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        width: 100%;
        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction:column;
       // flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        width: 100%;
        }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction:column;
       // flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        width: 100%;
        }
}

.xecommonButtonStyle {
  //  margin: 2px;
@include gadgets-to(mobile) {
    @media(max-width:376px) {
        font-size:11px;
    }

width:calc(33% - 3px);
display:flex;
flex-direction: row;
flex-wrap:wrap;
align-items: center;
justify-content: center;
margin :2px;
height: 30px;
border-radius: map-get($generalStyles,sixBorderRadius);
color: map-get($generalStyles, colorblack);
font-family:var(--font-family);
font-size: 12px;
border: 1px solid lightgray;
cursor: pointer;
transition: 0.3s;
}


@include gadgets-to(tablet) {
width:70%;
display:flex;
flex-direction: row;
flex-wrap:wrap;
align-items: center;
justify-content: center;
margin :2px;
height: 30px;
border-radius: map-get($generalStyles,sixBorderRadius);
color: map-get($generalStyles, colorblack);
font-family:var(--font-family);
font-size: 12px;
border: 1px solid lightgray;
cursor: pointer;
transition: 0.3s;
}

@include gadgets-to(laptop) {
    width:70%;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    margin :2px;
    height: 30px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    font-family:var(--font-family);
    font-size: 12px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
    }
@include gadgets-to(desktop) {
    width:70%;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    margin :2px;
    height: 45px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    font-family:var(--font-family);
    font-size: 18px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
    }
@include gadgets-to(largeDesktop) {
    width:70%;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    margin :2px;
    height: 50px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    font-family:var(--font-family);
    font-size: 20px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s;
    }

}

.xemenubuttonStyle {
    @extend .xecommonButtonStyle;
    @include gadgets-to(mobile) {
   background-color: white;
    }
    @include gadgets-to(tablet) {
        background-color: white;
         }
    @include gadgets-to(laptop) {
        background-color: white;
         }
    @include gadgets-to(desktop) {
        background-color: white;
         }
         @include gadgets-to(largeDesktop) {
            background-color: white;
             }
    
}

.xemenuactivebuttonStyle {
    @extend .xecommonButtonStyle;
    @include gadgets-to(mobile) {
    background-color:map-get($generalStyles,transparentChangeableMenu4);
    }
    @include gadgets-to(tablet) {
        background-color:map-get($generalStyles,transparentChangeableMenu4);
        }
    @include gadgets-to(laptop) {
        background-color:map-get($generalStyles,transparentChangeableMenu4);
        }
    @include gadgets-to(desktop) {
        background-color:map-get($generalStyles,transparentChangeableMenu4);
        }
    @include gadgets-to(largeDesktop) {
        background-color:map-get($generalStyles,transparentChangeableMenu4);
        }
}


.xeareaKategoriSelect{
    @include gadgets-to(mobile) {
        margin-top:1px;
    }
    @include gadgets-to(tablet) {
        margin-top:1px;
        display:flex;
        flex-direction:column;
        justify-content: center;
    }   
    @include gadgets-to(laptop) {
        margin-top:1px;
        display:flex;
        flex-direction:column;
        justify-content: center;
    }
    @include gadgets-to(desktop) {
        margin-top:1px;
        display:flex;
        flex-direction:column;
        justify-content: center;
    }
@include gadgets-to(largeDesktop) {
    margin-top:1px;
    display:flex;
    flex-direction:column;
    justify-content: center;

}
}


// .xeareaKategoriSelectSimple {
//     // Add your styles here
// }

.xekatfieldset {
    @include gadgets-to(mobile) {
   margin-top:5px;
    border:none;
    }
    @include gadgets-to(tablet) {
        margin-top:10px;
        border:none;
        }  
    @include gadgets-to(laptop) {
        margin-top:10px;
        border:none;
        } 
    @include gadgets-to(desktop) {
        margin-top:20px;
        border:none;
        display:flex;
        flex-direction: row;
        justify-content: center;
    }
@include gadgets-to(largeDesktop) {
    margin-top:20px;
    border:none;
    display:flex;
    flex-direction: row;
    justify-content: center;
}
}

.xekatlegend {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 12px;
        font-style: italic;
        }   
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        margin-left:10%;
        }
@include gadgets-to(largeDesktop) {
    font-family:var(--font-family);
    font-size: 20px;
    font-style: italic;
    margin-left:10%;
}
}

.xekategoriselect {
    @include gadgets-to(mobile) {
    width: 100%;
    height: 30px;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    background-color: white;
    font-family:var(--font-family);
    }
    @include gadgets-to(tablet) {
        width: 100%;
        height: 30px;
        border: 1px solid map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        background-color: white;
        font-family:var(--font-family);
        }
    @include gadgets-to(laptop) {
        width: 100%;
        height: 30px;
        border: 1px solid map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        background-color: white;
        font-family:var(--font-family);
        }
    @include gadgets-to(desktop) {
        width:80%;
        height: 40px;
        border: 1px solid map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        background-color: white;
        font-family:var(--font-family);
        }
@include gadgets-to(largeDesktop) {
    width:80%;
    height: 40px;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    background-color: white;
    font-family:var(--font-family);
    font-size:16px;
}

}


.axeconnRecepyFieldset {
    @include gadgets-to(mobile) {
    border: none;
    margin-top: 10px;
    margin-left: 12px;
    }
    @include gadgets-to(tablet) {
        border: none;
        margin-top: 15px;
        margin-left: 12px;
        }
    @include gadgets-to(laptop) {
        border: none;
        margin-top: 20px;
        margin-left: 12px;
        }
    @include gadgets-to(desktop) {
        border: none;
        margin-top: 25px;
        margin-left: 12px;
        }
@include gadgets-to(largeDesktop) {
    border: none;
    margin-top: 25px;
    margin-left: 12px;
}
    }

.axeconnRecepyLegend {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
        width:75%;
        font-family:var(--font-family);
        font-size: 12px;
        font-style: italic;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    @include gadgets-to(laptop) {
        width:75%;
        font-family:var(--font-family);
        font-size: 12px;
        font-style: italic;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    @include gadgets-to(desktop) {
        width:85%;
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    @include gadgets-to(largeDesktop) {
        width:85%;
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
}

.axedeleteIngredientTop{
    @include gadgets-to(mobile) {
        margin-top:1px;
    }
    @include gadgets-to(tablet) {
        margin-top:1px;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction: row;
        justify-content: center;
        margin-top:1px;
    }
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top:1px;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top:1px;
}
}

.xesimpleButton {
    @include gadgets-to(mobile) {

    border: 1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: map-get($generalStyles, colorblack);
  font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    }
@include gadgets-to(tablet) {
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: map-get($generalStyles, colorblack);
  font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    }
@include gadgets-to(laptop) {
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 6px;
    color: map-get($generalStyles, colorblack);
  font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
    }
@include gadgets-to(desktop) {
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 10px;
    color: map-get($generalStyles, colorblack);
  font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
    }
@include gadgets-to(largeDesktop) {
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    border-radius:8px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 12px;
    color: map-get($generalStyles, colorblack);
  font-family: var(--font-family);
    font-size: 18px;
    cursor: pointer;
    }
}

.xesimpleButton1 {
@include gadgets-to(mobile) {
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: map-get($generalStyles, colorwhite);
  font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
}

@include gadgets-to(tablet) {
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 3px;
    color: map-get($generalStyles, colorwhite);
  font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    }
@include gadgets-to(laptop) {
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 6px;
    color: map-get($generalStyles,black);
  font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
    }
@include gadgets-to(desktop) {
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 10px;
    color: map-get($generalStyles,black);
  font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
    }
@include gadgets-to(largeDesktop) {
    border:1px solid;
    border-color:map-get($generalStyles,datalistBorderColor);
    border-radius:8px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 12px;
    color: map-get($generalStyles,black);
  font-family: var(--font-family);
    font-size: 18px;
    cursor: pointer;
    }
}

.xeingredientListTop {
    @include gadgets-to(mobile) {
margin-top:5px;
border:none;
    }
@include gadgets-to(tablet) {
        margin-top:5px;
  
            }
    @include gadgets-to(laptop) {
        margin-top:5px;
  
            }
    @include gadgets-to(desktop) {
        margin-top:5px;
}
@include gadgets-to(largeDesktop) {
    margin-top:5px;
}
}


.axeingredientListTop {
    @include gadgets-to(mobile) {
    margin-top:5px;
    border:none;
    }
        @include gadgets-to(laptop) {
            margin-top:5px;
            border:none;
            }
    @include gadgets-to(desktop) {
        margin-top:5px;
        border:none;

    }
@include gadgets-to(largeDesktop) {
    margin-top:5px;
    border:none;
    }
}

    .axbingredientListTtop{
        @include gadgets-to(mobile) {
        margin-top:5px;
        border:none;
        }
        }


.axeingredientListData {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top:3px;
    height:auto;
    min-height:10px;
    max-height:120px;
    overflow-y: scroll;
    width:98%;
    margin-left:1vh;
    border:none;
    }

    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top:3px;
        height:auto;
        min-height:10px;
        max-height:70vh;
        overflow-y: scroll;
        width:98%;
        margin-left:1vh;
        border:none;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white; /* Adjust as needed */
        }
        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top:3px;
        height:auto;
        min-height:10px;
        max-height:70vh;
        overflow-y: scroll;
        width:98%;
        margin-left:1vh;
        border:none;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white; /* Adjust as needed */
        }
        }
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top:3px;
    height:auto;
    min-height:10px;
    max-height:70vh;
    overflow-y: scroll;
    width:98%;
    margin-left:1vh;
    border:none;
    &::-webkit-scrollbar {
        width: 10px; /* Adjust as needed */
    }
    
    &::-webkit-scrollbar-track {
        background: map-get($generalStyles, transparentChangeableMenu5);
    }
    
    &::-webkit-scrollbar-thumb {
        background:white; /* Adjust as needed */
    }
    }

    
}


.xeingredientListData3 {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top:3px;
    height:auto;
    min-height:10px;
    max-height:110px;
    overflow-y: scroll;
    width:98%;
    margin-left:1vh;
    border:none;    
    overflow-x: hidden;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top:3px;
        height:auto;
        min-height:10px;
        max-height:65vh;
        overflow-y: scroll;
        width:98%;
        margin-left:1vh;
        border:none;

        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }

        }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top:3px;
        height:auto;
        min-height:0px;
        max-height:65vh;
        overflow-y:scroll;
        width:98%;
        margin-left:1vh;
        border:none;

        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }

        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top:3px;
        height:auto;
        min-height:0px;
        max-height:65vh;
        overflow-y:scroll;
        width:98%;
        margin-left:1vh;
        border:none;

        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }

        }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top:3px;
        height:auto;
        min-height:0px;
        max-height:65vh;
        overflow-y:scroll;
        width:98%;
        margin-left:1vh;
        border:none;

        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }

        }

}


.xeingredientListData {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top:3px;
    height:auto;
    min-height:10px;
    max-height:110px;
    overflow-y: scroll;
    width:98%;
    margin-left:1vh;
    border:none;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top:3px;
        height:auto;
        min-height:10px;
        max-height:110px;
        overflow-y: scroll;
        width:98%;
        margin-left:1vh;
        border:none;
        }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top:3px;
        height:auto;
        min-height:10px;
        max-height:110px;
        overflow-y: scroll;
        width:100%;
        margin-left:1vh;
        border:none;
        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top:3px;
        height:auto;
        min-height:10px;
        max-height:110px;
        overflow-y: scroll;
        width:100%;
        margin-left:1vh;
        border:none;

}
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top:3px;
    height:auto;
    min-height:10px;
    max-height:110px;
    overflow-y: scroll;
    width:100%;
    margin-left:1vh;
    border:none;

}
}


.xeingredientListData2 {
    @extend .xeingredientListData;
    @include gadgets-to(mobile) {
    margin-top: 10px;
    min-height:5px;
    max-height: 110px;
    }
    @include gadgets-to(tablet) {
        margin-top: 10px;
        min-height:5px;
      //  height:auto;
        max-height:65vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }

        }
    @include gadgets-to(laptop) {
        width:98%;
        margin-top: 10px;
        min-height:5px;
        max-height:65vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content:center;
        width:98%;
        margin-top: 10px;
        min-height:5px;
        max-height:65vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content:center;
        width:98%;
        margin-top: 10px;
        min-height:5px;
        max-height:65vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }

}

.axbingredientListData2 {
    @extend .xeingredientListData;
    @include gadgets-to(mobile) {
    margin-top:1px;
    max-height: 120px;
    }
    @include gadgets-to(tablet) {
        margin-top:1px;
        max-height:65vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }

        }
    @include gadgets-to(laptop) {
        width:95%;
        margin-top:1px;
        min-height:5px;
        max-height:55vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }

        }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content:center;
        width:95%;
        margin-top:1px;
       margin-left:2%;
        min-height:5px;
        max-height:55vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content:center;
        width:95%;
        margin-top:1px;
       margin-left:2%;
        min-height:5px;
        max-height:55vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }
        }

}


.xeingredNew1{
    height:0;
    @include gadgets-to(mobile) {
transform:rotate(0deg);
animation-name:xeingredientmobile;
animation-duration: 1s;
animation-iteration-count: 1;
animation-fill-mode: forwards;
}

@include gadgets-to(tablet) {
    transform:rotate(0deg);
    animation-name:xeingredienttablet;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }        
@include gadgets-to(laptop) {
    transform:rotate(0deg);
    animation-name:xeingredientlaptop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }                 

@include gadgets-to(desktop) {
    transform:rotate(0deg);
    animation-name:xeingredientdesktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }   
@include gadgets-to(largeDesktop) {
    transform:rotate(0deg);
    animation-name:xeingredientLargedesktop;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }
}              


@keyframes xeingredientmobile{
0%{ height:0px }
100%{height:115px}
}

@keyframes xeingredienttablet{
    0%{ height:0px }
    100%{height:100px}
    }
@keyframes xeingredientlaptop{
        0%{ height:0px }
        100%{height:120px}
 }
 @keyframes xeingredientdesktop{
    0%{ height:0px }
    100%{height:135px}
}
    
@keyframes xeingredientLargedesktop{
    0%{ height:0px }
    100%{height:112px}
    }



    .ingredNew{
       
        transform:rotate(0deg);
        animation-name:ingredientNew;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        
          }
    
          @keyframes ingredientNew {
            0%{ height:0px }
            100%{height:102px}
            }

.xeingredNew2{
    @include gadgets-to(mobile) {
    transform:rotate(0deg);
    animation-name:xeingredientNew62mobile;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    }
    @include gadgets-to(tablet) {
        transform:rotate(0deg);
        animation-name:xeingredientNew62tablet;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        }
    @include gadgets-to(laptop) {
        transform:rotate(0deg);
        animation-name:xeingredientNew62laptop;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        }
    @include gadgets-to(desktop) {
        transform:rotate(0deg);
        animation-name:xeingredientNew62desktop;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        }
    @include gadgets-to(largeDesktop) {
        transform:rotate(0deg);
        animation-name:xeingredientNew62Largedesktop;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        }    
      }

@keyframes xeingredientNew62mobile{
0%{ height:0px }
100%{height:158px}
}

@keyframes xeingredientNew62tablet{
    0%{ height:0px }
    100%{height:138px}
    }
@keyframes xeingredientNew62laptop{
        0%{ height:0px }
        100%{height:138px}
}
@keyframes xeingredientNew62desktop{
    0%{ height:0px }
    100%{height:138px}
}
@keyframes xeingredientNew62Largedesktop{
    0%{ height:0px }
    100%{height:154px}
}



.xeeditIngredientBox1 { 
    @include gadgets-to(mobile) {
    width:100%;
    height:14vh;
   // border: 1px solid map-get($generalStyles, datalistBorderColor);
    border-radius: 3px;
    margin-top: 5px;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        width:100%;
        height:10vh;
       // border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: 3px;
        margin-top: 5px;
        border:none;   
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        width:100%;
        height:10vh;
       // border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: 3px;
        margin-top: 5px;
        border:none;   
    }
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
    height:11vh;
   // border: 1px solid map-get($generalStyles, datalistBorderColor);
    border-radius: 3px;
    margin-top: 5px;
    border:none;   
    }
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    width:100%;
    height:11vh;
   // border: 1px solid map-get($generalStyles, datalistBorderColor);
    border-radius: 3px;
    margin-top: 5px;
    border:none;   
    }
}

.xeeditIngredientInput {
    @include gadgets-to(mobile) {
    width:100%;
    height:30px;
  font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid map-get($generalStyles, datalistBorderColor);
    outline: none;
    }

    @include gadgets-to(tablet) {
        width:90%;
        height:25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       // border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        outline: none;

        }
    @include gadgets-to(laptop) {
        width:98%;
        height:30px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       // border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        outline: none;
        }
    @include gadgets-to(desktop) {
        width:98%;
        height:30px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       // border:none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        outline: none;
        }
@include gadgets-to(largeDesktop) {
    width:98%;
    height:30px;
  font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color:map-get($generalStyles, datalistBorderColor);
   // border:none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    outline: none;
    }
}

.xxeeditIngredientFieldset1{
    @include gadgets-to(mobile) {
    margin-top:1px;
    width:200px;
    height:12vh;
    border:none;
    }
    @include gadgets-to(tablet) {
        margin-top:1px;
        width:100%;
        height:10vh;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
    @include gadgets-to(laptop) {
        margin-top:1px;
        width:98%;
        height:10vh;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
    @include gadgets-to(desktop) {
        margin-top:1px;
        width:100%;
        height:10vh;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
   @include gadgets-to(largeDesktop) {
        margin-top:1px;
        width:100%;
        height:6vh;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
}

.xeeditIngredientLegend1 {
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 12px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 12px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
      font-family: var(--font-family);
        font-size: 12px;
        font-style: italic;
        }
    @include gadgets-to(largeDesktop) {
      font-family: var(--font-family);
        font-size: 12px;
        font-style: italic;
        }
}

.xeEditIngredientButton {
   @include gadgets-to(mobile) {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    
    cursor: pointer;
   }
   @include gadgets-to(tablet) {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    
    cursor: pointer;
   }
   @include gadgets-to(laptop) {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    cursor: pointer;
   }
   @include gadgets-to(desktop) {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    cursor: pointer;
   }
   @include gadgets-to(largeDesktop) {
    width: 100px;
    height: 30px;
    margin-top: 4px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    cursor: pointer;
   }

}
.xebuttonplacer{
    @include gadgets-to(tablet){
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
    }
    @include gadgets-to(laptop){
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
    }
    @include gadgets-to(desktop){
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
    }
    @include gadgets-to(largeDesktop){
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
    }
}
.editIngredientButton {
    @extend .xeEditIngredientButton;
    @include gadgets-to(mobile) {
    margin-left:53%;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: black;
    }
    @include gadgets-to(tablet) {
        //margin-left:53%;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: black;
        }
    @include gadgets-to(laptop) {
        //margin-left:53%;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: black;
        }
    @include gadgets-to(desktop) {
        //margin-left:53%;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: black;
        }
    @include gadgets-to(largeDesktop) {
        //margin-left:53%;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: black;
        }
}

.editIngredientButton1 {
    @extend .xeEditIngredientButton;
    @include gadgets-to(mobile) {
    margin-left:53%;
    background-color: map-get($generalStyles, pinkRed);
    color: white;
    }
    @include gadgets-to(tablet) {
       // margin-left:53%;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        }
    @include gadgets-to(laptop) {   
        //margin-left:53%;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        }
    @include gadgets-to(desktop) {
        //margin-left:53%;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        }
    @include gadgets-to(largeDesktop) {
        //margin-left:53%;
        background-color: map-get($generalStyles, pinkRed);
        color: white;
        }
    }



.xenutrientHeaderStyle{
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 12px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles,sixBorderRadius);
    margin-top: 10px;
    }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 12px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:5px;
        }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 12px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:5px;
    
        }
    @include gadgets-to(desktop) {
      font-family: var(--font-family);
        font-size: 12px;
        border: none;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top:5px;
        }
@include gadgets-to(largeDesktop) {
  font-family: var(--font-family);
    font-size: 12px;
    border: none;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    border-radius: map-get($generalStyles,sixBorderRadius);
    margin-top:5px;
    }
}

.xeheaderNutrient1 {
    @extend .xenutrientHeaderStyle;
    @include gadgets-to(mobile) {
    width:60%;
    height:25px;
    }
    @include gadgets-to(tablet) {
        width:60%;
        height:25px;
        color:map-get($generalStyles,colorblack);
        }
    @include gadgets-to(laptop) {
        width:60%;
        height:25px;
        cursor:pointer;

        }
    @include gadgets-to(desktop) {
        width:60%;
        height:25px;
        cursor:pointer;
        }
@include gadgets-to(largeDesktop) {
    width:60%;
    height:25px;
    cursor:pointer;
    }
}

.xeheaderQuantity1 {
    @extend .xenutrientHeaderStyle;
    @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    }
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height:25px;
        color:map-get($generalStyles,colorblack);
        }
    @include gadgets-to(laptop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height:25px;
        cursor:pointer;
        }
    @include gadgets-to(desktop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height:25px;
        cursor:pointer;
        }
@include gadgets-to(largeDesktop) {
    width:calc(20% - 2px);
    margin-left:2px;
    height:25px;
    cursor:pointer;
}
    }

.xeheaderUnit1 {
    @extend .xenutrientHeaderStyle;
    @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left: 2px;
    height:25px;
    }
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left: 2px;
        height:25px;
        color:map-get($generalStyles,colorblack);
        }
    @include gadgets-to(laptop) {
        width:calc(20% - 2px);
        margin-left: 2px;
        height:25px;
        cursor:pointer;
        }
    @include gadgets-to(desktop) {
        width:calc(20% - 2px);
        margin-left: 2px;
        height:25px;
        cursor:pointer;
        }
@include gadgets-to(largeDesktop) {
    width:calc(20% - 2px);
    margin-left: 2px;
    height:25px;
    cursor:pointer;
    }
}

.xedataTopStart{
    display:flex;
    flex-direction: row;
    justify-content: center;


}

.xedataTop{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    align-items:center;
    border:none;
    width:90%;
    margin-top:1px;
    min-height:5px;
    max-height:22vh;
    overflow-x:hidden;
    overflow-y:scroll;
    
    &::-webkit-scrollbar {
        width: 1px; /* Adjust as needed */
      }
      &::-webkit-scrollbar-track {
        background:black;
      }
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction: column;
        align-items:center;
        border:none;
        width:90%;
        margin-top:1px;
        min-height:5px;
        max-height:60vh;
        overflow-x:hidden;
        overflow-y:scroll;
        
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:black;
          }
        }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction: column;
        align-items:center;
        border:none;
        width:90%;
        margin-top:1px;
        min-height:5px;
        max-height:65vh;
        overflow-x:hidden;
        overflow-y:scroll;
        cursor:pointer;
        &::-webkit-scrollbar {
            width: 5px; /* Adjust as needed */
            cursor:pointer;
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);

          }
            &::-webkit-scrollbar-thumb {
                background:white;
        }
    }
@include gadgets-to(desktop) {
    display:flex;
    flex-direction: column;
    align-items:center;
    border:none;
    width:90%;
    margin-top:1px;
    min-height:5px;
    max-height:65vh;
    overflow-x:hidden;
    overflow-y:scroll;
    cursor:pointer;
    &::-webkit-scrollbar {
        width: 5px; /* Adjust as needed */
        cursor:pointer;
      }
      &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);

      }
        &::-webkit-scrollbar-thumb {
            background:white;
    }
    }
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction: column;
    align-items:center;
    border:none;
    width:90%;
    margin-top:1px;
    min-height:5px;
    max-height:65vh;
    overflow-x:hidden;
    overflow-y:scroll;
    cursor:pointer;
    &::-webkit-scrollbar {
        width: 5px; /* Adjust as needed */
        cursor:pointer;
      }
      &::-webkit-scrollbar-track {
        background:map-get($generalStyles,transparentChangeableMenu5);

      }
        &::-webkit-scrollbar-thumb {
            background:white;
    }
    }


}


.xefoodrowsarea{
    display:flex;
    flex-direction: row;
    align-items: center;
     justify-content: center;
     width:100%;
}


.xenutrientValue2 {
    @include gadgets-to(mobile) {
    width:60%;
    height:20px;
  font-family: var(--font-family);
    font-size: 14px;
    border:none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }
    @include gadgets-to(tablet) {
        width:60%;
        height:20px;
      font-family: var(--font-family);
        font-size: 14px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        outline: none;
        margin-top: 2px;
        text-align: left;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color:map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        width:60%;
        height:20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       
        border-radius: map-get($generalStyles, threeBorderRadius);
        outline: none;
        margin-top: 2px;
        text-align: left;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    @include gadgets-to(desktop) {
        width:60%;
        height:20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       
        border-radius: map-get($generalStyles, threeBorderRadius);
        outline: none;
        margin-top: 2px;
        text-align: left;
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    
@include gadgets-to(largeDesktop) {
    width:60%;
    height:20px;
  font-family: var(--font-family);
    font-size: 14px;
    border:1px solid;
    border-color:map-get($generalStyles, datalistBorderColor);
   
    border-radius: map-get($generalStyles, threeBorderRadius);
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }

}

.xenutrientValue3 {
    @include gadgets-to(mobile) {
    width:60%;  
    height:20px;
  font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    outline: none;
    margin-top: 2px;
    text-align: left;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }
    @include gadgets-to(tablet) {
        width:60%;  
        height:20px;
      font-family: var(--font-family);
        font-size: 14px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        outline: none;
        margin-top: 2px;
        text-align: left;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color:map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        width:60%;  
        height:20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       
        border-radius: map-get($generalStyles, threeBorderRadius);
        outline: none;
        margin-top: 2px;
        text-align: left;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    @include gadgets-to(desktop) {
        width:60%;  
        height:20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       
        border-radius: map-get($generalStyles, threeBorderRadius);
        outline: none;
        margin-top: 2px;
        text-align: left;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    @include gadgets-to(largeDesktop) {
        width:60%;  
        height:20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
       
        border-radius: map-get($generalStyles, threeBorderRadius);
        outline: none;
        margin-top: 2px;
        text-align: left;
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    }



.xenutrientQuantity2 {
  @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height: 20px;
  font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, colorwhite);
    margin-top: 2px;
    text-align: left;
    }
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
        margin-top: 2px;
        text-align: left;
        color:map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
        margin-top: 2px;
        text-align: left;
        }
    @include gadgets-to(desktop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
        margin-top: 2px;
        text-align: left;
        }
    @include gadgets-to(largeDesktop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
        margin-top: 2px;
        text-align: left;
        }
    
    background-color: map-get($generalStyles, colorwhite);

    }



.xenutrientQuantity3 {
    @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height: 20px;
  font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, colorwhite);
    margin-top: 2px;
    text-align: left;
    }
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
        margin-top: 2px;
        text-align: left;
        color:map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
     
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
        margin-top: 2px;
        text-align: left;
        }
    @include gadgets-to(desktop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
     
        border-radius: map-get($generalStyles, threeBorderRadius);
        margin-top: 2px;
        text-align: left;
    }
    @include gadgets-to(largeDesktop) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
     
        border-radius: map-get($generalStyles, threeBorderRadius);
        margin-top: 2px;
        text-align: left;
    }
    background-color: map-get($generalStyles, colorwhite);
}   




.xenutrientUnit2 {
 @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height: 20px;
  font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
 
    margin-top: 2px;
    text-align: left;
    }
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        margin-top: 2px;
        text-align: left;
        color:map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        width:calc(20% - 5px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
     
        margin-top: 2px;
        text-align: left;
        }
    @include gadgets-to(desktop) {
        width:calc(20% - 5px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
     
        margin-top: 2px;
        text-align: left;
        }
  @include gadgets-to(largeDesktop) {
        width:calc(20% - 5px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
     
        margin-top: 2px;
        text-align: left;
        }
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}

.xenutrientUnit3 {
     @include gadgets-to(mobile) {
    width:calc(20% - 2px);
    margin-left:2px;
    height: 20px;
  font-family: var(--font-family);
    font-size: 14px;
    border: none;
    border-radius: map-get($generalStyles, threeBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
 
    margin-top: 2px;
    text-align: left;
    }
    @include gadgets-to(tablet) {
        width:calc(20% - 2px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border: none;
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color:map-get($generalStyles, colorblack);
        margin-top: 2px;
        text-align: left;
        }
    @include gadgets-to(laptop) {
        width:calc(20% - 5px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
     
        margin-top: 2px;
        text-align: left;
        }
    @include gadgets-to(desktop) {
        width:calc(20% - 5px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
     
        margin-top: 2px;
        text-align: left;
        }
   @include gadgets-to(largeDesktop) {
        width:calc(20% - 5px);
        margin-left:2px;
        height: 20px;
      font-family: var(--font-family);
        font-size: 14px;
        border:1px solid;
        border-color:map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, threeBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu4);
     
        margin-top: 2px;
        text-align: left;
        }
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}

.axxeDeleteMessageButton {
    @include gadgets-to(mobile) {
    width:59%;
    height: 40px;
    border: 1px solid;
    margin-left:1%;
    border-color: map-get($generalStyles, datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
  font-family: var(--font-family);
    font-size: 12px;
  //  margin-left: 5px;
    }
    @include gadgets-to(tablet) {
        width:59%;
        height: 40px;
        border: 1px solid;
        margin-left:2%;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family: var(--font-family);
        font-size: 12px;
      //  margin-left: 5px;
        }
    @include gadgets-to(laptop) {
        width:59%;
        height: 40px;
        border: 1px solid;
        margin-left:2%;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family: var(--font-family);
        font-size: 12px;
      //  margin-left: 5px;
        }
    @include gadgets-to(desktop) {
        width:59%;
        height: 40px;
        border: 1px solid;
        margin-left:2%;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family: var(--font-family);
        font-size: 12px;
      //  margin-left: 5px;
        }
    @include gadgets-to(largeDesktop) {
        width:59%;
        height: 40px;
        border: 1px solid;
        margin-left:2%;
        border-color: map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
      font-family: var(--font-family);
        font-size: 12px;
      //  margin-left: 5px;
        }
}

.axxedeleteMessageButton5 {
    @extend .axxeDeleteMessageButton;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: black;
    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:white;
        }
    @include gadgets-to(laptop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:white;
        }
    @include gadgets-to(desktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color:white;
        }
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color:white;
    }
}

.axxedeleteMessageButton6 {
    @extend .axxeDeleteMessageButton;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, colorwhite);
    border-color:none;
    text-align: center;
    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, colorwhite);
        border-color:none;
        text-align: center;
        }
    @include gadgets-to(laptop) {
        background-color: map-get($generalStyles, colorwhite);
        border-color:none;
        text-align: center;
        }
    @include gadgets-to(desktop) {
        background-color: map-get($generalStyles, colorwhite);
        border-color:none;
        text-align: center;
        }
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, colorwhite);
    border-color:none;
    text-align: center;

    }
}

.axxeDeleteButton {
    @include gadgets-to(mobile) {
    margin-left: 10px;
    width:39%;
    height: 40px;
    margin-right:1%;
    border-radius: map-get($generalStyles,sixBorderRadius);
    border:none;
    outline: none;
    color: map-get($generalStyles, colorwhite);
    }

    @include gadgets-to(tablet) {
        margin-left:4px;
        width:41%;
        height:40px;
        margin-right:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        border:none;
        outline: none;
        color: map-get($generalStyles, colorwhite);
        }
    @include gadgets-to(laptop) {
        margin-left:4px;
        width:41%;
        height:40px;
        margin-right:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        border:none;
        outline: none;
        color: map-get($generalStyles, colorwhite);
        }
    @include gadgets-to(desktop) {
        margin-left:4px;
        width:41%;
        height:40px;
        margin-right:1%;
        border-radius: map-get($generalStyles,sixBorderRadius);
        border:none;
        outline: none;
        color: map-get($generalStyles, colorwhite)        

}

@include gadgets-to(largeDesktop) {
    margin-left:4px;
    width:41%;
    height:40px;
    margin-right:1%;
    border-radius: map-get($generalStyles,sixBorderRadius);
    border:none;
    outline: none;
    color: map-get($generalStyles, colorwhite);
    }
}


.axxeingredDeleteButton4 {
    @extend .axxeDeleteButton;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, pinkRed);
    border:none;
    opacity: 0.4;
    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, pinkRed);
        border:none;
        opacity: 0.4;
        margin-right:4%;
        }
    @include gadgets-to(laptop) {
        background-color: map-get($generalStyles, pinkRed);
        border:none;
        opacity: 0.4;
        margin-right:4%;
        }
    @include gadgets-to(desktop) {
        background-color: map-get($generalStyles, pinkRed);
        border:none;
        opacity: 0.4;
        }
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, pinkRed);
    border:none;
    opacity: 0.4;
    }
}

.axxeingredDeleteButton3 {
    @extend .axxeDeleteButton;
    @include gadgets-to(mobile) {
    background-color: map-get($generalStyles, pinkRed);
    opacity: 1;
    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, pinkRed);
        opacity: 1;
        margin-right:4%;
        }
    @include gadgets-to(laptop) {
        background-color: map-get($generalStyles, pinkRed);
        opacity: 1;
        margin-right:4%;
        }
    @include gadgets-to(desktop) {
        background-color: map-get($generalStyles, pinkRed);
        opacity: 1;
        }
@include gadgets-to(largeDesktop) {
    background-color: map-get($generalStyles, pinkRed);
    opacity: 1;
}
    }

.axedeleteArea {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content:center;
    border: none;
    width:99%;
    height: 32px;
   margin-left:1px;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        border: none;
        width:99%;
        height: 32px;
       margin-left:1px;
        }
     @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        border: none;
        width:99%;
        height: 32px;
       margin-left:1.5%;
        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        border: none;
        width:99%;
        height: 32px;
        margin-right:1%;
        }
@include gadgets-to(largeDesktop) {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    border: none;
    width:99%;
    height: 32px;
    margin-right:1%;
    }
}

// .xefoodArea1 {
 
//     max-height: 220px;
//  //   overflow-y: scroll;
//    // width:80%;

// }

.nutrientMessage {
  font-family: var(--font-family);
    font-size: 16px;
    margin-left: 10px;
}

.xeingredientAreaData {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin-top: 10px;
  //  margin-left: 5px;
    width: 100%;
    }

    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        margin-top: 10px;
      //  margin-left: 5px;
        width: 100%;
        }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        margin-top: 10px;
      //  margin-left: 5px;
        width: 100%;
        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        margin-top: 10px;
      //  margin-left: 5px;
        width: 100%;
        }
    @include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        margin-top: 10px;
      //  margin-left: 5px;
        width: 100%;
        }

}

.ingredientFieldset {
    @include gadgets-to(mobile) {
    width: 90%;
    height: 60px;
    padding-bottom: 1px;
    border: none;
    }

    @include gadgets-to(tablet) {
        width: 90%;
        height: 60px;
        padding-bottom: 1px;
        border: none;
        margin-top:1%;
        }
    @include gadgets-to(laptop) {
        width: 90%;
        height: 60px;
        padding-bottom: 1px;
        border: none;
        margin-top:1%;
        }
    @include gadgets-to(desktop) {
        width: 90%;
        height: 60px;
        padding-bottom: 1px;
        border: none;
        margin-top:1%;
        }
@include gadgets-to(largeDesktop) {
    width: 90%;
    height: 60px;
    padding-bottom: 1px;
    border: none;
    margin-top:3%;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
}

.ingredientLegend {
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 14px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 14px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
      font-family: var(--font-family);
        margin-left:5px;
        font-size: 18px;
        font-style: italic;
        }
@include gadgets-to(largeDesktop) {
  font-family: var(--font-family);
    margin-left:10%;
    font-size: 22px;
    font-style: italic;
    }

}
.ingredientLegend1 {
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 14px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 14px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
      font-family: var(--font-family);
        margin-left:12px;
        margin-top:5px;
        font-size: 18px;
        font-style: italic;
        }
@include gadgets-to(largeDesktop) {
  font-family: var(--font-family);
    margin-left:12%;
    margin-top:5px;
    font-size: 22px;
    font-style: italic;
    }

}

.ingredientInput {
    @include gadgets-to(mobile) {
    width: 95%;
    height: 30px;
  font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    }

    @include gadgets-to(tablet) {
        width: 95%;
        height: 25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        width: 95%;
        height: 30px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(desktop) {
        width: 95%;
        height: 30px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        }
@include gadgets-to(largeDesktop) {
    width: 80%;
    height: 35px;
  font-family: var(--font-family);
    font-size: 14px;
    border: 1px solid map-get($generalStyles, datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    }
}

.kategoriFieldset {
    @include gadgets-to(mobile) {
    width: 95%;
    height: 50px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 18px;
    }

    @include gadgets-to(tablet) {
        width: 95%;
        height: 50px;
        padding-bottom: 2px;
        margin-top: 1px;
        border: none;
        margin-left: 18px;
        }
    @include gadgets-to(laptop) {
        width: 95%;
        height: 50px;
        padding-bottom: 2px;
        margin-top: 1px;
        border: none;
        margin-left: 18px;
        }
    @include gadgets-to(desktop) {
        width: 95%;
        height: 50px;
        padding-bottom: 2px;
        margin-top: 1px;
        border: none;
        margin-left: 18px;

}
@include gadgets-to(largeDesktop) {
    width: 95%;
    height: 50px;
    padding-bottom: 2px;
    margin-top: 20px;
    border: none;
   // margin-left: 18px;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
}

.kategoriLegend {
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 14px;
        font-style: italic;
        }
}

.ingredselect {
    @include gadgets-to(mobile) {
    width: 92%;
    height: 30px;
    font-family:var(--font-family);
    font-size: 12px;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    background-color: map-get($generalStyles,colorwhite);
    }

    @include gadgets-to(tablet) {
        width: 92%;
        height: 30px;
        font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        background-color: map-get($generalStyles,colorwhite);
        }
    @include gadgets-to(laptop) {
        width: 92%;
        height: 30px;
        font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        background-color: map-get($generalStyles,colorwhite);
        }
    @include gadgets-to(desktop) {
        width: 91%;
        height: 30px;
        margin-left:1%;
        font-family:var(--font-family);
        font-size: 12px;
        border: 1px solid map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles, colorblack);
        background-color: map-get($generalStyles,colorwhite);
        }
@include gadgets-to(largeDesktop) {
    width: 76%;
    height: 35px;
    //margin-left:1%;
    font-family:var(--font-family);
    font-size: 12px;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles, colorblack);
    background-color: map-get($generalStyles,colorwhite);
    }
}

.xe1saveButtonArea{
@include gadgets-to(mobile) {
    align-self:flex-end;
    margin-right:9%;
}
@include gadgets-to(tablet) {
    align-self:flex-end;
    margin-right:9%;
}
@include gadgets-to(laptop) {
    align-self:flex-end;
    margin-right:9%;
}
@include gadgets-to(desktop) {
    align-self:flex-end;
    margin-right:9.5%;
    margin-top:5px;
}
@include gadgets-to(largeDesktop) {
    align-self:flex-end;
    margin-right:14.5%;
    margin-top:15px;
}
}

.xesaveButton {
    @include gadgets-to(mobile) {
    width: 150px;
    height: 30px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, pinkRed);
    color: map-get($generalStyles, colorwhite);
  font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
   // margin-left: 169px;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    }

    @include gadgets-to(tablet) {
        width: 150px;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 12px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }
    @include gadgets-to(laptop) {
        width: 150px;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 12px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }
    @include gadgets-to(desktop) {
        width: 150px;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 12px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }

 @include gadgets-to(largeDesktop) {
        width: 170px;
        height: 40px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 14px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }   

   

}



.xesaveButton1 {
    @include gadgets-to(mobile) {
    width: 150px;
    height: 30px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorwhite);
  font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
   // margin-left: 169px;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.8;
    }

    @include gadgets-to(tablet) {
        width: 150px;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 12px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.8;
        }
    @include gadgets-to(laptop) {
        width: 150px;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 12px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.8;
        }
    @include gadgets-to(desktop) {
        width: 150px;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 12px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.8;
        }
@include gadgets-to(largeDesktop) {
        width: 170px;
        height: 40px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        font-size: 14px;
        cursor: pointer;
       // margin-left: 169px;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.8;
        }   
}

.xerecepyConnTop {
    @include gadgets-to(mobile) {
    display: flex;
    width: 95%;
    height: auto;
    background-color: transparent;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    margin-top: 1px;
    margin-left: 1px;
    max-height: 100px;
    overflow-y: scroll;
    }
    @include gadgets-to(tablet) {
        display: flex;
        width: 95%;
        height: auto;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 1px;
        margin-left: 1px;
        max-height: 100px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    @include gadgets-to(laptop) {
        display: flex;
        width: 95%;
        height: auto;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 1px;
        margin-left: 1px;
        max-height: 100px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    @include gadgets-to(desktop) {
        display: flex;
        width: 95%;
        height: auto;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 1px;
        margin-left: 1px;
        min-height:0px;
        max-height: 100px;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
@include gadgets-to(largeDesktop) {
        display: flex;
        width: 95%;
        height: auto;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        margin-top: 1px;
        margin-left: 1px;
        max-height: 100px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5);
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }   
}
.xerecepyConnData {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 2px;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 2px;

        }   
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 2px;

        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 2px;

        }
@include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 2px;

        }
}

.xxeRecepyConnButton {
    @include gadgets-to(mobile) {
    width: auto;
    height: 30px;
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
    }
    @include gadgets-to(tablet) {
        width: auto;
        height: 30px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 4px;
        margin-bottom: 4px;
        outline: none;
        cursor: pointer;

}
@include gadgets-to(laptop) {
    width: auto;
    height: 30px;
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
    }
    @include gadgets-to(desktop) {
        width: auto;
        height: 30px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left: 4px;
        margin-bottom: 4px;
        outline: none;
        cursor: pointer;
        }
@include gadgets-to(largeDesktop) {
    width: auto;
    height: 40px;
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 4px;
    margin-bottom: 4px;
    outline: none;
    cursor: pointer;
    font-size:18px;
}
}

.xe1recepyConnButton {
    @extend .xxeRecepyConnButton;
    @include gadgets-to(mobile) {
    background-color:map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    }

    @include gadgets-to(tablet) {
        background-color:map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        background-color:map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(desktop) {
        background-color:map-get($generalStyles, transparentChangeableMenu5);
        color: map-get($generalStyles, colorblack);
        }
@include gadgets-to(largeDesktop) {
    background-color:map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
    }
}

.xe1recepyConnButton1 {
    @extend .xxeRecepyConnButton;
    @include gadgets-to(mobile) {
    background-color:map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(tablet) {
        background-color:map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        background-color:map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(desktop) {
        background-color:map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
        }
@include gadgets-to(largeDesktop) {
    background-color:map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    }
}

.xerecepyConnFieldset {
    @include gadgets-to(mobile) {
    width:96%;
    height: 100px;
    margin-top: 2px;
    border:none;

    }
    @include gadgets-to(tablet) {
        width:96%;
        min-height:1vh;
        height:auto;
        max-height:10vh;
        margin-top:2%;
        border:none;
        }
    @include gadgets-to(laptop) {
        width:96%;
        min-height:1vh;
        height:auto;
        max-height:10vh;
        margin-top:3%;
        border:none;
        }
    @include gadgets-to(desktop) {
        width:96%;
        min-height:1vh;
        height:auto;
        max-height:10vh;
        margin-top:3%;
        border:none;
        }
@include gadgets-to(largeDesktop) {
        width:96%;
        min-height:1vh;
        height:auto;
        max-height:10vh;
        margin-top:3%;
        border:none;
        }
}

.xerecepyConnLegend {
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width:95%;
    }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 14px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:95%;
        }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 16px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:90%;
        }
    @include gadgets-to(desktop) {
      font-family: var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:90%;
        }
@include gadgets-to(largeDesktop) {
      font-family: var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:90%;
        }
}


.axeingredientListFieldset {
    @include gadgets-to(mobile) {
    border: none;
    margin-top: 10px;
    margin-left: 5px;
    height: 41px;
    border:none;
    width:87%;
    }
    @include gadgets-to(tablet) {
        border: none;
        margin-top: 10px;
        margin-left: 5px;
        height: 41px;
        border:none;
        width:87%;
        }
    @include gadgets-to(laptop) {
        border: none;
        margin-top: 10px;
        margin-left: 5px;
        height: 41px;
        border:none;
        width:87%;
        }
    @include gadgets-to(desktop) {
        border: none;
        margin-top: 10px;
        margin-left: 5px;
        height: 41px;
        border:none;
        width:87%;
        }
@include gadgets-to(largeDesktop) {
        border: none;
        margin-top: 10px;
        margin-left: 5px;
        height: 41px;
        border:none;
        width:87%;
        }
}

.axeingredientListLegend {
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 14px;
    font-style: italic;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width:75%;
    }
@include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 14px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:75%;
        }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 16px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:75%;
        }
    @include gadgets-to(desktop) {
      font-family: var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:75%;
        }
@include gadgets-to(largeDesktop) {
      font-family: var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:75%;
        }
}

.axeingredientListSelect {
    @include gadgets-to(mobile) {
    outline: none;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 98%;
    height: 30px;
    border-radius: map-get($generalStyles, sixBorderRadius);
    background-color: map-get($generalStyles, colorwhite);
  font-family: var(--font-family);
    }
    @include gadgets-to(tablet) {
        outline: none;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 98%;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        }
    @include gadgets-to(laptop) {
        outline: none;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 98%;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        }
    @include gadgets-to(desktop) {
        outline: none;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 98%;
        height: 30px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        }
@include gadgets-to(largeDesktop) {
        outline: none;
        border: 1px solid;
        border-color: map-get($generalStyles, datalistBorderColor);
        width: 98%;
        height: 35px;
        border-radius: map-get($generalStyles, sixBorderRadius);
        background-color: map-get($generalStyles, colorwhite);
      font-family: var(--font-family);
        }

}

.axechangeIngredientFieldset {
    @include gadgets-to(mobile) {
    margin-top: 1px;
  //  width: 95%;
    margin-left: 3px;
    border:none;
    width:87%;
    }
@include gadgets-to(tablet) {
        margin-top:1%;
      //  width: 95%;
        margin-left: 3px;
        border:none;
        width:87%;
   

        }
    @include gadgets-to(laptop) {
        margin-top:1%;
      //  width: 95%;
        margin-left: 3px;
        border:none;
        width:87%;
        }
    @include gadgets-to(desktop) {
        margin-top:1%;
      //  width: 95%;
        margin-left: 3px;
        border:none;
        width:87%;
        }
@include gadgets-to(largeDesktop) {
        margin-top:1%;
      //  width: 95%;
        margin-left: 3px;
        border:none;
        width:87%;
        }
}

.axechangeIngredientLegend {
    @include gadgets-to(mobile) {
  font-family: var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
      font-family: var(--font-family);
        font-size: 12px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
      font-family: var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
      font-family: var(--font-family);
        font-size: 20px;
        font-style: italic;
        }
@include gadgets-to(largeDesktop) {
      font-family: var(--font-family);
        font-size: 20px;
        font-style: italic;
        }
}

.axechangeIngredientArea {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 5px;
    margin-left: 1px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-color: map-get($generalStyles, datalistBorderColor);
    max-height: 60px;
    overflow-y: auto;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 5px;
        margin-left: 1px;
        width: 100%;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        max-height:25vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5)
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    @include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 5px;
        margin-left: 1px;
        width: 100%;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        max-height:25vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5)
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 5px;
        margin-left: 1px;
        width: 100%;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        max-height:25vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5)
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
@include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 5px;
        margin-left: 1px;
        width: 100%;
        background-color: transparent;
        border: none;
        border-color: map-get($generalStyles, datalistBorderColor);
        max-height:25vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
          }
          &::-webkit-scrollbar-track {
            background:map-get($generalStyles,transparentChangeableMenu5)
          }
          &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
}

.axechangeIngredientButton {
    width: auto;
    height: 30px;
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left: 4px;
    margin-bottom: 4px;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
    color: map-get($generalStyles, colorblack);
  font-family: var(--font-family);
  //  font-size:14px;
    outline: none;
    @include gadgets-to(largeDesktop){
        height:40px;
    }
}
.axechangeIngredientButton11 {
@extend .axechangeIngredientButton;
@include gadgets-to(mobile) {
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    color: map-get($generalStyles, colorblack);
    }
    @include gadgets-to(tablet) {
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(laptop) {
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
        }
    @include gadgets-to(desktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
        }
@include gadgets-to(largeDesktop) {
        background-color: map-get($generalStyles, transparentChangeableMenu4);
        color: map-get($generalStyles, colorblack);
        }
}

.axechangeIngredientArea1 {
    display: flex;
    flex-direction: column;
}

.axechangeIngredientButton1 {
    @include gadgets-to(mobile) {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: map-get($generalStyles, sixBorderRadius);
    margin-left:60%;
    margin-bottom:4px;
    background-color: map-get($generalStyles, pinkRed);
    color: map-get($generalStyles, colorwhite);
    outline: none;
    cursor: pointer;
    margin-top: 1px;
  font-family: var(--font-family);
    }

    @include gadgets-to(tablet) {
        width: 120px;
        height: 30px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:57%;
        margin-bottom:4px;
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
        outline: none;
        cursor: pointer;
        margin-top: 1px;
      font-family: var(--font-family);
        }
    @include gadgets-to(laptop) {
        width: 120px;
        height: 30px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:57%;
        margin-bottom:4px;
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
        outline: none;
        cursor: pointer;
        margin-top: 1px;
      font-family: var(--font-family);
        }
    @include gadgets-to(desktop) {
        width: 120px;
        height: 30px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:57%;
        margin-bottom:4px;
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
        outline: none;
        cursor: pointer;
        margin-top: 1px;
      font-family: var(--font-family);
        }
@include gadgets-to(largeDesktop) {
        width: 150px;
        height: 40px;
        border: none;
        border-radius: map-get($generalStyles, sixBorderRadius);
        margin-left:57%;
        margin-bottom:4px;
        background-color: map-get($generalStyles, pinkRed);
        color: map-get($generalStyles, colorwhite);
        outline: none;
        cursor: pointer;
        margin-top: 1px;
      font-family: var(--font-family);
        font-size: 18px;
        }
}




.axechangeIngredientButtonDisabled {
    @include gadgets-to(mobile) {
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    margin-left:60%;
    margin-bottom: 4px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: black;
    outline: none;
    cursor: pointer;
    margin-top: 1px;
    opacity: 0.5;
    cursor: not-allowed;
    font-family:var(--font-family);
    }
    @include gadgets-to(tablet) {
        width: 120px;
        height: 30px;
        border: none;
        border-radius: 6px;
        margin-left:57%;
        margin-bottom: 4px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        outline: none;
        cursor: pointer;
        margin-top: 1px;
        opacity: 0.5;
        cursor: not-allowed;
        font-family:var(--font-family);
        }
    @include gadgets-to(laptop) {

        width: 120px;
        height: 30px;
        border: none;
        border-radius: 6px;
        margin-left:57%;
        margin-bottom: 4px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        outline: none;
        cursor: pointer;
        margin-top: 1px;
        opacity: 0.5;
        cursor: not-allowed;
        font-family:var(--font-family);
        }
    @include gadgets-to(desktop) {
        width: 120px;
        height: 30px;
        border: none;
        border-radius: 6px;
        margin-left:57%;
        margin-bottom: 4px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        outline: none;
        cursor: pointer;
        margin-top: 1px;
        opacity: 0.5;
        cursor: not-allowed;
        font-family:var(--font-family);
        }
@include gadgets-to(largeDesktop) {
        width: 150px;
        height: 40px;
        border: none;
        border-radius: 6px;
        margin-left:57%;
        margin-bottom: 4px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        outline: none;
        cursor: pointer;
        margin-top: 1px;
        opacity: 0.5;
        cursor: not-allowed;
        font-family:var(--font-family);
        }
}

.xeeditIngredientBox {
    @include gadgets-to(mobile) {
    width:100%;
    height: 88px;
    border: 1px solid grey;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 5px;
    overflow-x:hidden;
    }
    @include gadgets-to(tablet) {
        width:100%;
        height: 88px;
        // border: 1px solid grey;
        // border-radius: 3px;
        margin-top: 3px;
        margin-bottom: 5px;
        overflow-x:hidden;
        }
    @include gadgets-to(laptop) {
        width:100%;
        height: 92px;
        // border: 1px solid grey;
        // border-radius: 3px;
        margin-top: 3px;
        margin-bottom: 5px;
        overflow-x:hidden;
        }
    @include gadgets-to(desktop) {
        width:100%;
        height:11vh;
        // border: 1px solid grey;
        // border-radius: 3px;
        margin-top: 3px;
        margin-bottom: 5px;
        overflow-x:hidden;
        }
@include gadgets-to(largeDesktop) {
        width:100%;
        height:11vh;
        // border: 1px solid grey;
        // border-radius: 3px;
        margin-top: 3px;
        margin-bottom: 5px;
        overflow-x:hidden;
        }
}

.xeeditIngredientFieldset2 {
    @include gadgets-to(mobile) {
    border: none;
    margin-top: 1px;
    }
    @include gadgets-to(tablet) {
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top: 1px;
        }
    @include gadgets-to(laptop) {
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-top: 1px;
        }
@include gadgets-to(desktop) {
    border:1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    margin-top: 1px;
    }
@include gadgets-to(largeDesktop) {
    border:1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    border-radius: map-get($generalStyles,sixBorderRadius);
    margin-top: 1px;
    }
}

.xeeditIngredientPriceBox {
    @include gadgets-to(mobile) {

    width: 99%;
    }
    @include gadgets-to(tablet) {
        width: 99%;
        }
    @include gadgets-to(laptop) {
        width: 99%;
        }
    @include gadgets-to(desktop) {
        width: 99%;
        }
}

.xeeditIngredientLegend1 {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
@include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 12px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        }
@include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        }
}



.xeeditIngredientPricePlusIcon {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    cursor: pointer;
   // margin-left: 130px;
    margin-top: 1px;
    font-size: 46px;
    color: map-get($generalStyles,transparentChangeableMenu5);
    opacity: 0.5;

    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        cursor: pointer;
       // margin-left: 130px;
        margin-top: 1px;
        font-size: 46px;
        color: map-get($generalStyles,transparentChangeableMenu5);
        opacity: 0.5;
    
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        cursor: pointer;
       // margin-left: 130px;
        margin-top: 1px;
        font-size: 46px;
        color: map-get($generalStyles,transparentChangeableMenu5);
        opacity: 0.5;
    
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        cursor: pointer;
       // margin-left: 130px;
        margin-top: 1px;
        font-size: 46px;
        color: map-get($generalStyles,transparentChangeableMenu5);
        opacity: 0.5;
    
        }
@include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        cursor: pointer;
       // margin-left: 130px;
        margin-top: 1px;
        font-size: 46px;
        color: map-get($generalStyles,transparentChangeableMenu5);
        opacity: 0.5;
    
        }
}

.xeeditIngredientPriceMinusIcon {
    font-family:var(--font-family);
    cursor: pointer;
    margin-left: 10px;
    margin-top: 1px;
    font-size: 46px;
    color: map-get($generalStyles,transparentChangeableMenu5);
    opacity: 0.5;
}

.xeeditIngredientPrice {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    background-color: transparent;
}

.xeeditPriceButton {
    @include gadgets-to(mobile) {
    width: 60px;
    height: 25px;
    padding: 5px;
    margin-left:10%;
    margin-top: 20px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    text-align: center;
    font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    cursor: pointer;
    color: map-get($generalStyles,colorblack);
    border: none;
    }
    @include gadgets-to(tablet) {
        width: 60px;
        height: 25px;
        padding: 5px;
        margin-left:10%;
        margin-top: 20px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        text-align: center;
        font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles,sixBorderRadius);
        cursor: pointer;
        color: map-get($generalStyles,colorblack);
        border: none;
        }
    @include gadgets-to(laptop) {
        width: 100px;
        height: 30px;
        padding: 5px;
        margin-left:10%;
        margin-top: 20px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        text-align: center;
        font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles,sixBorderRadius);
        cursor: pointer;
        color: map-get($generalStyles,colorblack);
        border: none;

}
@include gadgets-to(desktop) {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left:10%;
    margin-top: 20px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    text-align: center;
    font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    cursor: pointer;
    color: map-get($generalStyles,colorblack);
    border: none;
    }
@include gadgets-to(largeDesktop) {
    width: 100px;
    height: 30px;
    padding: 5px;
    margin-left:10%;
    margin-top: 20px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    text-align: center;
    font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    cursor: pointer;
    color: map-get($generalStyles,colorblack);
    border: none;
    }
}


.xeeditPriceButton1 {
    @include gadgets-to(mobile) {
    width: 60px;
    height: 25px;
    padding: 5px;
    margin-left:10%;
    margin-top: 20px;
    background-color: map-get($generalStyles,pinkRed);
    text-align: center;
    font-family:var(--font-family);
    font-size: 12px;
    border-radius: map-get($generalStyles,sixBorderRadius);
    cursor: pointer;
    color: map-get($generalStyles,colorwhite);
    border: none;
    }
    @include gadgets-to(tablet) {
        width: 60px;
        height: 25px;
        padding: 5px;
        margin-left:10%;
        margin-top: 20px;
        background-color: map-get($generalStyles,pinkRed);
        text-align: center;
        font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles,sixBorderRadius);
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        border: none;
        }
    @include gadgets-to(laptop) {
        width: 100px;
        height: 30px;
        padding: 5px;
        margin-left:10%;
        margin-top: 20px;
        background-color: map-get($generalStyles,pinkRed);
        text-align: center;
        font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles,sixBorderRadius);
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        border: none;
        }
    @include gadgets-to(desktop) {
        width: 100px;
        height: 30px;
        padding: 5px;
        margin-left:10%;
        margin-top: 20px;
        background-color: map-get($generalStyles,pinkRed);
        text-align: center;
        font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles,sixBorderRadius);
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        border: none;
        }
@include gadgets-to(largeDesktop) {
        width: 100px;
        height: 30px;
        padding: 5px;
        margin-left:10%;
        margin-top: 20px;
        background-color: map-get($generalStyles,pinkRed);
        text-align: center;
        font-family:var(--font-family);
        font-size: 12px;
        border-radius: map-get($generalStyles,sixBorderRadius);
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        border: none;
        }
}



.xeprisAreaTop {
    @include gadgets-to(mobile) {
    width: 60%;
    height: 50px;
    background-color:transparent;
    border: 1px solid map-get($generalStyles,datalistBorderColor);
    margin-left: 1px;
    margin-top: 8px;
    }
    @include gadgets-to(tablet) {
        width: 98%;
        height: 50px;
        background-color:transparent;
        border:none;
        margin-left: 1px;
        margin-top: 15px;
        }
}

.xeprisFieldset {
    @include gadgets-to(mobile) {
    border: none;
    }
    @include gadgets-to(tablet) {
        border: none;
        width:98%;
        }
    @include gadgets-to(laptop) {
        border: none;
        width:98%;
        margin-top:3%;
        }
    @include gadgets-to(desktop) {
        border: none;
        width:98%;
        margin-top:4%;
        }
    @include gadgets-to(largeDesktop) {
        border: none;
        width:98%;
        margin-top:4%;
        }
}

.xeprisLegend {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        margin-left:5px;
        }
    @include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        margin-left:5px;
        }
}

.xeprisButton {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 20px;
    border: none;
    background-color:transparent;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 24px;
        border: none;
        background-color:transparent;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size:42px;
        border: none;
        background-color:transparent;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 46px;
        border: none;
        background-color:transparent;
        }
    @include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 46px;
        border: none;
        background-color:transparent;
        }
}

.xeinkopsStatistikTop {
    @include gadgets-to(mobile) {
    margin-top:1px;
    }
@include gadgets-to(tablet) {
        margin-top:1px;
        }
    @include gadgets-to(laptop) {
        margin-top:1px;
        }
    @include gadgets-to(desktop) {
        margin-top:1px;
        }
@include gadgets-to(largeDesktop) {
        margin-top:1px;
        }
}

.xeinkopsStatistikFieldset {
    @include gadgets-to(mobile) {
    border: none;
    margin-top: 1px;
    margin-left: 5px;
    height: 1px;
    width: 60%;
    font-family:var(--font-family);
    font-size: 14px;
    }
    @include gadgets-to(tablet) {
        border: none;
        margin-top:2%;
        margin-left:3%;
        height: 1px;
        width: 60%;
        font-family:var(--font-family);
        font-size: 14px;
        }
    @include gadgets-to(laptop) {
        border: none;
        margin-top:2%;
        margin-left:1%;
        height: 1px;
        width: 60%;
        font-family:var(--font-family);
        font-size: 18px;
        }
    @include gadgets-to(desktop) {
        border: none;
        margin-top:2%;
        margin-left:1%;
        height: 1px;
        width: 60%;
        font-family:var(--font-family);
        font-size: 18px;
        }
@include gadgets-to(largeDesktop) {
        border: none;
        margin-top:2%;
        margin-left:1%;
        height: 1px;
        width: 60%;
        font-family:var(--font-family);
        font-size: 18px;
        }
}



.xeinkopsStatistikLegend {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    }
@include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 12px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        }
@include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        }
}

.xeinkopHeaderDatum {
    @include gadgets-to(mobile) {
    border: none;
    width: 150px;
    height: 20px;
    font-family:var(--font-family);
    font-size: 12px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: map-get($generalStyles,threeBorderRadius);
    margin-left: 15px;
    margin-bottom: 3px;
    }
    @include gadgets-to(tablet) {
        border: none;
        width:39%;
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 15px;
        margin-bottom: 3px;
        }
    @include gadgets-to(laptop) {
        border: none;
        width:31.4%;
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 15px;
        margin-bottom: 3px;
        }
    @include gadgets-to(desktop) {
        border: none;
        width:31.4%;
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 15px;
        margin-bottom: 3px;
        }
@include gadgets-to(largeDesktop) {
        border: none;
        width:31.4%;
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 15px;
        margin-bottom: 3px;
        }
}

.xeinkopHeaderPris {
    @include gadgets-to(mobile) {
    border: none;
    width: 100px;
    height: 20px;
    font-family:var(--font-family);
    font-size: 12px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    border-radius: map-get($generalStyles,threeBorderRadius);
    margin-left: 2px;
    }
    @include gadgets-to(tablet) {
        border: none;
        width:calc(20% - 2px);
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 2px;
        }
    @include gadgets-to(laptop) {
        border: none;
        width:15.5%;
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 2px;
        }
    @include gadgets-to(desktop) {
        border: none;
        width:15.8%;
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 2px;
        }
@include gadgets-to(largeDesktop) {
        border: none;
        width:15.8%;
        height:25px;
        font-family:var(--font-family);
        font-size: 12px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,threeBorderRadius);
        margin-left: 2px;
        }
}

.xeinkopsStatistikData {
    @include gadgets-to(mobile) {
    margin-left: 13px;
    max-height: 180px;
    overflow-y: scroll;
    width: 100%;
    }
    @include gadgets-to(tablet) {
        margin-left: 13px;
        max-height: 180px;
        overflow-y: scroll;
        width: 100%;
        }
    @include gadgets-to(laptop) {
        margin-left: 13px;
        min-height:3px;
        max-height:50vh;
        overflow-y: scroll;
        width:80%;
        }
    @include gadgets-to(desktop) {
        margin-left: 13px;
        min-height:3px;
        max-height:50vh;
        overflow-y: scroll;
        width:80%;
        }
    @include gadgets-to(largeDesktop) {
        margin-left: 13px;
        min-height:3px;
        max-height:50vh;
        overflow-y: scroll;
        width:80%;
        }
}

.xedataDatum {
    @include gadgets-to(mobile) {
    width: 150px;
    height: 20px;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: map-get($generalStyles,threeBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    margin-left: 2px;
    margin-bottom: 3px;
    }
    @include gadgets-to(tablet) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
    }   
    @include gadgets-to(laptop) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(desktop) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(largeDesktop) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
        }
}


.xedataDatum1 {
    @include gadgets-to(mobile) {
    width: 150px;
    height: 20px;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: map-get($generalStyles,threeBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    margin-left: 2px;
    margin-bottom: 3px;
    }
    @include gadgets-to(tablet) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(laptop) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(desktop) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
@include gadgets-to(largeDesktop) {
        width:40%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
}

.xedataPris {
    @include  gadgets-to(mobile) {
    width: 100px;
    height: 20px;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: map-get($generalStyles,threeBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    margin-left: 2px;
    margin-bottom: 3px;
    }
    @include gadgets-to(tablet) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(laptop) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(desktop) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
        }
@include gadgets-to(largeDesktop) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        margin-bottom: 3px;
        }
}

.xedataPris1 {
    @include gadgets-to(mobile) {
    width: 100px;
    height: 20px;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: map-get($generalStyles,threeBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu4);
    margin-left: 2px;
    margin-bottom: 3px;
    }
    @include gadgets-to(tablet) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(laptop) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
    @include gadgets-to(desktop) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
@include gadgets-to(largeDesktop) {
        width:20%;
        height: 20px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,threeBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu4);
        margin-left: 2px;
        margin-bottom: 3px;
        }
}

.xeeditNutrientTop{
@include gadgets-to(mobile) {
   margin-top:1px;
    }


}

.xenutrientHeaderTop{
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.xenutrientHeaderArea{
    @include gadgets-to(mobile) {
    width:90%;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items:center;
    }
    @include gadgets-to(tablet) {
        width:90%;
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items:center;
        }
    @include gadgets-to(laptop) {
        width:90%;
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items:center;
        }
    @include gadgets-to(desktop) {
        width:90%;
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items:center;
        }
@include gadgets-to(largeDesktop) {
        width:90%;
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items:center;
        }
}

.xenutrientNameArea{
    @include gadgets-to(mobile) {
    display:flex;
    flex-direction:row;
    justify-content:center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:98%;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width:98%;
        }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top:10px;
        width:98%;
        }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top:10px;
        width:98%;
        }
@include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top:10px;
        width:98%;
        }
}
.xenutrientName {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 14px;
    color: map-get($generalStyles,colorblack);
    font-style: italic;
    margin-top:1.5%;
    margin-bottom:1%;
  //  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 14px;
        color: map-get($generalStyles,colorblack);
        font-style: italic;
        margin-top:1.5%;
        margin-bottom:1%;
      //  margin-left: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 16px;
        color: map-get($generalStyles,colorblack);
        font-style: italic;
        margin-top:1.5%;
        margin-bottom:1%;
      //  margin-left: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 16px;
        color: map-get($generalStyles,colorblack);
        font-style: italic;
        margin-top:1.5%;
        margin-bottom:1%;
      //  margin-left: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
        }
@include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 18px;
        color: map-get($generalStyles,colorblack);
        font-style: italic;
        margin-top:3.5%;
        margin-bottom:1%;
      //  margin-left: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
        }
}

.xenutrientButtonBlock {
    @include gadgets-to(mobile) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right:4.5%;
    }
    @include gadgets-to(tablet) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right:4.5%;
        }
@include gadgets-to(laptop) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right:4.5%;
        }
    @include gadgets-to(desktop) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right:4.5%;
        }
@include gadgets-to(largeDesktop) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right:4.5%;
        }
}

.xefetchNutrientButton {
    @include gadgets-to(mobile) {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: black;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    margin-left: 2px;
    cursor: pointer;
    }
    @include gadgets-to(tablet) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        margin-left: 2px;
        margin-top:3px;
        cursor: pointer;
        }
@include gadgets-to(laptop) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        margin-left: 2px;
        margin-top:3px;
        cursor: pointer;
        }
    @include gadgets-to(desktop) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        margin-left: 2px;
        margin-top:3px;
        cursor: pointer;
        }
@include gadgets-to(largeDesktop) {
        width: 170px;
        height: 40px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: black;
        font-family:var(--font-family);
        font-size: 14px;
        border: none;
      //  margin-left: 2px;
      margin-right:0.7%;
        margin-top:3px;
        cursor: pointer;
        }
}

// .nutrientSaveButton {
//     width: 100px;
//     height: 30px;
//     border-radius: 6px;
//     background-color: map-get($generalStyles,transparentChangeableMenu5);
//     color: black;
//     font-family:var(--font-family);
//     font-size: 12px;
//     border: none;
//     margin-left: 3px;
//     cursor: pointer;
//     opacity: 1.5;
// }


// .nutrientSaveButton1 {
//     width: 100px;
//     height: 30px;
//     border-radius: 6px;
//     background-color: red;
//     color: black;
//     font-family:var(--font-family);
//     font-size: 12px;
//     border: none;
//     margin-left: 3px;
//     cursor: not-allowed;
//     opacity: 1;
// }

.xeconvertFromVolumeTop {
    @include gadgets-to(mobile) {
    margin-top: 5px;
    margin-left: 3px;
    }
    @include gadgets-to(tablet) {
        margin-top: 10px;
        margin-left: 3px;
        }
    @include gadgets-to(laptop) {
        margin-top: 10px;
        margin-left: 3px;
        }
    @include gadgets-to(desktop) {
        margin-top: 10px;
        margin-left: 3px;
        }
@include gadgets-to(largeDesktop) {
        margin-top: 10px;
        margin-left: 3px;
        }
    }

.xeconvertFromVolumeFieldset {
    @include gadgets-to(mobile) {
    border: none;
    margin-top: 1px;
    margin-left: 17px;
    height: 41px;
    font-family:var(--font-family);
    font-size: 16px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
        border: none;
        margin-top: 1px;
        margin-left: 17px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
        border: none;
        margin-top: 12px;
        margin-left: 17px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
 
        }
    @include gadgets-to(desktop) {
        border: none;
        margin-top:18px;
        margin-left: 17px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        display:flex;
        flex-direction:row;
        justify-content:center;
        }
@include gadgets-to(largeDesktop) {
        border: none;
        margin-top:22px;
        margin-left: 17px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        display:flex;
        flex-direction:row;
        justify-content:center;
        }
}
.xeconvertFromVolumeLegend{
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 14px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 14px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        margin-left:6.5%;
        }
@include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        margin-left:8%;
        }

}
.xeconvertFromVolumeSelect1 {
    width: 65%;
    height: 30px;
    font-family:var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles,colorblack);
    background-color: white;

    @include gadgets-to(desktop) {
        width: 65%;
        height: 40px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        background-color: white;
        }
    @include gadgets-to(largeDesktop) {
        width: 65%;
        height: 40px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        background-color: white;
        }
}

.xeconvertFromVolumeButton1 {
    width: 100px;
    height: 30px;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    margin-left: 2px;
    cursor: pointer;
    color: map-get($generalStyles,colorwhite);

    @include gadgets-to(desktop) {
        width: 150px;
        height: 40px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left:5px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
}
@include gadgets-to(largeDesktop) {
        width: 150px;
        height: 40px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left:5px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        }
}


.xeconvertFromVolumeButton {
    width: 100px;
    height: 30px;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: map-get($generalStyles,pinkRed);
    margin-left: 2px;
    cursor: pointer;
    color: map-get($generalStyles,colorwhite);

    @include gadgets-to(desktop) {
        width: 150px;
        height: 40px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,pinkRed);
        margin-left: 5px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
    }
@include gadgets-to(largeDesktop) {
        width: 150px;
        height: 40px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,pinkRed);
        margin-left: 5px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        }
}

.xeconvertFromVolumeData {
    margin-left: 27px;
}

.xeconvertFromVolumeDataText {
    @include gadgets-to(mobile) {
    display: flex;
    align-items: center;
    font-family:var(--font-family);
    font-size: 44px;
    margin-left: 5px;
    margin-top: 10px;
    color: map-get($generalStyles,pinkerPink);
    }
    @include gadgets-to(tablet) {
        display: flex;
        align-items: center;
        font-family:var(--font-family);
        font-size: 44px;
        margin-left: 5px;
        margin-top: 10px;
        color: map-get($generalStyles,pinkerPink);
        }
    @include gadgets-to(laptop) {
        display: flex;
        align-items: center;
        font-family:var(--font-family);
        font-size: 44px;
        margin-left:7%;
        margin-top: 10px;
        color: map-get($generalStyles,pinkerPink);
        }
    @include gadgets-to(desktop) {
        display: flex;
        align-items: center;
        font-family:var(--font-family);
        font-size: 48px;
        margin-left:7%;
        margin-top:20px;
        color: map-get($generalStyles,pinkerPink);
        }
@include gadgets-to(largeDesktop) {
        display: flex;
        align-items: center;
        font-family:var(--font-family);
        font-size: 48px;
        margin-left:7%;
        margin-top:20px;
        color: map-get($generalStyles,pinkerPink);
        }
}



.xegramText {
    font-family:var(--font-family);
    font-size: 14px;
    margin-left: 7px;
    margin-top: 10px;
    color: map-get($generalStyles,colorblack);
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 14px;
        margin-left: 7px;
        margin-top: 10px;
        color: map-get($generalStyles,colorblack);

    }
@include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 14px;
        margin-left: 7px;
        margin-top: 10px;
        color: map-get($generalStyles,colorblack);
        }
}
.xeconvertFromVolumeDisclaimer{
    @include gadgets-to(mobile) {
    margin-top:1px;
    }
    @include gadgets-to(tablet) {
        margin-top:1px;
        }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        margin-top:1px;
        }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        margin-top:10px;
        }
@include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        margin-top:10px;
        }
}

.xeconvertFromVolumeDisclaimerText {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 13px;
    margin-left: 20px;
    margin-top: 10px;
    font-style: italic;
    width: 95%;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 13px;
        margin-left: 20px;
        margin-top: 10px;
        font-style: italic;
        width: 95%;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 13px;
        margin-left: 20px;
        margin-top: 10px;
        font-style: italic;
        width:80%;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 13px;
        margin-left: 20px;
        margin-top: 10px;
        font-style: italic;
        width:80%;
        }
@include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 13px;
        margin-left: 20px;
        margin-top: 10px;
        font-style: italic;
        width:80%;
        }
}

.xeingredInformationTop {
    @include gadgets-to(mobile) {
    margin-top: 5px;
    margin-left: 5px;
    }
    @include gadgets-to(tablet) {
        margin-top: 5px;
        margin-left: 5px;
        }
    @include gadgets-to(laptop) {
        margin-top: 5px;
        margin-left: 5px;
        }
    @include gadgets-to(desktop) {
        margin-top: 5px;
        margin-left: 5px;
        }
@include gadgets-to(largeDesktop) { 
        margin-top: 5px;
        margin-left: 5px;
        }
}

.xeingredInformationFieldset {
    @include gadgets-to(mobile) {
    border: none;
    margin-top: 8px;
    margin-left: 7px;
    height: 41px;
    font-family:var(--font-family);
    font-size: 16px;
    }
    @include gadgets-to(tablet) {
        border: none;
        margin-top: 15px;
        margin-left: 7px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        }
    @include gadgets-to(laptop) {
        border: none;
        margin-top: 15px;
        margin-left: 7px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        }
    @include gadgets-to(desktop) {
        border: none;
        margin-top: 20px;
        margin-left: 7px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        }
    @include gadgets-to(largeDesktop) {
        border: none;
        margin-top: 20px;
        margin-left: 7px;
        height: 41px;
        font-family:var(--font-family);
        font-size: 16px;
        }
        
}

.xeingredInformationLegend {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 16px;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 16px;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 18px;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        }
    @include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 20px;
        }
}

.xeingredInformationButton {
    @include gadgets-to(mobile) {
    width: 130px;
    height: 30px;
    font-family:var(--font-family);
    font-size: 12px;
    border: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    background-color: map-get($generalStyles,pinkRed);
    margin-left: 2px;
    cursor: pointer;
    color: map-get($generalStyles,colorwhite);
    margin-bottom: 10px;
    }
    @include gadgets-to(tablet) {
        width: 130px;
        height: 30px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,pinkRed);
        margin-left: 2px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        margin-bottom: 10px;
        }
    @include gadgets-to(laptop) {
        width: 130px;
        height: 30px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,pinkRed);
        margin-left: 2px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        margin-bottom: 10px;
        }
    @include gadgets-to(desktop) {
        width: 160px;
        height: 40px;
        font-family:var(--font-family);
        font-size: 12px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,pinkRed);
        margin-left: 2px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        margin-top:15px;
        margin-bottom: 10px;
        }
    @include gadgets-to(largeDesktop) { 
        width: 160px;
        height: 40px;
        font-family:var(--font-family);
        font-size: 16px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        margin-left: 2px;
        cursor: pointer;
        color: map-get($generalStyles,colorwhite);
        margin-top:15px;
        margin-bottom: 10px;
        }
}

.xeingredInformationCheckbox {
    @include gadgets-to(mobile) {
    width: 15px;
    height: 15px;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
    }
    @include gadgets-to(tablet) {
        width: 15px;
        height: 15px;
        margin-left: 20px;
        margin-top: 10px;
        cursor: pointer;
        }
    @include gadgets-to(laptop) {
        width: 15px;
        height: 15px;
        margin-left: 20px;
        margin-top: 10px;
        cursor: pointer;
        }
    @include gadgets-to(desktop) {
        width: 15px;
        height: 15px;
        margin-left: 20px;
        margin-top: 10px;
        cursor: pointer;
        }
    @include gadgets-to(largeDesktop) {
        width: 15px;
        height: 15px;
        margin-left: 20px;
        margin-top: 10px;
        cursor: pointer;
        }
}

.xeingredInformationTextArea {
    @include gadgets-to(mobile) {
    background-color: transparent;
    width: 95%;
    height: 200px;
    max-height: 200px;
    overflow-x: scroll;
    outline: none;
    border: 1px dotted;
    font-family:var(--font-family);
    font-size: 16px;
    padding: 5px;
    }
    @include gadgets-to(tablet) {
        background-color: transparent;
        width: 95%;
        height:50vh;
        max-height:50vh;
        overflow-x: scroll;
        outline: none;
        border: 1px dotted;
        font-family:var(--font-family);
        font-size: 16px;
        padding: 5px;
        overflow-x:hidden;
        //scrollbar
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
         

          }
          &::-webkit-scrollbar-track {
            background:black;
          }
        }
    @include gadgets-to(laptop) {
        background-color: transparent;
        width: 95%;
        height:50vh;
        max-height:50vh;
        overflow-x: scroll;
        outline: none;
        border: 1px dotted;
        font-family:var(--font-family);
        font-size: 16px;
        padding: 5px;
        overflow-x:hidden;
        //scrollbar
        &::-webkit-scrollbar {
            width: 1px; /* Adjust as needed */
         

          }
          &::-webkit-scrollbar-track {
            background:black;
          }
        }
    @include gadgets-to(desktop) {
        background-color: transparent;
        width: 95%;
        height:50vh;
        min-height:1px;
        max-height:50vh;
        overflow-y:auto;
        outline: none;
        border: 1px dotted;
        font-family:var(--font-family);
        font-size: 20px;
        padding: 5px;
        overflow-x:hidden;
        //scrollbar
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }
        }
    @include gadgets-to(largeDesktop) {
        background-color: transparent;
        width: 95%;
        height:50vh;
        min-height:1px;
        max-height:50vh;
        overflow-y:auto;
        outline: none;
        border: 1px dotted;
        font-family:var(--font-family);
        font-size: 20px;
        padding: 5px;
        overflow-x:hidden;
        //scrollbar
        &::-webkit-scrollbar {
            width: 10px; /* Adjust as needed */
        }
        
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles, transparentChangeableMenu5);
        }
        
        &::-webkit-scrollbar-thumb {
            background:white;
        }
        }

}

.xecheckboxlabel {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 14px;
    font-style: italic;
    margin-left: 5px;
    margin-top: 10px;
    }
}

// .chartBox{
//     @include gadgets-to(mobile) {
//     margin-bottom:25px;
//     border:none;
//     z-index: -3;
//     height:40vh;
//     }

// }

.xeareaChartLegend {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 16px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:85%;

        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:85%;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:85%;
        }
    @include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        width:85%;
        }
}


.xeareaChartFieldset {
    @include gadgets-to(mobile) {
        border:none;
        margin:0;
        padding:0;
        
    }
    @include gadgets-to(tablet) {
        border:none;
        margin-top:3%;
        margin-left:3%;
        }
    @include gadgets-to(laptop) {
        border:none;
        margin-top:3%;
        margin-left:3%;
        }
    @include gadgets-to(desktop) {
        border:none;
        margin-top:3%;
        margin-left:3%;
        }

    @include gadgets-to(largeDesktop) {
        border:none;
        margin-top:3%;
        margin-left:3%;
        }
}

.xeingredChartChoiceArea {
    @include gadgets-to(mobile) {
    margin-top:1px;
    }
    @include gadgets-to(tablet) {
        margin-top:1px;
        }
    @include gadgets-to(laptop) {
        margin-top:1px;
        }
    @include gadgets-to(desktop) {
        margin-top:1px;
        }
    @include gadgets-to(largeDesktop) {
        margin-top:1px;
        }

}

.xeingredChartChoiceFieldset {
    @include gadgets-to(mobile) {
    border:none;
    border-color: map-get($generalStyles,datalistBorderColor);
    margin-top: 1px;
    margin-left: 10px;
    }
    @include gadgets-to(tablet) {
        border:none;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-top: 1px;
        margin-left: 10px;
        }
    @include gadgets-to(laptop) {
        border:none;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-top: 1px;
        margin-left: 10px;
        }
    @include gadgets-to(desktop) {
        border:none;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-top: 1px;
        margin-left: 10px;
        }
    @include gadgets-to(largeDesktop) {
        border:none;
        border-color: map-get($generalStyles,datalistBorderColor);
        margin-top: 1px;
        margin-left: 10px;
        }
}

.xeingredChartChoiceLegend {
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
    @include gadgets-to(tablet) {
        font-family:var(--font-family);
        font-size: 12px;
        font-style: italic;
        }
    @include gadgets-to(laptop) {
        font-family:var(--font-family);
        font-size: 16px;
        font-style: italic;
        }
    @include gadgets-to(desktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        }
    @include gadgets-to(largeDesktop) {
        font-family:var(--font-family);
        font-size: 20px;
        font-style: italic;
        }
}

.ingred-katprodchoice-chart-button{
    @include gadgets-to(mobile) {
    margin:2px;
    width: auto;
    height: 20px;
    border-radius: 6px;
    background-color:RGB(255, 128, 0);
    color: white;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    border:none;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color:rgb(141, 71, 0);
    }
}
@include gadgets-to(tablet) {
    margin:2px;
    width: auto;
    height: 20px;
    border-radius: 6px;
    background-color:RGB(255, 128, 0);
    color: white;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    border:none;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color:rgb(141, 71, 0);
    }

}
@include gadgets-to(laptop) {
    margin:2px;
    width:100px;
    height: 30px;
    border-radius: 6px;
    background-color:RGB(255, 128, 0);
    color: white;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    border:none;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color:rgb(141, 71, 0);
    }
}
@include gadgets-to(desktop) {
    margin:2px;
    width:100px;
    height: 30px;
    border-radius: 6px;
    background-color:RGB(255, 128, 0);
    color: white;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 12px;
    border:none;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color:rgb(141, 71, 0);
    }
}
@include gadgets-to(largeDesktop) {
    margin:2px;
    width:100px;
    height: 40px;
    border-radius: 6px;
    background-color:RGB(255, 128, 0);
    color: white;
    font-family:map-get($generalStyles,fontFamily);
    font-size: 16px;
    border:none;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color:rgb(141, 71, 0);
    }
}
}


.ingred-katprodchoice-chart-button.active{
 @include gadgets-to(mobile) {
    background-color: red;
    color: white;
 }
    @include gadgets-to(tablet) {
        background-color: red;
        color: white;
    }
    @include gadgets-to(laptop) {
        background-color: red;
        color: white;
    }
    @include gadgets-to(desktop) {
        background-color: red;
        color: white;
    }
@include gadgets-to(largeDesktop) {
        background-color: red;
        color: white;
        }
}





.kanjiinput {
    @include gadgets-to(mobile) {
    width: 100%;
    height: 25px;
    font-family:var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles,colorblack);
    }
    @include gadgets-to(tablet) {
        width: 100%;
        height: 25px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        }
    @include gadgets-to(laptop) {
        width: 80%;
        height: 25px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        }
    @include gadgets-to(desktop) {
        width: 80%;
        height:30px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        }
        @include gadgets-to(largeDesktop) {
            width: 80%;
            height:30px;
            font-family:var(--font-family);
            font-size:18px;
            border: 1px solid;
            border-color: map-get($generalStyles,datalistBorderColor);
            outline: none;
            border-radius: map-get($generalStyles,sixBorderRadius);
            color: map-get($generalStyles,colorblack);
            }

}

.furiganainput {
    @include gadgets-to(mobile) {
    width: 100%;
    height: 25px;
    font-family:var(--font-family);
    font-size: 14px;
    border: 1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles,colorblack);
    margin-top: 3px;
    }
    @include gadgets-to(tablet) {
        width:100%;
        height: 25px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        margin-top: 3px;
        }
    @include gadgets-to(laptop) {
        width:80%;
        height: 25px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        margin-top: 3px;
        }
    @include gadgets-to(desktop) {
        width:80%;
        height:30px;
        font-family:var(--font-family);
        font-size: 14px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        margin-top: 3px;
        }
    @include gadgets-to(largeDesktop) {
        width:80%;
        height:30px;
        font-family:var(--font-family);
        font-size: 18px;
        border: 1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        margin-top: 3px;
        }
}


.kategoriFieldsetJAP {
    @include gadgets-to(mobile) {
    width:100%;
    height: 48px;
    padding-bottom: 2px;
    margin-top: 1px;
    border: none;
    margin-left: 40px;

    }
    @include gadgets-to(tablet) {
        width: 100%;
        height: 48px;
        padding-bottom: 2px;
        margin-top: 1px;
        border: none;
        margin-left:9%;
        }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        width: 100%;
        height: 48px;
        padding-bottom: 2px;
        margin-top: 10px;
        border: none;
       // margin-left: 9%;
        }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        width: 100%;
        height: 48px;
        padding-bottom: 2px;
        margin-top:4%;
        border: none;
       // margin-left: 9%;
        }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:row;
        justify-content:center;
        width: 100%;
        height: 48px;
        padding-bottom: 2px;
        margin-top:4%;
        border: none;
       // margin-left: 9%;
        }
}

.xeingredientFieldsetJAP {
    @include gadgets-to(mobile) {
    width: 90%;
    height: 60px;
    padding-bottom: 20px;
    border:none;
  //  margin-left: 40px;
    }
    @include gadgets-to(tablet) {
        width: 90%;
        height: 60px;
        padding-bottom: 20px;
        border:none;
      //  margin-left: 40px;
        }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width: 100%;
        height: 60px;
        padding-bottom: 20px;
        border:none;
        margin-top:3%;
      //  margin-left: 40px;
        }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width: 100%;
        height: 60px;
        padding-bottom: 20px;
        border:none;
        margin-top:3%;
      //  margin-left: 40px;
        }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width: 100%;
        height: 60px;
        padding-bottom: 20px;
        border:none;
        margin-top:3%;
      //  margin-left: 40px;
        }

}

.xeingredientLegendJAP{
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
}
@include gadgets-to(tablet) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    }
@include gadgets-to(laptop) {
    font-family:var(--font-family);
    font-size: 12px;
    font-style: italic;
    margin-left:10%;
    }
@include gadgets-to(desktop) {
    font-family:var(--font-family);
    font-size: 16px;
    font-style: italic;
    margin-left:10%;
    }
@include gadgets-to(desktop) {
    font-family:var(--font-family);
    font-size: 16px;
    font-style: italic;
    margin-left:10%;
    }
@include gadgets-to(largeDesktop) {
    font-family:var(--font-family);
    font-size: 16px;
    font-style: italic;
    margin-left:10%;
    }


}


.ingredselectJAP {
    @include gadgets-to(mobile) {
    width: 91%;
    height: 30px;
    font-family:var(--font-family);
    font-size: 12px;
    border:1px solid;
    border-color: map-get($generalStyles,datalistBorderColor);
    outline: none;
    border-radius: map-get($generalStyles,sixBorderRadius);
    color: map-get($generalStyles,colorblack);
    background-color: white;
    }
    @include gadgets-to(tablet) {
        width:92%;
        height: 30px;
        font-family:var(--font-family);
        font-size: 12px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        background-color: white;
        }
    @include gadgets-to(laptop) {
        width:80%;
        height: 30px;
        font-family:var(--font-family);
        font-size: 12px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        background-color: white;
        }
    @include gadgets-to(desktop) {
        width:80%;
        height: 30px;
        font-family:var(--font-family);
        font-size: 12px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        background-color: white;
        }
@include gadgets-to(largeDesktop) {
        width:80%;
        height: 35px;
        font-family:var(--font-family);
        font-size: 16px;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        outline: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        color: map-get($generalStyles,colorblack);
        background-color: white;
        }
}

.saveButtonJAP {
    @include gadgets-to(mobile) {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: map-get($generalStyles,pinkRed);
    color: white;
    font-family:var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    }
    @include gadgets-to(tablet) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,pinkRed);
        color: white;
        font-family:var(--font-family);
        font-size: 12px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }
    @include gadgets-to(laptop) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,pinkRed);
        color: white;
        font-family:var(--font-family);
        font-size: 12px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }

    @include gadgets-to(desktop) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,pinkRed);
        color: white;
        font-family:var(--font-family);
        font-size: 12px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }
    @include gadgets-to(largeDesktop) {
        width: 170px;
        height: 40px;
        border-radius: 6px;
        background-color: map-get($generalStyles,pinkRed);
        color: white;
        font-family:var(--font-family);
        font-size: 14px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        }
}

.saveButtonJAP1 {
    @include gadgets-to(mobile) {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: map-get($generalStyles,transparentChangeableMenu5);
    color: white;
    font-family:var(--font-family);
    font-size: 12px;
    cursor: pointer;
    margin-top: 1px;
    outline: none;
    border: none;
    margin-bottom: 5px;
    opacity: 0.7;
    }
    @include gadgets-to(tablet) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: white;
        font-family:var(--font-family);
        font-size: 12px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.7;
        }
    @include gadgets-to(laptop) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: white;
        font-family:var(--font-family);
        font-size: 12px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.7;
        }
    @include gadgets-to(desktop) {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: white;
        font-family:var(--font-family);
        font-size: 12px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.7;
        }
    @include gadgets-to(largeDesktop) {
        width: 170px;
        height: 40px;
        border-radius: 6px;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        color: white;
        font-family:var(--font-family);
        font-size: 14px;
        cursor: pointer;
        margin-top: 1px;
        outline: none;
        border: none;
        margin-bottom: 5px;
        opacity: 0.7;
        }
}
.xesaveButtonArea{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content:center;
   // align-items: center;
}

.xe1saveButtonAreaJAP{
//     width:100%;
//     display:flex;
//     flex-direction: row;
//     justify-content:center;
//    // align-items: center;
@include gadgets-to(mobile){
align-self:flex-end;
margin-right:14%;
}
@include gadgets-to(tablet){
    align-self:flex-end;
    margin-right:4.5%;
    }
@include gadgets-to(laptop){
    align-self:flex-end;
    margin-right:10%;
    }
@include gadgets-to(desktop){
    align-self:flex-end;
    margin-right:10%;
    margin-top:1.5%;
    }
@include gadgets-to(largeDesktop){
    align-self:flex-end;
    margin-right:10%;
    margin-top:1.5%;
    }
}

.xeeditIngredientBox1JAP {
    // width: 350px;
    // height: 140px;
    // border: 1px solid map-get($generalStyles,datalistBorderColor);
    // border-radius: 3px;
    // margin-top: 3px;
    @include gadgets-to(mobile) {
        width:100%;
        height:14vh;
       // border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: 3px;
        margin-top: 5px;
        }
        @include gadgets-to(tablet) {
            width:100%;
            height:14vh;
            border-radius: 3px;
            margin-top: 5px;
            }
        @include gadgets-to(laptop) {
            width:100%;
            height:14vh;
            border-radius: 3px;
            margin-top: 5px;
            }
        @include gadgets-to(desktop) {
            width:100%;
            height:14vh;
            border-radius: 3px;
            margin-top: 5px;
            }
    @include gadgets-to(largeDesktop) {
        width:100%;
        height:14vh;
        border-radius: 3px;
        margin-top: 5px;
        }
}

.xeeditIngredientFieldset1JAP {
    // margin-top: 10px;
    // margin-left: 10px;
    // width: 75%;
    // height: 20px;
    // border: none;
    @include gadgets-to(mobile) {
        margin-top:1px;
        width:200px;
        height:12vh;
        border:none;
        }
        @include gadgets-to(tablet) {
            margin-top:1px;
            width:200px;
            height:12vh;
            border:1px solid;
            border-color: map-get($generalStyles,datalistBorderColor);
            border-radius:map-get($generalStyles,sixBorderRadius);
            }
    @include gadgets-to(laptop) {
        margin-top:1px;
        width:200px;
        height:12vh;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        }
    @include gadgets-to(desktop) {
        margin-top:1px;
        width:200px;
        height:12vh;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        }
    @include gadgets-to(largeDesktop) {
        margin-top:1px;
        width:200px;
        height:9vh;
        border:1px solid;
        border-color: map-get($generalStyles,datalistBorderColor);
        border-radius:map-get($generalStyles,sixBorderRadius);
        }
}

.xeeditIngredientLegend1JAP {
    @include gadgets-to(mobile) {
        font-family:var(--font-family);
        font-size: 12px;
        font-style: italic;
        }

        @include gadgets-to(tablet) {
            font-family:var(--font-family);
            font-size: 12px;
            font-style: italic;
            }
        @include gadgets-to(laptop) {   
            font-family:var(--font-family);
            font-size: 12px;
            font-style: italic;
            }
        @include gadgets-to(desktop) {
            font-family:var(--font-family);
            font-size: 16px;
            font-style: italic;
            }
        @include gadgets-to(largeDesktop) {
            font-family:var(--font-family);
            font-size: 16px;
            font-style: italic;
            }
  
}

.xeeditIngredientKanjiInputJAP {
    // width: 300px;
    // height: 25px;
    // font-family:var(--font-family);
    // font-size: 14px;
    // border: 1px solid map-get($generalStyles,datalistBorderColor);
    // outline: none;
    // border-radius: map-get($generalStyles,sixBorderRadius);
    @include gadgets-to(mobile) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        }

        @include gadgets-to(tablet) {
            width:100%;
            height:25px;
          font-family: var(--font-family);
            font-size: 14px;
            border: 1px solid map-get($generalStyles, datalistBorderColor);
            border-radius: map-get($generalStyles, sixBorderRadius);
            outline: none;
            }
    @include gadgets-to(laptop) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        }

    @include gadgets-to(desktop) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        }
    @include gadgets-to(largeDesktop) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 16px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        }
}

.xeeditIngredientFuriganaInputJAP {
    // width: 300px;
    // height: 25px;
    // font-family:var(--font-family);
    // font-size: 14px;
    // border: 1px solid map-get($generalStyles,datalistBorderColor);
    // outline: none;
    // margin-top: 3px;
    // border-radius: map-get($generalStyles,sixBorderRadius);
    @include gadgets-to(mobile) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        margin-top:3px;
        }
        @include gadgets-to(tablet) {
            width:100%;
            height:25px;
          font-family: var(--font-family);
            font-size: 14px;
            border: 1px solid map-get($generalStyles, datalistBorderColor);
            border-radius: map-get($generalStyles, sixBorderRadius);
            outline: none;
            margin-top:3px;
            }
    @include gadgets-to(laptop) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        margin-top:3px;
        }
    @include gadgets-to(desktop) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 14px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        margin-top:3px;
        }
    @include gadgets-to(largeDesktop) {
        width:100%;
        height:25px;
      font-family: var(--font-family);
        font-size: 12px;
        border: 1px solid map-get($generalStyles, datalistBorderColor);
        border-radius: map-get($generalStyles, sixBorderRadius);
        outline: none;
        margin-top:3px;
        }
}

.xeeditIngredientButtonJAP {
    // width: 100px;
    // height: 30px;
    // margin-top: 4px;
    // margin-left: 203px;
    // border: none;
    // background-color: map-get($generalStyles,transparentChangeableMenu5);
    // border-radius: map-get($generalStyles,threeBorderRadius);
    // padding: 5px 10px;
    // cursor: pointer;
    @include gadgets-to(mobile) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
       }
       @include gadgets-to(tablet) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
    @include gadgets-to(laptop) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
    @include gadgets-to(desktop) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
    @include gadgets-to(largeDesktop) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,transparentChangeableMenu5);
        border-radius: map-get($generalStyles,sixBorderRadius);
        }
}

.xeeditIngredientButton1JAP {
    // width: 100px;
    // height: 30px;
    // margin-top: 4px;
    // margin-left: 203px;
    // border: none;
    // background-color: map-get($generalStyles,pinkRed);
    // border-radius: map-get($generalStyles,threeBorderRadius);
    // padding: 5px 10px;
    // cursor: pointer;
    // color: map-get($generalStyles,colorwhite);
    @include gadgets-to(mobile) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,pinkRed);
        border-radius: map-get($generalStyles,sixBorderRadius);
        color:white;
       }
       @include gadgets-to(tablet) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,pinkRed);
        border-radius: map-get($generalStyles,sixBorderRadius);
        color:white;
        }
    @include gadgets-to(laptop) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,pinkRed);
        border-radius: map-get($generalStyles,sixBorderRadius);
        color:white;
        }
    @include gadgets-to(desktop) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,pinkRed);
        border-radius: map-get($generalStyles,sixBorderRadius);
        color:white;
        }
    @include gadgets-to(largeDesktop) {
        width: 100px;
        height: 30px;
        margin-top: 4px;
        border: none;
        border-radius: map-get($generalStyles,sixBorderRadius);
        margin-left:52%;
        cursor: pointer;
        background-color: map-get($generalStyles,pinkRed);
        border-radius: map-get($generalStyles,sixBorderRadius);
        color:white;
        }
}

.xechartArea{
    @include gadgets-to(mobile) {
       display:flex;
         flex-direction:column;
            justify-content:center;
            width:99%;
            height:36vh;

    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        width:99%;
        height:60vh;
        }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        width:99%;
        margin-top:4vh;
        height:70vh;
        }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        width:99%;
        margin-top:4vh;
        height:70vh;
        }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction:column;
        justify-content:center;
        width:99%;
        margin-top:4vh;
        height:70vh;
        }
}

