@import '../style/MainStyles.scss';/* styles.css */



.xqtotWrapper{
    @include gadgets-to(mobile) {

    // display:flex;
    // flex-direction:row;
    // justify-content:center;
    //  align-items:center;
}
@include gadgets-to(tablet) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(laptop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(desktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
@include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:row;
    justify-content:center;
     align-items:center;
}
}



.xqingredTotalArea {
    @include gadgets-to(mobile) {
        display: grid;
       grid-template-rows: 7% 4% 78% 18%;
       // grid-template-rows:auto auto auto auto;

        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            ;
        width: 100%;
        height:auto;
        object-fit: cover;



    }


    @include gadgets-to(tablet) {
        display: grid;
        grid-template-columns:30% 45% 20%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c c d";
          column-gap:7px;
        width: 85%;
        height: 100%;
        object-fit: cover;

       
          }
    
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-columns:30% 45% 20%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c c d";
          column-gap:7px;
        width:85%;
        height: 100%;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-columns:30% 45% 20%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c c d";
          column-gap:7px;
        width:85%;
        height: 100%;
        object-fit: cover;


    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-columns:30% 45% 20%;
        grid-template-rows: auto auto;
        grid-template-areas:
          "a a a"
          "b b b"
          "c c d";
          column-gap:7px;
        width:85%;
        height: 100%;
        object-fit: cover;
    }

}

.xqingredTotalArea1 {
    @extend .xqingredTotalArea;
    background-color: initial;
}

.xqingredTotalAreaA {

    @include gadgets-to(mobile) {

    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 99%;
    height:63vh;
    margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius:map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(tablet) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius:map-get($generalStyles, sixBorderRadius);
    @media(min-width:1170px) and (max-width:1190px)
    {
        height:74vh;
    }
}
@include gadgets-to(laptop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius:map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(desktop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius:map-get($generalStyles, sixBorderRadius);
}
@include gadgets-to(largeDesktop) {
    grid-area: c;
    border: 1px solid;
    border-color: map-get($generalStyles, datalistBorderColor);
    width: 99%;
    height: 80vh;
    margin-left: 1px;
    background-color: map-get($generalStyles, transparentChangeable);
    margin-top:5px;
    border-radius:map-get($generalStyles, sixBorderRadius);
}
}


.xqingredTotalAreaB {
    @extend .xqingredTotalAreaA;
    @include gadgets-to(mobile) {
        grid-area: d;
        height:10vh;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: map-get($generalStyles, sixBorderRadius);

        @media(min-width:360px) and (max-width:370px)
        {
            margin-top: calc(15px - var(--margin-def));//japanese
        }
        
    @media(min-width:375px) and (max-width:380px)
    {
    margin-top:10px;
    }
    @media(min-width:384px) and (max-width:392px)
    {
        margin-top: calc(15px - var(--margin-def));//japanese
    }
    @media(min-width:410px) and (max-width:415px)
    and (min-height:700px)and (max-height:750px)
    {
        margin-top: calc(15px - var(--margin-def2));//japanese
    }
    @media(min-width:410px) and (max-width:415px)
    and (min-height:751px)
    {
        margin-top: calc(15px - var(--margin-def2));//japanese
    }
    

}
@include gadgets-to(tablet) {
    grid-area: d;
    height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: map-get($generalStyles, sixBorderRadius);
  
    @media(min-width:1170px) and (max-width:1190px)
    {
        height:74vh;
    }
}
@include gadgets-to(laptop) {
    grid-area: d;
    height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(desktop) {
    grid-area: d;
    height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
@include gadgets-to(largeDesktop) {
    grid-area: d;
    height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: map-get($generalStyles, sixBorderRadius);

}
}




.xqheaderArea{
    @include gadgets-to(mobile) {
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
    height:20px;
}
@include gadgets-to(tablet) {
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
    height:30px;
    @media(orientation:portrait){
        height:45px;
    }
}
@include gadgets-to(laptop) {
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
    height:40px;
}
@include gadgets-to(desktop) {
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
    height:40px;
}
@include gadgets-to(largeDesktop) {
    grid-area:a;
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:99%;
    margin-top:2px;
    height:7vh;
}
}



.xqrangeArea{
    @include gadgets-to(mobile) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:center;
        align-items:flex-end;
        height:3vh;
    }
    @include gadgets-to(tablet) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        height:20px;

 
         @media(min-width:1081px) and (max-width:1170px)
         {
            height:20px;
         }
         // as ipad
        //  @media(min-width:1171px) and (max-width:1195px)
        //  {
         
        //     height:auto;
        //  }

         @media(min-width:1196px) and (max-width:1400px)
         {
            height:20px;
         }


}
@include gadgets-to(laptop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:3vh;
    }
@include gadgets-to(desktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
    height:4vh;
    }
@include gadgets-to(largeDesktop) {
        grid-area: b;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
     //   margin-top:3%;
}
}
.xqrange1{
@include gadgets-to(mobile) {
   // margin-top:3%;
    accent-color: blue;
    width:125px;
    margin-left:10px;
}
@include gadgets-to(tablet) {
  //  margin-top:3%;
    accent-color: blue;
    width:125px;
    margin-left:10px;
    margin-right:2%;
    cursor:pointer;
}
@include gadgets-to(laptop) {
  //  margin-top:3%;
    accent-color: blue;
    width:125px;
    margin-left:10px;
    cursor:pointer;
}
@include gadgets-to(desktop) {
  //  margin-top:3%;
    accent-color: blue;
    width:125px;
    margin-left:10px;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
  //  margin-top:3%;
    accent-color: blue;
    width:125px;
    margin-left:10px;
    cursor:pointer;
}
}

.xqrange2 {
@extend .xqrange1;
@include gadgets-to(mobile) {
    accent-color: rgb(251, 239, 132);
    width:125px;

}
@include gadgets-to(tablet) {
    accent-color: rgb(251, 239, 132);
    width:125px;
    cursor:pointer;
}
@include gadgets-to(laptop) {
    accent-color: rgb(251, 239, 132);
    width:125px;
    cursor:pointer;
}
@include gadgets-to(desktop) {
    accent-color: rgb(251, 239, 132);
    width:125px;
    cursor:pointer;
}
@include gadgets-to(largeDesktop) {
    accent-color: rgb(251, 239, 132);
    width:125px;
    cursor:pointer;
}
}

.xqcompName {
    font-family: map-get($generalStyles,fontFamilyLobster);
    font-Size:26px;
    color:white;
   
    //pc
    @include gadgets-to(tablet) {
        margin-top:2px;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:30px;
        color:white;
    }
    @include gadgets-to(laptop) {
        margin-top:2vh;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:35px;
        color:white;
    }

    @include gadgets-to(desktop) {
        margin-top:1vh;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:45px;
        color:white;
    }

    @include gadgets-to(largeDesktop) {
        margin-top:3vh;
        font-family: map-get($generalStyles,fontFamilyLobster);
        font-Size:45px;
        color:white;
    }
}

.xqcompName1 {
    grid-area: a;

    font-family: map-get($generalStyles,fontFamilyLobster);
      font-Size:26px;
      color:map-get($generalStyles,transparentChangeableMenu5);
     
  
     @include gadgets-to(tablet) {
      margin-top:2px;
      font-family: map-get($generalStyles,fontFamilyLobster);
      font-Size:30px;
      color:map-get($generalStyles,transparentChangeableMenu5);
  }
  @include gadgets-to(laptop) {
      margin-top:2vh;
      font-family: map-get($generalStyles,fontFamilyLobster);
      font-Size:30px;
      color:map-get($generalStyles,transparentChangeableMenu5);
  }
  
  
  @include gadgets-to(desktop) {
      margin-top:1vh;
      font-family: map-get($generalStyles,fontFamilyLobster);
      font-Size:44px;
      color:map-get($generalStyles,transparentChangeableMenu5);
  }
  
  @include gadgets-to(largeDesktop) {
      margin-top:3vh;
      font-family: map-get($generalStyles,fontFamilyLobster);
      font-Size:45px;
      color:map-get($generalStyles,transparentChangeableMenu5);
  }
}

.xqcompNameJAP{
    @extend .xqcompName;
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size:30px;
  
    color:white;
}
@include gadgets-to(tablet) {
    font-family:var(--font-family);
    font-size: 35px;
    color:white;

}
@include gadgets-to(laptop) {
    font-family:var(--font-family);
    font-size: 35px;
    color:white;
    margin-top:2vh;
}
@include gadgets-to(desktop) {
    font-family:var(--font-family);
    font-size: 40px;
    color:white;
    margin-top:2vh;
}
@include gadgets-to(largeDesktop) {
    font-family:var(--font-family);
    font-size: 50px;
    font-weight:600;
    color:white;
    margin-top:2vh;
}
}

.xqcompNameJAP1{
    @extend .xqcompName1;
    @include gadgets-to(mobile) {
    font-family:var(--font-family);
    font-size: 30px;
    color:map-get($generalStyles,transparentChangeableMenu5);

}
@include gadgets-to(tablet) {
    font-family:var(--font-family);
    font-size: 35px;
    color:map-get($generalStyles,transparentChangeableMenu5);
}
@include gadgets-to(laptop) {
    font-family:var(--font-family);
    font-size: 35px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    margin-top:2vh;
}
@include gadgets-to(desktop) {
    font-family:var(--font-family);
    font-size: 40px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    margin-top:2vh;
}
@include gadgets-to(largeDesktop) {
    font-family:var(--font-family);
    font-size: 50px;
    color:map-get($generalStyles,transparentChangeableMenu5);
    margin-top:2vh;
}
}


.xqingredTop {
    @include gadgets-to(mobile) {
    margin-top: 5px;
    width:100%;
    margin-top:1vh;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(tablet) {
    margin-top: 5px;
    width:100%;
   // margin-top:1vh;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(laptop) {
    margin-top: 5px;
    width:100%;
   // margin-top:1vh;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(desktop) {
    margin-top: 5px;
    width:100%;
    margin-top:1vh;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@include gadgets-to(largeDesktop) {
    margin-top: 5px;
    width:100%;
    margin-top:1vh;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
}


.xqingredHeader {
    display: flex;
    flex-direction: row;
    width:90%;
}

.xqingredId{
    @include gadgets-to(mobile) {
        width:15%;
        height: 25px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: black;
        margin-left: 3px;
        margin-bottom: 3px;
 font-family:var(--font-family);
        font-size: 12px;
    }
    @include gadgets-to(tablet) {
        width:15%;
        height:30px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: black;
        margin-left: 3px;
        margin-bottom: 3px;
 font-family:var(--font-family);
        font-size: 14px;
    }
    @include gadgets-to(laptop) {
        width:15%;
        height: 30px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: black;
        margin-left: 3px;
        margin-bottom: 3px;
 font-family:var(--font-family);
        font-size: 16px;
    }
    @include gadgets-to(desktop) {
        width:15%;
        height: 35px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: black;
        margin-left: 3px;
        margin-bottom: 3px;
 font-family:var(--font-family);
        font-size: 20px;
    }
    @include gadgets-to(largeDesktop) {
        width:15%;
        height:40px;
        border: map-get($generalStyles, prefferedBorderStyle);
        border-color: map-get($generalStyles, prefferedBorderColor);
        background-color: map-get($generalStyles, transparentChangeableMenu5);
        border-radius: map-get($generalStyles, sixBorderRadius);
        color: black;
        margin-left: 3px;
        margin-bottom: 3px;
 font-family:var(--font-family);
        font-size:24px;
    }
}
.xqingredBenaemn{
    @extend .xqingredId;
    width:calc(55% - 2px);
    margin-left:2px;
    
}
.xqingredVikt{
    @extend .xqingredId;
   width:calc(15% - 2px);
   margin-left:2px;
    
}
.xqingredSumma {
    @extend .xqingredId;
    @include gadgets-to(mobile) {

    width:calc(15% - 2px);
    margin-left:2px;
    padding:1px;
    }
    @include gadgets-to(tablet) {
        margin-right:2px;
    }

    @include gadgets-to(laptop) {
        margin-right:8px;

}
@include gadgets-to(desktop) {
    margin-right:8px;

}
@include gadgets-to(largeDesktop) {
    margin-right:8px;

}
}




.xqdata {
    @include gadgets-to(mobile) {
    width:90%;
    display: flex;
    flex-direction:column;
  //  justify-content:center;
   // align-items:center;
    
    margin-left: 3px;
    margin-bottom: 3px;
    height:56vh;
    max-height:56vh;
    overflow-y: scroll;
    // small scroll
    /// webkit
    &::-webkit-scrollbar {
        width: 1px;
    }

}
@include gadgets-to(tablet) {
    width:90%;
    display: flex;
    flex-direction:column;

    margin-left: 3px;
    margin-left: 3px;
    margin-bottom: 3px;
    height:auto;
    max-height:72vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 4px;
    }
    @media(max-width:1080px) and (min-width:768px)
    {
        height:68vh;
        max-height:68vh;
    }
    @media(min-width:1081px) and (max-width:1170px)
    {
        height:68vh;
        max-height:68vh;
    }
    @media(min-width:1171px) and (max-width:1195px)
    {
        height:64vh;
        max-height:64vh;
    }

}
@include gadgets-to(laptop) {
    width:90%;
    display: flex;
    flex-direction:column;

    margin-left: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-bottom: 3px;
    height:auto;
    max-height:72vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 10px;
    }
    //track
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, transparentChangeableMenu5)
    }
    // Handle
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius:10px;
        border:1px solid map-get($generalStyles, transparentChangeableMenu5);
        cursor:pointer;
    }

}
@include gadgets-to(desktop) {
    width:90%;
    display: flex;
    flex-direction:column;

    margin-left: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-bottom: 3px;
    height:auto;
    max-height:70vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 10px;
    }
    //track
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, transparentChangeableMenu5)
    }
    // Handle
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius:10px;
        border:1px solid map-get($generalStyles, transparentChangeableMenu5);
        cursor:pointer;
    }

}
@include gadgets-to(largeDesktop) {
    width:90%;
    display: flex;
    flex-direction:column;

    margin-left: 3px;
    margin-bottom: 3px;
    height:auto;
    max: height 70vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 10px;
    }
    //track
    &::-webkit-scrollbar-track {
        background:map-get($generalStyles, transparentChangeableMenu5);
    }
    // Handle
    &::-webkit-scrollbar-thumb {
        background:white;
        border-radius:10px;
        border:1px solid map-get($generalStyles, transparentChangeableMenu5);
        cursor:pointer;
    }

}
}


.xqdatarows{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}

.xqtotproduktionid{
    @include gadgets-to(mobile) {

    width:15%;
    height: 25px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: black;
    margin-bottom: 3px;
 font-family:var(--font-family);
    font-size: 12px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(tablet) {
    width:15%;
    height:30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: black;
    margin-bottom: 3px;
 font-family:var(--font-family);
    font-size: 14px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(laptop) {
    width:15%;
    height: 30px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: black;
    margin-bottom: 3px;
 font-family:var(--font-family);
    font-size: 16px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(desktop) {
    width:15%;
    height: 35px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: black;
    margin-bottom: 3px;
 font-family:var(--font-family);
    font-size: 20px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
@include gadgets-to(largeDesktop) {
    width:15%;
    height:40px;
    border: map-get($generalStyles, prefferedBorderStyle);
    border-color: map-get($generalStyles, prefferedBorderColor);
    border-radius: map-get($generalStyles, sixBorderRadius);
    color: black;
    margin-bottom: 3px;
 font-family:var(--font-family);
    font-size: 24px;
    background-color: map-get($generalStyles, transparentChangeableMenu4);
}
}

.xqtotproduktionid1{
    @extend .xqtotproduktionid;
    background-color: map-get($generalStyles, transparentChangeableMenu5);

}
.xqtotbenaemn{
    @extend .xqtotproduktionid;
    width:calc(55% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left:2px;

}
.xqtotbenaemn1{
    @extend .xqtotbenaemn;
    background-color: map-get($generalStyles, transparentChangeableMenu5);
}
.xqtotvikt{
    @extend .xqtotproduktionid;
    width:calc(15% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left:2px;
    
}
.xqtotvikt1{
    @extend .xqtotvikt;
    background-color: map-get($generalStyles, transparentChangeableMenu5);

}
.xqtotsum{
    @extend .xqtotproduktionid;
    width:calc(15% - 2px);
    background-color: map-get($generalStyles, transparentChangeableMenu4);
    margin-left:2px;
    
}
.xqtotsum1 {
    @extend .xqtotsum;
    background-color: map-get($generalStyles, transparentChangeableMenu5);

}


.xqheadertext {
    @include gadgets-to(mobile) {
 font-family:var(--font-family);
    font-size: 14px;
}
@include gadgets-to(tablet) {
    margin-top:1vh;
 font-family:var(--font-family);
    font-size: 16px;
    margin-left:3.5vw;
}
@include gadgets-to(laptop) {
    margin-top:1vh;
 font-family:var(--font-family);
    font-size: 16px;
    margin-left:3.5vw;
}
@include gadgets-to(desktop) {
 font-family:var(--font-family);
    font-size: 20px;
    margin-left:3.5vw;
    margin-top:1vh;
}
@include gadgets-to(largeDesktop) {
 font-family:var(--font-family);
    font-size: 24px;
    margin-left:3.5vw;
    margin-top:1vh;
}
}


.xqingredTotalSummary{
    @include gadgets-to(mobile) { 
    margin-top: 2px;
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 16px;
    margin-top:5px;
}
@include gadgets-to(tablet) {
    margin-top: 2px;
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 16px;
    margin-top:5px;
    @media (orientation: portrait) {
        font-size:14px;
        margin-left:5px;
    }
}
@include gadgets-to(laptop) {
    margin-top: 2px;
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 20px;
    margin-top:5px;
}
@include gadgets-to(desktop) {
    margin-top: 2px;
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 24px;
    margin-top:5px;
}
@include gadgets-to(largeDesktop) {
    margin-top: 2px;
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 28px;
    margin-top:5px;
}
}

.xqingredTotalSummaryBottomAntal {
    @include gadgets-to(mobile) {
    margin-top: 2px;
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 16px;
    margin-top:5px;
}
@include gadgets-to(tablet) {
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 16px;
    margin-top:3vh;
  
    @media (orientation: portrait) {
        font-size:14px;
        margin-left:5px;
    }
  
}
@include gadgets-to(laptop) {
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 20px;
    margin-top:2vh;
}
@include gadgets-to(desktop) {
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 24px;
    margin-top:2vh;
}
@include gadgets-to(largeDesktop) {
    margin-left: 15px;
 font-family:var(--font-family);
    font-size: 28px;
    margin-top:2vh;
}
}


.xqingredTotalSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
}

.xqingredTotalSpinner {
    //  backgroundColor:'transparent',
}

.xqbackgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  object-fit: cover;
    z-index: -1;
    transform:scale(1)
   
  }
