:root {
    --rgbaVal:white;
    --rgbaVal2:white;
    --getColorVal:white;
    --val:white;
    --windowHeight: 92vh;
  }
  
  .backgroundImageRecepyNew {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: -1;
    transform: scale(1);
  }
  
  .indexTop {
    margin-right: 1px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }/*# sourceMappingURL=Index.css.map */