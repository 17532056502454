:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

/* styles.css */
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xptotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xptotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .xptotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalArea, .xpProdTotalArea1 {
    display: grid;
    grid-template-rows: 4.5% 5% 46% 44%;
    grid-template-areas: "a" "b" "c" "d";
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalArea, .xpProdTotalArea1 {
    display: grid;
    grid-template-columns: 50% 49%;
    grid-template-rows: auto auto;
    grid-template-areas: "a a a" "b b b" "c d d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .xpProdTotalArea .area-f, .xpProdTotalArea1 .area-f {
    grid-area: d;
  }
  .xpProdTotalArea .area-f .tabs, .xpProdTotalArea1 .area-f .tabs {
    display: flex;
    justify-content: space-between;
  }
  .xpProdTotalArea .area-f .tab-content, .xpProdTotalArea1 .area-f .tab-content {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalArea, .xpProdTotalArea1 {
    display: grid;
    grid-template-columns: 52% 48%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d d";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 90%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalArea, .xpProdTotalArea1 {
    display: grid;
    grid-template-columns: 52% 48%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d d";
    -moz-column-gap: 15px;
         column-gap: 15px;
    width: 90%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalArea, .xpProdTotalArea1 {
    display: grid;
    grid-template-columns: 52% 48%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a a a" "b b b" "c d e";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 99.8%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalWindowA, .xpProdTotalWindowB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 40vh;
    background-color: var(--val);
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalWindowA, .xpProdTotalWindowB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 84vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xpProdTotalWindowA, .xpProdTotalWindowB {
    height: 78vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalWindowA, .xpProdTotalWindowB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 82vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalWindowA, .xpProdTotalWindowB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 82vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalWindowA, .xpProdTotalWindowB {
    grid-area: c;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: 100%;
    height: 90vh;
    background-color: var(--val);
    margin-top: 5px;
    border-radius: 6px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalWindowB {
    grid-area: d;
    display: flex;
    flex-direction: column;
    height: 39vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalWindowB {
    grid-area: d;
    display: flex;
    flex-direction: column;
    height: 84vh;
    margin-top: 5px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xpProdTotalWindowB {
    height: 78vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalWindowB {
    grid-area: d;
    display: flex;
    flex-direction: column;
    height: 82vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalWindowB {
    grid-area: d;
    display: flex;
    flex-direction: column;
    height: 82vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalWindowB {
    grid-area: d;
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin-top: 5px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpheaderArea {
    grid-area: a;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpheaderArea {
    grid-area: a;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpheaderArea {
    grid-area: a;
    height: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .xpheaderArea {
    grid-area: a;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xprangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xprangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1081px) and (max-width: 1170px) {
  .xprangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1196px) and (max-width: 1400px) {
  .xprangeArea {
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xprangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 2vh;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xprangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .xprangeArea {
    grid-area: b;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xprange1, .xprange2 {
    margin-top: 3%;
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xprange1, .xprange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 2%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xprange1, .xprange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xprange1, .xprange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xprange1, .xprange2 {
    accent-color: blue;
    width: 125px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xprange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xprange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xprange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xprange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xprange2 {
    accent-color: rgb(251, 239, 132);
    width: 125px;
    cursor: pointer;
  }
}

.xpCompName {
  grid-area: a;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpCompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpCompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 32px;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpCompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpCompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: white;
  }
}
@media only screen and (min-width:2303px) {
  .xpCompName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: white;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpCompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 24px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpCompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 28px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpCompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 38px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpCompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 44px;
    color: var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xpCompName1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lobster";
    font-size: 46px;
    color: var(--rgbaVal);
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    height: 300px;
    border: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    height: 80vh;
    border: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 80vh;
    border: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.2vh;
    height: 80vh;
    border: none;
    padding: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalFieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.2vh;
    height: 80vh;
    border: none;
    padding: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalLegend {
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalLegend {
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 5%;
    padding-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalLegend {
    font-family: var(--font-family);
    font-size: 16px;
    margin-left: 5%;
    padding-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalLegend {
    font-family: var(--font-family);
    font-size: 18px;
    margin-left: 7.5%;
    padding-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalLegend {
    font-family: var(--font-family);
    font-size: 30px;
    margin-left: 10%;
    padding-top: 10px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalHeaderArea {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalHeaderArea {
    display: flex;
    flex-direction: row;
    width: 90%;
    border: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalHeaderArea {
    display: flex;
    flex-direction: row;
    width: 90%;
    border: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalHeaderArea {
    display: flex;
    flex-direction: row;
    width: 85%;
    border: none;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalHeaderArea {
    display: flex;
    flex-direction: row;
    width: 80%;
    border: none;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalProduktionsId, .xpProdTotalTot, .xpProdTotalPris, .xpProdTotalAntal, .xpProdTotalBenaemn, .xpProdTotalProduktid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(15% - 2px);
    height: 25px;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalProduktionsId, .xpProdTotalTot, .xpProdTotalPris, .xpProdTotalAntal, .xpProdTotalBenaemn, .xpProdTotalProduktid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: calc(15% - 2px);
    height: 25px;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-left: 1px;
    margin-top: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalProduktionsId, .xpProdTotalTot, .xpProdTotalPris, .xpProdTotalAntal, .xpProdTotalBenaemn, .xpProdTotalProduktid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: calc(15% - 2px);
    height: 25px;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalProduktionsId, .xpProdTotalTot, .xpProdTotalPris, .xpProdTotalAntal, .xpProdTotalBenaemn, .xpProdTotalProduktid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: calc(15% - 2px);
    height: 35px;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 3px;
    font-family: var(--font-family);
    font-size: 16px;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalProduktionsId, .xpProdTotalTot, .xpProdTotalPris, .xpProdTotalAntal, .xpProdTotalBenaemn, .xpProdTotalProduktid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgb(223, 212, 212);
    width: calc(15% - 2px);
    height: 40px;
    background-color: var(--rgbaVal);
    border-radius: 6px;
    margin-top: 3px;
    font-family: var(--font-family);
    font-size: 20px;
    cursor: pointer;
  }
}

.xpProdTotalProduktid {
  width: calc(15% - 2px);
  font-size: 14px;
  margin-left: 2px;
}

.xpProdTotalBenaemn {
  width: calc(40% - 2px);
  margin-left: 2px;
}

.xpProdTotalAntal {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xpProdTotalPris {
  width: calc(15% - 2px);
  margin-left: 2px;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalTot {
    width: 15%;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalTot {
    width: 15%;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalTot {
    width: 15%;
    margin-left: 2px;
    margin-right: 7px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalTot {
    width: 15%;
    margin-left: 1px;
    margin-right: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalTot {
    width: 15%;
    margin-left: 1px;
    margin-right: 14px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xptotallist1Data {
    display: flex;
    flex-direction: column;
    max-height: 32vh;
    overflow-y: scroll;
    width: 100%;
  }
  .xptotallist1Data::-webkit-scrollbar {
    width: 1px;
  }
  .xptotallist1Data::-webkit-scrollbar-track {
    background: transparent;
  }
  .xptotallist1Data::-webkit-scrollbar-thumb {
    background: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xptotallist1Data {
    display: flex;
    flex-direction: column;
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    width: 90%;
    outline: none;
  }
  .xptotallist1Data::-webkit-scrollbar {
    width: 1px;
  }
  .xptotallist1Data::-webkit-scrollbar-track {
    background: transparent;
  }
  .xptotallist1Data::-webkit-scrollbar-thumb {
    background: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xptotallist1Data {
    height: 70vh;
    max-height: 70vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xptotallist1Data {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 80vh;
    overflow-y: scroll;
    width: 90%;
    outline: none;
  }
  .xptotallist1Data::-webkit-scrollbar {
    width: 10px;
  }
  .xptotallist1Data::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xptotallist1Data::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xptotallist1Data {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 80vh;
    overflow-y: scroll;
    width: 85%;
  }
  .xptotallist1Data::-webkit-scrollbar {
    width: 10px;
  }
  .xptotallist1Data::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xptotallist1Data::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
  }
}
@media only screen and (min-width:2303px) {
  .xptotallist1Data {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 65vh;
    overflow-y: scroll;
    width: 80%;
  }
  .xptotallist1Data::-webkit-scrollbar {
    width: 10px;
  }
  .xptotallist1Data::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xptotallist1Data::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--rgbaVal);
  }
}

.xptotalproduktionid, .xptotallisttotal, .xptotalpris, .xptotalantal, .xptotalbenaemn, .xptotalproduktid, .xptotalproduktionid1, .xptotallisttotal1, .xptotalpris1, .xptotalantal1, .xptotalbenaemn1, .xptotalproduktid1 {
  width: 15%;
  height: 25px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  background-color: var(--rgbaVal2);
  border-radius: 6px;
  margin-top: 1px;
  font-family: var(--font-family);
  font-size: 12px;
  color: black;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xptotalproduktionid, .xptotallisttotal, .xptotalpris, .xptotalantal, .xptotalbenaemn, .xptotalproduktid, .xptotalproduktionid1, .xptotallisttotal1, .xptotalpris1, .xptotalantal1, .xptotalbenaemn1, .xptotalproduktid1 {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xptotalproduktionid, .xptotallisttotal, .xptotalpris, .xptotalantal, .xptotalbenaemn, .xptotalproduktid, .xptotalproduktionid1, .xptotallisttotal1, .xptotalpris1, .xptotalantal1, .xptotalbenaemn1, .xptotalproduktid1 {
    height: 40px;
    font-size: 20px;
  }
}

.xptotalproduktionid1, .xptotallisttotal1, .xptotalpris1, .xptotalantal1, .xptotalbenaemn1, .xptotalproduktid1 {
  background-color: var(--rgbaVal);
}

.xptotalproduktid {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xptotalproduktid1 {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xptotalbenaemn {
  width: calc(40% - 2px);
  margin-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xptotalbenaemn1 {
  width: calc(40% - 2px);
  margin-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xptotalantal {
  width: calc(15% - 2px);
  margin-left: 1px;
}

.xptotalantal1 {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xptotalpris {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xptotalpris1 {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xptotallisttotal {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xptotallisttotal1 {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xpProdTotalSummaryBottom {
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalSummaryBottomAntal {
    font-family: var(--font-family);
    margin-top: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalSummaryBottomAntal {
    font-family: var(--font-family);
    margin-left: 5%;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalSummaryBottomAntal {
    font-family: var(--font-family);
    margin-left: 15px;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalSummaryBottomAntal {
    font-family: var(--font-family);
    margin-left: 5%;
    margin-top: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalSummaryBottomAntal {
    font-family: var(--font-family);
    margin-left: 15px;
    margin-top: 8px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalSummaryText {
    font-family: var(--font-family);
    font-size: 12px;
    white-space: nowrap;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalSummaryText {
    font-family: var(--font-family);
    font-size: 16px;
    white-space: nowrap;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalSummaryText {
    font-family: var(--font-family);
    font-size: 16px;
    white-space: nowrap;
    margin-left: 3%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalSummaryText {
    font-family: var(--font-family);
    font-size: 18px;
    white-space: nowrap;
    margin-left: 3%;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalSummaryText {
    font-family: var(--font-family);
    font-size: 30px;
    white-space: nowrap;
    margin-left: 4%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalSummaryFieldset {
    width: 100%;
    margin-top: 8px;
    height: 300px;
    border: none;
    border-color: rgb(223, 212, 212);
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalSummaryFieldset {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    height: 80vh;
    border: none;
    border-color: rgb(223, 212, 212);
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalSummaryFieldset {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1px;
    margin-top: 8px;
    height: 80vh;
    border: none;
    border-color: rgb(223, 212, 212);
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalSummaryFieldset {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1px;
    margin-top: 8px;
    height: 300px;
    border: none;
    border-color: rgb(223, 212, 212);
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalSummaryFieldset {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    margin-top: 8px;
    height: 300px;
    border: none;
    border-color: rgb(223, 212, 212);
    padding: 0;
    margin: 0;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpProdTotalSummaryTopLegend {
    font-family: var(--font-family);
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpProdTotalSummaryTopLegend {
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpProdTotalSummaryTopLegend {
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpProdTotalSummaryTopLegend {
    font-family: var(--font-family);
    font-size: 14px;
    margin-left: 8%;
  }
}
@media only screen and (min-width:2303px) {
  .xpProdTotalSummaryTopLegend {
    font-family: var(--font-family);
    font-size: 20px;
    margin-left: 5%;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpstatusheaderMain {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpstatusheaderMain {
    margin: 0;
    padding: 0;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpstatusheaderMain {
    margin: 0;
    padding: 0;
    width: 90%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpstatusheaderMain {
    margin: 0;
    padding: 0;
    width: 85%;
  }
}
@media only screen and (min-width:2303px) {
  .xpstatusheaderMain {
    margin: 0;
    padding: 0;
    width: 90%;
  }
}

.xpstatusheaderHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .xpstatusheaderData {
    width: 100%;
    height: 28vh;
    max-height: 28vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
  }
  .xpstatusheaderData::-webkit-scrollbar {
    width: 1px;
  }
  .xpstatusheaderData::-webkit-scrollbar-track {
    background: transparent;
  }
  .xpstatusheaderData::-webkit-scrollbar-thumb {
    background: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .xpstatusheaderData {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
  }
  .xpstatusheaderData::-webkit-scrollbar {
    width: 1px;
  }
  .xpstatusheaderData::-webkit-scrollbar-track {
    background: transparent;
  }
  .xpstatusheaderData::-webkit-scrollbar-thumb {
    background: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1170px) and (max-width: 1190px) {
  .xpstatusheaderData {
    height: 68vh;
    max-height: 68vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpstatusheaderData {
    width: 100%;
    height: auto;
    max-height: 72vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
  }
  .xpstatusheaderData::-webkit-scrollbar {
    width: 10px;
  }
  .xpstatusheaderData::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xpstatusheaderData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpstatusheaderData {
    width: 100%;
    height: auto;
    max-height: 76vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
  }
  .xpstatusheaderData::-webkit-scrollbar {
    width: 10px;
  }
  .xpstatusheaderData::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xpstatusheaderData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid;
  }
}
@media only screen and (min-width:2303px) {
  .xpstatusheaderData {
    width: 100%;
    height: auto;
    max-height: 77vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
  }
  .xpstatusheaderData::-webkit-scrollbar {
    width: 10px;
  }
  .xpstatusheaderData::-webkit-scrollbar-track {
    background: var(--rgbaVal);
  }
  .xpstatusheaderData::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
    border: 1px solid;
  }
}

.xpstatusheaderProduktionsid, .xpsamproduktionid, .xpsamtotal, .xpsamsum, .xpsambenaemn, .xpsamkategoriid, .xpsamproduktionid1, .xpsamtotal1, .xpsamsum1, .xpsambenaemn1, .xpsamkategoriid1, .xpstatusheaderNum, .xpstatusheaderSum, .xpstatusheaderBenaemn, .xpstatusheaderProduktid {
  width: 15%;
  height: 25px;
  margin-bottom: 2px;
  border: 1px solid;
  border-color: rgb(223, 212, 212);
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 12px;
  background-color: var(--rgbaVal);
  border-color: rgb(223, 212, 212);
  color: black;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpstatusheaderProduktionsid, .xpsamproduktionid, .xpsamtotal, .xpsamsum, .xpsambenaemn, .xpsamkategoriid, .xpsamproduktionid1, .xpsamtotal1, .xpsamsum1, .xpsambenaemn1, .xpsamkategoriid1, .xpstatusheaderNum, .xpstatusheaderSum, .xpstatusheaderBenaemn, .xpstatusheaderProduktid {
    height: 35px;
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .xpstatusheaderProduktionsid, .xpsamproduktionid, .xpsamtotal, .xpsamsum, .xpsambenaemn, .xpsamkategoriid, .xpsamproduktionid1, .xpsamtotal1, .xpsamsum1, .xpsambenaemn1, .xpsamkategoriid1, .xpstatusheaderNum, .xpstatusheaderSum, .xpstatusheaderBenaemn, .xpstatusheaderProduktid {
    height: 40px;
    font-size: 20px;
  }
}

.xpstatusheaderProduktid {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xpstatusheaderBenaemn {
  width: calc(40% - 2px);
  margin-left: 2px;
}

.xpstatusheaderSum {
  width: calc(15% - 2px);
  margin-left: 2px;
  padding: 1px;
}

.xpstatusheaderNum {
  width: calc(15% - 2px);
  margin-left: 2px;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .xpstatusheaderNum {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .xpstatusheaderNum {
    margin-right: 10px;
  }
}
@media only screen and (min-width:2303px) {
  .xpstatusheaderNum {
    margin-right: 10px;
  }
}

.xpsamproduktionid, .xpsamtotal, .xpsamsum, .xpsambenaemn, .xpsamkategoriid, .xpsamproduktionid1, .xpsamtotal1, .xpsamsum1, .xpsambenaemn1, .xpsamkategoriid1 {
  width: 15%;
  background-color: var(--rgbaVal2);
  margin-top: 1px;
}

.xpsamproduktionid1, .xpsamtotal1, .xpsamsum1, .xpsambenaemn1, .xpsamkategoriid1 {
  background-color: var(--rgbaVal);
  width: 15%;
}

.xpsamkategoriid {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xpsamkategoriid1 {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xpsambenaemn {
  width: calc(40% - 2px);
  margin-left: 2px;
}

.xpsambenaemn1 {
  width: calc(40% - 2px);
  margin-left: 2px;
}

.xpsamsum {
  width: calc(15% - 2px);
  padding: 1px;
  margin-left: 2px;
}

.xpsamsum1 {
  width: calc(15% - 2px);
  padding: 1px;
  margin-left: 2px;
}

.xpsamtotal {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xpsamtotal1 {
  width: calc(15% - 2px);
  margin-left: 2px;
}

.xpbackgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.xpProdTotalSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(2);
  z-index: 10;
}

.xpProdtotalSpinner {
  color: black;
}/*# sourceMappingURL=ProdTotalNew2.css.map */